import originalAxios from "axios";

import httpMethods from "constants/httpMethods";
import api from "services//api";
import { performHttpCall } from "services//apiUtils";

let applicableTemplatesXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchApplicableTemplates(eventPathIds) {
  const ids = eventPathIds.join(",");
  const url = `bo/templates/applicableForMultiple?eventPathIds=${ids}`;

  return performHttpCall(applicableTemplatesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function putApplicableTemplates(templateObj) {
  return new Promise((resolve, reject) => {
    const url = `bo/events/bulkupdate`;

    if (applicableTemplatesXhr) {
      applicableTemplatesXhr.cancel("cancelled due to concurrent request");
    }

    applicableTemplatesXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, templateObj, applicableTemplatesXhr);
      applicableTemplatesXhr = null;
      resolve(response);
    } catch (err) {
      applicableTemplatesXhr = null;
      reject(err);
    }
    applicableTemplatesXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
