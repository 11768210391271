import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;

export function fetchInitialBetsData() {
  const url = "/bo/instantaction?type=BETSLIP";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentBetsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=BETSLIP`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchInitialAccountsData() {
  const url = "/bo/instantaction?type=ACCOUNTS";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentAccountsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=ACCOUNTS`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchInitialPaymentsData() {
  const url = "/bo/instantaction?type=TRANSACTIONS";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentPaymentsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=TRANSACTIONS`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchInitialFailedBetsData() {
  const url = "/bo/instantaction?type=FAILEDBETS";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentFailedBetsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=FAILEDBETS`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchInitialCancelledBetsData() {
  const url = "/bo/instantaction?type=CANCELLEDBETS";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentCancelledBetsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=CANCELLEDBETS`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchInitialCashOutBetsData() {
  const url = "/bo/instantaction?type=CASHOUTBETS";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSubsequentCashOutBetsData(lastKey) {
  const url = `/bo/instantaction?fromKey=${lastKey}&type=CASHOUTBETS`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBrandsFilterList(lastKey) {
  const url = `/bo/brands/assingable`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function getOperatorDetails(operatorId) {
  const url = `bo/operators/${operatorId}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchDefaultCurrency() {
  const url = `/bo/currencies/default`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
