import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import { renderGenericErrorToastMessage } from "../constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";
import * as API from "services/customerManager";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* getWagerLimitGroupInfo(action) {
  try {
    const { response, xhr } = yield call(API.getWagerLimitGroupInfo, action.id, action.eventPathId);
    if (response) {
      yield put({ result: response.wagerLimits, type: actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_FAILED });
  }
}

function* getEventPathsFromCode(action) {
  try {
    const { response, xhr } = yield call(API.getEventPathsFromCode, action.code);
    if (response) {
      yield put({ eventPaths: response, type: actionTypes.FETCH_EVENTPATHS_FROM_CODE_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_EVENTPATHS_FROM_CODE_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_EVENTPATHS_FROM_CODE_FAILED });
  }
}

function* getAccountWagerLimits(action) {
  try {
    const { response, xhr } = yield call(API.getAccountWagerLimits, action.id);
    if (response) {
      yield put({ response, type: actionTypes.FETCH_ACCOUNT_WAGER_LIMITS_SUCCEEDED });
      yield put({ id: response.defaultWagerLimitGroupId, type: actionTypes.SET_DEFAULT_WAGER_LIMIT });
      yield put({ arr: response.pathSpecificWagerLimits, type: actionTypes.SET_PATH_SPECIFIC_LIMITS });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNT_WAGER_LIMITS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNT_WAGER_LIMITS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* saveWagerLimit(action) {
  try {
    const { response, xhr } = yield call(API.saveWagerLimit, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.SAVE_WAGER_LIMIT_SUCCEEDED });
      yield call(getAccountWagerLimits, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Account Wager Limits successfully updated") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.SAVE_WAGER_LIMIT_FAILED });
      toastr.add({ message: i18n.t("Manager.Error updating wager limits Please try again"), type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.SAVE_WAGER_LIMIT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getPlayerProfileFlags(action) {
  // toastr.add({message: 'Refreshing...', type: 'INFO'});
  try {
    const { response, xhr } = yield call(API.getPlayerProfileFlags, action.id);
    if (response) {
      yield put({ response, type: actionTypes.FETCH_PLAYER_PROFILE_FLAGS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_PLAYER_PROFILE_FLAGS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_PLAYER_PROFILE_FLAGS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* savePlayerProfileFlag(action) {
  try {
    const { response, xhr } = yield call(API.savePlayerProfileFlag, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.SAVE_PLAYER_PROFILE_FLAG_SUCCEEDED });
      yield call(getPlayerProfileFlags, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Profile Flags successfully updated") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.SAVE_PLAYER_PROFILE_FLAG_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.SAVE_PLAYER_PROFILE_FLAG_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

export default function* customerManagerSettingsSaga() {
  yield takeLatest(actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO, getWagerLimitGroupInfo);
  yield takeLatest(actionTypes.FETCH_EVENTPATHS_FROM_CODE, getEventPathsFromCode);
  yield takeLatest(actionTypes.FETCH_ACCOUNT_WAGER_LIMITS, getAccountWagerLimits);
  yield takeLatest(actionTypes.SAVE_WAGER_LIMIT, saveWagerLimit);
  yield takeLatest(actionTypes.FETCH_PLAYER_PROFILE_FLAGS, getPlayerProfileFlags);
  yield takeLatest(actionTypes.SAVE_PLAYER_PROFILE_FLAG, savePlayerProfileFlag);
}
