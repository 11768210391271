import _ from "lodash";

const sortExRate = (response, baseCurrency) => {
  const sortedData = response.sort((a, b) => {
    const x = a.fromCurrencyDescription.toLowerCase();
    const y = b.fromCurrencyDescription.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }

    return 0;
  });
  const baseCurrencyIndex = _.findIndex(sortedData, (d) => d.fromCurrencyId == baseCurrency);
  sortedData.splice(baseCurrencyIndex, 1);

  return sortedData;
};

export default sortExRate;
