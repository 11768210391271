export const generateKey = (item) => item.id;

export const traverseChildren = ({ item, parentLevel, treeMap }) => {
  treeMap[item.id] = item;
  treeMap[item.id]["isExpanded"] = false;
  treeMap[item.id]["level"] = parentLevel + 1;
  treeMap[item.id]["type"] = "path";
  // treeMap[item.id]['isActive']=false
  if (item.children) {
    treeMap[item.id].children = item.children.map((val) => {
      treeMap = traverseChildren({ item: val, parentLevel: parentLevel + 1, treeMap });

      return generateKey(val);
    });
  }

  return treeMap;
};
