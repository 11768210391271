import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";

export const loadBrands = createAsyncThunk("brand/loadBrands", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/brands`);

    return {
      brands: result.data,
    };
  } catch (err) {
    const errorMessage = err?.response?.headers["x-information"] || "Unable to obtain brand details";
    const customError = {
      message: errorMessage,
      name: "Brand Info Fetch Error",
      status: err.response.statusText, // serializable (err.response.data)
    };
    throw customError;
  }
});

const brandSlice = createSlice({
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loadBrands.pending]: (state) => {
      state.loading = true;
      state.brands = [];
      state.error = null;
    },
    [loadBrands.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [loadBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload.brands;
    },
  },
  initialState: {
    brands: [],
    error: null,
    loading: false,
  },

  name: "brand",
  // reducers actions
  reducers: {},
});
const { actions, reducer } = brandSlice;
// export const { logout, setActiveBrand } = actions
export default reducer;
