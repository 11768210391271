import actionTypes from "./constants";

export function selectPath(path) {
  return {
    path,
    type: actionTypes.SELECT_EVENT_CREATOR_PATH,
  };
}

export function setLoadingVisibility(isLoading) {
  return {
    isLoading,
    type: actionTypes.SET_EVENT_CREATOR_LOADING_VISIBILITY,
  };
}

export function deleteEventPath(eventPathId) {
  return {
    eventPathId,
    type: actionTypes.DELETE_EVENT_PATH,
  };
}
export function deleteEventPaths(eventPathIds) {
  return {
    eventPathIds,
    type: actionTypes.DELETE_EVENT_PATHS,
  };
}

export function deleteEvent(eventId) {
  return {
    eventId,
    type: actionTypes.DELETE_EVENT,
  };
}

export function clearDeleteEventPathErrors() {
  return {
    type: actionTypes.CLEAR_DELETE_EVENT_PATH_ERRORS,
  };
}

export function saveReorder(printOrders) {
  return {
    printOrders,
    type: actionTypes.SAVE_REORDER,
  };
}

// Event Path Tags
export function fetchEventPathTags() {
  return {
    type: actionTypes.FETCH_EVENT_PATH_TAGS,
  };
}

export function clearEventPathTagsError() {
  return {
    type: actionTypes.CLEAR_EVENT_PATH_TAGS_ERROR,
  };
}

// Event Path Details

export function fetchEventPathDetails(eventPathId) {
  return {
    eventPathId,
    type: actionTypes.FETCH_EVENT_PATH_DETAILS,
  };
}

export function addEventPath(newEventPathDetails) {
  return {
    newEventPathDetails,
    type: actionTypes.ADD_EVENT_PATH,
  };
}

export function editEventPath(eventPathId, editedEventPathDetails) {
  return {
    editedEventPathDetails,
    eventPathId,
    type: actionTypes.EDIT_EVENT_PATH,
  };
}

export function clearEventPathDetailsError() {
  return {
    type: actionTypes.CLEAR_EVENT_PATH_DETAILS_ERROR,
  };
}

export function setEventPathActiveTabIndex(activeTabIndex) {
  return {
    activeTabIndex,
    type: actionTypes.SET_EVENT_PATH_ACTIVE_TAB_INDEX,
  };
}
export function setEventPathMode(mode) {
  return {
    mode,
    type: actionTypes.SET_EVENT_PATH_MODE,
  };
}
