import actionTypes from "../constants";

import { generateReportsMap, generateReportsList, getActiveFolder } from "./helpers";

const initialState = {
  chosenReport: "",
  chosenReportFolder: "",
  chosenSingleFolder: "",
  errMsg: "",
  fetchReportsFailed: null,
  isFetchingReports: null,
  reportsList: {},
  reportsListArray: [],
  reportsMap: {},
};

export default function (state = initialState, action) {
  const newReportsMap = { ...state.reportsMap };
  let prevFolder = [];

  switch (action.type) {
    case actionTypes.FETCH_REPORTS_LIST:
      return { ...state, fetchReportsFailed: false, isFetchingReports: true };

    case actionTypes.FETCH_REPORTS_LIST_SUCCEEDED:
      const reportsMap = generateReportsMap(action.reportsList);
      const reportsListArray = generateReportsList({ reportsMap });

      return {
        ...state,
        isFetchingReports: false,
        reportsList: action.reportsList,
        reportsListArray,
        reportsMap,
      };

    case actionTypes.FETCH_REPORTS_LIST_FAILED:
      return { ...state, errMsg: action.errMsg, fetchReportsFailed: true, isFetchingReports: false };

    case actionTypes.CHOOSE_FOLDER:
      const { name } = action;
      prevFolder = getActiveFolder(state).filter((p) => p.key !== name);
      if (prevFolder.length > 0) {
        const prev = prevFolder.pop();
        newReportsMap[prev.key].selected = !newReportsMap[prev.key].selected;
      }
      if (newReportsMap[name]) {
        newReportsMap[name].selected = !newReportsMap[name].selected;
      }

      return { ...state, chosenReport: "", reportsMap: newReportsMap };
    // return { ...state, chosenSingleFolder: action.name, chosenReport: '', chosenReportFolder: '' };

    case actionTypes.CHOOSE_REPORT:
      return { ...state, chosenReport: action.name, chosenReportFolder: action.folder, chosenSingleFolder: "" };

    case actionTypes.TOGGLE_FOLDER:
      const { folder } = action;
      if (newReportsMap[folder]) {
        newReportsMap[folder].isExpanded = !newReportsMap[folder].isExpanded;
      }

      return { ...state, reportsMap: newReportsMap };

    case actionTypes.TOGGLE_FOLDER_REPORT_ITEM:
      const { reportItem } = action;
      prevFolder = getActiveFolder(state);
      if (prevFolder.length > 0) {
        const prev = prevFolder.pop();
        newReportsMap[prev.key].selected = !newReportsMap[prev.key].selected;
      }

      if (newReportsMap[reportItem.key]) {
        newReportsMap[reportItem.key].selected = !newReportsMap[reportItem.key].selected;
      }

      return {
        ...state,
        chosenReport: reportItem.key,
        chosenReportFolder: reportItem.parentKey,
        reportsMap: newReportsMap,
      };

    default:
      return { ...state };
  }
}
