import actionTypes from "../constants";

export function openModal(modalName) {
  return {
    modalName,
    type: actionTypes.OPEN_MODAL,
  };
}

export function closeModal(modalName) {
  return {
    modalName,
    type: actionTypes.CLOSE_MODAL,
  };
}

export function closeAllModals() {
  return {
    type: actionTypes.CLOSE_ALL_MODALS,
  };
}
