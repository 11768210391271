const constants = {
  FETCH_FEED_HISTORY_FEEDS: "FETCH_FEED_HISTORY_FEEDS",
  FETCH_FEED_HISTORY_FEEDS_FAILED: "FETCH_FEED_HISTORY_FEEDS_FAILED",
  FETCH_FEED_HISTORY_FEEDS_SUCCEEDED: "FETCH_FEED_HISTORY_FEEDS_SUCCEEDED",

  FETCH_FEED_HISTORY_FEED_XML: "FETCH_FEED_HISTORY_FEED_XML",
  FETCH_FEED_HISTORY_FEED_XML_FAILED: "FETCH_FEED_HISTORY_FEED_XML_FAILED",
  FETCH_FEED_HISTORY_FEED_XML_SUCCEEDED: "FETCH_FEED_HISTORY_FEED_XML_SUCCEEDED",

  FETCH_FEED_HISTORY_LINEUP: "FETCH_FEED_HISTORY_LINEUP",
  FETCH_FEED_HISTORY_LINEUP_FAILED: "FETCH_FEED_HISTORY_LINEUP_FAILED",
  FETCH_FEED_HISTORY_LINEUP_SUCCEEDED: "FETCH_FEED_HISTORY_LINEUP_SUCCEEDED",

  PARSE_FEED_HISTORY_FEED_IMPORT_XML: "PARSE_FEED_HISTORY_FEED_IMPORT_XML",
  PARSE_FEED_HISTORY_FEED_IMPORT_XML_FAILED: "PARSE_FEED_HISTORY_FEED_IMPORT_XML_FAILED",
  PARSE_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED: "PARSE_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED",

  PROCESS_FEED_HISTORY_FEED_IMPORT_XML: "PROCESS_FEED_HISTORY_FEED_IMPORT_XML",
  PROCESS_FEED_HISTORY_FEED_IMPORT_XML_FAILED: "PROCESS_FEED_HISTORY_FEED_IMPORT_XML_FAILED",
  PROCESS_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED: "PROCESS_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED",
};

export default constants;
