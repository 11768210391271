import update from "immutability-helper";

import constants from "./constants";
import {
  parseBetsData,
  parseAccountsData,
  parsePaymentsData,
  parseFailedBetsData,
  parseCancelledBetsData,
  parseCashOutBetsData,
} from "./helpers";

import { loadState } from "localStorage";

const initialState = {
  activeTabIndex: 0,
  allSports: [],
  betDisplay: {
    backgroundColor: "#ffffff",
    fontColor: "#000000",
    items: [],
    lowerLimit: "",
    maxRows: "",
    upperLimit: "",
  },
  betFilters: {
    betType: {
      active: false,
      expanded: true,
      value: null,
    },
    markets: {
      active: true,
      expanded: true,
      live: true,
      mainbook: true,
    },
    riskAmount: {
      active: false,
      type: "atLeast",
      value: 500,
    },
    searchFilter: "",
    sports: {
      active: false,
      expanded: true,
      items: [],
      type: null,
    },
    totalStake: {
      active: false,
      type: "atLeast",
      value: 500,
    },
  },
  brandFilter: "All",
  brandFilterList: [],
  brands: [],
  channels: [],
  connected: {
    accounts: null,
    bets: null,
    cancelledBets: null,
    cashOutBets: null,
    failedBets: null,
    payments: null,
  },
  defaultCurrency: null,
  headersPreferences: {
    accounts: {
      account: true,
      date: true,
      email: true,
      icon: true,
      name: true,
      referrer: true,
      site: true,
      username: true,
    },
    bets: {
      account: true,
      accountDescription: true,
      date: true,
      description: true,
      icon: true,
      shop: true,
      sport: true,
      totalStake: true,
      totalStakeP: true,
      unitStake: true,
      unitStakeP: true,
      username: true,
      winAmount: true,
      winAmountP: true,
    },
    cancelledBets: {
      account: true,
      date: true,
      description: true,
      stake: true,
      username: true,
    },
    cashOutBets: {
      account: true,
      date: true,
      description: true,
      percentage: true,
      potentialWin: true,
      totalStake: true,
      username: true,
    },
    failedBets: {
      account: true,
      date: true,
      description: true,
      stake: true,
      username: true,
    },
    payments: {
      account: true,
      credit: true,
      date: true,
      debit: true,
      description: true,
      origin: true,
      username: true,
    },
  },
  lastKeys: {
    accounts: null,
    bets: null,
    cancelledBets: null,
    cashOutBets: null,
    failedBets: null,
    payments: null,
  },
  locks: {
    accounts: false,
    bets: false,
    cancelledBets: null,
    cashOutBets: null,
    failedBets: false,
    payments: false,
  },
  operatorDetails: {},
  origins: [],
  scrollTop: false,
  showPreferencesModal: false,
  tablesData: {
    accounts: null,
    bets: null,
    cancelledBets: null,
    cashOutBets: null,
    failedBets: null,
    payments: null,
  },
};

const instantAction = (state = initialState, action) => {
  switch (action.type) {
    case constants.REHYDRATE_STATE: {
      const persistedState = loadState("instantAction");

      if (persistedState) {
        state = {
          ...state,
          activeTabIndex: persistedState.activeTabIndex,
          betFilters: persistedState.betFilters,
          headersPreferences: persistedState.headersPreferences,
        };
      }

      return state;
    }

    case constants.FETCH_INITIAL_BETS_DATA_SUCCEEDED:
      const initialBets = parseBetsData(
        action.results.betSlipList,
        state.allSports,
        state.origins,
        state.channels,
        state.brands,
      );

      return {
        ...state,
        connected: update(state.connected, { bets: { $set: true } }),
        lastKeys: update(state.lastKeys, { bets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { bets: { $set: initialBets } }),
      };
    case constants.FETCH_INITIAL_BETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { bets: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_BETS_DATA_SUCCEEDED: {
      const newBets =
        action.results.lastKey !== state.lastKeys.bets
          ? parseBetsData(action.results.betSlipList, state.allSports, state.origins, state.channels, state.brands)
          : [];

      return {
        ...state,
        connected: update(state.connected, { bets: { $set: true } }),
        lastKeys: update(state.lastKeys, { bets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { bets: { $push: newBets } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_BETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { bets: { $set: false } }),
      };

    case constants.FETCH_INITIAL_ACCOUNTS_DATA_SUCCEEDED:
      const initialAccounts = parseAccountsData(
        action.results.accountList,
        state.origins,
        state.channels,
        state.brands,
      );

      return {
        ...state,
        connected: update(state.connected, { accounts: { $set: true } }),
        lastKeys: update(state.lastKeys, { accounts: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { accounts: { $set: initialAccounts } }),
      };
    case constants.FETCH_INITIAL_ACCOUNTS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { accounts: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_ACCOUNTS_DATA_SUCCEEDED: {
      const newAccounts =
        action.results.lastKey !== state.lastKeys.bets
          ? parseAccountsData(action.results.accountList, state.origins, state.channels, state.brands)
          : [];

      return {
        ...state,
        connected: update(state.connected, { accounts: { $set: true } }),
        lastKeys: update(state.lastKeys, { accounts: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { accounts: { $push: newAccounts } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_ACCOUNTS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { accounts: { $set: false } }),
      };

    case constants.FETCH_INITIAL_PAYMENTS_DATA_SUCCEEDED:
      const initialPayments = parsePaymentsData(
        action.results.transactionList,
        state.origins,
        state.brands,
        state.channels,
      );

      return {
        ...state,
        connected: update(state.connected, { payments: { $set: true } }),
        lastKeys: update(state.lastKeys, { payments: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { payments: { $set: initialPayments } }),
      };
    case constants.FETCH_INITIAL_PAYMENTS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { payments: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_PAYMENTS_DATA_SUCCEEDED: {
      const newPayments =
        action.results.lastKey !== state.lastKeys.bets
          ? parsePaymentsData(action.results.transactionList, state.origins, state.brands, state.channels)
          : [];

      return {
        ...state,
        connected: update(state.connected, { payments: { $set: true } }),
        lastKeys: update(state.lastKeys, { payments: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { payments: { $push: newPayments } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_PAYMENTS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { payments: { $set: false } }),
      };

    case constants.FETCH_INITIAL_FAILEDBETS_DATA_SUCCEEDED:
      const initialFailedBets = parseFailedBetsData(action.results.transactionList, state.origins, state.brands);

      return {
        ...state,
        connected: update(state.connected, { failedBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { failedBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { failedBets: { $set: initialFailedBets } }),
      };
    case constants.FETCH_INITIAL_FAILEDBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { failedBets: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_FAILEDBETS_DATA_SUCCEEDED: {
      const newFailedBets =
        action.results.lastKey !== state.lastKeys.bets
          ? parseFailedBetsData(action.results.transactionList, state.origins, state.brands)
          : [];

      return {
        ...state,
        connected: update(state.connected, { failedBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { failedBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { failedBets: { $push: newFailedBets } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_FAILEDBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { failedBets: { $set: false } }),
      };

    case constants.FETCH_INITIAL_CANCELLEDBETS_DATA_SUCCEEDED:
      const initialCancelledBets = parseCancelledBetsData(action.results.transactionList, state.origins, state.brands);

      return {
        ...state,
        connected: update(state.connected, { cancelledBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { cancelledBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { cancelledBets: { $set: initialCancelledBets } }),
      };
    case constants.FETCH_INITIAL_CANCELLEDBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { cancelledBets: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_SUCCEEDED: {
      const newCancelledBets =
        action.results.lastKey !== state.lastKeys.bets
          ? parseCancelledBetsData(action.results.transactionList, state.origins, state.brands)
          : [];

      return {
        ...state,
        connected: update(state.connected, { cancelledBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { cancelledBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { cancelledBets: { $push: newCancelledBets } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { cancelledBets: { $set: false } }),
      };

    case constants.FETCH_INITIAL_CASHOUTBETS_DATA_SUCCEEDED:
      const initialCashOutBets = parseCashOutBetsData(action.results.cashOutBetsList, state.origins, state.brands);

      return {
        ...state,
        connected: update(state.connected, { cashOutBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { cashOutBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { cashOutBets: { $set: initialCashOutBets } }),
      };
    case constants.FETCH_INITIAL_CASHOUTBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { cashOutBets: { $set: false } }),
      };

    case constants.FETCH_SUBSEQUENT_CASHOUTBETS_DATA_SUCCEEDED: {
      const newCashOutBets =
        action.results.lastKey !== state.lastKeys.bets
          ? parseCashOutBetsData(action.results.cashOutBetsList, state.origins, state.brands)
          : [];

      return {
        ...state,
        connected: update(state.connected, { cashOutBets: { $set: true } }),
        lastKeys: update(state.lastKeys, { cashOutBets: { $set: action.results.lastKey } }),
        tablesData: update(state.tablesData, { cashOutBets: { $push: newCashOutBets } }),
      };
    }
    case constants.FETCH_SUBSEQUENT_CASHOUTBETS_DATA_FAILED:
      return {
        ...state,
        connected: update(state.connected, { cashOutBets: { $set: false } }),
      };

    case constants.ADD_SPORT:
      return {
        ...state,
        betFilters: update(state.betFilters, { sports: { items: { $push: [action.name] } } }),
      };
    case constants.REMOVE_SPORT:
      const removeIndex = state.betFilters.sports.items.indexOf(action.name);

      return {
        ...state,
        betFilters: update(state.betFilters, { sports: { items: { $splice: [[removeIndex, 1]] } } }),
      };

    case constants.CHANGE_BET_TYPE:
      return {
        ...state,
        betFilters: update(state.betFilters, { betType: { value: { $set: action.value } } }),
      };

    case constants.TOGGLE_EXPAND:
      return {
        ...state,
        betFilters: update(state.betFilters, {
          [action.name]: { expanded: { $set: !state.betFilters[action.name].expanded } },
        }),
      };

    case constants.TOGGLE_ACTIVE:
      return {
        ...state,
        betFilters: update(state.betFilters, {
          [action.name]: { active: { $set: !state.betFilters[action.name].active } },
        }),
      };

    case constants.TOGGLE_MARKETS_TYPE:
      return {
        ...state,
        betFilters: update(state.betFilters, {
          markets: { [action.name]: { $set: !state.betFilters.markets[action.name] } },
        }),
      };

    case constants.CHANGE_TOTAL_STAKE_TYPE:
      return {
        ...state,
        betFilters: update(state.betFilters, { totalStake: { type: { $set: action.value } } }),
      };

    case constants.CHANGE_TOTAL_STAKE_VALUE:
      return {
        ...state,
        betFilters: update(state.betFilters, { totalStake: { value: { $set: action.value } } }),
      };

    case constants.CHANGE_RISK_AMOUNT_TYPE:
      return {
        ...state,
        betFilters: update(state.betFilters, { riskAmount: { type: { $set: action.value } } }),
      };

    case constants.CHANGE_RISK_AMOUNT_VALUE:
      return {
        ...state,
        betFilters: update(state.betFilters, { riskAmount: { value: { $set: action.value } } }),
      };

    case constants.CHANGE_SPORTS_TYPE:
      return {
        ...state,
        betFilters: update(state.betFilters, { sports: { type: { $set: action.value } } }),
      };

    case constants.CHANGE_MAX_ROWS:
      return {
        ...state,
        betDisplay: update(state.betDisplay, { maxRows: { $set: action.value } }),
      };

    case constants.CHANGE_ACTIVE_TAB_INDEX:
      return {
        ...state,
        activeTabIndex: action.index,
      };

    case constants.CHANGE_LOWER_LIMIT:
      return {
        ...state,
        betDisplay: update(state.betDisplay, { lowerLimit: { $set: action.value } }),
      };
    case constants.CHANGE_UPPER_LIMIT:
      return {
        ...state,
        betDisplay: update(state.betDisplay, { upperLimit: { $set: action.value } }),
      };
    case constants.CHANGE_FONT_COLOR:
      return {
        ...state,
        betDisplay: update(state.betDisplay, { fontColor: { $set: action.value } }),
      };
    case constants.CHANGE_BACKGROUND_COLOR:
      return {
        ...state,
        betDisplay: update(state.betDisplay, { backgroundColor: { $set: action.value } }),
      };
    case constants.ADD_FORMATTER_ITEM:
      return {
        ...state,
        betDisplay: update(state.betDisplay, {
          backgroundColor: { $set: "#ffffff" },
          fontColor: { $set: "#000000" },
          items: { $push: [action.item] },
          lowerLimit: { $set: "" },
          upperLimit: { $set: "" },
        }),
      };
    case constants.REMOVE_FORMATTER_ITEM:
      const newItems = state.betDisplay.items.filter((item) => item.id !== action.id);

      return {
        ...state,
        betDisplay: update(state.betDisplay, { items: { $set: newItems } }),
      };

    case constants.LOCK_TABLE:
      return {
        ...state,
        locks: update(state.locks, { [action.table]: { $set: true } }),
        scrollTop: false,
      };
    case constants.UNLOCK_TABLE:
      return {
        ...state,
        locks: update(state.locks, { [action.table]: { $set: false } }),
        scrollTop: true,
      };

    case constants.SHOW_PREFERENCES_MODAL:
      return {
        ...state,
        showPreferencesModal: true,
      };
    case constants.HIDE_PREFERENCES_MODAL:
      return {
        ...state,
        showPreferencesModal: false,
      };

    case constants.SET_HEADER_PREFERENCE:
      return {
        ...state,
        headersPreferences: update(state.headersPreferences, {
          [action.table]: { [action.header]: { $set: action.value } },
        }),
      };

    case constants.SAVE_CONSTANTS_TO_STORE:
      return {
        ...state,
        allSports: update(state.allSports, { $push: action.sports }),
        brands: update(state.brands, { $push: action.brands }),
        channels: update(state.channels, { $push: action.channels }),
        origins: update(state.origins, { $push: action.origins }),
      };

    case constants.CHANGE_BRAND_VALUE:
      return {
        ...state,
        brandFilter: action.value,
      };

    case constants.REMOVE_SCROLL_TOP:
      return {
        ...state,
        scrollTop: false,
      };

    case constants.FETCH_BRANDS_FILTER_SUCCEEDED:
      return {
        ...state,
        brandFilterList: action.list,
      };
    case constants.FETCH_OPERATOR_DETAILS_SUCCEEDED:
      return {
        ...state,
        operatorDetails: action.operatorDetails,
      };
    case constants.FETCH_OPERATOR_DETAILS_FAILED:
      return {
        ...state,
        operatorDetails: {},
      };
    case constants.FETCH_DEFAULT_CURRENCY_SUCCEEDED:
      return {
        ...state,
        defaultCurrency: action.defaultCurrency,
      };
    case constants.SET_SEARCH_FILTER:
      return {
        ...state,
        betFilters: update(state.betFilters, { searchFilter: { $set: action.value } }),
      };

    default:
      return { ...state };
  }
};

export default instantAction;
