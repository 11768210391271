import httpMethods from "../../../constants/httpMethods";
import { performHttpCall } from "../../../services/apiUtils";

import constants from "./constants";

const permisisonXHR = null;

export function fetchApplications(type, id) {
  const url = `/bo/${type}/${id}/applications`;

  return performHttpCall(permisisonXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const actionsXHR = null;

export function fetchActions(type, id) {
  const url = `/bo/${type}/${id}/actions`;

  return performHttpCall(actionsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const reportsXHR = null;

export function fetchReports(operatorGroupId, operatorId) {
  operatorId = operatorId || -1;
  const url = `/bo/reports?groupOperatorId=${operatorGroupId}&operatorId=${operatorId}`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
