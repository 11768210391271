import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { parseErrorMessageInXhr } from "services//apiUtils";


function* settingsTextSearch(action) {
  const { response, xhr } = yield call(API.onTextSearch, action);
  if (response) {
    yield put({ results: response, type: constants.FETCH_SEARCH_TEXT_DATA_SETTING_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SEARCH_TEXT_DATA_SETTING_FAILED });
  }
}

function* getAllSettings(action) {
  const { response, xhr } = yield call(API.onGetAllSettings);
  if (response) {
    yield put({ results: response, type: constants.FETCH_ALL_SETTINGS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_SETTINGS_DATA_FAILED });
  }
}

export default function* SettingsBrowser() {
  yield takeLatest(constants.FETCH_SEARCH_TEXT_DATA_SETTING, settingsTextSearch);
  yield takeLatest(constants.FETCH_ALL_SETTINGS_DATA, getAllSettings);
}
