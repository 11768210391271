const constants = {
  COPY_MESSAGE_PLAN: "BM::COPY_MESSAGE_PLAN",
  COPY_MESSAGE_PLAN_FAILED: "BM::COPY_MESSAGE_PLAN_FAILED",
  COPY_MESSAGE_PLAN_SUCCEEDED: "BM::COPY_MESSAGE_PLAN_SUCCEEDED",
  CREATE_MESSAGE_PLAN: "BM::CREATE_MESSAGE_PLAN",
  CREATE_MESSAGE_PLAN_FAILED: "BM::CREATE_MESSAGE_PLAN_FAILED",
  CANCEL_EDIT: "BM::CANCEL_EDIT",

  CREATE_MESSAGE_PLAN_SUCCEEDED: "BM::CREATE_MESSAGE_PLAN_SUCCEEDED",

  DELETE_MESSAGE_PLAN: "BM::DELETE_MESSAGE_PLAN",
  DELETE_MESSAGE_PLAN_FAILED: "BM::DELETE_MESSAGE_PLAN_FAILED",
  DELETE_MESSAGE_PLAN_SUCCEEDED: "BM::DELETE_MESSAGE_PLAN_SUCCEEDED",

  EDIT_MESSAGE_PLAN_ITEM: "BM::EDIT_MESSAGE_PLAN_ITEM",
  EDIT_MESSAGE_PLAN_ITEM_FAILED: "BM::EDIT_MESSAGE_PLAN_ITEM_FAILED",
  EDIT_MESSAGE_PLAN_ITEM_SUCCEEDED: "BM::EDIT_MESSAGE_PLAN_ITEM_SUCCEEDED",

  EMPTY_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN: "BM::EMPTY_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN",
  FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN: "BM::FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN",
  FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN_FAILED: "BM::FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN_FAILED",

  FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN_SUCCEEDED: "BM::FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN_SUCCEEDED",
  FETCH_MESSAGE_PLANS: "BM::FETCH_MESSAGE_PLANS",
  FETCH_MESSAGE_PLANS_FAILED: "BM::FETCH_MESSAGE_PLANS_FAILED",

  FETCH_MESSAGE_PLANS_SUCCEEDED: "BM::FETCH_MESSAGE_PLANS_SUCCEEDED",

  FETCH_MESSAGE_PLAN_DETAILS: "BM::FETCH_MESSAGE_PLAN_DETAILS",

  FETCH_MESSAGE_PLAN_DETAILS_FAILED: "BM::FETCH_MESSAGE_PLAN_DETAILS_FAILED",

  FETCH_MESSAGE_PLAN_DETAILS_SUCCEEDED: "BM::FETCH_MESSAGE_PLAN_DETAILS_SUCCEEDED",
  FETCH_OPPONENTS: "BM::FETCH_OPPONENTS",
  FETCH_OPPONENTS_FAILED: "BM::FETCH_OPPONENTS_FAILED",
  FETCH_OPPONENTS_SUCCEEDED: "BM::FETCH_OPPONENTS_SUCCEEDED",

  RESET_MESSAGE_PLANS: "BM::RESET_MESSAGE_PLANS",
  SET_CAMPAIGN_MODIFIED: "BM::SET_CAMPAIGN_MODIFIED",
  UPDATE_EDITED_MESSAGE_IN_REDUX: "BM::UPDATE_EDITED_MESSAGE_IN_REDUX",

  UPDATE_MESSAGE_PLAN: "BM::UPDATE_MESSAGE_PLAN",
  UPDATE_MESSAGE_PLAN_FAILED: "BM::UPDATE_MESSAGE_PLAN_FAILED",
  UPDATE_MESSAGE_PLAN_FIELD: "BM::UPDATE_MESSAGE_PLAN_FIELD",

  UPDATE_MESSAGE_PLAN_SUCCEEDED: "BM::UPDATE_MESSAGE_PLAN_SUCCEEDED",
};

export default constants;
