import _ from "lodash";

import constants from "./constants";

import { makeIterable } from "utils";

const initialState = {
  backUp: JSON.stringify({ value: [] }),
  indicatorActions: [],
  modifiedRow: {},
  stakeDistributationData: JSON.stringify({ value: [] }),
  stakeType: [],
  updateStakeDistributionSuccessfully: false,
};

const WL_StakeDistributation = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_STAKE_TYPE_SUCCEEDED:
      return {
        ...state,
        stakeType: action.stakeTypes,
      };
    case constants.FETCH_INDICATOR_ACTIONS_SUCCEEDED:
      return {
        ...state,
        indicatorActions: action.indicatorActions,
      };
    case constants.FETCH_STAKE_DISTRIBUTATION_SUCCEEDED:
      return {
        ...state,
        backUp: JSON.stringify({ value: action.response }),
        modifiedRow: {},
        stakeDistributationData: JSON.stringify({ value: action.response }),
      };
    case constants.FETCH_STAKE_DISTRIBUTATION_FAILED:
      return {
        ...state,
      };
    case constants.SELECT_ROW_TO_EDIT:
      const selectedRow = _.find(JSON.parse(state.backUp).value, (obj) => obj.id === action.row.id);

      return {
        ...state,
        selectedRowToEdit: JSON.stringify(selectedRow),
      };
    case constants.UPDATE_FIELD:
      const { field, row, value } = action;
      const selectedRowToEdit = JSON.parse(state.selectedRowToEdit);
      const isModified = !_.isEqual(selectedRowToEdit[field], value);
      const modifiedRow = state.modifiedRow;
      const stakeDistributationData = JSON.parse(state.stakeDistributationData).value;
      _.map(stakeDistributationData, (item, indx) => {
        if (stakeDistributationData[indx].id == row.id) {
          stakeDistributationData[indx][field] = value;
        }
      });
      if (isModified) {
        if (!modifiedRow[row.id]) {
          modifiedRow[row.id] = [];
          modifiedRow[row.id].push(field);
        } else if (!_.includes(modifiedRow[row.id], field)) {
          modifiedRow[row.id].push(field);
        }
      } else if (modifiedRow[row.id]) {
        _.remove(modifiedRow[row.id], (f) => f == field);
        if (modifiedRow[row.id].length == 0) {
          delete modifiedRow[row.id];
        }
      }

      return {
        ...state,
        modifiedRow,
        stakeDistributationData: JSON.stringify({ value: stakeDistributationData }),
      };
    case constants.ON_EXIT_STAKEDISTRIBUTATION_PAGE:
      return {
        ...state,
        modifiedRow: {},
        selectedRowToEdit: null,
        stakeDistributationData: state.backUp,
      };
    case constants.SAVE_STAKE_DISTRIBUTATION_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
        updateStakeDistributionSuccessfully: true,
      };
    case constants.SAVE_STAKE_DISTRIBUTATION_FAILED:
      return {
        ...state,
        updateStakeDistributionSuccessfully: false,
      };
    case constants.ON_REMOVE_STAKE_LIABILITY_INDICATOR_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
        updateStakeDistributionSuccessfully: true,
      };
    case constants.ON_REMOVE_STAKE_LIABILITY_INDICATOR_FAILED:
      return {
        ...state,
        updateStakeDistributionSuccessfully: false,
      };
    default:
      return { ...state };
  }
};

export default WL_StakeDistributation;
