import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchRiskcolumns(action) {
  const { response, xhr } = yield call(API.fetchRiskcolumns);
  if (response) {
    yield put({ columnsWithId: response, type: constants.FETCH_RISK_DEFAULT_COLUMNS });
  } else {
  }
}

function* fetchRiskDefaultcolumns(action) {
  const { response, xhr } = yield call(API.fetchRiskDefaultcolumns);
  if (response) {
    yield put({ defaultRiskColumn: response, type: constants.FETCH_RISK_DEFAULT_COLUMNS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_RISK_DEFAULT_COLUMNS_FAILED });
  }
}

function* saveRiskDefaultManagement(action) {
  const { response, xhr } = yield call(API.saveRiskDefaultManagement, action.dataToSave);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Risk Default Management Updated Successfully"), type: "SUCCESS" });
    yield put({ type: constants.FETCH_RISK_COLUMNS });
  } else {
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

export default function* utilitiesRiskDefaultManagement() {
  yield takeLatest(constants.FETCH_RISK_COLUMNS, fetchRiskcolumns);
  yield takeLatest(constants.FETCH_RISK_DEFAULT_COLUMNS, fetchRiskDefaultcolumns);
  yield takeLatest(constants.SAVE_RISK_DEFAULT_MANAGEMENT, saveRiskDefaultManagement);
}
