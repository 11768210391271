import actionTypes from "../../constants";

const initialState = {
  errors: null,
  optionsField: {},
};

const customerManagerCreateAccount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        errors: null,
      };
    case actionTypes.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        errors: action.errors,
      };
    case actionTypes.GET_OPTIONAL_FIELDS_SUCCESS:
      return {
        ...state,
        optionsField: action.result,
      };
    case actionTypes.RESET_ERROR_FIELD:
      return {
        ...state,
        errors: null,
      };
    default:
      return { ...state };
  }
};

export default customerManagerCreateAccount;
