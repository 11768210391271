import actionTypes from "../constants";

const initialState = {
  isLoadingTree: null,
  isLoadingTreeFailed: null,
  isUpdatingTree: null,
  isUpdatingTreeFailed: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_RESULTS_VIEWER_DATA:
      return {
        ...state,
        hasUpdates: false,
        isLoadingTree: true,
        isLoadingTreeFailed: false,
        isUpdatingTreeFailed: false,
      };
    case actionTypes.FETCH_RESULTS_VIEWER_DATA_SUCCEEDED:
      return {
        ...state,
        hasUpdates: !!action.hasUpdates,
        isLoadingTree: false,
        isLoadingTreeFailed: false,
      };
    case actionTypes.FETCH_RESULTS_VIEWER_DATA_FAILED:
      return { ...state, hasUpdates: false, isLoadingTree: false, isLoadingTreeFailed: true };
    case actionTypes.UPDATE_RESULTS_VIEWER_DATA:
      return { ...state, hasUpdates: false, isUpdatingTree: true, isUpdatingTreeFailed: false };
    case actionTypes.UPDATE_RESULTS_VIEWER_DATA_SUCCEEDED:
    case actionTypes.CANCEL_UPDATE_RESULTS_VIEWER_DATA:
      return {
        ...state,
        hasUpdates: action.hasUpdates,
        isLoadingTreeFailed: false,
        isUpdatingTree: false,
        isUpdatingTreeFailed: false,
      };
    case actionTypes.UPDATE_RESULTS_VIEWER_DATA_FAILED:
      return { ...state, isUpdatingTree: false, isUpdatingTreeFailed: true };
    case actionTypes.CLEAR_RESULTS_VIEWER_DATA:
      return { ...state, isLoadingTree: false, isLoadingTreeFailed: false };
    default:
      return { ...state };
  }
}
