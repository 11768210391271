export const FETCH_TERMS = "BM:FETCH_TERMS";
export const RECEIVE_TERMS = "BM:RECEIVE_TERMS";
export const SET_TERMS_MODE = "BM:SET_TERMS_MODE";
export const UPDATE_TERM = "BM:UPDATE_TERM";
export const UPDATE_TERM_SUC = "BM:UPDATE_TERM_SUC";
export const CREATE_TERM = "BM:CREATE_TERM";
export const COPY_TERM = "BM:COPY_TERM";
export const COPY_TERM_SUCCESS = "BM:COPY_TERM_SUCCESS";
export const COPY_TERM_FAILED = "BM:COPY_TERM_FAILED";
export const DELETE_TERM = "BM:DELETE_TERM";
export const DELETE_TERM_SUCCESS = "BM:DELETE_TERM_SUCCESS";
export const DELETE_TERM_FAILED = "BM:DELETE_TERM_FAILED";
export const CREATE_TERM_SUC = "BM:CREATE_TERM_SUC";
export const RESET_TERM = "BM:RESET_TERM";
export const SET_TERM = "BM:SET_TERM";
export const SET_TERM_MODIFIED = "BM:SET_TERM_MODIFIED";
export const FETCH_TERM_CAMPAIGNS = "BM:FETCH_TERM_CAMPAIGNS";
export const FETCH_TERM_CAMPAIGNS_SUCCESS = "BM:FETCH_TERM_CAMPAIGNS_SUCCESS";
export const FETCH_TERM_CAMPAIGNS_FAILED = "BM:FETCH_TERM_CAMPAIGNS_FAILED";

export const TERMMODE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
};
