import { replace } from "react-router-redux";
import { put, call, select, takeLatest } from "redux-saga/effects";
import _ from "underscore";

import {
  updatePath,
  finalizePath,
  deletePath,
  fetchEventMarkets,
  deletePaths,
  fetchEPT,
} from "../../SportsTree/actions";
import { toggleBulkUpdate } from "../App/actions";

import {
  fetchEventDefaultMarket,
  fetchEventSelectedOpponents,
  getDefaultTemplate,
  getDefaultTemplateSettings,
} from "./actions";
import constants, { validDefaultSettings } from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";
import { selectValidProps } from "utils";

function* getEvent(action) {
  // const {response, xhr} = yield call(API.fetchEvent, action.eventPathId, action.eventId);
  const { response } = yield call(API.fetchEvent, action.eventId);
  if (response) {
    const eventPathId = _.last(response.eventPaths).id;
    yield call(getOpponents, { eventPathId });
    yield put({ eventPathId, type: constants.SET_EVENT_PATH });
    yield put({ response, type: constants.FETCH_EVENT_SUCCEEDED });
    if (action.isRankEvent) {
      yield put(fetchEventDefaultMarket(action.eventId));
    }
  } else {
    yield put({ type: constants.FETCH_EVENT_FAILED });
  }
}

function* getEventDefaultMarket(action) {
  const { response } = yield call(API.fetchEventDefaultMarket, action.eventId);
  if (response) {
    yield put({ response, type: constants.FETCH_EVENT_DEFAULT_MARKET_SUCCEEDED });
    yield put(fetchEventSelectedOpponents(response.id, action.eventId));
  } else {
    yield put({ type: constants.FETCH_EVENT_DEFAULT_MARKET_FAILED });
  }
}

function* getEventSelectedOpponents(action) {
  const { response } = yield call(API.fetchEventSelectedOpponents, action.marketId, action.eventId);
  if (response) {
    yield put({ eventId: action.eventId, response, type: constants.FETCH_EVENT_SELECTED_OPPONENTS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EVENT_SELECTED_OPPONENTS_FAILED });
  }
}

function* deleteEvent(action) {
  const { response, xhr } = yield call(API.deleteEvent, action.eventId);
  if (response) {
    yield put({ response, type: constants.DELETE_EVENT_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Event successfully deleted") });
    const { pathsMap } = yield select((state) => state.sportsTree);
    const path = pathsMap[action.eventId];
    const parentPath = pathsMap[path.parentId];
    yield put(replace(parentPath.url)); // replace history state with parent path's url
    yield put(deletePath(action.eventId));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ type: constants.DELETE_EVENT_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete event")}. ${msg}`, type: "ERROR" });
  }
}

function* deleteEvents(action) {
  const { response, xhr } = yield call(API.deleteEvents, action.eventIds);
  if (response) {
    yield put({ response, type: constants.DELETE_EVENT_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Events successfully deleted") });
    const { activePathId, pathsMap } = yield select((state) => state.sportsTree);
    if (action.eventIds.includes(activePathId)) {
      const path = pathsMap[activePathId];
      const parentPath = pathsMap[path.parentId];
      yield put(replace(parentPath.url)); // replace history state with parent path's url
    }
    yield put(deletePaths(action.eventIds));
    yield put(toggleBulkUpdate(false));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ type: constants.DELETE_EVENT_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete event")}. ${msg}`, type: "ERROR" });
  }
}

function* createEvent(action) {
  const { response, xhr } = yield call(API.createEvent, action.eventPathId, action.data);
  if (response) {
    const text = response.description ? response.description : "";
    yield put({ response, type: constants.CREATE_EVENT_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Event.Successfully created")} ${text}.` });
    yield put(
      finalizePath(response.id, {
        description: response.description,
        id: response.id,
        parentId: action.eventPathId,
        startTime: response.startDateTime,
      }),
    );
    const { activeSportId, parameters, pathsMap } = yield select((state) => state.sportsTree);
    const path = pathsMap[response.id];
    yield put(fetchEPT(activeSportId, parameters));
    yield put(replace(path.url));
    yield put(fetchEventMarkets(path.id, parameters, { updateEventMarketCount: true }));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: constants.CREATE_EVENT_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to create event")}. ${msg}`, type: "ERROR" });
  }
}

function* updateEvent(action) {
  const { response, xhr } = yield call(API.updateEvent, action.eventId, action.data);
  if (response) {
    yield put({ response, type: constants.UPDATE_EVENT_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Event.Successfully updated")} ${response.description}.` });
    yield put(updatePath(response.id, { description: response.description, startTime: response.startDateTime }));
    if (action.data.outcomes && action.data.outcomes.length) {
      const defaultMarketId = yield select((state) => state.eventCreatorEvent.defaultMarketId);
      yield put(fetchEventSelectedOpponents(defaultMarketId, action.eventId));
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: constants.UPDATE_EVENT_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to update event")}. ${msg}`, type: "ERROR" });
  }
}

function* getOpponents(action) {
  const { response } = yield call(API.fetchOpponents, action.eventPathId);
  const options = action.options || {};
  if (response) {
    const { eventTypeId, shouldFetchDefaultTemplate } = options;
    if (shouldFetchDefaultTemplate && response.length) {
      yield put(getDefaultTemplate(action.eventPathId, eventTypeId, response));
    } else {
      yield put({ response, type: constants.FETCH_EVENT_OPPONENTS_SUCCEEDED });
    }
  } else {
    yield put({ type: constants.FETCH_EVENT_OPPONENTS_FAILED });
  }
}
function* getOpponentsByType(action) {
  const { response } = yield call(API.fetchOpponentsByType, action);
  const eventPathId = action.eventPathId;
  yield put({ eventPathId, type: constants.SET_EVENT_PATH });
  if (response) {
    yield put({ response, type: constants.FETCH_EVENT_OPPONENTS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EVENT_OPPONENTS_FAILED });
  }
}

function* fetchDefaultTemplate(action) {
  const { data, eventPathId, eventTypeId } = action;
  const { response, xhr } = yield call(API.fetchDefaultTemplate, eventPathId, eventTypeId);
  if (response) {
    yield put({ eventTemplateId: response[0], response: data, type: constants.FETCH_EVENT_OPPONENTS_SUCCEEDED });
    if (response[0] !== undefined) yield put(getDefaultTemplateSettings(eventTypeId, response[0]));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_EVENT_OPPONENTS_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to fetch players")}. ${msg}`, type: "ERROR" });
  }
}

function* fetchDefaultTemplateSettings(action) {
  const { eventTypeId, templateId } = action;
  const { response, xhr } = yield call(API.fetchDefaultTemplateSettings, templateId);
  if (response) {
    const settings =
      response.find(
        (setting) =>
          setting.eventTemplateId === templateId && setting.eventTypeId === eventTypeId && setting.feedId < 0,
      ) || {};
    // according to Dianne, calendarEvent is the equivalent of inRunning prop
    settings.calendarEvent = settings.inRunning;
    const validSettings = selectValidProps([settings], validDefaultSettings)[0];
    yield put({ settings: validSettings, type: constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to fetch default template settings")}. ${msg}`, type: "ERROR" });
  }
}

function* fetchPlayersOfOpponentA(action) {
  const { response, xhr } = yield call(API.fetchPlayersOfOpponent, action.opponentId);
  if (response) {
    yield put({ response, type: constants.FETCH_PLAYERS_OF_OPPONENTA_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_PLAYERS_OF_OPPONENTA_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to fetch players")}. ${msg}`, type: "ERROR" });
  }
}

function* fetchPlayersOfOpponentB(action) {
  const { response, xhr } = yield call(API.fetchPlayersOfOpponent, action.opponentId);
  if (response) {
    yield put({ response, type: constants.FETCH_PLAYERS_OF_OPPONENTB_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_PLAYERS_OF_OPPONENTB_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to fetch players")}. ${msg}`, type: "ERROR" });
  }
}

export default function* eventSaga() {
  yield takeLatest(constants.FETCH_EVENT, getEvent);
  yield takeLatest(constants.FETCH_EVENT_DEFAULT_MARKET, getEventDefaultMarket);
  yield takeLatest(constants.FETCH_EVENT_SELECTED_OPPONENTS, getEventSelectedOpponents);
  yield takeLatest(constants.DELETE_EVENT, deleteEvent);
  yield takeLatest(constants.DELETE_EVENTS, deleteEvents);
  yield takeLatest(constants.CREATE_EVENT, createEvent);
  yield takeLatest(constants.UPDATE_EVENT, updateEvent);
  yield takeLatest(constants.FETCH_EVENT_OPPONENTS, getOpponents);
  yield takeLatest(constants.FETCH_EVENT_OPPONENTS_BY_TYPE, getOpponentsByType);
  yield takeLatest(constants.FETCH_EVENT_DEFAULT_TEMPLATE, fetchDefaultTemplate);
  yield takeLatest(constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS, fetchDefaultTemplateSettings);
  yield takeLatest(constants.FETCH_PLAYERS_OF_OPPONENTA, fetchPlayersOfOpponentA);
  yield takeLatest(constants.FETCH_PLAYERS_OF_OPPONENTB, fetchPlayersOfOpponentB);
}
