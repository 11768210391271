const apiConstantsKeys = {
  "Affiliate Manager": ["assignableBrands", "brands"],
  // 'Bonus Manager': ['riskSports'],
"Bonus Manager": [
    "riskSports",
    "countries",
    "promogroupfilters",
    "languages",
    "promotionFilters",
    "promotionRewards",
    "promotionRewardTypes",
    "promotionRewardBasis",
    "brands",
    "channels",
    "sports",
    "shops",
    "marketTypes",
    "betCombinationTypes",
    "betTypes",
    "jackpotBetList",
    "currencies",
  ],
  
"Combination Risk Manager": ["channels", "riskSports", "currencies"],
  
"Customer Manager": [
    "voidReasons",
    "cancelReasons",
    "closureReasons",
    "accountStatuses",
    "performancePeriods",
    "riskSports",
    "outcomeWagerLimitsActions",
    "playerProfiles",
    "languages",
    "countries",
    "referralMethods",
    "currencies",
    "securityQuestions",
    "origins",
    "lines",
    "packages",
    "priceFormats",
    "accountTypes",
    "priceFormatsLong",
    "wagerLimitsGroupsDescription",
  ],
  
  "Event Creator": ["riskSports", "countries", "templates", "tags", "placeTerms", "bookTypes", "lines"],

  Global: [
    "wagerLimitsGroups",
    "outcomeWagerLimitsActions",
    "liabilityIndicatorsActions",
    "voidReasons",
    "placeTerms",
    "cancelReasons",
    "closureReasons",
    "accountStatuses",
    "messageTriggers",
  ],

  "Instant Action": ["riskSports", "allOrigins", "channels", "brands"],

  "Operator Manager": [
    "accountStatuses",
    "currencies",
    "languages",
    "origins",
    "priceFormatsShort",
    "assignableBrands",
  ],

  "Payment Handler": ["currencies", "paymentStatus", "paymentColumns", "paymentRejectReasons"],

  "Results Verifier": ["riskSports"],

  "Risk Manager": ["riskColumns", "riskSports"],

  Utilities: ["riskSports", "tags"],
};

export default apiConstantsKeys;
