import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

let fetchAnalysisSummaryXhr = null;
let fetchBetsAnalysisXhr = null;
let fetchBetDataXhr = null;

export function fetchAnalysisSummary(key) {
  return new Promise((resolve, reject) => {
    const url = `/bo/analysis/summary/${key}`;

    if (fetchAnalysisSummaryXhr) {
      fetchAnalysisSummaryXhr.cancel("cancelled due to concurrent request");
    }

    fetchAnalysisSummaryXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchAnalysisSummaryXhr);
      fetchAnalysisSummaryXhr = null;
      resolve(response);
    } catch (err) {
      fetchAnalysisSummaryXhr = null;
      reject(err);
    }
    fetchAnalysisSummaryXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBetData(transactionId, transactionType) {
  return new Promise((resolve, reject) => {
    const dataType = transactionType || "betdata";
    const url = `/bo/analysis/${dataType}/${transactionId}`;

    if (fetchBetDataXhr) {
      fetchBetDataXhr.cancel("cancelled due to concurrent request");
    }

    fetchBetDataXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchBetDataXhr);
      fetchBetDataXhr = null;
      resolve(response);
    } catch (err) {
      fetchBetDataXhr = null;
      reject(err);
    }
    fetchBetDataXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBetsAnalysis(key, betType, fromDate, toDate) {
  return new Promise((resolve, reject) => {
    let url = `/bo/analysis/${betType}/${key}`;
    if (fromDate) {
      url = `${url}?fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }

    if (fetchBetsAnalysisXhr) {
      fetchBetsAnalysisXhr.cancel("cancelled due to concurrent request");
    }

    fetchBetsAnalysisXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchBetsAnalysisXhr);
      fetchBetsAnalysisXhr = null;
      resolve(response);
    } catch (err) {
      fetchBetsAnalysisXhr = null;
      reject(err);
    }
    fetchBetsAnalysisXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMultipleSummary(key) {
  return new Promise((resolve, reject) => {
    const url = `/bo/analysis/multiplesummary/${key}`;

    if (fetchBetsAnalysisXhr) {
      fetchBetsAnalysisXhr.cancel("cancelled due to concurrent request");
    }

    fetchBetsAnalysisXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchBetsAnalysisXhr);
      fetchBetsAnalysisXhr = null;
      resolve(response);
    } catch (err) {
      fetchBetsAnalysisXhr = null;
      reject(err);
    }
    fetchBetsAnalysisXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
