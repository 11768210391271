import actionTypes from "../constants";

const initialState = {
  abandoningMarketsFailed: null,
  changingStatusFailed: null,
  eventDetails: {},
  fetchingEventDetailsFailed: null,
  isAbandoningMarkets: null,
  isChangingStatus: null,
  isFetchingEventDetails: null,
  isFetchingMarketPeriodDetails: null,
  isFetchingMarketPeriodDetailsFailed: null,
  isUpdatingCutOffAndAutoOpenDateTime: false,
  isUpdatingCutOffAndAutoOpenDateTimeFailed: false,
  periodDetails: {},
  selectedEventId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.VIEW_MARKET_STATE_DETAILS:
      return { ...state, selectedEventId: action.id };
    case actionTypes.FETCH_EVENT_DETAILS:
      return { ...state, fetchingEventDetailsFailed: false, isFetchingEventDetails: true };
    case actionTypes.FETCH_EVENT_DETAILS_SUCCEEDED:
      const eventDetails = action.eventDetails;

      return { ...state, eventDetails, isFetchingEventDetails: false };
    case actionTypes.FETCH_EVENT_DETAILS_FAILED:
      return { ...state, eventDetails: {}, fetchingEventDetailsFailed: true, isFetchingEventDetails: false };
    case actionTypes.CHANGE_MARKETS_STATUS:
      return { ...state, changingStatusFailed: false, isChangingStatus: true };
    case actionTypes.CHANGE_MARKETS_STATUS_SUCCEEDED:
      return { ...state, isChangingStatus: false };
    case actionTypes.CHANGE_MARKETS_STATUS_FAILED:
      return { ...state, changingStatusFailed: true, isChangingStatus: false };
    case actionTypes.ABANDON_MARKETS:
      return { ...state, abandoningMarketsFailed: false, isAbandoningMarkets: true };
    case actionTypes.ABANDON_MARKETS_SUCCEEDED:
      return { ...state, isAbandoningMarkets: false };
    case actionTypes.ABANDON_MARKETS_FAILED:
      return { ...state, abandoningMarketsFailed: true, isAbandoningMarkets: false };
    case actionTypes.FETCH_MARKET_PERIOD_DETAILS:
      return { ...state, isFetchingMarketPeriodDetails: true, isFetchingMarketPeriodDetailsFailed: false };
    case actionTypes.FETCH_MARKET_PERIOD_DETAILS_SUCCEEDED:
      return { ...state, isFetchingMarketPeriodDetails: false, periodDetails: action.periodDetails };
    case actionTypes.FETCH_MARKET_PERIOD_DETAILS_FAILED:
      return { ...state, isFetchingMarketPeriodDetails: false, isFetchingMarketPeriodDetailsFailed: true };
    case actionTypes.UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME:
      return { ...state, isUpdatingCutOffAndAutoOpenDateTime: true, isUpdatingCutOffAndAutoOpenDateTimeFailed: false };
    case actionTypes.UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_SUCCEEDED:
      return { ...state, isUpdatingCutOffAndAutoOpenDateTime: false, response: action.response };
    case actionTypes.UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_FAILED:
      return { ...state, isUpdatingCutOffAndAutoOpenDateTime: false, isUpdatingCutOffAndAutoOpenDateTimeFailed: true };
    default:
      return { ...state };
  }
}
