import actionTypes, { modes } from "./constants";

const initialState = {
  activeTabIndex: 0,
  addedPaths: {},
  addingEventPathDetailsFailed: false,
  deleteEventPathError: null,
  deletingEventPathFailed: null,
  
  editedPaths: {},

  // EventPath Details
errorMessage: null,

  errorMessageTags: null,

  eventPathDetails: {
    comments: null,
    customAttributes: null,
    description: null,
    evetPathCode: null,
    feedCode: null,
    parentId: -1,
    publicise: false,
    publish: false,
    publishSort: null,
    suppressP2P: null,
    tag: null,
    tagId: -1,
  },

  eventPathMode: modes.VIEW,

  eventPathTagList: [
    {
      description: null,
      id: -1,
    },
  ],

  fetchingEventPathDetailsFailed: false,
  fetchingEventPathTagListFailed: false,
  isAddingEventPathDetails: false,
  isDeletingEventPath: null,
  isFetchingEventPathDetails: false,

  isFetchingEventPathTagList: false,
  isLoading: null,

  isSavingChanges: null,
  isSavingEventPathChanges: false,

  newEvent: null,
  savingChangesFailed: null,
  savingEventPathChangesFailed: false,
  selectedEventPathTag: {
    description: null,
    id: -1,
  },
  selectedPath: null,
};

const eventCreatorEventPath = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_PATH_TREE:
      return { ...state, addedPaths: {}, editedPaths: {} };
    case actionTypes.SET_EVENT_CREATOR_LOADING_VISIBILITY:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.SELECT_EVENT_CREATOR_PATH:
      return { ...state, selectedPath: action.path };
    case actionTypes.DELETE_EVENT_PATH:
    case actionTypes.DELETE_EVENT_PATHS:
    case actionTypes.DELETE_EVENT:
      return { ...state, deleteEventPathError: null, deletingEventPathFailed: false, isDeletingEventPath: true };
    case actionTypes.DELETE_EVENT_PATH_SUCCEEDED:
    case actionTypes.DELETE_EVENT_SUCCEEDED:
      return { ...state, isDeletingEventPath: false };
    case actionTypes.DELETE_EVENT_PATH_FAILED:
    case actionTypes.DELETE_EVENT_FAILED:
      return {
        ...state,
        deleteEventPathError: action.errorMessage,
        deletingEventPathFailed: true,
        isDeletingEventPath: false,
      };
    case actionTypes.CLEAR_DELETE_EVENT_PATH_ERRORS:
      return { ...state, deleteEventPathError: null, deletingEventPathFailed: false };
    case actionTypes.SAVE_REORDER:
      return { ...state, isSavingChanges: true, savingChangesFailed: false };
    case actionTypes.SAVE_REORDER_SUCCEEDED:
      return { ...state, isSavingChanges: false };
    case actionTypes.SAVE_REORDER_FAILED:
      return { ...state, isSavingChanges: false, savingChangesFailed: true };

    // Event Path Details
    case actionTypes.FETCH_EVENT_PATH_DETAILS:
      return { ...state, fetchingEventPathDetailsFailed: false, isFetchingEventPathDetails: true };
    case actionTypes.FETCH_EVENT_PATH_DETAILS_SUCCEEDED:
      return {
        ...state,
        errorMessage: null,
        eventPathDetails: action.eventPathDetails,
        isFetchingEventPathDetails: false,
      };
    case actionTypes.FETCH_EVENT_PATH_DETAILS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        fetchingEventPathDetailsFailed: true,
        isFetchingEventPathDetails: false,
      };

    case actionTypes.ADD_EVENT_PATH:
      return { ...state, addingEventPathDetailsFailed: false, isAddingEventPathDetails: true };
    case actionTypes.ADD_EVENT_PATH_SUCCEEDED:
      return {
        ...state,
        errorMessage: null,
        eventPathDetails: action.eventPathDetails,
        isAddingEventPathDetails: false,
        newEvent: action.eventPathDetails,
      };
    case actionTypes.ADD_EVENT_PATH_FAILED:
      return {
        ...state,
        addingEventPathDetailsFailed: true,
        errorMessage: action.errorMessage,
        isAddingEventPathDetails: false,
      };

    case actionTypes.EDIT_EVENT_PATH:
      return { ...state, isSavingEventPathChanges: true, savingEventPathChangesFailed: false };
    case actionTypes.EDIT_EVENT_PATH_SUCCEEDED:
      return {
        ...state,
        errorMessage: null,
        eventPathDetails: action.eventPathDetails,
        isSavingEventPathChanges: false,
      };
    case actionTypes.EDIT_EVENT_PATH_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isSavingEventPathChanges: false,
        savingEventPathChangesFailed: true,
      };

    case actionTypes.CLEAR_EVENT_PATH_DETAILS_ERROR:
      return {
        ...state,
        addingEventPathDetailsFailed: false,
        errorMessage: null,
        fetchingEventPathDetailsFailed: false,
        savingEventPathChangesFailed: false,
      };

    // Event Path Tags
    case actionTypes.FETCH_EVENT_PATH_TAGS:
      return { ...state, fetchingEventPathTagListFailed: false, isFetchingEventPathTagList: true };
    case actionTypes.FETCH_EVENT_PATH_TAGS_SUCCEEDED:
      return {
        ...state,
        errorMessageTags: null,
        eventPathTagList: action.eventPathTagList,
        isFetchingEventPathTagList: false,
      };
    case actionTypes.FETCH_EVENT_PATH_TAGS_FAILED:
      return {
        ...state,
        errorMessageTags: action.errorMessage,
        fetchingEventPathTagListFailed: true,
        isFetchingEventPathTagList: false,
      };

    case actionTypes.CLEAR_EVENT_PATH_TAGS_ERROR:
      return { ...state, errorMessageTags: null, fetchingEventPathTagListFailed: false };
    case actionTypes.SET_EVENT_PATH_ACTIVE_TAB_INDEX:
      return { ...state, activeTabIndex: action.activeTabIndex };
    case actionTypes.SET_EVENT_PATH_MODE:
      let newIndex = state.activeTabIndex;
      if (action.mode === modes.CREATE) {
        newIndex = 0;
      }

      return { ...state, activeTabIndex: newIndex, eventPathMode: action.mode };
    default:
      return { ...state };
  }
};

export default eventCreatorEventPath;
