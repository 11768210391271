const constants = {
  
  // Fetch All Market Types
FETCH_ALL_MARKET_TYPES: "UTL::FETCH_ALL_MARKET_TYPES",

  
  
FETCH_ALL_MARKET_TYPES_FAILED: "UTL::FETCH_ALL_MARKET_TYPES_FAILED",

  
FETCH_ALL_MARKET_TYPES_SUCCEEDED: "UTL::FETCH_ALL_MARKET_TYPES_SUCCEEDED",

  // Fetch All period Types
FETCH_ALL_PERIOD_TYPES: "UTL::FETCH_ALL_PERIOD_TYPES",

  FETCH_ALL_PERIOD_TYPES_FAILED: "UTL::FETCH_ALL_PERIOD_TYPES_FAILED",

  FETCH_ALL_PERIOD_TYPES_SUCCEEDED: "UTL::FETCH_ALL_PERIOD_TYPES_SUCCEEDED",

  FETCH_ALL_MAJOR_SORT: "MTE::FETCH_ALL_MAJOR_SORT",

  FETCH_ALL_SPORTS: "UTL::FETCH_ALL_SPORTS",

  FETCH_ALL_MAJOR_SORT_SUCCEEDED: "MTE::FETCH_ALL_MAJOR_SORT_SUCCEEDED",

  FETCH_ALL_SPORTS_FAILED: "UTL::FETCH_ALL_SPORTS_FAILED",
  
  FETCH_ALL_SPORTS_SUCCEEDED: "UTL::FETCH_ALL_SPORTS_SUCCEEDED",
  
FETCH_ALL_WAGER_LIMIT_GROUP: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP",

  
FETCH_ALL_WAGER_LIMIT_GROUP_FAILED: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP_FAILED",
  
FETCH_ALL_WAGER_LIMIT_GROUP_SUCCEEDED: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP_SUCCEEDED",
  
FETCH_MARKET_PERIOD_TYPES: "MTE::FETCH_MARKET_PERIOD_TYPES",

  
FETCH_MARKET_PERIOD_TYPES_FAILED: "MTE::FETCH_MARKET_PERIOD_TYPES_FAILED",
  
  
FETCH_MARKET_PERIOD_TYPES_SUCCEEDED: "MTE::FETCH_MARKET_PERIOD_TYPES_SUCCEEDED",
  
  
// Fetch Period Types per Sport
FETCH_PERIOD_TYPES: "UTL::FETCH_PERIOD_TYPES",

  

FETCH_PERIOD_TYPES_FAILED: "UTL::FETCH_PERIOD_TYPES_FAILED",

  

FETCH_PERIOD_TYPES_SUCCEEDED: "UTL::FETCH_PERIOD_TYPES_SUCCEEDED",

  

UNDO_FORM_TABLE_DATA: "UTL::UNDO_FORM_TABLE_DATA",

  
  

UNDO_MARKET_PERIOD_TYPES_TABLE: "MTE::UNDO_MARKET_PERIOD_TYPES_TABLE",

  

// UPDATE - UNDO FORM DATA LOCAL REDUCER
UPDATE_FORM_TABLE_DATA: "UTL::UPDATE_FORM_TABLE_DATA",

  

UPDATE_MAJOR_SORT: "MTE::UPDATE_MAJOR_SORT",

  

UPDATE_MAJOR_SORT_OPTION: "MTE::UPDATE_MAJOR_SORT_OPTION",
  
  
// UPDATE MARKET FORM
UPDATE_MARKET_FORM: "MTE::UPDATE_MARKET_FORM",
  

UPDATE_MARKET_PERIOD_TYPES: "MTE::UPDATE_MARKET_PERIOD_TYPES",

  

UPDATE_MARKET_PERIOD_TYPES_FAILED: "MTE::UPDATE_MARKET_PERIOD_TYPES_FAILED",
  

UPDATE_MARKET_PERIOD_TYPES_SUCCEEDED: "MTE::UPDATE_MARKET_PERIOD_TYPES_SUCCEEDED",

  

UPDATE_MARKET_PERIOD_TYPES_TABLE: "MTE::UPDATE_MARKET_PERIOD_TYPES_TABLE",
  
// UPDATE MARKET TYPES
UPDATE_MARKET_TYPES: "UTL::UPDATE_MARKET_TYPES",
  
UPDATE_MARKET_TYPES_FAILED: "MTE::UPDATE_MARKET_TYPES_FAILED",

  
UPDATE_MARKET_TYPES_SUCCEEDED: "MTE::UPDATE_MARKET_TYPES_SUCCEEDED",
  // UPDATE PERIOD TYPES
UPDATE_PERIOD_TYPES: "MTE::UPDATE_PERIOD_TYPES",
  UPDATE_PERIOD_TYPES_FAILED: "MTE::UPDATE_PERIOD_TYPES_FAILED",
  UPDATE_PERIOD_TYPES_SUCCEEDED: "MTE::UPDATE_PERIOD_TYPES_SUCCEEDED",
};

export default constants;
