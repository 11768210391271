import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchHurdles() {
  const url = `/bo/hurdles/marketcontrol`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAlerts() {
  const url = `/bo/alert-control/market-control`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveUpdatedHurdles(hurdles) {
  const url = `/bo/hurdles/marketcontrol`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, hurdles, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveUpdatedAlerts(alerts) {
  const url = `/bo/alert-control/save`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, alerts, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAlertsEventPaths() {
  const url = `/bo/service/eventpaths/TENN`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
