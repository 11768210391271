import originalAxios from "axios";

import api from "./api";

export function getNotifyAlerts(accountId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/operators/${accountId}/alerts`;

    try {
      const response = api.get(url);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function claimItemApi(data) {
  const { accountId, alertId } = data;

  return new Promise((resolve, reject) => {
    const url = `/bo/operators/${accountId}/alerts/${alertId}/claim`;

    try {
      const response = api.put(url, undefined);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function readItemApi(data) {
  const { accountId, alertId } = data;

  return new Promise((resolve, reject) => {
    const url = `/bo/operators/${accountId}/alerts/${alertId}/read`;

    try {
      const response = api.put(url, undefined);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function readAllItemApi(accountId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/operators/${accountId}/alerts/readall`;

    try {
      const response = api.put(url, undefined);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
