import _ from "lodash";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchPlayerProfileGroup(action) {
  const { response } = yield call(API.fetchPlayerProfileGroup, action);
  if (response) {
    yield put({ response, type: constants.FETCH_PLAYER_PROFILE_GROUP_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_PLAYER_PROFILE_GROUP_FAILED });
  }
}

function* updatePlayerProfileGroup(action) {
  const { response } = yield call(API.updatePlayerProfileGroup, action);
  if (response) {
    if (!_.isEmpty(response.deletionErrors)) {
      yield put({ deletionErrors: response.deletionErrors, type: constants.DELETION_FAILED });
    }
    // don't show success update alert if deletion errors has some message
    if (response.deletionErrors && Object.keys(response.deletionErrors).length > 0) {
    } else if (response.outcomeWagerLimitGroups) {
      toastr.add({ message: i18n.t(`Wager.Player profile group updated successfully`), type: "SUCCESS" });
    }
    yield put({ response, type: constants.ON_SAVE_PLAYER_GROUP_SUCCEEDED });
  } else {
    toastr.add({ message: i18n.t(`Wager.Player profile group not updated!`), type: "ERROR" });
    yield put({ type: constants.ON_SAVE_PLAYER_GROUP_FAILED });
  }
}

export default function* WagerLimitsPlayerProfileGroup() {
  yield takeLatest(constants.FETCH_PLAYER_PROFILE_GROUP, fetchPlayerProfileGroup);
  yield takeLatest(constants.ON_SAVE_PLAYER_GROUP, updatePlayerProfileGroup);
}
