import constants from "./constants";

const initialState = {
  add: false,
  backupOriginValidation: [],
  edit: false,
  formValues: {
    currencyId: "",
    description: "",
    lineId: "",
    originId: "",
  },
  isFetchingOriginValidation: false,
  msg: "",
  originValidation: [],
  selectedRow: "",
  view: false,
};

const originValidation = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ORIGIN_VALIDATION_MAPPING:
      return {
        ...state,
        isFetchingOriginValidation: true,
      };
    case constants.FETCH_ORIGIN_VALIDATION_MAPPING_SUCCEEDED:
      return {
        ...state,
        isFetchingOriginValidation: false,
        originValidation: action.payload,
      };
    case constants.OPEN_ADD_NEW:
      return {
        ...state,
        add: true,
        formValues: {
          currencyId: "",
          description: "",
          lineId: "",
          originId: "",
        },
      };
    case constants.CLOSE_ADD_NEW:
      return {
        ...state,
        add: false,
      };
    case constants.UPDATE_ADD_FORM:
      return {
        ...state,
        formValues: action.payload,
      };
    case constants.SELECT_ROW: {
      return {
        ...state,
        selectedRow: action.payload,
      };
    }
    case constants.UNSELECT_ROW: {
      return {
        ...state,
        selectedRow: "",
      };
    }
    case constants.DELETE_ORIGIN_VALIDATION_MAPPINGS_SUCCEEDED: {
      return {
        ...state,
        selectedRow: "",
      };
    }
    default:
      return { ...state };
  }

  return null;
};

export default originValidation;
