import constants from "../constants";

const initialState = {
  filteredPreferences: [],
  isFetchingPreferences: false,
  preferences: [],
  showPreferences: false,
};

const preferences = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PREFERENCES:
      return {
        ...state,
        isFetchingPreferences: true,
      };
    case constants.UPDATE_PREFERENCES:
      return {
        ...state,
        isFetchingPreferences: true,
      };
    case constants.RECEIVE_PREFERENCES:
      return {
        ...state,
        filteredPreferences: action.preferences,
        isFetchingPreferences: false,
        preferences: action.preferences,
      };
    case constants.TOGGLE_PREFERENCES:
      return {
        ...state,
        showPreferences: !state.showPreferences,
      };
    default:
      return state;
  }
};

export default preferences;
