const constants = {
  ADD_NEW_HURDLE_SEQUENCE: "UTL::ADD_NEW_HURDLE_SEQUENCE",
  CHANGE_SEQUENCE_DESIPTION: "UTL::CHANGE_SEQUENCE_DESIPTION",
  DELETE_HURDEL_SEQUENCE_FROM_LOCAL: "UTL::DELETE_HURDEL_SEQUENCE_FROM_LOCAL",
  DELETE_HURDEL_SEQUENCE_FROM_SERVER: "UTL::DELETE_HURDEL_SEQUENCE_FROM_SERVER",
  FETCH_HURDLE_ENTITIES: "UTL::FETCH_HURDLE_ENTITIES",
  FETCH_HURDLE_ENTITIES_SUCCEEDED: "UTL::FETCH_HURDLE_ENTITIES_SUCCEEDED",
  FETCH_HURDLE_SEQUENCE: "UTL::FETCH_HURDLE_SEQUENCE",
  FETCH_HURDLE_SEQUENCE_ASSOC: "UTL::FETCH_HURDLE_SEQUENCE_ASSOC",
  FETCH_HURDLE_SEQUENCE_ASSOC_FAILED: "UTL::FETCH_HURDLE_SEQUENCE_ASSOC_FAILED",
  FETCH_HURDLE_SEQUENCE_ASSOC_SUCCEEDED: "UTL::FETCH_HURDLE_SEQUENCE_ASSOC_SUCCEEDED",
  FETCH_HURDLE_SEQUENCE_FAILED: "UTL::FETCH_HURDLE_SEQUENCE_FAILED",
  FETCH_HURDLE_SEQUENCE_SUCCEEDED: "UTL::FETCH_HURDLE_SEQUENCE_SUCCEEDED",
  FETCH_SEQUENCE_TO_EXECUTE: "UTL::FETCH_SEQUENCE_TO_EXECUTE",
  FETCH_SEQUENCE_TO_EXECUTE_FAILED: "UTL::FETCH_SEQUENCE_TO_EXECUTE_FAILED",
  FETCH_SEQUENCE_TO_EXECUTE_SUCCEEDED: "UTL::FETCH_SEQUENCE_TO_EXECUTE_SUCCEEDED",
  MAKE_HURDLE_SEQUENCE_COPY: "UTL::MAKE_HURDLE_SEQUENCE_COPY",
  ON_ADD_NEW_ENTRIES: "UTL::ON_ADD_NEW_ENTRIES",
  ON_CHANGE_HURDLE_SEQUENCE_TO_EXEC: "UTL::ON_CHANGE_HURDLE_SEQUENCE_TO_EXEC",
  ON_MOVE_HURDLE_DOWN: "UTL::ON_MOVE_HURDLE_DOWN",
  ON_SELECT_ENTITIES: "UTL::ON_SELECT_ENTITIES",
  ON_SELECT_ENTRIES_TO_DELETE: "UTL::ON_SELECT_ENTRIES_TO_DELETE",
  REMOVE_SELECTED_ENTRIES: "UTL::REMOVE_SELECTED_ENTRIES",
  SELECT_SEQUENCE: "UTL::SELECT_SEQUENCE",
  SET_CURRENT_TAB: "UTL::SET_CURRENT_TAB",
  UNDO_HURDLE_CHANGES: "UTL::UNDO_HURDLE_CHANGES",
  UNDO_HURDLE_SEQUENCE_ASSOC: "UTL::UNDO_HURDLE_SEQUENCE_ASSOC",
  UPDATE_HURDLE_SEQUENCE: "UTL::UPDATE_HURDLE_SEQUENCE",
  UPDATE_HURDLE_SEQUENCE_ASSOC: "UTL::UPDATE_HURDLE_SEQUENCE_ASSOC",
};

export const tabs = {
  HURDLE_SEQUENCE: "HURDLE_SEQUENCE",
  HURDLE_SEQUENCE_ASSO: "HURDLE_SEQUENCE_ASSO",
};

export default constants;
