import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../constants";
import * as EditMarketService from "../services/editMarket";
import * as RiskDataService from "../services/riskData";
import { objectToArray, formatFilterDates } from "../utils";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { fetchEventMarkets } from "containersV2/SportsTree/actions";
import { getParentEvent } from "containersV2/SportsTree/helpers";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchEditMarketDetails(action) {
  const { response, xhr } = yield call(EditMarketService.fetchEditMarketDetails, action.marketId);
  if (response) {
    yield put({ marketDetails: response, type: actionTypes.FETCH_EDIT_MARKET_DETAILS_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.FETCH_EDIT_MARKET_DETAILS_FAILED });
  }
}

function* saveEditMarketChanges(action) {
  const { editMarketChanges, marketId } = action;
  var { response, xhr } = yield call(EditMarketService.saveEditMarketChanges, marketId, editMarketChanges);
  if (response) {
    yield delay(800);
    // get new edit market form details
    var { response, xhr } = yield call(EditMarketService.fetchEditMarketDetails, action.marketId);
    if (response) {
      // update risk data table
      let params = yield select((state) => state.riskParameters);
      params = { ...params, ...formatFilterDates(params.date), code: `m${marketId}` };
      const { chunk, error } = yield call(RiskDataService.fetchChunkRiskData, params);
      if (chunk.success || error) {
        yield put({ marketDetails: response, type: actionTypes.CLEAR_SPECIFIC_UNSAVED_OUTCOME_PRICE_CHANGES });
        yield put({ marketDetails: response, type: actionTypes.SAVE_EDIT_MARKET_CHANGES_SUCCEEDED });
        const { activePathId, parameters, pathsMap } = yield select((state) => state.sportsTree);
        const activePath = pathsMap[activePathId];
        if (activePath) {
          let eventId;
          const parentPath = pathsMap[activePath.parentId];
          if (parentPath.type === "event") {
            eventId = parentPath.id;
          } else if (parentPath.type === "market") {
            const event = getParentEvent({ event: null, path: pathsMap[parentPath.parentId], pathsMap });
            if (event) {
              eventId = event.id;
            }
          }
          if (eventId) {
            yield put(fetchEventMarkets(eventId, parameters));
          }
        }
        toastr.add({ message: `Market details successfully updated.` });
      } else {
        yield put({ type: actionTypes.SAVE_EDIT_MARKET_CHANGES_FAILED });
        const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
        toastr.add({ message: `Unable to update market details. ${msg}`, type: "ERROR" });
      }
    } else {
      yield put({ type: actionTypes.SAVE_EDIT_MARKET_CHANGES_FAILED });
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
      toastr.add({ message: `Unable to update market details. ${msg}`, type: "ERROR" });
    }
  } else {
    yield put({ type: actionTypes.SAVE_EDIT_MARKET_CHANGES_FAILED });
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `Unable to update market details. ${msg}`, type: "ERROR" });
  }
}

export default function* editMarketSaga() {
  yield takeLatest(actionTypes.FETCH_EDIT_MARKET_DETAILS, fetchEditMarketDetails);
  yield takeLatest(actionTypes.SAVE_EDIT_MARKET_CHANGES, saveEditMarketChanges);
}
