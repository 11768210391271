import { combineReducers } from "redux";

import utilitiesChannelUtility from "../Main/ChannelUtility/reducers";
import utilitiesEmergencyLockdown from "../Main/EmergencyLockDown/reducers";
import utilitiesExchangeRate from "../Main/ExchangeRate/reducers";
import utilitiesHurdleManagement from "../Main/HurdleManagementPage/reducers";
import utilitiesLineManagement from "../Main/LineManagement/reducers";
import utilitiesMarketControl from "../Main/MarketControl/reducers";
import utilitiesMarketTypeConfig from "../Main/MarketTypeConfig/reducers";
import utilitiesMarketTypeEnablement from "../Main/MarketTypeEnablement/reducers";
import originValidation from "../Main/OriginValidationMapping/reducers";
import utilitiesRiskDefaultManagement from "../Main/RiskDefaultManagement/reducers";
import utilitiesWagerLimit from "../Main/WagerLimit/reducers";

import constants, { utilsTreeMap, utilsURLMap } from "./constants";

const initialState = {
  activePathId: null,
  isFetchingUtilities: false,
  isFetchingUtilitiesFailed: false,
  // utilsTreeList: [1, 2, 3], // the outermost list
  utilsTreeList: [1, 3],
  utilsTreeMap,
  utilsURLMap,
};

const utilities = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_UTILITIES:
      return {
        ...state,
        isFetchingUtilities: true,
        isFetchingUtilitiesFailed: false,
      };
    case constants.FETCH_UTILITIES_SUCCEEDED:
      return {
        ...state,
        isFetchingUtilities: false,
      };
    case constants.FETCH_UTILITIES_FAILED:
      return {
        ...state,
        isFetchingUtilities: false,
        isFetchingUtilitiesFailed: true,
      };
    case constants.UPDATE_ACTIVE_PATH_ID:
      if (true) {
        // create block scope
        const currentActivePath = { ...state.utilsTreeMap[state.activePathId], isActive: false };
        const newActivePath = { ...state.utilsTreeMap[action.activePathId], isActive: true };
        if (!state.utilsTreeMap[action.activePathId]) {
          return {
            ...state,
            activePathId: action.activePathId,
            utilsTreeMap: { ...state.utilsTreeMap, [currentActivePath.id]: currentActivePath },
          };
        }
        const utilsTreeMap = {
          ...state.utilsTreeMap,
          [currentActivePath.id]: currentActivePath,
          [newActivePath.id]: newActivePath,
        };

        return {
          ...state,
          activePathId: action.activePathId,
          utilsTreeMap,
        };
      }
      break;
    case constants.UPDATE_PATH:
      if (true) {
        // create block scope
        const path = action.path;
        const id = path.id;

        return {
          ...state,
          utilsTreeMap: {
            ...state.utilsTreeMap,
            [id]: {
              ...state.utilsTreeMap[id],
              ...path,
            },
          },
        };
      }
      break;
    default:
      return { ...state };
  }

  return null;
};

export default combineReducers({
  originValidation,
  utilities,
  utilitiesChannelUtility,
  utilitiesEmergencyLockdown,
  utilitiesExchangeRate,
  utilitiesHurdleManagement,
  utilitiesLineManagement,
  utilitiesMarketControl,
  utilitiesMarketTypeConfig,
  utilitiesMarketTypeEnablement,
  utilitiesRiskDefaultManagement,
  utilitiesWagerLimit,
});
