import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const FeedHistoryXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchFeedHistoryLineup(eventId) {
  const url = `/bo/events/${eventId}/lineup?`;

  return performHttpCall(FeedHistoryXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchFeedHistoryFeeds(eventId, searchQuery) {
  const searchParams = searchQuery ? `filter=${searchQuery}` : "";
  const url = `/bo/events/${eventId}/feeds?${searchParams}`;

  return performHttpCall(FeedHistoryXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchFeedHistoryFeedXML(feedId) {
  const url = `/bo/events/feeds/${feedId}?`;

  return performHttpCall(FeedHistoryXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function parseFeedHistoryFeedXML(data) {
  const url = `/bo/feeds/parse?`;

  return performHttpCall(FeedHistoryXhr, httpMethods.HTTP_POST, url, data, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function processFeedHistoryFeedXML(data) {
  const url = `/bo/feeds/process?`;

  return performHttpCall(FeedHistoryXhr, httpMethods.HTTP_POST, url, data, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
