import _ from "lodash";
import moment from "moment";

import { formatFilterDates } from "utils";

const sortExRate = (response, baseCurrency) => {
  const sortedData = response.sort((a, b) => {
    const x = a.fromCurrencyDescription.toLowerCase();
    const y = b.fromCurrencyDescription.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }

    return 0;
  });
  const baseCurrencyIndex = _.findIndex(sortedData, (d) => d.fromCurrencyId == baseCurrency);
  sortedData.splice(baseCurrencyIndex, 1);

  return sortedData;
};

export function getNewlyAddedData(previousData, newData) {
  const newlyAdded = [];
  if (previousData && previousData.length) {
    _.map(newData, (item, index) => {
      const find = _.find(previousData, (o) => o.transactionId == item.transactionId);
      if (!find) {
        newlyAdded.push(item.transactionId);
      }
    });
  }

  return newlyAdded;
}

export function getFilteredData(gridData, filter) {
  const selectedDate = formatFilterDates(filter.selectedDate);
  if (
    gridData &&
    filter.selectStatus &&
    filter.selectStatus.length &&
    selectedDate &&
    selectedDate.fromDate &&
    selectedDate.toDate
  ) {
    const filteredData = [];
    _.map(gridData, (data, index) => {
      if (
        _.includes(filter.selectStatus, data.approvalStatusId) &&
        moment(data.paymentDate).isBetween(selectedDate.fromDate, selectedDate.toDate)
      ) {
        if (filter.selectedCurrency * 1 > 0) {
          const currencyCode = _.find(filter.currencies, (c) => c.id == filter.selectedCurrency * 1);
          if (data.currencyIso == currencyCode.isoCode) {
            filteredData.push(data);
          }
        } else {
          filteredData.push(data);
        }
      }
    });

    return filteredData;
  }

  return gridData;
}

export function isKeyExistInTableColumns(columns, key) {
  let ret = false;
  _.map(columns, (val) => {
    if (val.key === key) {
      ret = true;
    }
  });
  if (key === "accountId" || key === "approve" || key === "rejectionReason" || key === "transactionId") {
    ret = true;
  }

  return ret;
}

export default sortExRate;
