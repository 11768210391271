import _ from "lodash";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* getLiabilityIndicator(action) {
  const { response } = yield call(API.getLiability, action.path);
  if (response) {
    yield put({ response, type: constants.FETCH_LIABILITY_INDICATORS_SUCCEEDED });
  } else {
    // yield put({ type: constants.FETCH_LIABILITY_INDICATORS_FAILED })
  }
}

function* saveLiabilityIndicators(action) {
  const { response } = yield call(API.saveLiabilityIndicators, action);
  if (response) {
    yield put({ response, type: constants.SAVE_LIABILITY_INDICATORS_SUCCEEDED });
    yield put({ path: { id: action.data.id }, type: constants.FETCH_LIABILITY_INDICATORS });
    toastr.add({ message: i18n.t(`Wager.Liability Indicator updated successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.SAVE_LIABILITY_INDICATORS_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to update Liability Indicator`), type: "ERROR" });
  }
}

function* removeLiabilityIndicators(action) {
  const { response } = yield call(API.removeLiabilityIndicators, action);
  if (response) {
    yield put({ type: constants.ON_REMOVE_LIABILITY_INDICATOR_SUCCEEDED });
    yield put({ path: { id: action.selectedSport.id }, type: constants.FETCH_LIABILITY_INDICATORS });
    toastr.add({ message: i18n.t(`Wager.Liability Indicators deleted successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_REMOVE_LIABILITY_INDICATOR_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to remove Liability Indicators`), type: "ERROR" });
  }
}

export default function* WagerLimitsLiabilityIndicators() {
  yield takeLatest(constants.FETCH_LIABILITY_INDICATORS, getLiabilityIndicator);
  yield takeLatest(constants.SAVE_LIABILITY_INDICATORS, saveLiabilityIndicators);
  yield takeLatest(constants.REMOVE_LIABILITY_INDICATOR, removeLiabilityIndicators);
}
