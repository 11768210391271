import { put, call, fork, select, takeLatest } from "redux-saga/effects";

import actionTypes from "../../constants";

import i18n from "i18n";
import * as API from "services/customerManager";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* getChatHistory(action) {
  try {
    const { response, xhr } = yield call(API.getChatHistory, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_CHAT_HISTORY_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_CHAT_HISTORY_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_CHAT_HISTORY_FAILED });
  }
}

function* getChatSession(action) {
  try {
    const { response, xhr } = yield call(API.getChatSessions, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_CHAT_SESSION_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_CHAT_SESSION_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_CHAT_SESSION_FAILED });
  }
}

export default function* customerManagerChatSaga() {
  yield takeLatest(actionTypes.FETCH_CHAT_HISTORY, getChatHistory);
  yield takeLatest(actionTypes.FETCH_CHAT_SESSION, getChatSession);
}
