const opponentsConst = {
  

ADD_OPPONENT: "ADD_OPPONENT",

  



ADD_OPPONENT_FAILED: "ADD_OPPONENT_FAILED",

  



CLEAR_OPPONENT_KITS: "CLEAR_OPPONENT_KITS",

  
  


ADD_OPPONENT_SUCCEEDED: "ADD_OPPONENT_SUCCEEDED",

  
  

// https://lotuslabs.atlassian.net/browse/PHX-378
FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS: "FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS",

  


EDIT_OPPONENT: "EDIT_OPPONENT",

  


FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_FAILED: "FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_FAILED",

  
  

EDIT_OPPONENT_FAILED: "EDIT_OPPONENT_FAILED",

  

FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_SUCCEEDED: "FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_SUCCEEDED",

  

// https://lotuslabs.atlassian.net/browse/PHX-374
DELETE_ALL_OPPONENT_OF_EVENT_PATH: "DELETE_ALL_OPPONENT_OF_EVENT_PATH",

  

// https://lotuslabs.atlassian.net/browse/PHX-377
FETCH_EVENT_PATH_OPPONENTS_DETAILS: "FETCH_EVENT_PATH_OPPONENTS_DETAILS",

  

DELETE_ALL_OPPONENT_OF_EVENT_PATH_FAILED: "DELETE_ALL_OPPONENT_OF_EVENT_PATH_FAILED",

  

FETCH_EVENT_PATH_OPPONENTS_DETAILS_FAILED: "FETCH_EVENT_PATH_OPPONENTS_DETAILS_FAILED",

  

DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER: "DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER",

  

FETCH_EVENT_PATH_OPPONENTS_DETAILS_SUCCEEDED: "FETCH_EVENT_PATH_OPPONENTS_DETAILS_SUCCEEDED",

  

DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_FAILED: "DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_FAILED",

  

FETCH_KIT_PATTERNS: "FETCH_KIT_PATTERNS",

  

// https://lotuslabs.atlassian.net/browse/PHX-379
ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH: "ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH",

  


FETCH_MULTIPLE_OPPONENTS_DETAILS: "FETCH_MULTIPLE_OPPONENTS_DETAILS",

  


ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_FAILED: "ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_FAILED",

  


FETCH_MULTIPLE_OPPONENTS_DETAILS_FAILED: "FETCH_MULTIPLE_OPPONENTS_DETAILS_FAILED",

  


ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_SUCCEEDED: "ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_SUCCEEDED",

  


FETCH_MULTIPLE_OPPONENTS_DETAILS_SUCCEEDED: "FETCH_MULTIPLE_OPPONENTS_DETAILS_SUCCEEDED",

  
  


ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH: "ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH",

  


// https://lotuslabs.atlassian.net/browse/PHX-376
FETCH_OPPONENT_TYPES: "FETCH_OPPONENT_TYPES",
  
  


ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_FAILED: "ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_FAILED",

  



FETCH_OPPONENT_TYPES_FAILED: "FETCH_OPPONENT_TYPES_FAILED",
  
  


ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH: "ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH",

  


FETCH_OPPONENT_TYPES_SUCCEEDED: "FETCH_OPPONENT_TYPES_SUCCEEDED",

  


ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_FAILED: "ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_FAILED",

  


FETCH_SINGLE_OPPONENT_DETAILS: "FETCH_SINGLE_OPPONENT_DETAILS",

  


ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_SUCCEEDED: "ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_SUCCEEDED",

  


FETCH_SINGLE_OPPONENT_DETAILS_FAILED: "FETCH_SINGLE_OPPONENT_DETAILS_FAILED",

  


ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_SUCCEEDED:
    "ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_SUCCEEDED",

  


FETCH_SINGLE_OPPONENT_DETAILS_SUCCEEDED: "FETCH_SINGLE_OPPONENT_DETAILS_SUCCEEDED",

  
  

CHANGE_OPPONENT_GRADE: "CHANGE_OPPONENT_GRADE",

  

FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS: "FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS",

  

CHANGE_OPPONENT_GRADE_FAILED: "CHANGE_OPPONENT_GRADE_FAILED",

  

FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_FAILED: "FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_FAILED",

  

CHANGE_OPPONENT_GRADE_SUCCEEDED: "CHANGE_OPPONENT_GRADE_SUCCEEDED",

  

FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_SUCCEEDED: "FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_SUCCEEDED",

  

CREATE_AND_ASSIGN_OPPONENT: "CREATE_AND_ASSIGN_OPPONENT",
  

CREATE_AND_ASSIGN_OPPONENT_FAILED: "CREATE_AND_ASSIGN_OPPONENT_FAILED",
  
// https://lotuslabs.atlassian.net/browse/PHX-375
FETCH_OPPONENT_KITS: "FETCH_OPPONENT_KITS",

  
CREATE_AND_ASSIGN_OPPONENT_SUCCEEDED: "CREATE_AND_ASSIGN_OPPONENT_SUCCEEDED",
  //RESET EVENT PATH OPPONENTS
RESET_EVENT_PATH_OPPONENTS_DETAILS: "RESET_EVENT_PATH_OPPONENTS_DETAILS",
  CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH: "CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH",

  CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_FAILED: "CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_FAILED",
  FETCH_OPPONENT_KITS_FAILED: "FETCH_OPPONENT_KITS_FAILED",
  CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_SUCCEEDED: "CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_SUCCEEDED",

  FETCH_OPPONENT_KITS_SUCCEEDED: "FETCH_OPPONENT_KITS_SUCCEEDED",
  CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE: "CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE",
  FETCH_KIT_PATTERNS_FAILED: "FETCH_KIT_PATTERNS_FAILED",

  CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED: "CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED",
  FETCH_KIT_PATTERNS_SUCCEEDED: "FETCH_KIT_PATTERNS_SUCCEEDED",
  CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_SUCCEEDED: "CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_SUCCEEDED",

  DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_SUCCEEDED: "DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_SUCCEEDED",
  EDIT_OPPONENT_SUCCEEDED: "EDIT_OPPONENT_SUCCEEDED",
  DELETE_ALL_OPPONENT_OF_EVENT_PATH_SUCCEEDED: "DELETE_ALL_OPPONENT_OF_EVENT_PATH_SUCCEEDED",

  EDIT_OPPONENT_WITH_GRADE: "EDIT_OPPONENT_WITH_GRADE",
  EDIT_OPPONENT_WITH_GRADE_FAILED: "EDIT_OPPONENT_WITH_GRADE_FAILED",
  EDIT_OPPONENT_WITH_GRADE_SUCCEEDED: "EDIT_OPPONENT_WITH_GRADE_SUCCEEDED",

  UNASSIGN_OPPONENT_FROM_EVENT_PATH: "UNASSIGN_OPPONENT_FROM_EVENT_PATH",
  UNASSIGN_OPPONENT_FROM_EVENT_PATH_FAILED: "UNASSIGN_OPPONENT_FROM_EVENT_PATH_FAILED",
  UNASSIGN_OPPONENT_FROM_EVENT_PATH_SUCCEEDED: "UNASSIGN_OPPONENT_FROM_EVENT_PATH_SUCCEEDED",
};

export default opponentsConst;
