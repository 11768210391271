import { put, call, fork, select, takeLatest, takeEvery, delay, take } from "redux-saga/effects";

import actionTypes from "../constants";
import { AUTH_USER_UNAUTHORIZED } from "../redux/actions/auth-actions";
import { setAuthLanguage, login } from "../redux/slices/authSlice";
import { clearApiConstants, updateLanguage } from "../services/apiConstants";
import * as API from "../services/user";

import i18n from "i18n";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
}

function* userLogin(action) {
  const currentLang = yield select((state) => state.auth.language);
  updateLanguage(currentLang);

  try {
    yield put(login({ password: action.pw, username: action.uname })); // login

    yield take([login.fulfilled, login.rejected]); // wait for the previous step to resolve either way
    yield sleep(1); // wait 1 seconds - this is to allow the state to propagate to local storage, something we depend on due to the api wrapper

    const isLoggedIn = yield select((state) => state.auth.loggedIn); // is the user logged in?
    const accountId = yield select((state) => state.auth.accountId); // the user account Id, if logged in
    const error = yield select((state) => state.auth.error); // the user account Id, if logged in
    const authToken = yield select((state) => state.auth.authToken); // the user account token, if logged in

    if (isLoggedIn) {
      yield put({ modalName: "changePasswordModal", type: actionTypes.CLOSE_MODAL });
      yield put({
        // details: response,
        type: actionTypes.LOGIN_SUCCEEDED, // TODO - this is a duplicate of the authSlice login action type
      });
      // yield put({ id: accountId, type: actionTypes.FETCH_USER_INFORMATION });
      yield put({ modalName: "changePasswordModal", type: actionTypes.CLOSE_MODAL });
    } else {
      if (error === "Password expired.") {
        yield put({ modalName: "changePasswordModal", type: actionTypes.OPEN_MODAL });
      }
      yield put({ errMsg: error, type: actionTypes.LOGIN_FAILED }); // TODO - this is a duplicate of the authSlice login action type
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.LOGIN_FAILED }); // TODO - this is a duplicate of the authSlice login action type
  }
}

function* fetchUserInformation(action) {
  try {
    const { response, xhr } = yield call(API.fetchUserInformation, action.id);
    if (response) {
      yield put({ type: actionTypes.FETCH_USER_INFORMATION_SUCCEEDED, userInfo: response });
    } else {
    }
  } catch (e) {}
}

function* logout() {
  yield put({ type: AUTH_USER_UNAUTHORIZED });
}

function* changePassword(action) {
  try {
    const { response, xhr } = yield call(API.changePassword, action.accountId, action.formData);
    if (response) {
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message;
      yield put({ errMsg: msg, type: actionTypes.LOGIN_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.LOGIN_FAILED });
  }
}

function* changeLanguage(action) {
  clearApiConstants();
  yield put(setAuthLanguage({ language: action.lang }));
  yield put({ type: actionTypes.STARTUP });
}

export default function* userSaga() {
  yield takeLatest(actionTypes.LOGIN, userLogin);
  yield takeLatest(actionTypes.LOGOUT, logout);
  yield takeLatest(actionTypes.CHANGE_PASSWORD, changePassword);
  yield takeLatest(actionTypes.SELECTED_LANGUAGE, changeLanguage);
  yield takeLatest(actionTypes.FETCH_USER_INFORMATION, fetchUserInformation);
}
