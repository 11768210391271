import originalAxios from "axios";

import api from "services/api";
// import { objectToArray } from '../utils';

let reportsListXhr = null;
let reportFiltersXhr = null;

export function fetchReportsList() {
  return new Promise((resolve, reject) => {
    const url = `/bo/reports/assigned`;

    if (reportsListXhr) {
      reportsListXhr.cancel("cancelled due to concurrent request");
    }

    reportsListXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, reportsListXhr);
      reportsListXhr = null;
      resolve(response);
    } catch (err) {
      reportsListXhr = null;
      reject(err);
    }
    reportsListXhr = null;
  })
    .then((response) => ({ response: response.data?.assignedReports }))
    .catch((error) => ({ error }));
}

export function fetchReportFilters(folder, report) {
  return new Promise((resolve, reject) => {
    const url = `/bo/reports/${folder}/${report}`;

    if (reportFiltersXhr) {
      reportFiltersXhr.cancel("cancelled due to concurrent request");
    }

    reportFiltersXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, reportFiltersXhr);
      reportFiltersXhr = null;
      resolve(response);
    } catch (err) {
      reportFiltersXhr = null;
      reject(err);
    }
    reportFiltersXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function to2Digits(number) {
  return String(number).padStart(2, "0");
}

function getTimezone() {
  const offset = new Date().getTimezoneOffset() * -1;
  const offsetHours = offset / 60;
  const offsetMinutes = Math.abs(offset % 60);
  const timezoneHeader = `${Math.sign(offsetHours) >= 0 ? "+" : "-"}${to2Digits(offsetHours)}:${to2Digits(
    offsetMinutes,
  )}`;

  return timezoneHeader;
}

export function getReport(folder, report, format, filters, authToken) {
  const filtersArr = Object.keys(filters).map((filter) => `${filter}=${filters[filter]}`);

  const url = `/bo/reports/${folder}/${report}.${format}?originId=1&${filtersArr.join(
    "&",
  )}&token=${authToken}&timezone=${encodeURIComponent(getTimezone())}`;

  window.open(url, "_blank");
}
