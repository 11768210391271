import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

let fetchEditMarketDetailsXhr = null;
let saveEditMarketChangesXhr = null;

export function fetchEditMarketDetails(marketId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/editmarket/${marketId}`;

    if (fetchEditMarketDetailsXhr) {
      fetchEditMarketDetailsXhr.cancel("cancelled due to concurrent request");
    }

    fetchEditMarketDetailsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchEditMarketDetailsXhr);
      fetchEditMarketDetailsXhr = null;
      resolve(response);
    } catch (err) {
      fetchEditMarketDetailsXhr = null;
      reject(err);
    }
    fetchEditMarketDetailsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveEditMarketChanges(marketId, editMarketChanges) {
  return new Promise((resolve, reject) => {
    if (saveEditMarketChangesXhr) {
      saveEditMarketChangesXhr.abort();
    }
    const url = `/bo/editmarket/${marketId}`;
    const requestBody = editMarketChanges;

    if (saveEditMarketChangesXhr) {
      saveEditMarketChangesXhr.cancel("cancelled due to concurrent request");
    }

    saveEditMarketChangesXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, saveEditMarketChangesXhr);
      saveEditMarketChangesXhr = null;
      resolve(response);
    } catch (err) {
      saveEditMarketChangesXhr = null;
      reject(err);
    }
    saveEditMarketChangesXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
