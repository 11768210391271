import httpMethods from "../../../constants/httpMethods";
import { performHttpCall } from "../../../services/apiUtils";

import constants from "./constants";

const operatorManagerXHR = null;

export function fetchOperatorGroups() {
  const url = `/bo/operatorgroups`; // remove filter once in test

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchOperatorGroupsByStatus(id) {
  const url = `bo/operatorgroups?statusId=${id}`; // remove filter once in test

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addGroup(groupDetails) {
  const url = `/bo/operatorgroups`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_POST, url, groupDetails)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function duplicateGroup(groupid, groupName) {
  const url = `/bo/operatorgroups/${groupid}`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_POST, url, { description: groupName })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function editGroup(id, groupDetails) {
  const url = `/bo/operatorgroups/${id}`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_PUT, url, groupDetails)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteGroup(groupDetails) {
  const url = `/bo/operatorgroups/${groupDetails}`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_DELETE, url, null)
    .then((response) => response)
    .catch((xhr) => ({ xhr }));
}

export function addOperator(operatorDetails) {
  const url = `/bo/operators/create`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_POST, url, operatorDetails)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateOperator(id, operatorDetails) {
  const url = `/bo/operators/${id}`;

  const sanitizedOperatorDetails = { ...operatorDetails };
  delete sanitizedOperatorDetails.passwordExpiryDate;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_PUT, url, sanitizedOperatorDetails)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function duplicateOperator(id, operatorDetails) {
  const url = `/bo/operators/${id}`;

  const sanitizedOperatorDetails = { ...operatorDetails };
  delete sanitizedOperatorDetails.passwordExpiryDate;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_POST, url, sanitizedOperatorDetails)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
