import { put, call, fork, cancel, take, takeLatest, select } from "redux-saga/effects";

import actionTypes from "../constants";
import * as API from "../services/reportsServices";

import i18n from "i18n";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* fetchReportsList(action) {
  try {
    const { error, response } = yield call(API.fetchReportsList);
    if (response) {
      yield put({ reportsList: response, type: actionTypes.FETCH_REPORTS_LIST_SUCCEEDED });
    } else {
      yield put({ errMsg: error, type: actionTypes.FETCH_REPORTS_LIST_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.FETCH_REPORTS_LIST_FAILED });
  }
}

function* fetchReportFilters(action) {
  try {
    const { response, xhr } = yield call(API.fetchReportFilters, action.folder, action.report);

    if (response) {
      yield put({
        reportDetails: response,
        reportName: action.report,
        type: actionTypes.FETCH_REPORT_FILTERS_SUCCEEDED,
      });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message;
      yield put({ errMsg: msg, type: actionTypes.FETCH_REPORT_FILTERS_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.FETCH_REPORT_FILTERS_FAILED });
  }
}

function* fetchReportFiltersFlow(action) {
  // console.log(action);
  const fetchingTask = yield fork(fetchReportFilters, action);
  yield take("SET_REPORT_DETAILS");
  yield cancel(fetchingTask);
}

function* getReport(action) {
  const authToken = yield select((state) => state.auth.authToken);
  yield call(API.getReport, action.folder, action.report, action.format, action.filters, authToken);
}

export default function* reportsSaga() {
  yield takeLatest(actionTypes.FETCH_REPORTS_LIST, fetchReportsList);
  yield takeLatest(actionTypes.FETCH_REPORT_FILTERS, fetchReportFiltersFlow);
  yield takeLatest(actionTypes.GET_REPORT, getReport);
}
