import _ from "lodash";
import moment from "moment";

const constants = {
  FETCH_DEFAULT_CURRENCY: "UTL::FETCH_DEFAULT_CURRENCY",
  FETCH_DEFAULT_CURRENCY_FAILED: "UTL::FETCH_DEFAULT_CURRENCY_FAILED",
  FETCH_DEFAULT_CURRENCY_SUCCEEDED: "UTL::FETCH_DEFAULT_CURRENCY_SUCCEEDED",
  FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY: "UTL::FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY",
  FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_FAILED: "UTL::FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_FAILED",
  FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_SUCCEEDED: "UTL::FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_SUCCEEDED",
  SAVE_EXCHANGE_RATE: "UTL::SAVE_EXCHANGE_RATE",
  SAVE_EXCHANGE_RATE_FAILED: "UTL::SAVE_EXCHANGE_RATE_FAILED",
  SAVE_EXCHANGE_RATE_SUCCEEDED: "UTL::SAVE_EXCHANGE_RATE_SUCCEEDED",
  SET_CURRENT_DATE: "UTL::SET_CURRENT_DATE",
  UNDO_EXCHANGE_RATE: "UTL::UNDO_EXCHANGE_RATE",
  UPDATE_ROW: "UTL::UPDATE_ROW",
};

export const getModifiedExchangeRate = (exchangeRateModified, exchangeRate, exchangeRateDate) => {
  const date = moment(exchangeRateDate).format();
  let error;
  const filtered = _.map(exchangeRateModified, (item, index) => {
    const find = _.find(exchangeRate, (o) => o.id == item);
    if (Number(find.exchangeRate) == 0) {
      error = "Invalid input";
    }

    return {
      date,
      exchangeRate: Number(find.exchangeRate),
      fromCurrencyId: find.fromCurrencyId,
      id: find.id,
      toCurrencyId: find.toCurrencyId,
    };
  });

  return {
    error,
    filtered,
  };
};

export default constants;
