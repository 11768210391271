import { replace } from "react-router-redux";
import { put, call, select, takeLatest } from "redux-saga/effects";
import _ from "underscore";

import { updatePath, finalizePath, deletePath, deletePaths, fetchEPT } from "../../SportsTree/actions";
import { toggleBulkUpdate } from "../App/actions";

import { setEventPathMode } from "./actions";
import actionTypes, { modes } from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { setEventCreatorMode } from "containers/EventCreator/actions/eventCreatorModes";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

const appModes = {
  CREATE_MODE: "create",
  DELETE_MODE: "delete",
  EDIT_MODE: "edit",
  READ_MODE: "read",
};
// Event Path Tree

const errMsg = i18n.t("Event.Something went wrong while connecting to server, please try again later");
const deletePathBaseErrorMsg =
  `${i18n.t("Event.Failed to remove event path")}.` +
  ` ${i18n.t("Event.Please try to remove everything under the selected item first")}.` +
  ` ${i18n.t("Event.There might be events which are not visible in your current view")}.`;
function* deleteEventPath(action) {
  const { response, xhr } = yield call(API.deleteEventPath, action.eventPathId);
  if (response) {
    yield put({ deletedKey: `p${action.eventPathId}`, type: actionTypes.DELETE_EVENT_PATH_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Successfully deleted event path") });
    const { pathsMap } = yield select((state) => state.sportsTree);
    const path = pathsMap[action.eventPathId];
    const parentPath = pathsMap[path.parentId];
    yield put(replace(parentPath.url)); // replace history state with parent path's url
    yield put(deletePath(action.eventPathId));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    const errorMessage = `${deletePathBaseErrorMsg} ${msg}`;
    yield put({ errorMessage, type: actionTypes.DELETE_EVENT_PATH_FAILED });
    toastr.add({ message: errorMessage, type: "ERROR" });
  }
}

function* deleteEventPaths(action) {
  const { response, xhr } = yield call(API.deleteEventPaths, action.eventPathIds);
  if (response) {
    yield put({ type: actionTypes.DELETE_EVENT_PATH_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Successfully deleted event paths") });
    const { activePathId, pathsMap } = yield select((state) => state.sportsTree);
    if (action.eventPathIds.includes(activePathId)) {
      const path = pathsMap[activePathId];
      const parentPath = pathsMap[path.parentId];
      yield put(replace(parentPath.url)); // replace history state with parent path's url
    }
    yield put(deletePaths(action.eventPathIds));
    yield put(toggleBulkUpdate(false));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    const errorMessage = `${deletePathBaseErrorMsg} ${msg}`;
    yield put({ errorMessage, type: actionTypes.DELETE_EVENT_PATH_FAILED });
    toastr.add({ message: errorMessage, type: "ERROR" });
  }
}

function* deleteEvent(action) {
  const { response, xhr } = yield call(API.deleteEvent, action.eventId);
  if (response) {
    yield put({ deletedKey: `e${action.eventId}`, type: actionTypes.DELETE_EVENT_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    const errorMessage = `${i18n.t("Event.Failed to remove event")}. ${msg}`;
    yield put({ errorMessage, type: actionTypes.DELETE_EVENT_FAILED });
  }
}

function* saveReorder(action) {
  const { response } = yield call(API.saveReorder, action.printOrders);
  if (response) {
    yield put({ type: actionTypes.SAVE_REORDER_SUCCEEDED });
  } else {
    const errorMessage = i18n.t("Event.Failed to save ordering changes");
    yield put({ errorMessage, type: actionTypes.SAVE_REORDER_FAILED });
  }
}

// Event Path Details
function* fetchEventPathDetails(action) {
  const { eventPathId } = action;
  const { response, xhr } = yield call(API.fetchEventPathDetails, eventPathId);
  if (response) {
    yield put({ eventPathDetails: response, type: actionTypes.FETCH_EVENT_PATH_DETAILS_SUCCEEDED });
  } else {
    const parseResponse = JSON.parse(xhr.response);
    let msg;
    try {
      msg = parseResponse.errorMessage || parseResponse.exception.message;
    } catch (e) {
      msg = i18n.t("Event.Error fetching path details");
    }
    yield put({ errorMessage: msg, type: actionTypes.FETCH_EVENT_PATH_DETAILS_FAILED });
  }
}

function* addEventPath(action) {
  const { newEventPathDetails } = action;
  const { response, xhr } = yield call(API.addEventPath, newEventPathDetails);
  if (response) {
    // yield delay(3000); // why add delay?
    yield put({ eventPathDetails: response, type: actionTypes.ADD_EVENT_PATH_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Successfully created a new event path") });
    yield put(
      finalizePath(response.id, { description: response.description, id: response.id, parentId: response.parentId }),
    );
    const { activeSportId, parameters, pathsMap } = yield select((state) => state.sportsTree);
    const path = pathsMap[response.id];
    yield put(fetchEPT(activeSportId, parameters));
    yield put(replace(path.url));
    yield put(setEventPathMode(modes.VIEW));
    yield put(setEventCreatorMode(appModes.READ_MODE));
  } else {
    const parseResponse = JSON.parse(xhr.response);

    let msg = null;
    if (_.has(parseResponse, "errors")) {
      const messages = _.pluck(parseResponse.errors, "message");
      const messagesWithoutLast = _.initial(messages);
      msg = "";
      _.each(messagesWithoutLast, (message) => {
        msg += `${message}, `;
      });
      msg += _.last(messages);
    } else {
      msg = parseResponse.errorMessage || parseResponse.exception.message;
    }
    yield put({ errorMessage: msg, type: actionTypes.ADD_EVENT_PATH_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* editEventPath(action) {
  const { editedEventPathDetails, eventPathId } = action;
  const { response, xhr } = yield call(API.editEventPath, eventPathId, editedEventPathDetails);
  if (response) {
    yield put({ eventPathDetails: response, type: actionTypes.EDIT_EVENT_PATH_SUCCEEDED });
    yield put(updatePath(response.id, { description: response.description }));
    yield put(setEventPathMode(modes.VIEW));
    yield put(setEventCreatorMode(appModes.READ_MODE));
    toastr.add({ message: i18n.t("Event.Updated Successfully") });
  } else {
    // const parseResponse = JSON.parse(xhr.response);
    // const msg = parseResponse.errorMessage || parseResponse.exception.message;
    const parseResponseMsg = parseErrorMessageInXhr("HTTP_PUT", xhr);
    const msg = parseResponseMsg || errMsg;

    yield put({ errorMessage: msg, type: actionTypes.EDIT_EVENT_PATH_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

// Event Path Tags

function* fetchEventPathTags(action) {
  const { response, xhr } = yield call(API.fetchEventPathTags);
  if (response) {
    yield put({ eventPathTagList: response, type: actionTypes.FETCH_EVENT_PATH_TAGS_SUCCEEDED });
  } else {
    const parseResponse = JSON.parse(xhr.response);
    const msg = parseResponse.errorMessage || parseResponse.exception.message;
    yield put({ errorMessage: msg, type: actionTypes.FETCH_EVENT_PATH_TAGS_FAILED });
  }
}

export default function* eventCreatorPathTreeSaga() {
  yield takeLatest(actionTypes.FETCH_EVENT_PATH_DETAILS, fetchEventPathDetails);
  yield takeLatest(actionTypes.ADD_EVENT_PATH, addEventPath);
  yield takeLatest(actionTypes.EDIT_EVENT_PATH, editEventPath);
  yield takeLatest(actionTypes.FETCH_EVENT_PATH_TAGS, fetchEventPathTags);
  yield takeLatest(actionTypes.DELETE_EVENT_PATH, deleteEventPath);
  yield takeLatest(actionTypes.DELETE_EVENT_PATHS, deleteEventPaths);
  yield takeLatest(actionTypes.DELETE_EVENT, deleteEvent);
  yield takeLatest(actionTypes.SAVE_REORDER, saveReorder);
}
