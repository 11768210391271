import httpMethods from "../../../constants/httpMethods";
import { performHttpCall } from "../../../services/apiUtils";

import constants from "./constants";

const operatorManagerXHR = null;

export function fetchOperatorGroups(filterId) {
  const url = `/bo/operatorgroups?statusId=${filterId}`;

  return performHttpCall(operatorManagerXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const applicationsXHR = null;

export function fetchApplications(type, id) {
  const url = `/bo/${type}/${id}/applications`;

  return performHttpCall(applicationsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const actionsXHR = null;

export function fetchActions(type, id) {
  const url = `/bo/${type}/${id}/actions`;

  return performHttpCall(actionsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const reportsXHR = null;

export function fetchReports(operatorGroupId, operatorId) {
  operatorId = operatorId || -1;
  const url = `/bo/reports?groupOperatorId=${operatorGroupId}&operatorId=${operatorId}`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const submitDataXHR = null;

export function submitOperatorData(mode, contentType, data, id) {
  const httpMethod = mode === constants.FORM_MODE_EDIT ? httpMethods.HTTP_PUT : httpMethods.HTTP_POST;
  const operatorURL = contentType === constants.OPERATORGROUP ? "operatorgroups" : "operators";
  /// ///// TODO::::::::: need to add logic on sending data
  const url = `/bo/${operatorURL}/${id}`;

  return performHttpCall(reportsXHR, httpMethod, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export const exportOperatorsGroup = () => {
  const url = `/bo/operatorgroups/export`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => response)
    .catch((xhr) => ({ xhr }));
};

export const exportOperators = () => {
  const url = `/bo/operators/export`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => response)
    .catch((xhr) => ({ xhr }));
};

export const updateGroup = (groupid) => {
  const url = `/bo/operatorgroups/${groupid}`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
};

export const deleteRestrictionForGroup = () => {
  const url = `/bo/operatorgroups/fixed`;

  return performHttpCall(reportsXHR, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
};
