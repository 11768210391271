import channelConfig from "../configs/channelConfig";
import actionTypes from "../constants";

const initialState = {
  activeCode: null,
  eventIdsFailedFetchingMarkets: [],
  eventIdsFetchingMarkets: [],
  isLoadingTree: null,
  isLoadingTreeFailed: null,
  isTreeEmpty: null,
  markets: {},
  selectedSport: null,
  selectingSport: null,
  selectingSportFailed: null,
  tree: [],
};

function removeItemFromArray(item, array) {
  return array.filter((i) => i != item);
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_PATH_TREE:
      return { ...state, isLoadingTree: true, isLoadingTreeFailed: false };
    case actionTypes.FETCH_EVENT_PATH_TREE_SUCCEEDED:
      return { ...state, isLoadingTree: false, isLoadingTreeFailed: false, tree: action.tree };
    case actionTypes.FETCH_EVENT_PATH_TREE_FAILED:
      return { ...state, isLoadingTree: false, isLoadingTreeFailed: true };

    case actionTypes.SELECT_EVENT_PATH_TREE_SPORT:
      return { ...state, selectingSport: true, selectingSportFailed: false };
    case actionTypes.SELECT_EVENT_PATH_TREE_SPORT_SUCCEEDED:
      return { ...state, selectedSport: action.sport, selectingSport: false };
    case actionTypes.SELECT_EVENT_PATH_TREE_SPORT_FAILED:
      return { ...state, selectingSport: false, selectingSportFailed: true };

    case actionTypes.FETCH_EVENT_MARKETS:
      return {
        ...state,
        eventIdsFailedFetchingMarkets: removeItemFromArray(action.eventId, state.eventIdsFailedFetchingMarkets),
        eventIdsFetchingMarkets: [...state.eventIdsFetchingMarkets, action.eventId],
        markets: {
          ...state.markets,
          [action.eventId]: [],
        },
      };
    case actionTypes.FETCH_EVENT_MARKETS_SUCCEEDED:
      return {
        ...state,
        eventIdsFetchingMarkets: removeItemFromArray(action.eventId, state.eventIdsFetchingMarkets),
        markets: {
          ...state.markets,
          [action.eventId]: action.markets,
        },
      };
    case actionTypes.FETCH_EVENT_MARKETS_FAILED:
      return {
        ...state,
        eventIdsFailedFetchingMarkets: [...state.eventIdsFailedFetchingMarkets, action.eventId],
        eventIdsFetchingMarkets: removeItemFromArray(action.eventId, state.eventIdsFetchingMarkets),
      };
    case actionTypes.FETCH_EVENT_MARKETS_CANCEL:
      return {
        ...state,
        eventIdsFetchingMarkets: removeItemFromArray(action.eventId, state.eventIdsFetchingMarkets),
      };

    case actionTypes.SEARCH_EVENT_PATH_TREE_SUCCEEDED:
      return { ...state, selectedSport: action.result };

    case actionTypes.RESET_EVENT_PATH_TREE:
      return { ...initialState };
    default:
      return { ...state };
  }
}
