import { cloneDeep } from "lodash";
import moment from "moment";
import numeral from "numeral";
import _ from "underscore";

import channelConfig from "./configs/channelConfig";
import filterTypes from "./constants/filterTypes";

export function objectToArray(obj) {
  const arr = [];
  for (const key in obj) {
    arr.push(obj[key]);
  }

  return arr;
}

export function prettifyTime(dateString) {
  const hours = dateString.substr(6, 2);
  const minutes = dateString.substr(8, 2);
  const seconds = dateString.substr(10, 2);

  return `${hours}:${minutes}:${seconds}`;
}

export function getDaysDiffFromNow(then) {
  var format = format || "MMMM Do YYYY, h:mm a";
  const year = `20${then.substr(0, 2)}`;
  const month = then.substr(2, 2);
  const day = then.substr(4, 2);
  const hours = then.substr(6, 2);
  // var minutes = then.substr(8, 2)
  // var seconds = then.substr(10, 2)

  const then2 = moment(`${year}-${month}-${day}T${hours}`);
  const now = moment();

  return then2.diff(now, "days");
}

export function manipulateDate(dateTimeString, value, unit, format) {
  format = format || "MMMM Do YYYY, h:mm a";
  const year = `20${dateTimeString.substr(0, 2)}`;
  const month = dateTimeString.substr(2, 2);
  const day = dateTimeString.substr(4, 2);
  const hours = dateTimeString.substr(6, 2);
  const minutes = dateTimeString.substr(8, 2);
  const seconds = dateTimeString.substr(10, 2);

  return moment(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`).add(value, unit).format(format);
}

export function formatDecimalPrice(value) {
  return numeral(value).format("0,0.00");
}

export function formatCurrency(value, currencyCode) {
  currencyCode = currencyCode || "";

  return `${numeral(value).format("0,0.00")} ${currencyCode}`;
}

export function getDateFromTimeString(dateTimeString) {
  const year = `20${dateTimeString.substr(0, 2)}`;
  const month = dateTimeString.substr(2, 2);
  const day = dateTimeString.substr(4, 2);
  const hours = dateTimeString.substr(6, 2);
  const minutes = dateTimeString.substr(8, 2);

  return moment(`${year}-${month}-${day}T${hours}:${minutes}:00`);
}

export function formatDateTimeString(dateTimeString, format = "DD-MMM-YYYY, h:mm A", useTimezone) {
  const timeZoneOffset = channelConfig.timeZoneOffset;
  let result = getDateFromTimeString(dateTimeString);
  if (useTimezone) {
    result = result.add(timeZoneOffset, "hours");
  }

  return result.format(format);
}

export function formatISODateString(ISODateString, format) {
  format = format || "DD-MMM-YYYY, h:mm:ss a";
  let date = new Date(ISODateString);
  if (date.toString() === "Invalid Date") {
    // date returns invalid in safari when format is: 2017-05-30T18:30:00.000+0000
    // TODO: what if ISODateString timezone is not +0000?
    const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})([+|-]\d{4})$/;
    if (regex.test(ISODateString)) {
      const match = ISODateString.match(regex);
      date = new Date(match[1], match[2], match[3], match[4], match[5], match[6]);
    }
  }
  const timeZoneOffset = channelConfig.timeZoneOffset;
  if (timeZoneOffset >= 0) {
    return moment(date).format(format);
  }
  if (timeZoneOffset < 0) {
    return moment(date).format(format);
  }
}

export function getNext7DayText() {
  return `${moment().startOf("day").format("DD-MMM-YYYY")} to ${moment()
    .add(6, "days")
    .endOf("day")
    .format("DD-MMM-YYYY")}`;
}

export function getLast7DayText() {
  return `${moment().subtract(6, "days").format("DD-MMM-YYYY")} to ${moment().endOf("day").format("DD-MMM-YYYY")}`;
}

export function getLastMonthText() {
  return `${moment().subtract(1, "months").add(1, "days").format("DD-MMM-YYYY")} to ${moment()
    .endOf("day")
    .format("DD-MMM-YYYY")}`;
}

export function formatFilterDates(date) {
  const filterDateTypes = filterTypes.DATES;
  const filterPastDates = filterTypes.PAST_DATES;
  const filterOtherDates = filterTypes.OTHER_DATES;
  const filterTransactionDates = filterTypes.TRANSACTION_DATES;
  const filterJackpotBetDate = filterTypes.JACKPOT_BETS_FILTER;
  if (!date) {
    return {
      fromDate: null,
      toDate: null,
    };
  }
  switch (date) {
    case filterDateTypes.TODAY:
    case filterPastDates.TODAY:
      return {
        fromDate: moment().startOf("day").format(),
        toDate: moment().endOf("day").format(),
      };
    case filterDateTypes.TODAY_TO_FUTURE:
      return {
        fromDate: moment().startOf("day").format(),
        toDate: moment().add(99, "years").endOf("day").format(),
      };
    case filterOtherDates.EVENT_2HRS_AGO:
      return {
        fromDate: moment().subtract(2, "hours").format(),
        toDate: moment().endOf("day").format(),
      };
    case filterOtherDates.EVENT_4HRS_AGO:
      return {
        fromDate: moment().subtract(4, "hours").format(),
        toDate: moment().endOf("day").format(),
      };
    case filterDateTypes.ALL_DATES:
    case filterPastDates.ALL_DATES:
      return {
        fromDate: null,
        toDate: null,
      };
    case filterDateTypes.YESTERDAY:
    case filterPastDates.YESTERDAY:
      const yesterday = moment().add(-1, "days");

      return {
        fromDate: yesterday.startOf("day").format(),
        toDate: yesterday.endOf("day").format(),
      };
    case filterDateTypes.TOMORROW:
      const tomorrow = moment().add(1, "days");

      return {
        fromDate: tomorrow.startOf("day").format(),
        toDate: tomorrow.endOf("day").format(),
      };
    case filterDateTypes.FUTURE:
      const future = moment().add(2, "days");

      return {
        fromDate: future.startOf("day").format(),
        toDate: moment().add(99, "years").endOf("day").format(),
      };
    case filterDateTypes.NEXT_7_DAYS:
    case `${filterDateTypes.NEXT_7_DAYS} (${getNext7DayText()})`:
      return {
        fromDate: moment().startOf("day").format(),
        toDate: moment().add(6, "days").endOf("day").format(),
      };
    case filterOtherDates.LAST_7_DAYS:
    case `${filterOtherDates.LAST_7_DAYS} (${getLast7DayText()})`:
      return {
        fromDate: moment().subtract(6, "days").startOf("day").format(),
        toDate: moment().endOf("day").format(),
      };
    case filterPastDates.THIS_MONTH:
      return {
        fromDate: moment().startOf("month").format(),
        toDate: moment().endOf("month").format(),
      };
    case filterOtherDates.LAST_MONTH:
    case `${filterOtherDates.LAST_MONTH} (${getLastMonthText()})`:
      return {
        fromDate: moment().subtract(1, "months").add(1, "days").format(),
        toDate: moment().endOf("day").format(),
      };
    case filterPastDates.THIS_WEEK:
      return {
        fromDate: moment().startOf("week").add(1, "days").format(),
        toDate: moment().startOf("week").add(7, "days").format(),
      };
    case filterPastDates.THIS_QUARTER:
      return {
        fromDate: moment().startOf("quarter").format(),
        toDate: moment().startOf("quarter").format(),
      };
    case filterPastDates.THIS_YEAR:
      return {
        fromDate: moment().startOf("year").format(),
        toDate: moment().endOf("year").format(),
      };
    case filterTransactionDates.LAST_HOUR:
      return {
        fromDate: moment().subtract(1, "hours").format(),
        toDate: moment().format(),
      };
    case filterTransactionDates.LAST_24:
      return {
        fromDate: moment().subtract(24, "hours").format(),
        toDate: moment().format(),
      };
    case filterTransactionDates.LAST_48:
      return {
        fromDate: moment().subtract(48, "hours").format(),
        toDate: moment().format(),
      };
    case filterJackpotBetDate.NEXT_30_DAYS:
      return {
        fromDate: moment().startOf("day").format(),
        toDate: moment().add(30, "days").endOf("day").format(),
      };
    case `${filterTransactionDates.THIS_WEEK} (${moment().startOf("week").format("L")} to ${moment()
      .endOf("week")
      .format("L")})`:
      return {
        fromDate: moment().startOf("week").format(),
        toDate: moment().endOf("week").format(),
      };
    case `${filterTransactionDates.THIS_WEEK} (${moment().startOf("week").format("DD MMM YYYY")} to ${moment()
      .endOf("week")
      .format("DD MMM YYYY")})`:
      return {
        fromDate: moment().startOf("week").format(),
        toDate: moment().endOf("week").format(),
      };
    case `${filterTransactionDates.LAST_WEEK} (${moment().subtract(1, "w").startOf("week").format("L")} to ${moment()
      .subtract(1, "w")
      .endOf("week")
      .format("L")})`:
      return {
        fromDate: moment().subtract(1, "w").startOf("week").format(),
        toDate: moment().subtract(1, "w").endOf("week").format(),
      };
    case `${filterTransactionDates.LAST_WEEK} (${moment()
      .subtract(1, "w")
      .startOf("week")
      .format("DD MMM YYYY")} to ${moment().subtract(1, "w").endOf("week").format("DD MMM YYYY")})`:
      return {
        fromDate: moment().subtract(1, "w").startOf("week").format(),
        toDate: moment().subtract(1, "w").endOf("week").format(),
      };
    case `${filterTransactionDates.THIS_MONTH} (${moment().startOf("month").format("L")} to ${moment()
      .endOf("month")
      .format("L")})`:
      return {
        fromDate: moment().startOf("month").format(),
        toDate: moment().endOf("month").format(),
      };
    case `${filterTransactionDates.THIS_MONTH} (${moment().startOf("month").format("DD MMM YYYY")} to ${moment()
      .endOf("month")
      .format("DD MMM YYYY")})`:
      return {
        fromDate: moment().startOf("month").format(),
        toDate: moment().endOf("month").format(),
      };
    default:
      const splitDate = date.split(/\s*\-\s*/g);
      let dateFormat;
      if (
        moment(splitDate[0], "DD MMM YYYY").format("DD MMM YYYY") == splitDate[0] &&
        moment(splitDate[1], "DD MMM YYYY").format("DD MMM YYYY") == splitDate[1]
      ) {
        dateFormat = "DD MMM YYYY";
      } else {
        dateFormat = "L";
      }
      if (date.indexOf("-") > -1 /* && date.indexOf('/') > -1 */) {
        const from = moment(date.split(" - ")[0]).format("L");
        const to = moment(date.split(" - ")[1]).format("L");

        return {
          fromDate: moment(from, dateFormat).startOf("day").format(),
          toDate: moment(to, dateFormat).endOf("day").format(),
        };
      }
      break;
  }
}

export function formatNumber(numberString, noDecimal) {
  if (typeof numberString === "number") {
    numberString = numberString.toString();
  }
  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(\.)/g, ".");
  // added comma to the number format

  const parts = numberString.split(".");
  if (noDecimal) {
    return parts[0];
  }
  if (parts.length === 1) {
    numberString += ".00"; // adding decimal part
  } else if (parts[1].length === 1) {
    numberString += "0"; // 2 decimal precision
  }

  return numberString;
}

//* * Used to fix Results Viewer - the next method is used in multiple places and best not touched
export function getResultViewerMarketStatusFromFlags(flags) {
  const statusTypes = objectToArray(filterTypes.STANDARD_MARKET_STATUSES);

  if (!flags) {
    return "Open";
  }
  const sanitisedFlags = flags.map((flag) => flag.charAt(0).toUpperCase() + flag.slice(1).toLowerCase());

  const matchingStatus = statusTypes.filter((status) => sanitisedFlags.indexOf(status.desc) > -1);

  return matchingStatus[0] && matchingStatus[0].desc ? matchingStatus[0].desc : "Open";
}

export function getMarketStatusFromFlags(flags) {
  const statusTypes = objectToArray(filterTypes.STATUS);

  if (!flags) {
    return "Open";
  }
  const matchingStatus = statusTypes.filter((status) => flags.indexOf(status.desc.toLowerCase()) > -1);

  return matchingStatus[0] && matchingStatus[0].desc ? matchingStatus[0].desc : "Open";
}

export function isMarketsTotals(marketTypeGroup) {
  return ["TOTALS", "FIXED_TOTAL", "THREE_WAYS_TOTALS", "RANGE_TOTALS"].indexOf(marketTypeGroup) > -1;
}

export function formatOutcomeSpreads(spread, spread2) {
  if (typeof spread === "number" && spread !== null) {
    spread = spread.toString();
  }
  if (spread.indexOf("+") === -1 && spread !== "0" && spread.indexOf("-") === -1) {
    spread = `+${spread}`;
  }
  if (typeof spread2 === "number" && spread2 !== null) {
    spread2 = spread2.toString();
  }
  if (spread2.indexOf("+") === -1 && spread2 !== "0" && spread2.indexOf("-") === -1) {
    spread2 = `+${spread2}`;
  }

  return {
    spread,
    spread2,
  };
}

function mergeProperties(propertyKey, firstObject, secondObject, uniqueKey) {
  const propertyValue = firstObject[propertyKey];
  uniqueKey = uniqueKey || "id"; // unique keys are use to know whether which array item should be retained
  if (!secondObject || typeof secondObject[propertyKey] === "undefined") {
    return firstObject[propertyKey];
  }
  if (!firstObject || typeof firstObject[propertyKey] === "undefined") {
    return secondObject[propertyKey];
  }
  if (Object.prototype.toString.call(propertyValue) === "[object Array]") {
    // uniqueKeys
    const uniqueKeysFound = secondObject[propertyKey].map((item) => item[uniqueKey]);
    const filteredOldArray = firstObject[propertyKey].filter((item) => uniqueKeysFound.indexOf(item[uniqueKey]) === -1);

    return [...filteredOldArray, ...secondObject[propertyKey]];
  }
  if (typeof propertyValue === "object") {
    return mergeNestedObjects(firstObject[propertyKey], secondObject[propertyKey], uniqueKey);
  }

  return secondObject[propertyKey];
}

export function mergeNestedObjects(firstObject, secondObject, uniqueKey) {
  const finalObject = {};

  // Merge first object and its properties.
  for (const firstKey in firstObject) {
    finalObject[firstKey] = mergeProperties(firstKey, firstObject, secondObject, uniqueKey);
  }

  // Merge second object and its properties.
  for (const secondKey in secondObject) {
    finalObject[secondKey] = mergeProperties(secondKey, secondObject, firstObject, uniqueKey);
  }

  return finalObject;
}

export function addParametersToUrl(url, parameters) {
  let qs = "";
  const qsPairs = [];
  if (url.indexOf("?") < 0) {
    qs = "?";
  } else {
    // it means there is already a parameter
    qs = "&";
  }
  for (const parameter in parameters) {
    if (url.indexOf(parameter) < 0 && parameters[parameter] !== null) {
      qsPairs.push(`${parameter}=${parameters[parameter]}`);
    }
  }
  if (qsPairs.length) {
    qs += qsPairs.join("&");
  } else {
    return url;
  }

  return url + qs;
}

export function pruneNullOrUndefined(objectToPrune) {
  return _.omit(
    objectToPrune,
    _.filter(_.keys(objectToPrune), (key) => _.isUndefined(objectToPrune[key]) || _.isNull(objectToPrune[key])),
  );
}

export function arrayFindWhere(list, properties) {
  return _.findWhere(list, properties);
}

export function randomStr(num) {
  const m = num || 9;
  let s = "";
  const r = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < m; i++) {
    s += r.charAt(Math.floor(Math.random() * r.length));
  }

  return s;
}

export const parseString = (str) => {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {}

  return result;
};

export const saveInStorage = (key, data) => {
  if (typeof key === "string" && data) {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export const loadFromStorage = (key, skipParse) => {
  let result = null;
  if (typeof key === "string") {
    const str = localStorage.getItem(key);
    if (skipParse) {
      result = str;
    } else {
      result = parseString(str);
    }
  }

  return result;
};

/**
 * Converts Object into an Iterable
 * Author: Jay-R Pampellona
 * Iterable Object can be used in for...of and in array destructuring ie. [...iterableObject]
 * @param  {Object}  object                     [Object to be converted into Iterable]
 * @param  {Boolean} returnAsKeyValuePairObject
 * [if true, the value for each iteration will be an object with 'key' and 'value' props]
 * @return {Iterable Object}                    [An object converted into Iterable]
 */
export const makeIterable = (object, returnAsKeyValuePairObject = false) => {
  if (object instanceof Object) {
    const iterableObject = { ...object };
    iterableObject[Symbol.iterator] = function* () {
      const props = Object.keys(this);
      for (const key of props) {
        if (returnAsKeyValuePairObject) {
          yield { key, value: this[key] };
        } else {
          yield this[key];
        }
      }
    };

    return iterableObject;
  }
  throw new Error("Must be an Object instance.");
};

export const combineDateTime = (date, time, format = "MM/DD/YYYYTHH:mm") => {
  if (!date || !time) {
    return null;
  }
  const dateTime = `${moment(date).format("L")}T${time}`;

  return moment(dateTime, format).format();
};

export const selectValidProps = (array, objectProps) =>
  array.map((obj) =>
    objectProps.reduce((accu, prop) => {
      if (obj[prop] !== undefined && obj[prop] !== null) {
        accu[prop] = obj[prop];
      }

      return accu;
    }, {}),
  );

export const getMarketBookRuleKey = (book) => {
  let ruleKey = "";
  if (book.nonRunnerNoBet === true) {
    if (book.rule4) {
      ruleKey = "dayEvent";
    } else {
      ruleKey = "nonRunner";
    }
  } else if (book.rule4) {
    // what rule?
  } else {
    ruleKey = "allIn";
  }

  return ruleKey;
};

const removeWhiteSpaces = (str) => str.replace(/\s/g, "");
export const generatePlaceTerms = (placeTerms, book) => {
  const targetPlaceTerm = placeTerms.find(
    (term) => removeWhiteSpaces(term.description) === removeWhiteSpaces(book.placeTerms[0].description),
  );
  let placeTermsRequest = {
    deduction: 0,
    fixed: false,
    numPlaces: 0,
  };
  if (targetPlaceTerm) {
    // this should always have a value
    placeTermsRequest = {
      deduction: targetPlaceTerm.placeTerms.deduction,
      fixed: targetPlaceTerm.fixed,
      numPlaces: targetPlaceTerm.placeTerms.numPlaces,
    };
  }

  return placeTermsRequest;
};

const validBookProps = [
  "id",
  "bookType",
  "rule4DeductionEnabled",
  "nonRunnerNoBet",
  "currentBook",
  "createSpBook",
  "placeTermsRequest",
];
export const generateBookPayload = (book) =>
  selectValidProps([{ ...book, bookType: book.description, rule4DeductionEnabled: book.rule4 }], validBookProps)[0];

export const pathNameToArray = (pathName = "") => {
  let arr = [];
  pathName = pathName.replace(/^\/|\/$/g, "");
  if (pathName) {
    arr = pathName.split("/");
  }

  return arr;
};

export const parsePathName = (pathName = "") => {
  const [baseUrl, sportCode, path, section] = pathNameToArray(pathName);

  return { baseUrl, path, section, sportCode };
};

export const getActiveEventInSportsTree = (sportsTree) => {
  const { activePathId, pathsMap } = sportsTree;
  const { parentId } = pathsMap[activePathId];

  return pathsMap[parentId];
};

export const generatePeriodsTree = (periods) => {
  const namespace = {};
  const tree = [];
  periods.forEach((period) => {
    const currentPeriod = (namespace[period.id] = {
      ...period,
      children: [],
    });
    if (currentPeriod.parentId === 0) {
      tree.push(currentPeriod);
    } else if (namespace[currentPeriod.parentId]) {
      namespace[currentPeriod.parentId].children.push(currentPeriod);
    }
  });

  return tree.sort((a, b) => {
    const aIsInrunning = a.periodType.inRunning;
    const bIsInrunning = b.periodType.inRunning;
    if (aIsInrunning === bIsInrunning) {
      return 0;
    }
    if (aIsInrunning && !bIsInrunning) {
      return 1;
    }
    if (bIsInrunning && !aIsInrunning) {
      return -1;
    }
  });
};

export const getOpponentDetails = (opponentId, opponentsArray) => {
  let OpponentDetails = {};
  opponentsArray.forEach((opponent) => {
    if (opponent.id == opponentId) {
      OpponentDetails = opponent;
    }
  });

  return OpponentDetails;
};

export const inputNumberOnly = (e) => {
  const numberRegEx = new RegExp("^[0-9]+$");
  const input = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!numberRegEx.test(input)) {
    e.preventDefault();

    return false;
  }
};

export const inputNumberWithDecimalOnly = (e) => {
  const numberRegEx = new RegExp("^[0-9.]+$");
  const input = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!numberRegEx.test(input)) {
    e.preventDefault();

    return false;
  }
};

export const inputNumberWithMinusOnly = (e) => {
  const numberRegEx = new RegExp("^[0-9-]+$");
  const input = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!numberRegEx.test(input)) {
    e.preventDefault();

    return false;
  }
};

export const formatInputNumber = (e) => {
  const number = e.target.value.split(".");
  if (number[1] && number[1].length > 2) e.target.value = parseFloat(e.target.value).toFixed(2);
  else e.target.value = parseFloat(e.target.value).toFixed(2);
};

export const getCommaNumbers = (n) => {
  if (n !== null) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return null;
};
// data = ['age,averageBetSize,unitstake,country,currency,deposit,firstRegisteredUser,language,lastBetDate,numberOfBets,profit,sex,turnover]
export const translateStringArr = (data, t, val) =>
  data.map((x) => ({
    desc: t(`${val}.${x}`),
    value: x,
  }));

// data = [
//     { desc: 'All', value: -1 },
//     { desc: 'Jackpot Bets', value: 1 },
// ]
export const translateObjectArr = (data, t, translateKey, descKey, valKey) => {
  const accDesc = descKey || "desc";
  const accVal = valKey || "value";
  const translatedArr = [];
  !!data &&
    data.length > 0 &&
    data.map((x) => {
      let obj = {};
      obj = { ...x };
      obj[accDesc] = t(`${translateKey}.${x[accDesc]}`);
      obj[accVal] = x[accVal];
      translatedArr.push(obj);
    });

  return translatedArr;
};
