const constants = {
  FETCH_EMERGENCY_LOCKDOWN: "UTL::FETCH_EMERGENCY_LOCKDOWN",
  FETCH_EMERGENCY_LOCKDOWN_FAILED: "UTL::FETCH_EMERGENCY_LOCKDOWN_FAILED",
  FETCH_EMERGENCY_LOCKDOWN_SUCCEEDED: "UTL::FETCH_EMERGENCY_LOCKDOWN_SUCCEEDED",
  HANDLE_LOCK_UNLOCK: "UTL::HANDLE_LOCK_UNLOCK",
  TO_BE_LOCKED: "UTL::TO_BE_LOCKED",
  TO_BE_UNLOCKED: "UTL::TO_BE_UNLOCKED",
  UNDO_EMERGENCY_LOCKDOWN: "UTL::UNDO_EMERGENCY_LOCKDOWN",
  UPDATE_EMERGENCY_LOCKDOWN: "UTL::UPDATE_EMERGENCY_LOCKDOWN",
};

export default constants;
