import actionTypes from "../constants";

const initialState = {
  disconnectingMarketFromPriceFeedFailed: null,
  fetchingMarketFeedInfoFailed: null,
  isDisconnectingMarketFromPriceFeed: null,
  isFetchingMarketFeedInfo: null,
  linesConnected: [],
  linesToDisconnect: [],
  markets: [],
  marketsConnected: [],
  marketsToDisconnect: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_MARKET_FEED_INFO_MARKETS:
      return { ...state, markets: action.markets };
    case actionTypes.FETCH_MARKET_FEED_INFO:
      return { ...state, fetchingMarketFeedInfoFailed: false, isFetchingMarketFeedInfo: true };
    case actionTypes.FETCH_MARKET_FEED_INFO_SUCCEEDED:
      return {
        ...state,
        isFetchingMarketFeedInfo: false,
        linesConnected: action.linesConnected,
        marketsConnected: action.marketsConnected,
      };
    case actionTypes.FETCH_MARKET_FEED_INFO_FAILED:
      return { ...state, fetchingMarketFeedInfoFailed: true, isFetchingMarketFeedInfo: false };
    case actionTypes.TOGGLE_MARKET_TO_DISCONNECT:
      const marketIndex = state.marketsToDisconnect.indexOf(action.market);
      if (marketIndex === -1) {
        return { ...state, marketsToDisconnect: [...state.marketsToDisconnect, action.market] };
      }

      return {
        ...state,
        marketsToDisconnect: [
          ...state.marketsToDisconnect.slice(0, marketIndex),
          ...state.marketsToDisconnect.slice(marketIndex + 1),
        ],
      };

    case actionTypes.TOGGLE_LINE_TO_DISCONNECT:
      const lineIdIndex = state.linesToDisconnect.indexOf(action.line);
      if (lineIdIndex === -1) {
        return { ...state, linesToDisconnect: [...state.linesToDisconnect, action.line] };
      }

      return {
        ...state,
        linesToDisconnect: [
          ...state.linesToDisconnect.slice(0, lineIdIndex),
          ...state.linesToDisconnect.slice(lineIdIndex + 1),
        ],
      };

    case actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED:
      return { ...state, isDisconnectingMarketFromPriceFeed: true };
    case actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED_SUCCEEDED:
      return { ...state, isDisconnectingMarketFromPriceFeed: false };
    case actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED_FAILED:
      return { ...state, disconnectingMarketFromPriceFeedFailed: true, isDisconnectingMarketFromPriceFeed: false };
    case actionTypes.RESET_MARKET_FEED_INFO: {
      return { ...initialState };
    }
    default:
      return { ...state };
  }
}
