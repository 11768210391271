import originalAxios from "axios";
import _ from "underscore";

import httpMethods from "constants/httpMethods";
import api from "services/api";

export function performHttpCall(xhrObjReference, httpMethod, urlString, bodyContentObj, headers) {
  return new Promise((resolve, reject) => {
    if (xhrObjReference) {
      xhrObjReference.cancel("");
    }

    switch (httpMethod) {
      case httpMethods.HTTP_GET:
        xhrObjReference = originalAxios.CancelToken.source();

        try {
          let response = api.get(urlString, xhrObjReference);
          xhrObjReference = null;
          if (typeof response === "undefined") {
            response = { success: true }; // for succeeded calls with no content as a response
          }
          resolve(response);
        } catch (err) {
          xhrObjReference = null;
          reject(err);
        }
        xhrObjReference = null;

        break;

      case httpMethods.HTTP_POST:
        xhrObjReference = originalAxios.CancelToken.source();

        try {
          const response = api.post(urlString, bodyContentObj, xhrObjReference);
          xhrObjReference = null;
          resolve(response);
        } catch (err) {
          xhrObjReference = null;
          reject(err);
        }
        xhrObjReference = null;
        break;

      case httpMethods.HTTP_CSVPOST:
        xhrObjReference = originalAxios.CancelToken.source();

        try {
          const response = api.post(urlString, bodyContentObj, xhrObjReference);
          xhrObjReference = null;
          resolve(response);
        } catch (err) {
          xhrObjReference = null;
          reject(err);
        }
        xhrObjReference = null;
        break;

      case httpMethods.HTTP_PUT:
        xhrObjReference = originalAxios.CancelToken.source();

        try {
          const response = api.put(urlString, bodyContentObj, xhrObjReference);
          xhrObjReference = null;
          resolve(response);
        } catch (err) {
          xhrObjReference = null;
          reject(err);
        }
        xhrObjReference = null;

        break;

      case httpMethods.HTTP_DELETE:
        xhrObjReference = originalAxios.CancelToken.source();

        try {
          const response = api.delete(urlString, bodyContentObj, xhrObjReference);
          xhrObjReference = null;
          resolve(response);
        } catch (err) {
          xhrObjReference = null;
          reject(err);
        }
        xhrObjReference = null;

        break;
    }
  });
}

export const parseJSON = (str) => {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {}

  return result;
};

// Note, chain the following on the function consumer
// .then((response) => ({ response: response.data }))
// .catch((xhr) => ({ xhr }));

export function parseErrorMessageInXhr(httpMethod, xhr) {
  // TODO handle different possible response status code per http methods

  function parseGetResponse() {
    const { status } = xhr;

    switch (xhr.status) {
      case 0:
        return "Server Timeout!";

      // case 200: // OK
      // case 302: // FOUND
      // case 304: // NOT FOUND
      // case 401: // UNAUTHORIZED
      // case 404: // NOT FOUND
      case 422: // UNPROCCESS ENTITY
        return extract500Error();
      case 500: // INTERNAL SERVER ERROR
        return extract500Error();
    }
  }

  function parsePutOrPostResponse() {
    const { status } = xhr;

    switch (xhr.status) {
      case 0:
        return "Server Timeout!";

      // case 200: // OK
      // case 201: // CREATED
      // case 202: // ACCEPTED
      // case 204: // NO CONTENT

      case 400: // BAD REQUEST
        return extract400Error();
      case 422: // UNPROCCESS ENTITY
        return extract500Error();
      // case 401: // UNAUTHORIZED
      // case 404: // NOT FOUND

      case 500: // INTERNAL SERVER ERROR
        return extract500Error();

      // case 520: // VALIDATION ERROR
    }
  }

  function parseDeleteResponse() {
    const { status } = xhr;

    switch (status) {
      case 0:
        return "Server Timeout!";

      case 200: // OK
      case 401: // UNAUTHORIZED
      case 404: // NOT FOUND

      case 500: // INTERNAL SERVER ERROR
        return extract500Error();
    }
  }

  function extract400Error() {
    const { response } = xhr;

    const respObj = JSON.parse(response);
    if (respObj.error) {
      return respObj.error;
    }
    let msg = "";
    const messages = _.pluck(respObj.errors, "message");
    const messagesWithoutLast = _.initial(messages);
    _.each(messagesWithoutLast, (message) => {
      msg += `${message}, `;
    });
    msg += _.last(messages);

    return msg;
  }

  function extract500Error() {
    const { response } = xhr;
    const result = parseJSON(response);
    if (result) {
      return result.errors[0];
    }

    return "An unexpected error has occured.";
  }

  switch (httpMethod) {
    case httpMethods.HTTP_GET:
      return parseGetResponse();
      break;

    case httpMethods.HTTP_POST:
      return parsePutOrPostResponse();
      break;

    case httpMethods.HTTP_PUT:
      return parsePutOrPostResponse();
      break;

    case httpMethods.HTTP_DELETE:
      return parseDeleteResponse();
      break;
  }
}
