import moment from "moment";

const stakesFilter = [
  {
    desc: "All Stakes",
    gt: null,
    key: "all",
    lt: null,
  },
  {
    desc: "<= 25",
    gt: null,
    key: "lte25",
    lt: 26,
  },
  {
    desc: "> 25",
    gt: 25,
    key: "gt25",
    lt: null,
  },
  {
    desc: "> 50",
    gt: 50,
    key: "gt50",
    lt: null,
  },
  {
    desc: "> 100",
    gt: 100,
    key: "gt100",
    lt: null,
  },
  {
    desc: "> 200",
    gt: 200,
    key: "gt200",
    lt: null,
  },
  {
    desc: "> 500",
    gt: 500,
    key: "gt500",
    lt: null,
  },
];

const transactionTypeFilter = [
  {
    desc: "All Transaction Types",
    filter: null,
    key: "all",
  },
  {
    desc: "Bets",
    filter: "BET,CANCELLED_BET,TOTO_BET,CANCELLED_TOTO_BET",
    key: "bets",
  },
  {
    desc: "Adjustments",
    filter: "ADJUSTMENT",
    key: "adjustments",
  },
  {
    desc: "Transfers",
    filter: "INTERNAL_TRANSFER,EXTERNAL_TRANSFER",
    key: "transfers",
  },
  {
    desc: "Payment/Receipt",
    filter: "PAYMENTRECEIPT",
    key: "paymentreceipt",
  },
  {
    desc: "Taxed Transactions",
    filter: "TAXED",
    key: "taxed",
  },
  {
    desc: "Bonus Transactions",
    filter: "BONUS",
    key: "bonus",
  },
];

const transactionPermissions = {
  CUSTOMER_CARE_CANCEL_BET: 229,

  // CANCEL BET & BETSLIP
  CUSTOMER_CARE_CANCEL_BETSLIP: 1204,

  CUSTOMER_CARE_CANCEL_BET_UNSETTLED: 1261,

  // DELETE TRANSACTION
  CUSTOMER_CARE_DELETE_TRANSACTION: 824,

  CUSTOMER_CARE_VOID_BET: 226,

  // VOID BET & BETSLIP
  CUSTOMER_CARE_VOID_BETSLIP: 1205,

  CUSTOMER_CARE_VOID_BET_UNSETTLED: 1262,

  MANUAL_SETTLE_ALL_BETS: 227,

  MANUAL_SETTLE_UNSETTLED_BETS: 1260,

  OVERRIDE_CANCEL_TIME_LIMIT: 1009,

  OVERRIDE_CANCEL_TIME_LIMIT_UNSETTLED: 1264,
  OVERRIDE_VOID_TIME_LIMIT: 1008,
  OVERRIDE_VOID_TIME_LIMIT_UNSETTLED: 1263,
};

const transactionMessages = {
  CANCEL_BET: "Please specify a reason for this cancellation",
  DELETE_TRANSACTION: "Are you sure you want to delete this transaction? This process cannot be reversed.",
  UNSETTLE_BET: "Are you sure you want to unsettle this bet?",
  VOID_BET: "Are you sure you want to void this bet?",
  VOID_BETSLIP: "Please select a reason for voiding the bet",
};

export { stakesFilter, transactionTypeFilter, transactionPermissions, transactionMessages };
