import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchRiskcolumns() {
  const url = `/bo/riskcolumns/all`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchRiskDefaultcolumns() {
  const url = `/bo/riskcolumns/default/all`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveRiskDefaultManagement(dataToSave) {
  const url = `/bo/riskcolumns/default/save`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, dataToSave)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
