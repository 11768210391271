import _ from "lodash";


import { UPDATE_OPERATOR_SUCCEEDED, ADD_UPDATE_GROUP_SUCCEEDED } from "../OperatorList/constants";

import constants from "./constants";
import {
  generateReportsMap,
  generateReportsList,
  updateReportMap,
  sortReports,
  getReportsArray,
  countAssignedWithCheck,
} from "./helpers";

import { makeIterable } from "utils";

const initialState = {
  actionPermissionsMap: [],
  allActionPermissions: [],
  applicationPermissionsMap: [],
  assignedActionPermissions: [],
  assignedAndCheckedCount: 0,
  assignedApplicationPermissions: [],
  assignedReportsArray: [],

  assignedReportsList: [],
  isFetchingActionPermissions: false,
  isFetchingApplicationPermissions: false,
  isFetchingReportsPermissions: false,
  isModifiedActionPermission: false,
  isModifiedApplicationPermission: false,
  isModifiedReportPermission: false,
  isResettingModifiedPermissions: false,

  originalAssignedActionPermissions: [],
  originalAssignedApplicationPermissions: [],

  originalAssignedReportPermissions: [],
  originalReportsMap: {},
  originalUnassignedActionPermissions: [],
  originalUnassignedApplicationPermissions: [],
  reportsMap: {},
  selectedReportPermissions: [],
  unassignedActionPermissions: [],
  unassignedAndCheckedCount: 0,
  unassignedApplicationPermissions: [],

  unassignedReportsArray: [],
  unassignedReportsList: [],
};

const permissionPanel = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_ALL_PERMISSIONS:
      return {
        ...state,
        originalAssignedActionPermissions: state.assignedActionPermissions,
        originalAssignedApplicationPermissions: state.assignedApplicationPermissions,
        originalUnassignedActionPermissions: state.unassignedActionPermissions,
        originalUnassignedApplicationPermissions: state.unassignedApplicationPermissions,
      };
    case constants.FETCH_APPLICATIONS:
      return {
        ...state,
        isFetchingApplicationPermissions: true,
      };
    case constants.FETCH_APPLICATIONS_SUCCEEDED:
      const applications = [
        ...action.operatorApplications.unassignedApplications,
        ...action.operatorApplications.assignedApplications,
      ];
      const applicationPermissionsMap = [];

      applications.forEach((app) => {
        applicationPermissionsMap[app.applicationId] = app;
      });

      const assignedApplications = _.map(action.operatorApplications.assignedApplications, (app) => app.applicationId);
      const unassignedApplications = _.map(
        action.operatorApplications.unassignedApplications,
        (app) => app.applicationId,
      );

      return {
        ...state,
        applicationPermissionsMap,
        assignedApplicationPermissions: assignedApplications,
        isFetchingApplicationPermissions: false,
        originalAssignedApplicationPermissions: assignedApplications,
        originalUnassignedApplicationPermissions: unassignedApplications,
        unassignedApplicationPermissions: unassignedApplications,
      };
    case constants.FETCH_APPLICATIONS_FAILED:
      return {
        ...state,
        isFetchingApplicationPermissions: false,
      };

    case constants.SET_APPLICATION_PERMISSION_SELECTED:
      // return (state => {
      const currSelectedApps = [...action.assigned];
      const currAssignedActionPermission = [...state.assignedActionPermissions];
      let unassignedActionsListUpdate = _.filter(
        state.allActionPermissions,
        (action) =>
          currAssignedActionPermission.indexOf(action.id) === -1 &&
          currSelectedApps.indexOf(action.applicationId) !== -1,
      );
      unassignedActionsListUpdate = _.map(unassignedActionsListUpdate, (app) => app.id);

      return {
        ...state,
        assignedApplicationPermissions: action.assigned,
        isModifiedApplicationPermission: !_.isEqual(action.assigned, state.originalAssignedApplicationPermissions),
        unassignedActionPermissions: unassignedActionsListUpdate,
        unassignedApplicationPermissions: action.unassigned,
        // isSettingApplicationPermission : true,
      };
    // })(state)

    case constants.FETCH_ACTIONS:
      return {
        ...state,
        isFetchingActionPermissions: true,
      };

    case constants.FETCH_ACTIONS_SUCCEEDED:
      const actions = [...action.operatorActions.unassignedActions, ...action.operatorActions.assignedActions];
      const selectedApps = [...state.assignedApplicationPermissions];
      const actionPermissionsMap = [];

      actions.forEach((app) => {
        actionPermissionsMap[app.id] = app;
      });

      let unassignedActionsList = _.filter(
        action.operatorActions.unassignedActions,
        (action) => selectedApps.indexOf(action.applicationId) !== -1,
      );

      const assignedActions = _.map(action.operatorActions.assignedActions, (app) => app.id);
      const unassignedActions = _.map(action.operatorActions.unassignedActions, (app) => app.id);
      unassignedActionsList = _.map(unassignedActionsList, (app) => app.id);

      return {
        ...state,
        actionPermissionsMap,
        allActionPermissions: actions,
        assignedActionPermissions: assignedActions,
        isFetchingActionPermissions: false,
        originalAssignedActionPermissions: assignedActions,
        originalUnassignedActionPermissions: unassignedActionsList,
        unassignedActionPermissions: unassignedActionsList,
      };
    case constants.FETCH_ACTIONS_FAILED:
      return {
        ...state,
        isFetchingActionPermissions: false,
      };

    case constants.SET_ACTION_PERMISSION_SELECTED:
      return {
        ...state,
        assignedActionPermissions: action.assigned,
        isModifiedActionPermission: !_.isEqual(action.assigned, state.originalAssignedActionPermissions),
        unassignedActionPermissions: action.unassigned,
        // isSettingApplicationPermission : true,
      };

    case constants.FETCH_REPORTS:
      return {
        ...state,
        isFetchingReportsPermissions: true,
      };

    case constants.FETCH_REPORTS_SUCCEEDED:
      return ((state) => {
        const { assignedReports, unassignedReports } = action.operatorReports;
        const reportsMap = generateReportsMap({
          assigned: assignedReports,
          unassigned: unassignedReports,
        });
        const assignedReportsArray = getReportsArray({ group: "assigned", isSorted: true, reportsMap });

        return {
          ...state,
          assignedReportsArray,
          assignedReportsList: generateReportsList({ group: "assigned", reportsMap }),
          isFetchingReportsPermissions: false,
          isModifiedReportPermission: false,
          operatorReports: action.operatorReports,
          originalAssignedReportPermissions: [...assignedReportsArray],
          originalReportsMap: { ...reportsMap },
          reportsMap,
          unassignedReportsArray: getReportsArray({ group: "unassigned", reportsMap }),
          unassignedReportsList: generateReportsList({ group: "unassigned", reportsMap }),
        };
      })(state);
    case constants.FETCH_REPORTS_FAILED:
      return {
        ...state,
        isFetchingReportsPermissions: false,
      };

    case constants.UPDATE_ACTION_PERMISSION_LIST_BOX:
      return {
        ...state,
        isFetchingApplicationActionPermissions: true,
      };

    case constants.UPDATE_ACTION_PERMISSION_LIST_BOX_SUCEEDED:
      return {
        ...state,
        isFetchingApplicationActionPermissions: false,
        operatorApplicationActions: action.response,
      };

    case constants.UPDATE_ACTION_PERMISSION_LIST_BOX_FAILED:
      return {
        ...state,
        isFetchingApplicationActionPermissions: false,
      };

    case constants.SET_REPORT_PERMISSIONS:
      return {
        ...state,
        isSettingReportPermission: true,
      };
    case constants.SET_REPORT_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        isModifiedReportPermission: !_.isEqual(action.selected, state.operatorReports.assignedReports),
        isSettingReportPermission: false,
        selectedReportPermissions: action.selected,
      };
    case constants.SET_REPORT_PERMISSIONS_FAILED:
      return {
        ...state,
        isSettingReportPermission: false,
      };

    case constants.SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED:
      return {
        ...state,
        isSettingOriginalApplicationPermission: true,
      };
    case constants.SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_SUCEEDED:
      return {
        ...state,
        isSettingOriginalApplicationPermission: false,
        originalApplicationPermissions: action.options,
      };
    case constants.SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_FAILED:
      return {
        ...state,
        isSettingOriginalApplicationPermission: false,
      };

    case constants.UPDATE_DEFAULT_SELECTED_PERMISSIONS:
      return {
        ...state,
        isModifiedActionPermission: false,
        isModifiedApplicationPermission: false,
        isModifiedReportPermission: false,
        selectedApplicationPermissions: state.selectedApplicationPermissions,
        selectedPermissions: state.selectedPermissions,
        selectedReportPermissions: state.selectedReportPermissions,
      };

    case constants.RESET_MODIFIED_PERMISSIONS:
      return ((state) => {
        const reportsMap = { ...state.originalReportsMap };

        return {
          ...state,
          assignedActionPermissions: state.originalAssignedActionPermissions,
          assignedAndCheckedCount: 0,
          assignedApplicationPermissions: state.originalAssignedApplicationPermissions,
          assignedReportsArray: [...state.originalAssignedReportPermissions],
          assignedReportsList: generateReportsList({ group: "assigned", reportsMap }),
          isModifiedActionPermission: false,
          isModifiedApplicationPermission: false,
          isModifiedReportPermission: false,
          reportsMap,
          unassignedActionPermissions: state.originalUnassignedActionPermissions,
          unassignedAndCheckedCount: 0,
          unassignedApplicationPermissions: state.originalUnassignedApplicationPermissions,
          unassignedReportsArray: getReportsArray({ group: "unassigned", reportsMap }),
          unassignedReportsList: generateReportsList({ group: "unassigned", reportsMap }),
        };
      })(state);

    case constants.UPDATE_RESET_MODIFIED_PERMISSIONS:
      return {
        ...state,
        assignedActionPermissions: state.assignedActionPermissions,
        assignedApplicationPermissions: state.assignedApplicationPermissions,
        isModifiedActionPermission: false,
        isModifiedApplicationPermission: false,
        unassignedActionPermissions: state.unassignedActionPermissions,
        unassignedApplicationPermissions: state.unassignedApplicationPermissions,
        // selectedReportPermissions : state.operatorReports.assignedReports,
        // isModifiedReportPermission : false
      };

    case constants.UPDATE_REPORT: // toggle collapse/expand
      return {
        ...state,
        reportsMap: updateReportMap({ report: action.report, reportsMap: state.reportsMap }),
      };
    case constants.UPDATE_REPORTS: // check/uncheck of report/s
      return ((state) => {
        let reportsMap = { ...state.reportsMap };
        action.reports.forEach((report) => {
          reportsMap = updateReportMap({ report, reportsMap });
        });

        return {
          ...state,
          assignedAndCheckedCount: countAssignedWithCheck({ group: "assigned", reportsMap }),
          reportsMap,
          unassignedAndCheckedCount: countAssignedWithCheck({ group: "unassigned", reportsMap }),
        };
      })(state);

    case constants.MOVE_REPORTS:
      return ((state) => {
        const reportsMap = { ...state.reportsMap };
        let from = "unassigned";
        let to = "assigned";
        let assignedAndCheckedCount = countAssignedWithCheck({ group: "assigned", reportsMap });
        let unassignedAndCheckedCount = countAssignedWithCheck({ group: "unassigned", reportsMap });
        if (action.direction === "left") {
          from = "assigned";
          to = "unassigned";
          assignedAndCheckedCount = 0; // checked reports are cleared upon moving of reports
        } else {
          unassignedAndCheckedCount = 0; // checked reports are cleared upon moving of reports
        }
        action.reports.forEach((report) => {
          reportsMap[report.key] = {
            ...report,
            [`isChecked_${from}`]: false,
            [`isExpanded_${from}`]: false,
          };
          if (report.parentKey) {
            const parentReport = reportsMap[report.parentKey];
            if (!parentReport[to].includes(report.key)) {
              parentReport[to] = [...parentReport[to], report.key];
            }
            if (parentReport && parentReport.parentKey) {
              // level 2 report
              // add the parentReport's key to grand parent's as well
              const grandparentReport = reportsMap[parentReport.parentKey];
              if (!grandparentReport[to].includes(parentReport.key)) {
                grandparentReport[to] = [...grandparentReport[to], parentReport.key];
              }
            }
            parentReport[from] = parentReport[from].filter((key) => key !== report.key);
          }
        });
        const assignedReportsArray = getReportsArray({ group: "assigned", isSorted: true, reportsMap });
        const unassignedReportsArray = getReportsArray({ group: "unassigned", reportsMap });

        return {
          ...state,
          assignedAndCheckedCount,
          assignedReportsArray,
          assignedReportsList: generateReportsList({ group: "assigned", reportsMap }),
          isModifiedReportPermission: !_.isEqual(assignedReportsArray, state.originalAssignedReportPermissions),
          reportsMap: sortReports(reportsMap),
          unassignedAndCheckedCount,
          unassignedReportsArray,
          unassignedReportsList: generateReportsList({ group: "unassigned", reportsMap }),
        };
      })(state);

    case UPDATE_OPERATOR_SUCCEEDED:
    case ADD_UPDATE_GROUP_SUCCEEDED:
      return {
        ...state,
        isModifiedReportPermission: false,
        originalAssignedActionPermissions: [...state.assignedActionPermissions],
        originalAssignedApplicationPermissions: [...state.assignedApplicationPermissions],
        originalAssignedReportPermissions: [...state.assignedReportsArray],
        originalReportsMap: { ...state.reportsMap },
      };
    default:
      return { ...state };
  }
};

export default permissionPanel;
