import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* wordSearch(action) {
  const { response, xhr } = yield call(API.onWordSearch, action);
  if (response) {
    yield put({ results: response, type: constants.FETCH_SEARCH_TEXT_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SEARCH_TEXT_DATA_FAILED });
  }
}

function* getAllLocales(action) {
  const { response, xhr } = yield call(API.onGetAllLocales);
  if (response) {
    yield put({ results: response, type: constants.FETCH_ALL_LOCALES_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_LOCALES_DATA_FAILED });
  }
}

function* setLanguagePreference(action) {
  const { response, xhr } = yield call(API.setAllLocales, action);
  if (response) {
    yield put({ type: constants.FETCH_ALL_LOCALES_DATA });
  } else {
    // yield put({ type: constants.FETCH_ALL_LOCALES_DATA_FAILED })
  }
}

function* addBaseVocab(action) {
  const { response, xhr } = yield call(API.onAddBaseVocab, action);
  if (response) {
    yield put({ results: response, type: constants.ADDING_BASE_VOCAB_WORD_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Translate.New base vocab added successfully")}.`, type: "SUCCESS" });
  } else {
    yield put({ type: constants.ADDING_BASE_VOCAB_WORD_FAILED });
    toastr.add({ message: `${i18n.t("Translate.Unable to add new base vocab")}.`, type: "ERROR" });
  }
}

function* deleteBaseTranslations(action) {
  const { response, xhr } = yield call(API.onDeleteBaseTranslations, action);
  if (response) {
    yield put({ results: response, type: constants.DELETE_BASE_TRANSLATIONS_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Translate.Base translations deleted")}.`, type: "SUCCESS" });
  } else {
    yield put({ type: constants.DELETE_BASE_TRANSLATIONS_FAILED });
    toastr.add({ message: `${i18n.t("Translate.Unable to delete  base translations")}.`, type: "ERROR" });
  }
}

function* updateBaseVocab(action) {
  const { response, xhr } = yield call(API.onUpdateBaseVocab, action);
  if (response) {
    yield put({ results: response, type: constants.UPDATE_BASE_VOCAB_WORD_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Translate.Base vocab updated successfully")}.`, type: "SUCCESS" });
  } else {
    yield put({ type: constants.UPDATE_BASE_VOCAB_WORD_FAILED });
    toastr.add({ message: `${i18n.t("Translate.Unable to update base vocab")}.`, type: "ERROR" });
  }
}

function* debugWordSreach(action) {
  const { response, xhr } = yield call(API.onDebugWordSreach, action);
  if (response) {
    yield put({ results: response, type: constants.DEBUG_WORD_SEARCH_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ type: constants.DEBUG_WORD_SEARCH_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

export default function* TranslationManager() {
  yield takeLatest(constants.FETCH_SEARCH_TEXT_DATA, wordSearch);
  yield takeLatest(constants.FETCH_ALL_LOCALES_DATA, getAllLocales);
  yield takeLatest(constants.ADDING_BASE_VOCAB_WORD, addBaseVocab);
  yield takeLatest(constants.DELETE_BASE_TRANSLATIONS, deleteBaseTranslations);
  yield takeLatest(constants.UPDATE_BASE_VOCAB, updateBaseVocab);
  yield takeLatest(constants.DEBUG_WORD_SEARCH, debugWordSreach);
  yield takeLatest(constants.SET_LOCALES_PREFERENCE, setLanguagePreference);
}
