import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

function* getInitialBetsData() {
  const { response, xhr } = yield call(API.fetchInitialBetsData);
  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_BETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_BETS_DATA_FAILED });
  }
}

function* getSubsequentBetsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentBetsData, action.lastKey);
  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_BETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_BETS_DATA_FAILED });
  }
}

function* getInitialAccountsData() {
  const { response, xhr } = yield call(API.fetchInitialAccountsData);

  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_ACCOUNTS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_ACCOUNTS_DATA_FAILED });
  }
}

function* getSubsequentAccountsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentAccountsData, action.lastKey);

  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_ACCOUNTS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_ACCOUNTS_DATA_FAILED });
  }
}

function* getInitialPaymentsData() {
  const { response, xhr } = yield call(API.fetchInitialPaymentsData);

  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_PAYMENTS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_PAYMENTS_DATA_FAILED });
  }
}

function* getSubsequentPaymentsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentPaymentsData, action.lastKey);

  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_PAYMENTS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_PAYMENTS_DATA_FAILED });
  }
}

function* getInitialFailedBetsData() {
  const { response, xhr } = yield call(API.fetchInitialFailedBetsData);

  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_FAILEDBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_FAILEDBETS_DATA_FAILED });
  }
}

function* getSubsequentFailedBetsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentFailedBetsData, action.lastKey);

  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_FAILEDBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_FAILEDBETS_DATA_FAILED });
  }
}

function* getInitialCancelledBetsData() {
  const { response, xhr } = yield call(API.fetchInitialCancelledBetsData);

  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_CANCELLEDBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_CANCELLEDBETS_DATA_FAILED });
  }
}

function* getSubsequentCancelledBetsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentCancelledBetsData, action.lastKey);

  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_FAILED });
  }
}

function* getInitialCashOutBetsData() {
  const { response, xhr } = yield call(API.fetchInitialCashOutBetsData);

  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_CASHOUTBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_CASHOUTBETS_DATA_FAILED });
  }
}

function* getSubsequentCashOutBetsData(action) {
  const { response, xhr } = yield call(API.fetchSubsequentCashOutBetsData, action.lastKey);

  if (response) {
    yield put({ results: response, type: constants.FETCH_SUBSEQUENT_CASHOUTBETS_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SUBSEQUENT_CASHOUTBETS_DATA_FAILED });
  }
}

function* getBrandsFilterList(action) {
  const { response } = yield call(API.fetchBrandsFilterList, action);
  if (response) {
    yield put({ list: response, type: constants.FETCH_BRANDS_FILTER_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_BRANDS_FILTER_FAILED });
  }
}

function* getOperatorDetails(action) {
  const { response } = yield call(API.getOperatorDetails, action.operatorId);
  if (response) {
    yield put({ operatorDetails: response, type: constants.FETCH_OPERATOR_DETAILS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_OPERATOR_DETAILS_FAILED });
  }
}

function* fetchDefaultCurrency(action) {
  const { response } = yield call(API.fetchDefaultCurrency);
  if (response) {
    yield put({ defaultCurrency: response, type: constants.FETCH_DEFAULT_CURRENCY_SUCCEEDED });
  } else {
    // yield put({ type: constans.FETCH_OPERATOR_DETAILS_FAILED })
  }
}

export default function* instantActionSaga() {
  yield takeLatest(constants.FETCH_INITIAL_BETS_DATA, getInitialBetsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_BETS_DATA, getSubsequentBetsData);

  yield takeLatest(constants.FETCH_INITIAL_ACCOUNTS_DATA, getInitialAccountsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_ACCOUNTS_DATA, getSubsequentAccountsData);

  yield takeLatest(constants.FETCH_INITIAL_PAYMENTS_DATA, getInitialPaymentsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_PAYMENTS_DATA, getSubsequentPaymentsData);

  yield takeLatest(constants.FETCH_INITIAL_FAILEDBETS_DATA, getInitialFailedBetsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_FAILEDBETS_DATA, getSubsequentFailedBetsData);

  yield takeLatest(constants.FETCH_INITIAL_CANCELLEDBETS_DATA, getInitialCancelledBetsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_CANCELLEDBETS_DATA, getSubsequentCancelledBetsData);

  yield takeLatest(constants.FETCH_INITIAL_CASHOUTBETS_DATA, getInitialCashOutBetsData);
  yield takeEvery(constants.FETCH_SUBSEQUENT_CASHOUTBETS_DATA, getSubsequentCashOutBetsData);

  yield takeLatest(constants.FETCH_BRANDS_FILTER, getBrandsFilterList);
  yield takeLatest(constants.FETCH_OPERATOR_DETAILS, getOperatorDetails);
  yield takeLatest(constants.FETCH_DEFAULT_CURRENCY, fetchDefaultCurrency);
}
