import { put, call, fork, select, takeLatest, takeEvery, delay } from "redux-saga/effects";

import actionTypes from "../constants";
import * as API from "../services/apps";

import i18n from "i18n";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* fetchApps(action) {
  try {
    const { response, xhr } = yield call(API.fetchApps, action.id);
    if (response) {
      yield put({
        allowedAppIds: response.allowedAppIds,
        appList: response.appList,
        type: actionTypes.FETCH_APPS_SUCCEEDED,
      });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message;
      yield put({ errMsg: msg, type: actionTypes.FETCH_APPS_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.FETCH_APPS_FAILED });
  }
}

function* fetchAppPermissions(action) {
  const { response, xhr } = yield call(API.fetchAppPermissions, action.userId);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_APP_PERMISSIONS_SUCCEEDED,
      userId: action.userId,
    });
  } else {
    yield put({ type: actionTypes.FETCH_APP_PERMISSIONS_FAILED });
  }
}

function* fetchRecentApps(action) {
  try {
    const { response, xhr } = yield call(API.fetchRecentApps, action.id);
    if (response) {
      yield put({ appList: response, type: actionTypes.FETCH_RECENT_APPS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message;
      yield put({ errMsg: msg, type: actionTypes.FETCH_RECENT_APPS_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.FETCH_RECENT_APPS_FAILED });
  }
}

function* useApp(action) {
  try {
    const { response, xhr } = yield call(API.useApp, action.userid, action.appId);
    if (response) {
      yield put({ response, type: actionTypes.USE_APP_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message;
      yield put({ errMsg: msg, type: actionTypes.USE_APP_FAILED });
    }
  } catch (e) {
    yield put({ errMsg, type: actionTypes.USE_APP_FAILED });
  }
}

function* fetchLines(action) {
  const { response } = yield call(API.lines, action);
  if (response) {
    yield put({ lines: response, type: actionTypes.LINES_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.LINES_FAILED });
  }
}

export default function* appsSaga() {
  yield takeLatest(actionTypes.FETCH_APPS, fetchApps);
  yield takeLatest(actionTypes.FETCH_APP_PERMISSIONS, fetchAppPermissions);
  yield takeLatest(actionTypes.FETCH_RECENT_APPS, fetchRecentApps);
  yield takeLatest(actionTypes.USE_APP, useApp);
  yield takeLatest(actionTypes.LINES, fetchLines);
}
