import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchSportOtherOptionsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function onFetchCombLiability(action) {
  const url =
    action.path.id === 0
      ? `/bo/wagerlimits/eventcombinationliabilityindicator/default`
      : `/bo/wagerlimits/eventcombinationliabilityindicator/eventpathid/${action.path.id}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onFetchCombLiabilityActions() {
  const url = `/bo/eventcombinationliabilityindicator/actions`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function onUdpateCombLiability(action) {
  const url = `/bo/eventcombinationliabilityindicator?eventPathId=${action.data.id}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_PUT, url, action.data.data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
