import actionTypes from "../constants";

const initialState = {
  collapsedEvents: [],
  fetchingChunkIds: [],
  isLoadingTree: null,
  isLoadingTreeFailed: null,
  isUpdatingTree: null,
  isUpdatingTreeFailed: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHUNK_RISK_DATA:
      return { ...state, fetchingChunkIds: [...state.fetchingChunkIds, action.key] };
    case actionTypes.FETCH_CHUNK_RISK_DATA_SUCCEEDED:
    case actionTypes.FETCH_CHUNK_RISK_DATA_FAILED:
      const chunkIdIndex = state.fetchingChunkIds.indexOf(action.key);

      return {
        ...state,
        fetchingChunkIds: [
          ...state.fetchingChunkIds.slice(0, chunkIdIndex),
          ...state.fetchingChunkIds.slice(chunkIdIndex + 1),
        ],
      };
    case actionTypes.FETCH_RISK_DATA:
      return {
        ...state,
        hasUpdates: false,
        isLoadingTree: true,
        isLoadingTreeFailed: false,
        isUpdatingTreeFailed: false,
      };
    case actionTypes.SET_RISK_FILTER_MARKET:
    case actionTypes.SET_RISK_FILTER_PERIOD:
    case actionTypes.SEARCH_EVENTS:
      return { ...state, isLoadingTree: true, isLoadingTreeFailed: false, isUpdatingTreeFailed: false };
    case actionTypes.FETCH_RISK_DATA_SUCCEEDED:
      return {
        ...state,
        hasUpdates: !!action.hasUpdates,
        isLoadingTree: false,
        isLoadingTreeFailed: false,
      };
    case actionTypes.FETCH_RISK_DATA_FAILED:
      return { ...state, hasUpdates: false, isLoadingTree: false, isLoadingTreeFailed: true };
    case actionTypes.UPDATE_RISK_DATA:
      return { ...state, hasUpdates: false, isUpdatingTree: true, isUpdatingTreeFailed: false };
    case actionTypes.UPDATE_RISK_DATA_SUCCEEDED:
    case actionTypes.FETCH_CHUNK_RISK_DATA:
    case actionTypes.CANCEL_UPDATE_RISK_DATA:
      return {
        ...state,
        hasUpdates: action.hasUpdates,
        isLoadingTreeFailed: false,
        isUpdatingTree: false,
        isUpdatingTreeFailed: false,
      };
    case actionTypes.UPDATE_RISK_DATA_FAILED:
      return { ...state, isUpdatingTree: false, isUpdatingTreeFailed: true };
    case actionTypes.CLEAR_RISK_DATA:
      return { ...state, isLoadingTree: false, isLoadingTreeFailed: false };
    case actionTypes.EXPAND_ALL_EVENTS:
      return { ...state, collapsedEvents: [] };
    case actionTypes.COLLAPSE_EVENT:
      return { ...state, collapsedEvents: [...state.collapsedEvents, action.eventId] };
    case actionTypes.EXPAND_EVENT:
      const index = state.collapsedEvents.indexOf(action.eventId);

      return {
        ...state,
        collapsedEvents: [...state.collapsedEvents.slice(0, index), ...state.collapsedEvents.slice(index + 1)],
      };
    default:
      return { ...state };
  }
}
