import actionTypes from "../../constants";

const initialState = {
  accountWagerLimits: null,
  defaultWagerLimit: null,

  errorFetchingAccountWagerLimits: false,
  errorFetchingEventPaths: false,

  errorFetchingProfileFlags: false,
  eventPaths: null,
  isFetchingAccountWagerLimits: false,

  isFetchingData: false,
  isFetchingEventPaths: false,
  isFetchingProfileFlags: false,
  isSavingProfileFlag: false,

  isSavingWagerLimit: false,
  outcomeWagerLimitData: null,
  pathSpecificLimits: null,
  profileFlags: null,
};

const customerManagerSettings = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_WAGER_LIMIT:
      return {
        ...state,
        defaultWagerLimit: action.id,
      };
    case actionTypes.SET_PATH_SPECIFIC_LIMITS:
      return {
        ...state,
        pathSpecificLimits: action.arr,
      };
    case actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO:
      return {
        ...state,
        isFetchingData: true,
      };
    case actionTypes.FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        outcomeWagerLimitData: action.result,
      };
    case actionTypes.FETCH_EVENTPATHS_FROM_CODE:
      return {
        ...state,
        errorFetchingEventPaths: false,
        eventPaths: null,
        isFetchingEventPaths: true,
      };
    case actionTypes.FETCH_EVENTPATHS_FROM_CODE_SUCCEEDED:
      return {
        ...state,
        errorFetchingEventPaths: false,
        eventPaths: action.eventPaths,
        isFetchingEventPaths: false,
      };
    case actionTypes.FETCH_EVENTPATHS_FROM_CODE_FAILED:
      return {
        ...state,
        errorFetchingEventPaths: true,
        eventPaths: null,
        isFetchingEventPaths: false,
      };
    case actionTypes.SAVE_WAGER_LIMIT:
      return {
        ...state,
        isSavingWagerLimit: true,
      };
    case actionTypes.SAVE_WAGER_LIMIT_SUCCEEDED:
      return {
        ...state,
        isSavingWagerLimit: false,
      };
    case actionTypes.FETCH_ACCOUNT_WAGER_LIMITS:
      return {
        ...state,
        accountWagerLimits: null,
        errorFetchingAccountWagerLimits: false,
        isFetchingAccountWagerLimits: true,
      };
    case actionTypes.FETCH_ACCOUNT_WAGER_LIMITS_SUCCEEDED:
      return {
        ...state,
        accountWagerLimits: action.response,
        errorFetchingAccountWagerLimits: false,
        isFetchingAccountWagerLimits: false,
      };
    case actionTypes.FETCH_ACCOUNT_WAGER_LIMITS_FAILED:
      return {
        ...state,
        accountWagerLimits: null,
        errorFetchingAccountWagerLimits: true,
        isFetchingAccountWagerLimits: false,
      };
    case actionTypes.FETCH_PLAYER_PROFILE_FLAGS:
      return {
        ...state,
        errorFetchingProfileFlags: false,
        isFetchingProfileFlags: true,
        profileFlags: null,
      };
    case actionTypes.FETCH_PLAYER_PROFILE_FLAGS_SUCCEEDED:
      return {
        ...state,
        errorFetchingProfileFlags: false,
        isFetchingProfileFlags: false,
        profileFlags: action.response,
      };
    case actionTypes.FETCH_PLAYER_PROFILE_FLAGS_FAILED:
      return {
        ...state,
        errorFetchingProfileFlags: true,
        isFetchingProfileFlags: false,
        profileFlags: null,
      };
    case actionTypes.SAVE_PLAYER_PROFILE_FLAG:
      return {
        ...state,
        isSavingProfileFlag: true,
      };
    case actionTypes.SAVE_PLAYER_PROFILE_FLAG_SUCCEEDED:
      return {
        ...state,
        isSavingProfileFlag: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    default:
      return { ...state };
  }
};

export default customerManagerSettings;
