import { configureStore, applyMiddleware, compose, getDefaultMiddleware } from "@reduxjs/toolkit";
import React from "react";
import { hashHistory } from "react-router";
import { syncHistoryWithStore, routerMiddleware } from "react-router-redux";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

// import freeze from 'redux-freeze'
// import { whyDidYouUpdate } from 'why-did-you-update'
import persistConfig from "./reducers/get-persist-config";
import { reducers } from "./reducers/index";
import { sagas } from "./sagas/index";

// add the middlewares
const middlewares = [];

// add the router middleware
middlewares.push(routerMiddleware(hashHistory));

// add the saga middleware
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

// add the redux logger
const logger = createLogger({ collapsed: true });
if (process.env.NODE_ENV !== "production") {
  middlewares.push(logger);
  // add the freeze dev middleware
  // middlewares.push(freeze);
}

// // add the redux dev tools
// if (process.env.NODE_ENV !== 'production' && window.devToolsExtension) {
//   middleware = compose(middleware, window.devToolsExtension())
// }

// create the store
const store = configureStore({
  // middleware: middlewares,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false, // Replace with the below if and when we start migrating to slices. We get frequent serialization warnings due to extensive inappropiate redux usage in legacy code
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(middlewares),
  reducer: persistReducer(persistConfig({ storage }), reducers),
});

const history = syncHistoryWithStore(hashHistory, store);
sagaMiddleware.run(sagas);

// export
export { store, history };
export const persistor = persistStore(store);
