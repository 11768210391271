import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;

export function fetchOperatorDetails(operatorId) {
  const url = `/bo/operators/${operatorId}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAssignedSession(operatorId) {
  const url = `/bo/chat/sessions?operatorId=${operatorId}&ended=false&unassigned=false`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUnassignedSession() {
  const url = "/bo/chat/sessions?unassigned=true&ended=false&hasCustomerMessages=true&logOperator=true";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function acceptSession(sessionId) {
  const url = `/bo/chat/sessions/${sessionId}/accept`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function selectSession(sessionId) {
  const url = `/bo/chat/sessions/${sessionId}/messages`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMessages(sessionId) {
  const url = `/bo/chat/sessions/${sessionId}/messages`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function sendMessage(sessionId, message) {
  const url = `/bo/chat/sessions/${sessionId}/messages`;
  const options = {
    chatSessionId: sessionId,
    message,
    origin: "OPERATOR",
  };

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, options)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function endSession(sessionId) {
  const url = `/bo/chat/sessions/${sessionId}/end?origin=OPERATOR`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSessionDetails(sessionId) {
  const url = `/bo/chat/sessions/${sessionId}?populateCustomerTimeoutWarning=true`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
