import { takeLatest, takeEvery, delay, put, call, fork, select } from "redux-saga/effects";

import playersConst from "../constants/playersConst";
import * as playersAPI from "../services/playersServices";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";


function* fetchSinglePlayerOfTeamDetails(action) {
  const { response, xhr } = yield call(playersAPI.fetchSinglePlayerOfTeamDetails, action.playerId);
  if (response) {
    yield put({ playerDetails: response, type: playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_FAILED });
  }
}

function* fetchMultiplePlayersOfTeamDetails(action) {
  const { response, xhr } = yield call(playersAPI.fetchMultiplePlayersOfTeamDetails, action.teamId);
  if (response) {
    yield put({ playerDetailsList: response, type: playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_FAILED });
  }
}

function* addPlayerToTeam(action) {
  const { response, xhr } = yield call(playersAPI.addPlayerToTeam, action.teamId, action.playerId);
  if (response) {
    yield put({ type: playersConst.ADD_PLAYER_TO_TEAM_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: playersConst.ADD_PLAYER_TO_TEAM_FAILED });
  }
}

function* deletePlayerOfTeam(action) {
  const { response, xhr } = yield call(playersAPI.deletePlayerOfTeam, action.teamId, action.playerId);
  if (response) {
    yield put({ playerIdRemoved: action.playerId, type: playersConst.DELETE_PLAYER_OF_TEAM_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: playersConst.DELETE_PLAYER_OF_TEAM_FAILED });
  }
}

function* deleteAllPlayerOfTeam(action) {
  const { response, xhr } = yield call(playersAPI.deleteAllPlayerOfTeam, action.teamId);
  if (response) {
    yield put({ type: playersConst.DELETE_ALL_PLAYER_OF_TEAM_SUCCEEDED });
    toastr.add({
      message: `${i18n.t("Event.Successfully deleted all")} ${
        action.opponentTypeId === 1 ? "teams" : action.opponentTypeId === 2 ? "players" : "horses"
      }.`,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: playersConst.DELETE_ALL_PLAYER_OF_TEAM_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete all players")}. ${msg}`, type: "ERROR" });
  }
}

function* deleteAllPlayerOfEventPath(action) {
  const { response, xhr } = yield call(playersAPI.deleteAllPlayerOfEventPath, action.eventPathId);
  if (response) {
    yield put({ type: playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH_SUCCEEDED });
    toastr.add({
      message: `${i18n.t(
        `Event.Successfully deleted all ${
          action.opponentTypeId === 1 ? "teams" : action.opponentTypeId === 2 ? "players" : "horses"
        }`,
      )}.`,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH_FAILED });
    toastr.add({
      message: `${i18n.t(
        `Event.Unable to delete all ${
          action.opponentTypeId === 1 ? "teams" : action.opponentTypeId === 2 ? "players" : "horses"
        }`,
      )}. ${msg}`,
      type: "ERROR",
    });
  }
}

export default function* playersSaga() {
  yield takeLatest(playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS, fetchSinglePlayerOfTeamDetails);
  yield takeLatest(playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS, fetchMultiplePlayersOfTeamDetails);
  yield takeLatest(playersConst.ADD_PLAYER_TO_TEAM, addPlayerToTeam);
  yield takeLatest(playersConst.DELETE_PLAYER_OF_TEAM, deletePlayerOfTeam);
  yield takeLatest(playersConst.DELETE_ALL_PLAYER_OF_TEAM, deleteAllPlayerOfTeam);
  yield takeLatest(playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH, deleteAllPlayerOfEventPath);
}
