import { put, call, takeLatest } from "redux-saga/effects";

import LiabilityIndicatorsConstant from "./Components/LiabilityIndicators/constants";
import StakeDistributionConstants from "./Components/StakeDistribution/constants";
import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchEventPathTree(action) {
  const { response } = yield call(API.fetchEventPathTree);
  if (response) {
    yield put({ response, type: constants.FETCH_EVENT_PATH_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EVENT_PATH_FAILED });
  }
}

function* fetchPlayerProfileGroups(action) {
  const { response } = yield call(API.onFetchPlayerProfileGroups);
  if (response) {
    yield put({ response, type: constants.FETCH_PLAYER_PROFILE_GROUPS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_PLAYER_PROFILE_GROUPS_FAILED });
  }
}

function* fetchWagerLimits(action) {
  const { response } = yield call(API.onFetchWagerLimits, action);
  if (response) {
    yield put({ response, type: constants.FETCH_WAGER_LIMITS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_WAGER_LIMITS_FAILED });
  }
}

function* fetchWagerActions(action) {
  const { response } = yield call(API.onFetchWagerActions);
  if (response) {
    yield put({ response, type: constants.FETCH_WAGER_ACTIONS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_WAGER_ACTIONS_FAILED });
  }
}

function* fetchWagerLimitGroup() {
  const { response } = yield call(API.fetchWagerLimitGroup);
  if (response) {
    yield put({ response, type: constants.FETCH_WAGER_LIMIT_GROUP_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_WAGER_LIMIT_GROUP_FAILED });
  }
}

function* saveWagerLimits(action) {
  const { response } = yield call(API.onSaveWagerLimits, action);
  if (response) {
    yield put({ response, type: constants.ON_SAVE_WAGER_LIMITS_SUCCEEDED });
    toastr.add({ message: i18n.t(`Wager.Wager Limits updated successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_SAVE_WAGER_LIMITS_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to update Wager Limits`), type: "ERROR" });
  }
}

function* removeWagerLimitsForEventPath(action) {
  const { response } = yield call(API.removeWagerLimitsForEventPath, action);
  if (response) {
    yield put({ type: constants.ON_REMOVE_WAGER_LIMITS_SUCCEEDED });
    toastr.add({ message: i18n.t(`Wager.Wager Limits for Default group deleted successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_REMOVE_WAGER_LIMITS_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to remove Wager Limits`), type: "ERROR" });
  }
}

function* removeAll_WL_LIAB_STAKE(action) {
  const { response } = yield call(API.removeAll_WL_LIAB_STAKE, action);
  if (response) {
    yield put({ path: { id: action.selectedSport.id }, type: LiabilityIndicatorsConstant.FETCH_LIABILITY_INDICATORS });
    yield put({ path: { id: action.selectedSport.id }, type: StakeDistributionConstants.FETCH_STAKE_DISTRIBUTATION });
    yield put({ type: constants.FETCH_WAGER_LIMITS, val: { id: action.selectedSport.id } });
    yield put({ type: constants.ON_REMOVE_ALL_WL_LIAB_STAKE_SUCCEEDED });
    toastr.add({ message: i18n.t(`Wager.All wager limit and indicators deleted successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_REMOVE_ALL_WL_LIAB_STAKE_FAILED });
    toastr.add({
      message: i18n.t(`Wager.Unable to remove all the wager limit and indicators set to specific event path`),
      type: "ERROR",
    });
  }
}

export default function* WagerLimits() {
  yield takeLatest(constants.FETCH_EVENT_PATH, fetchEventPathTree);
  yield takeLatest(constants.FETCH_PLAYER_PROFILE_GROUPS, fetchPlayerProfileGroups);
  yield takeLatest(constants.FETCH_WAGER_LIMITS, fetchWagerLimits);
  yield takeLatest(constants.FETCH_WAGER_LIMITS, fetchWagerActions);
  yield takeLatest(constants.FETCH_WAGER_LIMIT_GROUP, fetchWagerLimitGroup);
  yield takeLatest(constants.ON_SAVE_WAGER_LIMITS, saveWagerLimits);
  yield takeLatest(constants.REMOVE_WAGER_LIMIT_FOR_EVENT_PATH, removeWagerLimitsForEventPath);
  yield takeLatest(constants.REMOVE_ALL_WL_LIAB_STAKE, removeAll_WL_LIAB_STAKE);
}
