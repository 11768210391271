import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchHurdleSequence() {
  const url = `/bo/hurdles/sequences`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchHurdleEntities() {
  const url = `/bo/hurdles/entries`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteHurdleSequence(hurdleToDelete) {
  const url = `/bo/hurdles/sequences/${hurdleToDelete.id}`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateHurdleSequence(hurdleSequence) {
  const url = `/bo/hurdles/sequences/bulk`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, hurdleSequence)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchHurdleSequenceAssoc() {
  const url = `/bo/hurdles/types`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSequenceToExecute() {
  const url = `/bo/hurdles/sequences`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function upDateHurdleSequenceAssoc(hurdleSequenceAssoc) {
  const url = `/bo/hurdles/types/bulkupdate`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, hurdleSequenceAssoc)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
