import _ from "lodash";

import constants from "./constants";

import { makeIterable } from "utils";

const initialState = {
  backUp: JSON.stringify({ value: [] }),
  liabilityIndicatorsData: JSON.stringify({ value: [] }),
  modifiedRow: {},
  updateLiabilityIndicatorsSuccessfully: false,
};

const WL_Liability = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_LIABILITY_INDICATORS_SUCCEEDED:
      return {
        ...state,
        backUp: JSON.stringify({ value: action.response }),
        liabilityIndicatorsData: JSON.stringify({ value: action.response }),
      };
    case constants.FETCH_LIABILITY_INDICATORS_FAILED:
      return {
        ...state,
      };
    case constants.SELECT_ROW_TO_EDIT:
      const selectedRow = _.find(JSON.parse(state.backUp).value, (obj) => obj.id === action.row.id);

      return {
        ...state,
        selectedRowToEdit: JSON.stringify(selectedRow),
      };

    case constants.UPDATE_FIELD:
      const { field, row, value } = action;
      const selectedRowToEdit = JSON.parse(state.selectedRowToEdit);
      const isModified = !_.isEqual(selectedRowToEdit[field], value);

      const modifiedRow = state.modifiedRow;
      const liabilityIndicatorsData = JSON.parse(state.liabilityIndicatorsData).value;
      _.map(liabilityIndicatorsData, (item, indx) => {
        if (liabilityIndicatorsData[indx].id == row.id) {
          liabilityIndicatorsData[indx][field] = value;
        }
      });
      if (isModified) {
        if (!modifiedRow[row.id]) {
          modifiedRow[row.id] = [];
          modifiedRow[row.id].push(field);
        } else if (!_.includes(modifiedRow[row.id], field)) {
          modifiedRow[row.id].push(field);
        }
      } else if (modifiedRow[row.id]) {
        _.remove(modifiedRow[row.id], (f) => f == field);
        if (modifiedRow[row.id].length == 0) {
          delete modifiedRow[row.id];
        }
      }

      return {
        ...state,
        liabilityIndicatorsData: JSON.stringify({ value: liabilityIndicatorsData }),
        modifiedRow,
      };

    case constants.SAVE_LIABILITY_INDICATORS_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
        updateLiabilityIndicatorsSuccessfully: true,
      };
    case constants.SAVE_LIABILITY_INDICATORS_FAILED:
      return {
        ...state,
        updateLiabilityIndicatorsSuccessfully: false,
      };

    case constants.ON_EXIT_LIABILITY_INDICATORS_PAGE:
      return {
        ...state,
        liabilityIndicatorsData: state.backUp,
        modifiedRow: {},
        selectedRowToEdit: null,
      };

    default:
      return { ...state };
  }
};

export default WL_Liability;
