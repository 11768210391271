import _ from "lodash";
import moment from "moment";

import constants, { tabs } from "./constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

const initialState = {
  backUpHurdleSequence: _.cloneDeep([]),
  backupSelectedSequence: _.cloneDeep({}),
  currentTab: null,
  fetchingHurdleSequence: false,
  fetchingHurdleSequenceAssoc: false,
  hurdleEntities: [],
  hurdleSequence: _.cloneDeep([]),
  hurdleSequenceAssoc: _.cloneDeep([]),
  hurdleSequenceAssocBackup: _.cloneDeep([]),
  selectedEntitiesToAdd: [],
  selectedEntitiesToDelete: [],
  selectedSequence: null,
  sequenceToExecute: _.cloneDeep([]),
};

const utilitiesHurdleManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.tab,
      };
    case constants.FETCH_HURDLE_SEQUENCE:
      return {
        ...state,
        fetchingHurdleSequence: true,
      };
    case constants.FETCH_HURDLE_SEQUENCE_SUCCEEDED:
      return {
        ...state,
        backUpHurdleSequence: _.cloneDeep(action.response),
        backupSelectedSequence: _.cloneDeep({}),
        fetchingHurdleSequence: false,
        hurdleSequence: _.cloneDeep(action.response),
        selectedEntitiesToDelete: [],
        selectedSequence: null,
      };
    case constants.FETCH_HURDLE_SEQUENCE_FAILED:
      return {
        ...state,
        backUpHurdleSequence: _.cloneDeep([]),
        fetchingHurdleSequence: false,
        hurdleSequence: _.cloneDeep([]),
      };
    case constants.SELECT_SEQUENCE:
      return {
        ...state,
        backupSelectedSequence: _.cloneDeep(action.selectedSequence),
        selectedEntitiesToDelete: [],
        selectedSequence: _.cloneDeep(action.selectedSequence),
      };
    case constants.FETCH_HURDLE_ENTITIES_SUCCEEDED:
      return {
        ...state,
        hurdleEntities: action.response,
      };
    case constants.ON_SELECT_ENTITIES:
      return {
        ...state,
        selectedEntitiesToAdd: action.list,
      };
    case constants.ON_ADD_NEW_ENTRIES:
      _.map(state.selectedEntitiesToAdd, (item, index) => {
        state.selectedSequence.entries.push({
          approvable: false,
          entryDescription: item.description,
          hurdleEntryId: item.hurdleId,
          hurdleSequenceEntryId: item.hurdleSequenceId,
          ordinalPosition: state.selectedSequence.entries.length
            ? state.selectedSequence.entries[state.selectedSequence.entries.length - 1]["ordinalPosition"] + 1
            : 0,
          referable: item.referable,
        });
      });
      let sequenceIndex = _.findIndex(
        state.hurdleSequence,
        (s) =>
          (state.selectedSequence && state.selectedSequence.id && s.id == state.selectedSequence.id) ||
          (state.selectedSequence && state.selectedSequence.index && s.index == state.selectedSequence.index),
      );
      if (state.selectedSequence && (state.selectedSequence.id || state.selectedSequence.index)) {
        state.hurdleSequence[sequenceIndex]["entries"] = _.cloneDeep(state.selectedSequence.entries);
      }

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedEntitiesToAdd: [],
        selectedSequence: _.cloneDeep(state.selectedSequence),
      };
    case constants.ON_SELECT_ENTRIES_TO_DELETE:
      if (_.includes(state.selectedEntitiesToDelete, action.rowInfo.index)) {
        _.remove(state.selectedEntitiesToDelete, (a) => a == action.rowInfo.index);
      } else {
        state.selectedEntitiesToDelete.push(action.rowInfo.index);
      }

      return {
        ...state,
        selectedEntitiesToDelete: state.selectedEntitiesToDelete,
      };
    case constants.REMOVE_SELECTED_ENTRIES:
      let entriesUnderOperation = _.cloneDeep(state.selectedSequence.entries);
      const allOrdinalPosition = [];
      _.map(action.selectedEntitiesToDelete, (item, index) => {
        allOrdinalPosition.push(state.selectedSequence.entries[item]["ordinalPosition"]);
      });
      _.map(allOrdinalPosition, (pos, index) => {
        _.remove(entriesUnderOperation, (a) => a.ordinalPosition == pos);
      });
      _.map(entriesUnderOperation, (ent, index) => {
        entriesUnderOperation[index]["ordinalPosition"] = index;
      });
      state.selectedSequence.entries = _.cloneDeep(entriesUnderOperation);
      sequenceIndex = _.findIndex(state.hurdleSequence, (s) =>
        state.selectedSequence.id ? s.id == state.selectedSequence.id : s.index == state.selectedSequence.index,
      );
      state.hurdleSequence[sequenceIndex]["entries"] = _.cloneDeep(state.selectedSequence.entries);

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedEntitiesToDelete: [],
        selectedSequence: _.cloneDeep(state.selectedSequence),
      };
    case constants.ON_MOVE_HURDLE_DOWN:
      entriesUnderOperation = _.cloneDeep(state.selectedSequence.entries);
      const entryToSwap = state.selectedEntitiesToDelete[0];
      const entrySwapWith = action.isDown ? entryToSwap + 1 : entryToSwap - 1;
      const ordinalPos_of_entryToSwap = entriesUnderOperation[entryToSwap]["ordinalPosition"];
      const ordinalPos_of_entrySwapWith = entriesUnderOperation[entrySwapWith]["ordinalPosition"];
      const temp = entriesUnderOperation[entryToSwap];
      entriesUnderOperation[entryToSwap] = entriesUnderOperation[entrySwapWith];
      entriesUnderOperation[entryToSwap]["ordinalPosition"] = ordinalPos_of_entryToSwap;
      entriesUnderOperation[entrySwapWith] = temp;
      entriesUnderOperation[entrySwapWith]["ordinalPosition"] = ordinalPos_of_entrySwapWith;
      state.selectedSequence.entries = _.cloneDeep(entriesUnderOperation);
      sequenceIndex = _.findIndex(
        state.hurdleSequence,
        (s) =>
          (state.selectedSequence.id && s.id == state.selectedSequence.id) ||
          (state.selectedSequence.index && s.index == state.selectedSequence.index),
      );
      state.hurdleSequence[sequenceIndex]["entries"] = _.cloneDeep(state.selectedSequence.entries);
      state.selectedEntitiesToDelete[0] = action.isDown
        ? state.selectedEntitiesToDelete[0] + 1
        : state.selectedEntitiesToDelete[0] - 1;

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedEntitiesToDelete: _.cloneDeep(state.selectedEntitiesToDelete),
        selectedSequence: _.cloneDeep(state.selectedSequence),
      };
    case constants.CHANGE_SEQUENCE_DESIPTION:
      sequenceIndex = _.findIndex(
        state.hurdleSequence,
        (s) =>
          (state.selectedSequence.id && s.id == state.selectedSequence.id) ||
          (state.selectedSequence.index && s.index == state.selectedSequence.index),
      );
      state.hurdleSequence[sequenceIndex]["description"] = action.description;
      state.selectedSequence = state.hurdleSequence[sequenceIndex];

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedSequence: _.cloneDeep(state.selectedSequence),
      };
    case constants.UNDO_HURDLE_CHANGES:
      sequenceIndex = _.findIndex(
        state.hurdleSequence,
        (s) =>
          (state.selectedSequence.id && s.id == state.selectedSequence.id) ||
          (state.selectedSequence.index && s.index == state.selectedSequence.index),
      );
      state.selectedSequence = state.backUpHurdleSequence[sequenceIndex];

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.backUpHurdleSequence),
        selectedEntitiesToDelete: [],
        selectedSequence: _.cloneDeep(state.selectedSequence),
      };
    case constants.MAKE_HURDLE_SEQUENCE_COPY:
      const copy = {};
      copy["description"] = `Copy of ${state.selectedSequence.description}`;
      copy["editable"] = state.selectedSequence.editable;
      copy["entries"] = state.selectedSequence.entries;
      copy["index"] = state.hurdleSequence.length;
      state.hurdleSequence[state.hurdleSequence.length] = copy;

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedSequence: _.cloneDeep(copy),
      };
    case constants.DELETE_HURDEL_SEQUENCE_FROM_LOCAL:
      state.hurdleSequence.splice(action.hurdleToDelete.index, 1);
      toastr.add({ message: i18n.t("Utilities.Hurdle Sequence deleted successfully"), type: "SUCCESS" });

      return {
        ...state,
        hurdleSequence: state.hurdleSequence,
        selectedSequence: null,
      };
    case constants.ADD_NEW_HURDLE_SEQUENCE:
      const newSequence = {};
      newSequence["description"] = "New Sequence";
      newSequence["entries"] = [];
      newSequence["index"] = state.hurdleSequence.length;
      state.hurdleSequence[state.hurdleSequence.length] = newSequence;

      return {
        ...state,
        hurdleSequence: _.cloneDeep(state.hurdleSequence),
        selectedSequence: _.cloneDeep(newSequence),
      };

    case constants.FETCH_HURDLE_SEQUENCE_ASSOC:
      return {
        ...state,
        fetchingHurdleSequenceAssoc: true,
      };
    case constants.FETCH_HURDLE_SEQUENCE_ASSOC_SUCCEEDED:
      return {
        ...state,
        fetchingHurdleSequenceAssoc: false,
        hurdleSequenceAssoc: _.cloneDeep(action.response),
        hurdleSequenceAssocBackup: _.cloneDeep(action.response),
      };
    case constants.FETCH_HURDLE_SEQUENCE_ASSOC_FAILED:
      return {
        ...state,
        fetchingHurdleSequenceAssoc: false,
        hurdleSequenceAssoc: _.cloneDeep([]),
        hurdleSequenceAssocBackup: _.cloneDeep([]),
      };
    case constants.FETCH_SEQUENCE_TO_EXECUTE_SUCCEEDED:
      return {
        ...state,
        sequenceToExecute: _.cloneDeep(action.response),
      };
    case constants.FETCH_SEQUENCE_TO_EXECUTE_FAILED:
      return {
        ...state,
        sequenceToExecute: _.cloneDeep([]),
      };
    case constants.ON_CHANGE_HURDLE_SEQUENCE_TO_EXEC:
      _.map(state.hurdleSequenceAssoc, (item, index) => {
        if (item.id == action.row.id) {
          state.hurdleSequenceAssoc[index]["hurdleSequenceId"] = action.value * 1;
        }
      });

      return {
        ...state,
        hurdleSequenceAssoc: state.hurdleSequenceAssoc,
      };
    case constants.UNDO_HURDLE_SEQUENCE_ASSOC:
      return {
        ...state,
        hurdleSequenceAssoc: _.cloneDeep(state.hurdleSequenceAssocBackup),
      };
    default:
      return {
        ...state,
      };
  }

  return null;
};

export default utilitiesHurdleManagement;
