import actionTypes from "../constants";

const initialState = {
  fetchingApiConstantsFailed: null,
  isFetchingApiConstants: null,
  values: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_API_CONSTANTS:
      return { ...state, fetchingApiConstantsFailed: false, isFetchingApiConstants: true };
    case actionTypes.FETCH_API_CONSTANTS_SUCCEEDED:
      return { ...state, isFetchingApiConstants: false, values: { ...state.values, ...action.values } };
    case actionTypes.FETCH_API_CONSTANTS_FAILED:
      return { ...state, fetchingApiConstantsFailed: true, isFetchingApiConstants: false };
    case actionTypes.FETCH_BRAND_DETAILS_SUCCEEDED:
      const values = { ...state.values };
      values["userBrand"] = action.response;

      return {
        ...state,
        values,
      };
    default:
      return { ...state };
  }
}
