import constants from "./constants";

const initialState = {
  alerts: [],
  backupAlerts: [],
  backupHurdles: [],
  eventPaths: [],
  hurdles: [],
  isFetchingAlerts: false,
  isFetchingEventPaths: false,
  isFetchingHurdles: false,
  msg: "",
  selectedRow: "",
  selectedTab: "hurdles",
  updateHurdles: [],
};

const utilitiesMarketControl = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_MARKET_CONTROL_HURDLES:
      return {
        ...state,
        isFetchingHurdles: true,
      };
    case constants.FETCH_MARKET_CONTROL_HURDLES_SUCCEEDED:
      return {
        ...state,
        backupHurdles: action.payload,
        hurdles: action.payload,
        isFetchingHurdles: false,
        selectedRow: "",
      };
    case constants.SELECT_ROW:
      return {
        ...state,
        selectedRow: action.payload,
      };
    case constants.UNSELECT_ROW:
      return {
        ...state,
        selectedRow: "",
      };
    case constants.FETCH_MARKET_CONTROL_ALERTS:
      return {
        ...state,
        isFetchingAlerts: true,
      };
    case constants.SELECT_TAB_HURDLES_ALERTS:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case constants.FETCH_MARKET_CONTROL_ALERTS_SUCCEEDED:
      return {
        ...state,
        alerts: action.payload,
        backupAlerts: action.payload,
        isFetchingAlerts: false,
        selectedRow: "",
      };
    case constants.UPDATE_HURDLES_ROW:
      const hurdles = _.cloneDeep(state.hurdles);
      _.map(hurdles, (value) => {
        if (value.id == action.payload.id) {
          value.enabled = action.payload.val;
        }
      });

      return {
        ...state,
        hurdles,
      };
    case constants.UNDO_UPDATE_HURDLES:
      return {
        ...state,
        hurdles: state.backupHurdles,
        selectedRow: "",
      };
    case constants.UPDATE_ALERTS_ROW:
      const alerts = _.cloneDeep(state.alerts);
      _.map(alerts, (val) => {
        if (val.type == action.payload.id) {
          val.enabled = action.payload.val;
        }
      });

      return {
        ...state,
        alerts,
      };
    case constants.UNDO_UPDATE_ALERTS_ROW:
      return {
        ...state,
        alerts: state.backupAlerts,
        selectedRow: "",
      };
    case constants.UPDATE_ALERTS_DESCRIPTION:
      const alert = _.cloneDeep(state.alerts);
      _.map(alert, (val, i) => {
        if (val.type == state.selectedRow) {
          val.description = action.payload;
        }
      });

      return {
        ...state,
        alerts: alert,
      };
    case constants.FETCH_ALERTS_EVENTSPATHS:
      return {
        ...state,
        isFetchingEventPaths: true,
      };
    case constants.FETCH_ALERTS_EVENTSPATHS_SUCCEEDED:
      return {
        ...state,
        eventPaths: action.payload.eventPaths,
        isFetchingEventPaths: false,
      };
    case constants.UPDATE_ALERTS_CONFIG:
      const { index, value } = action.payload;
      const updateAlertCheckbox = _.cloneDeep(state.alerts);
      _.map(updateAlertCheckbox, (val, i) => {
        if (val.type == state.selectedRow) {
          val.payloads[index] = value;
        }
      });

      return {
        ...state,
        alerts: updateAlertCheckbox,
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesMarketControl;
