const constants = {
  CLEAR_STATE: "GRID::CLEAR_STATE",
  FILTER_GRID: "GRID::FILTER_GRID",
  SET_GRID_COLUMN_CONFIG: "GRID::SET_GRID_COLUMN_CONFIG",
  SET_GRID_DATA: "GRID::SET_GRID_DATA",
  SET_GRID_LOADING_STATE: "GRID::SET_GRID_LOADING_STATE",
  SET_GRID_SORT_CONFIG: "GRID::SET_GRID_SORT_CONFIG",
  SORT_GRID: "GRID::SORT_GRID",
};

export default constants;
