import originalAxios from "axios";

import httpMethods from "constants/httpMethods";
import api from "services//api";
import { performHttpCall } from "services//apiUtils";

const fetchAccumulatorFilterXhr = null;
const fetchAccumulatorDetailsXhr = null;
const fetchArbetDetailsXhr = null;
let putCombiIndicatorsXhr = null;
const fetchProfileFlagsXhr = null;
const fetchEventPathsFilterXhr = null;

const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

const addJSONContentType = (headers) => {
  headers["Content-Type"] = "application/json";
};

const consolidatedAccumulatorXHR = null;

export function fetchAccumulatorFilter(params, filterParams) {
  const url = `/bo/consolidatedaccumulatorrisk/filter?pageSize=${filterParams.pageSize}&pageIndex=${filterParams.pageIndex}`;

  return performHttpCall(fetchAccumulatorFilterXhr, httpMethods.HTTP_POST, url, params, addJSONContentType(headers))
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAccumulatorArbetDetails(params) {
  const url = `/bo/consolidatedaccumulatorrisk/getarbetdetail`;

  return performHttpCall(fetchArbetDetailsXhr, httpMethods.HTTP_POST, url, params, addJSONContentType(headers))
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAccumulatorDetails(paramId) {
  const url = `/bo/consolidatedaccumulatorrisk/${paramId}/riskdetails`;

  return performHttpCall(fetchAccumulatorFilterXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function putCombiIndicators(indicators) {
  return new Promise((resolve, reject) => {
    const url = `/bo/combinationliabilityindicator`;

    if (putCombiIndicatorsXhr) {
      putCombiIndicatorsXhr.cancel("cancelled due to concurrent request");
    }

    putCombiIndicatorsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, indicators, putCombiIndicatorsXhr);
      putCombiIndicatorsXhr = null;
      resolve(response);
    } catch (err) {
      putCombiIndicatorsXhr = null;
      reject(err);
    }
    putCombiIndicatorsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function getUpdatedLiabilityIndicators(payload) {
  const url = `/bo/combinationliabilityindicator/${payload.indicators[0].hash}`;

  return performHttpCall(fetchProfileFlagsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchProfileFlags(profileId) {
  const url = `bo/accountplayerprofileflags/${profileId}`;

  return performHttpCall(fetchProfileFlagsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function setBlockingRule(id, params) {
  const url = `/bo/consolidatedaccumulatorrisk/${id}/setblockingtype`;

  return performHttpCall(fetchArbetDetailsXhr, httpMethods.HTTP_PUT, url, params, addJSONContentType(headers))
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathsFilter(itemId, itemType) {
  const url = `/bo/consolidatedaccumulatorrisk/filter/tree?id=${itemId}&type=${itemType}`;

  return performHttpCall(fetchEventPathsFilterXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
