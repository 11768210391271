import _ from "lodash";
import { put, call, takeLatest } from "redux-saga/effects";

import { history, store } from "../../../../store";
import appActionTypes from "../../App/constants";
import { buildTreeItem, buildRemoveTreeItem } from "../../Sidebar/helpers";

import actionTypes from "./constants";
import * as API from "./services";

import { closeModal } from "actions/modal";
import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchPromotionalGroups(action) {
  const { response, xhr } = yield call(API.fetchPromotionalGroups);
  if (response) {
    yield put({ promotionalGroups: response, type: actionTypes.FETCH_PROMOTIONAL_GROUPS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_PROMOTIONAL_GROUPS_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionalGroupFilters(action) {
  const { response, xhr } = yield call(API.fetchPromotionalGroupFilters);
  if (response) {
    yield put({ promotionalGroupFilters: response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_FILTERS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_PROMOTIONAL_GROUP_FILTERS_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionalGroupMembers(action) {
  const { id, params } = action;
  const { response, xhr } = yield call(API.fetchPromotionalGroupMembers, id, params);
  if (response) {
    yield put({ promotionalGroupMembers: response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionalGroupMembersCount(action) {
  const { id, params } = action;
  let response;
  let xhr;

  if (_.has(params, "textFilter")) {
    const newParams = { textFilter: params.textFilter };
    const { response: res, xhr: x } = yield call(API.fetchPromotionalGroupMembersCount, id, newParams);
    response = res;
    xhr = x;
  } else {
    const { response: res, xhr: x } = yield call(API.fetchPromotionalGroupMembersCount, id);
    response = res;
    xhr = x;
  }

  if (response) {
    yield put({ memberCount: response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_SUCCEEDED });

    if (response.totalMembers > 0) {
      yield put({ id, params, type: actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionalGroupDetails(action) {
  const { response, xhr } = yield call(API.fetchPromotionalGroupItem, action.id);
  if (response) {
    yield put({ promotionalGroup: response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_PROMOTIONAL_GROUP_DETAILS_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* newPromotionalGroup(action) {
  const { response, xhr } = yield call(API.newPromotionalGroupItem, action.params);

  if (response) {
    yield put({ promotionalGroup: response, type: actionTypes.NEW_PROMOTIONAL_GROUP_SUCCEEDED });
    yield put({ state: false, type: appActionTypes.SET_BM_MODULE_EDIT_STATE });
    const { root, tree } = buildTreeItem({ category: "promotionalGroups", item: response, type: "PROMO_GROUP" });
    yield put(root);
    yield put(tree);
    history.push(`/bonus-manager/promotional-groups/${response.id}`);
    toastr.add({ message: i18n.t(`Bonus.Promotional Group Created`), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr) || JSON.parse(xhr.responseText).errors[0].message;
    yield put({ type: actionTypes.NEW_PROMOTIONAL_GROUP_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* copyPromotionalGroup(action) {
  const params = { ...action.params };

  if (_.has(params, "id")) {
    delete params.id;
  }

  params.name = `Copy of ${params.name}`;
  if (params.name.length > 64) {
    params.name = params.name.substring(0, 64);
  }

  const { response, xhr } = yield call(API.copyPromotionalGroupItem, params);

  if (response) {
    yield put({ promotionalGroup: response, type: actionTypes.NEW_PROMOTIONAL_GROUP_SUCCEEDED });

    const { root, tree } = buildTreeItem({ category: "promotionalGroups", item: response, type: "PROMO_GROUP" });
    yield put(root);
    yield put(tree);

    history.push(`/bonus-manager/promotional-groups/${response.id}`);
    toastr.add({ message: i18n.t(`Bonus.Promotional Group Copied`), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.NEW_PROMOTIONAL_GROUP_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* putPromotionalGroup(action) {
  const { response, xhr } = yield call(API.editPromotionalGroupItem, action.id, action.params);

  if (response) {
    yield put({ promotionalGroup: response, type: actionTypes.PUT_PROMOTIONAL_GROUP_SUCCEEDED });
    yield put({ state: false, type: appActionTypes.SET_BM_MODULE_EDIT_STATE });
    yield put({ modalName: "bmEditModal", type: "CLOSE_MODAL" });
    toastr.add({ message: i18n.t(`Bonus.Promotional Group Updated`), type: "SUCCESS" });

    const { bmGlobal } = store.getState().bonusManager;
    if (_.has(response, "retired") && response.retired && !bmGlobal.showRetired) {
      const { root, tree } = buildRemoveTreeItem({
        category: "promotionalGroups",
        id: response.id,
        type: "PROMO_GROUP",
      });
      yield put(root);
      yield put(tree);

      history.push(`/bonus-manager/promotional-groups/`);
    } else {
      const { root, tree } = buildTreeItem({
        category: "promotionalGroups",
        item: response,
        type: response.locked ? "LOCKED_PROMO_GROUP" : "PROMO_GROUP",
        update: true,
      });
      yield put(root);
      yield put(tree);
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.PUT_PROMOTIONAL_GROUP_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* deletePromotionalGroup(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.deletePromotionalGroup, id);
  if (response) {
    yield put({ response, type: actionTypes.DELETE_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED });

    const { root, tree } = buildRemoveTreeItem({ category: "promotionalGroups", id, type: "PROMO_GROUP" });
    yield put(root);
    yield put(tree);

    toastr.add({ message: i18n.t("Bonus.Promotional Group was successfully deleted") });
    history.push(`/bonus-manager/promotional-groups/`);
    yield put({ state: false, type: appActionTypes.SET_BM_MODULE_EDIT_STATE });
    yield put({ modalName: "bmDeleteModal", type: "CLOSE_MODAL" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.DELETE_PROMOTIONAL_GROUP_DETAILS_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* importMembers(action) {
  const { response, xhr } = yield call(API.importMembers, action.id, action.data);
  if (response) {
    yield put({ type: actionTypes.IMPORT_MEMBERS_SUCCEEDED });
    toastr.add({ message: i18n.t("Bonus.The Members of this promotional group were imported"), type: "SUCCESS" });
    yield put(closeModal("bmPromotionGroupImportConfirmModal"));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.IMPORT_MEMBERS_FAILED });
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put(closeModal("bmPromotionGroupImportConfirmModal"));
  }
}

function* exportMembers(action) {
  const { response, xhr } = yield call(API.exportMembers, action.id, action.fileName);
  if (response) {
    const windowUrl = window.URL || window.webkitURL;
    const blob = new Blob([response], { type: "application/csv" });
    const url = windowUrl.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = action.fileName;
    a.click();
    windowUrl.revokeObjectURL(url);
    yield put({ type: actionTypes.EXPORT_MEMBERS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.EXPORT_MEMBERS_FAILED });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

export default function* bonusManagerAppSaga() {
  yield takeLatest(actionTypes.NEW_PROMOTIONAL_GROUP, newPromotionalGroup);
  yield takeLatest(actionTypes.COPY_PROMOTIONAL_GROUP, copyPromotionalGroup);
  yield takeLatest(actionTypes.PUT_PROMOTIONAL_GROUP, putPromotionalGroup);
  yield takeLatest(actionTypes.DELETE_PROMOTIONAL_GROUP_DETAILS, deletePromotionalGroup);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUPS, fetchPromotionalGroups);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUP_FILTERS, fetchPromotionalGroupFilters);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS, fetchPromotionalGroupMembers);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT, fetchPromotionalGroupMembersCount);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUP_DETAILS, fetchPromotionalGroupDetails);
  yield takeLatest(actionTypes.IMPORT_MEMBERS, importMembers);
  yield takeLatest(actionTypes.EXPORT_MEMBERS, exportMembers);
}
