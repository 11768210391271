import originalAxios from "axios";

import api from "../api";

let fetchLookupPublishModesXhr = null;
let updateLookupPublishModesXhr = null;

export function fetchLookupPublishModes(action) {
  return new Promise((resolve, reject) => {
    const url = `/bo/publishmodes`;

    if (fetchLookupPublishModesXhr) {
      fetchLookupPublishModesXhr.cancel("cancelled due to concurrent request");
    }

    fetchLookupPublishModesXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchLookupPublishModesXhr);
      fetchLookupPublishModesXhr = null;
      resolve(response);
    } catch (err) {
      fetchLookupPublishModesXhr = null;
      reject(err);
    }
    fetchLookupPublishModesXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateLookupPublishModes(action) {
  return new Promise((resolve, reject) => {
    const url = `/bo/publishmodes/set`;

    if (updateLookupPublishModesXhr) {
      updateLookupPublishModesXhr.cancel("cancelled due to concurrent request");
    }

    updateLookupPublishModesXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, action.params, updateLookupPublishModesXhr);
      updateLookupPublishModesXhr = null;
      resolve(response);
    } catch (err) {
      updateLookupPublishModesXhr = null;
      reject(err);
    }
    updateLookupPublishModesXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
