import originalAxios from "axios";

import { addParametersToUrl } from "../utils";

import api from "./api";

let tree = [];
const markets = {};

let eventPathTreeXhr = null;
const eventMarketXhr = {};

export function resetEventPathTree() {
  tree = [];
  const markets = {};
  eventPathTreeXhr = null;
}

export function fetchEventMarketsCancel(eventId) {
  if (eventMarketXhr[eventId]) {
    eventMarketXhr[eventId].abort();
  }
}

export function fetchEventMarkets(eventId, parameters) {
  return new Promise((resolve, reject) => {
    let url = `/bo/service/eventpaths/event/${eventId}/markets`;
    tree = [];
    if (parameters) {
      url = addParametersToUrl(url, parameters);
    }

    if (eventMarketXhr[eventId]) {
      eventMarketXhr[eventId].cancel("cancelled due to concurrent request");
    }

    eventMarketXhr[eventId] = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, eventMarketXhr[eventId]);
      eventMarketXhr[eventId] = null;
      resolve(response);
    } catch (err) {
      eventMarketXhr[eventId] = null;
      reject(err);
    }
    eventMarketXhr[eventId] = null;
  })
    .then((response) => {
      markets[eventId] = response.data;

      return { response: response.data };
    })
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathTree(eventPathId, parameters, allowedSports) {
  return new Promise((resolve, reject) => {
    tree = [];
    let url = `/bo/service/eventpaths`;
    if (eventPathId) {
      url += `/${eventPathId}/tree`;
    }
    if (parameters) {
      url = addParametersToUrl(url, parameters);
    }

    if (eventPathTreeXhr) {
      eventPathTreeXhr.cancel("cancelled due to concurrent request");
    }

    eventPathTreeXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, eventPathTreeXhr);
      eventPathTreeXhr = null;
      if (response.eventPaths) {
        response.eventPaths = response.eventPaths.filter((eventPath) => {
          return true;
          if (typeof allowedSports === "undefined") {
            return true;
          }
          if (allowedSports.indexOf(eventPath.sportCode) > -1) {
            return true;
          }

          return false;
        });
      }
      tree = response.eventPaths;
      resolve(response);
    } catch (err) {
      eventPathTreeXhr = null;
      reject(err);
    }
    eventPathTreeXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function getChunk(id) {
  return new Promise((resolve, reject) => {
    const chunk = tree.find((element) => {
      if (typeof id === "string") {
        id = Number(id);
      }

      return element.id === id;
    });
    if (chunk) {
      resolve(chunk);
    } else {
      const error = null;
      reject(error);
    }
  })
    .then((chunk) => ({ chunk }))
    .catch((error) => ({ error }));
}

export function mapEventPathsToFilter(eventPaths, eventPathSearchString) {
  return eventPaths.map((eventPath) => {
    const matchedSearchString = eventPath.description.toLowerCase().indexOf(eventPathSearchString) > -1;
    let hasChildThatMatchedSearchString = false;
    let eventPaths = [];
    let events = [];
    if (eventPath.eventPaths && eventPath.eventPaths.length) {
      eventPaths = mapEventPathsToFilter(eventPath.eventPaths, eventPathSearchString);
      const filteredEventPaths = filterEventPaths(eventPath.eventPaths, eventPathSearchString);
      hasChildThatMatchedSearchString = !!filteredEventPaths.length;
      let i = 0;
      for (i = 0; i < eventPath.eventPaths.length; i++) {
        if (
          eventPath.eventPaths[i].eventPaths &&
          filterEventPaths(eventPath.eventPaths[i].eventPaths, eventPathSearchString).length
        ) {
          hasChildThatMatchedSearchString = true;
          break;
        }
        if (
          eventPath.eventPaths[i].events &&
          filterEventPaths(eventPath.eventPaths[i].events, eventPathSearchString).length
        ) {
          hasChildThatMatchedSearchString = true;
          break;
        }
      }
    }
    if (eventPath.events && eventPath.events.length && !hasChildThatMatchedSearchString) {
      events = mapEventPathsToFilter(eventPath.events, eventPathSearchString);
      hasChildThatMatchedSearchString = !!filterEventPaths(eventPath.events, eventPathSearchString).length;
    }

    return { ...eventPath, eventPaths, events, hasChildThatMatchedSearchString, matchedSearchString };
  });
}

function filterEventPaths(eventPaths, eventPathSearchString) {
  return eventPaths.filter((eventPath) => eventPath.description.toLowerCase().indexOf(eventPathSearchString) > -1);
}
