const constants = {
  FETCH_ALL_MAJOR_SORT_CONFIG: "MTE::FETCH_ALL_MAJOR_SORT_CONFIG",
  FETCH_ALL_MAJOR_SORT_CONFIG_SUCCEEDED: "MTE::FETCH_ALL_MAJOR_SORT_CONFIG_SUCCEEDED",
  FETCH_ALL_MARKET_TYPES_CONFIG: "UTL::FETCH_ALL_MARKET_TYPES_CONFIG",

  FETCH_ALL_MARKET_TYPES_CONFIG_FAILED: "UTL::FETCH_ALL_MARKET_TYPES_CONFIG_FAILED",
  FETCH_ALL_MARKET_TYPES_CONFIG_SUCCEEDED: "UTL::FETCH_ALL_MARKET_TYPES_CONFIG_SUCCEEDED",

  FETCH_ALL_PERIOD_TYPES_CONFIG: "UTL::FETCH_ALL_PERIOD_TYPES_CONFIG",
  FETCH_ALL_PERIOD_TYPES_CONFIG_FAILED: "UTL::FETCH_ALL_PERIOD_TYPES_CONFIG_FAILED",
  FETCH_ALL_PERIOD_TYPES_CONFIG_SUCCEEDED: "UTL::FETCH_ALL_PERIOD_TYPES_CONFIG_SUCCEEDED",

  FETCH_ALL_SPORTS_CONFIG: "UTL::FETCH_ALL_SPORTS_CONFIG",
  FETCH_ALL_SPORTS_CONFIG_FAILED: "UTL::FETCH_ALL_SPORTS_CONFIG_FAILED",
  FETCH_ALL_SPORTS_CONFIG_SUCCEEDED: "UTL::FETCH_ALL_SPORTS_CONFIG_SUCCEEDED",

  FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG",
  FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_FAILED: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_FAILED",

  FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_SUCCEEDED: "MTE::FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_SUCCEEDED",
  FETCH_PERIOD_TYPES_CONFIG_FAILED: "UTL::FETCH_PERIOD_TYPES_CONFIG_FAILED",
  FETCH_PERIOD_TYPES_CONFIG_SUCCEEDED: "UTL::FETCH_PERIOD_TYPES_CONFIG_SUCCEEDED",

  OPEN_UPDATE_POSITION_POPUP: "OPEN_UPDATE_POSITION_POPUP",
  SELECT_MARKET_CONFIG_LINE: "SELECT_MARKET_CONFIG_LINE",
  UNDO_FORM_TABLE_DATA_CONFIG: "UTL::UNDO_FORM_TABLE_DATA_CONFIG",

  UNDO_MARKET_PERIOD_TYPES_TABLE_CONFIG: "MTE::UNDO_MARKET_PERIOD_TYPES_TABLE_CONFIG",
  UPDATE_MAJOR_SORT_OPTION_CONFIG: "MTE::UPDATE_MAJOR_SORT_OPTION_CONFIG",
  UPDATE_MARKET_FORM_CONFIG: "MTE::UPDATE_MARKET_FORM_CONFIG",

  UPDATE_MARKET_PERIOD_TYPES_CONFIG: "MTE::UPDATE_MARKET_PERIOD_TYPES_CONFIG",

  UPDATE_MARKET_PERIOD_TYPES_CONFIG_FAILED: "MTE::UPDATE_MARKET_PERIOD_TYPES_CONFIG_FAILED",
  UPDATE_MARKET_PERIOD_TYPES_CONFIG_SUCCEEDED: "MTE::UPDATE_MARKET_PERIOD_TYPES_CONFIG_SUCCEEDED",
  UPDATE_MARKET_TYPES_CONFIG: "UTL::UPDATE_MARKET_TYPES_CONFIG",

  UPDATE_MARKET_TYPES_CONFIG_FAILED: "MTE::UPDATE_MARKET_TYPES_CONFIG_FAILED",
  UPDATE_MARKET_TYPES_CONFIG_SUCCEEDED: "MTE::UPDATE_MARKET_TYPES_CONFIG_SUCCEEDED",
  UPDATE_PERIOD_TYPES_CONFIG: "MTE::UPDATE_PERIOD_TYPES_CONFIG",

  UPDATE_PERIOD_TYPES_CONFIG_FAILED: "MTE::UPDATE_PERIOD_TYPES_CONFIG_FAILED",
  UPDATE_PERIOD_TYPES_CONFIG_SUCCEEDED: "MTE::UPDATE_PERIOD_TYPES_CONFIG_SUCCEEDED",
  UPDATE_POSITION: "UPDATE_POSITION",
};

export default constants;
