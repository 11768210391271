import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services/apiUtils";


let apiConstants = {};
export var languageAtLogin = {};
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};
const requests = {};

const keys = {
  accountStatuses: `/bo/staticdata/accountstatuses`,
  accountTypes: `/bo/staticdata/accounttypes`,
  allOrigins: `/bo/origins`,
  bookTypes: { headers: { "X-P8-Datasource": "database" }, url: `/bo/books/types` },
  brands: `/bo/brands`,
  cancelReasons: `/bo/transactions/cancel/reasons`,
  channels: `/bo/channels`,
  closureReasons: `/bo/staticdata/closurereasons`,
  countries: `/bo/countries`,
  currencies: `/bo/currencies`,
  languages: `/bo/languages`,
  liabilityIndicatorsActions: `/bo/liabilityindicators/actions`,
  lines: `/bo/lines/list?onlyActiveLines=true&agentId=-4`,
  messageTriggers: `/bo/staticdata/messageTriggers`,
  origins: `/bo/origins?onlyDisplayed=true`,
  betCombinationTypes: "/bo/staticdata/betCombinationTypes",
  outcomeWagerLimitsActions: `/bo/outcomewagerlimits/actions`,
  betTypes: "/bo/staticdata/betTypes?includeSubTypes=true",
  packages: `/bo/staticdata/packages`,
  assignableBrands: "/bo/brands/assignable",
  paymentColumns: "/bo/appcolumns?appId=22",
  jackpotBetList: "/bo/jackpots/list",
  paymentRejectReasons: "/bo/staticdata/rejectionReasons",
  marketTypes: "/bo/marketTypes",
  paymentStatus: "/bo/financials/payments/payrecstatus",
  performancePeriods: `/bo/accountperformance/periods`,
  placeTerms: `/bo/staticdata/placeterms/options`,
  playerProfiles: `/bo/playerprofiles`,
  priceFormats: `/bo/prices/formats?&onlyEnabled=true`,
  priceFormatsLong: `/bo/staticdata/prices/formats?longDescription=true&onlyEnabled=true`,
  wagerLimitsGroups: `/bo/accountwagerlimits/groups`,
  priceFormatsShort: `/bo/prices/formats?&onlyEnabled=true&description=short`,
  promogroupfilters: "bo/promotionalgroups/promotionalgroupfilters",
  voidReasons: `/bo/voidreasons/reasons`,
  promotionFilters: "/bo/staticdata/promotions/filters",
  promotionRewardBasis: "/bo/staticdata/promotions/rewardbasis",
  promotionRewardTypes: "/bo/staticdata/promotions/rewardtypes",
  promotionRewards: "/bo/staticdata/promotions/rewardtypes",
  referralMethods: `/bo/referralmethods`,
  riskColumns: `/bo/riskcolumns/all`,
  riskSports: `/bo/sports?setEventTypeEnabled=true`,
  securityQuestions: `/bo/securityquestions`,
  shops: "/bo/shops",
  sports: "/bo/sports",
  tags: `/bo/eventpaths/tags`,
  templates: `/bo/templates`,
  wagerLimitsGroupsDescription: " /bo/wagerlimitgroups?activeOnly=true",
};

export function fetchConstants(keysArray) {
  const promises = [];
  for (let i = 0; i < keysArray.length; i++) {
    const key = keysArray[i];
    const target = keys[key];
    let url;
    let headers;
    if (typeof target === "object") {
      url = target.url;
      headers = target.headers;
    } else {
      url = target;
      headers = {};
    }

    const promise = new Promise((resolve, reject) => {
      if (apiConstants[key]) {
        resolve(apiConstants[key]);
      } else {
        if (requests[key]) {
          requests[key].abort();
        }
        try {
          const response = api.get(url);
          delete requests[key];
          resolve(response);
        } catch (err) {
          delete requests[key];
          reject(err);
        }
      }
    });
    promises.push(promise);
  }

  return Promise.all(promises)
    .then((response) => {
      const responseData = response.map((x) => x.data);

      const combinedResponse = {};
      for (let i = 0; i < keysArray.length; i++) {
        const key = keysArray[i];
        combinedResponse[key] = responseData[i]; // what we are about to send back
        apiConstants[key] = { data: responseData[i] }; // cache for requests in the future (using the expected format coming from the promises...)
      }

      //          apiConstants[key] = response;

      return { response: combinedResponse };
    })
    .catch((xhr) => ({ xhr }));
}

export function getBrandDetails(path) {
  const url = `/bo/brands/details`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function clearApiConstants() {
  apiConstants = {};
}
export function updateLanguage(lang) {
  languageAtLogin = lang;
}
