import _ from "lodash";

import constants, { CATEGORY_MAP } from "./constants";
import { traverseChildren, generateKey } from "./helpers";

import { makeIterable } from "utils";

const initialState = {
  campaignList: {},
  campaignOption: 3,
  categories: ["campaigns", "promotions", "promotionalGroups", "termsAndConditions"],
  confirmUnsavedModalAction: null,
  isFetchingTree: false,
  isFetchingTreeFailed: false,
  isTreeLoaded: false,
  parentToExpand: null,
  searchKey: "",
  treeMap: {},
};

const bonusManagerTree = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_TREE:
      return {
        ...state,
        isFetchingTree: true,
        isFetchingTreeFailed: false,
        isTreeLoaded: false,
        parentToExpand: action.parent || null,
        searchKey: action.searchKey,
        treeMap: {},
      };
    case constants.FETCH_TREE_SUCCEEDED:
      if (true) {
        const data = {
          ...action.response,
          campaigns: _.sortBy(action.response.campaigns, (i) => i.name.toLowerCase()),
          promotionalGroups: _.sortBy(action.response.promotionalGroups, (i) => i.name.toLowerCase()),
          promotions: _.sortBy(action.response.promotions, (i) => !!i.name && i.name.toLowerCase()),
          termsAndConditions: _.sortBy(action.response.termsAndConditions, (i) => i.name.toLowerCase()),
        };

        let treeMap = { ...state.treeMap };
        [...makeIterable(data, true)].forEach((cat) => {
          if (!treeMap[cat.key]) {
            const parentUrl = CATEGORY_MAP[cat.key].url;
            treeMap[cat.key] = {
              ...CATEGORY_MAP[cat.key],
              isActive: false,
              isExpanded: cat.key === state.parentToExpand,
              key: cat.key,
              marked: false,
              type: "CATEGORY",
            };
            treeMap[cat.key].children = cat.value.map((val) => {
              treeMap = traverseChildren({ item: val, marked: false, parentUrl, treeMap });

              return generateKey(val);
            });
          }
        });

        return {
          ...state,
          campaignList: data,
          isFetchingTree: false,
          isTreeLoaded: true,
          treeMap,
        };
      }
      break;
    case constants.FETCH_TREE_FAILED:
      return {
        ...state,
        isFetchingTree: false,
        isFetchingTreeFailed: true,
      };
    case constants.SET_TREE_ACTIVE_ITEM:
      if (true) {
        const treeMap = { ...state.treeMap };
        const newTreeMap = {};
        [...makeIterable(treeMap, true)].forEach((item) => {
          newTreeMap[item.key] = {
            ...item.value,
            isActive: false,
          };
        });

        // const prevActive = [...makeIterable(state.treeMap, true)].find(item => item.value.isExpanded)
        const newMap = {
          ...state.treeMap[action.key],
          // isExpanded: !state.treeMap[action.key].isExpanded,
          // isActive: !state.treeMap[action.key].isActive,
        };

        return {
          ...state,
          treeMap: {
            ...newTreeMap,
            [action.key]: newMap,
          },
        };
      }

      return { ...state };

    case constants.EXPAND_SELECTION:
      return {
        ...state,
        treeMap: {
          ...state.treeMap,
          [action.item.key]: {
            ...state.treeMap[action.item.key],
            isExpanded: !state.treeMap[action.item.key].isExpanded,
          },
        },
      };

    case constants.COLLAPSE_SELECTION:
      return {
        ...state,
        treeMap: {
          ...state.treeMap,
          [action.key]: {
            ...state.treeMap[action.key],
            isExpanded: true,
          },
        },
      };

    case constants.CHANGE_CAMPAIGN_FILTER:
      return {
        ...state,
        campaignOption: action.campaignOption,
      };

    case constants.SEARCH_TREE_ITEM:
      if (true) {
        const tree = {};
        let treeMap = {};
        let data = {};
        if (action.data) {
          data = {
            ...data,
            ...action.data,
            campaigns: _.sortBy(action.data.campaigns, (i) => i.name.toLowerCase()),
            promotionalGroups: _.sortBy(action.data.promotionalGroups, (i) => i.name.toLowerCase()),
            promotions: _.sortBy(action.data.promotions, (i) => i.name.toLowerCase()),
            termsAndConditions: _.sortBy(action.data.termsAndConditions, (i) => i.name.toLowerCase()),
          };
        }

        const list = action.data ? data : { ...state.campaignList };

        Object.keys(list).forEach((c) => {
          tree[c] = list[c].filter((item) => item.name.toLowerCase().indexOf(action.searchKey.toLowerCase()) >= 0);
        });
        [...makeIterable(tree, true)].forEach((cat) => {
          if (!treeMap[cat.key]) {
            const parentUrl = CATEGORY_MAP[cat.key].url;
            treeMap[cat.key] = {
              ...CATEGORY_MAP[cat.key],
              isActive: false,
              isExpanded: !_.isEqual(state.campaignList, tree),
              key: cat.key,
              marked: false,
              type: "CATEGORY",
            };
            treeMap[cat.key].children = cat.value.map((val) => {
              treeMap = traverseChildren({
                item: val,
                marked: !_.isEqual(state.campaignList, tree),
                parentUrl,
                treeMap,
              });

              return generateKey(val);
            });
          }
        });

        if (action.data) {
          return {
            ...state,
            campaignList: action.data,
            isFetchingTree: false,
            isTreeLoaded: true,
            searchKey: action.searchKey,
            treeMap,
          };
        }

        return {
          ...state,
          searchKey: action.searchKey,
          treeMap,
        };
      }
      break;

    case constants.RESTORE_OLD_TREE_ITEM:
      if (true) {
        const oldItem = { ...state.campaignList };
        let treeMap = { ...state.treeMap };
        [...makeIterable(oldItem, true)].forEach((cat) => {
          if (!treeMap[cat.key]) {
            const parentUrl = CATEGORY_MAP[cat.key].url;
            treeMap[cat.key] = {
              ...CATEGORY_MAP[cat.key],
              isActive: false,
              isExpanded: cat.key === state.parentToExpand,
              key: cat.key,
              marked: false,
              type: "CATEGORY",
            };
            treeMap[cat.key].children = cat.value.map((val) => {
              treeMap = traverseChildren({ item: val, marked: false, parentUrl, treeMap });

              return generateKey(val);
            });
          }
        });

        return {
          ...state,
          isFetchingTree: false,
          isTreeLoaded: true,
          treeMap,
        };
      }
      break;

    case constants.ADD_ITEM_TO_TREE_ROOT:
      if (!action.update) {
        return {
          ...state,
          campaignList: {
            ...state.campaignList,
            [action.key]: [...state.campaignList[action.key], action.item],
          },
        };
      }
      const item = state.campaignList[action.key].find((item) => item.id === action.item.id);
      const newItem = {
        ...item,
        ...action.item,
      };
      const idx = state.campaignList[action.key].indexOf(item);
      const newDataSet = [...state.campaignList[action.key]].splice(idx, 1, newItem);

      return {
        ...state,
        campaignList: {
          ...state.campaignList,
          [action.key]: newDataSet,
        },
      };

    case constants.ADD_ITEM_TO_TREE:
      if (true) {
        const parentItem = { ...state.treeMap[action.key] };
        !action.update && parentItem.children.push(action.newKey);

        return {
          ...state,
          treeMap: {
            ...state.treeMap,
            ...action.treeMap,
            [action.key]: {
              ...parentItem,
            },
          },
        };
      }
      break;

    case constants.REMOVE_ITEM_FROM_TREE_ROOT:
      if (true) {
        const childList = [...state.campaignList[action.key]];
        const item = childList.find((i) => i.id === action.id);
        if (item) {
          const idx = childList.indexOf(item);
          if (idx >= 0) {
            childList.splice(idx, 1);
          }

          return {
            ...state,
            campaignList: {
              [action.key]: [...childList],
            },
          };
        }
      }
      break;

    case constants.REMOVE_ITEM_FROM_TREE:
      if (true) {
        const parentItem = { ...state.treeMap[action.key] };
        const idx = parentItem.children.indexOf(action.newKey);
        if (idx >= 0) {
          parentItem.children.splice(idx, 1);
        }
        const newTreeMap = { ...state.treeMap };
        delete newTreeMap[action.key];

        return {
          ...state,
          treeMap: {
            ...newTreeMap,
            [action.key]: {
              ...parentItem,
            },
          },
        };
      }

    case constants.CONFIRM_UNSAVED_MODAL:
      if (true) {
        return {
          ...state,
          confirmUnsavedModalAction: action.func,
        };
      }
      break;

    default:
      return { ...state };
  }

  return { ...state };
};

export default bonusManagerTree;
