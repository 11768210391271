import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import * as API from "../../services/riskManager/globalAccumulatorRisks";
import { objectToArray, formatFilterDates } from "../../utils";

function* fetchGlobalAccumulatorRisks(action) {
  const { response, xhr } = yield call(API.fetchGlobalAccumulatorRisks, action);
  if (response) {
    yield put({ globalAccumulatorRisks: response, type: actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS_FAILED });
  }
}

export default function* globalAccumulatorRisksSaga() {
  yield takeLatest(actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS, fetchGlobalAccumulatorRisks);
}
