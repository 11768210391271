
import actionTypes, { DUMMY_ID, availableDates } from "./constants";
import {
  generateParameters,
  generatePathsMap,
  generateChildMarkets,
  generatePathAncestors,
  SET_JACKPOT_BET_TYPE_FILTER,
  search,
  getSortedPaths,
  deletePath,
  modifyParentPathsCountBy,
  addInitialCountToParentPath,
  addCountToParentPath,
  subtractCountFromParentPath,
  generateSportsDropdown,
} from "./helpers";

import filterTypes from "constants/filterTypes";
import eventCreatorConstants from "containersV2/EventCreator/App/constants";
import { makeIterable } from "utils";

const datesFilter = availableDates[1];
const defaultParams = { includeEvents: true, virtual: 0 };
const datesParamFormat = "";
const marketStatusFilter = [...makeIterable(filterTypes.STATUS)][0].value;
const sportCode = "FOOT";

const initialState = {
  // the id of selected path. On first load, this will be the id of the default sport
  activePathAncestors: [240],

  activePathId: 240,

  // the id of selected sport.
  activeSportCode: "FOOT",

  activeSportDescription: "Football",

  // TODO: get from config
  activeSportId: 240,

  baseUrl: "/event-creator",
  config: {
    displayCheckbox: false, // a checkbox component will be rendered beside path name based on this config
  },
  datesFilter,

  defaultParams,

  isExpanded: false,

  isFetchingEPT: false,

  isFetchingEPTFailed: false,
  // use to determine if api call is on first load. Set as false upon first fetchEPT success
  isFiltered: false,
  isFirstLoad: true,

  isLoaded: false,

  isSavingPathsOrder: false,

  isSavingPathsOrderFailed: false,

  // use to determine if tree is already loaded
  isSorted: false,

  isVirtualActive: false,

  jackpotBetStatusFilter: "SETTLED",
  marketStatusFilter,
  parameters: generateParameters({ datesFilter, datesParamFormat, defaultParams, marketStatusFilter, sportCode }),
  pathSelections: [],
  pathSelectionsMap: {},
  pathsMap: {},
  pathsOrder: {},
  searchOption: "",
  searchStr: "",

  sportsMap: {},
  sportsTreeData: {},
};

const sportsTree = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PATH_BASE_URL:
      return { ...state, baseUrl: action.baseUrl };
    case actionTypes.FETCH_EPT:
    case actionTypes.FETCH_RESTRICTION_EPT:
      return {
        ...state,
        isFetchingEPT: true,
        isFetchingEPTFailed: false,

        isLoaded: false,
        // clear pathsOrder state when sports tree is refreshed
        // since eventPaths will be overwritten by the new data
        pathsOrder: {},
      };
    case actionTypes.FETCH_EVENT_MARKETS_SUCCEEDED:
      return ((state) => {
        const path = state.pathsMap[action.eventId];
        const pathsMap = generateChildMarkets({
          activePathId: state.activePathId,

          baseUrl: state.baseUrl,

          eventId: action.eventId,

          level: path.level + 1,

          markets: action.response,

          parentId: action.eventId,
          // PUZZLE: it doesn't update on first expand of event when passed as {...state.pathsMap}
          pathsMap: { ...state.pathsMap, [action.eventId]: { ...path } },
          sportCode: path.sportCode.toLowerCase(),
        });

        return {
          ...state,
          pathsMap: {
            ...state.pathsMap,
            ...pathsMap,
          },
        };
      })(state);

    case actionTypes.FETCH_EPT_SUCCEEDED:
      return ((state) => {
        const sportsTreeData = action.response;
        const sport = action.response.eventPaths.find((path) => path.id === state.activeSportId);
        // Either create a new map from scratch or use the old map
        const baseMap = action.persistOldPathsMap ? { ...state.pathsMap } : {};
        let pathsMap = {};
        if (sport) {
          pathsMap = generatePathsMap({
            activePathId: state.activePathId,
            baseUrl: state.baseUrl,
            level: 0,
            newPathsMap: baseMap,
            parentId: 0,
            paths: action.response.eventPaths.filter((path) => path.id === state.activeSportId),
            pathsMap: { ...state.pathsMap },
            sportCode: sport.sportCode.toLowerCase(),
          });
        }
        const sportsMap = action.response.eventPaths.reduce((accu, sport) => {
          accu[sport.id] = {
            count: sport.count,
            description: `${sport.description} (${sport.count})`,
            id: sport.id,
            rawDesc: sport.description,
            sportCode: sport.sportCode,
          };

          return accu;
        }, {});

        // this is to make the count similar to eventCount in Event Creator
        // if being used in Risk Manager
        if (state.config.useCount) {
          const events = [];
          [...makeIterable(pathsMap)].map((path) => {
            if (path.type === "event") {
              events.push(path);
            } else if (path.type === "path") {
              path.count = 0; // set to zero by default
            }
          });
          if (events.length) {
            events.forEach((event) => {
              addInitialCountToParentPath({ path: pathsMap[event.parentId], pathsMap });
            });
          } else {
            // if no results found, set active sport's count to 0
            if (pathsMap[state.activeSportId]) {
              pathsMap[state.activeSportId].count = 0;
            }
          }
        }
        if (state.searchStr) {
          // search again if searchStr is set after fetching the tree
          pathsMap = search({ pathsMap, searchOption: state.searchOption, searchStr: state.searchStr, state });
        }
        if (state.isExpanded) {
          pathsMap = [...makeIterable(pathsMap)].reduce((accu, val) => {
            if (val.eventType) {
              accu[val.id] = val;
            } else {
              accu[val.id] = { ...val, isExpanded: true };
            }

            return accu;
          }, {});
        }
        if (state.isSorted) {
          pathsMap = [...makeIterable(pathsMap)].reduce((accu, val) => {
            let sortedEventPaths = val.eventPaths;
            let sortedEvents = val.events;
            if (val.eventPaths.length) {
              sortedEventPaths = getSortedPaths(val.eventPaths, pathsMap);
            }
            if (val.events.length) {
              sortedEvents = getSortedPaths(val.events, pathsMap);
            }
            accu[val.id] = { ...val, sortedEventPaths, sortedEvents };

            return accu;
          }, {});
        }

        return {
          ...state,
          isFetchingEPT: false,
          isFirstLoad: false,
          isLoaded: true,
          pathsMap,
          sportsMap,
          sportsTreeData,
        };
      })(state);

    case actionTypes.FETCH_EPT_FAILED:
      return { ...state, isFetchingEPT: false, isFetchingEPTFailed: true };

    case actionTypes.GET_UPDATED_VALUES_SPORT:
      return ((state) => {
        if (state.sportsTreeData.eventPaths) {
          const sport = state.sportsTreeData.eventPaths.find((path) => path.id === state.activeSportId);
          // Either create a new map from scratch or use the old map
          const baseMap = action.persistOldPathsMap ? { ...state.pathsMap } : {};
          let pathsMap = {};
          if (sport) {
            pathsMap = generatePathsMap({
              activePathId: state.activePathId,
              baseUrl: state.baseUrl,
              level: 0,
              newPathsMap: baseMap,
              parentId: 0,
              paths: state.sportsTreeData.eventPaths.filter((path) => path.id === state.activeSportId),
              pathsMap: { ...state.pathsMap },
              sportCode: sport.sportCode.toLowerCase(),
            });
          }
          const sportsMap = state.sportsTreeData.eventPaths.reduce((accu, sport) => {
            accu[sport.id] = {
              count: sport.count,
              description: `${sport.description} (${sport.count})`,
              id: sport.id,
              rawDesc: sport.description,
              sportCode: sport.sportCode,
            };

            return accu;
          }, {});

          // this is to make the count similar to eventCount in Event Creator
          // if being used in Risk Manager
          if (state.config.useCount) {
            const events = [];
            [...makeIterable(pathsMap)].map((path) => {
              if (path.type === "event") {
                events.push(path);
              } else if (path.type === "path") {
                path.count = 0; // set to zero by default
              }
            });
            if (events.length) {
              events.forEach((event) => {
                addInitialCountToParentPath({ path: pathsMap[event.parentId], pathsMap });
              });
            } else {
              // if no results found, set active sport's count to 0
              if (pathsMap[state.activeSportId]) {
                pathsMap[state.activeSportId].count = 0;
              }
            }
          }
          if (state.searchStr) {
            // search again if searchStr is set after fetching the tree
            pathsMap = search({ pathsMap, searchOption: state.searchOption, searchStr: state.searchStr, state });
          }
          if (state.isExpanded) {
            pathsMap = [...makeIterable(pathsMap)].reduce((accu, val) => {
              if (val.eventType) {
                accu[val.id] = val;
              } else {
                accu[val.id] = { ...val, isExpanded: true };
              }

              return accu;
            }, {});
          }
          if (state.isSorted) {
            pathsMap = [...makeIterable(pathsMap)].reduce((accu, val) => {
              let sortedEventPaths = val.eventPaths;
              let sortedEvents = val.events;
              if (val.eventPaths.length) {
                sortedEventPaths = getSortedPaths(val.eventPaths, pathsMap);
              }
              if (val.events.length) {
                sortedEvents = getSortedPaths(val.events, pathsMap);
              }
              accu[val.id] = { ...val, sortedEventPaths, sortedEvents };

              return accu;
            }, {});
          }

          return {
            ...state,
            isFetchingEPT: false,
            isFirstLoad: false,
            isLoaded: true,
            pathsMap,
            sportsMap,
          };
        }

        return state;
      })(state);

    case actionTypes.UPDATE_PATH:
      return {
        ...state,
        pathsMap: {
          ...state.pathsMap,
          [action.id]: { ...state.pathsMap[action.id], ...action.data },
        },
      };
    case actionTypes.UPDATE_PATHS:
      const updatedPaths = action.pathsArray.reduce((accu, val) => {
        accu[val.id] = { ...state.pathsMap[val.id], ...val.data };

        return accu;
      }, {});

      return {
        ...state,
        pathsMap: {
          ...state.pathsMap,
          ...updatedPaths,
        },
      };
    case actionTypes.UPDATE_ACTIVE_SPORT_CODE_AND_ID:
      return {
        ...state,
        activePathAncestors: [action.activeSportId],
        activePathId: action.activeSportId,
        activeSportCode: action.activeSportCode,
        activeSportDescription: action.activeSportDescription,
        activeSportId: action.activeSportId,
        parameters: generateParameters({
          datesFilter: action.datesFilter ? action.datesFilter : state.datesFilter,
          datesParamFormat: state.datesParamFormat,
          defaultParams: state.defaultParams,
          marketStatusFilter: state.marketStatusFilter,
          sportCode: action.activeSportCode,
        }),
        pathSelections: [],
        pathSelectionsMap: {},
      };
    case actionTypes.UPDATE_ACTIVE_PATH_ID:
      const currentActivePath = { ...state.pathsMap[state.activePathId], isActive: false };
      const newActivePath = { ...state.pathsMap[action.activePathId], isActive: true };
      if (!state.pathsMap[action.activePathId]) {
        return {
          ...state,
          activePathAncestors: [],
          activePathId: action.activePathId,
          pathsMap: { ...state.pathsMap, [currentActivePath.id]: currentActivePath },
        };
      }
      const activePathAncestors = generatePathAncestors({
        ancestors: [],
        path: newActivePath,
        pathsMap: state.pathsMap,
      });
      const _pathsMap = {
        ...state.pathsMap,
        [currentActivePath.id]: currentActivePath,
        [newActivePath.id]: newActivePath,
      };
      if (!currentActivePath.id) {
        delete _pathsMap[currentActivePath.id];
      }

      return {
        ...state,
        activePathAncestors,
        activePathId: action.activePathId,
        pathsMap: _pathsMap,
      };
    case actionTypes.UPDATE_ACTIVE_PATH_ANCESTORS:
      return {
        ...state,
        activePathAncestors: action.activePathAncestors,
      };

    case actionTypes.UPDATE_TOGGLE_STATE:
      return ((state) => {
        let pathsMap = {};
        let isExpanded;
        if (action.toggleState.expandedAll) {
          isExpanded = true;
          pathsMap = [...makeIterable(state.pathsMap)].reduce((accu, val) => {
            if (val.eventType) {
              accu[val.id] = val;
            } else {
              accu[val.id] = { ...val, isExpanded: true };
            }

            return accu;
          }, {});
        } else if (action.toggleState.collapsedAll) {
          isExpanded = false;
          pathsMap = [...makeIterable(state.pathsMap)].reduce((accu, val) => {
            if (val.level === 0) {
              accu[val.id] = val;
            } else {
              accu[val.id] = { ...val, isExpanded: false };
            }

            return accu;
          }, {});
        }

        return { ...state, isExpanded, pathsMap };
      })(state);

    case actionTypes.UPDATE_FILTER_STATE:
      return { ...state, isFiltered: action.isFiltered };

    case actionTypes.UPDATE_SORT_STATE:
      return ((state) => {
        let pathsMap = state.pathsMap;
        if (action.isSorted) {
          pathsMap = [...makeIterable(state.pathsMap)].reduce((accu, val) => {
            let sortedEventPaths = val.eventPaths;
            let sortedEvents = val.events;
            if (val.eventPaths.length) {
              sortedEventPaths = getSortedPaths(val.eventPaths, pathsMap);
            }
            if (val.events.length) {
              sortedEvents = getSortedPaths(val.events, pathsMap);
            }
            accu[val.id] = { ...val, sortedEventPaths, sortedEvents };

            return accu;
          }, {});
        }

        return { ...state, isSorted: action.isSorted, pathsMap };
      })(state);

    case actionTypes.UPDATE_VIRTUAL_SPORT:
      return ((state) => {
        const defaultParams = state.defaultParams;
        if (action.isVirtual) {
          defaultParams["virtual"] = 1;
        } else {
          defaultParams["virtual"] = 0;
        }

        return {
          ...state,
          defaultParams,
          isVirtualActive: action.isVirtual,
          parameters: generateParameters({
            datesFilter: state.datesFilter,
            datesParamFormat: state.datesParamFormat,
            defaultParams,
            marketStatusFilter: action.marketStatusFilter,
            sportCode: action.activeSportCode,
          }),
        };
      })(state);

    case actionTypes.SET_DATES_FILTER:
      return {
        ...state,
        datesFilter: action.datesFilter,
        parameters: generateParameters({
          datesFilter: action.datesFilter,
          datesParamFormat: state.datesParamFormat,
          defaultParams: state.defaultParams,
          marketStatusFilter: state.marketStatusFilter,
          sportCode: state.activeSportCode,
        }),
      };
    case actionTypes.SET_MARKET_STATUS_FILTER:
      return {
        ...state,
        marketStatusFilter: action.marketStatusFilter,
        parameters: generateParameters({
          datesFilter: state.datesFilter,
          datesParamFormat: state.datesParamFormat,
          defaultParams: state.defaultParams,
          marketStatusFilter: action.marketStatusFilter,
          sportCode: state.activeSportCode,
        }),
      };

    case actionTypes.UPDATE_CONFIG: // update config
      return {
        ...state,
        config: { ...state.config, ...action.config },
      };

    case actionTypes.SET_PARAMETERS: // directly set parameters from components
      return {
        ...state,
        parameters: action.parameters,
      };
    case actionTypes.SET_DEFAULT_PARAMETERS: // directly set the default parameters from components
      return {
        ...state,
        defaultParams: action.defaultParams,
      };
    // set how date params are formatted: fromDate/toDate or dateFrom/dateTo
    case actionTypes.SET_DATES_PARAM_FORMAT:
      return {
        ...state,
        datesParamFormat: action.datesParamFormat,
      };
    case actionTypes.SET_INITIAL_STATE_PROPS:
      return {
        ...state,
        ...action.props,
      };

    case actionTypes.UPDATE_SEARCH_STR:
      return ((state) => {
        const { pathsMap } = state;
        const searchStr = action.searchStr.trim();
        // if (searchStr === state.searchStr) {
        //   return state
        // }
        const newPathsMap = search({ pathsMap, searchOption: state.searchOption, searchStr, state });

        return {
          ...state,
          pathsMap: newPathsMap,
          searchStr,
        };
      })(state);

    case actionTypes.UPDATE_SEARCH_OPTION:
      return ((state) => {
        const searchStr = state.searchStr || "";
        const { pathsMap } = state;
        const searchOption = action.searchOption.trim();

        return {
          ...state,
          searchOption,
        };
      })(state);

    case actionTypes.CREATE_PATH:
      return ((state) => {
        const parentPath = state.pathsMap[action.data.parentId];
        const path = { ...action.data };
        const otherProps = { count: parentPath.count ? parentPath.count + 1 : 1 };
        let pathForModification = null;
        const searchStr = state.searchStr;
        if (path.type === "path") {
          otherProps.eventPaths = [...parentPath.eventPaths, action.id];
        } else if (path.type === "event") {
          // increment count and eventCount as well
          otherProps.events = [...parentPath.events, action.id];
          otherProps.count = parentPath.count ? parentPath.count + 1 : 1;
          otherProps.eventCount = parentPath.eventCount ? parentPath.eventCount + 1 : 1;
          pathForModification = { ...parentPath };
        }
        let pathsMap = {
          ...state.pathsMap,
          [action.id]: path,
          [path.parentId]: {
            ...parentPath,
            ...otherProps,
            isExpanded: true,
          },
        };
        if (pathForModification && pathForModification.parentId) {
          pathsMap = modifyParentPathsCountBy(pathsMap, pathsMap[pathForModification.parentId], 1);
        }
        if (searchStr) {
          // show the dummy event (placeholder) when creating event
          path.hasDirectMatchOnParent = true;
          addCountToParentPath({ path, pathsMap, searchStr });
        }

        return {
          ...state,
          pathsMap,
        };
      })(state);

    case actionTypes.FINALIZE_PATH:
      return ((state) => {
        const parentPath = state.pathsMap[action.data.parentId];
        const path = state.pathsMap[DUMMY_ID];
        let prefix = "p";
        let targetKey = "eventPaths";
        if (path.type === "event") {
          prefix = "e";
          targetKey = "events";
        }
        const pathsMap = { ...state.pathsMap };
        pathsMap[action.data.id] = {
          ...pathsMap[DUMMY_ID],
          ...action.data,
          url: `${state.baseUrl}/${parentPath.sportCode.toLowerCase()}/${prefix}${action.data.id}`,
        };
        const targetIndex = parentPath[targetKey].findIndex((id) => id === DUMMY_ID);
        pathsMap[parentPath.id] = {
          ...parentPath,
          [targetKey]: [
            ...parentPath[targetKey].slice(0, targetIndex),
            ...parentPath[targetKey].slice(targetIndex + 1),
            action.data.id,
          ],
        };
        delete pathsMap[DUMMY_ID];

        return { ...state, activePathId: action.data.id, pathsMap };
      })(state);

    case actionTypes.DELETE_PATH:
      return ((state) => {
        const pathsMap = subtractCountFromParentPath({ path: state.pathsMap[action.id], pathsMap: state.pathsMap });
        const newState = { ...state, pathsMap };

        return deletePath({ id: action.id, state: newState });
      })(state);

    case actionTypes.DELETE_PATHS:
      return ((state) => {
        let newState = { ...state };
        const pathSelectionsMap = { ...state.pathSelectionsMap };
        let pathSelections = [...state.pathSelections];
        for (const id of action.ids) {
          newState = deletePath({ id, state: newState });
          delete pathSelectionsMap[id];
          pathSelections = pathSelections.filter((pathId) => pathId !== id);
        }

        return { ...newState, pathSelections, pathSelectionsMap };
      })(state);

    case actionTypes.SET_NEW_PATHS_ORDER:
      return { ...state, pathsOrder: { ...state.pathsOrder, ...action.pathsOrder } };

    case actionTypes.SAVE_NEW_PATHS_ORDER:
      return { ...state, isSavingPathsOrder: true, isSavingPathsOrderFailed: false };
    case actionTypes.SAVE_NEW_PATHS_ORDER_SUCCEEDED:
      return { ...state, isSavingPathsOrder: false, pathsOrder: {} };
    case actionTypes.SET_AS_FIRST_LOAD:
      return { ...state, isFirstLoad: action.isFirstLoad };
    case actionTypes.ADD_PATH_TO_SELECTIONS:
      return {
        ...state,
        pathSelections: state.pathSelectionsMap[action.path.id]
          ? state.pathSelections
          : [...state.pathSelections, action.path.id],
        pathSelectionsMap: {
          ...state.pathSelectionsMap,
          [action.path.id]: action.path,
        },
      };
    case actionTypes.REMOVE_PATH_FROM_SELECTIONS:
      return ((state) => {
        const newPathSelectionsMap = { ...state.pathSelectionsMap };
        const pathSelections = state.pathSelections.filter((pathId) => pathId !== action.path.id);
        delete newPathSelectionsMap[action.path.id];

        return { ...state, pathSelections, pathSelectionsMap: newPathSelectionsMap };
      })(state);
    case eventCreatorConstants.TOGGLE_BULK_UPDATE:
      return {
        ...state,
        config: { ...state.config, displayCheckbox: action.isBulkUpdateActive },
        pathSelections: action.isBulkUpdateActive ? state.pathSelections : [],
        pathSelectionsMap: action.isBulkUpdateActive ? state.pathSelectionsMap : {},
      };
    case actionTypes.REMOVE_IDS_FROM_SELECTIONS:
      return ((state) => {
        const newPathSelectionsMap = { ...state.pathSelectionsMap };
        const pathSelections = state.pathSelections.filter((pathId) => !action.ids.includes(pathId));
        for (const id of action.ids) {
          delete newPathSelectionsMap[id];
        }

        return { ...state, pathSelections, pathSelectionsMap: newPathSelectionsMap };
      })(state);
    case actionTypes.RESET:
      return { ...initialState };

    case actionTypes.SET_JACKPOT_BET_TYPE_FILTER:
      return {
        ...state,
        jackpotBetTypeFilter: action.jackpotBetTypeFilter,
      };

    case actionTypes.SET_JACKPOT_BET_STATUS_FILTER:
      return {
        ...state,
        jackpotBetStatusFilter: action.jackpotBetStatusFilter,
      };

    default:
      return { ...state };
  }
};

export default sportsTree;
