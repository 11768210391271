export const FETCH_OPERATOR_GROUPS = "OL::FETCH_OPERATOR_GROUPS";
export const FETCH_OPERATOR_GROUPS_BY_STATUS = "OL::FETCH_OPERATOR_GROUPS_BY_STATUS";
export const FETCH_OPERATOR_GROUPS_SUCCEEDED = "OL::FETCH_OPERATOR_GROUPS_SUCCEEDED";
export const FETCH_OPERATOR_GROUPS_FAILED = "OL::FETCH_OPERATOR_GROUPS_FAILED";

export const SELECT_OPERATOR = "OL::SELECT_OPERATOR";
export const SEARCH_OPERATOR = "OL::SEARCH_OPERATOR";
export const SELECT_GROUP = "OL::SELECT_GROUP";
export const FILTER_GROUP = "OL::FILTER_GROUP";

export const GROUP_ERROR = "OL::GROUP_ERROR";
export const ADD_GROUP = "OL::ADD_GROUP";
export const UPDATE_GROUP = "OL::UPDATE_GROUP";
export const DUPLICATE_GROUP = "OL::DUPLICATE_GROUP";
export const DUPLICATE_GROUP_SUCCEEDED = "OL::DUPLICATE_GROUP_SUCCEEDED";
export const ADD_UPDATE_GROUP_SUCCEEDED = "OL::ADD_UPDATE_GROUP_SUCCEEDED";
export const DELETE_GROUP = "OL::DELETE_GROUP";
export const DELETE_GROUP_SUCCEEDED = "OL::DELETE_GROUP_SUCCEEDED";
export const EDIT_GROUP = "OL::EDIT_GROUP";
export const EDIT_GROUP_SUCCEEDED = "OL::EDIT_GROUP_SUCCEEDED";

export const OPERATOR_ERROR = "OL::OPERATOR_ERROR";
export const ADD_OPERATOR = "OL::ADD_OPERATOR";
export const ADD_OPERATOR_SUCCEEDED = "OL::ADD_OPERATOR_SUCCEEDED";
export const ADD_OPERATOR_FAILED = "OL::ADD_OPERATOR_FAILED";
export const DELETE_OPERATOR = "OL::DELETE_OPERATOR";
export const DELETE_OPERATOR_SUCCEEDED = "OL::DELETE_OPERATOR_SUCCEEDED";
export const UPDATE_OPERATOR = "OL::UPDATE_OPERATOR";
export const UPDATE_OPERATOR_SUCCEEDED = "OL::UPDATE_OPERATOR_SUCCEEDED";
export const UPDATE_OPERATOR_FAILED = "OL::UPDATE_OPERATOR_FAILED";
export const DUPLICATE_OPERATOR = "OL::DUPLICATE_OPERATOR";
export const DUPLICATE_OPERATOR_SUCCEEDED = "OL::DUPLICATE_OPERATOR_SUCCEEDED";

export const TOGGLE_WARNING_MODAL = "OL::TOGGLE_WARNING_MODAL";

export const removedStatusFilter = [5, 6]; // filter status which are not being used or needed
