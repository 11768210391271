const constants = {
  ADD_NEW_ORIGIN_VALIDATION: "OVM::ADD_NEW_ORIGIN_VALIDATION",
  ADD_NEW_ORIGIN_VALIDATION_FAILED: "OVM::ADD_NEW_ORIGIN_VALIDATION_FAILED",
  ADD_NEW_ORIGIN_VALIDATION_SUCCEEDED: "OVM::ADD_NEW_ORIGIN_VALIDATION_SUCCEEDED",

  CLOSE_ADD_NEW: "OVM::CLOSE_ADD_NEW",
  DELETE_ORIGIN_VALIDATION_MAPPINGS: "OVM::DELETE_ORIGIN_VALIDATION_MAPPINGS",

  DELETE_ORIGIN_VALIDATION_MAPPINGS_FAILED: "OVM::DELETE_ORIGIN_VALIDATION_MAPPINGS_FAILED",
  DELETE_ORIGIN_VALIDATION_MAPPINGS_SUCCEEDED: "OVM::DELETE_ORIGIN_VALIDATION_MAPPINGS_SUCCEEDED",
  FETCH_ORIGIN_VALIDATION_MAPPING: "OVM::FETCH_ORIGIN_VALIDATION_MAPPING",

  FETCH_ORIGIN_VALIDATION_MAPPING_FAILED: "OVM::FETCH_ORIGIN_VALIDATION_MAPPING_FAILED",

  FETCH_ORIGIN_VALIDATION_MAPPING_SUCCEEDED: "OVM::FETCH_ORIGIN_VALIDATION_MAPPING_SUCCEEDED",
  OPEN_ADD_NEW: "OVM::OPEN_ADD_NEW",

  SELECT_ROW: "OVM::SELECT_ROW",
  UNSELECT_ROW: "UTL::UNSELECT_ROW",
  UPDATE_ADD_FORM: "OVM::UPDATE_ADD_FORM",
};

export default constants;
