import mainConst from "../../App/constants";

import constants from "./constants";

const initialState = {
  attachedCampaign: [],
  isCanceling: false,
  isCreatingMessagePlans: false,
  isCreatingMessagePlansFailed: false,
  isDeletingMessagePlan: false,
  isDeletingMessagePlanFailed: false,
  isFetchingMessagePlans: false,
  isFetchingMessagePlansFailed: false,
  isOpponentLoading: false,
  messageModifiy: null,
  messagePlan: {},
  messagePlanBackUp: {},
  messagePlans: [],
  modified: false,
  opponentsAvailable: [],
  retired: false,
};

const promotions = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_MESSAGE_PLANS:
      return {
        ...state,
        isFetchingMessagePlans: true,
        isFetchingMessagePlansFailed: false,
      };
    case constants.FETCH_MESSAGE_PLANS_SUCCEEDED:
      return {
        ...state,
        isFetchingMessagePlans: false,
        isFetchingMessagePlansFailed: false,
        messagePlans: action.messagePlans,
      };
    case constants.FETCH_MESSAGE_PLANS_FAILED:
      return {
        ...state,
        isFetchingMessagePlans: false,
        isFetchingMessagePlansFailed: true,
      };
    case constants.FETCH_MESSAGE_PLAN_DETAILS:
      return {
        ...state,
        isFetchingMessagePlans: true,
        isFetchingMessagePlansFailed: false,
      };
    case constants.FETCH_MESSAGE_PLAN_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchingMessagePlans: false,
        isFetchingMessagePlansFailed: false,
        messageModifiy: null,
        messagePlan: action.messagePlan,
        messagePlanBackUp: action.messagePlan,
        retired: action.messagePlan.retired,
      };
    case constants.FETCH_MESSAGE_PLAN_DETAILS_FAILED:
      return {
        ...state,
        isFetchingMessagePlans: false,
        isFetchingMessagePlansFailed: true,
      };
    case constants.RESET_MESSAGE_PLANS:
      return {
        ...state,
        messageModifiy: { brandId: 1, title: "New Message Plan" },
        messagePlan: {},
        messagePlanBackUp: {},
        messagePlans: [],
      };
    case constants.CREATE_MESSAGE_PLAN:
      return {
        ...state,
        //   isCreatingMessagePlans: true,
        //   isCreatingMessagePlansFailed: false,
      };
    case constants.CREATE_MESSAGE_PLAN_SUCCEEDED:
      return {
        ...state,
        isCreatingMessagePlans: false,
        isCreatingMessagePlansFailed: false,
        messageModifiy: null,
        messagePlan: action.messagePlan,
        messagePlanBackUp: action.messagePlan,
        modified: false,
      };
    case constants.CREATE_MESSAGE_PLAN_FAILED:
      return {
        ...state,
        isCreatingMessagePlans: false,
        isCreatingMessagePlansFailed: true,
      };
    case constants.NEW_PROMOTIONAL_GROUP_FAILED:
      return {
        ...state,
        isCreatingMessagePlans: false,
        isCreatingMessagePlansFailed: true,
      };
    case constants.SET_CAMPAIGN_MODIFIED:
      return {
        ...state,
        modified: action.value,
      };
    case constants.UPDATE_EDITED_MESSAGE_IN_REDUX:
      return {
        ...state,
        messageModifiy: JSON.parse(action.value),
        messagePlan: JSON.parse(action.value),
      };
    case constants.UPDATE_MESSAGE_PLAN_FIELD:
      if (true) {
        const { field, value } = action;
        const isModified = !_.isEqual(state.messagePlan[field], value);
        const changeMessagePlan = state.messageModifiy;
        changeMessagePlan[field] = value;

        return {
          ...state,
          messageModifiy: changeMessagePlan,
          modified: isModified,
        };
      }
    case constants.FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN_SUCCEEDED:
      return {
        ...state,
        attachedCampaign: action.response,
      };
    case constants.EMPTY_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN:
      return {
        ...state,
        attachedCampaign: [],
        modified: false,
        retired: state.messagePlan.retired,
      };
    case constants.DELETE_MESSAGE_PLAN:
      return {
        ...state,
        isDeletingMessagePlan: true,
        isDeletingMessagePlanFailed: false,
      };
    case constants.DELETE_MESSAGE_PLAN_SUCCEEDED:
      return {
        ...state,
        isDeletingMessagePlan: false,
        isDeletingMessagePlanFailed: false,
      };
    case constants.DELETE_MESSAGE_PLAN_FAILED:
      return {
        ...state,
        isDeletingMessagePlan: false,
        isDeletingMessagePlanFailed: true,
      };
    case constants.EDIT_MESSAGE_PLAN_ITEM_SUCCEEDED:
      return {
        ...state,
        messageModifiy: null,
        messagePlan: action.response,
        messagePlanBackUp: action.response,
      };
    case constants.DELETE_MESSAGE_PLAN_FAILED:
      return {
        ...state,
      };
    case constants.COPY_MESSAGE_PLAN_SUCCEEDED:
      return {
        ...state,
        messagePlan: action.response,
        messagePlanBackUp: action.response,
        retired: action.response.retired,
      };
    case constants.COPY_MESSAGE_PLAN_FAILED:
      return {
        ...state,
      };
    case constants.CANCEL_EDIT:
      return {
        ...state,
        isCanceling: action.isCanceling,
        messagePlan: state.messagePlanBackUp,
      };
    case constants.FETCH_OPPONENTS:
      return {
        ...state,
        isOpponentLoading: true,
      };
    case constants.FETCH_OPPONENTS_SUCCEEDED:
      return {
        ...state,
        isOpponentLoading: false,
        opponentsAvailable: action.opponents,
      };
    case constants.FETCH_OPPONENTS_FAILED:
      return {
        ...state,
        isOpponentLoading: false,
        opponentsAvailable: [],
      };
    case mainConst.SET_BM_MODULE_EDIT_STATE:
      return {
        ...state,
        modified:
          typeof action.state === "object" && !action.state.state && action.state.form == "message-plans"
            ? action.state.state
            : typeof action.state !== "object" && !action.state
            ? action.state
            : state.modified,
      };
    default:
      return { ...state };
  }
};

export default promotions;
