import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchUtilitiesWagerLimits(action) {
  const { data } = action;
  const { response, xhr } = yield call(API.fetchUtilitiesWagerLimitGroups, data);
  if (response) {
    yield put({ response, type: constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS_FAILED });
    // toastr.add({ message: `Error. ${msg}`, type: 'ERROR' })
  }
}

function* createUtilitiesWagerLimits(action) {
  const { data, err } = action.payload;

  if (err) {
    toastr.add({ message: i18n.t(`Wager.Error: Please Create Only one Row`), type: "ERROR" });
  } else {
    const { response, xhr } = yield call(API.createUtilitiesWagerLimitGroups, data);
    if (response) {
      yield put({ response, type: constants.ADD_NEW_WAGER_LIMIT_GROUP_SUCCEED });
      toastr.add({ message: i18n.t(`Wager.Successfully saved Wager Limit Group`), type: "SUCCESS" });
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
      yield put({ msg, type: constants.ADD_NEW_WAGER_LIMIT_GROUP_FAILED });
      toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
    }
  }
}

function* updateUtilitiesWagerLimits(action) {
  const { data, err } = action.payload;
  if (err) {
    toastr.add({ message: i18n.t(`Wager.Error: Please Update Only one Row`), type: "ERROR" });
  } else {
    const { response, xhr } = yield call(API.updateUtilitiesWagerLimitGroups, data);
    if (response) {
      yield put({ response, type: constants.UPDATE_WAGER_LIMIT_GROUPS_SUCCEEDED });
      toastr.add({ message: i18n.t(`Wager.Successfully saved Wager Limit Group`), type: "SUCCESS" });
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
      yield put({ msg, type: constants.UPDATE_WAGER_LIMIT_GROUPS_FAILED });
      toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
    }
  }
}

function* deleteUtilitiesWagerLimits(action) {
  const { response, xhr } = yield call(API.deleteUtilitiesWagerLimitGroups, action.payload);
  if (response) {
    yield put({ type: constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS });
    toastr.add({ message: i18n.t(`Wager.Successfully deleted Wager Limit Group`), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ msg, type: constants.DELETE_WAGER_LIMIT_GROUP_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* getWagerMarketDetails(action) {
  const { response, xhr } = yield call(API.getWagerMarketDetails, action.payload);
  if (response) {
    yield put({ payload: response, type: constants.GET_WAGER_MARKET_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.GET_WAGER_MARKET_DETAILS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "SUCCESS" });
  }
}

export default function* utilitiesWagerLimitSaga() {
  yield takeLatest(constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS, fetchUtilitiesWagerLimits),
    yield takeLatest(constants.ADD_NEW_WAGER_LIMIT_GROUP, createUtilitiesWagerLimits),
    yield takeLatest(constants.UPDATE_WAGER_LIMIT_GROUPS, updateUtilitiesWagerLimits),
    yield takeLatest(constants.DELETE_WAGER_LIMIT_GROUP, deleteUtilitiesWagerLimits),
    yield takeLatest(constants.GET_WAGER_MARKET_DETAILS, getWagerMarketDetails);
}
