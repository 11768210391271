import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchSinglePlayerOfTeamDetailsXhr = null;
const fetchMultiplePlayersOfTeamDetailsXhr = null;
const deletePlayerOfTeamXhr = null;
const deleteAllPlayerOfTeamXhr = null;
const deleteAllPlayerOfEventPathXhr = null;

export function fetchSinglePlayerOfTeamDetails(playerId) {
  const url = `/bo/opponents/${playerId}`;

  return performHttpCall(fetchSinglePlayerOfTeamDetailsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMultiplePlayersOfTeamDetails(teamId) {
  const url = `/bo/opponents/${teamId}/players`;

  return performHttpCall(fetchMultiplePlayersOfTeamDetailsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addPlayerToTeam(teamId, playerId) {
  const url = `/bo/opponents/${teamId}/${playerId}`;

  return performHttpCall(fetchMultiplePlayersOfTeamDetailsXhr, httpMethods.HTTP_PUT, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deletePlayerOfTeam(teamId, playerId) {
  const url = `/bo/opponents/${teamId}/${playerId}`;
  const headers = {
    "X-P8-Datasource": "database",
  };

  return performHttpCall(deletePlayerOfTeamXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteAllPlayerOfTeam(teamId) {
  const url = `/bo/opponents/${teamId}/players`;
  const headers = {
    "X-P8-Datasource": "database",
  };

  return performHttpCall(deleteAllPlayerOfTeamXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteAllPlayerOfEventPath(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/players`;
  const headers = {
    "X-P8-Datasource": "database",
  };

  return performHttpCall(deleteAllPlayerOfEventPathXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
