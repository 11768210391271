import constants from "./constants";

export function fetchGameResultsPeriodPoints(eventId) {
  return {
    eventId,
    type: constants.FETCH_GAME_RESULTS_PERIOD_POINTS,
  };
}

export function updateGameResultsPeriodPoints(formData) {
  return {
    formData,
    type: constants.UPDATE_GAME_RESULTS_PERIOD_POINTS,
  };
}

export function updateGameResultsVoidPeriod(formData) {
  return {
    formData,
    type: constants.UPDATE_GAME_RESULTS_VOID_PERIOD,
  };
}

export function fetchEventTotals(eventId, params) {
  return {
    eventId,
    params,
    type: constants.FETCH_EVENT_TOTALS,
  };
}

export function updateEventTotalsState(eventTotal, newValue) {
  return {
    eventTotal,
    newValue,
    type: constants.UPDATE_EVENT_TOTALS_STATE,
  };
}

export function updateEventTotals(eventTotals) {
  return {
    eventTotals,
    type: constants.UPDATE_EVENT_TOTALS,
  };
}
