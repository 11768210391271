import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

const toggleOutcomeVisibilityXhr = {};
let outcomePriceChangeXhr = null;
let fetchOutcomeLinePriceXhr = null;

export function hideOutcome(outcomeId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/outcomes/${outcomeId}/hide`;
    const requestBody = {
      flags: [
        {
          type: "OPERATOR",
        },
      ],
    };

    if (toggleOutcomeVisibilityXhr[outcomeId]) {
      toggleOutcomeVisibilityXhr[outcomeId].cancel("cancelled due to concurrent request");
    }

    toggleOutcomeVisibilityXhr[outcomeId] = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, toggleOutcomeVisibilityXhr[outcomeId]);
      toggleOutcomeVisibilityXhr[outcomeId] = null;
      delete toggleOutcomeVisibilityXhr[outcomeId];
      resolve(response);
    } catch (err) {
      delete toggleOutcomeVisibilityXhr[outcomeId];
      toggleOutcomeVisibilityXhr[outcomeId] = null;
      reject(err);
    }
    toggleOutcomeVisibilityXhr[outcomeId] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function unhideOutcome(outcomeId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/outcomes/${outcomeId}/hide`;
    const requestBody = {
      flags: [],
    };

    if (toggleOutcomeVisibilityXhr[outcomeId]) {
      toggleOutcomeVisibilityXhr[outcomeId].cancel("cancelled due to concurrent request");
    }

    toggleOutcomeVisibilityXhr[outcomeId] = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, toggleOutcomeVisibilityXhr[outcomeId]);
      toggleOutcomeVisibilityXhr[outcomeId] = null;
      resolve(response);
    } catch (err) {
      toggleOutcomeVisibilityXhr[outcomeId] = null;
      reject(err);
    }
    toggleOutcomeVisibilityXhr[outcomeId] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function changeOutcomePrices(outcomes) {
  return new Promise((resolve, reject) => {
    const url = `/bo/prices/batch`;

    if (outcomePriceChangeXhr) {
      outcomePriceChangeXhr.cancel("cancelled due to concurrent request");
    }

    outcomePriceChangeXhr = originalAxios.CancelToken.source();

    try {
      const response = api.post(url, outcomes, outcomePriceChangeXhr);
      outcomePriceChangeXhr = null;
      resolve(response);
    } catch (err) {
      outcomePriceChangeXhr = null;
      reject(err);
    }
    outcomePriceChangeXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchOutcomeLinePrice(outcomeId, lineId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/prices?outcomeId=${outcomeId}&lineId=${lineId}`;

    if (fetchOutcomeLinePriceXhr) {
      fetchOutcomeLinePriceXhr.cancel("cancelled due to concurrent request");
    }

    fetchOutcomeLinePriceXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchOutcomeLinePriceXhr);
      fetchOutcomeLinePriceXhr = null;
      resolve(response);
    } catch (err) {
      fetchOutcomeLinePriceXhr = null;
      reject(err);
    }
    fetchOutcomeLinePriceXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function cancelFetchOutcomeLinePrice() {
  if (fetchOutcomeLinePriceXhr) {
    fetchOutcomeLinePriceXhr.abort();
  }
}
