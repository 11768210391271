const customerManagerConfig = {
  statusList: [
    { desc: "Open", id: 1 },
    { desc: "Suspended", id: 2 },
    { desc: "Login_Suspended", id: 3 },
    { desc: "Closed", id: 4 },
    { desc: "Open Not Pool", id: 5 },
    { desc: "Suspended Except Login", id: 6 },
  ],
};
export default customerManagerConfig;
