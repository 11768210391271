import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchUtilitiesWagerLimitGroups() {
  const url = `/bo/wagerlimitgroups?activeOnly=false`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createUtilitiesWagerLimitGroups(wager) {
  const url = `/bo/wagerlimitgroups`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, wager, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateUtilitiesWagerLimitGroups(wager) {
  const url = `/bo/wagerlimitgroups`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, wager, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteUtilitiesWagerLimitGroups(id) {
  const url = `/bo/wagerlimitgroups/${id}`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function getWagerMarketDetails(market) {
  const url = `/bo/wagerlimitgroups/${market}/marketTypes`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
