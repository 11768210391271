const constants = {
  ADD_NEW_WAGER_LIMIT_GROUP: "UTL::ADD_NEW_WAGER_LIMIT",
  ADD_NEW_WAGER_LIMIT_GROUP_FAILED: "UTL::ADD_NEW_WAGER_LIMIT_FAILED",
  ADD_NEW_WAGER_LIMIT_GROUP_SUCCEED: "UTL::ADD_NEW_WAGER_LIMIT_SUCCEED",

  CLOSE_WAGER_MARKET_TYPE_MODAL: "UTL::CLOSE_WAGER_MARKET_TYPE_MODAL",
  DELETE_WAGER_LIMIT_GROUP: "UTL::DELETE_WAGER_LIMIT",
  DELETE_WAGER_LIMIT_GROUP_FAILED: "UTL::DELETE_WAGER_LIMIT_FAILED",
  DELETE_WAGER_LIMIT_GROUP_SUCCEEDED: "UTL::DELETE_WAGER_LIMIT_SUCCEEDED",

  EDIT_WAGER_LIMIT_GROUPS: "UTL::EDIT_WAGER_LIMITS",
  EDIT_WAGER_LIMIT_GROUPS_SUCCEEDED: "UTL::EDIT_WAGER_LIMITS_SUCCEEDED",

  FETCH_UTILITIES_WAGER_LIMIT_GROUPS: "UTL::FETCH_UTILITIES_WAGER_LIMITS",
  FETCH_UTILITIES_WAGER_LIMIT_GROUPS_FAILED: "UTL::FETCH_UTILITIES_WAGER_LIMITS_FAILED",
  FETCH_UTILITIES_WAGER_LIMIT_GROUPS_SUCCEEDED: "UTL::FETCH_UTILITIES_WAGER_LIMITS_SUCCEEDED",

  GET_WAGER_MARKET_DETAILS: "UTL::GET_WAGER_MARKET_DETAILS",
  GET_WAGER_MARKET_DETAILS_FAILED: "UTL::GET_WAGER_MARKET_DETAILS_FAILED",
  GET_WAGER_MARKET_DETAILS_SUCCEEDED: "UTL::GET_WAGER_MARKET_DETAILS_SUCCEEDED",

  SELECT_ROW: "UTL::SELECT_ROW",

  UNDO_UPDATED_WAGER_LIMITS_FIELDS: "UTL::UNDO_UPDATED_WAGER_LIMITS_FIELDS",

  UNSELECT_ROW: "UTL::UNSELECT_ROW",

  UPDATE_WAGER_LIMIT_GROUPS: "UTL::UPDATE_WAGER_LIMITS",
  UPDATE_WAGER_LIMIT_GROUPS_FAILED: "UTL::UPDATE_WAGER_LIMITS_FAILED",
  UPDATE_WAGER_LIMIT_GROUPS_FIELDS: "UTL::UPDATE_WAGER_LIMITS_FIELDS",
  UPDATE_WAGER_LIMIT_GROUPS_SUCCEEDED: "UTL::UPDATE_WAGER_LIMITS_SUCCEEDED",
};

export default constants;
