import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import * as API from "../../services/riskManager/lookupPublishModes";
import { objectToArray, formatFilterDates } from "../../utils";

import { toastr } from "components/toastr/index";

function* fetchLookupPublishModes(action) {
  const { response, xhr } = yield call(API.fetchLookupPublishModes, action);
  if (response) {
    yield put({ data: response, type: actionTypes.FETCH_LOOKUP_PUBLISH_MODES_SUCCESS });
  } else {
    yield put({ type: actionTypes.FETCH_LOOKUP_PUBLISH_MODES_FAILED });
  }
}

function* updateLookupPublishModes(action) {
  const { response, xhr } = yield call(API.updateLookupPublishModes, action);
  if (response) {
    yield put({ data: response, type: actionTypes.UPDATE_LOOKUP_PUBLISH_MODES_SUCCESS });
    toastr.add({ message: i18n.t("Risk.Successfully updated publishing mode!"), type: "SUCCESS" });
  } else {
    yield put({ type: actionTypes.UPDATE_LOOKUP_PUBLISH_MODES_FAILED });
  }
}

export default function* lookupPublishModesSaga() {
  yield takeLatest(actionTypes.FETCH_LOOKUP_PUBLISH_MODES, fetchLookupPublishModes);
  yield takeLatest(actionTypes.UPDATE_LOOKUP_PUBLISH_MODES, updateLookupPublishModes);
}
