import isEqual from "lodash.isequal";


import actionTypes from "./constants";
import { processMatrix, getMultipleFlag, getSingleFlag, getBetTypeKey, generateCriteriaKey } from "./helpers";

import { makeIterable } from "utils";

const steps = ["MATRIX", "NEW_MATRIX", "EVENT_PATH", "EVENT_TYPE", "PERIOD", "MARKET_TYPE"];
const stepsMap = {
  [steps[0]]: { enabled: true, isFirstStep: true, key: steps[0] },
  [steps[1]]: { enabled: false, key: steps[1] },
  [steps[2]]: { enabled: false, key: steps[2] },
  [steps[3]]: { enabled: true, key: steps[3] },
  [steps[4]]: { enabled: false, key: steps[4] },
  [steps[5]]: { enabled: true, isLastStep: true, key: steps[5] },
};
const initialStepIndex = 0;
const defaultSportCode = "BASK"; // default sport in sports list dropdown

const defaultNewBetRestrictionData = {
  bookTypeId: 0,

  eventPath: null,

  eventPathDescription: null,
  // required dynamic fields
  eventPathId: 0,
  eventTypeId: 0,
  marketTypeGroupDesc: null,
  marketTypeGroupId: null,
  marketTypeId: 0,

  periodId: null,

  phraseId: 0,
  // require fields but fixed
  tagId: 0,
};

const initialState = {
  // use to store matrix data for each bet restriction type
  activeBetType: {},

  activeBetTypeKey: "",

  activeCell: {},
  activeHistory: {},
  activeSportCode: defaultSportCode,
  betTypes: [],
  history: [],
  isAddingNewBetRestriction: false,
  isAddingNewBetRestrictionFailed: false,

  isDeletingBetRestrictions: false,

  isDeletingBetRestrictionsFailed: false,
  isDeletingBetRestrictionsHistory: false,
  isDeletingBetRestrictionsHistoryFailed: false,
  isEvaluationOrderVisible: false,
  isFetchingBetRestrictions: false,
  isFetchingBetRestrictionsFailed: false,
  isFetchingBetRestrictionsHistory: false,
  isFetchingBetRestrictionsHistoryFailed: false,
  isFetchingMarketTypeGroups: false,

  isFetchingMarketTypeGroupsFailed: false,

  isFetchingMatrixData: false,
  isFetchingMatrixDataFailed: false,

  isFetchingSportPeriods: false,
  isFetchingSportPeriodsFailed: false,

  isFetchingUnusedBetRestrictions: false,
  lastSelectionValues: {
    max: 40,
    min: 1,
  },

  isFetchingUnusedBetRestrictionsFailed: false,
  marketTypeGroups: [],

  isRestoringBetRestrictionsHistory: false,
  matrixDataCache: {},

  isRestoringBetRestrictionsHistoryFailed: false,
  unusedBetTypes: [],

  isUpdatingBetRestrictions: false,
  pathIdForEvent: null,

  isUpdatingBetRestrictionsFailed: false,
  sportPeriods: [],

  isUpdatingBetRestrictionsHistory: false,
  sportPeriodsMap: {},

  isUpdatingBetRestrictionsHistoryFailed: false,
  step: stepsMap[steps[initialStepIndex]],

  newBetRestrictionData: {
    isNewMatrix: false,
    sportId: null,
    newMatrixBetType: {},
    selectedType: {},

    ...defaultNewBetRestrictionData,
  },
  steps: [...steps],

  stepIndex: initialStepIndex,
  stepsMap: { ...stepsMap },
};

const betRestrictions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BET_RESTRICTION_KEYS:
      return {
        ...state,
        isFetchingBetRestrictions: true,
        isFetchingBetRestrictionsFailed: false,
      };
    case actionTypes.FETCH_BET_RESTRICTION_KEYS_SUCCEEDED:
      return {
        ...state,
        betTypes: action.response,
        isFetchingBetRestrictions: false,
      };
    case actionTypes.FETCH_BET_RESTRICTION_KEYS_FAILED:
      return {
        ...state,
        isFetchingBetRestrictions: false,
        isFetchingBetRestrictionsFailed: true,
      };

    case actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS:
      return {
        ...state,
        isFetchingUnusedBetRestrictions: true,
        isFetchingUnusedBetRestrictionsFailed: false,
      };
    case actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS_SUCCEEDED:
      return {
        ...state,
        isFetchingUnusedBetRestrictions: false,
        unusedBetTypes: action.response,
      };
    case actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS_FAILED:
      return {
        ...state,
        isFetchingUnusedBetRestrictions: false,
        isFetchingUnusedBetRestrictionsFailed: true,
      };

    case actionTypes.FETCH_BET_RESTRICTIONS_HISTORY:
      return {
        ...state,
        isFetchingBetRestrictionsHistory: true,
        isFetchingBetRestrictionsHistoryFailed: false,
      };
    case actionTypes.FETCH_BET_RESTRICTIONS_HISTORY_SUCCEEDED:
      return {
        ...state,
        history: action.response,
        isFetchingBetRestrictionsHistory: false,
      };
    case actionTypes.FETCH_BET_RESTRICTIONS_HISTORY_FAILED:
      return {
        ...state,
        isFetchingBetRestrictionsHistory: false,
        isFetchingBetRestrictionsHistoryFailed: true,
      };

    case actionTypes.DELETE_BET_RESTRICTIONS_HISTORY:
      return {
        ...state,
        isDeletingBetRestrictionsHistory: true,
        isDeletingBetRestrictionsHistoryFailed: false,
      };
    case actionTypes.DELETE_BET_RESTRICTIONS_HISTORY_SUCCEEDED:
      return ((state) => {
        let history = state.history;
        let activeHistory = state.activeHistory;
        const index = state.history.findIndex((history) => history.id === action.id);
        if (index >= 0) {
          history = [...history.slice(0, index), ...history.slice(index + 1)];
        }
        if (activeHistory.id === action.id) {
          activeHistory = {};
        }

        return {
          ...state,
          activeHistory,
          history,
          isDeletingBetRestrictionsHistory: false,
        };
      })(state);

    case actionTypes.DELETE_BET_RESTRICTIONS_HISTORY_FAILED:
      return {
        ...state,
        isDeletingBetRestrictionsHistory: false,
        isDeletingBetRestrictionsHistoryFailed: true,
      };

    case actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY:
      return {
        ...state,
        isUpdatingBetRestrictionsHistory: true,
        isUpdatingBetRestrictionsHistoryFailed: false,
      };
    case actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY_SUCCEEDED:
      return ((state) => {
        let history = state.history;
        let activeHistory = state.activeHistory;
        const index = state.history.findIndex((history) => history.id === action.data.id);
        if (index >= 0) {
          const target = { ...state.history[index], description: action.data.description };
          activeHistory = { ...target };
          history = [...history.slice(0, index), target, ...history.slice(index + 1)];
        }

        return {
          ...state,
          activeHistory,
          history,
          isUpdatingBetRestrictionsHistory: false,
        };
      })(state);

    case actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY_FAILED:
      return {
        ...state,
        isUpdatingBetRestrictionsHistory: false,
        isUpdatingBetRestrictionsHistoryFailed: true,
      };

    case actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY:
      return {
        ...state,
        isRestoringBetRestrictionsHistory: true,
        isRestoringBetRestrictionsHistoryFailed: false,
      };
    case actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY_SUCCEEDED:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const matrixDataCache = { ...state.matrixDataCache };
        matrixDataCache[activeBetTypeKey] = undefined;

        return {
          ...state,
          isRestoringBetRestrictionsHistory: false,
          matrixDataCache,
        };
      })(state);

    case actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY_FAILED:
      return {
        ...state,
        isRestoringBetRestrictionsHistory: false,
        isRestoringBetRestrictionsHistoryFailed: true,
      };

    case actionTypes.UPDATE_BET_RESTRICTIONS:
      return {
        ...state,
        isUpdatingBetRestrictions: true,
        isUpdatingBetRestrictionsFailed: false,
      };
    case actionTypes.UPDATE_BET_RESTRICTIONS_SUCCEEDED:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const cache = state.matrixDataCache[activeBetTypeKey];

        return {
          ...state,
          isUpdatingBetRestrictions: false,
          matrixDataCache: {
            ...state.matrixDataCache,
            [activeBetTypeKey]: {
              ...state.matrixDataCache[activeBetTypeKey],
              newRestrictionsMap: {},
              originalMatrix: {
                matrixArray: [...cache.matrixArray],
                matrixData: [...cache.matrixData],
                matrixHeaders: [...cache.matrixHeaders],
                matrixMap: { ...cache.matrixMap },
              },
              orignalMatrixMap: { ...cache.matrixMap },
              updatedCellsArray: [],
            },
          },
        };
      })(state);
    case actionTypes.UNDO_CHANGES:
      if (true) {
        const activeBetTypeKey = action.activeBetTypeKey;
        const cache = state.matrixDataCache[activeBetTypeKey];
        const originalMatrix = cache.originalMatrix;
        if (originalMatrix) {
          return {
            ...state,
            activeCell: {},
            isUpdatingBetRestrictions: false,
            lastSelectionValues: {
              max: 40,
              min: 1,
            },
            matrixDataCache: {
              ...state.matrixDataCache,
              [activeBetTypeKey]: {
                ...cache,
                cellData: {},
                deletedRestrictionsMap: {},
                matrixArray: [...originalMatrix.matrixArray],
                matrixData: [...originalMatrix.matrixData],
                matrixHeaders: [...originalMatrix.matrixHeaders],
                matrixMap: { ...originalMatrix.matrixMap },
                multipleRulesMap: {},
                newRestrictionsMap: {},
                newlyAddedRestrictionKey: "",
                updatedCellsArray: [],
              },
            },
          };
        }
        // it means it's a newly created matrix
        const matrixDataCache = { ...state.matrixDataCache };
        matrixDataCache[activeBetTypeKey] = {
          ...cache,
          cellData: {},
          deletedRestrictionsMap: {},
          matrixArray: [],
          matrixData: [],
          matrixHeaders: [],
          matrixMap: {},
          multipleRulesMap: {},
          newRestrictionsMap: {},
          newlyAddedRestrictionKey: "",
          updatedCellsArray: [],
        };

        return {
          ...state,
          activeCell: {},
          isUpdatingBetRestrictions: false,
          lastSelectionValues: {
            max: 40,
            min: 1,
          },
          matrixDataCache,
        };
      }
      break;

    case actionTypes.UPDATE_BET_RESTRICTIONS_FAILED:
      return {
        ...state,
        isUpdatingBetRestrictions: false,
        isUpdatingBetRestrictionsFailed: true,
      };

    case actionTypes.UPDATE_BET_RESTRICTION_KEYS:
      return { ...state, betTypes: action.betTypes };

    case actionTypes.ADD_NEW_BET_RESTRICTION:
      return ((state) =>
        ((state) => {
          const activeBetTypeKey = getBetTypeKey(action.betType);
          const matrixDataCache = { ...state.matrixDataCache };
          matrixDataCache[activeBetTypeKey] = {
            ...matrixDataCache[activeBetTypeKey],
            newlyAddedRestrictionKey: "",
          };

          return {
            ...state,
            activeBetType: action.betType,
            activeBetTypeKey,
            isAddingNewBetRestriction: true,
            isAddingNewBetRestrictionFailed: false,
            matrixDataCache,
          };
        })(state))(state);

    case actionTypes.ADD_NEW_BET_RESTRICTION_FAILED:
      return {
        ...state,
        isAddingNewBetRestriction: false,
        isAddingNewBetRestrictionFailed: true,
      };

    case actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX:
      return {
        ...state,
        activeBetType: {
          ...state.activeBetType,
          ...action.betType,
        },
        isAddingNewBetRestriction: true,
        isAddingNewBetRestrictionFailed: false,
      };
    case actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX_FAILED:
      return {
        ...state,
        isAddingNewBetRestriction: false,
        isAddingNewBetRestrictionFailed: true,
      };

    case actionTypes.DELETE_BET_RESTRICTIONS:
      return {
        ...state,
        isDeletingBetRestrictions: true,
        isDeletingBetRestrictionsFailed: false,
      };

    case actionTypes.DELETE_BET_RESTRICTIONS_SUCCEEDED:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const cache = state.matrixDataCache[activeBetTypeKey];

        return {
          ...state,
          isDeletingBetRestrictions: false,
          isDeletingBetRestrictionsFailed: false,
          matrixDataCache: {
            ...state.matrixDataCache,
            [activeBetTypeKey]: {
              ...cache,
              deletedRestrictionsMap: {},
              originalMatrix: {
                matrixArray: [...cache.matrixArray],
                matrixData: [...cache.matrixData],
                matrixHeaders: [...cache.matrixHeaders],
                matrixMap: { ...cache.matrixMap },
              },
            },
          },
        };
      })(state);

    case actionTypes.DELETE_BET_RESTRICTIONS_FAILED:
      return {
        ...state,
        isDeletingBetRestrictions: false,
        isDeletingBetRestrictionsFailed: true,
      };

    case actionTypes.FETCH_SPORT_PERIODS:
      return {
        ...state,
        isFetchingSportPeriods: true,
        isFetchingSportPeriodsFailed: false,
        sportPeriods: [],
        sportPeriodsMap: {},
      };
    case actionTypes.FETCH_SPORT_PERIODS_SUCCEEDED:
      return {
        ...state,
        isFetchingSportPeriods: false,
        sportPeriods: action.response,
        sportPeriodsMap: action.response.reduce((accu, period) => {
          accu[period.id] = period;

          return accu;
        }, {}),
      };
    case actionTypes.FETCH_SPORT_PERIODS_FAILED:
      return {
        ...state,
        isFetchingSportPeriods: false,
        isFetchingSportPeriodsFailed: true,
      };

    case actionTypes.FETCH_MARKET_TYPE_GROUPS:
      return {
        ...state,
        isFetchingMarketTypeGroups: true,
        isFetchingMarketTypeGroupsFailed: false,
        marketTypeGroups: [],
      };
    case actionTypes.FETCH_MARKET_TYPE_GROUPS_SUCCEEDED:
      return {
        ...state,
        isFetchingMarketTypeGroups: false,
        marketTypeGroups: action.response,
      };
    case actionTypes.FETCH_MARKET_TYPE_GROUPS_FAILED:
      return {
        ...state,
        isFetchingMarketTypeGroups: false,
        isFetchingMarketTypeGroupsFailed: true,
      };

    case actionTypes.FETCH_MATRIX_DATA:
      let min;
      let max;
      if (action.activeBetType.betTypeGroupId === 1) {
        min = 2;
        max = 40;
      } else if (action.activeBetType.betTypeGroupId === 2) {
        min = 3;
        max = 40;
      } else {
        min = 1;
        max = 40;
      }

      return {
        ...state,
        activeBetType: action.activeBetType,
        activeBetTypeKey: getBetTypeKey(action.activeBetType),
        activeCell: {},
        isFetchingMatrixData: true,
        isFetchingMatrixDataFailed: false,
        lastSelectionValues: {
          max,
          min,
        },
        matrixData: null,
      };
    case actionTypes.FETCH_MATRIX_DATA_SUCCEEDED:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const matrixDataCache = { ...state.matrixDataCache };
        const { matrixArray, matrixData, matrixHeaders, matrixMap, multipleRulesMap } = processMatrix({
          response: action.response,
          state,
        });
        matrixDataCache[activeBetTypeKey] = {
          cellData: {},
          deletedRestrictionsMap: {},
          matrixArray,
          matrixData,
          matrixHeaders,
          matrixMap,
          multipleRulesMap,
          newRestrictionsMap: {},
          newlyAddedRestrictionKey: "",
          originalMatrix: {
            matrixArray: [...matrixArray],
            matrixData: [...matrixData],
            matrixHeaders: [...matrixHeaders],
            matrixMap: { ...matrixMap },
          },
          orignalMatrixMap: { ...matrixMap },
          updatedCellsArray: [],
        };

        return {
          ...state,
          isAddingNewBetRestriction: false,
          isFetchingMatrixData: false,
          matrixDataCache,
        };
      })(state);

    case actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX_SUCCEEDED:
      return ((state) => {
        const activeBetType = action.betType;
        const activeBetTypeKey = getBetTypeKey(activeBetType);
        const matrixDataCache = { ...state.matrixDataCache };

        const { matrixArray, matrixData, matrixHeaders, matrixMap, multipleRulesMap } = processMatrix({
          options: {
            isFromNewMatrix: true,
          },
          response: action.response,
          state,
        });
        const newRestrictionsMap = action.restrictions.reduce((accu, restriction) => {
          const restrictionKey = generateCriteriaKey(restriction);
          accu[restrictionKey] = { ...restriction };

          return accu;
        }, {});
        matrixDataCache[activeBetTypeKey] = {
          cellData: {},
          deletedRestrictionsMap: {},
          matrixArray,
          matrixData,
          matrixHeaders,
          matrixMap,
          multipleRulesMap,
          newRestrictionsMap,
          orignalMatrixMap: { ...matrixMap },
          updatedCellsArray: [],
        };

        return {
          ...state,
          activeBetType,
          activeBetTypeKey,
          activeCell: action.response[0],
          isAddingNewBetRestriction: false,
          isFetchingMatrixData: false,
          matrixDataCache,
        };
      })(state);

    case actionTypes.FETCH_MATRIX_DATA_FAILED:
      return {
        ...state,
        isFetchingMatrixData: false,
        isFetchingMatrixDataFailed: true,
      };

    case actionTypes.ADD_NEW_BET_RESTRICTION_SUCCEEDED:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const matrixDataCache = { ...state.matrixDataCache };
        const cache = matrixDataCache[activeBetTypeKey];
        const { matrixArray, matrixData, matrixHeaders, matrixMap, multipleRulesMap } = processMatrix({
          response: action.response,
          state,
        });

        const orignalMatrixMap = { ...matrixMap, ...cache.orignalMatrixMap };
        const newRestrictionsMap = { ...cache.newRestrictionsMap };
        const deletedRestrictionsMap = { ...cache.deletedRestrictionsMap };
        action.restrictions.forEach((restriction) => {
          const restrictionKey = generateCriteriaKey(restriction);
          if (!newRestrictionsMap[restrictionKey]) {
            newRestrictionsMap[restrictionKey] = { ...restriction };
          }
          if (deletedRestrictionsMap[restrictionKey]) {
            delete deletedRestrictionsMap[restrictionKey];
          }
        });

        matrixDataCache[activeBetTypeKey] = {
          ...matrixDataCache[activeBetTypeKey],
          deletedRestrictionsMap,
          matrixArray,
          matrixData,
          matrixHeaders,
          matrixMap,
          multipleRulesMap,
          newRestrictionsMap,
          newlyAddedRestrictionKey: action.newlyAddedRestrictionKey,
          orignalMatrixMap,
        };

        return {
          ...state,
          isAddingNewBetRestriction: false,
          isFetchingMatrixData: false,
          matrixDataCache,
        };
      })(state);

    case actionTypes.DELETE_BET_RESTRICTIONS_TEMPORARILY:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const matrixDataCache = { ...state.matrixDataCache };
        const cache = matrixDataCache[activeBetTypeKey];
        const newRestrictionsMap = { ...cache.newRestrictionsMap };
        let activeCell = state.activeCell;
        const { matrixArray, matrixData, matrixHeaders, matrixMap, multipleRulesMap } = processMatrix({
          response: action.response,
          state,
        });
        // let orignalMatrixMap = {...matrixMap};
        const deletedRestrictionsMap = { ...action.deletedRestrictionsMap };

        // convert updatedCellsArray to object first for easier mapping
        const updatedCellsMap = cache.updatedCellsArray.reduce((accu, key) => {
          accu[key] = { key, persist: true };

          return accu;
        }, {});
        const newlyAddedRestrictionsAndDeleted = [];

        // remove restriction reference from updatedCellsArray and newRestrictionsMap
        [...makeIterable(deletedRestrictionsMap, true)].map((restriction) => {
          cache.updatedCellsArray.forEach((key) => {
            if (key.indexOf(restriction.key) >= 0) {
              updatedCellsMap[key].persist = false;
            }
          });

          if (newRestrictionsMap[restriction.key]) {
            delete newRestrictionsMap[restriction.key];
            newlyAddedRestrictionsAndDeleted.push(restriction.key);
          }
          if (activeCell.key) {
            if (activeCell.key === restriction.key || activeCell.key.indexOf(restriction.key) >= 0) {
              activeCell = {};
            }
          }
        });
        // create new updatedCellsArray using updatedCellsMap
        const updatedCellsArray = [...makeIterable(updatedCellsMap)]
          .filter((cell) => cell.persist)
          .map((cell) => cell.key);

        // remove from deletedRestrictionsMap if the restrictions are temporarily added and then deleted
        newlyAddedRestrictionsAndDeleted.forEach((key) => {
          if (deletedRestrictionsMap[key]) {
            delete deletedRestrictionsMap[key];
          }
        });

        matrixDataCache[activeBetTypeKey] = {
          ...matrixDataCache[activeBetTypeKey],
          // orignalMatrixMap,
          deletedRestrictionsMap,

          matrixArray,

          matrixData,

          matrixHeaders,

          matrixMap,

          multipleRulesMap,
          newRestrictionsMap,
          updatedCellsArray,
        };

        return {
          ...state,
          activeCell,
          isAddingNewBetRestriction: false,
          isFetchingMatrixData: false,
          matrixDataCache,
        };
      })(state);

    case actionTypes.UPDATE_CELL_DATA:
      return ((state) => {
        const activeBetTypeKey = state.activeBetTypeKey;
        const cache = state.matrixDataCache[activeBetTypeKey];
        const cellDataFromCache = cache.cellData;
        const multipleRulesMapFromCache = cache.multipleRulesMap;
        const orignalMatrixMapFromCache = cache.orignalMatrixMap;
        const updatedCellsArrayFromCache = cache.updatedCellsArray;
        const matrixMapFromCache = cache.matrixMap;

        const cellData = { ...cellDataFromCache, ...action.data };
        const activeBetType = state.activeBetType;
        const activeCellKey = state.activeCell.key;
        const isSelectOutcome = activeBetType.betRestrictionTypeId === 1;
        let multipleRulesMap = { ...multipleRulesMapFromCache };
        const originalCellData = orignalMatrixMapFromCache[activeCellKey].cell;
        let updatedCellsArray = [...updatedCellsArrayFromCache];
        const flag = isSelectOutcome ? getSingleFlag(cellData.rules) : getMultipleFlag(cellData.rules);
        const hasRules = cellData.rules && cellData.rules.length;
        if (flag.key === "MULTIPLE") {
          multipleRulesMap = {
            ...multipleRulesMap,
            [activeCellKey]: [...cellData.rules],
          };
        }
        if (isEqual(cellData, originalCellData)) {
          const index = updatedCellsArray.findIndex((key) => key === activeCellKey);
          if (index >= 0) {
            updatedCellsArray = [...updatedCellsArray.slice(0, index), ...updatedCellsArray.slice(index + 1)];
          }
        } else if (!updatedCellsArray.includes(activeCellKey)) {
          updatedCellsArray = [...updatedCellsArray, activeCellKey];
        }

        return {
          ...state,
          lastSelectionValues: {
            max: hasRules ? cellData.rules[0].maxSelectionCount : state.lastSelectionValues.max,
            min: hasRules ? cellData.rules[0].minSelectionCount : state.lastSelectionValues.min,
          },
          matrixDataCache: {
            ...state.matrixDataCache,
            [activeBetTypeKey]: {
              ...state.matrixDataCache[activeBetTypeKey],
              cellData,
              matrixMap: {
                ...matrixMapFromCache,
                [activeCellKey]: {
                  ...matrixMapFromCache[activeCellKey],
                  cell: cellData,
                  className: flag.className,
                  desc: flag.desc,
                  descExpanded: `${flag.desc} ${cellData.rules.length ? `(${cellData.level})` : ""}`,
                },
              },
              multipleRulesMap,
              updatedCellsArray,
            },
          },
        };
      })(state);

    case actionTypes.SET_ACTIVE_CELL:
      if (true) {
        const cellData = action.activeCell.cell;
        const hasRules = cellData.rules && cellData.rules.length > 1;
        let min;
        let max;
        if (cellData.betTypeGroup === 1) {
          min = 2;
          max = 40;
          if (cellData.rules && cellData.rules[0] && cellData.rules[0].minSelectionCount < 2) {
            cellData.rules[0].minSelectionCount = 2;
          }
        } else if (cellData.betTypeGroup === 2) {
          min = 3;
          max = 40;
          if (cellData.rules && cellData.rules[0] && cellData.rules[0].minSelectionCount < 3) {
            cellData.rules[0].minSelectionCount = 3;
          }
        } else {
          min = 1;
          max = 40;
        }

        return {
          ...state,
          activeCell: action.activeCell,
          lastSelectionValues: {
            max: hasRules ? cellData.rules[0].maxSelectionCount : max,
            min: hasRules ? cellData.rules[0].minSelectionCount : min,
          },
          matrixDataCache: {
            ...state.matrixDataCache,
            [state.activeBetTypeKey]: {
              ...state.matrixDataCache[state.activeBetTypeKey],
              cellData,
            },
          },
        };
      }
      break;
    case actionTypes.SET_ACTIVE_BET_TYPE:
      return ((state) => {
        let min;
        let max;
        if (action.activeBetType.betTypeGroupId === 1) {
          min = 2;
          max = 40;
        } else if (action.activeBetType.betTypeGroupId === 2) {
          min = 3;
          max = 40;
        } else {
          min = 1;
          max = 40;
        }
        const activeBetTypeKey = getBetTypeKey(action.activeBetType);

        return {
          ...state,
          activeBetType: { ...state.activeBetType, ...action.activeBetType },
          activeBetTypeKey,
          activeCell: {},
          lastSelectionValues: {
            max,
            min,
          },
          matrixDataCache: {
            ...state.matrixDataCache,
            [activeBetTypeKey]: {
              ...state.matrixDataCache[activeBetTypeKey],
              newlyAddedRestrictionKey: "",
            },
          },
        };
      })(state);

    case actionTypes.SET_ACTIVE_HISTORY:
      return { ...state, activeHistory: action.activeHistory };

    case actionTypes.SET_PATH_ID_FOR_EVENT:
      return { ...state, pathIdForEvent: action.pathIdForEvent };

    case actionTypes.SET_EVALUATION_ORDER_VISIBILITY:
      return { ...state, isEvaluationOrderVisible: action.isEvaluationOrderVisible };

    case actionTypes.NEXT_STEP:
      return ((state) => {
        let stepIndex = state.stepIndex + 1;
        let step;
        for (let i = stepIndex; i < state.steps.length; i++) {
          const stepKey = state.steps[i];
          if (state.stepsMap[stepKey].enabled) {
            stepIndex = i;
            step = { ...state.stepsMap[stepKey] };
            break;
          }
        }

        return { ...state, step, stepIndex };
      })(state);

    case actionTypes.PREVIOUS_STEP:
      return ((state) => {
        let stepIndex = state.stepIndex - 1;
        let step;
        for (let i = stepIndex; i >= 0; i--) {
          const stepKey = state.steps[i];
          if (state.stepsMap[stepKey].enabled) {
            stepIndex = i;
            step = { ...state.stepsMap[stepKey] };
            break;
          }
        }

        return { ...state, step, stepIndex };
      })(state);

    case actionTypes.UPDATE_STEP:
      return {
        ...state,
        stepsMap: {
          ...state.stepsMap,
          [action.key]: { ...state.stepsMap[action.key], ...action.data },
        },
      };

    case actionTypes.RESET_STEPS:
      return {
        ...state,
        newBetRestrictionData: {
          ...state.newBetRestrictionData,
          ...defaultNewBetRestrictionData,
          newMatrixBetType: {},
          selectedType: {},
        },
        step: stepsMap[steps[initialStepIndex]],
        stepIndex: initialStepIndex,
        steps: [...steps],

        stepsMap: { ...stepsMap },
      };

    case actionTypes.RESET_HISTORY_DATA:
      return { ...state, activeHistory: {}, history: [] };

    case actionTypes.UPDATE_NEW_MATRIX_DATA:
      return { ...state, newBetRestrictionData: { ...state.newBetRestrictionData, ...action.data } };

    case actionTypes.RESET_CURRENT_DATA:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }

  return null;
};

export default betRestrictions;
