import moment from "moment";

import filterTypes from "constants/filterTypes";

const constants = {
  CHANGE_CURRENCY: "PH::CHANGE_CURRENCY",
  ADD_NOTE: "PH::ADD_NOTE",
  CHANGE_DATE_RANGE: "PH::CHANGE_DATE_RANGE",
  CLEAR_DEPOSIT_WITHDRAWAL: "PH::CLEAR_DEPOSIT_WITHDRAWAL",
  FETCH_APPROVAL_CANDIDATES: "PH::FETCH_APPROVAL_CANDIDATES",
  FETCH_APPROVAL_CANDIDATES_FAILED: "PH::FETCH_APPROVAL_CANDIDATES_FAILED",
  FETCH_APPROVAL_CANDIDATES_SUCCEEDED: "PH::FETCH_APPROVAL_CANDIDATES_SUCCEEDED",
  FETCH_BASE_CURRENCY: "PH::FETCH_BASE_CURRENCY",

  FETCH_CUSTOM_SEARCH: "PH::FETCH_CUSTOM_SEARCH",
  FETCH_CUSTOM_SEARCH_FAILED: "PH::FETCH_CUSTOM_SEARCH_FAILED",
  FETCH_CUSTOM_SEARCH_SUCCEEDED: "PH::FETCH_CUSTOM_SEARCH_SUCCEEDED",
  FETCH_DEPOSIT_APPROVAL_CANDIDATES: "PH:FETCH_DEPOSIT_APPROVAL_CANDIDATES",
  FETCH_NOTES: "PH::FETCH_NOTES",
  FETCH_DEPOSIT_APPROVAL_CANDIDATES_FAILED: "PH:FETCH_DEPOSIT_APPROVAL_CANDIDATES_FAILED",
  FETCH_PAYMENTS: "PH::FETCH_PAYMENTS",
  DELETE_DEPOSIT_UPDATE_DATA: "PH::DELETE_DEPOSIT_UPDATE_DATA",
  FETCH_PAYMENTS_FAILED: "PH::FETCH_PAYMENTS_FAILED",
  FETCH_DEPOSIT_APPROVAL_CANDIDATES_SUCCEEDED: "PH:FETCH_DEPOSIT_APPROVAL_CANDIDATES_SUCCEEDED",

  FETCH_PAYMENTS_SUCCEEDED: "PH::FETCH_PAYMENTS_SUCCEEDED",
  DELETE_WITHDRAWAL_UPDATE_DATA: "PH::DELETE_WITHDRAWAL_UPDATE_DATA",
  FETCH_PREFERENCES: "PH::FETCH_PREFERENCES",
  RECEIVE_CUSTOM_SEARCH_DEPOSIT: "PH::RECEIVE_CUSTOM_SEARCH_DEPOSIT",

  RECEIVE_NOTE: "PH::RECEIVE_NOTE",
  RECEIVE_CUSTOM_SEARCH_WITHDRAWALS: "PH::RECEIVE_CUSTOM_SEARCH_WITHDRAWALS",
  RECEIVE_NOTES: "PH::RECEIVE_NOTES",
  MAKE_TRANSACTION_PENDING: "PH::MAKE_TRANSACTION_PENDING",
  RECEIVE_PREFERENCES: "PH::RECEIVE_PREFERENCES",
  FETCH_PAYMENT_STATUS_FOR_FILTER: "PH::FETCH_PAYMENT_STATUS_FOR_FILTER",
  RESET_CALENDAR: "PH::RESET_CALENDAR",

  FETCH_PAYMENT_STATUS_FOR_FILTER_SUCCEEDED: "PH::FETCH_PAYMENT_STATUS_FOR_FILTER_SUCCEEDED",
  RESET_STATUS: "PH::RESET_STATUS",
  FETCH_STATUS: "PH::FETCH_STATUS",
  SET_BASE_CURRENCY: "PH::SET_BASE_CURRENCY",

  FETCH_FILTERED_DATA: "PH::FETCH_FILTERED_DATA",
  SELECT_CRITERIA: "PH::SELECT_CRITERIA",

  FETCH_FILTERED_DATA_SUCCESS: "PH::FETCH_FILTERED_DATA_SUCCESS",
  SELECT_CUSTOM_CRITERIA: "PH::SELECT_CUSTOM_CRITERIA",
  FETCH_STATUS_LIST: "PH::FETCH_STATUS_LIST",

  SELECT_STATUS: "PH::SELECT_STATUS",
  RESET_DEPOSIT_UPDATE_DATA: "PH::RESET_DEPOSIT_UPDATE_DATA",
  UPDATE_CUSTOM_SEARCH_CRITERIA: "PH::UPDATE_CUSTOM_SEARCH_CRITERIA",

  RESET_NOTES: "PH::RESET_NOTES",
  SELECT_TAB: "PH::SELECT_TAB",
  RESET_WITHDRAWAL_UPDATE_DATA: "PH::RESET_WITHDRAWAL_UPDATE_DATA",

  SET_CALENDAR: "PH::SET_CALENDAR",
  SET_DEPOSIT_UPDATE_DATA: "PH::SET_DEPOSIT_UPDATE_DATA",
  TOGGLE_NOTES: "PH::TOGGLE_NOTES",

  SET_DFAULT_SELECTED_STATUS: "PH::SET_DFAULT_SELECTED_STATUS",
  TOGGLE_PREFERENCES: "PH::TOGGLE_PREFERENCES",
  SET_FILTER_DATE: "PH::SET_FILTER_DATE",

  SET_SEARCH_BY: "PH::SET_SEARCH_BY",
  UPDATE_PREFERENCES: "PH::UPDATE_PREFERENCES",
  SET_WITHDRAWAL_UPDATE_DATA: "PH::SET_WITHDRAWAL_UPDATE_DATA",

  SHOW_NOTES: "PH::SHOW_NOTES",
  TOGGLE_APPROVE_ALL_CONFIRM_MODAL: "PH:TOGGLE_APPROVE_ALL_CONFIRM_MODAL",
  TOGGLE_UNSAVED_MODAL: "PH::TOGGLE_UNSAVED_MODAL",
  UPDATE_DEPOSITS: "PH:UPDATE_DEPOSITS",
  UPDATE_DEPOSITS_FAILED: "PH:UPDATE_DEPOSITS_FAILED",
  UPDATE_DEPOSITS_SUCCEEDED: "PH:UPDATE_DEPOSITS_SUCCEEDED",
  UPDATE_PAYMENT_NOTES: "PH::UPDATE_PAYMENT_NOTES",
  UPDATE_WITHDRAWALS: "PH:UPDATE_WITHDRAWALS",
  UPDATE_WITHDRAWALS_FAILED: "PH:UPDATE_WITHDRAWALS_FAILED",
  UPDATE_WITHDRAWALS_SUCCEEDED: "PH:UPDATE_WITHDRAWALS_SUCCEEDED",
};

export const paymentType = {
  DEPOSITS: "deposits",
  WITHDRAWALS: "withdrawals",
};

export const searchByOptions = [
  {
    description: "Any Text",
    id: "1",
    name: "anyText",
    prefId: 0,
  },
  {
    description: "Account ID",
    id: "2",
    name: "accountId",
    prefId: 44,
  },
  {
    description: "Account Name",
    id: "3",
    name: "accountName",
    prefId: 32,
  },
  {
    description: "Account Number",
    id: "4",
    name: "accountNumber",
    prefId: 34,
  },
  {
    description: "Username",
    id: "5",
    name: "userName",
    prefId: 54,
  },
];

export const url = {
  // we can change this
  // APPROVAL_CANDIDATES: '/bo/financials/payments/approvalcandidates',
  APPROVAL_CANDIDATES: "/bo/ph/withdrawals",
  // DEPOSIT_APPROVAL_CANDIDATES: '/bo/financials/payments/userdeposits/approvalcandidates',
  DEPOSIT_APPROVAL_CANDIDATES: "/bo/ph/deposits",

  DEPOSIT_CANDIDATES: "/bo/financials/payments/userdeposits",

  PAYMENT_DEPOSITS: "/bo/financials/payments/deposits",

  PAYMENT_WITHDRAWALS: "/bo/financials/payments/withdrawals",

  // UPDATE_DEPOSITS: '/bo/financials/payments/userdeposits',
  UPDATE_DEPOSITS: "/bo/ph/",

  // UPDATE_WITHDRAWALS: '/bo/financials/payments/paymentStatus',
  UPDATE_WITHDRAWALS: "/bo/ph/",
};

export const permissionsCode = {
  APPROVE_ALL: 1325,
  EDIT_APPROVAL_STATUS: 1200,
  EDIT_FINAL_APPROVAL_STATUS: 1215,
  SEARCH_ACCOUNT_ID: 1321,
  SEARCH_ACCOUNT_NAME: 1324,
  SEARCH_ACCOUNT_NUMER: 1322,
  SEARCH_BY_CURRENCY: 1326,
  SEARCH_USERNAME: 1323,
};

const dates = filterTypes.TRANSACTION_DATES;

export const availableDates = [
  dates.LAST_HOUR,
  dates.LAST_24,
  dates.LAST_48,
  `${dates.THIS_WEEK} (${moment().startOf("week").format("DD MMM YYYY")} to ${moment()
    .endOf("week")
    .format("DD MMM YYYY")})`,
  `${dates.LAST_WEEK} (${moment().subtract(1, "w").startOf("week").format("DD MMM YYYY")} to ${moment()
    .subtract(1, "w")
    .endOf("week")
    .format("DD MMM YYYY")})`,
  `${dates.THIS_MONTH} (${moment().startOf("month").format("DD MMM YYYY")} to ${moment()
    .endOf("month")
    .format("DD MMM YYYY")})`,
  dates.CUSTOM,
];

export const dummyStatus = [
  {
    description: "New",
    id: 0,
    pending: true,
  },
  {
    description: "Under Review",
    id: 1,
    pending: true,
  },
  {
    description: "Under Final Review",
    id: 2,
    pending: true,
  },
  {
    description: "Pending 3rd Party",
    id: 3,
    pending: true,
  },
  {
    description: "Rejected",
    id: 4,
    rejected: true,
  },
  {
    description: "Pre-Rejected",
    id: 5,
    rejected: true,
  },
  {
    description: "Rejected by 3rd Party",
    id: 6,
    rejected: true,
  },
  {
    approved: true,
    description: "Approved",
    id: 7,
  },
  {
    approved: true,
    description: "Pre-Approved",
    id: 8,
  },
  {
    approved: true,
    description: "Approved for 3rd Party",
    id: 9,
  },
  {
    approved: true,
    description: "Approved by 3rd Party",
    id: 10,
  },
];

export default constants;
