import _ from "lodash";

import constants from "./constants";

const initialState = {
  backupPriority: [],
  channels: [],
  edit: false,
  isFetchingTriggers: false,
  isFetchingTriggersFailed: false,
  modified: false,
  modifiedPriorityList: [],
  msg: "",
  priority: [],
  selectedRow: "",
  triggers: [],
  view: false,
};

const utilitiesChannelUtility = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_MESSAGE_TRIGGERS_CHANNELS:
      return {
        ...state,
        isFetchingTriggers: true,
        isFetchingTriggersFailed: false,
      };
    case constants.FETCH_MESSAGE_TRIGGERS_CHANNELS_SUCCEEDED:
      return {
        ...state,
        backupPriority: _.cloneDeep(action.priority),
        channels: action.channels,
        isFetchingTriggers: false,
        modified: false,
        modifiedPriorityList: [],
        priority: action.priority,
        triggers: action.triggers,
      };
    case constants.UPDATE_ROW:
      const priority = state.priority;
      const backupPriority = state.backupPriority;
      const rowId = action.rowId;
      const columnId = action.columnId;
      const value = action.value;
      const modifiedPriorityList = state.modifiedPriorityList;
      const index = _.findIndex(priority, (p) => p.channelId == action.rowId && p.triggerId == action.columnId);
      if (priority[index]) {
        priority[index]["priority"] = value;
        if (priority[index]["priority"] == backupPriority[index]["priority"]) {
          _.remove(modifiedPriorityList, (m) => m.id == priority[index]["id"]);
        } else {
          const isAlreadyThere = _.findIndex(modifiedPriorityList, (m) => m.id == priority[index]["id"]);
          if (isAlreadyThere >= 0) {
            modifiedPriorityList[isAlreadyThere]["priority"] = value;
          } else {
            modifiedPriorityList.push(priority[index]);
          }
        }
      }

      return {
        ...state,
        modified: JSON.stringify(priority) != JSON.stringify(backupPriority),
        modifiedPriorityList,
        priority: _.cloneDeep(priority),
      };
    case constants.UNDO_CHANNEL_UTILITY:
      return {
        ...state,
        modified: false,
        modifiedPriorityList: [],
        priority: _.cloneDeep(state.backupPriority),
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesChannelUtility;
