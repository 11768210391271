import { put, call, takeLatest } from "redux-saga/effects";

import { store, history } from "../../../../store";
import { buildTreeItem, buildRemoveTreeItem } from "../../Sidebar/helpers";

import {
  FETCH_TERMS,
  RECEIVE_TERMS,
  UPDATE_TERM,
  UPDATE_TERM_SUC,
  CREATE_TERM_SUC,
  CREATE_TERM,
  COPY_TERM,
  COPY_TERM_SUCCESS,
  COPY_TERM_FAILED,
  SET_TERMS_MODE,
  TERMMODE,
  DELETE_TERM,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAILED,
  FETCH_TERM_CAMPAIGNS,
  FETCH_TERM_CAMPAIGNS_SUCCESS,
  FETCH_TERM_CAMPAIGNS_FAILED,
} from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import * as CRMAPI from "containersV2/CombiRiskManager/services";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchTerms(action) {
  const { id } = action;
  const { response } = yield call(API.fetchTerms, id);
  if (response) {
    yield put({ terms: response, type: RECEIVE_TERMS });
  } else {
    // const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr)
    // yield put({ type: actionTypes.FETCH_PROMOTIONS_FAILED, response: response })
    toastr.add({ message: `Error`, type: "ERROR" });
  }
}

function* updateTerm(action) {
  const { id, term } = action;
  const { response, xhr } = yield call(API.updateTerm, id, term);
  if (response) {
    yield put({ term: response, type: UPDATE_TERM_SUC });
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    toastr.add({ message: i18n.t("Bonus.Terms and Conditions was successfully updated") });
    const { bmGlobal } = store.getState().bonusManager;
    if (response.retired && !bmGlobal.showRetired) {
      const { root, tree } = buildRemoveTreeItem({
        category: "termsAndConditions",
        id: action.id,
        type: "TERMS_AND_CONDITONS",
      });
      yield put(root);
      yield put(tree);
      history.push(`/bonus-manager/terms-and-conditions`);
    } else {
      const { root, tree } = buildTreeItem({
        category: "termsAndConditions",
        item: response,
        type: "TERMS_AND_CONDITONS",
        update: true,
      });
      yield put(root);
      yield put(tree);
    }
  } else {
    const error = JSON.parse(xhr.response);
    toastr.add({ message: `Error. ${error.error}`, type: "ERROR" });
  }
}

function* createTerm(action) {
  const { term } = action;
  const { response, xhr } = yield call(API.createTerm, term);
  if (response) {
    yield put({ term: response, type: CREATE_TERM_SUC });
    const { root, tree } = buildTreeItem({
      category: "termsAndConditions",
      item: response,
      type: "TERMS_AND_CONDITONS",
    });
    yield put(root);
    yield put(tree);
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    history.push(`/bonus-manager/terms-and-conditions/${response.id}`);
    // yield put({ type: SET_TERMS_MODE, mode: TERMMODE.UPDATE })
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr) || JSON.parse(xhr.responseText).errors[0].message;
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* copyTerm(action) {
  const { term } = action;
  const { response, xhr } = yield call(API.copyTerm, term);
  if (response) {
    yield put({ term: response, type: COPY_TERM_SUCCESS });
    toastr.add({ message: i18n.t("Bonus.Terms and Conditions was successfully created") });
    const { root, tree } = buildTreeItem({
      category: "termsAndConditions",
      item: response,
      type: "TERMS_AND_CONDITONS",
    });
    yield put(root);
    yield put(tree);
    yield put({ id: null, type: FETCH_TERMS });
    yield put({ state: true, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    yield put({ mode: TERMMODE.UPDATE, type: SET_TERMS_MODE });
    history.push(`/bonus-manager/terms-and-conditions/${response.id}`);
  } else {
    toastr.add({ message: `Error. ${xhr.error}`, type: "ERROR" });
    yield put({ type: COPY_TERM_FAILED });
  }
}

function* deleteTerm(action) {
  const { response, xhr } = yield call(API.deleteTerm, action.id);
  if (response) {
    yield put({
      type: DELETE_TERM_SUCCESS,
    });
    toastr.add({ message: i18n.t("Bonus.Terms and Conditions was successfully deleted") });
    const { root, tree } = buildRemoveTreeItem({
      category: "termsAndConditions",
      id: action.id,
      type: "TERMS_AND_CONDITONS",
    });
    yield put(root);
    yield put(tree);
    history.push(`/bonus-manager/terms-and-conditions/`);
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    yield put({ modalName: "bmDeleteModal", type: "CLOSE_MODAL" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: DELETE_TERM_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* fetchTermCampaigns(action) {
  const { id } = action;
  const { response } = yield call(API.fetchTermCampaigns, id);
  if (response) {
    yield put({ response, type: FETCH_TERM_CAMPAIGNS_SUCCESS });
  } else {
    // const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr)
    // yield put({ type: actionTypes.FETCH_PROMOTIONS_FAILED, response: response })
    yield put({ type: FETCH_TERM_CAMPAIGNS_FAILED });
    toastr.add({ message: `Error`, type: "ERROR" });
  }
}
// function* fetchPromotionData(action) {
//   const { id } = action
//   const { response, xhr } = yield call(API.fetchPromotions, id)
//   if (response) {
//     yield put({ type: actionTypes.FETCH_PROMOTION_DATA_SUCCEEDED, promotion: response })
//   } else {
//     const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr)
//     yield put({ type: actionTypes.FETCH_PROMOTION_DATA_FAILED, response: response })

//     toastr.add({ message: `Error. ${msg}`, type: 'ERROR' })
//   }
// }

export default function* bonusManagerTermsSaga() {
  yield takeLatest(FETCH_TERMS, fetchTerms);
  yield takeLatest(UPDATE_TERM, updateTerm);
  yield takeLatest(CREATE_TERM, createTerm);
  yield takeLatest(COPY_TERM, copyTerm);
  yield takeLatest(DELETE_TERM, deleteTerm);
  yield takeLatest(FETCH_TERM_CAMPAIGNS, fetchTermCampaigns);

  //   yield takeLatest(actionTypes.FETCH_PROMOTION_DATA, fetchPromotionData)
}
