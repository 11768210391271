import customerManagerConfig from "../../configs/customerManagerConfig";
import actionTypes from "../../constants";

const initialState = {
  ...customerManagerConfig,
  accttypes: [],
  countries: [],
  currencies: [],
  isFetchingData: false,
  isRequestFailed: false,
  languages: [],
  lines: [],
  origins: [],
  p8Settings: {},
  packages: [],
  priceFormats: [],
  referralMethods: [],
  securityQuestions: [],
};

const customerManagerConstants = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORIGINS:
    case actionTypes.FETCH_LINES:
    case actionTypes.FETCH_ACCTTYPES:
    case actionTypes.FETCH_PRICE_FORMATS:
    case actionTypes.FETCH_PACKAGES:
    case actionTypes.FETCH_LANGUAGES:
    case actionTypes.FETCH_COUNTRIES:
    case actionTypes.FETCH_REFERRAL_METHODS:
    case actionTypes.FETCH_CURRENCIES:
    case actionTypes.FETCH_SECURITY_QUESTIONS:
    case actionTypes.FETCH_P8SETTINGS:
      return {
        ...state,
        isFetchingData: true,
      };
    case actionTypes.FETCH_ORIGINS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        origins: action.origins,
      };
    case actionTypes.FETCH_LINES_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        lines: action.lines,
      };
    case actionTypes.FETCH_ACCTTYPES_SUCCEEDED:
      return {
        ...state,
        accttypes: action.accttypes,
        isFetchingData: false,
      };
    case actionTypes.FETCH_PRICE_FORMATS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        priceFormats: action.priceFormats,
      };
    case actionTypes.FETCH_PACKAGES_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        packages: action.packages,
      };
    case actionTypes.FETCH_LANGUAGES_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        languages: action.languages,
      };
    case actionTypes.FETCH_COUNTRIES_SUCCEEDED:
      return {
        ...state,
        countries: action.countries,
        isFetchingData: false,
      };
    case actionTypes.FETCH_REFERRAL_METHODS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        referralMethods: action.referralMethods,
      };
    case actionTypes.FETCH_CURRENCIES_SUCCEEDED:
      return {
        ...state,
        currencies: action.currencies,
        isFetchingData: false,
      };
    case actionTypes.FETCH_SECURITY_QUESTIONS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        securityQuestions: action.securityQuestions,
      };
    case actionTypes.FETCH_P8SETTINGS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        p8Settings: action.p8Settings,
      };
    case actionTypes.FETCH_ORIGINS_FAILED:
    case actionTypes.FETCH_LINES_FAILED:
    case actionTypes.FETCH_ACCTTYPES_FAILED:
    case actionTypes.FETCH_PRICE_FORMATS_FAILED:
    case actionTypes.FETCH_PACKAGES_FAILED:
    case actionTypes.FETCH_LANGUAGES_FAILED:
    case actionTypes.FETCH_COUNTRIES_FAILED:
    case actionTypes.FETCH_REFERRAL_METHODS_FAILED:
    case actionTypes.FETCH_CURRENCIES_FAILED:
    case actionTypes.FETCH_SECURITY_QUESTIONS_FAILED:
    case actionTypes.FETCH_P8SETTINGS_FAILED:
      return {
        ...state,
        isRequestFailed: true,
      };
    default:
      return { ...state };
  }
};

export default customerManagerConstants;
