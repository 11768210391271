import constants, { CATEGORY_MAP } from "./constants";

export const generateKey = (item) => `${item.type}-${item.id}`.replace("LOCKED_", "");

export const traverseChildren = ({ item, level, marked, newItem, parentUrl, treeMap }) => {
  let url;
  if (item.type === "PROMOTION") {
    url = CATEGORY_MAP["promotions"].url;
  } else if (item.type === "PROMO_GROUP") {
    url = CATEGORY_MAP["promotionalGroups"].url;
  } else if (item.type === "CAMPAIGN") {
    url = CATEGORY_MAP["campaigns"].url;
  } else if (item.type === "TERMS_AND_CONDITONS") {
    url = CATEGORY_MAP["termsAndConditions"].url;
  } else if (item.type === "CAMPAIGN_MESSAGE_PLAN") {
    url = CATEGORY_MAP["messagePlans"].url;
  } else {
    url = parentUrl;
  }
  const key = generateKey(item);
  const prevItem = treeMap[key] || {};
  treeMap[key] = {
    ...item,
    children: [],
    isActive: !!prevItem.isActive,
    isExpanded: newItem || !!prevItem.isExpanded,
    key,
    marked,
    url: `${url}/${item.id}`,
  };
  if (item.children) {
    treeMap[key].children = item.children.map((val) => {
      treeMap = traverseChildren({ item: val, parentUrl, treeMap });

      return generateKey(val);
    });
  }

  return treeMap;
};

export const buildTreeItem = ({ category, item: response, type, update }) => {
  if (update) {
  }

  const item = {
    children: response.children || null,
    description: response.description,
    id: response.id,
    name: response.name || response.title,
    retired: response.retired,
    type,
  };

  const treeItem = { ...item };
  delete treeItem.description;

  const treeMap = traverseChildren({
    item: treeItem,
    level: undefined,
    marked: false,
    newItem: true,
    parentUrl: CATEGORY_MAP[category].url,
    treeMap: {},
  });

  const root = {
    item,
    key: category,
    type: constants.ADD_ITEM_TO_TREE_ROOT,
    update,
  };

  const tree = {
    key: category,
    newKey: `${item.type}-${item.id}`,
    treeMap,
    type: constants.ADD_ITEM_TO_TREE,
    update,
  };

  return { root, tree };
};

export const buildRemoveTreeItem = ({ category, id, type }) => {
  const root = {
    id,
    key: category,
    type: constants.REMOVE_ITEM_FROM_TREE_ROOT,
  };

  const tree = {
    key: category,
    newKey: `${type}-${id}`,
    type: constants.REMOVE_ITEM_FROM_TREE,
  };

  return { root, tree };
};

export const getListBaseOnFilter = (keyword, sourceList, category, list) => {
  let newList = [];

  const itemToDisplay = [...sourceList[category]]
    .filter((item) => item.name.toLowerCase().indexOf(keyword) >= 0)
    .map((cat) => cat.id);

  newList = list.filter((l) => itemToDisplay.indexOf(l.id) >= 0);

  return newList;
};
