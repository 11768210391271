import { combineReducers } from "redux";

import campaigns from "../Main/Campaigns/reducers";
import messagePlansCons from "../Main/MessagePlans/constants";
import messagePlans from "../Main/MessagePlans/reducers";
import promotionalGroups from "../Main/PromotionalGroups/reducers";
import promotions from "../Main/Promotions/reducers";
import terms from "../Main/TermsAndConditions/reducers";

import constants from "./constants";

const initialState = {
  form: "",
  isActiveEdit: false,
  module: null,
  showRetired: false,
};

const bmGlobal = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_BM_MODULE:
      return {
        ...state,
        module: action.module,
      };
    case constants.SET_BM_MODULE_EDIT_STATE:
      return {
        ...state,
        form: typeof action.state === "object" ? action.state.form : "",
        isActiveEdit: typeof action.state === "object" ? action.state.state : action.state,
      };
    case constants.TOGGLE_RETIRED:
      return {
        ...state,
        showRetired: !state.showRetired,
      };
    case messagePlansCons.CREATE_MESSAGE_PLAN_FAILED:
      return {
        ...state,
        form: "",
        isActiveEdit: false,
      };
    default:
      return { ...state };
  }
};

export default combineReducers({
  bmGlobal,
  campaigns,
  messagePlans,
  promotionalGroups,
  promotions,
  terms,
});
