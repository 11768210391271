const constants = {
  ADD_MESSAGE_PLAN: "BM:ADD_MESSAGE_PLAN",
  ADD_PROMOTION: "BM:CAMPAIGN_ADD_PROMOTION",
  COPY_CAMPAIGN: "BM:COPY_CAMPAIGN",
  COPY_CAMPAIGN_FAILED: "BM:COPY_CAMPAIGN_FAILED",
  COPY_CAMPAIGN_SUCCESS: "BM:COPY_CAMPAIGN_SUCCESS",
  CREATE_CAMPAIGN: "BM:CREATE_CAMPAIGN",
  CREATE_CAMPAIGN_SUC: "BM:CREATE_CAMPAIGN_SUC",
  DELETE_CAMPAIGN: "BM:DELETE_CAMPAIGN",
  DELETE_CAMPAIGN_FAILED: "BM:DELETE_CAMPAIGN_FAILED",
  DELETE_CAMPAIGN_SUCCESS: "BM:DELETE_CAMPAIGN_SUCCESS",
  FETCH_BRANDS: "BM::FETCH_BRANDS",
  FETCH_BRANDS_SUCCEEDED: "BM::FETCH_BRANDS_SUCCEEDED",
  CREATE_PROMO_CODE: "BM:CREATE_PROMO_CODE",
  FETCH_CAMPAIGNS: "BM:FETCH_CAMPAIGNS",
  CREATE_PROMO_CODE_FAILED: "BM:CREATE_PROMO_CODE_FAILED",
  FETCH_CAMPAIGN_MESSAGES: "BM:FETCH_CAMPAIGN_MESSAGES",
  CREATE_PROMO_CODE_SUCCESS: "BM:CREATE_PROMO_CODE_SUCCESS",
  FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG: "BM:FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG",
  FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_FAILED: "BM:FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_FAILED",
  FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_SUCCESS: "BM:FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_SUCCESS",
  FETCH_CAMPAIGN_PROMOTION_STATISTICS: "BM:FETCH_CAMPAIGN_PROMOTION_STATISTICS",
  RECEIVE_CAMPAIGN: "BM:RECEIVE_CAMPAIGN",
  CHANGE_PROMO_FILTER: "BM:CHANGE_PROMO_FILTER",
  RECEIVE_CAMPAIGNS: "BM:RECEIVE_CAMPAIGNS",
  FETCH_CAMPAIGN_PROMOTION_STATISTICS_FAILED: "BM:FETCH_CAMPAIGN_PROMOTION_STATISTICS_FAILED",
  REMOVE_MESSAGE_PLAN: "BM:REMOVE_MESSAGE_PLAN",
  DELETE_PROMO_CODE: "BM:DELETE_PROMO_CODE",
  REMOVE_PROMOTION: "BM:CAMPAIGN_REMOVE_PROMOTION",
  DELETE_PROMO_CODE_FAILED: "BM:DELETE_PROMO_CODE_FAILED",
  RESET_CAMPAIGN: "BM:RESET_CAMPAIGN",
  BATCH_DELETE_PROMO_CODE: "BM:BATCH_DELETE_PROMO_CODE",
  SET_CAMPAIGN: "BM:SET_CAMPAIGN",
  BATCH_DELETE_PROMO_CODE_FAILED: "BM:BATCH_DELETE_PROMO_CODE_FAILED",
  SET_CAMPAIGN_MODE: "BM:SET_CAMPAIGN_MODE",
  BATCH_DELETE_PROMO_CODE_SUCCESS: "BM:BATCH_DELETE_PROMO_CODE_SUCCESS",

  SET_CAMPAIGN_MODIFIED: "BM:SET_CAMPAIGN_MODIFIED",
  BATCH_UPDATE_PROMO_CODE: "BM:BATCH_UPDATE_PROMO_CODE",

  UPDATE_CAMPAIGN: "BM:UPDATE_CAMPAIGN",
  BATCH_UPDATE_PROMO_CODE_FAILED: "BM:BATCH_UPDATE_PROMO_CODE_FAILED",
  SET_PROMOTION: "BM:CAMPAIGN_SET_PROMOTION",

  BATCH_UPDATE_PROMO_CODE_SUCCESS: "BM:BATCH_UPDATE_PROMO_CODE_SUCCESS",
  UPDATE_CAMPAIGN_SUC: "BM:UPDATE_CAMPAIGN_SUC",
  DELETE_PROMO_CODE_SUCCESS: "BM:DELETE_PROMO_CODE_SUCCESS",

  UPDATE_CAMPAIGN_FAIL: "BM:UPDATE_CAMPAIGN_FAIL",
  FETCH_CAMPAIGN_PROMOTION_STATISTICS_SUCCESS: "BM:FETCH_CAMPAIGN_PROMOTION_STATISTICS_SUCCESS",
  TOGGLE_PROMOTION_STATUS: "BM:CAMPAIGN_TOGGLE_PROMOTION_STATUS",

  FETCH_PROMO_CODE: "BM:FETCH_PROMO_CODE",
  FETCH_PROMO_CODE_FAILED: "BM:FETCH_PROMO_CODE_FAILED",
  SET_LOADING: "BM:SET_LOADING",

  FETCH_PROMO_CODE_SUCCESS: "BM:FETCH_PROMO_CODE_SUCCESS",
  SET_MESSAGE_PLAN: "BM:SET_MESSAGE_PLAN",

  FETCH_PROMO_CODE_USAGE: "BM:FETCH_PROMO_CODE_USAGE",
  FETCH_PROMO_CODE_USAGE_FAILED: "BM:FETCH_PROMO_CODE_USAGE_FAILED",
  FETCH_PROMO_CODE_USAGE_SUCCESS: "BM:FETCH_PROMO_CODE_USAGE_SUCCESS",

  GENERATE_PROMO_CODE: "BM:GENERATE_PROMO_CODE",
  GENERATE_PROMO_CODE_FAILED: "BM:GENERATE_PROMO_CODE_FAILED",
  GENERATE_PROMO_CODE_SUCCESS: "BM:GENERATE_PROMO_CODE_SUCCESS",

  RESET_TERMS_CONDITION: "RESET_TERMS_CONDITION",
  SEARCH_PROMO_CODE: "BM:SEARCH_PROMO_CODE",
  SEARCH_PROMO_CODE_FAILED: "BM:SEARCH_PROMO_CODE_FAILED",

  SEARCH_PROMO_CODE_SUCCESS: "BM:SEARCH_PROMO_CODE_SUCCESS",
  SET_BRANDID: "SET_BRANDID",
  SET_PROMO_CODE_FORM_DATA: "BM:SET_PROMO_CODE_FORM_DATA",

  SET_PROMO_CODE_FORM_METHOD: "BM:SET_PROMO_CODE_FORM_METHOD",
  TOGGLE_PROMO_CODE_MODAL: "BM:TOGGLE_PROMO_CODE_MODAL",
  TOGGLE_SELECTED_PROMO_CODE: "BM:TOGGLE_SELECTED_PROMO_CODE",

  UPDATE_PROMO_CODE: "BM:UPDATE_PROMO_CODE",
  UPDATE_PROMO_CODE_FAILED: "BM:UPDATE_PROMO_CODE_FAILED",
  UPDATE_PROMO_CODE_SUCCESS: "BM:UPDATE_PROMO_CODE_SUCCESS",
};

export const CAMPAIGNMODE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
};

export const frequency = {
  MONTHLY: {
    display: "Day of Month",
  },
  WEEKLY: {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thur",
    5: "Fri",
    6: "Sat",
    7: "Sun",
    display: "Day of Week",
  },
};

export default constants;

export const TimeZone = [
  { country: "(GMT+00:00) Africa/Monrovia", offset: "+00:00", timezone: "Africa/Monrovia" },
  { country: "(GMT+01:00) Europe/Warsaw", offset: "+01:00", timezone: "Europe/Warsaw" },
  { country: "(GMT+02:00) Europe/Vilnius", offset: "+02:00", timezone: "Europe/Vilnius" },
  { country: "(GMT+03:00) Europe/Moscow", offset: "+03:00", timezone: "Europe/Moscow" },
  { country: "(GMT+03:30) Asia/Tehran", offset: "+03:30", timezone: "Asia/Tehran" },
  { country: "(GMT+04:00) Asia/Yerevan", offset: "+04:00", timezone: "Asia/Yerevan" },
  { country: "(GMT+04:30) Asia/Kabul", offset: "+04:30", timezone: "Asia/Kabul" },
  { country: "(GMT+05:00) Asia/Tashkent", offset: "+05:00", timezone: "Asia/Tashkent" },
  { country: "(GMT+05:30) Asia/Colombo", offset: "+05:30", timezone: "Asia/Colombo" },
  { country: "(UTC+05:45) Asia/Kathmandu", offset: "+05:45", timezone: "Asia/Kathmandu" },
  { country: "(GMT+06:00) Asia/Dhaka", offset: "+06:00", timezone: "Asia/Dhaka" },
  { country: "(GMT+06:30) Asia/Rangoon", offset: "+06:30", timezone: "Asia/Rangoon" },
  { country: "(GMT+07:00) Asia/Krasnoyarsk", offset: "+07:00", timezone: "Asia/Krasnoyarsk" },
  { country: "(GMT+08:00) Asia/Manila", offset: "+08:00", timezone: "Asia/Manila" },
  { country: "(UTC+08:45) Australia/Eucla", offset: "+08:45", timezone: "Australia/Eucla" },
  { country: "(GMT+09:00) Asia/Tokyo", offset: "+09:00", timezone: "Asia/Tokyo" },
  { country: "(GMT+09:30) Australia/Darwin", offset: "+09:30", timezone: "Australia/Darwin" },
  { country: "(GMT+10:00) Pacific/Port_Moresby", offset: "+10:00", timezone: "Pacific/Port_Moresby" },
  { country: "(GMT+10:30) Australia/Adelaide", offset: "+10:30", timezone: "Australia/Adelaide" },
  { country: "(GMT+11:00) Pacific/Noumea", offset: "+11:00", timezone: "Pacific/Noumea" },
  { country: "(GMT+12:00) Asia/Kamchatka", offset: "+12:00", timezone: "Asia/Kamchatka" },
  { country: "(UTC+12:45) Pacific/Chatham", offset: "+12:45", timezone: "Pacific/Chatham" },
  { country: "(GMT+13:00) Pacific/Tongatapu", offset: "+13:00", timezone: "Pacific/Tongatapu" },
  { country: "(GMT+14:00) Pacific/Apia", offset: "+14:00", timezone: "Pacific/Apia" },
  { country: "(GMT-01:00) Atlantic/Cape_Verde", offset: "-01:00", timezone: "Atlantic/Cape_Verde" },
  { country: "(GMT-02:00) Atlantic/South_Georgia", offset: "-02:00", timezone: "Atlantic/South_Georgia" },
  { country: "(UTC-02:30) America/St_Johns", offset: "-02:30", timezone: "America/St_Johns" },
  { country: "(GMT-03:00) America/Santiago", offset: "-03:00", timezone: "America/Santiago" },
  { country: "(GMT-03:30) America/St_Johns", offset: "-03:30", timezone: "America/St_Johns" },
  { country: "(GMT-04:00) America/La_Paz", offset: "-04:00", timezone: "America/La_Paz" },
  { country: "(GMT-04:30) America/Caracas", offset: "-04:30", timezone: "America/Caracas" },
  { country: "(GMT-05:00) America/Lima", offset: "-05:00", timezone: "America/Lima" },
  { country: "(GMT-06:00) America/Guatemala", offset: "-06:00", timezone: "America/Guatemala" },
  { country: "(GMT-07:00) America/Mazatlan", offset: "-07:00", timezone: "America/Mazatlan" },
  { country: "(GMT-08:00) America/Tijuana", offset: "-08:00", timezone: "America/Tijuana" },
  { country: "(GMT-09:00) US/Alaska", offset: "-09:00", timezone: "US/Alaska" },
  { country: "(UTC-09:30) Pacific/Marquesas", offset: "-09:30", timezone: "Pacific/Marquesas" },
  { country: "(GMT-10:00) Pacific/Honolulu", offset: "-10:00", timezone: "Pacific/Honolulu" },
  { country: "(GMT-11:00) Pacific/Pago_Pago", offset: "-11:00", timezone: "Pacific/Pago_Pago" },
  { country: "(GMT-12:00) Pacific/Wallis", offset: "-12:00", timezone: "Pacific/Wallis" },
];
