import { select, put, call, takeLatest } from "redux-saga/effects";

import { fetchGameResultsPeriodPoints as fetchGRPP } from "./actions";
import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchGameResultsPeriodPoints(action) {
  const { response } = yield call(API.fetchGameResultsPeriodPoints, action.eventId);
  if (response) {
    yield put({ response, type: constants.FETCH_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_GAME_RESULTS_PERIOD_POINTS_FAILED });
  }
}

function* updateGameResultsPeriodPoints(action) {
  const { response } = yield call(API.updateGameResultsPeriodPoints, action.formData);
  const { event } = yield select((state) => state.eventCreatorEvent);
  if (response) {
    yield put({ response, type: constants.UPDATE_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Event.Successfully updated game scores")}.` });
    yield put(fetchGRPP(event.id));
  } else {
    yield put({ type: constants.UPDATE_GAME_RESULTS_PERIOD_POINTS_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to update game scores")}.`, type: "ERROR" });
  }
}

function* updateGameResultsVoidPeriod(action) {
  const { response } = yield call(API.updateGameResultsVoidPeriod, action.formData);
  const { event } = yield select((state) => state.eventCreatorEvent);
  if (response) {
    yield put({ response, type: constants.UPDATE_GAME_RESULTS_VOID_PERIOD_SUCCEEDED });
    toastr.add({ message: `${i18n.t("Event.Successfully updated void game period")}.` });
    yield put(fetchGRPP(event.id));
  } else {
    yield put({ type: constants.UPDATE_GAME_RESULTS_VOID_PERIOD_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to updated void game period")}.`, type: "ERROR" });
  }
}

function* fetchEventTotals(action) {
  let eventTotalList = [];

  if (action.params.opponentType == "INDIVIDUAL") {
    if (action.params.opponentAId) {
      // get opponent 1 details
      const paramA = {
        filterYN: action.params.filterYN,
        opponentId: action.params.opponentAId,
        periodId: action.params.periodId,
      };
      const responseAId = yield call(API.fetchEventTotals, action.eventId, paramA);
      if (responseAId.response) {
        eventTotalList = eventTotalList.concat(responseAId.response);
      }
    }
    if (action.params.opponentBId) {
      // get opponent 2 details
      const paramB = {
        filterYN: action.params.filterYN,
        opponentId: action.params.opponentBId,
        periodId: action.params.periodId,
      };
      const responseBId = yield call(API.fetchEventTotals, action.eventId, paramB);
      if (responseBId.response) {
        eventTotalList = eventTotalList.concat(responseBId.response);
      }
    }
  } else {
    const { response } = yield call(API.fetchEventTotals, action.eventId, action.params);
    eventTotalList = eventTotalList.concat(response);
  }

  if (eventTotalList) {
    yield put({ response: eventTotalList, type: constants.FETCH_EVENT_TOTALS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EVENT_TOTALS_FAILED });
  }
}

function* updateEventTotals(action) {
  let checkCount = 0;
  while (checkCount < action.eventTotals.length) {
    const { response } = yield call(API.updateEventTotals, action.eventTotals[checkCount]);
    if (response) {
      toastr.add({ message: `${i18n.t("Event.Successfully updated Event Total Type")}.` });
    } else {
      toastr.add({ message: `${i18n.t("Event.Unable to update Event Total Type")}.`, type: "ERROR" });
    }
    checkCount++;
  }
  yield put({ type: constants.UPDATE_EVENT_TOTALS_SUCCEEDED });
}

export default function* eventSaga() {
  yield takeLatest(constants.FETCH_GAME_RESULTS_PERIOD_POINTS, fetchGameResultsPeriodPoints);
  yield takeLatest(constants.UPDATE_GAME_RESULTS_PERIOD_POINTS, updateGameResultsPeriodPoints);
  yield takeLatest(constants.UPDATE_GAME_RESULTS_VOID_PERIOD, updateGameResultsVoidPeriod);
  yield takeLatest(constants.FETCH_EVENT_TOTALS, fetchEventTotals);
  yield takeLatest(constants.UPDATE_EVENT_TOTALS, updateEventTotals);
}
