import originalAxios from "axios";

import filterTypes from "../constants/filterTypes";
import couponModel from "../models/couponModel";

import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";
import { isEmpty } from "lodash";

let toBasket = null;
const chunkRiskDataXhr = {};
let riskDataXhr = null;
let riskDataUpdateXhr = null;
const ufoDataUpdateXhr = null;

export function fetchRiskData(params) {
  return new Promise((resolve, reject) => {
    if (riskDataUpdateXhr) {
      riskDataUpdateXhr.cancel("");
    }
    for (const key in chunkRiskDataXhr) {
      chunkRiskDataXhr[key]?.cancel("");
      delete chunkRiskDataXhr[key];
    }
    const { betStatus, bookType, code, fromDate, liabilityType, line, marketStatusIds, priceType, toDate, winPlace } =
      params;
    let url = `/bo/risks/grid/${code}?virtual=1&marketStatusIds=${marketStatusIds}&lineId=${line}&bookType=${bookType}&liabilityType=${liabilityType}&priceType=${priceType}&winPlace=${winPlace}&hasBets=${betStatus}`;
    toBasket = null;
    if (fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }

    if (riskDataXhr) {
      riskDataXhr.cancel("cancelled due to concurrent request");
    }

    riskDataXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, riskDataXhr);
      riskDataXhr = null;

      resolve(response);
    } catch (err) {
      riskDataXhr = null;
      reject(err);
    }
    riskDataXhr = null;
  })
    .then((response) => {
      if (response.data.toBasket) {
        toBasket = response.data.toBasket;
      }
      couponModel.load(response.data);

      return { response: { hasUpdates: true, success: true } };
    })
    .catch((xhr) => ({ xhr }));
}

export function fetchChunkRiskData(params) {
  return new Promise((resolve, reject) => {
    const { betStatus, bookType, code, fromDate, liabilityType, line, marketStatusIds, priceType, toDate, winPlace } =
      params;

    let url = `/bo/risks/grid/${code}?virtual=1&marketStatusIds=${marketStatusIds}&lineId=${line}&bookType=${bookType}&liabilityType=${liabilityType}&priceType=${priceType}&winPlace=${winPlace}&hasBets=${betStatus}`;
    if (fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }
    if (toBasket) {
      url = `${url}&from=${toBasket}`;
    }

    if (chunkRiskDataXhr[code]) {
      chunkRiskDataXhr[code].cancel("cancelled due to concurrent request");
    }

    chunkRiskDataXhr[code] = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, chunkRiskDataXhr[code]);
      chunkRiskDataXhr[code] = null;

      resolve(response);
    } catch (err) {
      chunkRiskDataXhr[code] = null;
      reject(err);
    }
    chunkRiskDataXhr[code] = null;
  })
    .then((response) => {
      try {
        couponModel.update(response.data, {
          includeAllUpdates: true,
        });
      } catch (e) {}

      return { chunk: { success: true } };
    })
    .catch((xhr) => ({ xhr }));
}

export function updateRiskData(params) {
  return new Promise((resolve, reject) => {
    if (riskDataXhr) {
      riskDataXhr.cancel("");
    }

    const { betStatus, bookType, code, fromDate, liabilityType, line, marketStatusIds, priceType, toDate, winPlace } =
      params;
    let url = `/bo/risks/grid/${code}?virtual=1&marketStatusIds=${marketStatusIds}&lineId=${line}&bookType=${bookType}&liabilityType=${liabilityType}&priceType=${priceType}&winPlace=${winPlace}&hasBets=${betStatus}`;
    if (fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }
    if (toBasket) {
      url = `${url}&from=${toBasket}`;
    }

    if (riskDataUpdateXhr) {
      riskDataUpdateXhr.cancel("cancelled due to concurrent request");
    }

    riskDataUpdateXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, riskDataUpdateXhr);
      riskDataUpdateXhr = null;

      resolve(response);
    } catch (err) {
      riskDataUpdateXhr = null;
      reject(err);
    }
    riskDataUpdateXhr = null;
  })
    .then((response) => {
      if (response.data.toBasket) {
        toBasket = response.data.toBasket;
      }
      try {
        couponModel.update(response.data, {
          includeAllUpdates: true,
        });
      } catch (e) {}

      return { response: { hasUpdates: !isEmpty(response.data.items), success: true } };
    })
    .catch((xhr) => ({ xhr }));
}

function filterMarkets(markets, marketFilter, periodFilter) {
  const allMarkets = marketFilter === filterTypes.MARKETS.ALL_MARKETS;
  const allPeriods = periodFilter === filterTypes.PERIODS.ALL_PERIODS;
  if (allMarkets && allPeriods) {
    return [...markets];
  }

  return markets.filter((market) => {
    let passedMarketFilter = allMarkets;
    let passedPeriodFilter = allPeriods;
    if (!allMarkets) {
      passedMarketFilter = market.desc.toLowerCase().indexOf(marketFilter.toLowerCase()) > -1;
    }
    if (!allPeriods) {
      passedPeriodFilter =
        market.period.toLowerCase().replace(/^\s+|\s+$/g, "") === periodFilter.replace(/^\s+|\s+$/g, "").toLowerCase();
    }

    return passedMarketFilter && passedPeriodFilter;
  });
}

function getMarketPeriodsFromEvent(event) {
  const marketPeriods = [];
  event.children.forEach((market) => {
    if (marketPeriods.indexOf(market.periodAbrv) > -1) {
      return;
    }
    marketPeriods.push(market.periodAbrv);
  });

  return marketPeriods;
}

export function filterEvents(marketFilter, periodFilter, eventSearchString) {
  const allMarkets = marketFilter === filterTypes.MARKETS.ALL_MARKETS;
  const allPeriods = periodFilter === filterTypes.PERIODS.ALL_PERIODS;
  let events = couponModel.tree.events.map((event) => {
    const marketPeriods = getMarketPeriodsFromEvent(event);

    return { ...event, children: event.children, marketPeriods };
  });
  if (allMarkets && allPeriods && !eventSearchString.length) {
    couponModel.setFilteredEvents(events);

    return;
  }
  if (!allMarkets || !allPeriods || !!eventSearchString.length) {
    events = events.map((event, index) => {
      let children = [...event.children];
      let matchedSearchString = true;
      if (!allMarkets || !allPeriods) {
        children = filterMarkets(event.children, marketFilter, periodFilter);
      }
      if (eventSearchString && eventSearchString.length) {
        matchedSearchString = event.desc.toLowerCase().indexOf(eventSearchString.toLowerCase()) > -1;
      }

      return { ...event, children, matchedSearchString };
    });
  }
  const filteredData = events.filter((event) => !!event.children.length);

  couponModel.setFilteredEvents(filteredData);
}

export function requestUofp(eventId) {
  const url = `/bo/events/uofoddsrecovery`;

  return performHttpCall(ufoDataUpdateXhr, httpMethods.HTTP_POST, url, { id: eventId })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function requestUofs(eventId) {
  const url = `/bo/events/uofstatefulrecovery`;

  return performHttpCall(ufoDataUpdateXhr, httpMethods.HTTP_POST, url, { id: eventId })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
