import actionTypes from "../constants";

const initialState = {
  activeKey: null,
  betData: [],
  betsAnalysis: [],
  fetchingBetDataFailed: null,
  fetchingBetsAnalysisFailed: null,
  fetchingRiskAnalysisSummaryFailed: null,
  fetchingRiskMultipleSummaryFailed: null,
  isFetchingBetData: null,
  isFetchingBetsAnalysis: null,
  isFetchingRiskAnalysisSummary: null,
  isFetchingRiskMultipleSummary: null,
  multipleSummary: [],
  riskAnalysisSummary: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RISK_ANALYSIS_ACTIVE_KEY:
      return { ...state, activeKey: action.key };
    case actionTypes.RESET_RISK_ANALYSIS:
      return { ...initialState };
    case actionTypes.CLEAR_BET_DATA:
      return { ...state, betData: [] };

    case actionTypes.FETCH_RISK_ANALYSIS_SUMMARY:
      return {
        ...state,
        fetchingRiskAnalysisSummaryFailed: false,
        isFetchingRiskAnalysisSummary: true,
        riskAnalysisSummary: {},
      };
    case actionTypes.FETCH_RISK_ANALYSIS_SUMMARY_SUCCEEDED:
      return { ...state, isFetchingRiskAnalysisSummary: false, riskAnalysisSummary: action.riskAnalysisSummary };
    case actionTypes.FETCH_RISK_ANALYSIS_SUMMARY_FAILED:
      return { ...state, fetchingRiskAnalysisSummaryFailed: true, isFetchingRiskAnalysisSummary: false };

    case actionTypes.FETCH_BETS_ANALYSIS:
      return { ...state, betsAnalysis: [], fetchingBetsAnalysisFailed: false, isFetchingBetsAnalysis: true };
    case actionTypes.FETCH_BETS_ANALYSIS_SUCCEEDED:
      return { ...state, betsAnalysis: action.betsAnalysis, isFetchingBetsAnalysis: false };
    case actionTypes.FETCH_BETS_ANALYSIS_FAILED:
      return { ...state, fetchingBetsAnalysisFailed: true, isFetchingBetsAnalysis: false };

    case actionTypes.FETCH_RISK_MULTIPLE_SUMMARY:
      return {
        ...state,
        fetchingRiskMultipleSummaryFailed: false,
        isFetchingRiskMultipleSummary: true,
        multipleSummary: {},
      };
    case actionTypes.FETCH_RISK_MULTIPLE_SUMMARY_SUCCEEDED:
      return { ...state, isFetchingRiskMultipleSummary: false, multipleSummary: action.multipleSummary };
    case actionTypes.FETCH_RISK_MULTIPLE_SUMMARY_FAILED:
      return { ...state, fetchingRiskMultipleSummaryFailed: true, isFetchingRiskMultipleSummary: false };

    case actionTypes.FETCH_BET_DATA:
      return { ...state, betData: [], fetchingBetDataFailed: false, isFetchingBetData: true };
    case actionTypes.FETCH_BET_DATA_SUCCEEDED:
      return { ...state, betData: action.betData, isFetchingBetData: false };
    case actionTypes.FETCH_BET_DATA_FAILED:
      return { ...state, fetchingBetDataFailed: true, isFetchingBetData: false };

    default:
      return { ...state };
  }
}
