import actionTypes from "../../constants";

const initialState = {
  performance: [],
  performanceLoading: false,
  performanceSummary: null,
  performanceSummaryLoading: false,
};

const customerManagerPerformance = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERFORMANCE_SUMMARY:
      return {
        ...state,
        performanceSummary: null,
        performanceSummaryLoading: true,
      };
    case actionTypes.FETCH_PERFORMANCE_SUMMARY_SUCCEEDED:
      return {
        ...state,
        performanceSummary: action.result,
        performanceSummaryLoading: false,
      };
    case actionTypes.FETCH_PERFORMANCE_SUMMARY_FAILED:
      return {
        ...state,
        performanceSummary: null,
        performanceSummaryLoading: false,
      };
    case actionTypes.FETCH_PERFORMANCE:
      return {
        ...state,
        performance: [],
        performanceLoading: true,
      };
    case actionTypes.FETCH_PERFORMANCE_SUCCEEDED:
      return {
        ...state,
        performance: action.result,
        performanceLoading: false,
      };
    case actionTypes.FETCH_PERFORMANCE_FAILED:
      return {
        ...state,
        performance: [],
        performanceLoading: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    default:
      return { ...state };
  }
};

export default customerManagerPerformance;
