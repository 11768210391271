import originalAxios from "axios";

import api from "../api";

let fetchGlobalAccumulatorRisksXhr = null;

export function fetchGlobalAccumulatorRisks(action) {
  const minStake = (action.data && action.data.minStake) || 100;
  const minPayout = (action.data && action.data.minPayout) || 100;

  return new Promise((resolve, reject) => {
    const url = `/bo/accumulatorrisks?minTotalStake=${minStake}&minPotentialWin=${minPayout}&order=stake`;

    if (fetchGlobalAccumulatorRisksXhr) {
      fetchGlobalAccumulatorRisksXhr.cancel("cancelled due to concurrent request");
    }

    fetchGlobalAccumulatorRisksXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchGlobalAccumulatorRisksXhr);
      fetchGlobalAccumulatorRisksXhr = null;
      resolve(response);
    } catch (err) {
      fetchGlobalAccumulatorRisksXhr = null;
      reject(err);
    }
    fetchGlobalAccumulatorRisksXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
