import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchHurdles() {
  const { response, xhr } = yield call(API.fetchHurdles);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_MARKET_CONTROL_HURDLES_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_MARKET_CONTROL_HURDLES_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* fetchAlerts() {
  const { response, xhr } = yield call(API.fetchAlerts);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_MARKET_CONTROL_ALERTS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_MARKET_CONTROL_ALERTS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* saveUpdatedHurdles(action) {
  const { response, xhr } = yield call(API.saveUpdatedHurdles, action.payload);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Updated Successfully"), type: "SUCCESS" });
    yield put({ type: constants.FETCH_MARKET_CONTROL_HURDLES });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* saveUpdatedAlerts(action) {
  const { response, xhr } = yield call(API.saveUpdatedAlerts, action.payload);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Updated Successfully"), type: "SUCCESS" });
    yield put({ type: constants.FETCH_MARKET_CONTROL_ALERTS });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* fetchAlertsEventPaths() {
  const { response, xhr } = yield call(API.fetchAlertsEventPaths);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ALERTS_EVENTSPATHS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ payload: msg, type: constants.FETCH_ALERTS_EVENTSPATHS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

export default function* utilitiesMarketControlSaga() {
  yield takeLatest(constants.FETCH_MARKET_CONTROL_HURDLES, fetchHurdles),
    yield takeLatest(constants.FETCH_MARKET_CONTROL_ALERTS, fetchAlerts),
    yield takeLatest(constants.SAVE_HURDLES_UPDATES, saveUpdatedHurdles),
    yield takeLatest(constants.SAVE_ALERTS_UPDATES, saveUpdatedAlerts),
    yield takeLatest(constants.FETCH_ALERTS_EVENTSPATHS, fetchAlertsEventPaths);
}
