const constants = {
  ADDING_BASE_VOCAB_WORD: "ADDING_BASE_VOCAB_WORD",
  ADDING_BASE_VOCAB_WORD_FAILED: "ADDING_BASE_VOCAB_WORD_FAILED",

  ADDING_BASE_VOCAB_WORD_SUCCEEDED: "ADDING_BASE_VOCAB_WORD_SUCCEEDED",
  ADD_NEW_BASE_VOCAB_INTO_TABLE: "ADD_NEW_BASE_VOCAB_INTO_TABLE",
  DEBUG_WORD_SEARCH: "DEBUG_WORD_SREACH",

  DEBUG_WORD_SEARCH_FAILED: "DEBUG_WORD_SREACH_FAILED",
  DEBUG_WORD_SEARCH_SUCCEEDED: "DEBUG_WORD_SREACH_SUCCEEDED",
  DELETE_BASE_TRANSLATIONS: "DELETE_BASE_TRANSLATIONS",

  DELETE_BASE_TRANSLATIONS_FAILED: "DELETE_BASE_TRANSLATIONS_FAILED",

  DELETE_BASE_TRANSLATIONS_SUCCEEDED: "DELETE_BASE_TRANSLATIONS_SUCCEEDED",
  EMPTY_ALL_LOCALS_DATA: "EMPTY_ALL_LOCALS_DATA",
  FETCH_ALL_LOCALES_DATA: "FETCH_ALL_LOCALES_DATA",
  FETCH_ALL_LOCALES_DATA_FAILED: "FETCH_ALL_LOCALES_DATA_FAILED",
  FETCH_ALL_LOCALES_DATA_SUCCEEDED: "FETCH_ALL_LOCALES_DATA_SUCCEEDED",
  FETCH_SEARCH_TEXT_DATA: "FETCH_SEARCH_TEXT_DATA",
  FETCH_SEARCH_TEXT_DATA_FAILED: "FETCH_SEARCH_TEXT_DATA_FAILED",

  FETCH_SEARCH_TEXT_DATA_SUCCEEDED: "FETCH_SEARCH_TEXT_DATA_SUCCEEDED",
  ON_ROW_SELECTED: "ON_ROW_SELECTED",
  ON_TABLE_ROW_EDIT: "ON_TABLE_ROW_EDIT",

  REMOVE_NEW_BASE_VOCAB_INTO_TABLE: "REMOVE_NEW_BASE_VOCAB_INTO_TABLE",

  RESET_PAGE_STATE: "RESET_PAGE_STATE",
  SAVE_PREVIOUS_DATA: "SAVE_PERVIOUS_DATA",
  SELECTED_LANGUAGES: "SELECTED_LANGUAGES",

  SELECTED_LANGUAGES: "SELECTED_LANGUAGES",
  SET_LOCALES_PREFERENCE: "SET_LOCALES_PREFERENCE",
  TABLE_REFRESH: "TABLE_REFRESH",
  TRANSLATION_MANAGER_APP_ID: "24",

  UNDO_TEXT_CHANGES: "UNDO_TEXT_CHANGES",

  UPDATE_BASE_VOCAB: "UPDATE_BASE_VOCAB",
  UPDATE_BASE_VOCAB_WORD_FAILED: "UPDATE_BASE_VOCAB_WORD_FAILED",
  UPDATE_BASE_VOCAB_WORD_SUCCEEDED: "UPDATE_BASE_VOCAB_WORD_SUCCEEDED",
};

export default constants;
