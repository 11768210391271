const constants = {
  APPLICATION_ID: 45,
  DISABLE_AUTOREFRESH: "CRM::DISABLE_AUTOREFRESH",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED:
    "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED:
    "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_FAILED: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_FAILED",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_SUCCEEDED: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_SUCCEEDED",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_FAILED: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_FAILED",
  FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_SUCCEEDED: "CRM::FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_SUCCEEDED",
  FETCH_PROFILE_FLAGS: "CRM::FETCH_PROFILE_FLAGS",
  FETCH_PROFILE_FLAGS_FAILED: "CRM::FETCH_PROFILE_FLAGS_FAILED",
  FETCH_PROFILE_FLAGS_SUCCEEDED: "CRM::FETCH_PROFILE_FLAGS_SUCCEEDED",
  FETCH_EVENT_PATHS_FILTER: "CRM::FETCH_EVENT_PATHS_FILTER",
  INITIALIZE_DEFAULT: "CRM::INITIALIZE_DEFAULT",
  FETCH_EVENT_PATHS_FILTER_FAILED: "CRM::FETCH_EVENT_PATHS_FILTER_FAILED",
  PUT_COMBINATION_LIABILITY_INDICATOR: "CRM::PUT_COMBINATION_LIABILITY_INDICATOR",
  FETCH_EVENT_PATHS_FILTER_SUCCEEDED: "CRM::FETCH_EVENT_PATHS_FILTER_SUCCEEDED",
  PUT_COMBINATION_LIABILITY_INDICATOR_FAILED: "CRM::PUT_COMBINATION_LIABILITY_INDICATOR_FAILED",
  PUT_COMBINATION_LIABILITY_INDICATOR_SUCCEEDED: "CRM::PUT_COMBINATION_LIABILITY_INDICATOR_SUCCEEDED",
  RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS: "CRM::RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS",
  RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED:
    "CRM::RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED",
  RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED:
    "CRM::RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED",
  REMOVE_COMBINATION_LIABILITY_INDICATOR: "CRM::REMOVE_COMBINATION_LIABILITY_INDICATOR",
  REMOVE_FLAG_ITEM_MODIFIED: "CRM::REMOVE_FLAG_ITEM_MODIFIED",
  RESET_LIMIT_CHANGE: "CRM::RESET_LIMIT_CHANGE",
  RESET_PROFILE_FLAGS: "CRM::RESET_PROFILE_FLAGS",
  RESET_EVENT_PATHS_FILTER: "CRM::RESET_EVENT_PATHS_FILTER",
  RESET_SPORT_AND_EVENTPATH_FILTER: "CRM::RESET_SPORT_AND_EVENTPATH_FILTER",
  GET_UPDATED_LIABILITY_INDICATORS: "CRM::GET_UPDATED_LIABILITY_INDICATORS",
  refreshOptions: [
    {
      value: "0",
      description: "Auto Refresh Off",
    },
    {
      value: "2",
      description: "Every 2 min",
    },
    {
      value: "5",
      description: "Every 5 min",
    },
    {
      value: "10",
      description: "Every 10 min",
    },
  ],
  GET_UPDATED_LIABILITY_INDICATORS_FAILED: "CRM::GET_UPDATED_LIABILITY_INDICATORS_FAILED",
  GET_UPDATED_LIABILITY_INDICATORS_SUCCEEDED: "CRM::GET_UPDATED_LIABILITY_INDICATORS_SUCCEEDED",
  SET_ASSIGN_EVENT_PATH_FILTER: "CRM::SET_ASSIGN_EVENT_PATH_FILTER",
  SET_AUTOREFRESH: "CRM::SET_AUTOREFRESH",
  SET_BET_TYPE: "CRM::SET_BET_TYPE",
  SET_BLOCKING_RULE: "CRM::SET_BLOCKING_RULE",

  SET_BLOCKING_RULE_FAILED: "CRM::SET_BLOCKING_RULE_FAILED",
  SET_BLOCKING_RULE_SUCCEEDED: "CRM::SET_BLOCKING_RULE_SUCCEEDED",

  SET_BLOCKING_TYPE_MAP: "CRM::SET_BLOCKING_TYPE_MAP",
  SET_DEFAULT_CHANNELS: "CRM::SET_DEFAULT_CHANNELS",

  SET_FILTER: "CRM::SET_FILTER",
  SET_FLAG_ITEM_MODIFIED: "CRM::SET_FLAG_ITEM_MODIFIED",
  UPDATE_COMBINATION_LIABILITY_INDICATOR: "CRM::UPDATE_COMBINATION_LIABILITY_INDICATOR",
  SET_LIMIT_CHANGE: "CRM::SET_LIMIT_CHANGE",
  SET_MAIN_PANEL_SELECTED_ITEM: "CRM::SET_MAIN_PANEL_SELECTED_ITEM",

  SET_SPORT_DESC: "CRM::SET_SPORT_DESC",
  SET_SPORT_FILTER: "CRM::SET_SPORT_FILTER",
  SET_TOP_PANEL_DETAILS: "CRM::SET_TOP_PANEL_DETAILS",
  SET_SPORT_FILTER_TYPE: "CRM::SET_SPORT_FILTER_TYPE",

  SET_UNASSIGN_EVENT_PATH_FILTER: "CRM::SET_UNASSIGN_EVENT_PATH_FILTER",

  SHOULD_ITEM_RESET: "CRM::SHOULD_ITEM_RESET",
  SORT_ACCUMULATOR_DATA: "CRM::SORT_ACCUMULATOR_DATA",

  TOGGLE_ASSIGN_EVENT_PATH_FILTER: "CRM::TOGGLE_ASSIGN_EVENT_PATH_FILTER",
  TOGGLE_BLOCKING_RULE_MODAL: "CRM::TOGGLE_BLOCKING_RULE_MODAL",
  TOGGLE_EVENT_PATHS_FILTER: "CRM::TOGGLE_EVENT_PATHS_FILTER",

  TOGGLE_FILTER_MODAL: "CRM::TOGGLE_FILTER_MODAL",
  TOGGLE_UNASSIGN_EVENT_PATH_FILTER: "CRM::TOGGLE_UNASSIGN_EVENT_PATH_FILTER",
  UPDATE_FILTERS: "CRM::UPDATE_FILTERS",
};

export const riskActionTypes = {
  BLOCK_ANY_COMBINATION: "Block Any Combination",
  BLOCK_SPECIFIC_COMBINATION: "Block Specific Combination",
  NONE: "None",
  REFER_100: "Refer Level 2",
  REFER_50: "Refer 50",
  REFER_ALL: "Refer All",
  REJECT_100: "Reject Level 2",
  REJECT_50: "Reject Level 1",
};

export const limitCombinationTypes = {
  LIABILITY: "LIABILITY",
  PAYOUT: "PAYOUT",
  STAKE: "STAKE",
};

export const betTypes = ["None", "Single", "Double", "Treble", "4 Fold Accumulator"];

export const betStatus = {
  "Last Leg": "LAST_LEG",
  Lost: "LOST",
  Pending: "PENDING",
  Void: "VOID",
  Won: "WON",
};

export const blockingStatus = ["ANY", "SPECIFIC", "NONE"];

export const settledStatus = {
  Any: "ANY",
  "Not Settled": "NOT_SETTLED",
  Settled: "SETTLED",
};

export default constants;
