import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchCombLiability(action) {
  const { response } = yield call(API.onFetchCombLiability, action);
  if (response) {
    yield put({ response, type: constants.FETCH_COMBLIABILITY_LIMITS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_COMBLIABILITY_LIMITS_FAILED });
  }
}

function* fetchCombLiabilityActions() {
  const { response } = yield call(API.onFetchCombLiabilityActions);
  if (response) {
    yield put({ response, type: constants.FETCH_COMBLIABILITY_ACTIONS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_COMBLIABILITY_ACTIONS_FAILED });
  }
}
function* udpateCombLiability(action) {
  const { response } = yield call(API.onUdpateCombLiability, action);
  if (response) {
    yield put({ response, type: constants.ON_COMB_ROW_UPDATE_SUCCEEDED });
    yield put({ path: { id: action.data.id }, type: constants.FETCH_COMBLIABILITY_LIMITS });
    toastr.add({ message: i18n.t(`Wager.Comb Liability indicators updated successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_COMB_ROW_UPDATE_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to update Comb Liability indicators`), type: "ERROR" });
  }
}
export default function* CombLiabilityLimits() {
  yield takeLatest(constants.FETCH_COMBLIABILITY_LIMITS, fetchCombLiability);
  yield takeLatest(constants.FETCH_COMBLIABILITY_ACTIONS, fetchCombLiabilityActions);
  yield takeLatest(constants.ON_COMB_ROW_UPDATE, udpateCombLiability);
}
