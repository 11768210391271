import actionTypes from "../../constants";

const initialState = {
  dailyStatistics: {},
  fetchingDailyStatisticsFailed: null,
  isFetchingDailyStatistics: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DAILY_STATISTICS:
      return { ...state, fetchingDailyStatisticsFailed: false, isFetchingDailyStatistics: true };
    case actionTypes.FETCH_DAILY_STATISTICS_SUCCEEDED:
      return { ...state, dailyStatistics: action.dailyStatistics, isFetchingDailyStatistics: false };
    case actionTypes.FETCH_DAILY_STATISTICS_FAILED:
      return { ...state, fetchingDailyStatisticsFailed: true, isFetchingDailyStatistics: false };
    default:
      return { ...state };
  }
}
