const actionTypes = {
  CHOOSE_FOLDER: "CHOOSE_FOLDER",
  CHOOSE_FORMAT: "CHOOSE_FORMAT",
  CHOOSE_REPORT: "CHOOSE_REPORT",

  CLEAR_CACHED_REPORT_DETAILS: "CLEAR_CACHED_REPORT_DETAILS",
  CLEAR_CACHED_REPORT_DETAILS_ALL: "CLEAR_CACHED_REPORT_DETAILS_ALL",

  CLEAR_REPORT_FILTERS: "CLEAR_REPORT_FILTERS",

  FETCH_REPORTS_LIST: "FETCH_REPORTS_LIST",
  FETCH_REPORTS_LIST_FAILED: "FETCH_REPORTS_LIST_FAILED",
  FETCH_REPORTS_LIST_SUCCEEDED: "FETCH_REPORTS_LIST_SUCCEEDED",

  FETCH_REPORT_FILTERS: "FETCH_REPORT_FILTERS",
  FETCH_REPORT_FILTERS_FAILED: "FETCH_REPORT_FILTERS_FAILED",
  FETCH_REPORT_FILTERS_SUCCEEDED: "FETCH_REPORT_FILTERS_SUCCEEDED",

  GET_REPORT: "GET_REPORT",
  GET_REPORT_FAILED: "GET_REPORT_FAILED",

  GET_REPORT_SUCCEEDED: "GET_REPORT_SUCCEEDED",
  SET_REPORT_DETAILS: "SET_REPORT_DETAILS",
  SET_REPORT_FILTER: "SET_REPORT_FILTER",

  TOGGLE_FOLDER: "PRM::TOGGLE_FOLDER",
  TOGGLE_FOLDER_REPORT_ITEM: "PRM::TOGGLE_FOLDER_REPORT_ITEM",
};

export default actionTypes;
