import { put, call, takeLatest } from "redux-saga/effects";

import ecAppConstants from "../App/constants";
import ecEventConstant from "../Event/constants";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* updateApplicableTemplate({ pathObj, payload }) {
  const { response } = yield call(API.putApplicableTemplates, payload);
  if (response) {
    yield put({ response, type: constants.UPDATE_APPLICABLE_TEMPLATES_SUCCEEDED });
    yield put({ isBulkUpdateActive: false, type: ecAppConstants.TOGGLE_BULK_UPDATE });
    toastr.add({ message: i18n.t("Event.Template successfully updated") });

    if (pathObj) {
      yield put({ eventId: pathObj.eventId, isRankEvent: pathObj.isRankEvent, type: ecEventConstant.FETCH_EVENT });
    }
  } else {
    yield put({ type: constants.UPDATE_APPLICABLE_TEMPLATES_FAILED });
    toastr.add({ message: i18n.t("Event.Unable to update template"), type: "ERROR" });
  }
}

function* fetchApplicableTemplates({ eventPathIds }) {
  const { response } = yield call(API.fetchApplicableTemplates, eventPathIds);
  if (response) {
    yield put({ eventPathIds, templates: response, type: constants.FETCH_APPLICABLE_TEMPLATES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_APPLICABLE_TEMPLATES_FAILED });
  }
}

export default function* bulkUpdateSaga() {
  yield takeLatest(constants.UPDATE_APPLICABLE_TEMPLATES, updateApplicableTemplate);
  yield takeLatest(constants.FETCH_APPLICABLE_TEMPLATES, fetchApplicableTemplates);
}
