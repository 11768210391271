import originalAxios from "axios";

import api from "../api";

let fetchDailyStatisticsXhr = null;

export function fetchDailyStatistics() {
  return new Promise((resolve, reject) => {
    const url = `/bo/dailystats`;

    if (fetchDailyStatisticsXhr) {
      fetchDailyStatisticsXhr.cancel("cancelled due to concurrent request");
    }

    fetchDailyStatisticsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchDailyStatisticsXhr);
      fetchDailyStatisticsXhr = null;
      resolve(response);
    } catch (err) {
      fetchDailyStatisticsXhr = null;
      reject(err);
    }
    fetchDailyStatisticsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
