import mainConst from "../../App/constants";

import {
  RECEIVE_TERMS,
  FETCH_TERMS,
  SET_TERMS_MODE,
  TERMMODE,
  UPDATE_TERM_SUC,
  CREATE_TERM_SUC,
  RESET_TERM,
  SET_TERM,
  SET_TERM_MODIFIED,
  COPY_TERM,
  COPY_TERM_SUCCESS,
  COPY_TERM_FAILED,
  DELETE_TERM,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAILED,
  FETCH_TERM_CAMPAIGNS,
  FETCH_TERM_CAMPAIGNS_SUCCESS,
  FETCH_TERM_CAMPAIGNS_FAILED,
} from "./constants";

const initialState = {
  defaultCheck: false,
  isDuplicatingTerm: false,
  isDuplicatingTermFailed: false,
  isLoading: false,
  mode: TERMMODE.VIEW,
  modified: false,
  newTerm: false,
  term: {
    content: "",
    default: false,
    id: null,
    name: "",
    retired: false,
  },
  termCampaigns: [],
  terms: [],
};

const terms = (state = initialState, action) => {
  const { term } = action;
  switch (action.type) {
    case SET_TERM_MODIFIED:
      return {
        ...state,
        modified: action.modified,
      };
    case SET_TERM:
      return {
        ...state,
        term: action.term,
      };
    case RESET_TERM:
      return {
        ...state,
        mode: TERMMODE.VIEW,
        modified: false,
        newTerm: false,
        term: {
          content: "",
          default: false,
          id: null,
          name: "",
          retired: false,
        },
      };
    case CREATE_TERM_SUC:
      return {
        ...state,
        newTerm: true,
        terms: [...state.terms, term],
      };
    case UPDATE_TERM_SUC:
      return {
        ...state,
        mode: TERMMODE.VIEW,
        modified: false,
        terms: state.terms.map((current) => {
          if (Number(term.id) === Number(current.id)) {
            return term;
          }

          return current;
        }),
      };
    case SET_TERMS_MODE:
      const { mode } = action;

      return {
        ...state,
        mode,
      };
    case FETCH_TERMS:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_TERMS:
      const { terms } = action;

      return {
        ...state,
        defaultCheck: { ...terms }.default,
        isLoading: false,
        term: { ...terms },
        terms: terms instanceof Array ? terms : [...state.terms, terms],
      };
    case COPY_TERM:
      return {
        ...state,
        isDuplicatingTerm: true,
        isDuplicatingTermFailed: false,
      };
    case COPY_TERM_SUCCESS:
      return {
        ...state,
        isDuplicatingTerm: false,
        isDuplicatingTermFailed: false,
        term: action.term,
      };
    case COPY_TERM_FAILED:
      return {
        ...state,
        isDuplicatingTerm: false,
        isDuplicatingTermFailed: true,
      };
    case DELETE_TERM:
      return {
        ...state,
        isDeletingTerm: true,
        isDeletingTermFailed: false,
      };
    case DELETE_TERM_SUCCESS:
      return {
        ...state,
        isDeletingTerm: false,
        isDeletingTermFailed: false,
      };
    case DELETE_TERM_FAILED:
      return {
        ...state,
        isDeletingTerm: false,
        isDeletingTermFailed: true,
      };
    case FETCH_TERM_CAMPAIGNS:
      return {
        ...state,
        isFetchingTermCampaigns: true,
        isFetchingTermCampaignsFailed: false,
      };
    case FETCH_TERM_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetchingTermCampaigns: false,
        isFetchingTermCampaignsFailed: false,
        termCampaigns: action.response,
      };
    case FETCH_TERM_CAMPAIGNS_FAILED:
      return {
        ...state,
        isFetchingTermCampaigns: false,
        isFetchingTermCampaignsFailed: true,
      };
    case mainConst.SET_BM_MODULE_EDIT_STATE: {
      const term = _.cloneDeep(state.term);
      term["name"] =
        typeof action.state === "object" && action.state.state && action.state.form == "terms-and-conditions"
          ? "New Terms and Conditions"
          : term["name"];

      return {
        ...state,
        modified:
          typeof action.state === "object" && !action.state.state && action.state.form == "terms-and-conditions"
            ? action.state.state
            : typeof action.state !== "object" && !action.state
            ? action.state
            : state.modified,
        term,
      };
    }
    default:
      return state;
  }
};

export default terms;
