import originalAxios from "axios";

import httpMethods from "constants/httpMethods";
import api from "services//api";
import { performHttpCall } from "services//apiUtils";

let fetchEventPathDetailsXhr = null;
let addEventPathXhr = null;
let editEventPathXhr = null;
let fetchEventPathTagsXhr = null;

let deleteEventPathXhr = null;
let deleteEventXhr = null;
let saveReorderXhr = null;

const deleteEventPathsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function deleteEventPaths(eventpathIds) {
  const url = `/bo/eventpaths/${eventpathIds.join(",")}`;

  return performHttpCall(deleteEventPathsXhr, httpMethods.HTTP_DELETE, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteEventPath(eventPathId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/eventpaths/${eventPathId}`;

    if (deleteEventPathXhr) {
      deleteEventPathXhr.cancel("cancelled due to concurrent request");
    }

    deleteEventPathXhr = originalAxios.CancelToken.source();

    try {
      const response = api.delete(url, {}, deleteEventPathXhr);
      deleteEventPathXhr = null;
      resolve(response);
    } catch (err) {
      deleteEventPathXhr = null;
      reject(err);
    }
    deleteEventPathXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteEvent(eventId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/events/${eventId}`;

    if (deleteEventXhr) {
      deleteEventXhr.cancel("cancelled due to concurrent request");
    }

    deleteEventXhr = originalAxios.CancelToken.source();

    try {
      const response = api.delete(url, {}, deleteEventXhr);
      deleteEventXhr = null;
      resolve(response);
    } catch (err) {
      deleteEventXhr = null;
      reject(err);
    }
    deleteEventXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveReorder(printOrders) {
  return new Promise((resolve, reject) => {
    const url = `/bo/eventpaths/updateprintorders`;

    if (saveReorderXhr) {
      saveReorderXhr.cancel("cancelled due to concurrent request");
    }

    saveReorderXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, printOrders, saveReorderXhr);
      saveReorderXhr = null;
      resolve(response);
    } catch (err) {
      saveReorderXhr = null;
      reject(err);
    }
    saveReorderXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathDetails(eventPathId) {
  return new Promise((resolve, reject) => {
    let url = `/bo/eventpaths`;
    if (eventPathId) {
      url += `/${eventPathId}`;
    }

    if (fetchEventPathDetailsXhr) {
      fetchEventPathDetailsXhr.cancel("cancelled due to concurrent request");
    }

    fetchEventPathDetailsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchEventPathDetailsXhr);
      fetchEventPathDetailsXhr = null;
      resolve(response);
    } catch (err) {
      fetchEventPathDetailsXhr = null;
      reject(err);
    }
    fetchEventPathDetailsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addEventPath(newEventPathDetails) {
  return new Promise((resolve, reject) => {
    const url = `/bo/eventpaths/`;
    const requestBody = newEventPathDetails;

    if (addEventPathXhr) {
      addEventPathXhr.cancel("cancelled due to concurrent request");
    }

    addEventPathXhr = originalAxios.CancelToken.source();

    try {
      const response = api.post(url, requestBody, addEventPathXhr);
      addEventPathXhr = null;
      resolve(response);
    } catch (err) {
      addEventPathXhr = null;
      reject(err);
    }
    addEventPathXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function editEventPath(eventPathId, editedEventPathDetails) {
  return new Promise((resolve, reject) => {
    const url = `/bo/eventpaths/${eventPathId}`;
    const requestBody = editedEventPathDetails;

    if (editEventPathXhr) {
      editEventPathXhr.cancel("cancelled due to concurrent request");
    }

    editEventPathXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, editEventPathXhr);
      editEventPathXhr = null;
      resolve(response);
    } catch (err) {
      editEventPathXhr = null;
      reject(err);
    }
    editEventPathXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathTags() {
  return new Promise((resolve, reject) => {
    const url = `/bo/eventpaths/tags`;

    if (fetchEventPathTagsXhr) {
      fetchEventPathTagsXhr.cancel("cancelled due to concurrent request");
    }

    fetchEventPathTagsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchEventPathTagsXhr);
      fetchEventPathTagsXhr = null;
      resolve(response);
    } catch (err) {
      fetchEventPathTagsXhr = null;
      reject(err);
    }
    fetchEventPathTagsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
