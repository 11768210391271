const constants = {
  FETCH_ALL_SETTINGS_DATA: "FETCH_ALL_SETTINGS_DATA",
  FETCH_ALL_SETTINGS_DATA_FAILED: "FETCH_ALL_SETTINGS_DATA_FAILED",

  FETCH_ALL_SETTINGS_DATA_SUCCEEDED: "FETCH_ALL_SETTINGS_DATA_SUCCEEDED",
  FETCH_SEARCH_TEXT_DATA_SETTING: "FETCH_SEARCH_TEXT_DATA_SETTING",
  FETCH_SEARCH_TEXT_DATA_SETTING_FAILED: "FETCH_SEARCH_TEXT_DATA_SETTING_FAILED",

  FETCH_SEARCH_TEXT_DATA_SETTING_SUCCEEDED: "FETCH_SEARCH_TEXT_DATA_SETTING_SUCCEEDED",
  RESET_PAGE_STATE: "RESET_PAGE_STATE",
  SETTINGS_BROWSER_APP_ID: "34",

  TABLE_REFRESH: "TABLE_REFRESH",
  UNDO_TEXT_CHANGES: "UNDO_TEXT_CHANGES",
};

export default constants;
