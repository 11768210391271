import { defaultChosenFormat } from "../configs/reportsFormats";
import actionTypes from "../constants";

import { generateDateParams } from "./helpers";

const initialState = {
  cachedReportsDetails: {},
  chosenFormat: defaultChosenFormat,
  dateParams: {},
  errMsg: "",
  fetchingReportFiltersFailed: null,
  filters: {},
  isFetchingReportFilters: null,
  reportDetails: {},
};

export default function (state = initialState, action) {
  const parameters = [];
  const params = [];
  switch (action.type) {
    case actionTypes.CHOOSE_FORMAT:
      return { ...state, chosenFormat: action.format };

    case actionTypes.FETCH_REPORT_FILTERS:
      return { ...state, fetchingReportFiltersFailed: false, isFetchingReportFilters: true, reportDetails: {} };

    case actionTypes.FETCH_REPORT_FILTERS_SUCCEEDED:
      const cachedReportsDetails = { ...state.cachedReportsDetails }; // copy cached reports details first
      cachedReportsDetails[action.reportName] = action.reportDetails;

      return {
        ...state,
        cachedReportsDetails,
        dateParams: generateDateParams(action.reportDetails.parameters),
        fetchingReportFiltersFailed: false,
        isFetchingReportFilters: false,
        reportDetails: action.reportDetails,
      };

    case actionTypes.FETCH_REPORT_FILTERS_FAILED:
      return { ...state, errMsg: action.errMsg, fetchingReportFiltersFailed: true, isFetchingReportFilters: false };

    case actionTypes.SET_REPORT_FILTER:
      // concat filter into array as a string
      const filters = { ...state.filters }; // copy filters
      filters[action.filterName] = action.value;

      return { ...state, filters };

    case actionTypes.CLEAR_REPORT_FILTERS:
      return { ...state, filters: {} };

    case actionTypes.SET_REPORT_DETAILS:
      return {
        ...state,
        dateParams: generateDateParams(action.details.parameters),
        isFetchingReportFilters: false,
        reportDetails: action.details,
      };

    case actionTypes.CLEAR_CACHED_REPORT_DETAILS:
      const cachedReportsDetailsToFilter = { ...state.cachedReportsDetails };
      delete cachedReportsDetailsToFilter[action.report];

      return { ...state, cachedReportsDetails: cachedReportsDetailsToFilter };

    case actionTypes.CLEAR_CACHED_REPORT_DETAILS_ALL:
      return { ...state, cachedReportsDetails: {} };

    case actionTypes.GET_REPORT:
      return { ...state };

    default:
      return { ...state };
  }
}
