import playersConst from "../constants/playersConst";

const initialState = {
  actionFailed: false,

  delAllPlayerOfEPErrorMessage: null,

  delAllPlayerOfEPFailed: false,
  // general opponent action info
  errorMessage: null,

  isAddPlayerToTeamPerforming: false,

  isDelAllPlayerOfEPPerforming: false,

  isDeleteAllPlayerOfTeamPerforming: false,

  isDeletePlayerOfTeamPerforming: false,

  isFetchMultiplePlayersOfTeamDetailsPerforming: false,

  // jem bug fix
  isFetchSinglePlayerOfTeamDetailsPerforming: false,

  isPerformingAction: false,

  lastActionMade: null,

  // action specific results
  playerDetails: null,

  playerDetailsList: [],
  playerIdsRemoved: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchSinglePlayerOfTeamDetailsPerforming: true,
        lastActionMade: action.type,
      };
    case playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_SUCCEEDED:
      return { ...state, isFetchSinglePlayerOfTeamDetailsPerforming: false, playerDetails: action.playerDetails };
    case playersConst.FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchSinglePlayerOfTeamDetailsPerforming: false,
      };

    case playersConst.CLEAR_PLAYERS_OF_TEAM_DETAILS:
      return { ...state, playerDetailsList: [] };

    case playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchMultiplePlayersOfTeamDetailsPerforming: true,
        lastActionMade: action.type,
        playerIdsRemoved: [],
      };
    case playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchMultiplePlayersOfTeamDetailsPerforming: false,
        playerDetailsList: action.playerDetailsList,
      };
    case playersConst.FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchMultiplePlayersOfTeamDetailsPerforming: false,
      };

    case playersConst.ADD_PLAYER_TO_TEAM:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isAddPlayerToTeamPerforming: true,
        lastActionMade: action.type,
      };
    case playersConst.ADD_PLAYER_TO_TEAM_SUCCEEDED:
      return { ...state, isAddPlayerToTeamPerforming: false };
    case playersConst.ADD_PLAYER_TO_TEAM_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isAddPlayerToTeamPerforming: false };

    case playersConst.DELETE_PLAYER_OF_TEAM:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isDeletePlayerOfTeamPerforming: true,
        lastActionMade: action.type,
      };
    case playersConst.DELETE_PLAYER_OF_TEAM_SUCCEEDED:
      return {
        ...state,
        isDeletePlayerOfTeamPerforming: false,
        playerIdsRemoved: [...state.playerIdsRemoved, action.playerIdRemoved],
      };
    case playersConst.DELETE_PLAYER_OF_TEAM_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isDeletePlayerOfTeamPerforming: false };

    case playersConst.DELETE_ALL_PLAYER_OF_TEAM:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isDeleteAllPlayerOfTeamPerforming: true,
        lastActionMade: action.type,
      };
    case playersConst.DELETE_ALL_PLAYER_OF_TEAM_SUCCEEDED:
      return { ...state, isDeleteAllPlayerOfTeamPerforming: false };
    case playersConst.DELETE_ALL_PLAYER_OF_TEAM_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isDeleteAllPlayerOfTeamPerforming: false,
      };

    case playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH:
      return {
        ...state,
        delAllPlayerOfEPErrorMessage: null,
        delAllPlayerOfEPFailed: false,
        isDelAllPlayerOfEPPerforming: true,
      };
    case playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH_SUCCEEDED:
      return { ...state, isDelAllPlayerOfEPPerforming: false };
    case playersConst.DELETE_ALL_PLAYER_OF_EVENTPATH_FAILED:
      return {
        ...state,
        delAllPlayerOfEPErrorMessage: action.errorMessage,
        delAllPlayerOfEPFailed: true,
        isDelAllPlayerOfEPPerforming: false,
      };

    default:
      return { ...state };
  }
}
