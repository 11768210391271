import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchSportOtherOptionsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchEventPathTree() {
  const url = `/bo/wagerlimits/tree`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onFetchPlayerProfileGroups() {
  const url = `/bo/wagerlimits/groups`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onFetchWagerLimits(action) {
  const url = `/bo/outcomewagerlimits?eventPathId=${action.val.id}&getWagerLimits=true`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onFetchWagerActions() {
  const url = `/bo/outcomewagerlimits/actions`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchWagerLimitGroup() {
  const url = `/bo/wagerlimitgroups?activeOnly=true`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onSaveWagerLimits(action) {
  const url = `/bo/outcomewagerlimits/updatemultiple?eventPathIds=${action.data.id}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_PUT, url, action.data.data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function removeWagerLimitsForEventPath(action) {
  const url = `/bo/outcomewagerlimits/${action.WagerLimitGroupId}/${action.eventPathIdString}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function removeAll_WL_LIAB_STAKE(action) {
  const url = `/bo/wagerlimits/delete/all/${action.WagerLimitGroupId}/${action.eventPathIdString}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
