import channelConfig from "../configs/channelConfig";
import actionTypes from "../constants";
import filterTypes from "../constants/filterTypes";
import { loadFromStorage, saveInStorage } from "../utils";

const initialState = {
  code: null,
  desc: null,
  fromDate: null,
  market: filterTypes.MARKETS.ALL_MARKETS,
  marketStatusIds: ["1", "2", "3", "4", "5"],
  rowHeight: loadFromStorage("GRID_ROW_HEIGHT") || "Default",
  toDate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RESULTS_VIEWER_FILTER_CODE:
      return { ...state, code: action.code };
    case actionTypes.SET_RESULTS_VIEWER_FILTER_DESC:
      return { ...state, desc: action.desc };
    case actionTypes.ADD_RESULTS_VIEWER_CUSTOM_DATE_FILTER:
    case actionTypes.ADD_CUSTOM_DATE_FILTER:
      return { ...state, fromDate: action.fromDate, toDate: action.toDate };
    case actionTypes.ADD_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER:
      return { ...state, marketStatusIds: [...state.marketStatusIds, action.id] };
    case actionTypes.REMOVE_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER:
      const index = state.marketStatusIds.indexOf(action.id);
      if (index >= 0) {
        return {
          ...state,
          marketStatusIds: [...state.marketStatusIds.slice(0, index), ...state.marketStatusIds.slice(index + 1)],
        };
      }

      return { ...state };

    case actionTypes.SET_RESULTS_VIEWER_ROW_HEIGHT:
      saveInStorage("GRID_ROW_HEIGHT", action.rowHeight);

      return { ...state, rowHeight: action.rowHeight };
    default:
      return { ...state };
  }
}
