import customerManagerConfig from "../../configs/customerManagerConfig";
import actionTypes from "../../constants";

const initialState = {
  ...customerManagerConfig,
  brands: [],
  bulkCreditValidateError: [],
  bulkCreditValidateSuccess: [],
  bulkCredits: [],
  isBulkCreditRequestLoading: false,
  isBulkCreditRequestSuccess: false,
  isFetchingBrandsError: false,
  isFetchingBrandsLoading: false,
  isFetchingBrandsSuccess: false,
  modalErrorMsg: null,
  modalShowAccountTransfer: false,
  modalShowBulkCreditError: false,
  modalShowBulkCreditSuccess: false,
  modalShowBulkCrediting: false,
  modalShowDragonPay: false,
  modalShowDragonPayPayback: false,
  modalShowEndCall: false,
  modalShowError: null,
  modalShowOperatorLogin: false,
  modalShowSelfExclusionWarning: false,
  modalShowTransactionWarning: false,
  modalShowTransferAdvanceSearch: false,
  modalShowTransferConfirmation: false,
  selectedBrand: "",
};

const customerManagerModal = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL_ACCOUNT_TRANSFER_SEARCH:
    case actionTypes.CLOSE_MODAL_ACCOUNT_TRANSFER_SEARCH:
      return {
        ...state,
        modalShowTransferAdvanceSearch: action.show,
      };
    case actionTypes.OPEN_MODAL_ACCOUNT_TRANSFER_CONFIRMATION:
    case actionTypes.CLOSE_MODAL_ACCOUNT_TRANSFER_CONFIRMATION:
      return {
        ...state,
        modalShowTransferConfirmation: action.show,
      };
    case actionTypes.OPEN_MODAL_END_CALL:
    case actionTypes.CLOSE_MODAL_END_CALL:
      return {
        ...state,
        modalShowEndCall: action.show,
      };
    case actionTypes.OPEN_MODAL_ACCOUNT_TRANSFER:
    case actionTypes.CLOSE_MODAL_ACCOUNT_TRANSFER:
      return {
        ...state,
        modalShowAccountTransfer: action.show,
      };
    case actionTypes.OPEN_MODAL_DRAGON_PAY:
    case actionTypes.CLOSE_MODAL_DRAGON_PAY:
      return {
        ...state,
        modalShowDragonPay: action.show,
      };
    case actionTypes.OPEN_MODAL_DRAGON_PAY_PAYBACK:
    case actionTypes.CLOSE_MODAL_DRAGON_PAY_PAYBACK:
      return {
        ...state,
        modalShowDragonPayPayback: action.show,
      };

    case actionTypes.ADD_DRAGON_PAY_ACCOUNT_FAILED:
    case actionTypes.OPEN_MODAL_ERROR:
      return {
        ...state,
        modalErrorMsg: action.modalErrorMsg,
        modalShowError: true,
      };
    case actionTypes.CLOSE_MODAL_ERROR:
      return {
        ...state,
        modalErrorMsg: null,
        modalShowError: false,
      };
    case actionTypes.OPEN_MODAL_CM_OPERATOR_LOGIN:
    case actionTypes.CLOSE_MODAL_CM_OPERATOR_LOGIN:
      return {
        ...state,
        modalShowOperatorLogin: action.show,
      };
    case actionTypes.OPEN_SELF_EXCLUSION_WARN_MODAL:
    case actionTypes.CLOSE_SELF_EXCLUSION_WARN_MODAL:
      return {
        ...state,
        modalShowSelfExclusionWarning: action.show,
      };
    case actionTypes.OPEN_TRANSACTION_WARN_MODAL:
    case actionTypes.CLOSE_TRANSACTION_WARN_MODAL:
      return {
        ...state,
        modalShowTransactionWarning: action.show,
      };
    case actionTypes.OPEN_MODAL_BULKCREDITING:
    case actionTypes.CLOSE_MODAL_BULKCREDITING:
      return {
        ...state,
        modalShowBulkCrediting: action.show,
      };
    case actionTypes.VALIDATE_BULK_CSV_ERROR:
      return {
        ...state,
        bulkCreditValidateError: action.errors,
        modalShowBulkCreditError: true,
        modalShowBulkCrediting: false,
      };
    case actionTypes.VALIDATE_BULK_CSV_SUCCESS:
      return {
        ...state,
        bulkCreditValidateSuccess: action.success,
        modalShowBulkCreditSuccess: true,
        modalShowBulkCrediting: false,
      };
    case actionTypes.CLOSE_BULK_ERROR_MODAL:
      return {
        ...state,
        bulkCreditValidateError: [],
        modalShowBulkCreditError: action.value,
      };
    case actionTypes.CLOSE_BULK_SUCCESS_MODAL:
      return {
        ...state,
        bulkCreditValidateSuccess: [],
        isBulkCreditRequestLoading: false,
        isBulkCreditRequestSuccess: false,
        modalShowBulkCreditSuccess: action.value,
      };
    case actionTypes.REQUEST_BULK_CREDIT:
      return {
        ...state,
        isBulkCreditRequestLoading: true,
      };
    case actionTypes.REQUEST_BULK_CREDIT_SUCCESS:
      return {
        ...state,
        bulkCredits: action.bulkCredits,
        isBulkCreditRequestLoading: false,
        isBulkCreditRequestSuccess: true,
      };
    case actionTypes.FETCH_BRANDS:
      return {
        ...state,
        isFetchingBrandsError: false,
        isFetchingBrandsLoading: true,
        isFetchingBrandsSuccess: false,
      };
    case actionTypes.FETCH_BRANDS_ERROR:
      return {
        ...state,
        isFetchingBrandsError: true,
        isFetchingBrandsLoading: false,
        isFetchingBrandsSuccess: false,
      };
    case actionTypes.FETCH_BRANDS_SUCCESS:
      let brandId;
      if (action.brandId == "0") {
        brandId = "1";
      } else {
        brandId = action.brandId;
      }

      return {
        ...state,
        brands: action.brands,
        isFetchingBrandsError: false,
        isFetchingBrandsLoading: false,
        isFetchingBrandsSuccess: true,
        selectedBrand: brandId,
      };
    case actionTypes.CHANGE_BRAND:
      return {
        ...state,
        selectedBrand: action.id,
      };
    case actionTypes.REQUEST_BULK_CREDIT_ERROR:
      return {
        ...state,
        bulkCredits: [],
        isBulkCreditRequestLoading: false,
        isBulkCreditRequestSuccess: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    default:
      return { ...state };
  }
};

export default customerManagerModal;
