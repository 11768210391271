import { constants as marketConstants } from "../EventMarkets/constants";
import pathConstants from "../Path/constants";

import constants from "./constants";

const initialState = {
  defaultMarketId: null,
  event: null,
  eventPathId: "",
  eventPlayersMap: {},
  eventStatus: null,

  eventTemplateDefaultSettings: {},
  eventTemplateId: null,

  isCreatingNewEvent: false,
  isCreatingNewEventFailed: false,

  isDeletingEvent: false,
  isDeletingEventFailed: false,

  isFetchingEventDefaultTemplate: false,
  isFetchingEventDefaultTemplateFailed: false,

  isFetchingPlayersA: false,
  isFetchingPlayersAFailed: false,

  isFetchingPlayersB: false,
  isFetchingPlayersBFailed: false,

  isFetchingSelectedOpponents: false,

  isFetchingSelectedOpponentsFailed: false,
  isFetchingTemplateSettings: false,
  isUpdatingEvent: false,
  isUpdatingEventFailed: false,
  mode: "TEAM",
  newSelectedOpponents: [],
  opponents: [],
  playersA: [],
  playersB: [],
  selectedOpponents: [],
};

let eventPlayersMap;
const eventCreatorEvent = (state = initialState, action) => {
  switch (action.type) {
    case marketConstants.FETCH_MARKET:
      // always clear the opponents state when fetching market
      // so that when new market popup is opened, opponents data will be refetched...
      return {
        ...state,
        opponents: [],
      };
    case marketConstants.FETCH_MARKET_SUCCEEDED:
      eventPlayersMap = { ...state.eventPlayersMap };
      if (action.response.parentType === "RANKEVENT") {
        eventPlayersMap[action.response.eventId] = {
          ...eventPlayersMap[action.response.eventId],
          players: action.response.outcomes,
        };
      }
      if (state.event && state.event.id !== action.response.eventId) {
        // clear state.event if market's eventId is not the current event
        return {
          ...state,
          event: null,
          eventPlayersMap,
          opponents: [],
        };
      }

      return {
        ...state,
        eventPlayersMap,
        opponents: [],
      };
    case marketConstants.FETCH_MARKET_PLAYERS_SUCCEEDED:
      // save the players for this event.
      // action.response is an object with opponentAId and opponentBId keys.
      return {
        ...state,
        eventPlayersMap: {
          ...state.eventPlayersMap,
          [action.eventId]: {
            ...state.eventPlayersMap[action.eventId],
            players: action.response,
          },
        },
      };

    case constants.FETCH_EVENT:
      return {
        ...state,
        defaultMarketId: null,
        eventStatus: "LOADING",
        isCreatingNewEventFailed: false,
        isUpdatingEventFailed: false,
        newSelectedOpponents: [],
        opponents: [],
        selectedOpponents: [],
      };
    case constants.FETCH_EVENT_SUCCEEDED:
      eventPlayersMap = { ...state.eventPlayersMap };
      if (action.response.eventType === "GAMEEVENT") {
        eventPlayersMap[action.response.id] = {
          ...eventPlayersMap[action.response.id],
          opponents: [action.response.opponentAId, action.response.opponentBId],
        };
      }

      return {
        ...state,
        event: {
          ...action.response,
          opponentAId: action.response.americanFormat ? action.response.opponentBId : action.response.opponentAId,
          opponentBId: action.response.americanFormat ? action.response.opponentAId : action.response.opponentBId,
        },
        eventPlayersMap,
        eventStatus: null,
      };
    case constants.FETCH_EVENT_FAILED:
      return {
        ...state,
        eventStatus: "ERROR",
      };

    case constants.DELETE_EVENT:
    case constants.DELETE_EVENTS:
      return {
        ...state,
        isDeletingEvent: true,
        isDeletingEventFailed: false,
      };
    case constants.DELETE_EVENT_SUCCEEDED:
      return {
        ...state,
        isDeletingEvent: false,
      };
    case constants.DELETE_EVENT_FAILED:
      return {
        ...state,
        isDeletingEvent: false,
        isDeletingEventFailed: true,
      };

    case constants.FETCH_EVENT_OPPONENTS:
      return {
        ...state,
        eventTemplateId: null,
        isFetchingSelectedOpponents: true,
      };

    case constants.FETCH_EVENT_DEFAULT_TEMPLATE:
      return {
        ...state,
        isFetchingEventDefaultTemplate: true,
        isFetchingSelectedOpponentsFailed: false,
      };
    case constants.FETCH_EVENT_DEFAULT_TEMPLATE_FAILED:
      return {
        ...state,
        isFetchingEventDefaultTemplate: false,
        isFetchingSelectedOpponentsFailed: false,
      };
    case constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS:
      return {
        ...state,
        eventTemplateDefaultSettings: {},
        eventTemplateId: action.templateId,
        isFetchingTemplateSettings: true,
      };
    case constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS_SUCCEEDED:
      return {
        ...state,
        eventTemplateDefaultSettings: action.settings,
        isFetchingTemplateSettings: false,
      };
    case constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS_FAILED:
      return {
        ...state,
        isFetchingTemplateSettings: false,
      };
    case constants.RESET_EVENT_DEFAULT_TEMPLATE_SETTINGS:
      return {
        ...state,
        eventTemplateId: "",
      };
    case constants.FETCH_EVENT_OPPONENTS_SUCCEEDED:
      return {
        ...state,
        isFetchingEventDefaultTemplate: false,
        isFetchingSelectedOpponents: false,
        isFetchingSelectedOpponentsFailed: false,
        opponents: action.response,
        ...(action.eventTemplateId && {
          eventTemplateId: action.eventTemplateId,
        }),
      };
    case constants.CREATE_EVENT:
      return {
        ...state,
        isCreatingNewEvent: true,
        isCreatingNewEventFailed: false,
      };
    case constants.CREATE_EVENT_SUCCEEDED:
      return {
        ...state,
        event: action.response,
        isCreatingNewEvent: false,
        newSelectedOpponents: [],
      };
    case constants.CREATE_EVENT_FAILED:
      return {
        ...state,
        isCreatingNewEvent: false,
        isCreatingNewEventFailed: true,
      };

    case constants.UPDATE_EVENT:
      return {
        ...state,
        isUpdatingEvent: true,
        isUpdatingEventFailed: false,
        newSelectedOpponents: [],
      };
    case constants.UPDATE_EVENT_SUCCEEDED:
      return {
        ...state,
        event: action.response,
        isUpdatingEvent: false,
      };
    case constants.UPDATE_EVENT_FAILED:
      return {
        ...state,
        isUpdatingEvent: false,
        isUpdatingEventFailed: true,
      };

    case constants.FETCH_EVENT_DEFAULT_MARKET:
      return {
        ...state,
        isFetchingSelectedOpponents: true,
        isFetchingSelectedOpponentsFailed: false,
      };
    case constants.FETCH_EVENT_DEFAULT_MARKET_SUCCEEDED:
      return {
        ...state,
        defaultMarketId: action.response.id,
      };
    case constants.FETCH_EVENT_DEFAULT_MARKET_FAILED:
      return {
        ...state,
        isFetchingSelectedOpponents: false,
        isFetchingSelectedOpponentsFailed: true,
      };

    case constants.FETCH_EVENT_SELECTED_OPPONENTS:
      return {
        ...state,
      };
    case constants.FETCH_EVENT_SELECTED_OPPONENTS_SUCCEEDED:
      return {
        ...state,
        eventPlayersMap: {
          ...state.eventPlayersMap,
          [action.eventId]: {
            ...state.eventPlayersMap[action.eventId],
            players: action.response,
          },
        },
        isFetchingSelectedOpponents: false,
        selectedOpponents: action.response,
      };
    case constants.FETCH_EVENT_SELECTED_OPPONENTS_FAILED:
      return {
        ...state,
        isFetchingSelectedOpponents: false,
        isFetchingSelectedOpponentsFailed: true,
      };

    case pathConstants.FETCH_EVENT_PATH_DETAILS:
      return {
        ...state,
        newSelectedOpponents: [],
        selectedOpponents: [],
      };
    case constants.SET_NEW_SELECTED_OPPONENTS:
      return {
        ...state,
        newSelectedOpponents: action.opponents,
      };
    case constants.CLEAR_NEW_SELECTED_OPPONENTS:
      return {
        ...state,
        newSelectedOpponents: [],
      };

    case constants.FETCH_PLAYERS_OF_OPPONENTA:
      return {
        ...state,
        isFetchingPlayersA: true,
        isFetchingPlayersAFailed: false,
      };
    case constants.FETCH_PLAYERS_OF_OPPONENTA_SUCCEEDED:
      return {
        ...state,
        isFetchingPlayersA: false,
        playersA: action.response,
      };
    case constants.FETCH_PLAYERS_OF_OPPONENTA_FAILED:
      return {
        ...state,
        isFetchingPlayersA: false,
        isFetchingPlayersAFailed: true,
        playersA: [],
      };

    case constants.FETCH_PLAYERS_OF_OPPONENTB:
      return {
        ...state,
        isFetchingPlayersB: true,
      };
    case constants.FETCH_PLAYERS_OF_OPPONENTB_SUCCEEDED:
      return {
        ...state,
        isFetchingPlayersB: false,
        playersB: action.response,
      };
    case constants.FETCH_PLAYERS_OF_OPPONENTB_FAILED:
      return {
        ...state,
        isFetchingPlayersB: false,
        isFetchingPlayersBFailed: true,
        playersB: [],
      };

    case constants.CLEAR_PLAYERS_OF_OPPONENTS_A_B:
      return {
        ...state,
        playersA: [],
        playersB: [],
      };
    case constants.CHANGE_PLAY_MODE:
      const { mode } = action;

      return {
        ...state,
        mode,
      };
    case constants.SET_EVENT_PATH:
      return {
        ...state,
        eventPathId: action.eventPathId,
      };
    default:
      return { ...state };
  }
};

export default eventCreatorEvent;
