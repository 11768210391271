import { createSelector } from "@reduxjs/toolkit";

export const getAuthSelector = createSelector(
  (state) => state.auth,
  (auth) => auth || {},
);

export const getAuthAccountId = createSelector(
  (state) => state.auth?.accountId,
  (accountId) => accountId,
);

export const getAuthError = createSelector(
  (state) => state.auth?.error,
  (error) => error,
);

export const getAuthLanguage = createSelector(
  (state) => state.auth?.language,
  (language) => language,
);

export const getAuthLoading = createSelector(
  (state) => state.auth?.loading,
  (loading) => loading,
);

export const getAuthLoggedIn = createSelector(
  (state) => state.auth?.loggedIn,
  (loggedIn) => loggedIn,
);

export const getAuthRememberedUsername = createSelector(
  (state) => state.auth?.rememberedUsername,
  (rememberedUsername) => rememberedUsername,
);

export const getAuthToken = createSelector(
  (state) => state.auth?.authToken,
  (authToken) => authToken,
);

export const getAuthUsername = createSelector(
  (state) => state.auth?.username,
  (username) => username,
);
