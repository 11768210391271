import { takeLatest, put, call } from "redux-saga/effects";

import constants, { paymentType } from "./constants";
import { setGridData, filterGrid } from "./Grid/actions";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchPayments(action) {
  const requestPayload = {
    params: action.params,
    type: action.paymentType || paymentType.WITHDRAWALS,
  };

  const { response, xhr } = yield call(API.fetchPayment, requestPayload);
  if (response) {
    yield put({ response, type: constants.FETCH_PAYMENTS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_PAYMENTS_FAILED });
  }
}

function* fetchApprovalCandidates(action) {
  const { response, xhr } = yield call(API.fetchApprovalCandidates, action.payload);
  if (response) {
    yield put({
      approvalCandidates: response,
      type: constants.FETCH_APPROVAL_CANDIDATES_SUCCEEDED,
    });
  } else {
    yield put({ type: constants.FETCH_APPROVAL_CANDIDATES_FAILED });
  }
}

function* fetchPreferences({ id }) {
  const { response, xhr } = yield call(API.fetchUserPreferences, id);
  if (response) {
    yield put({ preferences: response, type: constants.RECEIVE_PREFERENCES });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* updatePreferences({ preferences: details }) {
  const { response, xhr } = yield call(API.updateUserPreferences, details);
  if (response) {
    yield put({
      preferences: details.preferences,
      type: constants.RECEIVE_PREFERENCES,
    });
    yield put({ type: constants.TOGGLE_PREFERENCES });
    toastr.add({ message: i18n.t("Payment.Preferences Updated!") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* fetchNotes({ id }) {
  const { response, xhr } = yield call(API.fetchUserNotes, id);
  if (response) {
    yield put({ notes: response, type: constants.RECEIVE_NOTES });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* addNote({ data, note, tabIndex }) {
  const { response, xhr } = yield call(API.saveNote, note);
  if (response) {
    yield put({ note: response, type: constants.RECEIVE_NOTE });
    const updatedData = data.map((item) => {
      if (item.payRecId === note.payRecId) {
        return {
          ...item,
          notes: note.note,
        };
      }

      return item;
    });
    if (tabIndex === 0) {
      yield put({ deposits: updatedData, type: constants.RECEIVE_CUSTOM_SEARCH_DEPOSIT });
    } else {
      yield put({ type: constants.RECEIVE_CUSTOM_SEARCH_WITHDRAWALS, withdrawals: updatedData });
    }
    yield put(setGridData(updatedData));
    toastr.add({ message: i18n.t("Payment.Note Added!") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* searchCustom({ params, tabIndex }) {
  const { response, xhr } = yield call(API.customSearch, params, tabIndex);
  if (response) {
    if (tabIndex === 0) {
      yield put({ deposits: response, type: constants.RECEIVE_CUSTOM_SEARCH_DEPOSIT });
    } else {
      yield put({ type: constants.RECEIVE_CUSTOM_SEARCH_WITHDRAWALS, withdrawals: response });
    }
    yield put({ type: constants.FETCH_CUSTOM_SEARCH_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_CUSTOM_SEARCH_FAILED });
    toastr.add({ message: JSON.parse(xhr.response).exception.message, type: "ERROR" });
  }
}

function* fetchDepositApprovalCandidates(action) {
  const { response, xhr } = yield call(API.fetchDepositApprovalCandidates, action.payload);
  if (response) {
    yield put({
      app: action.payload.app,
      deposits: response,
      type: constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES_SUCCEEDED,
    });
  } else {
    yield put({ type: constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES_FAILED });
  }
}

function* updateDeposits(action) {
  const { response, xhr } = yield call(API.updateDeposits, { paymentHandlerData: action.formData });
  if (response) {
    yield put({ data: action.formData, type: constants.UPDATE_DEPOSITS_SUCCEEDED });
    yield put({ type: constants.RESET_DEPOSIT_UPDATE_DATA });
    yield put({ payload: action.query, type: constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES });
    toastr.add({
      message: `${i18n.t(
        `Payment.Successfully updated ${action.query.app === "withdrawals" ? "withdrawal" : "deposit"} transaction`,
      )}`,
    });
  } else {
    const is400 = xhr.status === 400;
    const msg = is400 ? JSON.parse(xhr.response) : parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `${is400 ? msg.message : msg}`, type: "ERROR" });
    yield put({ type: constants.UPDATE_DEPOSITS_FAILED });
  }
}

function* updateWithdrawals(action) {
  const { response, xhr } = yield call(API.updateWithdrawals, { paymentHandlerData: action.formData });
  if (response) {
    yield put({ data: action.formData, type: constants.UPDATE_WITHDRAWALS_SUCCEEDED });
    yield put({ type: constants.RESET_WITHDRAWAL_UPDATE_DATA });
    yield put({ payload: action.query, type: constants.FETCH_APPROVAL_CANDIDATES });
    toastr.add({ message: i18n.t("Payment.Successfully updated withdrawal transaction") });
  } else {
    const is400 = xhr.status === 400;
    const msg = is400 ? JSON.parse(xhr.response) : parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `${is400 ? msg.message : msg}`, type: "ERROR" });
    yield put({ type: constants.UPDATE_WITHDRAWALS_FAILED });
    // yield put({ type: constants.FETCH_APPROVAL_CANDIDATES })
  }
}

function* fetchPaymentStatusForFilter(action) {
  const { response, xhr } = yield call(API.fetchPaymentStatusForFilter);
  if (response) {
    yield put({ response, type: constants.FETCH_PAYMENT_STATUS_FOR_FILTER_SUCCEEDED });
    yield put({ response, type: constants.SET_DFAULT_SELECTED_STATUS });
  } else {
  }
}

function* fetchPaymentStatusList(action) {
  const { response, xhr } = yield call(API.fetchPaymentStatusList);
  if (response) {
    yield put({ response, type: constants.FETCH_STATUS_LIST });
  } else {
  }
}

function* fetchFilteredData(action) {
  const { response, xhr } = yield call(API.fetchFilteredData, action);
  if (response) {
    // yield put({type: constants.FETCH_FILTERED_DATA_SUCCESS, response: {data: response, tab: action.app} })
    yield put(setGridData(response));

    let ui_filter_searchBy = "";
    let ui_filter_criteria = "";
    if (action.filters_data && action.filters_data.criteria) {
      ui_filter_criteria = action.filters_data.criteria;
    }
    if (action.filters_data && action.filters_data.searchBy) {
      ui_filter_searchBy = action.filters_data.searchBy;
    }
    if (ui_filter_criteria != "") {
      yield put(filterGrid(ui_filter_criteria, ui_filter_searchBy));
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `${msg}`, type: "ERROR" });
  }
}

export default function* paymentHandlerSaga() {
  yield takeLatest(constants.FETCH_PAYMENTS, fetchPayments);
  yield takeLatest(constants.FETCH_APPROVAL_CANDIDATES, fetchApprovalCandidates);
  yield takeLatest(constants.FETCH_PREFERENCES, fetchPreferences);
  yield takeLatest(constants.UPDATE_PREFERENCES, updatePreferences);
  yield takeLatest(constants.FETCH_NOTES, fetchNotes);
  yield takeLatest(constants.ADD_NOTE, addNote);
  yield takeLatest(constants.FETCH_CUSTOM_SEARCH, searchCustom);
  yield takeLatest(constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES, fetchDepositApprovalCandidates);
  yield takeLatest(constants.UPDATE_DEPOSITS, updateDeposits);
  yield takeLatest(constants.UPDATE_WITHDRAWALS, updateWithdrawals);
  yield takeLatest(constants.FETCH_PAYMENT_STATUS_FOR_FILTER, fetchPaymentStatusForFilter);
  yield takeLatest(constants.FETCH_STATUS, fetchPaymentStatusList);
  yield takeLatest(constants.FETCH_FILTERED_DATA, fetchFilteredData);
}
