import _ from "lodash";

const removeOriginId = (lines) => {
  _.map(lines, (item, index) => {
    delete lines[index]["originIds"];
  });

  return lines;
};

export default removeOriginId;
