const constants = {
  FETCH_LINES: "UTL::FETCH_LINES",
  FETCH_LINES_FAILED: "UTL::FETCH_LINES_FAILED",
  FETCH_LINES_SUCCEEDED: "UTL::FETCH_LINES_SUCCEEDED",
  ON_ADD_NEW_LINE: "UTL::ON_ADD_NEW_LINE",
  ON_LINE_ACTIVE: "UTL::ON_LINE_ACTIVE",
  ON_MOVE_DOWN: "UTL::ON_MOVE_DOWN",
  OPEN_ADD_LINE_POPUP: "UTL::OPEN_ADD_LINE_POPUP",
  SAVE_LINE_MANAGEMENT: "UTL::SAVE_LINE_MANAGEMENT",
  SAVE_LINE_MANAGEMENT_SUCCEEDED: "UTL::SAVE_LINE_MANAGEMENT_SUCCEEDED",
  SELECT_LINE: "UTL::SELECT_LINE",
  UNDO_LINE_MANAGEMENT: "UTL::UNDO_LINE_MANAGEMENT",
};

export default constants;
