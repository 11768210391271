import qs from "query-string";
import { push, replace } from "react-router-redux";
import { reset } from "redux-form";
import { put, call, select, takeLatest } from "redux-saga/effects";

import { removeErrorMsg } from "../../../actions/user";

import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCEEDED,
  UPDATE_PASSWORD_FAILED,
  RESET_NEW_OPERATOR_PASSWORD,
  RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED,
  RESET_NEW_OPERATOR_PASSWORD_FAILED,
} from "./actions";
import * as API from "./services";

import { closeModal, openModal } from "actions/modal";
import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";
import { makeIterable } from "utils";

function* updateOperatorPassword(action) {
  const { response, error } = yield call(API.updateOperatorPassword, action.operatorid, action.formData);
  if (response) {
    yield put({ response, type: UPDATE_PASSWORD_SUCCEEDED });
    yield put(closeModal("changePasswordModal"));
    yield put(reset("ChangePasswordModal"));
    toastr.add({ message: `${i18n.t("Operator.Operator password successfully updated")}.` });
  } else {
    yield put({ errorMessage: "Error", type: UPDATE_PASSWORD_FAILED });
    toastr.add({ message: `${i18n.t("Operator.Failed to update operator password")}. ${error}`, type: "ERROR" });
  }
}

function* resetNewOperatorPassword(action) {
  const { response, error } = yield call(API.resetNewOperatorPassword, action.operatorid, action.formData);
  if (response) {
    yield put({ response, type: RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED });
    yield put(removeErrorMsg());
    yield put(closeModal("changePasswordModal"));
    toastr.add({ message: `${i18n.t("Operator.Password successfully updated")}.` });
    yield put(reset("ChangePasswordModal"));
  } else {
    yield put({ errorMessage: "Error", type: RESET_NEW_OPERATOR_PASSWORD_FAILED });
    toastr.add({ message: `${i18n.t("Operator.Failed to update operator password")}. ${error}`, type: "ERROR" });
  }
}

export default function* changeOperatorPasswordSaga() {
  yield takeLatest(UPDATE_PASSWORD, updateOperatorPassword),
    yield takeLatest(RESET_NEW_OPERATOR_PASSWORD, resetNewOperatorPassword);
}
