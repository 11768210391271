import { takeLatest, takeEvery, delay, select, call, put } from "redux-saga/effects";

import actionTypes from "../constants";
import * as notifyApi from "../services/notificationApi";

function* getNotificationAlerts(action) {
  const accountId = yield select((state) => state.user?.userInfo?.id);
  if (accountId) {
    const { response, xhr } = yield call(notifyApi.getNotifyAlerts, accountId);
    const oldAlerts = yield select((state) => state.notifications.checkIsUpdate);
    if (response) {
      if (oldAlerts.totalUnread === undefined || oldAlerts.totalUnread !== response.totalUnread) {
        yield put({ response, type: actionTypes.GET_NOTIFY_ALERTS_SUCCESS });
      }
    }
  }
}

function* claimNotification(action) {
  const { response, xhr } = yield call(notifyApi.claimItemApi, action.data);
  yield put({ data: action.data, type: actionTypes.READ_ITEM });
  const userName = yield select((state) => state.user.userInfo.firstName);
  if (response) {
    yield put({ data: { ...action.data, claimedByUsername: userName }, type: actionTypes.CLAIM_ITME_SUCCESS });
  }
}

function* readNotification(action) {
  const { response, xhr } = yield call(notifyApi.readItemApi, action.data);
  if (response) {
    yield put({ data: action.data, type: actionTypes.READ_ITEM_SUCCESS });
  }
}

function* readAllNotification(action) {
  const { response, xhr } = yield call(notifyApi.readAllItemApi, action.accountId);
  if (response) {
    yield put({ type: actionTypes.READ_ALL_SUCCESS });
  }
}

export default function* notificationSaga() {
  yield takeEvery(actionTypes.GET_NOTIFY_ALERTS, getNotificationAlerts);
  yield takeEvery(actionTypes.CLAIM_ITME, claimNotification);
  yield takeEvery(actionTypes.READ_ITEM, readNotification);
  yield takeEvery(actionTypes.READ_ALL, readAllNotification);
}
