import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function onTextSearch(action) {
  const url = `/bo/settings/allsettings`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onGetAllSettings(action) {
  const url = `/bo/settings/all`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
