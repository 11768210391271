import { put, call, fork, select } from "redux-saga/effects";

import opponentsSaga from "../containers/EventCreator/Pages/Opponents/sagas/opponentsSaga";
import playersSaga from "../containers/EventCreator/Pages/Opponents/sagas/playersSaga";
import reportsSaga from "../containersNew/ReportsManager/sagas/reportsSagas";

import appsSaga from "./apps";
import constantsSaga from "./constants";
import customerManagerAccountSaga from "./customerManager/account";
import customerManagerChatSaga from "./customerManager/chat";
import customerManagerCommentsSaga from "./customerManager/comments";
import customerManagerContantsSaga from "./customerManager/constants";
import customerManagerSaga from "./customerManager/index";
import customerManagerPerformanceSaga from "./customerManager/performance";
import customerManagerSettingsSaga from "./customerManager/settings";
import editMarketSaga from "./editMarket";
import eventPathTreeSaga from "./eventPathTree";
import marketSaga from "./market";
import marketFeedInfoSaga from "./marketFeedInfo";
import marketStateDetailsSaga from "./marketStateDetails";
import notification from "./notificationSaga";
import outcomeSaga from "./outcome";
import outcomeWagerLimitsSaga from "./outcomeWagerLimits";
import resultsViewerDataSaga from "./resultsViewerData";
import riskAnalysisSaga from "./riskAnalysis";
import riskDataSaga from "./riskData";
import dailyStatisticsSaga from "./riskManager/dailyStatistics";
import globalAccumulatorRisksSaga from "./riskManager/globalAccumulatorRisks";
import lookupPublishModesSaga from "./riskManager/lookupPublishModes";
import riskTransactionSaga from "./riskTransaction";
import startupSaga from "./startup";
import userSaga from "./user";
import userRiskPreferencesSaga from "./userRiskPreferences";
// import eventCreatorPathTreeSaga from './eventCreator/eventCreatorPathTree';
// import eventPathTagsSaga from 'eventCreatorEventPathSagas/eventPathTags';
// import eventPathDetailsSaga from 'eventCreatorEventPathSagas/eventPathDetails';

import bonusManagerCampaignsSaga from "containersV2/BonusManager/Main/Campaigns/sagas";
import bonusManagerPromotionalGroupsSaga from "containersV2/BonusManager/Main/PromotionalGroups/sagas";
import bonusManagerPromotionsSaga from "containersV2/BonusManager/Main/Promotions/sagas";
import bonusManagerTermsSaga from "containersV2/BonusManager/Main/TermsAndConditions/sagas";
import bonusManagerTreeSaga from "containersV2/BonusManager/Sidebar/sagas";
import combinationRiskManagerSaga from "containersV2/CombiRiskManager/sagas";
import customerChatSaga from "containersV2/CustomerChat/sagas";
import eventCreatorAppSaga from "containersV2/EventCreator/App/sagas";
import betRestrictionsSaga from "containersV2/EventCreator/BetRestrictions/sagas";
import eventCreatorApplicableTemplateSaga from "containersV2/EventCreator/BulkUpdate/sagas";
import feedHistorySaga from "containersV2/EventCreator/Event/FeedHistory/sagas";
import eventSaga from "containersV2/EventCreator/Event/sagas";
import eventMarketsSaga from "containersV2/EventCreator/EventMarkets/sagas";
import gameResultsSaga from "containersV2/EventCreator/GameResults/sagas";
import eventCreatorPathTreeSaga from "containersV2/EventCreator/Path/sagas";
import instantAction from "containersV2/InstantAction/sagas";
import addJackPotSaga from "containersV2/JackpotBetManager/AddJackpotBet/Components/EventTreeCreator/sagas";
import newJackPotBetData from "containersV2/JackpotBetManager/AddJackpotBet/sagas";
import jackpotBetsaga from "containersV2/JackpotBetManager/App/sagas";
import winnerjackPotSaga from "containersV2/JackpotBetManager/WinnerDialogBox/sagas";
import operatorManagerSaga from "containersV2/OperatorManager/App/sagas";
import changePasswordSaga from "containersV2/OperatorManager/OperatorForm/sagas";
import operatorListSaga from "containersV2/OperatorManager/OperatorList/sagas";
import permissionPanelSaga from "containersV2/OperatorManager/PermissionPanel/sagas";
import paymentHandlerSaga from "containersV2/PaymentHandler/sagas";
import SettingsBrowser from "containersV2/SettingsBrowser/App/sagas";
import sportsTreeSaga from "containersV2/SportsTree/sagas";
import TranslationManager from "containersV2/TranslationManager/App/sagas";
import utilitiesSaga from "containersV2/Utilities/App/sagas";
import fetchChannelUtility from "containersV2/Utilities/Main/ChannelUtility/sagas";
import utilitiesEmergencyLockdown from "containersV2/Utilities/Main/EmergencyLockDown/sagas";
import utilitiesExchangeRate from "containersV2/Utilities/Main/ExchangeRate/sagas";
import utilitiesHurdleManagement from "containersV2/Utilities/Main/HurdleManagementPage/sagas";
import utilitiesLineManagement from "containersV2/Utilities/Main/LineManagement/sagas";
import utilitiesMarketControlSaga from "containersV2/Utilities/Main/MarketControl/sagas";
import utilitiesMarketTypeConfig from "containersV2/Utilities/Main/MarketTypeConfig/sagas";
import utilitiesMarketTypeEnablement from "containersV2/Utilities/Main/MarketTypeEnablement/sagas";
import originValidationMappingSaga from "containersV2/Utilities/Main/OriginValidationMapping/sagas";
import utilitiesRiskDefaultManagement from "containersV2/Utilities/Main/RiskDefaultManagement/sagas";
import utilitiesWagerLimitSaga from "containersV2/Utilities/Main/WagerLimit/sagas";
import CombLiabilityLimits from "containersV2/WagerLimits/App/Components/CombLiabilityIndicators/sagas";
import WagerLimitsLiabilityIndicators from "containersV2/WagerLimits/App/Components/LiabilityIndicators/sagas";
import WagerLimitsPlayerProfileGroup from "containersV2/WagerLimits/App/Components/PlayerProfileGroups/sagas";
import WagerLimitsStakeDistributation from "containersV2/WagerLimits/App/Components/StakeDistribution/sagas";
import WagerLimits from "containersV2/WagerLimits/App/sagas";

// main saga generators
export function* sagas() {
  yield fork(startupSaga);
  yield fork(eventPathTreeSaga);
  yield fork(riskDataSaga);
  yield fork(riskAnalysisSaga);
  yield fork(resultsViewerDataSaga);
  yield fork(marketSaga);
  yield fork(outcomeSaga);
  yield fork(outcomeWagerLimitsSaga);
  yield fork(marketStateDetailsSaga);
  yield fork(marketFeedInfoSaga);
  yield fork(globalAccumulatorRisksSaga);
  yield fork(dailyStatisticsSaga);
  yield fork(lookupPublishModesSaga);
  yield fork(customerManagerSaga);
  yield fork(customerManagerChatSaga);
  yield fork(customerManagerAccountSaga);
  yield fork(customerManagerContantsSaga);
  yield fork(customerManagerCommentsSaga);
  yield fork(customerManagerPerformanceSaga);
  yield fork(customerManagerSettingsSaga);
  yield fork(editMarketSaga);
  yield fork(riskTransactionSaga);
  yield fork(userSaga);
  yield fork(appsSaga);
  yield fork(userRiskPreferencesSaga);
  yield fork(utilitiesSaga);
  yield fork(utilitiesWagerLimitSaga);
  yield fork(utilitiesHurdleManagement);
  yield fork(utilitiesExchangeRate);
  yield fork(utilitiesLineManagement);
  yield fork(originValidationMappingSaga);
  yield fork(utilitiesRiskDefaultManagement);
  yield fork(utilitiesMarketControlSaga);
  yield fork(utilitiesEmergencyLockdown);
  yield fork(utilitiesMarketTypeEnablement);
  yield fork(utilitiesMarketTypeConfig);

  yield fork(fetchChannelUtility);
  // yield fork(eventCreatorPathTreeSaga);
  // yield fork(eventPathTagsSaga);
  // yield fork(eventPathDetailsSaga);
  yield fork(opponentsSaga);
  yield fork(playersSaga);

  yield fork(eventSaga);
  yield fork(jackpotBetsaga);
  yield fork(newJackPotBetData);
  yield fork(eventMarketsSaga);
  yield fork(eventCreatorPathTreeSaga);
  yield fork(betRestrictionsSaga);
  yield fork(sportsTreeSaga);
  yield fork(eventCreatorAppSaga);

  yield fork(feedHistorySaga);
  yield fork(gameResultsSaga);

  yield fork(addJackPotSaga);
  yield fork(instantAction);

  yield fork(winnerjackPotSaga);
  yield fork(operatorManagerSaga);
  yield fork(permissionPanelSaga);

  yield fork(constantsSaga);
  yield fork(reportsSaga);
  yield fork(customerChatSaga);
  yield fork(operatorListSaga);
  yield fork(changePasswordSaga);
  yield fork(eventCreatorApplicableTemplateSaga);
  yield fork(paymentHandlerSaga);
  yield fork(combinationRiskManagerSaga);
  yield fork(bonusManagerCampaignsSaga);
  yield fork(bonusManagerTreeSaga);
  yield fork(bonusManagerPromotionsSaga);
  yield fork(bonusManagerTermsSaga);
  yield fork(bonusManagerPromotionalGroupsSaga);
  yield fork(TranslationManager);
  yield fork(SettingsBrowser);
  yield fork(WagerLimits);
  yield fork(WagerLimitsStakeDistributation);
  yield fork(WagerLimitsLiabilityIndicators);
  yield fork(CombLiabilityLimits);
  yield fork(WagerLimitsPlayerProfileGroup);

  yield fork(notification);
}
