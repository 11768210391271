const filterTypes = {
  BET_STATUS: {
    ALL_BET_STATUS: { desc: "All Bet Status", value: "0" },
    WITH_BET_STATUS: { desc: "With Bets", value: "1" },
  },
  BOOK_TYPE: {
    ALL: { desc: "All Books", value: "ALL" },
    LIVE: { desc: "Live Book", value: "LIVE" },
    PREMATCH: { desc: "Prematch Book", value: "PREMATCH" },
  },
  DATES: {
    ALL_DATES: "All Dates",
    CUSTOM: "Custom",
    FUTURE: "Future (After Tomorrow)",
    NEXT_7_DAYS: "Next 7 Days",
    TODAY: "Today",
    TODAY_TO_FUTURE: "Today to Future",
    TOMORROW: "Tomorrow",
    YESTERDAY: "Yesterday",
  },
  JACKPOT_BETS_FILTER: {
    NEXT_30_DAYS: "Next 30 Days",
  },
  JACKPOT_BET_STATUS: {
    ACTIVE: { desc: "Active", value: "ACTIVE" },
    DISABLED: { desc: "Disabled", value: "DISABLED" },
    SETTLED: { desc: "Settled", value: "SETTLED" },
  },
  JACKPOT_BET_TYPES: {
    ALL: { desc: "All", value: -1 },
    JACKPOT_BETS: { desc: "Jackpot Bets", value: 1 },
    // PREDICTOR_BETS: { desc: 'Predictor Bets', value: 2 },
  },
  LIABILITY_TYPE: {
    EXPOSURE: { desc: "Exposure", value: "EXPOSURE_ALL" },
    EXPOSURE_RUN_UP: { desc: "Exposure Run Up", value: "EXPOSURE_RU" },
    LIABILITY: { desc: "Liability", value: "LIABILITY" },
    LIABITY_RUN_UP: { desc: "Liability Run Up", value: "LIABILITY_WITH_RUNUP" },
    PAYOUT: { desc: "Payout", value: "PAYOUT" },
  },
  MARKETS: {
    ALL_MARKETS: "All Markets",
  },
  OTHER_DATES: {
    EVENT_2HRS_AGO: "Event started less than 2 hours ago",

    EVENT_4HRS_AGO: "Event started less than 4 hours ago",
    // do not convert into array and use as a whole, only use the props separately
    LAST_7_DAYS: "Last 7 Days",
    LAST_MONTH: "Last Month",
  },
  OVERRIDES: {
    ALPHABET: { desc: "Alphabetical", value: "ALPHABET" },
    NONE: { desc: "No Override", value: "NONE" },
    PRICE: { desc: "Price Ascending", value: "PRICE" },
    ROTATION: { desc: "Rotation/Number", value: "ROTATION" },
  },
  PAST_DATES: {
    ALL_DATES: "All",
    CUSTOM: "Custom",
    THIS_MONTH: "This Month",
    THIS_QUARTER: "This Quarter",
    THIS_WEEK: "This Week",
    THIS_YEAR: "This Year",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
  },
  PERIODS: {
    ALL_PERIODS: "All Periods",
  },
  PRICE_TYPE: {
    PRICED: { desc: "Priced", value: "PRICED" },
    PRICED_AND_UNPRICED: { desc: "Priced and Unpriced", value: "ALL" },
    UNPRICED: { desc: "Unpriced", value: "UNPRICED" },
  },
  RESULTS_VIEWER_DATES: {
    ALL_DATES: "All Dates",
    CUSTOM: "Custom",
    EVENT_2HRS_AGO: "Event started less than 2 hours ago",
    EVENT_4HRS_AGO: "Event started less than 4 hours ago",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
  },
  REVENUE_WITHDRAWLS: {
    STATUS: [
      { desc: "Pending Approval", id: 0, value: "pending" },
      { desc: "Approved Withdrawls", id: 0, value: "approved" },
      { desc: "Rejected Withdrawls", id: 0, value: "rejected" },
    ],
  },
  SORTS: {
    ASC: { desc: "Ascending", value: "ASC" },
    DEFAULT: { desc: "Default", value: "DEF" },
    DESC: { desc: "Descending", value: "DESC" },
  },
  SPORTS_TREE_SEARCH_OPTIONS: {
    ANY: { desc: "Search by Description", value: "ANY" },
    EVENT_ID: { desc: "Search by Event ID", value: "EVENT_ID" },
    FEED_ID: { desc: "Search by Feed ID", value: "FEED_ID" },
  },
  STAKE_TYPES: {
    ALL_BETS: { desc: "All Bets", value: "ALL_BETS" },
    SINGLES: { desc: "Singles", value: "SINGLES" },
  },
  STANDARD_MARKET_STATUSES: {
    CLOSED: { desc: "Closed", value: "3" },
    OPEN: { desc: "Open", value: "2" },
    RESULTED: { desc: "Resulted", value: "4" },
    SETTLED: { desc: "Settled", value: "5" },
    SUSPENDED: { desc: "Suspended", value: "1" },
  },
  STATUS: {
    ANY_STATUS: { desc: "Any Status", value: "1,2,3,4,5" },
    CLOSED: { desc: "Closed", value: "3" },
    CLOSED_RESULTED_AND_SETTLED: { desc: "Closed, Resulted and Settled", value: "3,4,5" },
    OPEN: { desc: "Open", value: "2" },
    OPEN_AND_SUSPENDED: { desc: "Open and Suspended", value: "1,2" },
    OPEN_SUSPENDED_AND_CLOSED: { desc: "Open, Suspended and Closed", value: "1,2,3" },
    RESULTED: { desc: "Resulted", value: "4" },
    SETTLED: { desc: "Settled", value: "5" },
    SUSPENDED: { desc: "Suspended", value: "1" },
  },
  TRANSACTION_DATES: {
    CUSTOM: "Custom",
    LAST_24: "Last 24H",
    LAST_48: "Last 48H",
    LAST_HOUR: "Last Hour",
    LAST_WEEK: "Last Week",
    THIS_MONTH: "This Month",
    THIS_WEEK: "This Week",
  },
  WINNER_DATES: {
    CUSTOM: "Custom",
    LAST_24: "Last 24H",
    LAST_48: "Last 48H",
    LAST_6_MONTH: "Last 6 Month",
    LAST_HOUR: "Last Hour",
    LAST_MONTH: "Last Month",
    LAST_WEEK: "Last Week",
    LAST_YEAR: "Last Year",
    THIS_MONTH: "This Month",
    THIS_WEEK: "This Week",
    THIS_YEAR: "This Year",
  },
  WIN_PLACE: {
    PLACE: { desc: "Place", value: "PLACE" },
    WIN: { desc: "Win", value: "WIN" },
  },
};

export default filterTypes;
