import { put, call, fork, select, takeLatest } from "redux-saga/effects";

import channelConfig from "../configs/channelConfig";
import actionTypes from "../constants";
import * as RiskDataService from "../services/riskData";
import { formatFilterDates } from "../utils";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchRiskData(action) {
  try {
    yield put({
      type: actionTypes.CLEAR_UNSAVED_OUTCOME_PRICE_CHANGES,
    });
    let params = yield select((state) => state.riskParameters);
    params = { ...params, ...formatFilterDates(params.date), ...action.params };
    if (params.marketStatusIds !== "") {
      var res = yield call(RiskDataService.fetchRiskData, params);
    } /* else{
      toastr.add({ message: `Please select a status`, type: 'ERROR' })
    } */
    const { response, xhr } = res;
    RiskDataService.filterEvents(params.market, params.period, params.eventSearchString);
    if (response.success) {
      yield put({
        hasUpdates: true,
        type: actionTypes.FETCH_RISK_DATA_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.FETCH_RISK_DATA_FAILED });
    }
  } catch (error) {
    yield put({ type: actionTypes.FETCH_RISK_DATA_FAILED });
  }
}

function* fetchChunkRiskData(action) {
  try {
    let params = yield select((state) => state.riskParameters);
    params = { ...params, ...formatFilterDates(params.date), code: action.key };
    const { response, xhr } = yield call(RiskDataService.fetchChunkRiskData, params);
    RiskDataService.filterEvents(params.market, params.period, params.eventSearchString);
    if (response.success) {
      yield put({
        key: action.key,
        type: actionTypes.FETCH_CHUNK_RISK_DATA_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.FETCH_CHUNK_RISK_DATA_FAILED });
    }
  } catch (error) {
    yield put({ type: actionTypes.FETCH_CHUNK_RISK_DATA_FAILED });
  }
}

function* updateRiskData(action) {
  try {
    let params = yield select((state) => state.riskParameters);
    params = { ...params, ...formatFilterDates(params.date) };
    if (params.marketStatusIds !== "") {
      var res = yield call(RiskDataService.updateRiskData, params);
    } /* else{
      toastr.add({ message: `Please select a status`, type: 'ERROR' })
    } */
    const { response, xhr } = res;
    if (response.hasUpdates) {
      // only filter events if there's an update
      RiskDataService.filterEvents(params.market, params.period, params.eventSearchString);
    }
    if (response.success) {
      yield put({
        hasUpdates: response.hasUpdates,
        type: actionTypes.UPDATE_RISK_DATA_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.UPDATE_RISK_DATA_FAILED });
    }
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_RISK_DATA_FAILED });
  }
}

function* filterRiskDataByMarket(action) {
  const params = yield select((state) => state.riskParameters);
  RiskDataService.filterEvents(action.market, params.period, params.eventSearchString);
  yield put({
    type: actionTypes.FETCH_RISK_DATA_SUCCEEDED,
  });
}

function* filterRiskDataByPeriod(action) {
  const params = yield select((state) => state.riskParameters);
  RiskDataService.filterEvents(params.market, action.period, params.eventSearchString);
  yield put({
    type: actionTypes.FETCH_RISK_DATA_SUCCEEDED,
  });
}

function* filterRiskDataByEventName() {
  const params = yield select((state) => state.riskParameters);
  RiskDataService.filterEvents(params.market, params.period, params.eventSearchString);
  yield put({
    type: actionTypes.FETCH_RISK_DATA_SUCCEEDED,
  });
}

function* requestUofs(action) {
  try {
    const { response, xhr } = yield call(RiskDataService.requestUofs, action.eventId);
    if (response) {
      toastr.add({ message: i18n.t("Risk.Request has been successfully sent") });
    } else {
      toastr.add({ message: i18n.t("Risk.Unable to send request"), type: "ERROR" });
    }
  } catch (e) {
    toastr.add({ message: i18n.t("Risk.Unable to send request"), type: "ERROR" });
  }
}

function* requestUofp(action) {
  try {
    const { response, xhr } = yield call(RiskDataService.requestUofp, action.eventId);
    if (response) {
      toastr.add({ message: i18n.t("Risk.Request has been successfully sent") });
    } else {
      toastr.add({ message: i18n.t("Risk.Unable to send request"), type: "ERROR" });
    }
  } catch (e) {
    toastr.add({ message: i18n.t("Risk.Unable to send request"), type: "ERROR" });
  }
}

export default function* riskDataSaga() {
  yield takeLatest(actionTypes.FETCH_RISK_DATA, fetchRiskData);
  yield takeLatest(actionTypes.FETCH_CHUNK_RISK_DATA, fetchChunkRiskData);
  yield takeLatest(actionTypes.UPDATE_RISK_DATA, updateRiskData);
  yield takeLatest(actionTypes.SET_RISK_FILTER_MARKET, filterRiskDataByMarket);
  yield takeLatest(actionTypes.SET_RISK_FILTER_PERIOD, filterRiskDataByPeriod);
  yield takeLatest(actionTypes.SEARCH_EVENTS, filterRiskDataByEventName);
  yield takeLatest(actionTypes.REQUEST_U_FOS, requestUofs);
  yield takeLatest(actionTypes.REQUEST_UFOP, requestUofp);
}
