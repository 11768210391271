import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

let changeMarketStatusXhr = null;
const fetchEventXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchEventDetails(eventId) {
  return new Promise((resolve, reject) => {
    const eventDetails = couponModel.getChunk(eventId);
    if (eventDetails) {
      resolve(eventDetails);
    } else {
      reject("Error");
    }
  })
    .then((response) => {
      return { response: response };
    })
    .catch((xhr) => ({ xhr }));
}

export function changeMarketStatus(marketIds, status) {
  return new Promise((resolve, reject) => {
    const url = `/bo/markets/${marketIds}`;
    const requestBody = {
      status,
    };

    if (changeMarketStatusXhr) {
      changeMarketStatusXhr.cancel("cancelled due to concurrent request");
    }

    changeMarketStatusXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, changeMarketStatusXhr);
      changeMarketStatusXhr = null;
      resolve(response);
    } catch (err) {
      changeMarketStatusXhr = null;
      reject(err);
    }
    changeMarketStatusXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function abandonMarkets(marketIds, voidReasonId, voidReasonNotes) {
  return new Promise((resolve, reject) => {
    const url = `/bo/markets/${marketIds}/abandon`;
    const requestBody = {
      voidReasonId,
      voidReasonNotes,
    };

    if (changeMarketStatusXhr) {
      changeMarketStatusXhr.cancel("cancelled due to concurrent request");
    }

    changeMarketStatusXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, changeMarketStatusXhr);
      changeMarketStatusXhr = null;
      resolve(response);
    } catch (err) {
      changeMarketStatusXhr = null;
      reject(err);
    }
    changeMarketStatusXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketPeriodDetails(eventIds) {
  const url = `/bo/markets?lineId=2&eventIds=${eventIds}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketCutOffAndAutoOpenDateTime(formData) {
  const url = `/bo/markets?lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, formData)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
