const constants = {
  ADD_PROMOTION: "BM::ADD_PROMOTION",
  ADD_PROMOTION_FAILED: "BM::ADD_PROMOTION_FAILED",
  ADD_PROMOTION_GROUP: "BM::ADD_PROMOTION_GROUP",

  ADD_PROMOTION_SUCCEEDED: "BM::ADD_PROMOTION_SUCCEEDED",
  CLEAR_PROMOTION_DATA: "BM::CLEAR_PROMOTION_DATA",
  ADD_PROMOTION_CRITERIA: "BM::ADD_PROMOTION_CRITERIA",

  COPY_PROMOTION: "BM::COPY_PROMOTION",
  COPY_PROMOTION_FAILED: "BM::COPY_PROMOTION_FAILED",
  COPY_PROMOTION_SUCCEEDED: "BM::COPY_PROMOTION_SUCCEEDED",

  DELETE_PROMOTION: "BM::DELETE_PROMOTION",
  DELETE_PROMOTION_CRITERIA: "BM::DELETE_PROMOTION_CRITERIA",
  ADD_PROMOTION_REWARD: "BM::ADD_PROMOTION_REWARD",

  DELETE_PROMOTION_FAILED: "BM::DELETE_PROMOTION_FAILED",
  DELETE_PROMOTION_GROUP: "BM::DELETE_PROMOTION_GROUP",
  DELETE_PROMOTION_REWARD: "BM::DELETE_PROMOTION_REWARD",

  DELETE_PROMOTION_SUCCEEDED: "BM::DELETE_PROMOTION_SUCCEEDED",
  FETCH_MARKET_TYPES: "BM::FETCH_MARKET_TYPES",
  FETCH_MARKET_TYPES_FAILED: "BM::FETCH_MARKET_TYPES_FAILED",

  FETCH_PROMOTIONAL_GROUP_WITH_BRANDID: "BM::FETCH_PROMOTIONAL_GROUP_WITH_BRANDID",
  FETCH_MARKET_TYPES_SUCCEEDED: "BM::FETCH_MARKET_TYPES_SUCCEEDED",
  FETCH_PROMOTIONS: "BM::FETCH_PROMOTIONS",

  FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_FAILED: "BM::FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_FAILED",
  FETCH_PROMOTIONS_FAILED: "BM::FETCH_PROMOTIONS_FAILED",
  FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_SUCCEEDED",

  FETCH_PROMOTIONS_SUCCEEDED: "BM::FETCH_PROMOTIONS_SUCCEEDED",

  FETCH_PROMOTION_CAMPAIGN: "BM::FETCH_PROMOTION_CAMPAIGN",
  FETCH_PROMOTION_CAMPAIGN_FAILED: "BM::FETCH_PROMOTION_CAMPAIGN_FAILED",
  FETCH_PROMOTION_CAMPAIGN_SUCCEEDED: "BM::FETCH_PROMOTION_CAMPAIGN_SUCCEEDED",

  FETCH_PROMOTION_DATA: "BM::FETCH_PROMOTION_DATA",
  FETCH_PROMOTION_DATA_FAILED: "BM::FETCH_PROMOTION_DATA_FAILED",
  FETCH_PROMOTION_DATA_SUCCEEDED: "BM::FETCH_PROMOTION_DATA_SUCCEEDED",

  FETCH_SPORT_EVENT_PATHS: "BM::FETCH_SPORT_EVENT_PATHS",
  FETCH_SPORT_EVENT_PATHS_FAILED: "BM::FETCH_SPORT_EVENT_PATHS_FAILED",
  FETCH_SPORT_EVENT_PATHS_SUCCEEDED: "BM::FETCH_SPORT_EVENT_PATHS_SUCCEEDED",
  RESET_PROMOTION: "BM::RESET_PROMOTION",
  SELECT_PROMOTION_CRITERIA: "BM::SELECT_PROMOTION_CRITERIA",

  SELECT_PROMOTION_REWARD: "BM::SELECT_PROMOTION_REWARD",
  SET_PROMOTION_DATA: "BM::SET_PROMOTION_DATA",
  TOGGLE_EVENT_PATH: "BM::TOGGLE_EVENT_PATH",
  UPDATE_PROMOTION: "BM::UPDATE_PROMOTION",

  UPDATE_PROMOTION_CRITERIA: "BM::UPDATE_PROMOTION_CRITERIA",
  UPDATE_PROMOTION_CRITERIA_FOR_BRAND_CHANGE: "BM::UPDATE_PROMOTION_CRITERIA_FOR_BRAND_CHANGE",
  UPDATE_PROMOTION_FAILED: "BM::UPDATE_PROMOTION_FAILED",

  UPDATE_PROMOTION_FIELD: "BM::UPDATE_PROMOTION_FIELD",
  UPDATE_PROMOTION_GROUP: "BM::UPDATE_PROMOTION_GROUP",
  UPDATE_PROMOTION_REWARD: "BM::UPDATE_PROMOTION_REWARD",
  UPDATE_PROMOTION_REWARD_FILTER_VALUE: "BM::UPDATE_PROMOTION_REWARD_FILTER_VALUE",

  UPDATE_PROMOTION_SUCCEEDED: "BM::UPDATE_PROMOTION_SUCCEEDED",
};

export default constants;
