import _ from "lodash";

const sortHurdleSequence = (response) => {
  const sortedData = response.sort((a, b) => {
    const x = a.description.toLowerCase();
    const y = b.description.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }

    return 0;
  });

  return sortedData;
};

export const getFilteredData = (changed, original) => true;

export const removeIndexFromNewHurdle = (hurdleSequence) => {
  const reverse = _.cloneDeep(hurdleSequence);
  reverse.reverse();
  for (const key in reverse) {
    if (!reverse[key]["id"] && reverse[key]["index"]) {
      delete reverse[key]["index"];
    } else {
      break;
    }
  }

  return reverse;
};

export default sortHurdleSequence;
