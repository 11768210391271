import originalAxios from "axios";

import filterTypes from "../constants/filterTypes";
import couponModel from "../models/couponModel";

import api from "./api";
import { isEmpty } from "lodash";

let toBasket = null;
const chunkRiskDataXhr = {};
let resultsViewerDataXhr = null;
let resultsViewerDataUpdateXhr = null;

export function fetchResultsViewerData(params) {
  return new Promise((resolve, reject) => {
    if (resultsViewerDataUpdateXhr) {
      resultsViewerDataUpdateXhr.cancel("cancelled due to concurrent request");
    }
    for (const key in chunkRiskDataXhr) {
      chunkRiskDataXhr[key].cancel("cancelled due to concurrent request");
      delete chunkRiskDataXhr[key];
    }
    const { bookType, code, fromDate, liabilityType, line, marketStatusIds, priceType, toDate, winPlace } = params;
    let url = `/bo/results/${code}?virtual=1&marketStatusIds=${marketStatusIds}&lineId=0&isResultsOnly=1`;
    toBasket = null;
    if (fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }

    if (resultsViewerDataXhr) {
      resultsViewerDataXhr.cancel("cancelled due to concurrent request");
    }

    resultsViewerDataXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, resultsViewerDataXhr);
      resultsViewerDataXhr = null;
      resolve(response);
    } catch (err) {
      resultsViewerDataXhr = null;
      reject(err);
    }
    resultsViewerDataXhr = null;
  })
    .then((response) => {
      if (response.data.toBasket) {
        toBasket = response.data.toBasket;
      }
      couponModel.load(response.data);

      return { response: { hasUpdates: true, success: true } };
    })
    .catch((xhr) => ({ xhr }));
}

export function updateResultsViewerData(params) {
  return new Promise((resolve, reject) => {
    if (resultsViewerDataXhr) {
      resultsViewerDataXhr.cancel("cancelled due to concurrent request");
    }

    const { bookType, code, fromDate, liabilityType, line, marketStatusIds, priceType, toDate, winPlace } = params;
    let url = `/bo/results/${code}?virtual=1&marketStatusIds=${marketStatusIds}&lineId=0&isResultsOnly=1`;
    if (fromDate) {
      url = `${url}&fromDate=${fromDate}`;
    }
    if (toDate) {
      url = `${url}&toDate=${toDate}`;
    }
    if (toBasket) {
      url = `${url}&from=${toBasket}`;
    }

    if (resultsViewerDataUpdateXhr) {
      resultsViewerDataUpdateXhr.cancel("cancelled due to concurrent request");
    }

    resultsViewerDataUpdateXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, resultsViewerDataUpdateXhr);

      resolve(response);
    } catch (err) {
      resultsViewerDataUpdateXhr = null;
      reject(err);
    }
    resultsViewerDataUpdateXhr = null;
  })
    .then((response) => {
      resultsViewerDataUpdateXhr = null;
      if (response.data.toBasket) {
        toBasket = response.data.toBasket;
      }
      try {
        couponModel.update(response.data, {
          includeAllUpdates: true,
        });
      } catch (e) {}

      return { response: { hasUpdates: !isEmpty(response.data.items), success: true } };
    })
    .catch((xhr) => ({ xhr }));
}

function filterMarkets(markets, marketFilter, periodFilter) {
  const allMarkets = marketFilter === filterTypes.MARKETS.ALL_MARKETS;
  if (allMarkets) {
    return [...markets];
  }

  return markets.filter((market) => market.desc.toLowerCase().indexOf(marketFilter.toLowerCase()) > -1);
}

function getMarketPeriodsFromEvent(event) {
  const marketPeriods = [];
  event.children.forEach((market) => {
    if (marketPeriods.indexOf(market.periodAbrv) > -1) {
      return;
    }
    marketPeriods.push(market.periodAbrv);
  });

  return marketPeriods;
}

export function filterEvents(marketFilter) {
  const allMarkets = marketFilter === filterTypes.MARKETS.ALL_MARKETS;
  let events = couponModel.tree.events.map((event) => {
    const marketPeriods = getMarketPeriodsFromEvent(event);

    return { ...event, children: event.children, marketPeriods, parentPath: event.parentPath };
  });
  if (allMarkets) {
    couponModel.setFilteredEvents(events);

    return;
  }
  events = events.map((event, index) => {
    let children = (children = filterMarkets(event.children, marketFilter));

    return { ...event, children, parentPath: event.parentPath };
  });

  const filteredData = events.filter((event) => !!event.children.length);

  couponModel.setFilteredEvents(filteredData);
}
