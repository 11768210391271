import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchSportOtherOptionsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchStakeType() {
  const url = `/bo/stakedistributionindicators/stake/types`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchIndicatorActions() {
  const url = `/bo/liabilityindicators/actions`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchStakeDistributationIndicator(path) {
  const url = `/bo/stakedistributionindicators?eventPathId=${path.id}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveStakeDistribution(action) {
  const url = `/bo/stakedistributionindicators/updatemultiple?eventPathIds=${action.data.id}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_PUT, url, action.data.data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function removeStakeLiabilityIndicators(action) {
  const url = `/bo/stakedistributionindicators/eventpaths/${action.eventPathIdString}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
