import api from "./api";

export function getParameterByName(paramKey) {
  const url = window.location.href;
  paramKey = paramKey.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${paramKey}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
