import originalAxios from "axios";

import appNames from "../constants/appNames";
import { objectToArray } from "../utils";

import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services/apiUtils";

let appsListXhr = null;
let recentAppsListXhr = null;
let useAppXhr = null;
const fetchAppPermissionsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchAppPermissions(userId) {
  const url = `/bo/accounts/${userId}/permissions?extendedResponse=true`;

  return performHttpCall(fetchAppPermissionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchApps(id) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/${id}/applications`;

    if (appsListXhr) {
      appsListXhr.cancel("cancelled due to concurrent request");
    }

    appsListXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, appsListXhr);
      appsListXhr = null;
      resolve(response);
    } catch (err) {
      appsListXhr = null;
      reject(err);
    }
    appsListXhr = null;
  })
    .then((response) => {
      const allowedApps = objectToArray(appNames);
      const allowedAppIds = response.data.map((app) => app.applicationId);
      const appList = response.data.filter((app) => allowedApps.indexOf(app.description) > -1);

      return {
        response: { allowedAppIds, appList },
      };
    })
    .catch((xhr) => ({ xhr }));
}

export function fetchRecentApps(id) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/${id}/applications/recentlyused`;
    if (recentAppsListXhr) {
      recentAppsListXhr.cancel("cancelled due to concurrent request");
    }

    recentAppsListXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, recentAppsListXhr);
      recentAppsListXhr = null;
      resolve(response);
    } catch (err) {
      recentAppsListXhr = null;
      reject(err);
    }
    recentAppsListXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function useApp(userid, appId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/${userid}/applications/use?applicationId=${appId}`;
    if (useAppXhr) {
      useAppXhr.cancel("cancelled due to concurrent request");
    }

    useAppXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, useAppXhr);
      useAppXhr = null;
      resolve(response);
    } catch (err) {
      useAppXhr = null;
      reject(err);
    }
    useAppXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({ err }));
}

export function lines() {
  return new Promise((resolve, reject) => {
    const url = `/bo/lines?onlyActiveLines=true&agentId=-4`;
    if (useAppXhr) {
      useAppXhr.cancel("cancelled due to concurrent request");
    }

    useAppXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, useAppXhr);
      useAppXhr = null;
      resolve(response);
    } catch (err) {
      useAppXhr = null;
      reject(err);
    }
    useAppXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
