import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

const changeMarketStatusXhr = {};

export function changeMarketStatus(marketId, status) {
  return new Promise((resolve, reject) => {
    const url = `/bo/markets/${marketId}`;
    const requestBody = {
      status,
    };

    if (changeMarketStatusXhr[marketId]) {
      changeMarketStatusXhr[marketId].cancel("cancelled due to concurrent request");
    }

    changeMarketStatusXhr[marketId] = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, changeMarketStatusXhr);
      delete changeMarketStatusXhr[marketId];
      resolve(response);
    } catch (err) {
      delete changeMarketStatusXhr[marketId];
      reject(err);
    }
    delete changeMarketStatusXhr[marketId];
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
