import actionTypes from "../constants";

const initialState = {
  alerts: {},
  checkIsUpdate: {},
  isUpdate: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_NOTIFY_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.response,
        checkIsUpdate: action.response,
        isUpdate: true,
      };
    case actionTypes.CLAIM_ITME_SUCCESS:
      let count = 0;
      const claimedData = {
        ...state.alerts,
        alerts: state.alerts.alerts.map((val) => {
          if (val.id === action.data.alertId) {
            if (val.read === false) {
              count = 1;
            }

            return (val = { ...val, claimed: true, claimedByUsername: action.data.claimedByUsername, read: true });
          }

          return val;
        }),
        totalUnread: state.alerts.totalUnread - count,
      };
      count = 0;

      return {
        ...state,
        alerts: claimedData,
      };
    case actionTypes.READ_ITEM_SUCCESS:
      count = 0;
      const readData = {
        ...state.alerts,
        alerts: state.alerts.alerts.map((val) => {
          if (val.id === action.data.alertId && val.read === false) {
            count = 1;

            return (val = { ...val, read: true });
          }

          return val;
        }),
        totalUnread: state.alerts.totalUnread - count,
      };
      count = 0;

      return {
        ...state,
        alerts: readData,
      };
    case actionTypes.READ_ALL_SUCCESS:
      const readAllData = {
        ...state.alerts,
        alerts: (state.alerts.alerts ?? []).map((val) => (val = { ...val, read: true })),
        totalUnread: 0,
      };

      return {
        ...state,
        alerts: readAllData,
      };
    case actionTypes.ALERT_UPDATE:
      return {
        ...state,
        isUpdate: false,
      };
    default:
      return { ...state };
  }
}
