import _ from "lodash";
import moment from "moment";

import constants from "./constants";

const initialState = {
  allPeriod: [],
  allSports: [],
  backupMarketData: [],
  backupMarketPeriodData: [],
  backupTabledata: [],
  changedEnabledMarketIds: [],
  changedSortIds: [],
  fetchingMarketData: false,
  fetchingPeriodData: false,
  loadingWagerLimitGroup: false,
  majorSort: {
    data: [],
    value: "MARKET_PERIOD",
  },
  marketData: [],
  marketPeriodData: [],
  selectedSports: "",
  tableHeader: [],
  tabledata: [],
  updateIds: [],
  updateMarketIds: [],
  wagerLimitGroup: [],
};

const utilitiesMarketTypeEnablement = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_SPORTS_SUCCEEDED:
      return {
        ...state,
        allSports: action.payload,
      };
    case constants.FETCH_ALL_SPORTS_FAILED:
      return {
        ...state,
        allSports: [],
      };
    case constants.FETCH_PERIOD_TYPES:
      return {
        ...state,
        fetchingPeriodData: true,
        selectedSports: action.payload,
      };
    case constants.FETCH_PERIOD_TYPES_SUCCEEDED:
      return {
        ...state,
        tableHeader: action.tableHeader,
      };
    case constants.FETCH_PERIOD_TYPES_FAILED:
      return {
        ...state,
        tableHeader: [],
      };
    case constants.FETCH_ALL_PERIOD_TYPES_SUCCEEDED:
      return {
        ...state,
        allPeriod: action.allPeriod,
        backupTabledata: _.cloneDeep(action.payload),
        fetchingPeriodData: false,
        tabledata: action.payload,
      };
    case constants.FETCH_ALL_PERIOD_TYPES_FAILED:
      return {
        ...state,
        backupTabledata: _.cloneDeep([]),
        tabledata: [],
      };
    case constants.FETCH_ALL_MARKET_TYPES:
      return {
        ...state,
        fetchingMarketData: true,
      };
    case constants.FETCH_ALL_MARKET_TYPES_SUCCEEDED:
      return {
        ...state,
        backupMarketData: action.payload,
        fetchingMarketData: false,
        marketData: action.payload,
      };
    case constants.FETCH_MARKET_PERIOD_TYPES_SUCCEEDED:
      return {
        ...state,
        backupMarketPeriodData: action.response,
        marketPeriodData: action.response,
      };
    case constants.FETCH_ALL_MARKET_TYPES_FAILED:
      return {
        ...state,
        marketData: [],
      };
    case constants.UPDATE_FORM_TABLE_DATA:
      let id = _.cloneDeep(state.updateIds);
      const temp = [];
      if (!_.isEqual(state.backupTabledata, action.payload)) {
        id.push(action.id);
        id = _.uniqBy(id, "id");
      } else {
        _.map(id, (val) => {
          if (val.id != action.id.id) {
            temp.push(val);
          }
        });
        id = temp;
      }

      return {
        ...state,
        tabledata: action.payload,
        updateIds: id,
      };
    case constants.UPDATE_MARKET_FORM:
      const { enabled, ordinalPosition, row, value, wagerLimit } = action.payload;
      const newRow = _.cloneDeep(row);
      const changedEnable = _.cloneDeep(state.changedEnabledMarketIds);
      let tempChangedEnabledMarketIds = _.cloneDeep(state.changedEnabledMarketIds);

      const changedSortId = _.cloneDeep(state.changedSortIds);
      let tempChangedSortIds = _.cloneDeep(state.changedSortIds);

      const marketData = _.cloneDeep(state.marketData);
      const backupMarketData = _.cloneDeep(state.backupMarketData);

      let tempMarketIds = _.cloneDeep(state.updateMarketIds);

      if (
        _.filter(backupMarketData, (o) => o.id == row.id && o.enabled == enabled).length > 0 &&
        changedEnable.indexOf(row.id) > -1
      ) {
        tempChangedEnabledMarketIds = _.without(changedEnable, row.id);
      } else if (
        _.filter(backupMarketData, (o) => o.id == row.id && o.enabled != enabled).length > 0 &&
        changedEnable.indexOf(row.id) < 0
      ) {
        tempChangedEnabledMarketIds.push(row.id);
      }

      if (
        _.filter(backupMarketData, (o) => o.id == row.id && o.ordinalPosition == ordinalPosition).length > 0 &&
        changedSortId.indexOf(row.id) > -1
      ) {
        tempChangedSortIds = _.without(changedSortId, row.id);
      } else if (
        _.filter(backupMarketData, (o) => o.id == row.id && o.ordinalPosition != ordinalPosition).length > 0 &&
        changedSortId.indexOf(row.id) < 0
      ) {
        tempChangedSortIds.push(row.id);
      }

      newRow.enabled = enabled;
      newRow.marketTypeWagerLimitGroupId = wagerLimit;
      newRow.ordinalPosition = ordinalPosition;

      const i = _.findIndex(marketData, (o) => o.id == row.id);
      marketData[i] = newRow;

      if (!_.isEqual(state.backupMarketData, marketData)) {
        tempMarketIds.push(row.id);
        tempMarketIds = _.uniq(tempMarketIds, "id");
      } else {
        const marketTempPayload = [];
        _.map(tempMarketIds, (val) => (val != row.id ? marketTempPayload.push(val) : null));
        tempMarketIds = marketTempPayload;
      }

      return {
        ...state,
        changedEnabledMarketIds: tempChangedEnabledMarketIds,
        changedSortIds: tempChangedSortIds,
        marketData,
        updateMarketIds: tempMarketIds,
      };
    case constants.UPDATE_MARKET_PERIOD_TYPES_TABLE:
      return {
        ...state,
        marketPeriodData: action.payload,
      };
    case constants.UNDO_MARKET_PERIOD_TYPES_TABLE:
      return {
        ...state,
        marketPeriodData: state.backupMarketPeriodData,
      };
    case constants.UPDATE_MARKET_TYPES_SUCCEEDED:
      return {
        ...state,
        changedEnabledMarketIds: [],
        changedSortIds: [],
        updateMarketIds: [],
      };
    case constants.UNDO_FORM_TABLE_DATA:
      const selects = _.filter(state.majorSort.data, (o) => o.sportCode == state.selectedSports);
      const vals = selects.length ? selects[0]["marketSortOrder"] : "MARKET_PERIOD";

      return {
        ...state,
        changedEnabledMarketIds: [],
        changedSortIds: [],
        majorSort: {
          ...state.majorSort,
          value: vals,
        },
        marketData: _.cloneDeep(state.backupMarketData),
        tabledata: _.cloneDeep(state.backupTabledata),
        updateIds: [],
        updateMarketIds: [],
      };
    case constants.FETCH_ALL_WAGER_LIMIT_GROUP:
      return {
        ...state,
        loadingWagerLimitGroup: true,
      };
    case constants.FETCH_ALL_WAGER_LIMIT_GROUP_SUCCEEDED:
      return {
        ...state,
        loadingWagerLimitGroup: false,
        wagerLimitGroup: action.response,
      };
    case constants.UPDATE_MAJOR_SORT:
      return {
        ...state,
        majorSort: {
          ...state.majorSort,
          value: action.payload,
        },
      };
    case constants.FETCH_ALL_MAJOR_SORT_SUCCEEDED:
      const select = _.filter(action.response, (o) => o.sportCode == state.selectedSports);
      const val = select.length ? select[0]["marketSortOrder"] : "MARKET_PERIOD";

      return {
        ...state,
        majorSort: {
          ...state.majorSort,
          data: action.response,
          value: val,
        },
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesMarketTypeEnablement;
