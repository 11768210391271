import { put, call, takeLatest } from "redux-saga/effects";

import { store, history } from "../../../../store";
import constants from "../../App/constants";
import { buildTreeItem, buildRemoveTreeItem } from "../../Sidebar/helpers";

import actionTypes from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import * as CRMAPI from "containersV2/CombiRiskManager/services";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchPromotions(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchPromotions, id);
  if (response) {
    yield put({
      promotions: response,
      type: actionTypes.FETCH_PROMOTIONS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({
      response,
      type: actionTypes.FETCH_PROMOTIONS_FAILED,
    });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionData(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchPromotions, id);
  if (response) {
    yield put({
      promotion: response,
      type: actionTypes.FETCH_PROMOTION_DATA_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({
      response,
      type: actionTypes.FETCH_PROMOTION_DATA_FAILED,
    });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchPromotionCampaign(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchPromotionCampaign, id);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_PROMOTION_CAMPAIGN_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({
      response,
      type: actionTypes.FETCH_PROMOTION_CAMPAIGN_FAILED,
    });
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* addPromotion(action) {
  const { response, xhr } = yield call(API.addPromotion, action.promotionData);
  if (response) {
    yield put({
      response,
      type: actionTypes.ADD_PROMOTION_SUCCEEDED,
    });
    toastr.add({ message: i18n.t("Bonus.Promotion was successfully created") });
    const { root, tree } = buildTreeItem({ category: "promotions", item: response, type: "PROMOTION" });
    yield put(root);
    yield put(tree);
    yield put({ state: false, type: constants.SET_BM_MODULE_EDIT_STATE });
    history.push(`/bonus-manager/promotions/${response.id}`);
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr) || JSON.parse(xhr.responseText).errors[0].message;
    yield put({ type: actionTypes.ADD_PROMOTION_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* copyPromotion(action) {
  const { response, xhr } = yield call(API.copyPromotion, action.promotionData);
  if (response) {
    yield put({
      response,
      type: actionTypes.COPY_PROMOTION_SUCCEEDED,
    });
    toastr.add({ message: i18n.t("Bonus.Promotion was successfully copied") });
    const { root, tree } = buildTreeItem({ category: "promotions", item: response, type: "PROMOTION" });
    yield put(root);
    yield put(tree);
    history.push(`/bonus-manager/promotions/${response.id}`);
    yield put({ state: true, type: "BM::SET_BM_MODULE_EDIT_STATE" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.COPY_PROMOTION_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* deletePromotion(action) {
  const { response, xhr } = yield call(API.deletePromotion, action.promotionId);
  if (response) {
    yield put({
      response,
      type: actionTypes.DELETE_PROMOTION_SUCCEEDED,
    });
    toastr.add({ message: i18n.t("Bonus.Promotion was successfully deleted") });
    const { root, tree } = buildRemoveTreeItem({ category: "promotions", id: action.promotionId, type: "PROMOTION" });
    yield put(root);
    yield put(tree);
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    yield put({ modalName: "bmDeleteModal", type: "CLOSE_MODAL" });
    history.push(`/bonus-manager/promotions/`);
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.DELETE_PROMOTION_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* updatePromotion(action) {
  const { response, xhr } = yield call(API.updatePromotion, action.promotionData);
  if (response) {
    yield put({
      response,
      type: actionTypes.UPDATE_PROMOTION_SUCCEEDED,
    });
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    toastr.add({ message: i18n.t("Bonus.Promotion Updated!") });
    const { bmGlobal } = store.getState().bonusManager;
    if (response.retired && !bmGlobal.showRetired) {
      const { root, tree } = buildRemoveTreeItem({
        category: "promotions",
        id: response.id,
        type: "PROMOTION",
      });
      yield put(root);
      yield put(tree);
      history.push(`/bonus-manager/promotions`);
    } else {
      const { root, tree } = buildTreeItem({ category: "promotions", item: response, type: "PROMOTION", update: true });
      yield put(root);
      yield put(tree);
    }
    yield put({ modalName: "bmEditModal", type: "CLOSE_MODAL" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ type: actionTypes.UPDATE_PROMOTION_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* fetchMarketTypes(action) {
  const { code } = action;
  const { response, xhr } = yield call(API.fetchMarketTypes, code);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_MARKET_TYPES_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({
      response,
      type: actionTypes.FETCH_MARKET_TYPES_FAILED,
    });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}
function* fetchPromotionalGroupsWithBrandId() {
  const { response, xhr } = yield call(API.fetchPromotionalGroupsWithBrandId);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_SUCCEEDED });
  } else {
    yield put({ response, type: actionTypes.FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_FAILED });
  }
}
// const { response, xhr } = yield call(CRMAPI.fetchEventPathsFilter, code);

function* fetchSportEventPaths(action) {
  const { excludeEmptyEventPaths, isOpen, itemId, itemType, parentId, sportId } = action;
  const { response, xhr } = yield call(API.fetchSportEventPaths, itemId, itemType, excludeEmptyEventPaths);
  if (response) {
    yield put({
      isOpen,
      itemId,
      itemType,
      parentId,
      response,
      sportId,
      type: actionTypes.FETCH_SPORT_EVENT_PATHS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({
      response,
      type: actionTypes.FETCH_SPORT_EVENT_PATHS_FAILED,
    });

    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

export default function* bonusManagerAppSaga() {
  yield takeLatest(actionTypes.FETCH_PROMOTIONS, fetchPromotions);
  yield takeLatest(actionTypes.FETCH_PROMOTION_DATA, fetchPromotionData);
  yield takeLatest(actionTypes.FETCH_PROMOTION_CAMPAIGN, fetchPromotionCampaign);
  yield takeLatest(actionTypes.ADD_PROMOTION, addPromotion);
  yield takeLatest(actionTypes.COPY_PROMOTION, copyPromotion);
  yield takeLatest(actionTypes.DELETE_PROMOTION, deletePromotion);
  yield takeLatest(actionTypes.UPDATE_PROMOTION, updatePromotion);
  yield takeLatest(actionTypes.FETCH_MARKET_TYPES, fetchMarketTypes);
  yield takeLatest(actionTypes.FETCH_SPORT_EVENT_PATHS, fetchSportEventPaths);
  yield takeLatest(actionTypes.FETCH_PROMOTIONAL_GROUP_WITH_BRANDID, fetchPromotionalGroupsWithBrandId);
}
