import _ from "underscore";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchOpponentTypesXhr = null;
const fetchSingleOpponentDetailsXhr = null;
const fetchMultipleOpponentsDetailsXhr = null;
const fetchSingleOpponentDetailsWithGradeXhr = null;
const fetchEventPathOpponentsDetailsXhr = null;
const fetchEventPathAncestralOpponentsXhr = null;
const fetchOpponentKitsXhr = null;
const fetchKitPatternsXhr = null;
const addOpponentXhr = null;
const editOpponentXhr = null;
const deleteAllOpponentOfEventPathXhr = null;
const deleteAllOpponentOfEventPathAndUnderXhr = null;
const assignSingleOpponentToEventPathXhr = null;
const assignSingleOpponentToEventPathWithGradeXhr = null;
const changeOpponentGradeXhr = null;
const assignMultipleOpponentsToEventPathXhr = null;
const unAssignOpponentFromEventPathXhr = null;

export function fetchOpponentTypes() {
  const url = "/bo/opponents/types";

  return performHttpCall(fetchOpponentTypesXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSingleOpponentDetails(opponentId) {
  const url = `/bo/opponents/${opponentId}`;

  return performHttpCall(fetchSingleOpponentDetailsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMultipleOpponentsDetails(opponentIdList) {
  const opponentIds = "";

  if (opponentIdList && _.isArray(opponentIdList) && _.size(opponentIdList) > 1) {
    const opponentId = _.first(opponentIdList);

    const restOfOpponentIds = _.rest(opponentIdList);
    _.each(restOfOpponentIds, (opponentId) => {
      opponentId += `,${opponentId}`;
    });
  }

  const url = `/bo/opponents/${opponentIds}`;

  return performHttpCall(fetchMultipleOpponentsDetailsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSingleOpponentDetailsWithGrade(eventPathId, opponentId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/${opponentId}`;

  return performHttpCall(fetchSingleOpponentDetailsWithGradeXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathOpponentsDetails(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents`;

  return performHttpCall(fetchEventPathOpponentsDetailsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventPathAncestralOpponents(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/ancestors`;

  return performHttpCall(fetchEventPathAncestralOpponentsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchOpponentKits(opponentId) {
  const url = `/bo/opponents/${opponentId}/kit`;

  return performHttpCall(fetchOpponentKitsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchKitPatterns() {
  const url = `/bo/opponents/kit/patterns`;

  return performHttpCall(fetchKitPatternsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addOpponent(opponentObj) {
  const url = `/bo/opponents`;

  return performHttpCall(addOpponentXhr, httpMethods.HTTP_POST, url, opponentObj)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function editOpponent(opponentId, opponentObj) {
  const url = `/bo/opponents/${opponentId}`;

  return performHttpCall(editOpponentXhr, httpMethods.HTTP_PUT, url, opponentObj)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteAllOpponentOfEventPath(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents`;

  return performHttpCall(deleteAllOpponentOfEventPathXhr, httpMethods.HTTP_DELETE, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteAllOpponentOfEventPathAndUnder(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents?deleteChildren=true`;
  const headers = {
    "X-P8-Datasource": "database",
  };

  return performHttpCall(deleteAllOpponentOfEventPathAndUnderXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function assignSingleOpponentToEventPath(eventPathId, opponentId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/${opponentId}`;

  return performHttpCall(assignSingleOpponentToEventPathXhr, httpMethods.HTTP_PUT, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function assignSingleOpponentToEventPathWithGrade(eventPathId, opponentId, grade) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/${opponentId}?grade=${grade}`;

  return performHttpCall(assignSingleOpponentToEventPathWithGradeXhr, httpMethods.HTTP_PUT, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function changeOpponentGrade(eventPathId, opponentId, grade) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/${opponentId}?grade=${grade}`;

  return performHttpCall(changeOpponentGradeXhr, httpMethods.HTTP_PUT, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function assignMultipleOpponentsToEventPath(eventPathId, opponentIdList) {
  const url = `/bo/eventpaths/${eventPathId}/opponents`;
  const body = { opponentIds: opponentIdList };

  return performHttpCall(assignMultipleOpponentsToEventPathXhr, httpMethods.HTTP_PUT, url, body)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function unAssignOpponentFromEventPath(eventPathId, opponentId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents/${opponentId}`;
  const headers = {
    "X-P8-Datasource": "database",
  };

  return performHttpCall(unAssignOpponentFromEventPathXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
