import mainConst from "../../App/constants";

import constants, { CAMPAIGNMODE } from "./constants";

const initialState = {
  brands: [],
  campaign: {
    brandId: "",
    campaignMessages: [],
    campaignPromotions: [],
    description: "",
    fromDate: null,
    id: null,
    name: "New Campaign",
    schedule: "forever",
    timeZone: null,
    toDate: null,
  },
  campaignBudgetLog: {},
  campaignStatistics: {},
  campaigns: [],
  isFetching: false,
  isFetchingCampaignPromotionBudgetLog: false,
  isFetchingCampaignPromotionBudgetLogFailed: false,
  isFetchingCampaignPromotionStatistics: false,
  isFetchingCampaignPromotionStatisticsFailed: false,
  isSearchingPromoCode: false,
  isSearchingPromoCodeFailed: false,
  mode: CAMPAIGNMODE.VIEW,
  modified: false,
  newCampaign: false,
  promoCode: {
    codeUsages: [],
    codes: [],
    filters: {
      selected: "All",
    },
    isValidCode: null,
    method: "specific",
    modalType: "",
    no_code: {
      campaignpromoid: null,
      count: null,
      expirydate: null,
      maxuses: null,
    },
    selectedPromoCodes: [],
    specific: {
      active: false,
      campaignPromoId: null,
      code: null,
      expiryDate: null,
      expirydate: null,
      maxUsedNumber: null,
      usedNumber: 0,
    },
  },
};

const campaigns = (state = initialState, action) => {
  const { campaign, campaigns } = action;
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case constants.SET_CAMPAIGN_MODE:
      return {
        ...state,
        mode: action.mode,
      };
    case constants.RECEIVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
        campaigns:
          action.campaign.id === 0
            ? [...state.campaigns]
            : state.campaigns.find((x) => x.id === action.campaign.id)
            ? [...state.campaigns]
            : [...state.campaigns, action.campaign],
        isFetching: false,
      };
    case constants.FETCH_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
      };
    case constants.FETCH_BRANDS_SUCCEEDED:
      return {
        ...state,
        brands: action.response,
      };
    case constants.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        campaigns,
        isFetching: false,
      };
    case constants.UPDATE_CAMPAIGN_SUC:
      let val = [];

      return {
        ...state,
        campaign: val.id ? val : state.campaign,
        campaigns: state.campaigns.map((current) => {
          if (Number(campaign.id) === Number(current.id)) {
            val = campaign;

            return campaign;
          }

          return current;
        }),
        isFetching: false,
        modified: false,
      };
    case constants.SET_CAMPAIGN_MODIFIED:
      return {
        ...state,
        modified: action.modified,
      };
    case constants.SET_CAMPAIGN:
      return {
        ...state,
        campaign,
      };
    case constants.RESET_CAMPAIGN:
      return {
        ...state,
        campaign: {
          brandId: "",
          campaignPromotions: [],
          description: "",
          fromDate: null,
          id: null,
          name: "New Campaign",
          schedule: "forever",
          timeZone: null,
          toDate: null,
        },
        mode: CAMPAIGNMODE.VIEW,
        modified: false,
        newCampaign: false,
      };
    case constants.OPEN_NEW_CAMPAIGN_FORM:
      return {
        ...state,
      };
    case constants.CREATE_CAMPAIGN_SUC:
      return {
        ...state,
        // newCampaign: false,
        campaign,

        campaigns: [...state.campaigns, campaign],
        isFetching: false,
        modified: false,
      };
    case constants.UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case constants.REMOVE_PROMOTION:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignPromotions: state.campaign.campaignPromotions.filter(
            (promotion) => Number(promotion.promotionId) !== Number(action.id),
          ),
        },
      };
    case constants.ADD_PROMOTION:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignPromotions: [...state.campaign.campaignPromotions, action.promotion],
        },
      };
    case constants.TOGGLE_PROMOTION_STATUS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignPromotions: state.campaign.campaignPromotions.map((promotion) => {
            if (Number(promotion.promotionId) === Number(action.id)) {
              return {
                ...promotion,
                paused: !promotion.paused,
              };
            }

            return promotion;
          }),
        },
      };
    case constants.RESET_TERMS_CONDITION:
      let term = false;
      if (action.terms.length > 0) {
        term = action.terms[0].id;
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignPromotions:
            (state.campaign.campaignPromotions.length > 0 &&
              state.campaign.campaignPromotions.map((promotion) => ({
                ...promotion,
                termsAndConditionsId: term,
              }))) ||
            [],
        },
      };
    case constants.SET_BRANDID:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          brandId: action.id,
        },
      };
    case constants.SET_PROMOTION:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignPromotions: state.campaign.campaignPromotions.map((promotion) => {
            if (Number(promotion.promotionId) === Number(action.id)) {
              return action.promotion;
            }

            return promotion;
          }),
        },
      };
    case constants.DELETE_CAMPAIGN:
      return {
        ...state,
        isDeletingCampaign: true,
        isDeletingCampaignFailed: false,
      };
    case constants.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isDeletingCampaign: false,
        isDeletingCampaignFailed: false,
      };
    case constants.DELETE_CAMPAIGN_FALSE:
      return {
        ...state,
        isDuplicatingCampaign: false,
        isDuplicatingCampaignFailed: true,
      };
    case constants.COPY_CAMPAIGN:
      return {
        ...state,
        isDuplicatingCampaign: true,
        isDuplicatingCampaignFailed: false,
      };
    case constants.COPY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.campaign],
        isDuplicatingCampaign: false,
        isDuplicatingCampaignFailed: false,
        modified: false,
        newCampaign: true,
      };
    case constants.COPY_CAMPAIGN_FAILED:
      return {
        ...state,
        isDuplicatingCampaign: false,
        isDuplicatingCampaignFailed: true,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_STATISTICS:
      return {
        ...state,
        campaignBudgetLog: [],
        campaignStatistics: {},
        isFetchingCampaignPromotionStatistics: true,
        isFetchingCampaignPromotionStatisticsFailed: false,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_STATISTICS_SUCCESS:
      return {
        ...state,
        campaignStatistics: action.response,
        isFetchingCampaignPromotionStatistics: false,
        isFetchingCampaignPromotionStatisticsFailed: false,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_STATISTICS_FAILED:
      return {
        ...state,
        isFetchingCampaignPromotionStatistics: false,
        isFetchingCampaignPromotionStatisticsFailed: true,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG:
      return {
        ...state,
        isFetchingCampaignPromotionBudgetLog: true,
        isFetchingCampaignPromotionBudgetLogFailed: false,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_SUCCESS:
      return {
        ...state,
        campaignBudgetLog: action.response,
        isFetchingCampaignPromotionBudgetLog: false,
        isFetchingCampaignPromotionBudgetLogFailed: false,
      };
    case constants.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_FAILED:
      return {
        ...state,
        isFetchingCampaignPromotionBudgetLog: false,
        isFetchingCampaignPromotionBudgetLogFailed: true,
      };

    case constants.SET_PROMO_CODE_FORM_METHOD:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          isValidCode: null,
          method: action.method,
          // [state.promoCode.method]: {
          //   ...state.promoCode[state.promoCode.method],
          //   ...action.formData
          // }
        },
      };

    case constants.SET_PROMO_CODE_FORM_DATA:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          isValidCode: null,
          no_code: {
            ...state.promoCode.no_code,
            [action.field === "maxUsedNumber"
              ? "maxuses"
              : action.field === "campaignPromoId"
              ? "campaignpromoid"
              : action.field === "expiryDate"
              ? "expirydate"
              : action.field]: action.value,
          },
          specific: {
            ...state.promoCode.specific,
            [action.field === "maxuses"
              ? "maxUsedNumber"
              : action.field === "campaignpromoid"
              ? "campaignPromoId"
              : action.field === "expirydate"
              ? "expiryDate"
              : action.field]: action.value,
          },
        },
      };

    case constants.SEARCH_PROMO_CODE:
      return {
        ...state,
        isSearchingPromoCode: true,
        isSearchingPromoCodeFailed: false,
      };
    case constants.SEARCH_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isSearchingPromoCode: false,
        isSearchingPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          isValidCode: !action.response.id,
        },
      };
    case constants.SEARCH_PROMO_CODE_FAILED:
      return {
        ...state,
        isSearchingPromoCode: false,
        isSearchingPromoCodeFailed: true,
      };

    case constants.CREATE_PROMO_CODE:
      return {
        ...state,
        isCreatingingPromoCode: true,
        isCreatingingPromoCodeFailed: false,
      };
    case constants.CREATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isCreatingingPromoCode: false,
        isCreatingingPromoCodeFailed: false,
      };
    case constants.CREATE_PROMO_CODE_FAILED:
      return {
        ...state,
        isCreatingingPromoCode: false,
        isCreatingingPromoCodeFailed: true,
      };

    case constants.GENERATE_PROMO_CODE:
      return {
        ...state,
        isGeneratingPromoCode: true,
        isGeneratingPromoCodeFailed: false,
      };
    case constants.GENERATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isGeneratingPromoCode: false,
        isGeneratingPromoCodeFailed: false,
      };
    case constants.GENERATE_PROMO_CODE_FAILED:
      return {
        ...state,
        isGeneratingPromoCode: false,
        isGeneratingPromoCodeFailed: true,
      };

    case constants.FETCH_PROMO_CODE:
      return {
        ...state,
        isFetchingPromoCode: true,
        isFetchingPromoCodeFailed: false,
      };
    case constants.FETCH_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isFetchingPromoCode: false,
        isFetchingPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          codes: action.response,
        },
      };
    case constants.FETCH_PROMO_CODE_FAILED:
      return {
        ...state,
        isFetchingPromoCode: false,
        isFetchingPromoCodeFailed: true,
      };
    case constants.CHANGE_PROMO_FILTER:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          filters: {
            ...state.promoCode.filters,
            selected: action.filter,
          },
          selectedPromoCodes: [],
        },
      };
    case constants.TOGGLE_SELECTED_PROMO_CODE:
      const selectedPromoCodes = [...state.promoCode.selectedPromoCodes];
      if (action.checked && selectedPromoCodes.indexOf(action.promoCodeId) === -1) {
        selectedPromoCodes.push(action.promoCodeId);
      } else {
        selectedPromoCodes.splice(selectedPromoCodes.indexOf(action.promoCodeId), 1);
      }

      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          selectedPromoCodes: [...selectedPromoCodes],
        },
      };
    case constants.FETCH_PROMO_CODE_USAGE:
      return {
        ...state,
        isFetchingPromoCodeUsage: true,
        isFetchingPromoCodeUsageFailed: false,
      };
    case constants.FETCH_PROMO_CODE_USAGE_SUCCESS:
      return {
        ...state,
        isFetchingPromoCodeUsage: false,
        isFetchingPromoCodeUsageFailed: false,
        promoCode: {
          ...state.promoCode,
          codeUsages: action.response,
        },
      };
    case constants.FETCH_PROMO_CODE_USAGE_FAILED:
      return {
        ...state,
        isFetchingPromoCodeUsage: false,
        isFetchingPromoCodeUsageFailed: true,
      };

    case constants.UPDATE_PROMO_CODE:
      return {
        ...state,
        isUpdatingPromoCode: true,
        isUpdatingPromoCodeFailed: false,
      };
    case constants.UPDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isUpdatingPromoCode: false,
        isUpdatingPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          selectedPromoCodes: [],
        },
      };
    case constants.UPDATE_PROMO_CODE_FAILED:
      return {
        ...state,
        isUpdatingPromoCode: false,
        isUpdatingPromoCodeFailed: true,
      };

    case constants.BATCH_UPDATE_PROMO_CODE:
      return {
        ...state,
        isUpdatingBatchPromoCode: true,
        isUpdatingBatchPromoCodeFailed: false,
      };
    case constants.BATCH_UPDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isUpdatingBatchPromoCode: false,
        isUpdatingBatchPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          selectedPromoCodes: [],
        },
      };
    case constants.BATCH_UPDATE_PROMO_CODE_FAILED:
      return {
        ...state,
        isUpdatingBatchPromoCode: false,
        isUpdatingBatchPromoCodeFailed: true,
      };

    case constants.DELETE_PROMO_CODE:
      return {
        ...state,
        isDeletingPromoCode: true,
        isDeletingPromoCodeFailed: false,
      };
    case constants.DELETE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isDeletingPromoCode: false,
        isDeletingPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          selectedPromoCodes: [],
        },
      };
    case constants.DELETE_PROMO_CODE_FAILED:
      return {
        ...state,
        isDeletingPromoCode: false,
        isDeletingPromoCodeFailed: true,
      };

    case constants.BATCH_DELETE_PROMO_CODE:
      return {
        ...state,
        isDeletingBatchPromoCode: true,
        isDeletingBatchPromoCodeFailed: false,
      };
    case constants.BATCH_DELETE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isDeletingBatchPromoCode: false,
        isDeletingBatchPromoCodeFailed: false,
        promoCode: {
          ...state.promoCode,
          selectedPromoCodes: [],
        },
      };
    case constants.BATCH_DELETE_PROMO_CODE_FAILED:
      return {
        ...state,
        isDeletingBatchPromoCode: false,
        isDeletingBatchPromoCodeFailed: true,
      };

    case constants.TOGGLE_PROMO_CODE_MODAL:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          modalType: action.method,
        },
      };
    case constants.ADD_MESSAGE_PLAN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignMessages: [...state.campaign.campaignMessages, action.message],
        },
      };

    case constants.REMOVE_MESSAGE_PLAN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignMessages: state.campaign.campaignMessages.filter(
            (message) => Number(message.campaignMessagePlanId) !== Number(action.id),
          ),
        },
      };

    case constants.SET_MESSAGE_PLAN:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignMessages: state.campaign.campaignMessages.map((message) => {
            if (Number(message.campaignMessagePlanId) === Number(action.id)) {
              return action.message;
            }

            return message;
          }),
        },
      };
    case mainConst.SET_BM_MODULE_EDIT_STATE:
      return {
        ...state,
        modified:
          typeof action.state === "object" && !action.state.state && action.state.form == "campaigns"
            ? action.state.state
            : typeof action.state !== "object" && !action.state
            ? action.state
            : state.modified,
      };

    default:
      return { ...state };
  }
};

export default campaigns;
