import _ from "lodash";

import constants from "./constants";

import { makeIterable } from "utils";

const initialState = {
  backUp: JSON.stringify({ value: [] }),
  deletionError: {},
  groupsForDelete: [],
  modifiedRow: {},
  playerProfileGroupData: JSON.stringify({ value: [] }),
  updatePlayerProfileGroupSuccessfully: false,
};

const newRow = {
  colour: "",
  defaultGroup: false,
  description: "",
  id: 0,
  inPlayDelay: 0,
};

const WL_PlayerProfileGroup = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PLAYER_PROFILE_GROUP_SUCCEEDED:
      const data = action.response;
      data.push(newRow);

      return {
        ...state,
        backUp: JSON.stringify({ value: data }),
        groupsForDelete: [],
        modifiedRow: {},
        playerProfileGroupData: JSON.stringify({ value: data }),
      };
    case constants.SELECT_ROW_TO_EDIT:
      const selected = _.find(JSON.parse(state.playerProfileGroupData).value, (obj) => obj.id == action.row.id);
      const selectedRow =
        selected.id >= 0 ? _.find(JSON.parse(state.backUp).value, (obj) => obj.id == selected.id) : selected;

      return {
        ...state,
        selectedRowToEdit: JSON.stringify(selectedRow),
      };
    case constants.DELETE_ROW:
      const rowToDelete = state.groupsForDelete;
      const modified_row = state.modifiedRow;
      if (action.value) {
        if (!modified_row[action.row.id]) {
          modified_row[action.row.id] = [];
        }
        modified_row[action.row.id].push("delete");
        rowToDelete.push(action.row.id);
      } else {
        _.remove(rowToDelete, (id) => id == action.row.id);
        _.remove(modified_row[action.row.id], (f) => f == "delete");
        if (modified_row[action.row.id].length == 0) {
          delete modified_row[action.row.id];
        }
      }

      return {
        ...state,
        groupsForDelete: rowToDelete,
        modifiedRow: modified_row,
      };
    case constants.UPDATE_FIELD:
      const { field, row, value } = action;
      const groupsForDelete = state.groupsForDelete;
      const selectedRowToEdit = JSON.parse(state.selectedRowToEdit);
      const isModified = selectedRowToEdit.id > 0 ? !_.isEqual(selectedRowToEdit[field], value) : true;
      const modifiedRow = state.modifiedRow;
      const playerProfileGroupData = JSON.parse(state.playerProfileGroupData).value;
      _.map(playerProfileGroupData, (item, indx) => {
        if (selectedRowToEdit.id > 0 || selectedRowToEdit.id < 0) {
          if (field == "defaultGroup") {
            if (playerProfileGroupData[indx].id && playerProfileGroupData[indx].id != row.id) {
              playerProfileGroupData[indx][field] = false;
            }
            if (value) {
              _.remove(groupsForDelete, (id) => id == row.id);
            }
          }
          if (playerProfileGroupData[indx].id == row.id) {
            playerProfileGroupData[indx][field] = value;
          }
        } else if (selectedRowToEdit.id == 0) {
          if (playerProfileGroupData[indx]["id"] == row.id) {
            let id = -1;
            if (playerProfileGroupData[indx - 1]["id"] < 0) {
              id = playerProfileGroupData[indx - 1]["id"] + -1;
            }
            playerProfileGroupData[indx]["id"] = id;
            if (row.id == selectedRowToEdit.id) {
              playerProfileGroupData.push(newRow);
            }
            playerProfileGroupData[indx][field] = value;
          }
        }
      });
      if (isModified) {
        if (field == "defaultGroup") {
          const keyList = Object.keys(modifiedRow);
          _.map(keyList, (key, indx) => {
            _.remove(modifiedRow[key], (f) => f == field);
            if (modifiedRow[key].length == 0) {
              delete modifiedRow[key];
            }
          });
          if (value) {
            _.remove(modifiedRow[row.id], (f) => f == "delete");
          }
        }
        if (row.id <= 0) {
          const id = row.id == 0 ? -1 : row.id + -1;
          if (!modifiedRow[id] && selectedRowToEdit.id == row.id) {
            modifiedRow[id] = [];
            modifiedRow[id].push("newRow");
          }
        } else if (!modifiedRow[row.id]) {
          modifiedRow[row.id] = [];
          modifiedRow[row.id].push(field);
        } else if (!_.includes(modifiedRow[row.id], field)) {
          modifiedRow[row.id].push(field);
        }
      } else {
        if (field == "defaultGroup") {
          const keyList = Object.keys(modifiedRow);
          _.map(keyList, (key, indx) => {
            _.remove(modifiedRow[key], (f) => f == field);
            if (modifiedRow[key].length == 0) {
              delete modifiedRow[key];
            }
          });
          if (value) {
            _.remove(modifiedRow[row.id], (f) => f == "delete");
          }
        }
        if (modifiedRow[row.id]) {
          _.remove(modifiedRow[row.id], (f) => f == field);
          if (modifiedRow[row.id].length == 0) {
            delete modifiedRow[row.id];
          }
        }
        if (selectedRowToEdit.id <= 0) {
          _.remove(modifiedRow[row.id], (f) => f == "newRow");
          if (modifiedRow[row.id].length == 0) {
            delete modifiedRow[row.id];
          }
        }
      }

      return {
        ...state,
        modifiedRow,
        playerProfileGroupData: JSON.stringify({ value: playerProfileGroupData }),
      };
    case constants.FETCH_PLAYER_PROFILE_GROUP_FAILED:
      return {
        ...state,
      };
    case constants.ON_EXIT_PLAYER_GROUP_PAGE:
      return {
        ...state,
        groupsForDelete: [],
        modifiedRow: {},
        playerProfileGroupData: state.backUp,
        selectedRowToEdit: null,
      };
    case constants.ON_SAVE_PLAYER_GROUP_SUCCEEDED:
      return {
        ...state,
        groupsForDelete: [],
        modifiedRow: {},
        updatePlayerProfileGroupSuccessfully: true,
      };
    case constants.ON_SAVE_PLAYER_GROUP_FAILED:
      return {
        ...state,
        updatePlayerProfileGroupSuccessfully: false,
      };
    case constants.DELETION_FAILED:
      return {
        ...state,
        deletionError: action.deletionErrors,
      };
    default:
      return { ...state };
  }
};

export default WL_PlayerProfileGroup;
