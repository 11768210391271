import originalAxios from "axios";

import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const userXhr = {};
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchUserDetails(userId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/${userId}`;

    if (userXhr[url]) {
      userXhr[url].cancel("cancelled due to concurrent request");
    }

    userXhr[url] = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, userXhr[url]);
      userXhr[url] = null;
      resolve(response);
    } catch (err) {
      userXhr[url] = null;
      reject(err);
    }
    userXhr[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
//
export function validateOperatorCredentials(uname, pw) {
  return new Promise((resolve, reject) => {
    // const url = `/bo/accounts/operator/${uname}?password=${pw}`
    const url = `/bo/accounts/operator/${uname}`;

    const requestBody = { password: pw };

    if (userXhr[url]) {
      userXhr[url].cancel("cancelled due to concurrent request");
    }

    userXhr[url] = originalAxios.CancelToken.source();

    try {
      const response = api.post(url, requestBody, userXhr[url]);
      userXhr[url] = null;
      resolve(response);
    } catch (err) {
      userXhr[url] = null;
      reject(err);
    }
    userXhr[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUserInformation(userId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/${userId}?ignoreValidation=true`;

    if (userXhr[url]) {
      userXhr[url].cancel("cancelled due to concurrent request");
    }

    userXhr[url] = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, userXhr[url]);
      userXhr[url] = null;
      resolve(response);
    } catch (err) {
      userXhr[url] = null;
      reject(err);
    }
    userXhr[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function changePassword(accountId, formData) {
  return new Promise((resolve, reject) => {
    const url = `/bo/accounts/operator/pwd`;

    if (userXhr[url]) {
      userXhr[url].cancel("cancelled due to concurrent request");
    }

    userXhr[url] = originalAxios.CancelToken.source();

    try {
      const response = api.put(
        url,
        { accountId, newPassword: formData.confirmPassword, oldPassword: formData.password },
        userXhr[url],
      );
      userXhr[url] = null;
      resolve(response);
    } catch (err) {
      userXhr[url] = null;
      reject(err);
    }
    userXhr[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function logout() {
  api.logout();
}

export function isLoggedIn() {
  return new Promise((resolve, reject) => {
    api.isLoggedIn((response) => {
      if (typeof response !== "undefined") {
        resolve(!!response);
      } else {
        reject(null);
      }
    });
  })
    .then((isLoggedIn) => ({ isLoggedIn }))
    .catch((xhr) => ({ xhr }));
}
