import _ from "lodash";
import moment from "moment";

import constants from "./constants";
import handleLockUnlock from "./healpers";

const authantication = {
  password: "",
  username: "",
};

const initialState = {
  authantication: _.cloneDeep(authantication),
  emergencyLockdown: [],
  emergencyLockdownBackUp: [],
  fetchingEmergencyLockdown: false,
  modified: false,
  toBeLock: [],
  toBeUnlock: [],
};

const utilitiesEmergencyLockdown = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_EMERGENCY_LOCKDOWN:
      return {
        ...state,
        fetchingEmergencyLockdown: true,
      };
    case constants.FETCH_EMERGENCY_LOCKDOWN_SUCCEEDED:
      return {
        ...state,
        authantication: _.cloneDeep(authantication),
        emergencyLockdown: _.cloneDeep(action.response),
        emergencyLockdownBackUp: _.cloneDeep(action.response),
        fetchingEmergencyLockdown: false,
        modified: false,
      };
    case constants.FETCH_EMERGENCY_LOCKDOWN_FAILED:
      return {
        ...state,
        emergencyLockdown: _.cloneDeep([]),
        emergencyLockdownBackUp: _.cloneDeep([]),
        fetchingEmergencyLockdown: false,
        modified: false,
      };
    case constants.HANDLE_LOCK_UNLOCK:
      const { object } = action;
      const { emergencyLockdown, emergencyLockdownBackUp } = state;
      const newList = handleLockUnlock(emergencyLockdown, object);

      return {
        ...state,
        emergencyLockdown: _.cloneDeep(newList),
        modified: JSON.stringify(newList) != JSON.stringify(emergencyLockdownBackUp),
      };
    case constants.UNDO_EMERGENCY_LOCKDOWN:
      return {
        ...state,
        authantication: _.cloneDeep(authantication),
        emergencyLockdown: _.cloneDeep(state.emergencyLockdownBackUp),
        modified: false,
      };
    case constants.TO_BE_LOCKED:
      return {
        ...state,
        toBeLock: action.toBeLock,
      };
    case constants.TO_BE_UNLOCKED:
      return {
        ...state,
        toBeUnlock: action.toBeUnlock,
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesEmergencyLockdown;
