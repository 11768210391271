import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { parseErrorMessageInXhr } from "services//apiUtils";


function* fetchMessageTriggersWithChannels(action) {
  const { response, xhr } = yield call(API.fetchMessageTriggers, action);
  if (response) {
    // yield put({ type: constants.FETCH_MESSAGE_TRIGGERS_SUCCEEDED, payload: response });
    yield put({ triggers: response, type: constants.FETCH_UTILITY_CHANNELS });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_MESSAGE_TRIGGERS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* fetchUtilityChannels(action) {
  const { response, xhr } = yield call(API.fetchUtilityChannels);
  if (response) {
    yield put({ channels: response, triggers: action.triggers, type: constants.FETCH_ALL_CHANNEL_PRIORITY_TRIGGER });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_MESSAGE_TRIGGERS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* fetchChannelPriorityTrigger(action) {
  const { response, xhr } = yield call(API.fetchChannelPriorityTrigger);
  if (response) {
    yield put({
      channels: action.channels,
      priority: response,
      triggers: action.triggers,
      type: constants.FETCH_MESSAGE_TRIGGERS_CHANNELS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_MESSAGE_TRIGGERS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* saveChannelPriority(action) {
  const { response, xhr } = yield call(API.saveChannelPriority, action.priority);
  if (response) {
    yield put({ type: constants.FETCH_MESSAGE_TRIGGERS_CHANNELS });
  } else {
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

export default function* channelUtilitySaga() {
  yield takeLatest(constants.FETCH_MESSAGE_TRIGGERS_CHANNELS, fetchMessageTriggersWithChannels);
  yield takeLatest(constants.FETCH_UTILITY_CHANNELS, fetchUtilityChannels);
  yield takeLatest(constants.FETCH_ALL_CHANNEL_PRIORITY_TRIGGER, fetchChannelPriorityTrigger);
  yield takeLatest(constants.SAVE_CHANNEL_UTILITY, saveChannelPriority);
}
