import { put, call, fork, select, takeLatest } from "redux-saga/effects";

import actionTypes from "../../constants";
import { renderGenericErrorToastMessage } from "../constants";

import i18n from "i18n";
import * as API from "services/customerManager";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* getPerformanceSummary(action) {
  try {
    const { response, xhr } = yield call(API.getPerformanceSummary, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_PERFORMANCE_SUMMARY_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_PERFORMANCE_SUMMARY_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_PERFORMANCE_SUMMARY_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getPerformance(action) {
  try {
    const { response, xhr } = yield call(API.getPerformance, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_PERFORMANCE_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_PERFORMANCE_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_PERFORMANCE_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

export default function* customerManagerPerformanceSaga() {
  yield takeLatest(actionTypes.FETCH_PERFORMANCE_SUMMARY, getPerformanceSummary);
  yield takeLatest(actionTypes.FETCH_PERFORMANCE, getPerformance);
}
