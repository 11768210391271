import {
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_SUCCEEDED,
  UPDATE_OPERATOR_FAILED,
  SET_OPERATOR_FORM,
  SET_ORIGINAL_FORM,
  CLEAR_OPERATOR_FORM,
  RESET_OPERATOR_FORM_MODIFIED,
  RESET_MODIFIED,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCEEDED,
  UPDATE_PASSWORD_FAILED,
  SET_RENDERED_VALUES,
  RESET_NEW_OPERATOR_PASSWORD,
  RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED,
  RESET_NEW_OPERATOR_PASSWORD_FAILED,
  RESET_NEW_PASSWORD_VARIABLE,
} from "./actions";

const initialState = {
  groupid: "",
  hideModalMessage: true,
  isChangingPassword: false,
  isChangingPasswordFailed: false,
  isResetNewOperatorPassword: false,

  logUsername: "",

  modified: false,
  operatorDetails: {
    brandId: "",
    currencyId: "",
    email: "",
    firstName: "",
    groupId: "",
    languageId: "",
    lastName: "",
    mobile: "",
    password: "",
    passwordExpiryDate: null,
    priceFormatId: "",
    securityLevel: 0,
    statusId: "",
    username: "",
  },
  originalOperatorDetails: {
    brandId: "",
    currencyId: "",
    email: "",
    firstName: "",
    groupId: "",
    languageId: "",
    lastName: "",
    mobile: "",
    password: "",
    passwordExpiryDate: null,
    priceFormatId: "",
    securityLevel: 0,
    statusId: "",
    username: "",
  },
  renderedValues: {
    brandId: "",
    currencyId: "",
    email: "",
    firstName: "",
    groupId: "",
    languageId: "",
    lastName: "",
    mobile: "",
    password: "",
    passwordExpiryDate: null,
    priceFormatId: "",
    securityLevel: 0,
    statusId: "",
    username: "",
  },
  showLoader: true,
  updateOperatorStatus: "",
};

const operatorDetailsForm = (state = initialState, action) => {
  let operator = action.operatorDetails;
  switch (action.type) {
    case SET_OPERATOR_FORM:
      const hasChanged = JSON.stringify(state.originalOperatorDetails) !== JSON.stringify(action.formDetails);

      return {
        ...state,
        modified: hasChanged,
        operatorDetails: {
          ...action.formDetails,
          email: action.formDetails.email || "",
        },
      };
    case SET_RENDERED_VALUES:
      return {
        ...state,
        renderedValues: {
          ...operator,
        },
      };
    case SET_ORIGINAL_FORM:
      if (operator.details) {
        operator = operator.details;
      }

      return {
        ...state,
        operatorDetails: {
          brandId: operator.brandId,
          currencyId: operator.currencyId,
          email: operator.email || "",
          firstName: operator.firstName || "",
          groupId: operator.operatorGroupId,
          languageId: operator.languageId,
          lastName: operator.lastName || "",
          mobile: operator.mobile || "",
          password: operator.password || "",
          passwordExpiryDate: operator.expiryDate,
          priceFormatId: operator.priceFormatId,
          securityLevel: operator.securityLevel,
          statusId: operator.statusId,
          username: operator.userName || operator.username,
        },
        originalOperatorDetails: {
          brandId: operator.brandId,
          currencyId: operator.currencyId,
          email: operator.email || "",
          firstName: operator.firstName || "",
          groupId: operator.operatorGroupId || operator.groupId,
          languageId: operator.languageId,
          lastName: operator.lastName || "",
          mobile: operator.mobile || "",
          password: operator.password || "",
          passwordExpiryDate: operator.expiryDate,
          priceFormatId: operator.priceFormatId,
          securityLevel: operator.securityLevel,
          statusId: operator.statusId,
          username: operator.userName || operator.username,
        },
        renderedValues: {
          brandId: operator.brandId,
          currencyId: operator.currencyId,
          email: operator.email || "",
          firstName: operator.firstName || "",
          groupId: operator.operatorGroupId,
          languageId: operator.languageId,
          lastName: operator.lastName || "",
          mobile: operator.mobile || "",
          password: operator.password || "",
          passwordExpiryDate: operator.expiryDate,
          priceFormatId: operator.priceFormatId,
          securityLevel: operator.securityLevel,
          statusId: operator.statusId,
          username: operator.userName || operator.username,
        },
      };
    case UPDATE_OPERATOR:
      return {
        ...state,
        modified: true,
        operatorDetails: {
          ...state.operatorDetails,
          ...action.formDetails,
        },
        updateOperatorStatus: "LOADING",
      };
    case UPDATE_OPERATOR_SUCCEEDED:
      return {
        ...state,
        modified: false,
        operatorDetails: {
          ...state.operatorDetails,
          brandId: state.operatorDetails.brandId,
          currencyId: state.operatorDetails.currencyId,
          email: state.operatorDetails.email,
          firstName: state.operatorDetails.firstName,
          groupId: state.operatorDetails.groupId,
          languageId: state.operatorDetails.languageId,
          lastName: state.operatorDetails.lastName,
          mobile: state.operatorDetails.mobile,
          priceFormatId: state.operatorDetails.priceFormatId,
          securityLevel: state.operatorDetails.securityLevel,
          statusId: state.operatorDetails.statusId,
        },
        originalOperatorDetails: {
          brandId: state.operatorDetails.brandId,
          currencyId: action.operatorDetails.currencyId,
          email: action.operatorDetails.email || "",
          firstName: action.operatorDetails.firstName || "",
          groupId: action.operatorDetails.operatorGroupId,
          languageId: action.operatorDetails.languageId,
          lastName: action.operatorDetails.lastName || "",
          mobile: action.operatorDetails.mobile || "",
          password: action.operatorDetails.password || "",
          priceFormatId: action.operatorDetails.priceFormatId,
          securityLevel: action.operatorDetails.securityLevel,
          statusId: action.operatorDetails.statusId,
          username: action.operatorDetails.userName,
        },
        updateOperatorStatus: "SUCCESS",
      };
    case UPDATE_OPERATOR_FAILED:
      return {
        ...state,
        modified: true,
        updateOperatorStatus: "FAILED",
      };
    case CLEAR_OPERATOR_FORM:
      return initialState;
    case RESET_OPERATOR_FORM_MODIFIED:
      return {
        ...state,
        modified: false,
        operatorDetails: { ...state.originalOperatorDetails },
        renderedValues: { ...state.originalOperatorDetails },
        updateOperatorStatus: "",
      };
    case RESET_MODIFIED:
      return {
        ...state,
        modified: false,
      };

    case UPDATE_PASSWORD:
      return {
        ...state,
        isChangingPassword: true,
      };
    case UPDATE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        isChangingPassword: false,
        isChangingPasswordFailed: false,
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        isChangingPassword: false,
        isChangingPasswordFailed: true,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        isChangingPassword: true,
      };
    case UPDATE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        isChangingPassword: false,
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        isChangingPassword: false,
      };
    case RESET_NEW_OPERATOR_PASSWORD:
      return {
        ...state,
        hideModalMessage: true,
        isResetNewOperatorPassword: false,
        logUsername: action.operatorid,
      };
    case RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED:
      return {
        ...state,
        hideModalMessage: true,
        isResetNewOperatorPassword: true,
      };
    case RESET_NEW_OPERATOR_PASSWORD_FAILED:
      return {
        ...state,
        hideModalMessage: false,
        isResetNewOperatorPassword: false,
        showLoader: false,
      };
    case RESET_NEW_PASSWORD_VARIABLE:
      return {
        ...state,
        isResetNewOperatorPassword: false,
        showLoader: false,
      };

    default:
      return state;
  }
};

export default operatorDetailsForm;
