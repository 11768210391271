import _ from "lodash";

import mainConst from "../../App/constants";

import constants from "./constants";

const initialState = {
  isCreatingPromotionalGroupFilters: false,
  isCreatingPromotionalGroupFiltersFailed: false,
  isDeletingPromotionalGroupDetail: false,
  isFetchMemberFailed: false,
  isFetchingMember: false,
  isFetchingPromotionalGroupFilters: false,
  isFetchingPromotionalGroupFiltersFailed: false,
  isFetchingPromotionalGroups: false,
  isFetchingPromotionalGroupsFailed: false,
  isImportingMember: false,
  isImportingMemberFailed: false,
  isUpdatingPromotionalGroupFilters: false,
  isUpdatingPromotionalGroupFiltersFailed: false,
  memberCount: {
    membersPerPage: 0,
    totalMembers: 0,
    totalPages: 0,
  },
  memberList: [],
  onEditPromotionalGroup: {},
  promotionalGroup: {},
  promotionalGroupFilters: [],
  promotionalGroupFiltersObject: {},
  promotionalGroupMap: [],
  promotionalGroups: [],
};

const promotionalGroup = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROMOTIONAL_GROUPS:
      return {
        ...state,
        isFetchingPromotionalGroups: true,
        isFetchingPromotionalGroupsFailed: false,
      };
    case constants.FETCH_PROMOTIONAL_GROUPS_SUCCEEDED:
      const promoGroupMap = {};
      _.each(action.promotionalGroups, (pg) => {
        promoGroupMap[pg.id] = pg;
      });

      return {
        ...state,
        isFetchingPromotionalGroups: false,
        isFetchingPromotionalGroupsFailed: false,
        promotionalGroupMap: promoGroupMap,
        promotionalGroups: _.sortBy(action.promotionalGroups, (promo) => promo.name.toLowerCase()),
      };
    case constants.FETCH_PROMOTIONAL_GROUPS_FAILED:
      return {
        ...state,
        isFetchingPromotionalGroups: false,
        isFetchingPromotionalGroupsFailed: true,
      };
    case constants.FETCH_PROMOTIONAL_GROUP_FILTERS:
      return {
        ...state,
        isFetchingPromotionalGroupFilters: true,
        isFetchingPromotionalGroupFiltersFailed: false,
      };
    case constants.FETCH_PROMOTIONAL_GROUP_FILTERS_SUCCEEDED:
      if (true) {
        let newObject = {};
        action.promotionalGroupFilters.forEach((p) => {
          newObject = {
            ...newObject,
            [p.id]: p,
          };
        });

        return {
          ...state,
          isFetchingPromotionalGroupFilters: false,
          isFetchingPromotionalGroupFiltersFailed: false,
          promotionalGroupFilters: action.promotionalGroupFilters,
          promotionalGroupFiltersObject: newObject,
        };
      }

      return {
        ...state,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_FILTERS_FAILED:
      return {
        ...state,
        isFetchingPromotionalGroupFilters: false,
        isFetchingPromotionalGroupFiltersFailed: true,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_DETAILS:
      return {
        ...state,
        isFetchingPromotionalGroups: true,
        isFetchingPromotionalGroupsFailed: false,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchingPromotionalGroups: false,
        isFetchingPromotionalGroupsFailed: false,
        promotionalGroup: action.promotionalGroup,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_DETAILS_FAILED:
      return {
        ...state,
        isFetchingPromotionalGroups: false,
        isFetchingPromotionalGroupsFailed: true,
      };

    case constants.RESET_PROMOTIONAL_GROUP_DETAIL_TO_EMPTY:
      return {
        ...state,
        promotionalGroup: {},
      };

    case constants.RESET_PROMOTIONAL_GROUPS:
      return {
        ...state,
        promotionalGroups: [],
      };

    case constants.UPDATE_PROMOTIONAL_GROUP_ITEM:
      return {
        ...state,
        onEditPromotionalGroup: {
          ...action.item,
          retired: state.onEditPromotionalGroup.retired || action.item.retired,
        },
      };

    case constants.RESET_PROMOTIONAL_GROUP_ITEM:
      return {
        ...state,
        onEditPromotionalGroup: {},
      };

    case constants.SET_ITEM_RETIRED_STATUS:
      if (true) {
        let itemOnEdit = {};
        if (!_.isEmpty(Object.keys(state.onEditPromotionalGroup))) {
          itemOnEdit = {
            ...state.onEditPromotionalGroup,
          };
        } else {
          itemOnEdit = {
            ...state.promotionalGroup,
          };
        }

        const newState = {
          ...state,
          onEditPromotionalGroup: {
            ...itemOnEdit,
            retired: action.status,
          },
        };

        return newState;
      }

      return {
        ...state,
        onEditPromotionalGroup: {
          ...state.onEditPromotionalGroup,
          retired: action.status,
        },
      };

    case constants.PUT_PROMOTIONAL_GROUP:
      return {
        ...state,
        isUpdatingPromotionalGroups: true,
        isUpdatingPromotionalGroupsFailed: false,
      };

    case constants.PUT_PROMOTIONAL_GROUP_SUCCEEDED:
      return {
        ...state,
        isUpdatingPromotionalGroups: false,
        isUpdatingPromotionalGroupsFailed: false,
        promotionalGroup: action.promotionalGroup,
      };

    case constants.PUT_PROMOTIONAL_GROUP_FAILED:
      return {
        ...state,
        isUpdatingPromotionalGroups: false,
        isUpdatingPromotionalGroupsFailed: true,
      };

    case constants.NEW_PROMOTIONAL_GROUP:
      return {
        ...state,
        isCreatingPromotionalGroups: true,
        isCreatingPromotionalGroupsFailed: false,
      };

    case constants.NEW_PROMOTIONAL_GROUP_SUCCEEDED:
      return {
        ...state,
        isCreatingPromotionalGroups: false,
        isCreatingPromotionalGroupsFailed: false,
        promotionalGroup: action.promotionalGroup,
      };

    case constants.NEW_PROMOTIONAL_GROUP_FAILED:
      return {
        ...state,
        isCreatingPromotionalGroups: false,
        isCreatingPromotionalGroupsFailed: true,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS:
      return {
        ...state,
        isFetchingMember: true,
        memberList: [],
      };
    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS_SUCCEEDED:
      return {
        ...state,
        isFetchingMember: false,
        memberList: action.promotionalGroupMembers,
      };
    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS_FAILED:
      return {
        ...state,
        isFetchMemberFailed: true,
      };

    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT:
      return {
        ...state,
        isFetchingMember: true,
        memberList: [],
      };
    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_SUCCEEDED:
      return {
        ...state,
        isFetchingMember: false,
        memberCount: action.memberCount,
      };
    case constants.FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_FAILED:
      return {
        ...state,
        isFetchMemberFailed: true,
      };

    case constants.RESET_FETCH_MEMBER_FAILED_ERROR:
      return {
        ...state,
        isFetchMemberFailed: false,
      };

    case constants.DELETE_PROMOTIONAL_GROUP_DETAILS:
      return {
        ...state,
        isDeletingPromotionalGroupDetail: true,
      };

    case constants.DELETE_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED:
      return {
        ...state,
        isDeletingPromotionalGroupDetail: false,
      };

    case constants.IMPORT_MEMBERS:
      return {
        ...state,
        isImportingMember: true,
      };
    case constants.IMPORT_MEMBERS_SUCCEEDED:
      return {
        ...state,
        isImportingMember: false,
        onEditPromotionalGroup: {
          ...state.onEditPromotionalGroup,
          imported: true,
          locked: true,
        },
        promotionalGroup: {
          ...state.promotionalGroup,
          imported: true,
          locked: true,
        },
      };
    case constants.IMPORT_MEMBERS_FAILED:
      return {
        ...state,
        isImportingMember: false,
        isImportingMemberFailed: true,
      };
    case mainConst.SET_BM_MODULE_EDIT_STATE: {
      const promotionalGroup = _.cloneDeep(state.promotionalGroup);
      promotionalGroup["name"] =
        typeof action.state === "object" && action.state.state && action.state.form == "promotional-groups"
          ? "New Promotional Group"
          : promotionalGroup["name"];

      return {
        ...state,
        promotionalGroup,
      };
    }
    default:
      return { ...state };
  }
};

export default promotionalGroup;
