import { createSlice } from "@reduxjs/toolkit";

export const betReferralOperatorStatsInitialState = {
  accepted: 0, // accepted by me during this session
  acceptedBetslipIds: [], // accepted by me during this session
  claimedBetslipIds: [], // claimed (by anyone)
  knownBetslipIds: [], // betslips seen during this session (traded by me or not)
  missed: 0, // missed - seen but no one picked them up
  myBetslipIds: [], // my trades (whether I accepted them, rejected them, or timed out)
  rejected: 0, // rejected by me during this session
  rejectedBetslipIds: [], // rejected by me during this session
  timedOut: 0, // betslips I traded but failed to accept or reject in time
  timedOutBetslipIds: [], // betslips I traded but failed to accept or reject in time
  total: 0, // betslips seen during this session (traded by me or not)
  traded: 0, //  my trades (whether I accepted them, rejected them, or timed out)
};

export const getBetReferralInitialState = ({
  connected = false, // whether the WS achieved a connection
  operatorStats = betReferralOperatorStatsInitialState, // the stats for this operator session...
  subscribed = false, // whether we achieved a successful subscription
  subscribedFilter = { autoOpen: false, origins: [], sports: [] }, // parameters to subscribe for (origins, sports...), if any
  subscriptionRequested = false, // whether the user is active and should be subscribed (i.e. he has reque
}) => ({
  betQueue: {},
  connected,
  operatorStats,
  subscribed,
  subscribedFilter,
  subscriptionRequested,
});

const betReferralSlice = createSlice({
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
  },
  initialState: getBetReferralInitialState({}),

  name: "betReferral",
  // reducers actions
  reducers: {
    acceptTrade(state, action) {
      const betslipId = action.payload.betslipId;

      const updatedAcceptedBetslipIds = new Set([...state.operatorStats.acceptedBetslipIds]);
      updatedAcceptedBetslipIds.add(betslipId);
      state.operatorStats.acceptedBetslipIds = [...updatedAcceptedBetslipIds];
      state.operatorStats.accepted = updatedAcceptedBetslipIds.size;
    },
    addBetToQueue(state, action) {
      const betToQueue = action.payload.betToQueue;
      state.betQueue.push(betToQueue);
    },
    clearAll(state) {
      state.betQueue = {};
    },
    connect(state) {
      state.connected = true;
    },
    disconnect(state) {
      state.connected = false;
      state.betQueue = {};
    },
    rejectTrade(state, action) {
      const betslipId = action.payload.betslipId;

      const updatedRejectedBetslipIds = new Set([...state.operatorStats.rejectedBetslipIds]);
      updatedRejectedBetslipIds.add(betslipId);
      state.operatorStats.rejectedBetslipIds = [...updatedRejectedBetslipIds];
      state.operatorStats.rejected = updatedRejectedBetslipIds.size;
    },
    requestSubscription(state) {
      state.subscriptionRequested = true;

      state.operatorStats = {
        // reset stats
        accepted: 0,
        acceptedBetslipIds: [],
        claimedBetslipIds: [],
        knownBetslipIds: [],
        missed: 0,
        myBetslipIds: [],
        rejected: 0,
        rejectedBetslipIds: [],
        timedOut: 0,
        timedOutBetslipIds: [],
        total: 0,
        traded: 0,
      };
    },
    setSubscribedFilter(state, action) {
      state.subscribedFilter = action.payload.subscribedFilter;
    },
    subscribe(state) {
      state.subscribed = true;
    },
    trackOperatorStats(state, action) {
      const activeBetslipIds = action.payload.activeBetslipIds;
      const activeClaimedBetslipIds = action.payload.activeClaimedBetslipIds;
      const myBetslipIds = action.payload.myBetslipIds;

      const updatedKnownBetslipIds = new Set([...state.operatorStats.knownBetslipIds]);
      activeBetslipIds.forEach((item) => updatedKnownBetslipIds.add(item));
      state.operatorStats.knownBetslipIds = [...updatedKnownBetslipIds];
      state.operatorStats.total = updatedKnownBetslipIds.size;

      const updatedMyBetslipIds = new Set([...state.operatorStats.myBetslipIds]);
      myBetslipIds.forEach((item) => updatedMyBetslipIds.add(item));
      state.operatorStats.myBetslipIds = [...updatedMyBetslipIds];
      state.operatorStats.traded = updatedMyBetslipIds.size;

      const updatedClaimedBetslipIds = new Set([...state.operatorStats.claimedBetslipIds]);
      activeClaimedBetslipIds.forEach((item) => updatedClaimedBetslipIds.add(item));
      state.operatorStats.claimedBetslipIds = [...updatedClaimedBetslipIds];

      //  TOTAL - active - finished claimed (i.e. not active unclaimed)
      state.operatorStats.missed =
        updatedKnownBetslipIds.size -
        activeBetslipIds.length -
        (updatedClaimedBetslipIds.size - activeClaimedBetslipIds.length);
    },
    unrequestSubscription(state) {
      state.subscriptionRequested = false;
    },
    unsubscribe(state) {
      state.subscriptionRequested = false; // this should be the case, but confirm it.
      state.subscribed = false;
      state.betQueue = {};
    },
    updateBetQueue(state, action) {
      state.betQueue = action.payload.betQueue;
    },
  },
});

const { actions, reducer } = betReferralSlice;
export const {
  acceptTrade,
  clearAll,
  connect,
  disconnect,
  rejectTrade,
  requestSubscription,
  setSubscribedFilter,
  subscribe,
  trackOperatorStats,
  unrequestSubscription,
  unsubscribe,
  updateBetQueue,
} = actions;
export default reducer;
