const constants = {
  FETCH_LIABILITY_INDICATORS: "LI::FETCH_LIABILITY_INDICATORS",
  FETCH_LIABILITY_INDICATORS_FAILED: "LI::FETCH_LIABILITY_INDICATORS_FAILED",
  FETCH_LIABILITY_INDICATORS_SUCCEEDED: "LI::FETCH_LIABILITY_INDICATORS_SUCCEEDED",

  ON_EXIT_LIABILITY_INDICATORS_PAGE: "LI:ON_EXIT_LIABILITY_INDICATORS_PAGE",
  ON_REMOVE_LIABILITY_INDICATOR_FAILED: "WL::ON_REMOVE_LIABILITY_INDICATOR_FAILED",

  ON_REMOVE_LIABILITY_INDICATOR_SUCCEEDED: "WL::ON_REMOVE_LIABILITY_INDICATOR_SUCCEEDED",

  REMOVE_LIABILITY_INDICATOR: "WL::REMOVE_LIABILITY_INDICATOR",
  SAVE_LIABILITY_INDICATORS: "LI:SAVE_LIABILITY_INDICATORS",
  SAVE_LIABILITY_INDICATORS_FAILED: "LI::SAVE_LIABILITY_INDICATORS_FAILED",

  SAVE_LIABILITY_INDICATORS_SUCCEEDED: "LI::SAVE_LIABILITY_INDICATORS_SUCCEEDED",
  SELECT_ROW_TO_EDIT: "LI::SELECT_ROW_TO_EDIT",
  UPDATE_FIELD: "LI::UPDATE_FIELD",
};

export default constants;
