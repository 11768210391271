import { makeIterable } from "utils";

export const createReport = ({ type = "group", level = 0, parentKey = "", items = [], key, desc }) => {
  const report = {
    desc,
    key,
    level,
    parentKey,
    reportList: items,
    selected: false,
    type,
  };
  if (type === "group") {
    report.isExpanded = false;
  }

  return report;
};

export const generateReportsMap = (reports) => {
  const reportsMap = {};
  [...makeIterable(reports, true)].forEach((report) => {
    if (report.key.indexOf("/") >= 0) {
      const [parentKey, subKey] = report.key.split("/");
      if (reportsMap[parentKey]) {
        reportsMap[parentKey]["reportList"].push(report.key);
      } else {
        reportsMap[parentKey] = createReport({
          desc: parentKey,
          items: [report.key],
          key: parentKey,
        });
      }

      if (!reportsMap[report.key]) {
        reportsMap[report.key] = createReport({
          desc: subKey,
          items: [...report.value],
          key: report.key,
          level: 1,
          parentKey,
        });
      } else {
        reportsMap[report.key]["reportList"] = [...report.value]
          .reduce((accu, val) => {
            if (!accu.includes(val)) {
              accu = [...accu, val];
            }

            return accu;
          }, reportsMap[report.key]["reportList"])
          .sort();
      }

      report.value.map((key) => {
        reportsMap[key] = createReport({
          desc: key,
          key,
          parentKey: report.key,
          type: "report",
        });
      });
    } else {
      if (!reportsMap[report.key]) {
        reportsMap[report.key] = createReport({
          desc: report.key,
          items: [...report.value],
          key: report.key,
        });
      } else {
        reportsMap[report.key]["reportList"] = [...reportsMap[report.key]["reportList"], ...report.value];
      }

      report.value.map((key) => {
        reportsMap[key] = createReport({
          desc: key,
          key,
          parentKey: report.key,
          type: "report",
        });
      });
    }
  });

  return sortReports(reportsMap);
};

export const sortReports = (reportsMap) => {
  // sort assigned/unassigned array by type first, then by key
  for (const report of makeIterable(reportsMap)) {
    if (report.type === "group") {
      report.reportList.sort((a, b) => {
        if (reportsMap[a].type > reportsMap[b].type) {
          return -1;
        }
        if (reportsMap[a].type < reportsMap[b].type) {
          return 1;
        }
        if (reportsMap[a].type === reportsMap[b].type) {
          if (reportsMap[a].key > reportsMap[b].key) {
            return 1;
          }
          if (reportsMap[a].key < reportsMap[b].key) {
            return -1;
          }

          return 0;
        }

        return 0;
      });
    }
  }

  return reportsMap;
};

export const generateReportsList = ({ reportsMap }) =>
  [...makeIterable(reportsMap)]
    .filter((report) => report.type === "group" && report.level === 0 && !!report.reportList.length)
    .map((report) => report.key)
    .sort();

export const getActiveFolder = ({ reportsMap }) => [...makeIterable(reportsMap, true)].filter((d) => d.value.selected);

export const sortFolderAndReport = (reportsMap) => {
  const newReportsMap = {};
  [...makeIterable(reportsMap, true)].map((list) => {
    if (list.value.reportList.length > 0) {
      const folders = list.value.reportList.filter((r) => r.includes("/"));
      const reports = list.value.reportList.filter((r) => !r.includes("/"));
      const newReportList = [...folders, ...reports];

      newReportsMap[list.key] = { ...list.value, reportList: newReportList };
    } else {
      newReportsMap[list.key] = list.value;
    }
  });

  return newReportsMap;
};

/**
 * Generate param from testing date range
 * @param {array} parameters - Parameters from the API.
 * @return {object} - Parameter key to be use on date range filter
 */
export const generateDateParams = (parameters) => {
  let newParameters = parameters.filter((p) => !p.hidden).map((p) => p.name);
  let params = [...newParameters];
  newParameters = newParameters
    .map((p) => p.toLowerCase())
    .filter((p) => p.includes("date"))
    .filter((p) => !p.includes("hh_mm_ss"));
  newParameters = newParameters
    .map((p) => {
      if (p.includes("to") || p.includes("end")) {
        return { to: p };
      }
      if (p.includes("from") || p.includes("start")) {
        return { from: p };
      }

      return { [p]: p };
    })
    .reduce((a, b) => ({ ...a, ...b }), {});
  params = params.map((p) => ({ [p.toLowerCase()]: p })).reduce((a, b) => ({ ...a, ...b }), {});

  return { from: params[newParameters.from], to: params[newParameters.to] };
};
