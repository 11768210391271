import actionTypes from "../../constants";

const initialState = {
  callCentreComment: null,
  comments: null,
  commentsLoading: false,
  openModal: false,
  selectedCommentId: null,
};

const customerManagerComments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMMENTS:
      return {
        ...state,
        // comments: null,
        commentsLoading: true,
      };
    case actionTypes.FETCH_COMMENTS_SUCCEEDED:
    case actionTypes.UPDATE_CC_COMMENT_SUCCEEDED:
      return {
        ...state,
        callCentreComment: action.callCentreComment,
        comments: action.result,
        commentsLoading: false,
      };
    case actionTypes.FETCH_COMMENTS_FAILED:
      return {
        ...state,
        commentsLoading: false,
      };
    case actionTypes.OPEN_MODAL_COMMENT:
    case actionTypes.CLOSE_MODAL_COMMENT:
      return {
        ...state,
        openModal: action.show,
      };
    case actionTypes.SELECT_COMMENT_ID:
      return {
        ...state,
        selectedCommentId: action.id,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    default:
      return { ...state };
  }
};

export default customerManagerComments;
