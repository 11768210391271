import moment from "moment";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchEmergencyLockdown(action) {
  const { response, xhr } = yield call(API.fetchEmergencyLockdown);
  if (response) {
    yield put({ response, type: constants.FETCH_EMERGENCY_LOCKDOWN_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EMERGENCY_LOCKDOWN_FAILED });
  }
}

function* updateEmergencyLockdown(action) {
  const { response, xhr } = yield call(API.updateEmergencyLockdown, action.data);
  if (response) {
    yield put({ type: constants.FETCH_EMERGENCY_LOCKDOWN });
    toastr.add({ message: i18n.t("Utilities.Emergency Lock Down Updated Successfully"), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

export default function* utilitiesEmergencyLockdown() {
  yield takeLatest(constants.FETCH_EMERGENCY_LOCKDOWN, fetchEmergencyLockdown);
  yield takeLatest(constants.UPDATE_EMERGENCY_LOCKDOWN, updateEmergencyLockdown);
}
