import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEPTXhr = null;
let headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchEPT(sportId, parameters, activeAppId) {
  let url = `/bo/service/eventpaths`;
  if (sportId) {
    url = `${url}/${sportId}/tree`;
  }
  url = `${url}?${parameters}`;
  if (activeAppId === 19) {
    headers = { ...headers, "X-P8-Datasource": "cache" };
  }

  return performHttpCall(fetchEPTXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchRestrictionEPT(code) {
  const url = `/bo/service/eventpaths/${code}`;

  return performHttpCall(fetchEPTXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventMarkets(eventId, parameters) {
  // Sanitize parameters, do not send garbage to the API...
  const pars = parameters.split(/[&;]/g);
  const valid_params = ["fromDate", "toDate", "marketStatusIds", "eventId", "includeLive"];

  // reverse iteration as may be destructive
  for (let i = pars.length; i-- > 0; ) {
    // idiom for string.startsWith
    if (!valid_params.includes(pars[i].split("=")[0])) {
      pars.splice(i, 1); // remove anything unsupported
    }
  }

  const sanitized_params = pars.length > 0 ? pars.join("&") : "";

  const url = `/bo/service/eventpaths/event/${eventId}/markets?${sanitized_params}`;

  return performHttpCall(fetchEPTXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveNewPathsOrder(pathsOrder) {
  const url = `/bo/eventpaths/updateprintorders`;

  return performHttpCall(fetchEPTXhr, httpMethods.HTTP_PUT, url, pathsOrder)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
