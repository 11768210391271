import constants from "./constants";

export function fetchMarkets(eventId) {
  return {
    eventId,
    type: constants.FETCH_MARKETS,
  };
}

export function fetchMarket(marketId) {
  return {
    marketId,
    type: constants.FETCH_MARKET,
  };
}

export function reFetchMarket(marketId) {
  return {
    marketId,
    type: constants.REFETCH_MARKET,
  };
}

export function updateMarketDetails(marketId, data) {
  return {
    data,
    marketId,
    type: constants.UPDATE_MARKET_DETAILS,
  };
}

export function updateMarketOutcomes(marketId, data) {
  return {
    data,
    marketId,
    type: constants.UPDATE_MARKET_OUTCOMES,
  };
}

export function setNewMarketOutcomes(outcomes) {
  return {
    outcomes,
    type: constants.SET_NEW_MARKET_OUTCOMES,
  };
}

export function deleteMarket(marketId) {
  return {
    marketId,
    type: constants.DELETE_MARKET,
  };
}
export function deleteMarkets(marketIds) {
  return {
    marketIds,
    type: constants.DELETE_MARKETS,
  };
}

export function setUpdatedMarketBook(book, hasSPBook) {
  return {
    book,
    hasSPBook,
    type: constants.SET_UPDATED_MARKET_BOOK,
  };
}
export function clearUpdatedMarketBook() {
  return {
    type: constants.CLEAR_UPDATED_MARKET_BOOK,
  };
}
export function updateMarketBooks(marketId, data) {
  return {
    data,
    marketId,
    type: constants.UPDATE_MARKET_BOOKS,
  };
}
export function fetchMarketBooks(marketId) {
  return {
    marketId,
    type: constants.FETCH_MARKET_BOOKS,
  };
}
export function fetchMarketTypes(eventId) {
  return {
    eventId,
    type: constants.FETCH_MARKET_TYPES,
  };
}
export function fetchMarketPeriods(eventId) {
  return {
    eventId,
    type: constants.FETCH_MARKET_PERIODS,
  };
}
export function clearMarketTypes() {
  return {
    type: constants.CLEAR_MARKET_TYPES,
  };
}
export function fetchMarketPlayers(players, eventId) {
  return {
    eventId,
    players,
    type: constants.FETCH_MARKET_PLAYERS,
  };
}
export function createNewMarkets(eventId, data, hideOutcomesOnCreate) {
  return {
    data,
    eventId,
    hideOutcomesOnCreate,
    type: constants.CREATE_NEW_MARKETS,
  };
}
export function updateNewMarketPayload(id, data) {
  return {
    data,
    id,
    type: constants.UPDATE_NEW_MARKET_PAYLOAD,
  };
}
export function setHideOutcomesOnCreateOption(hideOutcomesOnCreate) {
  return {
    hideOutcomesOnCreate,
    type: constants.SET_HIDE_OUTCOMES_ON_CREATE_OPTION,
  };
}
export function resetNewMarketPayload() {
  return {
    type: constants.RESET_NEW_MARKET_PAYLOAD,
  };
}
export function updateNewMarketFilters(newMarketFilters) {
  return {
    newMarketFilters,
    type: constants.UPDATE_NEW_MARKET_FILTERS,
  };
}
export function resetNewMarketFilters() {
  return {
    type: constants.RESET_NEW_MARKET_FILTERS,
  };
}

export function fetchGameResultPeriods(eventId) {
  return {
    eventId,
    type: constants.FETCH_GAME_RESULT_PERIODS,
  };
}

export function fetchGameResultMarketTypes(eventId) {
  return {
    eventId,
    type: constants.FETCH_GAME_RESULT_MARKET_TYPES,
  };
}

export function updateGameResultsFilters(gameResultsFilters) {
  return {
    gameResultsFilters,
    type: constants.UPDATE_GAME_RESULT_FILTERS,
  };
}
