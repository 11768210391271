import actionTypes from "../constants";

const initialState = {
  abandonMarket: false,
  customEptDate: false,
  cutOffAndAutoOpenMarket: false,
  editMarket: false,
  editMarketMarketFeedInfo: false,
  marketFeedInfo: false,
  marketStateDetails: false,
  openModalsCount: 0,
  outcomeWagerLimits: false,
  riskTransactionDetails: false,
  saveCancelConfirmation: false,
  uofDisclaimer: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return { ...state, [action.modalName]: true, openModalsCount: state.openModalsCount + 1 };
    case actionTypes.CLOSE_MODAL:
      return { ...state, [action.modalName]: false, openModalsCount: state.openModalsCount - 1 };
    case actionTypes.CLOSE_ALL_MODALS:
      const newState = {};
      for (const key in state) {
        newState[key] = false;
      }

      return { ...newState, openModalsCount: 0 };
    default:
      return { ...state };
  }
}
