import filterTypes from "constants/filterTypes";
import { getNext7DayText, getLast7DayText, getLastMonthText } from "utils";

const constants = {
  FETCH_INITIAL_JACKPOTBET_DATA: "FETCH_INITIAL_JACKPOTBET_DATA",
  FETCH_INITIAL_JACKPOTBET_DATA_FAILED: "FETCH_INITIAL_JACKPOTBET_DATA_FAILED",
  FETCH_INITIAL_JACKPOTBET_DATA_SUCCEEDED: "FETCH_INITIAL_JACKPOTBET_DATA_SUCCEEDED",
  FETCH_JACKPOT_EVENT: "FETCH_JACKPOT_EVENT",

  FETCH_JACKPOT_EVENT_FAILED: "FETCH_JACKPOT_EVENT_FAILED",
  FETCH_JACKPOT_EVENT_SUCCEEDED: "FETCH_JACKPOT_EVENT_SUCCEEDED",
  FETCH_JACKPOT_MARKET: "FETCH_JACKPOT_MARKET",

  FETCH_JACKPOT_MARKET_FAILED: "FETCH_JACKPOT_MARKET_FAILED",

  FETCH_JACKPOT_MARKET_SUCCEEDED: "FETCH_JACKPOT_MARKET_SUCCEEDED",

  HIDE_NEW_JACKPOT_BET_MODAL: "HIDE_NEW_JACKPOT_BET_MODAL",

  HIDE_WINNER_MODAL: "HIDE_WINNER_MODAL",
  ON_DISABLE_JACKPOT: "ON_DISABLE_JACKPOT",
  ON_DISABLE_JACKPOT_FAILED: "ON_DISABLE_JACKPOT_FAILED",

  ON_DISABLE_JACKPOT_SUCCEEDED: "ON_DISABLE_JACKPOT_SUCCEEDED",
  SELECTED_CURRENCY_STACK_MAP: "SELECTED_CURRENCY_STACK_MAP",
  SELECTED_JACKPOT: "SELECTED_JACKPOT",

  SELECTED_JACKPOT_CONSOLATIONS: "SELECTED_JACKPOT_CONSOLATIONS",
  SET_DATES_FILTER: "EPT::SET_DATES_FILTER",
  SET_TRANS_FUNC: "EPT::SET_TRANS_FUNC",
  SHOW_NEW_JACKPOT_BET_MODAL: "SHOW_NEW_JACKPOT_BET_MODAL",

  SHOW_WINNER_MODAL: "SHOW_WINNER_MODAL",
};

export default constants;

export const DUMMY_ID = -123456789;

const dates = filterTypes.DATES;
const otherDates = filterTypes.OTHER_DATES;

export const availableDates = [
  `${dates.NEXT_7_DAYS} (${getNext7DayText()})`,
  dates.TODAY,
  dates.TOMORROW,
  dates.FUTURE,
  dates.YESTERDAY,
  `${otherDates.LAST_7_DAYS} (${getLast7DayText()})`,
  `${otherDates.LAST_MONTH} (${getLastMonthText()})`,
  dates.CUSTOM,
];

export const permissionsCode = {
  JACKPOTBET_PAYOUT: 1501,
};
