import channelConfig from "../configs/channelConfig";
import actionTypes from "../constants";
import filterTypes from "../constants/filterTypes";
import { loadFromStorage, saveInStorage } from "../utils";

const initialState = {
  activeColumn: null,
  activeColumnSort: filterTypes.SORTS.DEFAULT.value,
  code: null,
  desc: null,
  eventSearchString: "",
  fromDate: null,
  rowHeight: loadFromStorage("GRID_ROW_HEIGHT") || "Default",
  startTimeSort: filterTypes.SORTS.ASC.value,
  toDate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RESULTS_VIEWER_FILTER_CODE:
      return { ...state, code: action.code };
    case actionTypes.SET_RESULTS_VIEWER_FILTER_DESC:
      return { ...state, desc: action.desc };
    case actionTypes.SET_RESULTS_VIEWER_START_TIME_SORT:
      return { ...state, startTimeSort: action.sort };
    case actionTypes.SET_RESULTS_VIEWER_COLUMN_SORT:
      return { ...state, activeColumn: action.column, activeColumnSort: action.sort };
    case actionTypes.ADD_RESULTS_VIEWER_CUSTOM_DATE_FILTER:
      return { ...state, fromDate: action.fromDate, toDate: action.toDate };
    case actionTypes.SET_EVENT_SEARCH_VALUE:
      return { ...state, eventSearchString: action.eventSearchString };
    case actionTypes.RESET_RESULTS_VIEWER_FILTERS:
      return { ...initialState, code: state.code, date: state.date, eventSearchString: "", rowHeight: state.rowHeight };
    case actionTypes.SET_RESULTS_VIEWER_ROW_HEIGHT:
      saveInStorage("GRID_ROW_HEIGHT", action.rowHeight);

      return { ...state, rowHeight: action.rowHeight };
    default:
      return { ...state };
  }
}
