import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import { createTable, modifiyDataForTable, createMarketDate } from "./helper";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchAllSports(action) {
  const { response, xhr } = yield call(API.fetchAllSport);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ALL_SPORTS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_SPORTS_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchPeriodType(action) {
  const { response, xhr } = yield call(API.fetchPeriodType, action.payload);
  if (response) {
    const tableHeader = createTable(response);
    yield put({ tableHeader, type: constants.FETCH_PERIOD_TYPES_SUCCEEDED });
    yield put({ response, type: constants.FETCH_ALL_PERIOD_TYPES });
  } else {
    yield put({ type: constants.FETCH_PERIOD_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchALLPeriodType(action) {
  const { response, xhr } = yield call(API.fetchALLPeriodType);
  const formateTableData = modifiyDataForTable(action.response, response);
  if (formateTableData) {
    yield put({ allPeriod: response, payload: formateTableData, type: constants.FETCH_ALL_PERIOD_TYPES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_PERIOD_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchALLMarketType(action) {
  const { response, xhr } = yield call(API.fetchALLMarketType, action.payload);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ALL_MARKET_TYPES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_MARKET_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchMarketPeriodType(action) {
  const { response, xhr } = yield call(API.fetchMarketPeriodType);
  if (response) {
    yield put({ response, type: constants.FETCH_MARKET_PERIOD_TYPES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_MARKET_PERIOD_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* updateMarketPeriodType(action) {
  const { response, xhr } = yield call(API.updateMarketPeriodType, action.payload);
  if (response) {
    yield put({ type: constants.FETCH_MARKET_PERIOD_TYPES });
  } else {
    yield put({ type: constants.UPDATE_MARKET_PERIOD_TYPES_FAILED });
    toastr.add({ message: i18n.t("Utilities.Error Updating Market!"), type: "ERROR" });
  }
}

function* updatePeriodTypes(action) {
  const { response, xhr } = yield call(API.updatePeriodTypes, action.payload.data);
  if (response) {
    yield put({ payload: action.payload.sport, type: constants.FETCH_PERIOD_TYPES });
  } else {
    yield put({ type: constants.UPDATE_PERIOD_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* updateMarketTypes(action) {
  const { response, xhr } = yield call(API.updateMarketTypes, action.payload.data);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Updated Successfully"), type: "SUCCESS" });
    yield put({ type: constants.UPDATE_MARKET_TYPES_SUCCEEDED });
    yield put({ payload: action.payload.sports, type: constants.FETCH_ALL_MARKET_TYPES });
  } else {
    yield put({ type: constants.UPDATE_MARKET_TYPES_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchAllWagerLimitGroup(action) {
  const { response, xhr } = yield call(API.fetchAllWagerLimitGroup);
  if (response) {
    yield put({ response, type: constants.FETCH_ALL_WAGER_LIMIT_GROUP_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_WAGER_LIMIT_GROUP_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchAllMajorSort(action) {
  const { response, xhr } = yield call(API.fetchAllMajorSort);
  if (response) {
    yield put({ response, type: constants.FETCH_ALL_MAJOR_SORT_SUCCEEDED });
  } else {
    toastr.add({ message: i18n.t("Utilities.Error Fetching Major Sort"), type: "ERROR" });
  }
}

function* updateMajorSort(action) {
  const { response, xhr } = yield call(API.updateMajorSort, action.payload);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Updated Successfully"), type: "SUCCESS" });
    yield put({ type: constants.FETCH_ALL_MAJOR_SORT });
  } else {
    toastr.add({ message: i18n.t("Utilities.Error updating Major Sort"), type: "ERROR" });
  }
}

export default function* utilitiesMarketTypeEnablement() {
  yield takeLatest(constants.FETCH_ALL_SPORTS, fetchAllSports);
  yield takeLatest(constants.FETCH_PERIOD_TYPES, fetchPeriodType);
  yield takeLatest(constants.FETCH_ALL_PERIOD_TYPES, fetchALLPeriodType);
  yield takeLatest(constants.FETCH_ALL_MARKET_TYPES, fetchALLMarketType);
  yield takeLatest(constants.FETCH_MARKET_PERIOD_TYPES, fetchMarketPeriodType);
  yield takeLatest(constants.UPDATE_PERIOD_TYPES, updatePeriodTypes);
  yield takeLatest(constants.UPDATE_MARKET_TYPES, updateMarketTypes);
  yield takeLatest(constants.FETCH_ALL_WAGER_LIMIT_GROUP, fetchAllWagerLimitGroup);
  yield takeLatest(constants.UPDATE_MARKET_PERIOD_TYPES, updateMarketPeriodType);
  yield takeLatest(constants.FETCH_ALL_MAJOR_SORT, fetchAllMajorSort);
  yield takeLatest(constants.UPDATE_MAJOR_SORT_OPTION, updateMajorSort);
}
