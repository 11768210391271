import ecModesConstants from "../constants/eventCreatorModes";

export function fetchEventCreatorMode() {
  return {
    type: ecModesConstants.FETCH_EVENT_CREATOR_MODE,
  };
}

export function setEventCreatorMode(appMode) {
  return {
    appMode,
    type: ecModesConstants.SET_EVENT_CREATOR_MODE,
  };
}
