// apps only available on PHX for now

const appNames = {
  BONUS_MANAGER: "Bonus Manager",
  COMBI_RISK_MANAGER: "Combination Risk Manager",
  CUSTOMER_CHAT: "Customer Chat",
  CUSTOMER_MANAGER: "Customer Manager",
  EVENT_CREATOR: "Event Creator",
  INSTANT_ACTION: "Instant Action",
  JACKPOT_BET_MANAGER: "Jackpot Bet Manager",
  OPERATOR_MANAGER: "Operator Manager",
  PAYMENT_HANDLER: "Payment Handler",
  REPORTS_MANAGER: "Reports Manager",
  RESULTS_VIEWER: "Results Viewer",
  RISK_MANAGER: "Risk Manager",
  SETTINGS_BROWSER: "Settings Browser",
  SETTINGS_VIEWER: "Settings Viewer",
  SHOP_MANAGER: "Shop Manager",
  TRANSLATION_MANAGER: "Translation Manager",
  UTILITIES: "Utilities",
  WAGER_LIMITS: "Wager Limits",
  // AFFILIATE_MANAGER: 'Affiliate Manager',
};

export const appNamesAlias = {
  "Results Verifier": "Results Viewer",
  "SQL Query Viewer": "Reports Manager",
  "Settings Viewer": "Settings Browser",
};

export default appNames;
