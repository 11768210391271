import { put, call, select, takeLatest } from "redux-saga/effects";

import { fetchMatrixData as fetchMatrixDataAction, updateNewMatrixData, updateBetRestrictionKeys } from "./actions";
import actionTypes from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchBetRestrictionKeys(action) {
  const { id } = action;
  const { response } = yield call(API.fetchBetRestrictionKeys, id);
  if (response) {
    const { activeAppId } = yield select((state) => state.apps);
    yield put({ response, type: actionTypes.FETCH_BET_RESTRICTION_KEYS_SUCCEEDED });
    const betType = response[0];
    const param = {
      betRestrictionTypeId: betType.betRestrictionTypeId,
      betTypeGroupId: betType.keys[0].betTypeGroupId,
      eventTypeId: betType.keys[0].eventTypeId,
      transSubTypeId: betType.keys[0].transSubTypeId,
    };
    if (activeAppId === 1) {
      const { pathIdForEvent } = yield select((state) => state.betRestrictions);
      const { activePathId } = yield select((state) => state.sportsTree);
      param.eventPathId = activePathId;
      if (pathIdForEvent) {
        param.eventPathId = pathIdForEvent;
      }
    }
    yield put(fetchMatrixDataAction(param));
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_BET_RESTRICTION_KEYS_FAILED });
  }
}

function* fetchUnusedBetRestrictionKeys(action) {
  const { id } = action;
  const { response } = yield call(API.fetchUnusedBetRestrictionKeys, id);
  if (response) {
    const { newMatrixBetType } = yield select((state) => state.betRestrictions.newBetRestrictionData);
    if (!newMatrixBetType.betRestrictionTypeId) {
      const betType = response[0];
      const selectedType = { ...betType.keys[0], betRestrictionTypeId: betType.betRestrictionTypeId, subTypes: [] };
      const param = {
        betRestrictionTypeId: betType.betRestrictionTypeId,
        betTypeGroupId: betType.keys[0].betTypeGroupId,
        eventTypeId: betType.keys[0].eventTypeId,
        transSubTypeId: betType.keys[0].transSubTypeId,
      };
      yield put(updateNewMatrixData({ newMatrixBetType: param, selectedType }));
    }
    yield put({ response, type: actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS_FAILED });
  }
}

function* fetchSportPeriods(action) {
  const { code } = action;
  const { response } = yield call(API.fetchSportPeriods, code);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_SPORT_PERIODS_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_SPORT_PERIODS_FAILED });
  }
}

function* fetchMarketTypeGroups(action) {
  const { params } = action;
  const { response } = yield call(API.fetchMarketTypeGroups, params);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_MARKET_TYPE_GROUPS_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_MARKET_TYPE_GROUPS_FAILED });
  }
}

function* fetchBetRestrictionsHistory(action) {
  const { betType } = action;
  const { response } = yield call(API.fetchBetRestrictionsHistory, betType);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_BET_RESTRICTIONS_HISTORY_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_BET_RESTRICTIONS_HISTORY_FAILED });
  }
}

function* updateBetRestrictions(action) {
  const { restrictions } = action;
  const { response, xhr } = yield call(API.updateBetRestrictions, restrictions);
  if (response) {
    yield put({ response, type: actionTypes.UPDATE_BET_RESTRICTIONS_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Bet restrictions successfully updated") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.UPDATE_BET_RESTRICTIONS_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to update bet restrictions")}. ${msg}`, type: "ERROR" });
  }
}

function* updateBetRestrictionsHistory(action) {
  const { data } = action;
  const { response, xhr } = yield call(API.updateBetRestrictionsHistory, data);
  if (response) {
    yield put({ data, response, type: actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Bet restrictions history successfully updated") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to update bet restrictions history")}. ${msg}`, type: "ERROR" });
  }
}

function* restoreBetRestrictionsHistory(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.restoreBetRestrictionsHistory, id);
  if (response) {
    yield put({ id, response, type: actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Bet restrictions history successfully re-stored") });
    const { activeBetType } = yield select((state) => state.betRestrictions);
    yield put(fetchMatrixDataAction(activeBetType));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to re-store bet restrictions history")}. ${msg}`, type: "ERROR" });
  }
}

function* addNewBetRestrictions(action) {
  const { betType, newlyAddedRestrictionKey, restrictions } = action;
  const { response, xhr } = yield call(API.addNewBetRestrictions, betType, restrictions);
  if (response) {
    yield put({
      newlyAddedRestrictionKey,
      response,
      restrictions,
      type: actionTypes.ADD_NEW_BET_RESTRICTION_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.ADD_NEW_BET_RESTRICTION_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to add new bet restriction")}. ${msg}`, type: "ERROR" });
  }
}

function* createNewBetRestrictionsMatrix(action) {
  const { betType, newlyAddedRestrictionKey, restrictions } = action;
  const { response, xhr } = yield call(API.addNewBetRestrictions, betType, restrictions);
  if (response) {
    const { betTypes, newBetRestrictionData } = yield select((state) => state.betRestrictions);
    const { selectedType } = newBetRestrictionData;

    const index = betTypes.findIndex((type) => type.betRestrictionTypeId === selectedType.betRestrictionTypeId);
    const restrictionType = { ...betTypes[index] };
    const targetIndex = restrictionType.keys.findIndex(
      (type) =>
        type.betTypeGroupId === selectedType.betTypeGroupId && type.transSubTypeId === selectedType.transSubTypeId,
    );
    if (targetIndex >= 0) {
      // it means it exists already
      const target = { ...restrictionType.keys[targetIndex] };
      // insert the selected type's subtypes into the target's subtypes
      target.subTypes = [...target.subTypes, ...selectedType.subTypes];
      restrictionType.keys = [
        ...restrictionType.keys.slice(0, targetIndex),
        target,
        ...restrictionType.keys.slice(targetIndex + 1),
      ];
    } else {
      // just add the selected type to existing keys
      restrictionType.keys = [...restrictionType.keys, selectedType];
    }
    const newBetTypes = [...betTypes.slice(0, index), restrictionType, ...betTypes.slice(index + 1)];
    yield put(updateBetRestrictionKeys(newBetTypes));
    yield put({
      betType,
      newlyAddedRestrictionKey,
      response,
      restrictions,
      type: actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to add new bet restriction")}. ${msg}`, type: "ERROR" });
  }
}

function* deleteBetRestrictions(action) {
  const { betType, restrictions } = action;
  const { response, xhr } = yield call(API.deleteBetRestrictions, betType, restrictions);
  if (response) {
    yield put({ response, type: actionTypes.DELETE_BET_RESTRICTIONS_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Bet restrictions successfully deleted") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.DELETE_BET_RESTRICTIONS_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete bet restrictions")}. ${msg}`, type: "ERROR" });
  }
}

function* deleteBetRestrictionsHistory(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.deleteBetRestrictionsHistory, id);
  if (response) {
    yield put({ id, response, type: actionTypes.DELETE_BET_RESTRICTIONS_HISTORY_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Bet restrictions history successfully deleted") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: "Error", type: actionTypes.DELETE_BET_RESTRICTIONS_HISTORY_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete bet restrictions history")}. ${msg}`, type: "ERROR" });
  }
}

function* fetchMatrixData(action) {
  const { activeBetType } = action;
  const { response } = yield call(API.fetchMatrixData, activeBetType);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_MATRIX_DATA_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_MATRIX_DATA_FAILED });
  }
}

export default function* eventCreatorPathTreeSaga() {
  yield takeLatest(actionTypes.FETCH_BET_RESTRICTION_KEYS, fetchBetRestrictionKeys);
  yield takeLatest(actionTypes.FETCH_UNUSED_BET_RESTRICTION_KEYS, fetchUnusedBetRestrictionKeys);
  yield takeLatest(actionTypes.FETCH_MATRIX_DATA, fetchMatrixData);
  yield takeLatest(actionTypes.FETCH_SPORT_PERIODS, fetchSportPeriods);
  yield takeLatest(actionTypes.FETCH_MARKET_TYPE_GROUPS, fetchMarketTypeGroups);
  yield takeLatest(actionTypes.FETCH_BET_RESTRICTIONS_HISTORY, fetchBetRestrictionsHistory);
  yield takeLatest(actionTypes.UPDATE_BET_RESTRICTIONS, updateBetRestrictions);
  yield takeLatest(actionTypes.UPDATE_BET_RESTRICTIONS_HISTORY, updateBetRestrictionsHistory);
  yield takeLatest(actionTypes.RESTORE_BET_RESTRICTIONS_HISTORY, restoreBetRestrictionsHistory);
  yield takeLatest(actionTypes.ADD_NEW_BET_RESTRICTION, addNewBetRestrictions);
  yield takeLatest(actionTypes.CREATE_NEW_BET_RESTRICTIONS_MATRIX, createNewBetRestrictionsMatrix);
  yield takeLatest(actionTypes.DELETE_BET_RESTRICTIONS, deleteBetRestrictions);
  yield takeLatest(actionTypes.DELETE_BET_RESTRICTIONS_HISTORY, deleteBetRestrictionsHistory);
}
