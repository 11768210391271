const constants = {
  FETCH_INDICATOR_ACTIONS: "SD::FETCH_INDICATOR_ACTIONS",
  FETCH_INDICATOR_ACTIONS_SUCCEEDED: "SD::FETCH_INDICATOR_ACTIONS_SUCCEEDED",
  FETCH_STAKE_DISTRIBUTATION: "SD::FETCH_STAKE_DISTRIBUTATION",
  FETCH_STAKE_DISTRIBUTATION_FAILED: "SD::FETCH_STAKE_DISTRIBUTATION_FAILED",

  FETCH_STAKE_DISTRIBUTATION_SUCCEEDED: "SD::FETCH_STAKE_DISTRIBUTATION_SUCCEEDED",
  FETCH_STAKE_TYPE: "SD::FETCH_STAKE_TYPE",
  FETCH_STAKE_TYPE_SUCCEEDED: "SD::FETCH_STAKE_TYPE_SUCCEEDED",

  ON_EXIT_STAKEDISTRIBUTATION_PAGE: "SD::ON_EXIT_STAKEDISTRIBUTATION_PAGE",
  ON_REMOVE_STAKE_LIABILITY_INDICATOR_FAILED: "SD::ON_REMOVE_STAKE_LIABILITY_INDICATOR_FAILED",
  ON_REMOVE_STAKE_LIABILITY_INDICATOR_SUCCEEDED: "SD::ON_REMOVE_STAKE_LIABILITY_INDICATOR_SUCCEEDED",

  REMOVE_STAKE_LIABILITY_INDICATOR: "SD::REMOVE_STAKE_LIABILITY_INDICATOR",
  SAVE_STAKE_DISTRIBUTATION: "SD::SAVE_STAKE_DISTRIBUTATION",
  SAVE_STAKE_DISTRIBUTATION_FAILED: "SD::SAVE_STAKE_DISTRIBUTATION_FAILED",

  SAVE_STAKE_DISTRIBUTATION_SUCCEEDED: "SD::SAVE_STAKE_DISTRIBUTATION_SUCCEEDED",

  SELECT_ROW_TO_EDIT: "SD::SELECT_ROW_TO_EDIT",
  SET_STATE_AFTER_UPDATE: "SD::SET_STATE_AFTER_UPDATE",
  UPDATE_FIELD: "SD::UPDATE_FIELD",
};

export default constants;
