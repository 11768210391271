import _ from "lodash";

import constants from "./constants";

const initialState = {
  allPeriodConfig: [],
  allSportsConfig: [],
  backupMarketDataConfig: [],
  changedEnabledMarketIdsConfig: [],
  changedSortIdsConfig: [],
  enableSave: false,
  fetchingMarketDataConfig: false,
  fetchingPeriodDataConfig: false,
  loadingWagerLimitGroupConfig: false,
  marketDataConfig: [],
  openUpdatePositionPopup: false,
  savaUpdateData: [],
  selectedRow: null,
  selectedSportsConfig: "",
  tempChangedWagerLimitGroupId: [],
  tempUpdatedData: {},
  updateIdsConfig: [],
  updateMarketIdsConfig: [],
  wagerLimitGroupConfig: [],
};

const utilitiesMarketTypeConfig = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_SPORTS_CONFIG_SUCCEEDED: // to get all sports
      return {
        ...state,
        allSportsConfig: action.payload,
      };
    case constants.FETCH_ALL_SPORTS_CONFIG_FAILED:
      return {
        ...state,
        allSportsConfig: [],
      };
    case constants.SELECT_MARKET_CONFIG_LINE:
      return {
        ...state,
        selectedRow: state.selectedRow == null ? action.id : state.selectedRow == action.id ? null : action.id,
      };
    case constants.FETCH_ALL_MARKET_TYPES_CONFIG:
      return {
        ...state,
        fetchingMarketDataConfig: true,
      };
    case constants.FETCH_ALL_MARKET_TYPES_CONFIG_SUCCEEDED: // table data comes here
      return {
        ...state,
        backupMarketDataConfig: _.cloneDeep(action.payload),
        fetchingMarketDataConfig: false,
        marketDataConfig: action.payload,
      };
    case constants.FETCH_ALL_MARKET_TYPES_CONFIG_FAILED:
      return {
        ...state,
        marketDataConfig: [],
      };
    case constants.UPDATE_MARKET_FORM_CONFIG:
      const { enabled, ordinal, row, wagerLimit } = action.payload;
      const newRow = _.cloneDeep(row);
      const changedEnable = _.cloneDeep(state.changedEnabledMarketIdsConfig);
      let tempChangedEnabledMarketIds = _.cloneDeep(state.changedEnabledMarketIdsConfig);
      const changedSortId = _.cloneDeep(state.changedSortIdsConfig);
      let tempChangedSortIds = _.cloneDeep(state.changedSortIdsConfig);
      const changedWagerLimit = _.cloneDeep(state.tempChangedWagerLimitGroupId);
      let tempchangedWagerLimit = _.cloneDeep(state.tempChangedWagerLimitGroupId);
      const marketDataConfig = _.cloneDeep(state.marketDataConfig);
      const backupMarketDataConfig = _.cloneDeep(state.backupMarketDataConfig);
      let tempMarketIds = _.cloneDeep(state.updateMarketIdsConfig);

      if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.enabled == enabled).length > 0 &&
        changedEnable.indexOf(row.id) > -1
      ) {
        tempChangedEnabledMarketIds = _.without(changedEnable, row.id);
      } else if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.enabled != enabled).length > 0 &&
        changedEnable.indexOf(row.id) < 0
      ) {
        tempChangedEnabledMarketIds.push(row.id);
      }
      if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.marketTypeWagerLimitGroupId == +wagerLimit).length >
          0 &&
        changedWagerLimit.indexOf(row.id) > -1
      ) {
        tempchangedWagerLimit = _.without(changedWagerLimit, row.id);
      } else if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.marketTypeWagerLimitGroupId != +wagerLimit).length >
          0 &&
        changedWagerLimit.indexOf(row.id) < 0
      ) {
        tempchangedWagerLimit.push(row.id);
      }
      if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.ordinal == ordinal).length > 0 &&
        changedSortId.indexOf(row.id) > -1
      ) {
        tempChangedSortIds = _.without(changedSortId, row.id);
      } else if (
        _.filter(backupMarketDataConfig, (o) => o.id == row.id && o.ordinal != ordinal).length > 0 &&
        changedSortId.indexOf(row.id) < 0
      ) {
        tempChangedSortIds.push(row.id);
      }
      newRow.enabled = enabled;
      newRow.marketTypeWagerLimitGroupId = wagerLimit;
      newRow.ordinal = ordinal;
      const i = _.findIndex(marketDataConfig, (o) => o.id == row.id);
      marketDataConfig[i] = newRow;
      if (!_.isEqual(state.backupMarketDataConfig, marketDataConfig)) {
        tempMarketIds.push(row.id);
        tempMarketIds = _.uniq(tempMarketIds, "id");
      } else {
        const marketTempPayload = [];
        _.map(tempMarketIds, (val) => (val != row.id ? marketTempPayload.push(val) : null));
        tempMarketIds = marketTempPayload;
      }
      let tempUpdatedData = {};
      const data = [];
      tempUpdatedData = state.tempUpdatedData;
      tempUpdatedData[newRow.id] = newRow;
      !!tempChangedEnabledMarketIds &&
        tempChangedEnabledMarketIds.length > 0 &&
        tempChangedEnabledMarketIds.map((val) => {
          data.push(tempUpdatedData[val]);
        });
      !!tempChangedSortIds &&
        tempChangedSortIds.length > 0 &&
        tempChangedSortIds.map((val) => {
          data.push(tempUpdatedData[val]);
        });
      !!tempchangedWagerLimit &&
        tempchangedWagerLimit.length > 0 &&
        tempchangedWagerLimit.map((val) => {
          data.push(tempUpdatedData[val]);
        });

      return {
        ...state,
        changedEnabledMarketIdsConfig: tempChangedEnabledMarketIds,
        changedSortIdsConfig: tempChangedSortIds,
        enableSave: data.length > 0,
        marketDataConfig,
        openUpdatePositionPopup: false,
        savaUpdateData: data,
        tempChangedWagerLimitGroupId: tempchangedWagerLimit,
        tempUpdatedData,
        updateMarketIdsConfig: tempMarketIds,
      };
    case constants.UNDO_MARKET_PERIOD_TYPES_TABLE_CONFIG:
      return {
        ...state,
        enableSave: false,
        marketDataConfig: state.backupMarketDataConfig,
      };
    case constants.UPDATE_MARKET_TYPES_CONFIG_SUCCEEDED: // update table data
      return {
        ...state,
        changedEnabledMarketIdsConfig: [],
        enableSave: false,
        updateMarketIdsConfig: [],
      };
    case constants.UNDO_FORM_TABLE_DATA_CONFIG:
      return {
        ...state,
        changedEnabledMarketIdsConfig: [],
        changedSortIdsConfig: [],
        marketDataConfig: _.cloneDeep(state.backupMarketDataConfig),
        updateIdsConfig: [],
        updateMarketIdsConfig: [],
      };
    case constants.OPEN_UPDATE_POSITION_POPUP:
      return {
        ...state,
        openUpdatePositionPopup: action.value,
      };
    case constants.FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG:
      return {
        ...state,
        loadingWagerLimitGroupConfig: true,
      };
    case constants.FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_SUCCEEDED:
      return {
        ...state,
        loadingWagerLimitGroupConfig: false,
        wagerLimitGroupConfig: action.response,
      };
    case constants.UPDATE_MARKET_TYPES_CONFIG_FAILED:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default utilitiesMarketTypeConfig;
