import actionTypes from "../constants";

export function fetchRiskData(params = {}) {
  return {
    params,
    type: actionTypes.FETCH_RISK_DATA,
  };
}

export function updateRiskData() {
  return {
    type: actionTypes.UPDATE_RISK_DATA,
  };
}

export function cancelUpdateRiskData() {
  return {
    type: actionTypes.CANCEL_UPDATE_RISK_DATA,
  };
}

export function clearRiskData() {
  return {
    type: actionTypes.CLEAR_RISK_DATA,
  };
}

export function collapseEvent(eventId) {
  return {
    eventId,
    type: actionTypes.COLLAPSE_EVENT,
  };
}

export function expandEvent(eventId) {
  return {
    eventId,
    type: actionTypes.EXPAND_EVENT,
  };
}

export function expandAllEvents() {
  return {
    type: actionTypes.EXPAND_ALL_EVENTS,
  };
}

export function requestUofs(eventId) {
  return {
    eventId,
    type: actionTypes.REQUEST_U_FOS,
  };
}

export function requestUofp(eventId) {
  return {
    eventId,
    type: actionTypes.REQUEST_UFOP,
  };
}
