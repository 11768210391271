import constants from "./constants";

export function fetchBetRestrictionKeys(id) {
  return {
    id,
    type: constants.FETCH_BET_RESTRICTION_KEYS,
  };
}

export function fetchUnusedBetRestrictionKeys(id) {
  return {
    id,
    type: constants.FETCH_UNUSED_BET_RESTRICTION_KEYS,
  };
}

export function fetchSportPeriods(code) {
  return {
    code,
    type: constants.FETCH_SPORT_PERIODS,
  };
}
export function fetchMarketTypeGroups(params) {
  return {
    params,
    type: constants.FETCH_MARKET_TYPE_GROUPS,
  };
}
export function fetchBetRestrictionsHistory(betType) {
  return {
    betType,
    type: constants.FETCH_BET_RESTRICTIONS_HISTORY,
  };
}
export function updateBetRestrictions(restrictions) {
  return {
    restrictions,
    type: constants.UPDATE_BET_RESTRICTIONS,
  };
}
export function updateBetRestrictionsHistory(data) {
  return {
    data,
    type: constants.UPDATE_BET_RESTRICTIONS_HISTORY,
  };
}
export function updateBetRestrictionKeys(betTypes) {
  return {
    betTypes,
    type: constants.UPDATE_BET_RESTRICTION_KEYS,
  };
}
export function restoreBetRestrictionsHistory(id) {
  return {
    id,
    type: constants.RESTORE_BET_RESTRICTIONS_HISTORY,
  };
}

export function fetchMatrixData(activeBetType) {
  return {
    activeBetType,
    type: constants.FETCH_MATRIX_DATA,
  };
}

export function setActiveBetType(activeBetType) {
  return {
    activeBetType,
    type: constants.SET_ACTIVE_BET_TYPE,
  };
}

export function setActiveCell(activeCell) {
  return {
    activeCell,
    type: constants.SET_ACTIVE_CELL,
  };
}

export function setActiveHistory(activeHistory) {
  return {
    activeHistory,
    type: constants.SET_ACTIVE_HISTORY,
  };
}

export function setPathIdForEvent(pathIdForEvent) {
  return {
    pathIdForEvent,
    type: constants.SET_PATH_ID_FOR_EVENT,
  };
}

export function updateCellData(data) {
  return {
    data,
    type: constants.UPDATE_CELL_DATA,
  };
}

export function resetCurrentData() {
  return {
    type: constants.RESET_CURRENT_DATA,
  };
}

export function nextStep() {
  return {
    type: constants.NEXT_STEP,
  };
}
export function previousStep() {
  return {
    type: constants.PREVIOUS_STEP,
  };
}
export function updateStep(key, data) {
  return {
    data,
    key,
    type: constants.UPDATE_STEP,
  };
}
export function resetSteps() {
  return {
    type: constants.RESET_STEPS,
  };
}
export function resetHistoryData() {
  return {
    type: constants.RESET_HISTORY_DATA,
  };
}
export function updateNewMatrixData(data) {
  return {
    data,
    type: constants.UPDATE_NEW_MATRIX_DATA,
  };
}
export function addNewBetRestrictions(betType, restrictions, newlyAddedRestrictionKey) {
  return {
    betType,
    newlyAddedRestrictionKey,
    restrictions,
    type: constants.ADD_NEW_BET_RESTRICTION,
  };
}
export function createNewBetRestrictionsMatrix(betType, restrictions, newlyAddedRestrictionKey) {
  return {
    betType,
    newlyAddedRestrictionKey,
    restrictions,
    type: constants.CREATE_NEW_BET_RESTRICTIONS_MATRIX,
  };
}
export function deleteBetRestrictions(betType, restrictions) {
  return {
    betType,
    restrictions,
    type: constants.DELETE_BET_RESTRICTIONS,
  };
}
export function deleteBetRestrictionsTemporarily(deletedRestrictionsMap, response) {
  return {
    deletedRestrictionsMap,
    response,
    type: constants.DELETE_BET_RESTRICTIONS_TEMPORARILY,
  };
}

export function deleteBetRestrictionsHistory(id) {
  return {
    id,
    type: constants.DELETE_BET_RESTRICTIONS_HISTORY,
  };
}

export function setEvaluationOrderVisibility(isEvaluationOrderVisible) {
  return {
    isEvaluationOrderVisible,
    type: constants.SET_EVALUATION_ORDER_VISIBILITY,
  };
}

export function undoChanges(activeBetTypeKey) {
  return {
    activeBetTypeKey,
    type: constants.UNDO_CHANGES,
  };
}
