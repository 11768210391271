import originalAxios from "axios";

import api from "./api";

let marketFeedInfoXhr = null;
let disconnectMarketFromPriceFeedXhr = null;

export function fetchMarketFeedInfo(marketIds) {
  return new Promise((resolve, reject) => {
    const url = `/bo/pricefeeds/${marketIds}`;

    if (marketFeedInfoXhr) {
      marketFeedInfoXhr.cancel("cancelled due to concurrent request");
    }

    marketFeedInfoXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, marketFeedInfoXhr);
      marketFeedInfoXhr = null;
      resolve(response);
    } catch (err) {
      marketFeedInfoXhr = null;
      reject(err);
    }
    marketFeedInfoXhr = null;
  })
    .then((response) => {
      const marketsConnected = [];
      const linesConnected = [];
      response.data.forEach((market) => {
        if (market.priceFromFeed && marketsConnected.indexOf(`m${market.marketId}`) === -1) {
          marketsConnected.push(`m${market.marketId}`);
        }
        const marketLinesConnected = findLinesConnected(market.linePriceFeeds);
        marketLinesConnected.forEach((marketLine) => {
          if (linesConnected.indexOf(marketLine) === -1) {
            linesConnected.push(marketLine);
          }
        });
      });

      return { response: { linesConnected, marketsConnected } };
    })
    .catch((xhr) => ({ xhr }));
}

export function disconnectMarketFromPriceFeed(marketIds, marketIdsToDisconnect, lineIdsToDisconnect) {
  return new Promise((resolve, reject) => {
    const url = `/bo/pricefeeds`;
    const requestBody = [];
    marketIds.forEach((marketId) => {
      const priceFromFeed = marketIdsToDisconnect.indexOf(marketId) > -1;
      requestBody.push({
        linePriceFeeds: lineIdsToDisconnect.map((lineId) => ({
          derivePrices: false,
          lineId: Number(lineId),
          marketId: Number(marketId),
        })),
        marketId: Number(marketId),
        priceFromFeed: !priceFromFeed,
      });
    });

    if (disconnectMarketFromPriceFeedXhr) {
      disconnectMarketFromPriceFeedXhr.cancel("cancelled due to concurrent request");
    }

    disconnectMarketFromPriceFeedXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, disconnectMarketFromPriceFeedXhr);
      disconnectMarketFromPriceFeedXhr = null;
      resolve(response);
    } catch (err) {
      disconnectMarketFromPriceFeedXhr = null;
      reject(err);
    }
    disconnectMarketFromPriceFeedXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function findLinesConnected(linePriceFeeds) {
  const linesConnected = [];
  linePriceFeeds.forEach((linePriceFeed) => {
    if (linePriceFeed.derivePrices && linesConnected.indexOf(linePriceFeed.lineId) === -1) {
      linesConnected.push(linePriceFeed.lineId);
    }
  });

  return linesConnected;
}
