import { takeLatest, takeEvery, delay, put, call, fork, select } from "redux-saga/effects";

import opponentsConst from "../constants/opponentsConst";
import * as opponentsAPI from "../services/opponentsServices";
import * as playersAPI from "../services/playersServices";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

// TODO check xhr.status = for status code

function* fetchOpponentTypes(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchOpponentTypes);
  if (response) {
    yield put({ opponentTypes: response, type: opponentsConst.FETCH_OPPONENT_TYPES_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_OPPONENT_TYPES_FAILED });
  }
}

function* fetchSingleOpponentDetails(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchSingleOpponentDetails, action.opponentId);
  if (response) {
    yield put({ opponentDetails: response, type: opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS_FAILED });
  }
}

function* fetchMultipleOpponentsDetails(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchMultipleOpponentsDetails, action.opponentIdList);
  if (response) {
    yield put({ opponentDetailsMultiple: response, type: opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS_FAILED });
  }
}

function* fetchSingleOpponentDetailsWithGrade(action) {
  const { response, xhr } = yield call(
    opponentsAPI.fetchSingleOpponentDetailsWithGrade,
    action.eventPathId,
    action.opponentId,
  );
  if (response) {
    yield put({
      opponentDetailsSingleWithGrade: response,
      type: opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_FAILED });
  }
}

function* fetchEventPathOpponentsDetails(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchEventPathOpponentsDetails, action.eventPathId);
  if (response) {
    yield put({
      eventPathOpponentDetailsList: response,
      type: opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS_FAILED });
  }
}

function* fetchEventPathAncestralOpponents(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchEventPathAncestralOpponents, action.eventPathId);
  if (response) {
    yield put({
      eventPathAncestralOpponentsList: response,
      type: opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_FAILED });
  }
}

function* fetchOpponentKits(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchOpponentKits, action.opponentId);
  if (response) {
    yield put({ opponentKitList: response, type: opponentsConst.FETCH_OPPONENT_KITS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_OPPONENT_KITS_FAILED });
  }
}

function* fetchKitPatterns(action) {
  const { response, xhr } = yield call(opponentsAPI.fetchKitPatterns);
  if (response) {
    yield put({ kitPattenList: response.kitPatterns, type: opponentsConst.FETCH_KIT_PATTERNS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.FETCH_KIT_PATTERNS_FAILED });
  }
}

function* addOpponent(action) {
  const { response, xhr } = yield call(opponentsAPI.addOpponent, action.opponentObj);
  if (response) {
    yield put({ newOppenentDetails: response, type: opponentsConst.ADD_OPPONENT_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.ADD_OPPONENT_FAILED });
  }
}

function* editOpponent(action) {
  const { response, xhr } = yield call(opponentsAPI.editOpponent, action.opponentId, action.opponentObj);
  if (response) {
    yield put({ newOppenentDetails: response, type: opponentsConst.EDIT_OPPONENT_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.EDIT_OPPONENT_FAILED });
  }
}

function* editOpponentWithGrade(action) {
  const { response, xhr } = yield call(opponentsAPI.editOpponent, action.opponentId, action.opponentObj);
  if (response) {
    if (action.grade === "NO_GRADE") {
      yield put({ newOppenentDetails: response, type: opponentsConst.EDIT_OPPONENT_WITH_GRADE_SUCCEEDED });
    } else {
      const { response: response2, xhr: xhr2 } = yield call(
        opponentsAPI.assignSingleOpponentToEventPathWithGrade,
        action.eventPathId,
        action.opponentId,
        action.grade,
      );

      if (response2) {
        yield put({ newOppenentDetails: response, type: opponentsConst.EDIT_OPPONENT_WITH_GRADE_SUCCEEDED });
      } else {
        const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr2);
        yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED });
      }
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.EDIT_OPPONENT_WITH_GRADE_FAILED });
  }
}

function* deleteAllOpponentOfEventPath(action) {
  const { response, xhr } = yield call(opponentsAPI.deleteAllOpponentOfEventPath, action.eventPathId);
  if (response) {
    yield put({ type: opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_SUCCEEDED });
    toastr.add({ message: i18n.t("Event.Successfully deleted all teams") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete all teams")}. ${msg}`, type: "ERROR" });
  }
}

function* deleteAllOpponentOfEventPathAndUnder(action) {
  const { response, xhr } = yield call(opponentsAPI.deleteAllOpponentOfEventPathAndUnder, action.eventPathId);
  if (response) {
    yield put({ type: opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_SUCCEEDED });
    toastr.add({
      message: i18n.t(
        `Event.Successfully deleted all ${
          action.opponentTypeId === 1 ? "teams" : action.opponentTypeId === 2 ? "players" : "horses"
        }.`,
      ),
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete all teams")}. ${msg}`, type: "ERROR" });
  }
}

function* assignSingleOpponentToEventPath(action) {
  const { response, xhr } = yield call(
    opponentsAPI.assignSingleOpponentToEventPath,
    action.eventPathId,
    action.opponentId,
  );
  if (response) {
    yield put({ type: opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_FAILED });
  }
}

function* assignSingleOpponentToEventPathWithGrade(action) {
  const { response, xhr } = yield call(
    opponentsAPI.assignSingleOpponentToEventPathWithGrade,
    action.eventPathId,
    action.opponentId,
    action.grade,
  );
  if (response) {
    yield put({ type: opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_FAILED });
  }
}

function* changeOpponentGrade(action) {
  const { response, xhr } = yield call(
    opponentsAPI.changeOpponentGrade,
    action.eventPathId,
    action.opponentId,
    action.grade,
  );
  if (response) {
    yield put({ type: opponentsConst.CHANGE_OPPONENT_GRADE_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.CHANGE_OPPONENT_GRADE_FAILED });
  }
}

function* assignMultipleOpponentsToEventPath(action) {
  const { response, xhr } = yield call(
    opponentsAPI.assignMultipleOpponentsToEventPath,
    action.eventPathId,
    action.opponentIdList,
  );
  if (response) {
    yield put({ type: opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_SUCCEEDED });
    yield call(fetchEventPathOpponentsDetails, { eventPathId: action.eventPathId });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_FAILED });
  }
}

function* unAssignOpponentFromEventPath(action) {
  const { response, xhr } = yield call(
    opponentsAPI.unAssignOpponentFromEventPath,
    action.eventPathId,
    action.opponentId,
  );
  if (response) {
    yield put({
      opponentIdUnassigned: action.opponentId,
      type: opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH_SUCCEEDED,
    });
    toastr.add({
      message: `${i18n.t(
        `Event.Successfully deleted ${
          action.opponentTypeId === 1 ? "team" : action.opponentTypeId === 2 ? "player" : "horse"
        }`,
      )}.`,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH_FAILED });
    toastr.add({ message: `${i18n.t("Event.Unable to delete opponent")}. ${msg}`, type: "ERROR" });
  }
}

function* createAndAssignOpponent(action) {
  const { response, xhr } = yield call(opponentsAPI.addOpponent, action.opponentObj);
  if (response) {
    const newOpponent = response;
    const newOpponentId = newOpponent.id;

    const { response: response2, xhr: xhr2 } = yield call(playersAPI.addPlayerToTeam, action.teamId, newOpponentId);

    if (response2) {
      yield put({ newOppenentDetails: newOpponent, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_SUCCEEDED });
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr2);
      yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_FAILED });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_FAILED });
  }
}

function* createAndAssignOpponentWithGrade(action) {
  const { response, xhr } = yield call(opponentsAPI.addOpponent, action.opponentObj);
  if (response) {
    yield delay(2000);
    const newOpponent = response;
    const newOpponentId = newOpponent.id;

    if (action.grade === "NO_GRADE") {
      yield put({
        newOppenentDetails: newOpponent,
        type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_SUCCEEDED,
      });
    } else {
      const { response: response2, xhr: xhr2 } = yield call(
        opponentsAPI.assignSingleOpponentToEventPathWithGrade,
        action.eventPathId,
        newOpponentId,
        action.grade,
      );

      if (response2) {
        yield put({
          newOppenentDetails: newOpponent,
          type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_SUCCEEDED,
        });
      } else {
        const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr2);
        yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED });
      }
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED });
  }
}

function* createAndAssignOpponentToEventPath(action) {
  const { response, xhr } = yield call(opponentsAPI.addOpponent, action.opponentObj);
  if (response) {
    const newOpponent = response;
    const newOpponentId = newOpponent.id;

    const { response: response2, xhr: xhr2 } = yield call(
      opponentsAPI.assignSingleOpponentToEventPath,
      action.eventPathId,
      newOpponentId,
    );

    if (response2) {
      yield put({
        newOppenentDetails: newOpponent,
        type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_SUCCEEDED,
      });
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr2);
      yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_FAILED });
      toastr.add({ message: `${msg}`, type: "ERROR" });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: msg, type: opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_FAILED });
    toastr.add({ message: `${msg}`, type: "ERROR" });
  }
}

export default function* opponentsSaga() {
  yield takeLatest(opponentsConst.FETCH_OPPONENT_TYPES, fetchOpponentTypes);
  yield takeLatest(opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS, fetchSingleOpponentDetails);
  yield takeLatest(opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS, fetchMultipleOpponentsDetails);
  yield takeLatest(opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS, fetchSingleOpponentDetailsWithGrade);
  yield takeLatest(opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS, fetchEventPathOpponentsDetails);
  yield takeLatest(opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS, fetchEventPathAncestralOpponents);
  yield takeLatest(opponentsConst.FETCH_OPPONENT_KITS, fetchOpponentKits);
  yield takeLatest(opponentsConst.FETCH_KIT_PATTERNS, fetchKitPatterns);
  yield takeLatest(opponentsConst.ADD_OPPONENT, addOpponent);
  yield takeLatest(opponentsConst.EDIT_OPPONENT, editOpponent);
  yield takeLatest(opponentsConst.EDIT_OPPONENT_WITH_GRADE, editOpponentWithGrade);
  yield takeLatest(opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH, deleteAllOpponentOfEventPath);
  yield takeLatest(opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER, deleteAllOpponentOfEventPathAndUnder);
  yield takeLatest(opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH, assignSingleOpponentToEventPath);
  yield takeLatest(
    opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH,
    assignSingleOpponentToEventPathWithGrade,
  );
  yield takeLatest(opponentsConst.CHANGE_OPPONENT_GRADE, changeOpponentGrade);
  yield takeLatest(opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH, assignMultipleOpponentsToEventPath);
  yield takeLatest(opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH, unAssignOpponentFromEventPath);
  yield takeLatest(opponentsConst.CREATE_AND_ASSIGN_OPPONENT, createAndAssignOpponent);
  yield takeLatest(opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE, createAndAssignOpponentWithGrade);
  yield takeLatest(opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH, createAndAssignOpponentToEventPath);
}
