import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTH_SET_TOKEN } from "../actions/auth-actions";
import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";

export const getAuthInitialState = ({
  accountId = null,
  authToken = null,
  language = "en",
  loggedIn = false,
  rememberedUsername = "",
  username = null,
}) => ({
  accountId,
  authToken,
  error: null,
  language,
  loading: false,
  loggedIn,
  rememberedUsername,
  username,
});

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const loginResult = await axios.post(`/bo/accounts/operator/${username}`, {
      password,
    });

    return {
      accountId: loginResult.data.id,
      authToken: loginResult.data.hsToken,
      username,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to login", // serializable (err.response.data)
      name: "Login Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const authSlice = createSlice({
  extraReducers: {
    [AUTH_SET_TOKEN]: (state, action) => {
      state.authToken = action.payload.authToken;
      // state.loggedIn = true;
    },
    [login.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [login.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      state.loggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.accountId = action.payload.accountId;
      state.authToken = action.payload.authToken;
      state.currencyCode = action.payload.currencyCode;
      state.error = null;
      state.loading = false;
      state.loggedIn = true;
      state.rememberedUsername = action.payload.rememberedUsername;
      state.username = action.payload.username;
    },
  },
  initialState: getAuthInitialState({}),
  name: "auth",
  reducers: {
    forceLogin: {
      // Hack: `liveSlice` uses `language`, `priceFormat` but has no direct access to this state.
      // Therefore, update them when in redux values changed..
      prepare(payload) {
        return { payload };
      },
      reducer(state, action) {
        state.accountId = action.payload.accountId;
        state.authToken = action.payload.authToken;
        state.language = action.payload.language || "en";
        state.loggedIn = true;
      },
    },
    setAuthLanguage: {
      prepare(payload) {
        return { payload };
      },
      reducer(state, action) {
        state.language = action.payload.language;
      },
    },
  },
});

const { actions, reducer } = authSlice;
export const { forceLogin, setAuthLanguage } = actions;
export default reducer;
