import { getAuthAccountId, getAuthLanguage, getAuthToken } from "../reselect/auth-selector";

export function getRequestParams(state) {
  const accountId = getAuthAccountId(state);
  const authToken = getAuthToken(state);
  const language = getAuthLanguage(state);

  return {
    accountId,
    authToken,
    language,
  };
}
