const constants = {
  FETCH_COMBLIABILITY_ACTIONS: "WL::FETCH_COMBLIABILITY_ACTIONS",
  FETCH_COMBLIABILITY_ACTIONS_FAILED: "WL::FETCH_COMBLIABILITY_ACTIONS_FAILED",
  FETCH_COMBLIABILITY_ACTIONS_SUCCEEDED: "WL::FETCH_COMBLIABILITY_ACTIONS_SUCCEEDED",

  FETCH_COMBLIABILITY_LIMITS: "WL::FETCH_COMBLIABILITY_LIMITS",
  FETCH_COMBLIABILITY_LIMITS_FAILED: "WL::FETCH_COMBLIABILITY_LIMITS_FAILED",
  FETCH_COMBLIABILITY_LIMITS_SUCCEEDED: "WL::FETCH_COMBLIABILITY_LIMITS_SUCCEEDED",

  ON_COMB_LIABILITY_EXIT: "WL::ON_COMB_LIABILITY_EXIT",
  ON_COMB_ROW_EDIT: "WL::ON_COMB_ROW_EDIT",

  ON_COMB_ROW_SELECTED: "WL::ON_COMB_ROW_SELECTED",
  ON_COMB_ROW_UPDATE: "WL::ON_COMB_ROW_UPDATE",
  ON_COMB_ROW_UPDATE_FAILED: "WL::ON_COMB_ROW_UPDATE_FAILED",

  ON_COMB_ROW_UPDATE_SUCCEEDED: "WL::ON_COMB_ROW_UPDATE_SUCCEEDED",
};

export default constants;
