import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import * as API from "../../services/riskManager/dailyStatistics";
import { objectToArray, formatFilterDates } from "../../utils";

function* fetchDailyStatistics(action) {
  const { response, xhr } = yield call(API.fetchDailyStatistics);
  if (response) {
    yield put({ dailyStatistics: response, type: actionTypes.FETCH_DAILY_STATISTICS_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.FETCH_DAILY_STATISTICS_FAILED });
  }
}

export default function* dailyStatisticsSaga() {
  yield takeLatest(actionTypes.FETCH_DAILY_STATISTICS, fetchDailyStatistics);
}
