import { put, call, takeLatest } from "redux-saga/effects";
import _ from "underscore";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchFeedHistoryLineup(action) {
  const { response } = yield call(API.fetchFeedHistoryLineup, action.eventId);
  if (response) {
    yield put({ response, type: constants.FETCH_FEED_HISTORY_LINEUP_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_FEED_HISTORY_LINEUP_FAILED });
  }
}

function* fetchFeedHistoryFeeds(action) {
  const { response } = yield call(API.fetchFeedHistoryFeeds, action.eventId, action.searchQuery);
  if (response) {
    yield put({ response, type: constants.FETCH_FEED_HISTORY_FEEDS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_FEED_HISTORY_FEEDS_FAILED });
  }
}

function* fetchFeedHistoryFeedXML(action) {
  const { response } = yield call(API.fetchFeedHistoryFeedXML, action.feedId);
  if (response) {
    yield put({ response, type: constants.FETCH_FEED_HISTORY_FEED_XML_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_FEED_HISTORY_FEED_XML_FAILED });
  }
}

function* parseFeedHistoryFeedXML(action) {
  const { response } = yield call(API.parseFeedHistoryFeedXML, action.data);
  if (response) {
    yield put({ response, type: constants.PARSE_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED });
  } else {
    yield put({ type: constants.PARSE_FEED_HISTORY_FEED_IMPORT_XML_FAILED });
  }
}

function* processFeedHistoryFeedXML(action) {
  const { response } = yield call(API.processFeedHistoryFeedXML, action.data);
  if (response) {
    let event = {};
    if (response.contents.length) {
      event = response.contents[0];
    }
    yield put({ response, type: constants.PROCESS_FEED_HISTORY_FEED_IMPORT_XML_SUCCEEDED });

    if (!_.isEmpty(event) && event.queueType === "PROCESSED") {
      toastr.add({ message: i18n.t("Event.Event imported successfully") });
    } else {
      toastr.add({ message: i18n.t("Event.Event has errors, import cancelled"), type: "ERROR" });
    }
  } else {
    yield put({ type: constants.PROCESS_FEED_HISTORY_FEED_IMPORT_XML_FAILED });
  }
}

export default function* eventSaga() {
  yield takeLatest(constants.FETCH_FEED_HISTORY_LINEUP, fetchFeedHistoryLineup);
  yield takeLatest(constants.FETCH_FEED_HISTORY_FEEDS, fetchFeedHistoryFeeds);
  yield takeLatest(constants.FETCH_FEED_HISTORY_FEED_XML, fetchFeedHistoryFeedXML);
  yield takeLatest(constants.PARSE_FEED_HISTORY_FEED_IMPORT_XML, parseFeedHistoryFeedXML);
  yield takeLatest(constants.PROCESS_FEED_HISTORY_FEED_IMPORT_XML, processFeedHistoryFeedXML);
}
