import constants from "./constants";

const initialState = {
  eventPathIds: [],
  isFetchingApplicableTemplates: false,
  isFetchingApplicableTemplatesFailed: false,
  isUpdatingApplicableTemplates: false,

  isUpdatingApplicableTemplatesFailed: false,
  templates: [],
};

const applicableTemplates = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_APPLICABLE_TEMPLATES:
      return {
        ...state,
        isUpdatingApplicableTemplates: true,
      };
    case constants.UPDATE_APPLICABLE_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        isUpdatingApplicableTemplates: false,
      };
    case constants.UPDATE_APPLICABLE_TEMPLATES_FAILED:
      return {
        ...state,
        isUpdatingApplicableTemplates: false,
        isUpdatingApplicableTemplatesFailed: true,
      };
    case constants.FETCH_APPLICABLE_TEMPLATES:
      return {
        ...state,
        isFetchingApplicableTemplates: true,
      };
    case constants.FETCH_APPLICABLE_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        eventPathIds: action.eventPathIds,
        isFetchingApplicableTemplates: false,
        templates: action.templates,
      };
    case constants.FETCH_APPLICABLE_TEMPLATES_FAILED:
      return {
        ...state,
        isFetchingApplicableTemplates: false,
        isFetchingApplicableTemplatesFailed: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default applicableTemplates;
