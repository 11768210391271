const constants = {
  FETCH_ALL_CHANNEL_PRIORITY_TRIGGER: "CU::FETCH_ALL_CHANNEL_PRIORITY_TRIGGER",
  FETCH_MESSAGE_TRIGGERS_CHANNELS: "CU::FETCH_MESSAGE_TRIGGERS_CHANNELS",
  FETCH_MESSAGE_TRIGGERS_CHANNELS_FAILED: "CU::FETCH_MESSAGE_TRIGGERS_CHANNELS_FAILED",
  FETCH_MESSAGE_TRIGGERS_CHANNELS_SUCCEEDED: "CU::FETCH_MESSAGE_TRIGGERS_CHANNELS_SUCCEEDED",
  FETCH_UTILITY_CHANNELS: "CU::FETCH_UTILITY_CHANNELS",
  SAVE_CHANNEL_UTILITY: "CU::SAVE_CHANNEL_UTILITY",
  UNDO_CHANNEL_UTILITY: "CU::UNDO_CHANNEL_UTILITY",
  UPDATE_ROW: "CU::UPDATE_ROW",
};

export default constants;
