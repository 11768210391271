const constants = {
  APPLICATION_ID: 13,
  EMPTY_SELECTED_SPORT: "WL::EMPTY_SELECTED_SPORT",
  FETCH_EVENT_PATH: "WL::FETCH_EVENT_PATH",

  FETCH_EVENT_PATH_FAILED: "WL::FETCH_EVENT_PATH_FAILED",
  FETCH_EVENT_PATH_SUCCEEDED: "WL::FETCH_EVENT_PATH_SUCCEEDED",
  FETCH_PLAYER_PROFILE_GROUPS: "WL::FETCH_PLAYER_PROFILE_GROUPS",

  FETCH_PLAYER_PROFILE_GROUPS_FAILED: "WL::FETCH_PLAYER_PROFILE_GROUPS_SUCCEEDED",
  FETCH_PLAYER_PROFILE_GROUPS_SUCCEEDED: "WL::FETCH_PLAYER_PROFILE_GROUPS_SUCCEEDED",
  FETCH_WAGER_ACTIONS: "WL::FETCH_WAGER_ACTIONS",

  FETCH_WAGER_ACTIONS_FAILED: "WL::FETCH_WAGER_ACTIONS_FAILED",
  FETCH_WAGER_ACTIONS_SUCCEEDED: "WL::FETCH_WAGER_ACTIONS_SUCCEEDED",
  FETCH_WAGER_LIMITS: "WL::FETCH_WAGER_LIMITS",

  FETCH_WAGER_LIMITS_FAILED: "WL::FETCH_WAGER_LIMITS_FAILED",
  FETCH_WAGER_LIMITS_SUCCEEDED: "WL::FETCH_WAGER_LIMITS_SUCCEEDED",
  FETCH_WAGER_LIMIT_GROUP: "SD::FETCH_WAGER_LIMIT_GROUP",

  FETCH_WAGER_LIMIT_GROUP_FAILED: "SD::FETCH_WAGER_LIMIT_GROUP_FAILED",
  FETCH_WAGER_LIMIT_GROUP_SUCCEEDED: "SD::FETCH_WAGER_LIMIT_GROUP_SUCCEEDED",

  ON_EXIT_WAGER_LIMITS: "WL::ON_EXIT_WAGER_LIMITS",
  ON_FETCHING: "WL::ON_FETCHING",
  ON_REMOVE_ALL_WL_LIAB_STAKE_FAILED: "WL::ON_REMOVE_ALL_WL_LIAB_STAKE_FAILED",

  ON_REMOVE_ALL_WL_LIAB_STAKE_SUCCEEDED: "WL::ON_REMOVE_ALL_WL_LIAB_STAKE_SUCCEEDED",

  ON_REMOVE_WAGER_LIMITS_FAILED: "WL::ON_REMOVE_WAGER_LIMITS_FAILED",
  ON_REMOVE_WAGER_LIMITS_SUCCEEDED: "WL::ON_REMOVE_WAGER_LIMITS_SUCCEEDED",
  ON_SAVE_WAGER_LIMITS: "WL::ON_SAVE_WAGER_LIMITS",

  ON_SAVE_WAGER_LIMITS_FAILED: "WL::ON_SAVE_WAGER_LIMITS_FAILED",
  ON_SAVE_WAGER_LIMITS_SUCCEEDED: "WL::ON_SAVE_WAGER_LIMITS_SUCCEEDED",

  PATH_CLICK: "WL::PATH_CLICK",
  REMOVE_ALL_WL_LIAB_STAKE: "WL::REMOVE_ALL_WL_LIAB_STAKE",
  REMOVE_WAGER_LIMIT_FOR_EVENT_PATH: "WL::REMOVE_WAGER_LIMIT_FOR_EVENT_PATH",

  SELECT_ROW_TO_EDIT: "WL::SELECT_ROW_TO_EDIT",
  UPDATE_FIELD: "WL::UPDATE_FIELD",
  UPDATE_TREE: "WL::UPDATE_TREE",
};

export default constants;
