import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import { login } from "../../redux/slices/authSlice";
import * as API from "../../services/customerManager";
import { renderGenericErrorToastMessage } from "../constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";
import { validateOperatorCredentials } from "../../services/user";

const errMsg = i18n.t("Manager.Something went wrong while connecting to server, please try again later");

function* getMainOperatorPermission(action) {
  try {
    const { response, xhr } = yield call(API.getAccountPermissions, action.id);
    if (response) {
      yield put({ permissions: response, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors.length ? parseResponse.errors[0].message : "failed fetching data";
      yield put({ errorMessage: msg, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FAILED });
  }
}

function* getMainOperatorPermissionFromRedirect(action) {
  try {
    const { response, xhr } = yield call(API.getAccountPermissions, action.id);
    if (response) {
      yield put({ permissions: response, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_SUCCEEDED });
      yield put({ id: action.customerId, type: actionTypes.LOAD_DETAILS_SILENT });
      yield put({ id: action.customerId, type: actionTypes.INITIALIZE_CALL });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors.length ? parseResponse.errors[0].message : "failed fetching data";
      yield put({ errorMessage: msg, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_FAILED });
  }
}

function* searchAccount(action) {
  if (action.existingId) {
    yield call(endCall, { id: action.existingId });
  }
  try {
    const { response, xhr } = yield call(API.accountSearch, action.payload);
    if (response) {
      yield put({ searchResult: response, status: "CONFIRMATION", type: actionTypes.SEARCH_ACCOUNT_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ type: actionTypes.SEARCH_ACCOUNT_FAILED });
      yield put({ errorMessage: msg, type: actionTypes.CM_ADD_ERROR });
    }
  } catch (e) {
    yield put({ type: actionTypes.SEARCH_ACCOUNT_FAILED });
    yield put({ errorMessage: errMsg, type: actionTypes.CM_ADD_ERROR });
  }
}

function* advanceSearchAccount(action) {
  try {
    const { response, xhr } = yield call(API.accountAdvanceSearch, action.data);
    if (response) {
      if (response && response.length == 0) {
        toastr.add({ message: i18n.t(`Manager.Warning No Accounts were found for this search`), type: "WARNING" });
      } else {
        yield put({
          searchResult: response,
          status: "CONFIRMATION",
          type: actionTypes.ADVANCE_SEARCH_ACCOUNT_SUCCEEDED,
        });
        yield put({ show: false, type: actionTypes.CLOSE_ADVANCE_ACCOUNT_SEARCH });
      }
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      toastr.add({ message: msg, type: "ERROR" });
      yield put({ errorMessage: msg, type: actionTypes.ADVANCE_SEARCH_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.ADVANCE_SEARCH_ACCOUNT_FAILED });
  }
}

function* advanceSearchAccountTransfer(action) {
  try {
    const { response, xhr } = yield call(API.accountAdvanceSearch, action.data);
    if (response) {
      yield put({ searchResult: response, type: actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER_SUCCEEDED });
      if (response.length === 0) {
        toastr.add({ message: i18n.t(`Manager.No results found`), type: "ERROR" });
      } else {
        yield put({ show: false, type: actionTypes.CLOSE_MODAL_ACCOUNT_TRANSFER_SEARCH });
        yield put({ show: true, type: actionTypes.OPEN_MODAL_ACCOUNT_TRANSFER_CONFIRMATION });
      }
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER_FAILED });
  }
}

function* getAccountDetails(action) {
  if (action.existingId) {
    yield call(endCall, { id: action.existingId });
  }
  try {
    const { response, xhr } = yield call(API.accountMoreInfo, action.id);
    if (response) {
      yield put({ result: response, status: "CONFIRMATION", type: actionTypes.FETCH_ACCOUNT_DETAILS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      let msg = i18n.t(`Manager.failed fetching data`);
      if (parseResponse.exception.message) {
        msg = parseResponse.exception.message;
      } else if (parseResponse.exception.message) {
        msg = parseResponse.errors[0].message;
      }
      if (msg.indexOf("You are not authorised") !== 0) {
        msg = i18n.t("Manager.You are not authorised to view this account");
      }
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNT_DETAILS_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNT_DETAILS_FAILED });
  }
}

function* getAccountDetailsSilent(action) {
  if (action.existingId) {
    yield call(endCall, { id: action.existingId });
  }
  try {
    const { response, xhr } = yield call(API.accountMoreInfo, action.id);
    if (response) {
      yield put({ result: response, status: "", type: actionTypes.FETCH_ACCOUNT_DETAILS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      let msg = "failed fetching data";
      if (parseResponse.exception.message) {
        msg = parseResponse.exception.message;
      } else if (parseResponse.exception.message) {
        msg = parseResponse.errors[0].message;
      }
      if (msg.indexOf("You are not authorised") !== 0) {
        msg = i18n.t("Manager.You are not authorised to view this account");
      }
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNT_DETAILS_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNT_DETAILS_FAILED });
  }
}

function* getAccountPermissions(action) {
  try {
    const { response, xhr } = yield call(API.getAccountPermissions, action.id);
    if (response) {
      yield put({ permissions: response, type: actionTypes.FETCH_ACCOUNT_PERMISSIONS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors.length ? parseResponse.errors[0].message : "failed fetching data";
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNT_PERMISSIONS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNT_PERMISSIONS_FAILED });
  }
}

function* validatePin(action) {
  try {
    const { response, xhr } = yield call(API.validatePin, action.id, action.pin);
    if (response) {
      yield put({ pinStatus: response.result, type: actionTypes.VALIDATE_PIN_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.VALIDATE_PIN_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.VALIDATE_PIN_FAILED });
  }
}

function* initializeCall(action) {
  try {
    const { response, xhr } = yield call(API.initializeCall, action.id);
    if (response) {
      const callStatus = response.result === "success" ? "started" : "failed";
      yield put({ callStatus, status: "CONFIRMED", type: actionTypes.INITIALIZE_CALL_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.INITIALIZE_CALL_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.INITIALIZE_CALL_FAILED });
  }
}

function* endCall(action) {
  try {
    const { response, xhr } = yield call(API.endCall, action.id);
    if (response) {
      const callStatus = response.result === "success" ? "ended" : "failed";
      yield put({ type: actionTypes.END_CALL_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.END_CALL_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.END_CALL_FAILED });
  }
}

function* getTransactions(action) {
  try {
    const { response, xhr } = yield call(API.getTransactions, action.id, action.params);
    if (response) {
      yield put({ transactionsData: response, type: actionTypes.FETCH_TRANSACTIONS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_TRANSACTIONS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_TRANSACTIONS_FAILED });
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_TRANSACTIONS_FAILED });
  }
}

function* getTransactionDetails(action) {
  try {
    const { response, xhr } = yield call(API.getTransactionDetails, action.id, action.params);
    if (response) {
      yield put({ transactionDetails: response, type: actionTypes.FETCH_TRANSACTION_DETAILS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_TRANSACTION_DETAILS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_TRANSACTION_DETAILS_FAILED });
  }
}

function* getRecentAccounts(action) {
  try {
    const { response, xhr } = yield call(API.getRecentAccounts, action.id);
    if (response) {
      yield put({ recentAccounts: response, type: actionTypes.FETCH_RECENT_ACCOUNTS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_RECENT_ACCOUNTS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_RECENT_ACCOUNTS_FAILED });
  }
}

function* getAccountBalance(action) {
  try {
    const { response, xhr } = yield call(API.getAccountBalance, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_ACCOUNT_BALANCE_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors.length ? parseResponse.errors[0].message : "failed fetching data";
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {}
}

function* getTransactionTypes(action) {
  try {
    const { response, xhr } = yield call(API.getTransactionTypes, action.id);
    if (response) {
      yield put({ transactionTypes: response, type: actionTypes.FETCH_TRANSACTION_TYPE_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_TRANSACTION_TYPE_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_TRANSACTION_TYPE_FAILED });
  }
}

function* accountAdjust(action) {
  try {
    const { response, xhr } = yield call(API.accountAdjustment, action.id, action.params);
    if (response) {
      yield put({ accountAdjust: true, type: actionTypes.ADJUST_ACCOUNT_SUCCEEDED });
      yield call(getAccountBalance, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Transaction Success!"), type: "SUCCESS" });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors;
      yield put({ errorMessage: msg, type: actionTypes.ADJUST_ACCOUNT_FAILED });
      toastr.add({ message: i18n.t("Manager.Transaction Failed!"), type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.ADJUST_ACCOUNT_FAILED });
    toastr.add({ message: i18n.t("Manager.Transaction Failed!"), type: "ERROR" });
  }
}

function* getFXRate(action) {
  try {
    const { response, xhr } = yield call(API.getFXRate, action.fromId, action.toId);
    if (response) {
      yield put({ fxRate: response, type: actionTypes.FETCH_FX_RATE_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_FX_RATE_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_FX_RATE_FAILED });
  }
}

function* accountTransfer(action) {
  try {
    const { response, xhr } = yield call(API.accountTransfer, action.id, action.params);
    if (response) {
      yield call(getAccountBalance, { id: action.id });
      yield put({ accountTransfer: response, type: actionTypes.ACCOUNT_TRANSFER_SUCCEEDED });
      yield put({ show: false, type: actionTypes.CLOSE_MODAL_ACCOUNT_TRANSFER });
      yield put({ searchResult: [], type: actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER_SUCCEEDED });
      yield put({ data: null, type: actionTypes.CLEAR_ACCOUNT_TO_TRANSFER });
      toastr.add({ message: i18n.t("Manager.Transfer Success!"), type: "SUCCESS" });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.ACCOUNT_TRANSFER_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.ACCOUNT_TRANSFER_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* createAccount(action) {
  try {
    const { response, xhr } = yield call(API.createAccount, action.params);
    if (response) {
      yield call(getAccountBalance, { id: response.id });
      yield put({ result: response, type: actionTypes.CREATE_ACCOUNT_SUCCEEDED });
      yield call(initializeCall, { id: response.id });
      toastr.add({ message: i18n.t("Manager.New Account Successfully Created!"), type: "SUCCESS" });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg =
        parseResponse.errorMessage ||
        parseResponse.errors.map((e) => e.message).join("\n") ||
        parseResponse.exception.message;
      yield put({ errors: parseResponse.errors, type: actionTypes.CREATE_ACCOUNT_FAILED });
      // toastr.add({ message: errMsg, type: 'ERROR' })
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.CREATE_ACCOUNT_FAILED });
    toastr.add({ message: errMsg, type: "ERROR" });
    // yield call(renderGenericErrorToastMessage)
  }
}

function* getOptionalFields() {
  try {
    const { response, xhr } = yield call(API.fetchOptionalFields);
    if (response) {
      yield put({ result: response, type: actionTypes.GET_OPTIONAL_FIELDS_SUCCESS });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.GET_OPTIONAL_FIELDS_FAILED });
    toastr.add({ message: errMsg, type: "ERROR" });
  }
}

function* revokeTransaction(action) {
  try {
    const { response, xhr } = yield call(API.revokeTransaction, action.transactionId, action.method, action.params);
    if (!xhr) {
      yield put({ revokeTransaction: response, type: actionTypes.REVOKE_TRANSACTION_SUCCEEDED });
      toastr.add({ message: i18n.t("Manager.Successfully settled transaction") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.REVOKE_TRANSACTION_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.REVOKE_TRANSACTION_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* deleteTransaction(action) {
  const { response, xhr } = yield call(API.deleteTransaction, action.transactionId, action.params);
  if (response) {
    yield put({ deleteTransaction: response, type: actionTypes.DELETE_TRANSACTION_SUCCEEDED });
    // yield put({type: actionTypes.FETCH_TRANSACTIONS});
    toastr.add({ message: i18n.t("Manager.Transaction was successfully deleted") });
  } else {
    const parseResponse = JSON.parse(xhr.response);
    const msg = parseResponse.errorMessage || parseResponse.exception.message;
    yield put({ errorMessage: msg, type: actionTypes.DELETE_TRANSACTION_FAILED });
    toastr.add({ message: i18n.t("Manager.Failed to deleted transaction, something went wrong"), type: "ERROR" });
  }
}

function* getBetStatistics(action) {
  try {
    const { response, xhr } = yield call(API.getBetStatistics, action.id);
    if (response) {
      yield put({ accountBetStatistics: response, type: actionTypes.FETCH_BETSTATISTICS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_BETSTATISTICS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_BETSTATISTICS_FAILED });
  }
}

function* getAccountStats(action) {
  try {
    const { response, xhr } = yield call(API.getAccountStats, action.id);
    if (response) {
      yield put({ accountStatistics: response, type: actionTypes.FETCH_ACCOUNTSTATS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNTSTATS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNTSTATS_FAILED });
  }
}

function* loginOperator(action) {
  try {
    const { response, xhr } = yield call(validateOperatorCredentials, action.username, action.password);
    if (response) {
      yield put({ result: response, type: actionTypes.CM_OPERATOR_LOGIN_SUCCEEDED });
      yield call(getAccountPermissions, { id: response.id });
      yield put({ show: false, type: actionTypes.CLOSE_MODAL_CM_OPERATOR_LOGIN });
      yield put({ isFieldsLocked: false, type: actionTypes.ACCOUNT_UNLOCK });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg =
        parseResponse.errors && parseResponse.errors.length
          ? parseResponse.errors[0].message
          : parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.CM_OPERATOR_LOGIN_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.CM_OPERATOR_LOGIN_FAILED });
  }
}

function* getMoneyTypes(action) {
  try {
    const { response, xhr } = yield call(API.getMoneyTypes);
    if (response) {
      yield put({ response, type: actionTypes.CM_GET_MONEYTYPES_SUCCESS });
    } else {
      yield put({ response, type: actionTypes.CM_GET_MONEYTYPES_FAILED });
    }
  } catch (e) {
    yield put({ response, type: actionTypes.CM_GET_MONEYTYPES_FAILED });
  }
}

function* doExpirePromotionReward(action) {
  try {
    const { response, xhr } = yield call(API.doExpirePromotionReward, action.promoRewardId);
    if (response) {
      yield put({ response, type: actionTypes.CM_DO_EXPIRE_PROMOTION_REWARD_SUCCESS });
    } else {
      yield put({ type: actionTypes.CM_DO_EXPIRE_PROMOTION_REWARD_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.CM_DO_EXPIRE_PROMOTION_REWARD_FAILED });
  }
}

function* fetchBrands() {
  try {
    const { response, xhr } = yield call(API.fetchBrands);
    if (response) {
      const brandId = yield select((state) => state.apiConstants.values.userBrand.id);
      yield put({ brandId, brands: response, type: actionTypes.FETCH_BRANDS_SUCCESS });
    } else {
      yield put({ type: actionTypes.FETCH_BRANDS_ERROR });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_BRANDS_ERROR });
    toastr.add({ message: i18n.t("Manager.Something went wrong"), type: "ERROR" });
  }
}

function* validateCsv(action) {
  try {
    const file = action.file;
    file.append("brandId", action.brandId);
    const { response, xhr } = yield call(API.validateCSV, file);
    if (response) {
      if (response.errorList.length > 0) {
        yield put({ errors: response.errorList, type: actionTypes.VALIDATE_BULK_CSV_ERROR });
      } else {
        yield put({ success: response.bulkCreditDetailsList, type: actionTypes.VALIDATE_BULK_CSV_SUCCESS });
      }
    } else {
      toastr.add({ message: i18n.t("Manager.Something went wrong"), type: "ERROR" });
    }
  } catch (e) {
    toastr.add({ message: i18n.t("Manager.Something went wrong"), type: "ERROR" });
  }
}

function* uplaodBulkCredit(action) {
  try {
    const { response, xhr } = yield call(API.uploadBulkCredit, action.bulkCreditDetails, action.brandId);
    if (response) {
      yield put({ bulkCredits: response.bulkCreditDetailsList, type: actionTypes.REQUEST_BULK_CREDIT_SUCCESS });
    } else {
      yield put({ type: actionTypes.REQUEST_BULK_CREDIT_ERROR });
      toastr.add({ message: i18n.t("Manager.Something went wrong"), type: "ERROR" });
    }
  } catch (e) {
    yield put({ type: actionTypes.REQUEST_BULK_CREDIT_ERROR });
    toastr.add({ message: i18n.t("Manager.Something went wrong"), type: "ERROR" });
  }
}

export default function* customerManagerSaga() {
  yield takeLatest(actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS, getMainOperatorPermission);
  yield takeLatest(actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT, getMainOperatorPermissionFromRedirect);
  yield takeLatest(actionTypes.SEARCH_ACCOUNT, searchAccount);
  yield takeLatest(actionTypes.ADVANCE_SEARCH_ACCOUNT, advanceSearchAccount);
  yield takeLatest(actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER, advanceSearchAccountTransfer);
  yield takeLatest(actionTypes.FETCH_ACCOUNT_DETAILS, getAccountDetails);
  yield takeLatest(actionTypes.LOAD_DETAILS_SILENT, getAccountDetailsSilent);
  yield takeLatest(actionTypes.FETCH_ACCOUNT_PERMISSIONS, getAccountPermissions);
  yield takeLatest(actionTypes.VALIDATE_PIN, validatePin);
  yield takeLatest(actionTypes.INITIALIZE_CALL, initializeCall);
  yield takeLatest(actionTypes.END_CALL, endCall);
  yield takeLatest(actionTypes.FETCH_TRANSACTIONS, getTransactions);
  yield takeLatest(actionTypes.FETCH_TRANSACTION_DETAILS, getTransactionDetails);
  yield takeLatest(actionTypes.FETCH_RECENT_ACCOUNTS, getRecentAccounts);
  yield takeLatest(actionTypes.FETCH_ACCOUNT_BALANCE, getAccountBalance);
  yield takeLatest(actionTypes.FETCH_TRANSACTION_TYPE, getTransactionTypes);
  yield takeLatest(actionTypes.ADJUST_ACCOUNT, accountAdjust);
  yield takeLatest(actionTypes.FETCH_FX_RATE, getFXRate);
  yield takeLatest(actionTypes.ACCOUNT_TRANSFER, accountTransfer);
  yield takeLatest(actionTypes.CREATE_ACCOUNT, createAccount);
  yield takeLatest(actionTypes.GET_OPTIONAL_FIELDS, getOptionalFields);
  yield takeLatest(actionTypes.REVOKE_TRANSACTION, revokeTransaction);
  yield takeLatest(actionTypes.DELETE_TRANSACTION, deleteTransaction);
  yield takeLatest(actionTypes.FETCH_BETSTATISTICS, getBetStatistics);
  yield takeLatest(actionTypes.FETCH_ACCOUNTSTATS, getAccountStats);
  yield takeLatest(actionTypes.CM_OPERATOR_LOGIN, loginOperator);
  yield takeLatest(actionTypes.CM_GET_MONEYTYPES, getMoneyTypes);
  yield takeLatest(actionTypes.CM_DO_EXPIRE_PROMOTION_REWARD, doExpirePromotionReward);
  yield takeLatest(actionTypes.VALIDATE_BULK_CSV, validateCsv);
  yield takeLatest(actionTypes.REQUEST_BULK_CREDIT, uplaodBulkCredit);
  yield takeLatest(actionTypes.FETCH_BRANDS, fetchBrands);
}

export { renderGenericErrorToastMessage };
