import _ from "lodash";

export const modifiyDataForTable = (response, alldata) => {
  const type = [];
  const obj1 = {};
  const obj2 = {};
  _.map(response, (item, index) => {
    const label = _.find(alldata, (o) => o.id == item.id);
    const prefixName =
      item.prefix && item.prefix !== null ? item.prefix.split(" ").join("") : item.suffix.split(" ").join("");
    const enableValue = label !== undefined && label.enabledYN !== undefined ? label.enabledYN : null;
    if (item.inRunning) {
      obj1[`${prefixName}ordinalPosition`] = item.ordinalPosition;
      obj1[`${prefixName}enabledYN`] = enableValue;
      obj1["periodType"] = "Live";
      obj1[`${prefixName}id`] = item.id;
    } else {
      obj2[`${prefixName}ordinalPosition`] = item.ordinalPosition;
      obj2[`${prefixName}enabledYN`] = enableValue;
      obj2["periodType"] = "Main Book";
      obj2[`${prefixName}id`] = item.id;
    }
  });
  !_.isEmpty(obj2) && type.push(obj2);
  !_.isEmpty(obj1) && type.push(obj1);

  return type;
};
export const createTable = (response) => {
  const headerArray = [];
  let a = [];
  _.map(response, (item, index) => {
    const prefixName = item.prefix && item.prefix !== null ? item.prefix : item.suffix;
    const dateName =
      item.prefix && item.prefix !== null ? item.prefix.split(" ").join("") : item.suffix.split(" ").join("");
    const itemExist = _.findIndex(headerArray, (val) => val.prefixName == prefixName);
    if (item.inRunning && itemExist < 0) {
      a.push({
        datakey: `${dateName}enabledYN`,
        subTableName: "Enabled",
      });
      a.push({
        datakey: `${dateName}ordinalPosition`,
        subTableName: "Sort Ovde",
      });
      headerArray.push({
        prefixName,
        subKeys: a,
      });
      a = [];
    } else if (headerArray.length == 0 && !item.inRunning) {
      a.push({
        datakey: `${dateName}enabledYN`,
        subTableName: "Enabled",
      });
      a.push({
        datakey: `${dateName}ordinalPosition`,
        subTableName: "Sort Ovde",
      });
      headerArray.push({
        prefixName,
        subKeys: a,
      });
      a = [];
    }
  });

  return headerArray;
};

export const createMarketDate = (response) => {
  const marketData = [];
  _.map(response, (market, index) => {
    // console.log(market,"section")
  });

  return marketData;
};
