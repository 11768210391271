import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchInitialJackPotBetData(action) {
  const url = `bo/jackpots?endDate=${action.details.endDate}&settled=${action.details.settled}&name=${action.details.text}&active=${action.details.active}&disabled=${action.details.disabled}&jackpotBetTypeId=${action.details.jackpotBetTypeId}&startDate=${action.details.startDate}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function disableSelectedJackPot(action) {
  const url = ` bo/jackpots/${action.id}/disable`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, action.payload)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onfetchJackPotMarket(action) {
  const url = `bo/markets?marketIds=${action}&lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((responses) => ({ responses: responses.data }))
    .catch((xhrs) => ({ xhrs }));
}

export function onfetchJackPotEvent(action) {
  const url = `bo/events?ids=${action}&lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
