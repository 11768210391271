const constants = {
  DELETE_ROW: "PPG::DELETE_ROW",
  DELETION_FAILED: "PPG::DELETION_FAILED",
  FETCH_PLAYER_PROFILE_GROUP: "PPG::FETCH_PLAYER_PROFILE_GROUP",

  FETCH_PLAYER_PROFILE_GROUP_FAILED: "PPG::FETCH_PLAYER_PROFILE_GROUP_FAILED",
  FETCH_PLAYER_PROFILE_GROUP_SUCCEEDED: "PPG::FETCH_PLAYER_PROFILE_GROUP_SUCCEEDED",
  ON_EXIT_PLAYER_GROUP_PAGE: "PPG::ON_EXIT_PLAYER_GROUP_PAGE",

  ON_SAVE_PLAYER_GROUP: "PPG::ON_SAVE_PLAYER_GROUP",

  ON_SAVE_PLAYER_GROUP_FAILED: "PPG::ON_SAVE_PLAYER_GROUP_FAILED",
  ON_SAVE_PLAYER_GROUP_SUCCEEDED: "PPG::ON_SAVE_PLAYER_GROUP_SUCCEEDED",
  SELECT_ROW_TO_EDIT: "PPG::SELECT_ROW_TO_EDIT",

  UPDATE_FIELD: "PPG::UPDATE_FIELD",
};

export default constants;
