import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services/apiUtils";

const operatorPasswordXHR = null;

export function updateOperatorPassword(operatorId, formData) {
  // const url = `/bo/operators/${operatorId}`;
  const url = `/bo/operators/pwd`;

  return performHttpCall(operatorPasswordXHR, httpMethods.HTTP_PUT, url, formData)
    .then((response) => ({ response: response.data }))
    .catch((error) => {
      if (error?.response?.data?.errors?.length > 0) {
        return { error: error?.response?.data?.errors[0]?.message };
      }

      return { error };
    });
}

export function resetNewOperatorPassword(operatorId, formData) {
  // formData.accountId = 4893259;

  if (formData.accountId) {
    formData.username = formData.accountId;
    delete formData["accountId"];
  }

  const url = `/bo/accounts/operator/resetpassword`;

  return performHttpCall(operatorPasswordXHR, httpMethods.HTTP_POST, url, formData)
    .then((response) => ({ response: response.data }))
    .catch((error) => {
      if (error?.response?.data?.errors?.length > 0) {
        return { error: error?.response?.data?.errors[0]?.message };
      }

      return { error };
    });
}
