import { put, call, fork, select, takeLatest } from "redux-saga/effects";

import channelConfig from "../configs/channelConfig";
import actionTypes from "../constants";
import * as ResultsViewerDataService from "../services/resultsViewerData";
import { formatFilterDates } from "../utils";

function* fetchResultsViewerData(action) {
  try {
    const params = yield select((state) => state.resultsViewerParameters);
    // params = { ...params, ...formatFilterDates(params.date) }
    const { response, xhr } = yield call(ResultsViewerDataService.fetchResultsViewerData, params);
    ResultsViewerDataService.filterEvents(params.market, params.period, params.eventSearchString);
    if (response.success) {
      yield put({
        hasUpdates: true,
        type: actionTypes.FETCH_RESULTS_VIEWER_DATA_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.FETCH_RESULTS_VIEWER_DATA_FAILED });
    }
  } catch (error) {
    yield put({ type: actionTypes.FETCH_RESULTS_VIEWER_DATA_FAILED });
  }
}

function* updateResultsViewerData(action) {
  try {
    let params = yield select((state) => state.resultsViewerParameters);
    //     params = { ...params, ...formatFilterDates(params.date) };
    params = { ...params };
    const { response, xhr } = yield call(ResultsViewerDataService.updateResultsViewerData, params);
    if (response.hasUpdates) {
      // only filter events if there's an update
      ResultsViewerDataService.filterEvents(params.market, params.period, params.eventSearchString);
    }
    if (response.success) {
      yield put({
        hasUpdates: response.hasUpdates,
        type: actionTypes.UPDATE_RESULTS_VIEWER_DATA_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.UPDATE_RESULTS_VIEWER_DATA_FAILED });
    }
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_RESULTS_VIEWER_DATA_FAILED });
  }
}

function* filterResultsViewerDataByMarket(action) {
  const params = yield select((state) => state.riskParameters);
  ResultsViewerDataService.filterEvents(action.market, params.period, params.eventSearchString);
  yield put({
    type: actionTypes.FETCH_RESULTS_VIEWER_DATA_SUCCEEDED,
  });
}

export default function* riskDataSaga() {
  yield takeLatest(actionTypes.FETCH_RESULTS_VIEWER_DATA, fetchResultsViewerData);
  yield takeLatest(actionTypes.UPDATE_RESULTS_VIEWER_DATA, updateResultsViewerData);
}
