import constants from "./constants";

const initialState = {
  backupWager: [],
  edit: false,
  isFetchingWager: false,
  isFetchingWagerFailed: false,
  marketTypes: [],
  msg: "",
  selectedRow: "",
  updatedWagerItems: {
    data: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  view: false,
  wager: [],
};

const utilitiesWagerLimit = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS:
      return {
        ...state,
        isFetchingWager: true,
        isFetchingWagerFailed: false,
        msg: "",
      };
    case constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS_SUCCEEDED:
      return {
        ...state,
        backupWager: action.response,
        isFetchingWager: false,
        wager: action.response,
      };
    case constants.FETCH_UTILITIES_WAGER_LIMIT_GROUPS_FAILED:
      return {
        ...state,
        isFetchingWager: false,
        isFetchingWagerFailed: true,
        msg: action.msg,
      };
    case constants.ADD_NEW_WAGER_LIMIT_GROUP: {
      const wager = _.cloneDeep(state.wager);
      const updatedWagerItems = _.cloneDeep(state.updatedWagerItems);
      wager[wager.length] = action.payload.data;
      updatedWagerItems.data = [0];

      return {
        ...state,
        selectedRow: "",
        updatedWagerItems,
        wager,
      };
    }
    case constants.UPDATE_WAGER_LIMIT_GROUPS_FIELDS: {
      const { response } = action;
      const wager = _.cloneDeep(state.wager);
      const updatedWagerItems = _.cloneDeep(state.updatedWagerItems);
      const index = _.findIndex(wager, (o) => o.id == response.id);
      wager[index].active = response.active;
      wager[index].description = response.desc;
      _.findIndex(updatedWagerItems.data, (val) => val == response.id) < 0
        ? updatedWagerItems.data.push(response.id)
        : null;

      return {
        ...state,
        updatedWagerItems,
        wager,
      };
    }
    case constants.UNDO_UPDATED_WAGER_LIMITS_FIELDS: {
      const updatedWagerItems = _.cloneDeep(state.updatedWagerItems);
      updatedWagerItems.data = [];

      return {
        ...state,
        edit: false,
        updatedWagerItems,
        view: false,
        wager: state.backupWager,
      };
    }

    case constants.ADD_NEW_WAGER_LIMIT_GROUP_SUCCEED: {
      const wager = _.cloneDeep(state.wager);
      const updatedWagerItems = _.cloneDeep(state.updatedWagerItems);
      const index = _.findIndex(wager, (o) => o.id === 0);
      const backupWager = _.cloneDeep(state.backupWager);
      wager[index] = action.response;
      backupWager[index] = action.response;
      updatedWagerItems.data = [];

      return {
        ...state,
        backupWager,
        edit: false,
        selectedRow: "",
        updatedWagerItems,
        wager,
      };
    }

    case constants.UPDATE_WAGER_LIMIT_GROUPS_SUCCEEDED: {
      const wager = _.cloneDeep(state.wager);
      const updatedWagerItems = _.cloneDeep(state.updatedWagerItems);
      const index = _.findIndex(wager, (o) => o.id == updatedWagerItems.data[0]);
      const backupWager = _.cloneDeep(state.backupWager);
      wager[index] = action.response;
      backupWager[index] = action.response;
      updatedWagerItems.data = [];

      return {
        ...state,
        backupWager,
        edit: false,
        selectedRow: "",
        updatedWagerItems,
        wager,
      };
    }
    case constants.SELECT_ROW: {
      return {
        ...state,
        selectedRow: action.payload,
      };
    }
    case constants.UNSELECT_ROW: {
      return {
        ...state,
        edit: false,
        selectedRow: "",
      };
    }
    case constants.EDIT_WAGER_LIMIT_GROUPS: {
      return {
        ...state,
        edit: true,
      };
    }
    case constants.GET_WAGER_MARKET_DETAILS_SUCCEEDED: {
      return {
        ...state,
        marketTypes: action.payload,
        view: true,
      };
    }
    case constants.CLOSE_WAGER_MARKET_TYPE_MODAL: {
      return {
        ...state,
        selectedRow: "",
        view: false,
      };
    }
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesWagerLimit;
