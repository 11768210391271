import constants from "./constants";

export function setGridData(data) {
  return {
    data,
    type: constants.SET_GRID_DATA,
  };
}

export function setGridState(isLoading) {
  return {
    isLoading,
    type: constants.SET_GRID_LOADING_STATE,
  };
}

export function setGridColumns(config) {
  return {
    config,
    type: constants.SET_GRID_COLUMN_CONFIG,
  };
}

export function setGridSortConfig({ sortBy, sortDirection }) {
  return {
    sortBy,
    sortDirection,
    type: constants.SET_GRID_SORT_CONFIG,
  };
}

export function sortGrid({ sortBy, sortDirection }) {
  return {
    sortBy,
    sortDirection,
    type: constants.SORT_GRID,
  };
}

export function filterGrid(criteria, searchBy) {
  return {
    criteria,
    searchBy,
    type: constants.FILTER_GRID,
  };
}

export function clearState() {
  return {
    type: constants.CLEAR_STATE,
  };
}
