import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchEvent(eventId) {
  const url = `/bo/events/${eventId}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventDefaultMarket(eventId) {
  const url = `/bo/events/defaultMarket?&eventIds=${eventId}&lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventSelectedOpponents(marketId) {
  const url = `/bo/outcomes?marketId=${marketId}&lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteEvent(eventId) {
  const url = `/bo/events/${eventId}?lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_DELETE, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function deleteEvents(eventIds) {
  const url = `/bo/events/${eventIds.join(",")}?lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_DELETE, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateEvent(eventId, data) {
  const url = `/bo/events/${eventId}?lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchOpponents(eventPathId) {
  const url = `/bo/eventpaths/${eventPathId}/opponents`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchOpponentsByType(data) {
  const { eventPathId, mode } = data;
  const url = `/bo/eventpaths/${eventPathId}/opponents?lineId=2&opponentType=${mode}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchDefaultTemplate(eventPathId, eventTypeId) {
  const url = `/bo/templates/default/selected?eventPathId=${eventPathId}&eventTypeId=${eventTypeId}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchDefaultTemplateSettings(templateId) {
  const url = `bo/eventTemplates?templateIds=${templateId}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createEvent(eventPathId, data) {
  const url = `/bo/eventpaths/${eventPathId}/events?lineId=2`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPlayersOfOpponent(opponentId) {
  const url = `/bo/opponents/${opponentId}/players`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
