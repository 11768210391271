import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function onWordSearch(action) {
  const url = `/bo/translations/search`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onGetAllLocales(action) {
  const url = `/bo/translations/locales?showPreferred=true`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function setAllLocales(action) {
  const url = `/bo/translations/locales/preference`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, action.locales)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onAddBaseVocab(action) {
  const url = `/bo/vocab/add`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function onDeleteBaseTranslations(action) {
  const url = `/bo/translations/save/`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function onUpdateBaseVocab(action) {
  const url = `/bo/translations/save/`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onDebugWordSreach(action) {
  const url = `/bo/translations/debug`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, action.baseWord)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
