import _ from "lodash";

import constants from "./constants";
import { traverseChildren, generateKey } from "./helpers";

import { makeIterable } from "utils";

const initialState = {
  backUp: null,
  fetchingPlayerProfileSuccess: false,
  isFetching: false,
  isFetchingTree: false,
  isFetchingTreeFailed: false,
  isTreeLoaded: false,
  modifiedRow: {},
  parentToExpand: null,
  playerProfileGroups: null,
  selectedRowToEdit: null,
  selectedSport: null,
  totalModified: false,
  treeMap: {},
  updateWagerLimitSuccessfully: false,
  wagerActions: null,
  wagerLimits: null,
  wagerLimitsGroup: [],
  wagerTree: null,
};

const WagerLimitsApp = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_EVENT_PATH:
      return {
        ...state,
        isFetchingTree: true,
        isFetchingTreeFailed: false,
        isTreeLoaded: false,
        parentToExpand: action.parent || null,
        treeMap: {},
      };
    case constants.FETCH_EVENT_PATH_SUCCEEDED:
      // let treeMap = { ...state.treeMap };
      let treeMap = state.treeMap;
      const data = action.response;
      treeMap[data.id] = data;
      treeMap[data.id]["isExpanded"] = true;
      treeMap[data.id]["level"] = 1;
      treeMap[data.id]["type"] = "path";
      // treeMap[data.id]['isActive']=false
      treeMap[data.id]["children"] = data.children.map((val) => {
        treeMap = traverseChildren({ item: val, parentLevel: 1, treeMap });

        return generateKey(val);
      });
      treeMap = treeMap;

      return {
        ...state,
        isFetchingTree: false,
        isTreeLoaded: true,
        treeMap,
        wagerTree: action.response,
      };
    case constants.FETCH_EVENT_PATH_FAILED:
      return {
        ...state,
        isFetchingTree: false,
        isFetchingTreeFailed: true,
      };
    case constants.UPDATE_TREE:
      return {
        ...state,
        treeMap: {
          ...state.treeMap,
          [action.id]: { ...state.treeMap[action.id], ...action.data },
        },
      };
    case constants.PATH_CLICK:
      return {
        ...state,
        selectedSport: action.path,
      };
    case constants.FETCH_PLAYER_PROFILE_GROUPS_SUCCEEDED:
      return {
        ...state,
        fetchingPlayerProfileSuccess: true,
        playerProfileGroups: action.response,
      };
    case constants.FETCH_PLAYER_PROFILE_GROUPS_FAILED:
      return {
        ...state,
        fetchingPlayerProfileSuccess: false,
      };
    case constants.FETCH_WAGER_LIMITS_SUCCEEDED:
      return {
        ...state,
        backUp: JSON.stringify(action.response),
        isFetching: false,
        modifiedRow: {},
        wagerLimits: JSON.stringify(action.response),
      };
    case constants.FETCH_WAGER_LIMITS_FAILED:
      return {
        ...state,
        wagerLimits: null,
      };
    case constants.FETCH_WAGER_ACTIONS_SUCCEEDED:
      return {
        ...state,
        wagerActions: action.response,
      };
    case constants.FETCH_WAGER_ACTIONS_FAILED:
      return {
        ...state,
        wagerActions: null,
      };
    case constants.SELECT_ROW_TO_EDIT:
      const valueTest = JSON.parse(state.backUp);
      const selectedRow = _.find(valueTest.wagerLimits, (obj1) => obj1.id === action.val.id);

      return {
        ...state,
        selectedRowToEdit: JSON.stringify(selectedRow),
      };
    case constants.ON_EXIT_WAGER_LIMITS:
      const onExitBackup = JSON.parse(state.backUp).wagerLimits;

      return {
        ...state,
        backUp: JSON.stringify({ wagerLimits: onExitBackup }),
        modifiedRow: {},
        wagerLimits: JSON.stringify({ wagerLimits: onExitBackup }),
      };
    case constants.UPDATE_FIELD:
      const { field, row, value } = action;
      let fieldValue = value;
      if (_.includes(["maxBetStake", "maxBetLiability", "maxRestrictedBetStake", "maxRestrictedBetLiability"], field)) {
        fieldValue = parseInt(fieldValue).toString();
        fieldValue = fieldValue.replace(/^0+/, "");
        fieldValue = _.isEmpty(fieldValue) ? "0" : fieldValue;
      }
      const selectedRowToEdit = JSON.parse(state.selectedRowToEdit);
      let isModified = !_.isEqual(selectedRowToEdit[field], fieldValue * 1);
      const modifiedRow = state.modifiedRow;
      const preBackup = JSON.parse(state.backUp).wagerLimits;
      const totalModified = state.totalModified;
      const wagerLimitss = JSON.parse(state.wagerLimits).wagerLimits;
      _.map(wagerLimitss, (item, indx) => {
        let new_Limit;
        let previousValue;
        let previous_Limit;
        if (field == "maxBetSkakePercent" || field == "maxBetLiabilityPercent") {
          previousValue = wagerLimitss[indx][field];
          if (previousValue == undefined) {
            previous_Limit =
              field == "maxBetSkakePercent"
                ? wagerLimitss[indx]["maxRestrictedBetStake"]
                : wagerLimitss[indx]["maxRestrictedBetLiability"];
          }
        }
        if (wagerLimitss[indx].id == row.id) {
          if (field == "maxBetStake") {
            wagerLimitss[indx]["maxBetSkakePercent"] = _.round(parseFloat(row.maxBetSkakePercent * 1), 1).toFixed(1);
            const changedValue = parseInt((parseFloat(fieldValue) / 100) * (row.maxBetSkakePercent * 1));
            wagerLimitss[indx]["maxRestrictedBetStake"] = changedValue == "Infinity" ? 0 : changedValue;
          }
          if (field == "maxRestrictedBetStake") {
            let changedValue =
              parseFloat(fieldValue) == 0
                ? parseFloat(parseFloat(fieldValue))
                : parseFloat((parseFloat(fieldValue) / (row.maxBetStake * 1)) * 100);
            if (changedValue == "Infinity") {
              wagerLimitss[indx]["maxBetSkakePercent"] = 0;
            } else {
              changedValue = changedValue.toString();
              if (changedValue.indexOf(".") === -1) {
                wagerLimitss[indx]["maxBetSkakePercent"] = parseInt(changedValue * 1).toFixed(1);
              } else {
                wagerLimitss[indx]["maxBetSkakePercent"] = _.round(changedValue * 1, 1).toFixed(1);
              }
            }
          }
          if (field == "maxBetSkakePercent") {
            const changedValue = parseInt((parseFloat(fieldValue) / 100) * (row.maxBetStake * 1));
            wagerLimitss[indx]["maxRestrictedBetStake"] = changedValue == "Infinity" ? 0 : changedValue;
          }
          if (field == "maxBetLiability") {
            wagerLimitss[indx]["maxBetLiabilityPercent"] = _.round(parseFloat(row.maxBetLiabilityPercent * 1)).toFixed(
              1,
            );
            const changedValue = parseInt((parseFloat(fieldValue) / 100) * (row.maxBetLiabilityPercent * 1));
            wagerLimitss[indx]["maxRestrictedBetLiability"] = changedValue == "Infinity" ? 0 : changedValue;
          }
          if (field == "maxRestrictedBetLiability") {
            let changedValue =
              parseFloat(fieldValue) == 0
                ? parseFloat(parseFloat(fieldValue))
                : parseFloat((parseFloat(fieldValue) / (row.maxBetLiability * 1)) * 100);
            if (changedValue == "Infinity") {
              wagerLimitss[indx]["maxBetLiabilityPercent"] = 0;
            } else {
              changedValue = changedValue.toString();
              if (changedValue.indexOf(".") === -1) {
                wagerLimitss[indx]["maxBetLiabilityPercent"] = parseInt(changedValue * 1).toFixed(1);
              } else {
                wagerLimitss[indx]["maxBetLiabilityPercent"] = _.round(changedValue * 1, 1).toFixed(1);
              }
            }
          }
          if (field == "maxBetLiabilityPercent") {
            const changedValue = parseInt((parseFloat(fieldValue) / 100) * (row.maxBetLiability * 1));
            wagerLimitss[indx]["maxRestrictedBetLiability"] = changedValue == "Infinity" ? 0 : changedValue;
          }
          if (field == "maxBetSkakePercent" || field == "maxBetLiabilityPercent") {
            if (field == "maxBetSkakePercent") {
              if (fieldValue.indexOf(".") === -1) {
                wagerLimitss[indx][field] = parseInt(fieldValue * 1).toFixed(1);
              } else {
                wagerLimitss[indx][field] = _.round(fieldValue * 1, 1).toFixed(1);
              }
              new_Limit = wagerLimitss[indx]["maxRestrictedBetStake"];
            } else if (field == "maxBetLiabilityPercent") {
              if (fieldValue.indexOf(".") === -1) {
                wagerLimitss[indx][field] = parseInt(fieldValue * 1).toFixed(1);
              } else {
                wagerLimitss[indx][field] = _.round(fieldValue * 1, 1).toFixed(1);
              }
              new_Limit = wagerLimitss[indx]["maxRestrictedBetLiability"];
            }
            if (previousValue == undefined) {
              if (previous_Limit == new_Limit) {
                isModified = false;
              } else {
                isModified = true;
              }
            } else if (previousValue == wagerLimitss[indx][field]) {
              isModified = false;
            } else {
              isModified = true;
            }
          } else {
            wagerLimitss[indx][field] = fieldValue;
          }
        }
      });
      if (isModified) {
        if (!modifiedRow[row.id]) {
          modifiedRow[row.id] = [];
          modifiedRow[row.id].push(field);
        } else if (!_.includes(modifiedRow[row.id], field)) {
          modifiedRow[row.id].push(field);
        }
      } else if (modifiedRow[row.id]) {
        _.remove(modifiedRow[row.id], (f) => f == field);
        if (modifiedRow[row.id].length == 0) {
          delete modifiedRow[row.id];
        }
      }

      return {
        ...state,
        isModified,
        modifiedRow,
        wagerLimits: JSON.stringify({ wagerLimits: wagerLimitss }),
      };
    case constants.FETCH_WAGER_LIMIT_GROUP_SUCCEEDED:
      return {
        ...state,
        isFetching: false,
        modifiedRow: {},
        wagerLimitsGroup: action.response,
      };
    case constants.ON_SAVE_WAGER_LIMITS_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
        updateWagerLimitSuccessfully: true,
      };
    case constants.ON_SAVE_WAGER_LIMITS_FAILED:
      return {
        ...state,
        updateWagerLimitSuccessfully: false,
      };
    case constants.ON_FETCHING:
      return {
        ...state,
        isFetching: action.data,
      };
    case constants.EMPTY_SELECTED_SPORT:
      return {
        ...state,
        selectedSport: null,
      };
    case constants.ON_REMOVE_WAGER_LIMITS_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
        updateWagerLimitSuccessfully: true,
      };
    case constants.ON_REMOVE_WAGER_LIMITS_FAILED:
      return {
        ...state,
        updateWagerLimitSuccessfully: false,
      };
    default:
      return { ...state };
  }
};

export default WagerLimitsApp;
