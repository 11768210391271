import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchAllSport() {
  const url = `/bo/sports`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPeriodType(sportsCode) {
  const url = `/bo/periods/types?code=${sportsCode}`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function fetchALLPeriodType(sportsCode) {
  const url = `/bo/periods`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchALLMarketType(sportsCode) {
  const url = `/bo/marketTypes/list?code=${sportsCode}`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketPeriodType() {
  const url = `/bo/overrides/marketperiodtype`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketPeriodType(marketPeriods) {
  const url = `/bo/overrides/marketperiodtype`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, marketPeriods, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updatePeriodTypes(periods) {
  const url = "/bo/periods/types/grades";

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, periods, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketTypes(marketTypes) {
  const url = "/bo/marketTypes";

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, marketTypes, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAllWagerLimitGroup() {
  const url = `/bo/wagerlimitgroups?activeOnly=true`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchAllMajorSort() {
  const url = `/bo/overrides/sport`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMajorSort(majorSort) {
  const url = `/bo/overrides/sport`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, majorSort, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
