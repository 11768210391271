import actionTypes from "../constants";

export function fetchApps(id) {
  return {
    id,
    type: actionTypes.FETCH_APPS,
  };
}

export function fetchRecentApps(id) {
  return {
    id,
    type: actionTypes.FETCH_RECENT_APPS,
  };
}

export function useApp(userid, appId) {
  return {
    appId,
    type: actionTypes.USE_APP,
    userid,
  };
}

export function toggleSideBar(bool) {
  return {
    bool,
    type: actionTypes.SIDEBAR_TOGGLE,
  };
}

export function fetchAppPermissions(userId) {
  return {
    type: actionTypes.FETCH_APP_PERMISSIONS,
    userId,
  };
}

export function fetchLines(data) {
  return {
    type: actionTypes.LINES,
  };
}
