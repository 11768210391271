import { paymentType, url as path } from "./constants";
import * as helpers from "./helpers";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";


const fetchPaymentsXhr = null;
const fetchApprovalCandidatesXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchPayment({ params, type }) {
  const queryParams = helpers.buildQueryParams(params);
  let url = "";

  switch (type) {
    case paymentType.WITHDRAWALS:
      url = `${path.PAYMENT_WITHDRAWALS}${queryParams}`;

      return performHttpCall(fetchPaymentsXhr, httpMethods.HTTP_GET, url, null, headers)
        .then((response) => ({ response: response.data }))
        .catch((xhr) => ({ xhr }));
      break;
    case paymentType.DEPOSITS:
      url = `${path.PAYMENT_DEPOSITS}${queryParams}`;

      return performHttpCall(fetchPaymentsXhr, httpMethods.HTTP_GET, url, null, headers)
        .then((response) => ({ response: response.data }))
        .catch((xhr) => ({ xhr }));
      break;
    default:
      break;
  }
}

export function fetchApprovalCandidates(action) {
  const query = action.query ? action.query : "";

  return performHttpCall(
    fetchApprovalCandidatesXhr,
    httpMethods.HTTP_GET,
    `${path.APPROVAL_CANDIDATES}${query}`,
    null,
    headers,
  )
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUserPreferences(id) {
  const url = `/bo/appcolumns/${id}?appId=22`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateUserPreferences({ id, preferences }) {
  const url = `/bo/appcolumns?accountId=${id}&appId=22`;

  return performHttpCall(null, httpMethods.HTTP_PUT, url, preferences)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUserNotes(id) {
  const url = `/bo/payrecdatanotes?payrecdataid=${id}`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveNote(note) {
  const url = ` /bo/payrecdatanotes`;

  return performHttpCall(null, httpMethods.HTTP_POST, url, note)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function customSearch(params, index) {
  const queryParams = helpers.buildQueryParams(params);
  const url = `${index === 0 ? path.DEPOSIT_CANDIDATES : path.PAYMENT_WITHDRAWALS}${queryParams}`;

  return performHttpCall(fetchPaymentsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchDepositApprovalCandidates(action) {
  return performHttpCall(
    fetchApprovalCandidatesXhr,
    httpMethods.HTTP_GET,
    `${action.app == "withdrawals" ? path.APPROVAL_CANDIDATES : path.DEPOSIT_APPROVAL_CANDIDATES}${action.query}`,
    null,
    headers,
  )
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateDeposits(formData) {
  return performHttpCall(null, httpMethods.HTTP_PUT, path.UPDATE_DEPOSITS, formData)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateWithdrawals(formData) {
  return performHttpCall(null, httpMethods.HTTP_PUT, path.UPDATE_WITHDRAWALS, formData)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPaymentStatusForFilter() {
  const url = "/bo/financials/payments/payrecstatus?enabledOnly=true";

  return performHttpCall(fetchApprovalCandidatesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBaseCurrency() {
  const url = "/bo/currencies/default";

  return performHttpCall(fetchApprovalCandidatesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPaymentStatusList() {
  const url = "/bo/ph/payrecstatus?enabledOnly=true";

  return performHttpCall(fetchApprovalCandidatesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchFilteredData(action) {
  const url = `/bo/ph/${action.app}${action.query}`;

  return performHttpCall(fetchApprovalCandidatesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
