import filterTypes from "constants/filterTypes";
import { getNext7DayText, getLast7DayText, getLastMonthText } from "utils";


export default {
  CREATE_PATH: "EPT::CREATE_PATH",
  DELETE_PATH: "EPT::DELETE_PATH",
  DELETE_PATHS: "EPT::DELETE_PATHS",
  FETCH_EPT: "EPT::FETCH_EPT",
  FETCH_EPT_FAILED: "EPT::FETCH_EPT_FAILED",
  FETCH_EPT_SUCCEEDED: "EPT::FETCH_EPT_SUCCEEDED",

  ADD_PATH_TO_SELECTIONS: "EPT::ADD_PATH_TO_SELECTIONS",
  FETCH_EVENT_MARKETS: "EPT::FETCH_EVENT_MARKETS",
  FETCH_EVENT_MARKETS_FAILED: "EPT::FETCH_EVENT_MARKETS_FAILED",

  FETCH_EVENT_MARKETS_SUCCEEDED: "EPT::FETCH_EVENT_MARKETS_SUCCEEDED",
  FETCH_RESTRICTION_EPT: "EPT::FETCH_RESTRICTION_EPT",
  FINALIZE_PATH: "EPT::FINALIZE_PATH",

  GET_UPDATED_VALUES_SPORT: "GET_UPDATED_VALUES_SPORT",
  REMOVE_IDS_FROM_SELECTIONS: "EPT::REMOVE_IDS_FROM_SELECTIONS",
  REMOVE_PATH_FROM_SELECTIONS: "EPT::REMOVE_PATH_FROM_SELECTIONS",
  RESET: "EPT::RESET",
  SAVE_NEW_PATHS_ORDER: "EPT::SAVE_NEW_PATHS_ORDER",
  SAVE_NEW_PATHS_ORDER_FAILED: "EPT::SAVE_NEW_PATHS_ORDER_FAILED",
  SAVE_NEW_PATHS_ORDER_SUCCEEDED: "EPT::SAVE_NEW_PATHS_ORDER_SUCCEEDED",
  SET_AS_FIRST_LOAD: "SET_AS_FIRST_LOAD",
  SET_DATES_FILTER: "EPT::SET_DATES_FILTER",
  SET_DATES_PARAM_FORMAT: "EPT::SET_DATES_PARAM_FORMAT",
  SET_DEFAULT_PARAMETERS: "EPT::SET_DEFAULT_PARAMETERS",
  SET_INITIAL_STATE_PROPS: "EPT::SET_INITIAL_STATE_PROPS",

  SET_JACKPOT_BET_STATUS_FILTER: "EPT::SET_JACKPOT_BET_STATUS_FILTER",
  SET_JACKPOT_BET_TYPE_FILTER: "EPT::SET_JACKPOT_BET_TYPE_FILTER",
  SET_MARKET_STATUS_FILTER: "EPT::SET_MARKET_STATUS_FILTER",
  SET_NEW_PATHS_ORDER: "EPT::SET_NEW_PATHS_ORDER",
  SET_PARAMETERS: "EPT::SET_PARAMETERS",

  UPDATE_ACTIVE_PATH_ANCESTORS: "EPT::UPDATE_ACTIVE_PATH_ANCESTORS",
  UPDATE_ACTIVE_PATH_ID: "EPT::UPDATE_ACTIVE_PATH_ID",
  UPDATE_ACTIVE_SPORT_CODE_AND_ID: "EPT::UPDATE_ACTIVE_SPORT_CODE_AND_ID",
  UPDATE_CONFIG: "EPT::UPDATE_CONFIG",

  UPDATE_FILTER_STATE: "EPT::UPDATE_FILTER_STATE",
  UPDATE_PATH: "EPT::UPDATE_PATH",
  UPDATE_PATHS: "EPT::UPDATE_PATHS",

  UPDATE_PATH_BASE_URL: "EPT::UPDATE_PATH_BASE_URL",
  UPDATE_SEARCH_OPTION: "EPT::UPDATE_SEARCH_OPTION",
  UPDATE_SEARCH_STR: "EPT::UPDATE_SEARCH_STR",
  UPDATE_SORT_STATE: "EPT::UPDATE_SORT_STATE",

  UPDATE_TOGGLE_STATE: "EPT::UPDATE_TOGGLE_STATE",
  UPDATE_VIRTUAL_SPORT: "EPT::UPDATE_VIRTUAL_SPORT",
};

const dates = filterTypes.DATES;
const otherDates = filterTypes.OTHER_DATES;

export const availableDates = [
  `${dates.NEXT_7_DAYS} (${getNext7DayText()})`,
  dates.TODAY,
  dates.TOMORROW,
  dates.FUTURE,
  dates.YESTERDAY,
  `${otherDates.LAST_7_DAYS} (${getLast7DayText()})`,
  `${otherDates.LAST_MONTH} (${getLastMonthText()})`,
  dates.CUSTOM,
];

export const riskAvailableDates = [
  /* dates.ALL_DATES, */
  `${dates.NEXT_7_DAYS} (${getNext7DayText()})`,
  dates.TODAY,
  dates.TOMORROW,
  dates.FUTURE,
  dates.YESTERDAY,
  `${otherDates.LAST_7_DAYS} (${getLast7DayText()})`,
  `${otherDates.LAST_MONTH} (${getLastMonthText()})`,
  dates.CUSTOM,
];
export const resultsViewerAvailableDates = [
  dates.ALL_DATES,
  otherDates.EVENT_2HRS_AGO,
  otherDates.EVENT_4HRS_AGO,
  dates.TODAY,
  dates.YESTERDAY,
  dates.CUSTOM,
];
export const DUMMY_ID = -123456789;
