import moment from "moment";

import filterTypes from "constants/filterTypes";
import {
  getNext7DayText,
  getLast7DayText,
  getLastMonthText /* getLast6MonthText, getThisYearText, getLastYearText */,
} from "utils";


const constants = {
  FETCH_INITIAL_JACKPOT_WINNER_DATA: "FETCH_INITIAL_JACKPOT_WINNER_DATA",
  FETCH_INITIAL_JACKPOT_WINNER_DATA_FAILED: "FETCH_INITIAL_JACKPOT_WINNER_DATA_FAILED",

  FETCH_INITIAL_JACKPOT_WINNER_DATA_SUCCEEDED: "FETCH_INITIAL_JACKPOT_WINNER_DATA_SUCCEEDED",
  HIDE_NEW_WINNER_DIALOG_MODAL: "HIDE_NEW_WINNER_DIALOG_MODAL",
  PAYOUT_JACKPOT_BET_WINNER: "PAYOUT_JACKPOT_BET_WINNER",

  PAYOUT_JACKPOT_BET_WINNER_FAILED: "PAYOUT_JACKPOT_BET_WINNER_FAILED",
  PAYOUT_JACKPOT_BET_WINNER_SUCCEEDED: "PAYOUT_JACKPOT_BET_WINNER_SUCCEEDED",
  SELECTION_COUNT_CHANGE: "SELECTION_COUNT_CHANGE",

  SHOW_NEW_WINNER_DIALOG_MODAL: "SHOW_NEW_WINNER_DIALOG_MODAL",
};

const dates = filterTypes.WINNER_DATES;

export const availableDates = [
  dates.LAST_HOUR,
  dates.LAST_24,
  dates.LAST_48,
  `${dates.THIS_WEEK} (${moment().startOf("week").format("L")} to ${moment().endOf("week").format("L")})`,
  `${dates.LAST_WEEK} (${moment().subtract(1, "w").startOf("week").format("L")} to ${moment()
    .subtract(1, "w")
    .endOf("week")
    .format("L")})`,
  `${dates.THIS_MONTH} (${moment().startOf("month").format("L")} to ${moment().endOf("month").format("L")})`,
  `${dates.LAST_MONTH} (${getLastMonthText()})`,
  // `${dates.LAST_6_MONTH} (${getLast6MonthText()})`,
  // `${dates.THIS_YEAR} (${moment()
  //   .startOf('year')
  //   .format('L')} to ${moment()
  //   .endOf('year')
  //   .format('L')})`,
  dates.THIS_YEAR,
  // `${dates.LAST_YEAR} (${getLastYearText()})`,
  // `${dates.LAST_YEAR} (${moment()
  //   .subtract(1, 'y')
  //   .startOf('year')
  //   .format('L')} to ${moment()
  //   .subtract(1, 'y')
  //   .endOf('year')
  //   .format('L')})`,
  dates.CUSTOM,
];

export default constants;
