import _ from "lodash";

const handleLockUnlock = (emergencyLockdown, object) => {
  if (object.selected) {
    let value;
    if (object.type == "lockSelected") {
      value = true;
    } else {
      value = false;
    }
    _.map(emergencyLockdown, (item, index) => {
      if (_.includes(object.selected, item.id)) {
        emergencyLockdown[index]["locked"] = value;
      }
    });
  } else if (object.type == "lockAll") {
    _.map(emergencyLockdown, (item, index) => {
      emergencyLockdown[index]["locked"] = true;
    });
  } else if (object.type == "unLockAll") {
    _.map(emergencyLockdown, (item, index) => {
      emergencyLockdown[index]["locked"] = false;
    });
  }

  return emergencyLockdown;
};

export default handleLockUnlock;
