const constants = {
  COPY_PROMOTIONAL_GROUP: "BM::COPY_PROMOTIONAL_GROUP",
  DELETE_PROMOTIONAL_GROUP_DETAILS: "BM::DELETE_PROMOTIONAL_GROUP_DETAILS",
  DELETE_PROMOTIONAL_GROUP_DETAILS_FAILED: "BM::DELETE_PROMOTIONAL_GROUP_DETAILS_FAILED",
  DELETE_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED: "BM::DELETE_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED",
  FETCH_PROMOTIONAL_GROUPS: "BM::FETCH_PROMOTIONAL_GROUPS",
  EXPORT_MEMBERS: "BM::EXPORT_MEMBERS",

  FETCH_PROMOTIONAL_GROUPS_FAILED: "BM::FETCH_PROMOTIONAL_GROUPS_FAILED",

  EXPORT_MEMBERS_FAILED: "BM::EXPORT_MEMBERS_FAILED",
  FETCH_PROMOTIONAL_GROUPS_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUPS_SUCCEEDED",
  EXPORT_MEMBERS_SUCCEEDED: "BM::EXPORT_MEMBERS_SUCCEEDED",

  FETCH_PROMOTIONAL_GROUP_DETAILS: "BM::FETCH_PROMOTIONAL_GROUP_DETAILS",
  FETCH_PROMOTIONAL_GROUP_DETAILS_FAILED: "BM::FETCH_PROMOTIONAL_GROUP_DETAILS_FAILED",
  FETCH_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUP_DETAILS_SUCCEEDED",

  FETCH_PROMOTIONAL_GROUP_FILTERS: "BM::FETCH_PROMOTIONAL_GROUP_FILTERS",
  FETCH_PROMOTIONAL_GROUP_FILTERS_FAILED: "BM::FETCH_PROMOTIONAL_GROUP_FILTERS_FAILED",
  FETCH_PROMOTIONAL_GROUP_FILTERS_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUP_FILTERS_SUCCEEDED",

  FETCH_PROMOTIONAL_GROUP_MEMBERS: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS",

  FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT",

  FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_FAILED: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_FAILED",
  FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS_COUNT_SUCCEEDED",
  FETCH_PROMOTIONAL_GROUP_MEMBERS_FAILED: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS_FAILED",

  FETCH_PROMOTIONAL_GROUP_MEMBERS_SUCCEEDED: "BM::FETCH_PROMOTIONAL_GROUP_MEMBERS_SUCCEEDED",
  IMPORT_MEMBERS: "BM::IMPORT_MEMBERS",
  IMPORT_MEMBERS_FAILED: "BM::IMPORT_MEMBERS_FAILED",

  IMPORT_MEMBERS_SUCCEEDED: "BM::IMPORT_MEMBERS_SUCCEEDED",
  NEW_PROMOTIONAL_GROUP: "BM::NEW_PROMOTIONAL_GROUP",
  NEW_PROMOTIONAL_GROUP_FAILED: "BM::NEW_PROMOTIONAL_GROUP_FAILED",
  NEW_PROMOTIONAL_GROUP_SUCCEEDED: "BM::NEW_PROMOTIONAL_GROUP_SUCCEEDED",

  PUT_PROMOTIONAL_GROUP: "BM::PUT_PROMOTIONAL_GROUP",
  PUT_PROMOTIONAL_GROUP_FAILED: "BM::PUT_PROMOTIONAL_GROUP_FAILED",
  PUT_PROMOTIONAL_GROUP_SUCCEEDED: "BM::PUT_PROMOTIONAL_GROUP_SUCCEEDED",

  RESET_FETCH_MEMBER_FAILED_ERROR: "BM::RESET_FETCH_MEMBER_FAILED_ERROR",
  RESET_PROMOTIONAL_GROUPS: "BM::RESET_PROMOTIONAL_GROUPS",
  RESET_PROMOTIONAL_GROUP_DETAIL_TO_EMPTY: "BM::RESET_PROMOTIONAL_GROUP_DETAIL_TO_EMPTY",

  RESET_PROMOTIONAL_GROUP_ITEM: "BM::RESET_PROMOTIONAL_GROUP_ITEM",
  SET_ITEM_RETIRED_STATUS: "BM::SET_ITEM_RETIRED_STATUS",
  UPDATE_PROMOTIONAL_GROUP_ITEM: "BM::UPDATE_PROMOTIONAL_GROUP_ITEM",
};

export const filterName = {
  age: "Age",
  averageBetSize: "Average bet unit stake",
  country: "Country",
  currency: "Currency",
  deposit: "Deposit",
  firstRegisteredUser: "First registered user",
  language: "Language",
  lastBetDate: "Last Bet Date",
  numberOfBets: "Number of bets",
  profit: "Profit",
  sex: "Sex",
  turnover: "Turnover",
  unitstake: "Bet unit stake",
};
export const filterOptionName = {
  after: "After",
  before: "Before",
  between: "Between",
  equals: "Equals",
  greaterthan: "Greater than",
  lessthan: "Less than",
  notbetween: "Not Between",
  notequals: "Not equals",
  notgreaterthan: "Not greater than",
  notlessthan: "Not less than",
};

export default constants;
