export function combLiabilityData(response) {
  const data = [{}, {}, {}];
  _.map(response, (val, i) => {
    const checkLiability = val.combinationLiabilityIndicatorType.split("_");
    if (checkLiability[1] === "STAKE") {
      const obj = {};
      if (checkLiability[0] == "WARNING") {
        data[0]["id"] = i;
        data[0]["Warn"] = val.cliLimit;
        data[0]["WarnActionType"] = val.combinationLiabilityActionType;
        data[0]["combLiabilityType"] = "Stake";
        data[0]["eventPathId"] = val.eventPathId;
        data[0]["combinationLiabilityIndicatorType"] = val.combinationLiabilityIndicatorType;
        data[0]["inherit_warning"] = val.inherit;
        // data.splice(0, 0, obj);
      }
      if (checkLiability[0] == "CRITICAL") {
        data[0]["Critical"] = val.cliLimit;
        data[0]["CriticalActionType"] = val.combinationLiabilityActionType;
        data[0]["inherit_critical"] = val.inherit;
      }
    }
    if (checkLiability[1] === "PAYOUT") {
      const obj1 = {};
      if (checkLiability[0] === "WARNING") {
        data[1]["id"] = i;
        data[1]["Warn"] = val.cliLimit;
        data[1]["WarnActionType"] = val.combinationLiabilityActionType;
        data[1]["combLiabilityType"] = "Payout";
        data[1]["eventPathId"] = val.eventPathId;
        data[1]["combinationLiabilityIndicatorType"] = val.combinationLiabilityIndicatorType;
        data[1]["inherit_warning"] = val.inherit;
        // data.splice(1, 0, obj1);
      }
      if (checkLiability[0] == "CRITICAL") {
        data[1]["Critical"] = val.cliLimit;
        data[1]["CriticalActionType"] = val.combinationLiabilityActionType;
        data[1]["inherit_critical"] = val.inherit;
      }
    }
    if (checkLiability[1] === "LIABILITY") {
      const obj2 = {};
      if (checkLiability[0] === "WARNING") {
        data[2]["id"] = i;
        data[2]["Warn"] = val.cliLimit;
        data[2]["WarnActionType"] = val.combinationLiabilityActionType;
        data[2]["combLiabilityType"] = "Liability";
        data[2]["eventPathId"] = val.eventPathId;
        data[2]["combinationLiabilityIndicatorType"] = val.combinationLiabilityIndicatorType;
        data[2]["inherit_warning"] = val.inherit;
        // data.splice(2, 0, obj2);
      }
      if (checkLiability[0] == "CRITICAL") {
        data[2]["Critical"] = val.cliLimit;
        data[2]["CriticalActionType"] = val.combinationLiabilityActionType;
        data[2]["inherit_critical"] = val.inherit;
      }
    }
  });

  return data;
}
export function arrangeData(val, rows, cellName, cellValue, checkLiability) {
  if (cellName === "Critical" && checkLiability[0] === "CRITICAL") {
    val.cliLimit = parseInt(cellValue);
  }
  if (cellName === "Warn" && checkLiability[0] === "WARNING") {
    val.cliLimit = parseInt(cellValue);
  }
  if (cellName === "CriticalActionType" && checkLiability[0] === "CRITICAL") {
    val.combinationLiabilityActionType = cellValue;
  }
  if (cellName === "WarnActionType" && checkLiability[0] === "WARNING") {
    val.combinationLiabilityActionType = cellValue;
  }
}
export function modifiyData(prevData, rows, cellName, cellValue) {
  _.map(prevData, (val, i) => {
    const checkLiability = val.combinationLiabilityIndicatorType.split("_");
    if (checkLiability[1] === "STAKE" && rows.combLiabilityType == "Stake") {
      arrangeData(val, rows, cellName, cellValue, checkLiability);
    }
    if (checkLiability[1] === "PAYOUT" && rows.combLiabilityType == "Payout") {
      arrangeData(val, rows, cellName, cellValue, checkLiability);
    }
    if (checkLiability[1] === "LIABILITY" && rows.combLiabilityType == "Liability") {
      arrangeData(val, rows, cellName, cellValue, checkLiability);
    }
  });

  return prevData;
}
