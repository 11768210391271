import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import * as API from "../../services/customerManager";
import { renderGenericErrorToastMessage } from "../constants";

import i18n from "i18n";

function* getOrigins() {
  try {
    const { response, xhr } = yield call(API.getOrigins);
    if (response) {
      yield put({ origins: response, type: actionTypes.FETCH_ORIGINS_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_ORIGINS_FAILED });
  }
}

function* getLines() {
  try {
    const { response, xhr } = yield call(API.getLines);
    if (response) {
      yield put({ lines: response, type: actionTypes.FETCH_LINES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LINES_FAILED });
  }
}

function* getPackages() {
  try {
    const { response, xhr } = yield call(API.getPackages);
    if (response) {
      yield put({ packages: response, type: actionTypes.FETCH_PACKAGES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_PACKAGES_FAILED });
  }
}

function* getPriceFormats() {
  try {
    const { response, xhr } = yield call(API.getPriceFormats);
    if (response) {
      yield put({ priceFormats: response, type: actionTypes.FETCH_PRICE_FORMATS_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_PRICE_FORMATS_FAILED });
  }
}

function* getLanguages() {
  try {
    const { response, xhr } = yield call(API.getLanguages);
    if (response) {
      yield put({ languages: response, type: actionTypes.FETCH_LANGUAGES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LANGUAGES_FAILED });
  }
}

function* getCountries() {
  try {
    const { response, xhr } = yield call(API.getCountries);
    if (response) {
      yield put({ countries: response, type: actionTypes.FETCH_COUNTRIES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_COUNTRIES_FAILED });
  }
}

function* getReferralMethods() {
  try {
    const { response, xhr } = yield call(API.getReferralMethods);
    if (response) {
      yield put({ referralMethods: response, type: actionTypes.FETCH_REFERRAL_METHODS_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_REFERRAL_METHODS_FAILED });
  }
}

function* getCurrencies() {
  try {
    const { response, xhr } = yield call(API.getCurrencies);
    if (response) {
      yield put({ currencies: response, type: actionTypes.FETCH_CURRENCIES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_CURRENCIES_FAILED });
  }
}

function* getSecurityQuestions() {
  try {
    const { response, xhr } = yield call(API.getSecurityQuestions);
    if (response) {
      yield put({ securityQuestions: response, type: actionTypes.FETCH_SECURITY_QUESTIONS_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_SECURITY_QUESTIONS_FAILED });
  }
}

function* getAccountTypes() {
  try {
    const { response, xhr } = yield call(API.getAccountTypes);
    if (response) {
      yield put({ accttypes: response, type: actionTypes.FETCH_ACCTTYPES_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_ACCTTYPES_FAILED });
  }
}

function* getP8Settings(action) {
  try {
    const { response, xhr } = yield call(API.getP8Settings, action.params);
    if (response) {
      yield put({ p8Settings: response, type: actionTypes.FETCH_P8SETTINGS_SUCCEEDED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_P8SETTINGS_FAILED });
  }
}

export default function* customerManagerContantsSaga() {
  yield takeLatest(actionTypes.FETCH_ORIGINS, getOrigins);
  yield takeLatest(actionTypes.FETCH_LINES, getLines);
  yield takeLatest(actionTypes.FETCH_ACCTTYPES, getAccountTypes);
  yield takeLatest(actionTypes.FETCH_PACKAGES, getPackages);
  yield takeLatest(actionTypes.FETCH_PRICE_FORMATS, getPriceFormats);
  yield takeLatest(actionTypes.FETCH_LANGUAGES, getLanguages);
  yield takeLatest(actionTypes.FETCH_COUNTRIES, getCountries);
  yield takeLatest(actionTypes.FETCH_REFERRAL_METHODS, getReferralMethods);
  yield takeLatest(actionTypes.FETCH_CURRENCIES, getCurrencies);
  yield takeLatest(actionTypes.FETCH_SECURITY_QUESTIONS, getSecurityQuestions);
  yield takeLatest(actionTypes.FETCH_P8SETTINGS, getP8Settings);
}

// export {renderGenericErrorToastMessage};
