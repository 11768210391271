import { put, call, takeLatest } from "redux-saga/effects";

import { history } from "../../../../store";
import constants from "../../App/constants";
import { buildTreeItem, buildRemoveTreeItem } from "../../Sidebar/helpers";
import { updateMessagePlanItem } from "../MessagePlans/actions";

import actionTypes from "./constants";
import * as API from "./services";

import { closeModal } from "actions/modal";
import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchCampaigns(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchCampaigns, id);
  if (response) {
    if (id) {
      yield put({ campaign: response, type: actionTypes.RECEIVE_CAMPAIGN });
    } else {
      yield put({ campaigns: response, type: actionTypes.RECEIVE_CAMPAIGNS });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}
function* fetchBrands(action) {
  const { response, xhr } = yield call(API.fetchBrands);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_BRANDS_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* createCampaign(action) {
  yield put({ type: actionTypes.SET_LOADING });
  // const { campaign } = action
  // const { response, xhr } = yield call(API.createCampaign, campaign)
  // if (response) {
  //   toastr.add({
  //     message: `Campaign was successfully created`,
  //     type: 'SUCCESS',
  //   })
  //   const { root, tree } = buildTreeItem({
  //     type: 'CAMPAIGN',
  //     category: 'campaigns',
  //     item: response,
  //   })
  //   yield put(root)
  //   yield put(tree)
  //   // yield put({ type: actionTypes.CREATE_CAMPAIGN_SUC, campaign: response })
  // } else {
  //   const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr) || JSON.parse(xhr.responseText).errors[0].message;
  //   toastr.add({ message: `Error. ${msg}`, type: 'ERROR' })
  // }
}

function* updateCampaign(action) {
  yield put({ type: actionTypes.SET_LOADING });
  const { campaign, id } = action;
  if (id) {
    const { campaignMessages } = campaign;
    const { response, xhr } = yield call(API.updateCampaign, id, campaign);
    if (response) {
      toastr.add({
        message: i18n.t("Bonus.Campaign was successfully updated"),
        type: "SUCCESS",
      });
      const { root, tree } = buildTreeItem({
        category: "campaigns",
        item: response,
        type: "CAMPAIGN",
        update: true,
      });
      yield put(root);
      yield put(tree);
      yield put({ campaign: response, type: actionTypes.UPDATE_CAMPAIGN_SUC });
      yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
      yield put(updateMessagePlanItem(campaignMessages));
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
      toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
      yield put({ type: actionTypes.UPDATE_CAMPAIGN_FAIL });
      yield put({ state: true, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    }
  } else {
    // yield put({ type: actionTypes.SET_LOADING })
    // const { campaign } = action
    const { response, xhr } = yield call(API.createCampaign, campaign);
    if (response) {
      toastr.add({
        message: i18n.t("Bonus.Campaign was successfully created"),
        type: "SUCCESS",
      });
      const { root, tree } = buildTreeItem({
        category: "campaigns",
        item: response,
        type: "CAMPAIGN",
      });
      yield put(root);
      yield put(tree);
      yield put({ state: false, type: constants.SET_BM_MODULE_EDIT_STATE });
      yield put({ campaign: response, type: actionTypes.CREATE_CAMPAIGN_SUC });
      history.push(`/bonus-manager/campaigns/${response.id}`);
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr) || JSON.parse(xhr.responseText).errors[0].message;
      toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    }
  }
}

function* deleteCampaign(action) {
  const { response, xhr } = yield call(API.deleteCampaign, action.id);
  if (response) {
    yield put({
      response,
      type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
    });

    toastr.add({ message: i18n.t("Bonus.Campaign was successfully deleted") });
    const { root, tree } = buildRemoveTreeItem({
      category: "campaigns",
      id: action.id,
      type: "CAMPAIGN",
    });
    yield put(root);
    yield put(tree);
    history.push(`/bonus-manager/campaigns/`);
    yield put({ state: false, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    yield put({ modalName: "bmDeleteModal", type: "CLOSE_MODAL" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ type: actionTypes.DELETE_CAMPAIGN_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
    yield put({ modalName: "bmDeleteModal", type: "CLOSE_MODAL" });
  }
}

function* copyCampaign(action) {
  const { campaign } = action;
  const { response, xhr } = yield call(API.copyCampaign, campaign);
  if (response) {
    toastr.add({
      message: i18n.t("Bonus.Campaign was successfully copied"),
      type: "SUCCESS",
    });
    yield put({ campaign: response, type: actionTypes.SET_CAMPAIGN });
    yield put({ campaign: response, type: actionTypes.COPY_CAMPAIGN_SUCCESS });
    yield put({ state: true, type: "BM::SET_BM_MODULE_EDIT_STATE" });
    const { root, tree } = buildTreeItem({
      category: "campaigns",
      item: response,
      type: "CAMPAIGN",
    });
    yield put(root);
    yield put(tree);
    history.push(`/bonus-manager/campaigns/${response.id}`);
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.COPY_CAMPAIGN_FAILED });
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

function* fetchCampaignPromotionStatistics(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchCampaignPromotionStatistics, id);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_CAMPAIGN_PROMOTION_STATISTICS_SUCCESS,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.FETCH_CAMPAIGN_PROMOTION_STATISTICS_FAILED });
  }
}

function* fetchCampaignPromotionBudgetLog(action) {
  const { id } = action;
  const { response, xhr } = yield call(API.fetchCampaignPromotionBudgetLog, id);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_SUCCESS,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG_FAILED });
  }
}

function* searchPromoCode(action) {
  const { code } = action;
  const { response, xhr } = yield call(API.searchPromoCode, code);
  if (response) {
    yield put({
      response,
      type: actionTypes.SEARCH_PROMO_CODE_SUCCESS,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.SEARCH_PROMO_CODE_FAILED });
  }
}

function* generatePromoCode(action) {
  const { response, xhr } = yield call(API.generatePromoCode, action.promoCodeData);
  if (response) {
    yield put({
      response,
      type: actionTypes.GENERATE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoCodeData.campaignpromoid,
      type: actionTypes.FETCH_PROMO_CODE,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.GENERATE_PROMO_CODE_FAILED });
  }
}

function* createPromoCode(action) {
  const { response, xhr } = yield call(API.createPromoCode, action.promoCodeData);
  if (response) {
    yield put({
      response,
      type: actionTypes.CREATE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoCodeData.campaignPromoId,
      type: actionTypes.FETCH_PROMO_CODE,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.CREATE_PROMO_CODE_FAILED });
  }
}

function* fetchPromoCode(action) {
  const { response, xhr } = yield call(API.fetchPromoCode, action.promoId);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_PROMO_CODE_SUCCESS,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.FETCH_PROMO_CODE_FAILED });
  }
}

function* fetchPromoCodeUsage(action) {
  const { response, xhr } = yield call(API.fetchPromoCodeUsage, action.code);
  if (response) {
    yield put({
      response,
      type: actionTypes.FETCH_PROMO_CODE_USAGE_SUCCESS,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.FETCH_PROMO_CODE_USAGE_FAILED });
  }
}

function* deletePromoCode(action) {
  const { response, xhr } = yield call(API.deletePromoCode, action.promoCodeId);
  if (response) {
    yield put({
      response,
      type: actionTypes.DELETE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoId,
      type: actionTypes.FETCH_PROMO_CODE,
    });
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-delete"));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.DELETE_PROMO_CODE_FAILED });
  }
}

function* batchDeletePromoCode(action) {
  const { response, xhr } = yield call(API.batchDeletePromoCode, action.codes);
  if (response) {
    yield put({
      response,
      type: actionTypes.BATCH_DELETE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoId,
      type: actionTypes.FETCH_PROMO_CODE,
    });
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-delete"));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.BATCH_DELETE_PROMO_CODE_FAILED });
  }
}

function* updatePromoCode(action) {
  const { response, xhr } = yield call(API.updatePromoCode, action.promoCodeId, action.promoCodeData);
  if (response) {
    yield put({
      response,
      type: actionTypes.UPDATE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoId,
      type: actionTypes.FETCH_PROMO_CODE,
    });
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-activate"));
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-deactivate"));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.UPDATE_PROMO_CODE_FAILED });
  }
}

function* batchUpdatePromoCode(action) {
  const { response, xhr } = yield call(API.batchUpdatePromoCode, action.codes);
  if (response) {
    yield put({
      response,
      type: actionTypes.BATCH_UPDATE_PROMO_CODE_SUCCESS,
    });
    yield put({
      promoId: action.promoId,
      type: actionTypes.FETCH_PROMO_CODE,
    });
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-activate"));
    yield put(closeModal("bmPromotionPromoCodeConfirmModal-deactivate"));
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
    yield put({ type: actionTypes.BATCH_UPDATE_PROMO_CODE_FAILED });
  }
}

export default function* bonusManagerAppSaga() {
  yield takeLatest(actionTypes.FETCH_CAMPAIGNS, fetchCampaigns);
  yield takeLatest(actionTypes.CREATE_CAMPAIGN, createCampaign);
  yield takeLatest(actionTypes.UPDATE_CAMPAIGN, updateCampaign);
  yield takeLatest(actionTypes.DELETE_CAMPAIGN, deleteCampaign);
  yield takeLatest(actionTypes.COPY_CAMPAIGN, copyCampaign);
  yield takeLatest(actionTypes.FETCH_CAMPAIGN_PROMOTION_STATISTICS, fetchCampaignPromotionStatistics);
  yield takeLatest(actionTypes.FETCH_CAMPAIGN_PROMOTION_BUDGET_LOG, fetchCampaignPromotionBudgetLog);
  yield takeLatest(actionTypes.SEARCH_PROMO_CODE, searchPromoCode);
  yield takeLatest(actionTypes.GENERATE_PROMO_CODE, generatePromoCode);
  yield takeLatest(actionTypes.CREATE_PROMO_CODE, createPromoCode);
  yield takeLatest(actionTypes.FETCH_PROMO_CODE, fetchPromoCode);
  yield takeLatest(actionTypes.FETCH_PROMO_CODE_USAGE, fetchPromoCodeUsage);
  yield takeLatest(actionTypes.DELETE_PROMO_CODE, deletePromoCode);
  yield takeLatest(actionTypes.BATCH_DELETE_PROMO_CODE, batchDeletePromoCode);
  yield takeLatest(actionTypes.UPDATE_PROMO_CODE, updatePromoCode);
  yield takeLatest(actionTypes.BATCH_UPDATE_PROMO_CODE, batchUpdatePromoCode);
  yield takeLatest(actionTypes.FETCH_BRANDS, fetchBrands);
}
