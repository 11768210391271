import _ from "lodash";
import moment from "moment";
import React from "react";

import { formatCurrency } from "../../utils";

export function getUniqueId() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

function getIcon(originId, origins, channels) {
  if (origins[0] && channels[0]) {
    const channelId = origins?.find((origin) => origin.id === originId).channelId;

    return channels.find((channel) => channel.id === channelId).description;
  }

  return "";
}

export function parseBetsData(bets, sports, origins, channels, brands) {
  const parsedBets = [];
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });
  bets.forEach((bet) => {
    const brandId = origins?.find((origin) => origin.id === bet.iaBets[0].originId)?.brandId;
    parsedBets.push({
      accountDescription: bet.iaBets[0].accountDescription,
      accountId: bet.iaBets[0].account.id,
      betColor: bet.iaBets[0].account.playerProfileFlags[0] ? bet.iaBets[0].account.playerProfileFlags[0].colour : "",
      brand: brands.find((brand) => brand.id === brandId)?.description,
      date: moment(bet.iaBets[0].createdDate).format(dateFormatter),
      description: bet.iaBets[0].description,
      events: bet.iaBets[0].betDatas
        .map((data) => ({
          description: data.eventDescription,
          id: data.eventId,
          startTime: moment(data.eventStartTime).format("MM/DD/YYYY"),
        }))
        .reduce((accu, val) => {
          if (!accu.find((event) => event.id === val.id)) {
            accu.push(val);
          }

          return accu;
        }, []),
      fxrate: bet.iaBets[0].fxrate,
      icon: getIcon(bet.iaBets[0].originId, origins, channels),
      isLive: bet.iaBets[0].betDatas[0].live,
      shop: "",
      sport: sports.find((sport) => sport.code === bet.iaBets[0].sportCode).description,
      sportCodeLower: bet.iaBets[0].sportCode.toLowerCase(),
      totalStake: bet.totalStake,
      totalStakeP: bet.totalStake * bet.iaBets[0].fxrate,
      type: bet.iaBets[1] || bet.iaBets[0].betDatas[1] ? "multiple" : "single",
      unitStake: bet.unitStake,
      unitStakeP: bet.unitStake * bet.iaBets[0].fxrate,
      username: bet.iaBets[0].account.userName,
      winAmount: bet.potentialWin,
      winAmountP: bet.potentialWin * bet.iaBets[0].fxrate,
    });
  });

  return parsedBets;
}

export function parseAccountsData(accounts, origins, channels, brands) {
  const parsedAccounts = [];
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });

  accounts.forEach((account) => {
    const brandId = origins?.find((origin) => origin.id === account.originId)?.brandId;
    parsedAccounts.push({
      accountId: account.id,
      brand: brands.find((brand) => brand.id === brandId)?.description,
      date: moment(account.createdDate).format(dateFormatter),
      email: account.email,
      icon: getIcon(account.originId, origins, channels),
      name: `${account.firstName} ${account.lastName}`,
      referrer: account.referer,
      site: "website",
      username: account.userName,
    });
  });

  return parsedAccounts;
}

export function parsePaymentsData(payments, origins, brands, channels) {
  const parsedPayments = [];
  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  payments.forEach((payment) => {
    const brandId = origins?.find((origin) => origin.id === payment.originId)?.brandId;
    parsedPayments.push({
      accountId: payment.account.id,
      brand: brands.find((brand) => brand.id === brandId)
        ? brands.find((brand) => brand.id === brandId)?.description
        : null,
      credit: payment.credit,
      debit: payment.debit,
      description: payment.description,
      // originId: origins?.find(origin => origin.id === payment.originId) ? origins?.find(origin => origin.id === payment.originId).description : null
      originId: getIcon(payment.originId, origins, channels),

      time: moment(payment.createdDate).format(dateFormatter),

      username: payment.userName,
    });
  });

  return parsedPayments;
}

export function parseFailedBetsData(failedBets, origins, brands) {
  const parsedFailedBets = [];
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });
  failedBets.forEach((bet) => {
    const brandId = origins?.find((origin) => origin.id === bet.originId)?.brandId;
    parsedFailedBets.push({
      accountId: bet.account.id,
      brand: brands.find((brand) => brand.id === brandId)?.description,
      date: moment(bet.createdDate).format(dateFormatter),
      description: bet.description,
      stake: (bet.debit * bet.fxrate).toFixed(2),
      username: bet.userName,
    });
  });

  return parsedFailedBets;
}

export function parseCancelledBetsData(cancelledBets, origins, brands) {
  const parsedCancelledBets = [];
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });
  cancelledBets.forEach((bet) => {
    const brandId = origins?.find((origin) => origin.id === bet.originId)?.brandId;
    parsedCancelledBets.push({
      accountId: bet.account.id,
      brand: brands.find((brand) => brand.id === brandId)?.description,
      date: moment(bet.createdDate).format(dateFormatter),
      description: bet.description,
      stake: (bet.debit * bet.fxrate).toFixed(2),
      username: bet.userName,
    });
  });

  return parsedCancelledBets;
}

export function parseCashOutBetsData(cashOutBets, origins, brands) {
  const parsedCashOutBets = [];
  const dateFormatter = "MM/DD/YYYY HH:mm:ss";
  const customFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

  // const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // });

  cashOutBets.forEach((cashOutBet) => {
    const brandId = origins?.find((origin) => origin.id === cashOutBet.originId)?.brandId;

    parsedCashOutBets.push({
      accountId: cashOutBet.accountId,
      // stake: (cashOutBet.unitStake * cashOutBet.fxrate).toFixed(2),
      brand: brands.find((brand) => brand.id === brandId)?.description,

      //2023-10-04T17:08:36.292+00:00
      date: moment(cashOutBet.createdDate).format(dateFormatter),

      description: cashOutBet.betSlipDescription,

      percentage: Number(cashOutBet.percentage).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        style: "percent",
      }),

      potentialWin: (cashOutBet.totalPotentialWin * cashOutBet.fxRate).toFixed(2),

      totalQuote: (cashOutBet.totalQuote * cashOutBet.fxRate).toFixed(2),

      totalStake: (cashOutBet.totalStake * cashOutBet.fxRate).toFixed(2),

      username: cashOutBet.userName,
    });
  });

  return parsedCashOutBets;
}

export function getBetsColumns(headersPreferences, baseCurrency, t) {
  const columns = [
    {
      accessor: "icon",
      className: "icon-cell",
      getHeaderProps: () => ({ className: "icon-header" }),
      header: t("Instant.Origin"),
      minWidth: 50,
      render: ({ value }) => {
        switch (value) {
          case "CALL_CENTRE":
            return <i className="phx-telebet" title={t(`Instant.${value}`)} />;
          case "DESKTOP_WEB":
            return <i className="phx-internet" title={t(`Instant.${value}`)} />;
          case "RTG":
            return <i className="phx-rtg" title={t(`Instant.${value}`)} />;
          case "MOBILE_WEB":
            return <i className="phx-mobile" title={t(`Instant.${value}`)} />;
          case "BetPoint":
            return <i className="phx-betpoint" title={t(`Instant.${value}`)} />;
          case "Kiosk":
            return <i className="phx-kiosk" title={t(`Instant.${value}`)} />;
          case "Opera Mini":
            return <i className="phx-opera" title={t(`Instant.${value}`)} />;
          case "Remote Bet Slip":
            return <i className="phx-remote" title={t(`Instant.${value}`)} />;
          case "SMS":
            return <i className="phx-comment" title={t(`Instant.${value}`)} />;
          case "Tablet":
            return <i className="phx-tablet" title={t(`Instant.${value}`)} />;
          default:
            return value;
        }
      },
      show: headersPreferences.icon,
      style: { alignSelf: "center", textAlign: "center" },
    },
    {
      accessor: "date",
      className: "date-cell",
      getHeaderProps: () => ({ className: "date-header" }),
      header: t("Instant.Date/Time"),
      minWidth: 85,
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>{moment(props.value.split(" ")[0]).format("DD-MMM-YYYY ")}</span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
    },
    {
      accessor: "shop",
      className: "shop-cell",
      getHeaderProps: () => ({ className: "shop-header" }),
      header: t("Instant.Shop"),
      minWidth: 60,
      show: headersPreferences.shop,
    },
    {
      accessor: "accountId",
      className: "account-cell",
      getHeaderProps: () => ({ className: "account-header" }),
      header: t("Instant.AccountId"),
      minWidth: 50,
      show: headersPreferences.account,
    },
    {
      accessor: "username",
      className: "username-cell",
      getHeaderProps: () => ({ className: "username-header" }),
      header: t("Instant.Username"),
      minWidth: 80,
      show: headersPreferences.username,
    },
    {
      accessor: "accountDescription",
      className: "account-description-header",
      getHeaderProps: () => ({ className: "account-description-header" }),
      header: t("Instant.Account Description"),
      minWidth: 120,
      show: headersPreferences.accountDescription,
    },
    // {
    //   header: 'Description',
    //   accessor: 'description',
    //   getHeaderProps: () => ({ className: 'account-description-header' }),
    //   className: 'description-cell',
    //   show: headersPreferences.description,
    //   minWidth: 120,
    // },
    {
      accessor: "sport",
      className: "sport-cell",
      getHeaderProps: () => ({ className: "sport-header" }),
      header: t("Instant.Sport"),
      minWidth: 65,
      show: headersPreferences.sport,
    },
    {
      accessor: "unitStake",
      className: "unit-stake-cell",
      getHeaderProps: () => ({ className: "unit-stake-header" }),
      header: t("Instant.Unit Stake"),
      minWidth: 65,
      render: (props) => <span>{`${props.row.description.split(" ")[0]} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.unitStake,
    },
    {
      accessor: "totalStake",
      className: "total-stake-cell",
      getHeaderProps: () => ({ className: "total-stake-header" }),
      header: t("Instant.Total Stake"),
      minWidth: 75,
      render: (props) => <span>{`${props.row.description.split(" ")[0]} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.totalStake,
    },
    {
      accessor: "winAmount",
      className: "win-amount-cell",
      getHeaderProps: () => ({ className: "win-amount-header" }),
      header: t("Instant.Win Amount"),
      minWidth: 85,
      render: (props) => <span>{`${props.row.description.split(" ")[0]} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.winAmount,
    },
    {
      accessor: "unitStakeP",
      className: "unit-stake-p-cell",
      getHeaderProps: () => ({ className: "unit-stake-p-header" }),
      header: (props) => (
        <span>
          {t("Instant.Unit Stake")} ({baseCurrency.isoCode})
        </span>
      ),
      minWidth: 95,
      render: (props) => (
        <span>
          {`${
            parseInt(props.rowValues.fxrate) == 1 ? props.row.description.split(" ")[0] : baseCurrency.isoCode
          } ${formatCurrency(props.value)}`}
        </span>
      ),
      show: headersPreferences.unitStakeP,
    },
    {
      accessor: "totalStakeP",
      className: "total-stake-p-cell",
      getHeaderProps: () => ({ className: "total-stake-p-header" }),
      header: (props) => (
        <span>
          {t("Instant.Total Stake")} ({baseCurrency.isoCode})
        </span>
      ),
      minWidth: 95,
      render: (props) => (
        <span>
          {`${
            parseInt(props.rowValues.fxrate) == 1 ? props.row.description.split(" ")[0] : baseCurrency.isoCode
          } ${formatCurrency(props.value)}`}
        </span>
      ),
      show: headersPreferences.totalStakeP,
    },
    {
      accessor: "winAmountP",
      className: "win-amount-p-cell",
      getHeaderProps: () => ({ className: "win-amount-p-header" }),
      header: (props) => (
        <span>
          {t("Instant.Win Amount")} ({baseCurrency.isoCode})
        </span>
      ),
      minWidth: 110,
      render: (props) => (
        <span>
          {`${
            parseInt(props.rowValues.fxrate) == 1 ? props.row.description.split(" ")[0] : baseCurrency.isoCode
          } ${formatCurrency(props.value)}`}
        </span>
      ),
      show: headersPreferences.winAmountP,
    },
  ];

  return columns;
}

export function getAccountsColumns(headersPreferences, t) {
  const columns = [
    {
      accessor: "icon",
      className: "icon-cell",
      getHeaderProps: () => ({ className: "icon-header" }),
      header: t("Instant.Origin"),
      minWidth: 50,
      render: ({ value }) => {
        switch (value) {
          case "Telebet":
            return <i className="phx-telebet" title={t(`Instant.${value}`)} />;
          case "Internet":
            return <i className="phx-internet" title={t(`Instant.${value}`)} />;
          case "RTG":
            return <i className="phx-rtg" title={t(`Instant.${value}`)} />;
          case "Mobile":
            return <i className="phx-mobile" title={t(`Instant.${value}`)} />;
          case "BetPoint":
            return <i className="phx-betpoint" title={t(`Instant.${value}`)} />;
          case "Kiosk":
            return <i className="phx-kiosk" title={t(`Instant.${value}`)} />;
          case "Opera Mini":
            return <i className="phx-opera" title={t(`Instant.${value}`)} />;
          case "Remote Bet Slip":
            return <i className="phx-remote" title={t(`Instant.${value}`)} />;
          case "SMS":
            return <i className="phx-comment" title={t(`Instant.${value}`)} />;
          case "Tablet":
            return <i className="phx-tablet" title={t(`Instant.${value}`)} />;
          default:
            return value;
        }
      },
      show: headersPreferences.icon,
      style: { alignSelf: "center", textAlign: "center" },
    },
    {
      accessor: "date",
      className: "date-cell",
      getHeaderProps: () => ({ className: "date-header" }),
      header: t("Instant.Date/Time"),
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>{moment(props.value.split(" ")[0]).format("DD-MMM-YYYY ")}</span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
    },
    {
      accessor: "accountId",
      className: "account-cell",
      getHeaderProps: () => ({ className: "account-header" }),
      header: t("Instant.AccountId"),
      show: headersPreferences.account,
    },
    {
      accessor: "username",
      className: "username-cell",
      getHeaderProps: () => ({ className: "username-header" }),
      header: t("Instant.Username"),
      show: headersPreferences.username,
    },
    {
      accessor: "name",
      className: "name-cell",
      getHeaderProps: () => ({ className: "name-header" }),
      header: t("Instant.Name"),
      show: headersPreferences.name,
    },
    {
      accessor: "email",
      className: "email-cell",
      getHeaderProps: () => ({ className: "email-header" }),
      header: t("Instant.Email"),
      show: headersPreferences.email,
    },
    {
      accessor: "site",
      className: "site-cell",
      getHeaderProps: () => ({ className: "site-header" }),
      header: t("Instant.Site"),
      show: headersPreferences.site,
    },
    {
      accessor: "referrer",
      className: "referrer-cell",
      getHeaderProps: () => ({ className: "referrer-header" }),
      header: t("Instant.Referrer"),
      show: headersPreferences.referrer,
    },
  ];

  return columns;
}

export function getPaymentColumns(headersPreferences, t) {
  const columns = [
    {
      accessor: "originId",
      className: "icon-cell",
      header: t("Instant.Origin"),
      render: ({ value }) => {
        switch (value) {
          case "Telebet":
            return <i className="phx-telebet" title={t(`Instant.${value}`)} />;
          case "Internet":
            return <i className="phx-internet" title={t(`Instant.${value}`)} />;
          case "RTG":
            return <i className="phx-rtg" title={t(`Instant.${value}`)} />;
          case "Mobile":
            return <i className="phx-mobile" title={t(`Instant.${value}`)} />;
          case "BetPoint":
            return <i className="phx-betpoint" title={t(`Instant.${value}`)} />;
          case "Kiosk":
            return <i className="phx-kiosk" title={t(`Instant.${value}`)} />;
          case "Opera Mini":
            return <i className="phx-opera" title={t(`Instant.${value}`)} />;
          case "Remote Bet Slip":
            return <i className="phx-remote" title={t(`Instant.${value}`)} />;
          case "SMS":
            return <i className="phx-comment" title={t(`Instant.${value}`)} />;
          case "Tablet":
            return <i className="phx-tablet" title={t(`Instant.${value}`)} />;
          default:
            return value;
        }
      },
      show: headersPreferences.origin,
      style: { textAlign: "center", whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
    {
      accessor: "time",
      header: t("Instant.Date/Time"),
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>
            {moment(props.value && props.value.split(" ")[0]).format("DD-MMM-YYYY ")}
          </span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value && props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
      // render: props => (
      //   <span style={{ textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
      //     <span style={{ wordWrap: 'break-word' }}>{moment(props.value && props.value.split(' ')[0]).format("DD-MMM-YYYY ")}</span>
      //     <br />
      //     <span style={{ wordWrap: 'break-word' }}>{props.value && props.value.split(' ')[1]}</span>
      //   </span>
      // ),
    },
    {
      accessor: "accountId",
      header: t("Instant.AccountId"),
      show: headersPreferences.account,
      style: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
    {
      accessor: "username",
      header: t("Instant.Username"),
      show: headersPreferences.username,
      style: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
    {
      accessor: "description",
      header: t("Instant.Description"),
      show: headersPreferences.description,
      style: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
    {
      accessor: "credit",
      header: t("Instant.Credit"),
      render: (props) => <span>{formatCurrency(props.value)}</span>,
      show: headersPreferences.credit,
      style: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
    {
      accessor: "debit",
      header: t("Instant.Debit"),
      render: (props) => <span>{formatCurrency(props.value)}</span>,
      show: headersPreferences.debit,
      style: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
    },
  ];

  return columns;
}

export function getFailedBetsColumns(headersPreferences, baseCurrency, t) {
  const columns = [
    {
      accessor: "date",
      header: t("Instant.Date/Time"),
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>{moment(props.value.split(" ")[0]).format("DD-MMM-YYYY ")}</span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
    },
    {
      accessor: "username",
      header: t("Instant.Username"),
      show: headersPreferences.username,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "accountId",
      header: t("Instant.AccountId"),
      show: headersPreferences.account,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "description",
      header: t("Instant.Description"),
      show: headersPreferences.description,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "stake",
      header: (props) => (
        <span>
          {t("Instant.Stake")} ({baseCurrency.isoCode})
        </span>
      ),
      render: (props) => <span>{`${baseCurrency.isoCode} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.stake,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
  ];

  return columns;
}

export function getCancelledBetsColumns(headersPreferences, baseCurrency, t) {
  const columns = [
    {
      accessor: "date",
      header: t("Instant.Date/Time"),
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>{moment(props.value.split(" ")[0]).format("DD-MMM-YYYY ")}</span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
    },
    {
      accessor: "username",
      header: t("Instant.Username"),
      show: headersPreferences.username,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "accountId",
      header: t("Instant.AccountId"),
      show: headersPreferences.account,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "description",
      header: t("Instant.Description"),
      show: headersPreferences.description,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "stake",
      header: (props) => (
        <span>
          {t("Instant.Stake")} ({baseCurrency.isoCode})
        </span>
      ),
      render: (props) => <span>{`${baseCurrency.isoCode} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.stake,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
  ];

  return columns;
}

export function getCashOutBetsColumns(headersPreferences, baseCurrency, t) {
  const columns = [
    {
      accessor: "date",
      header: t("Instant.Date/Time"),
      render: (props) => (
        <span style={{ textOverflow: "ellipsis", whiteSpace: "normal" }}>
          <span style={{ wordWrap: "break-word" }}>{moment(props.value.split(" ")[0]).format("DD-MMM-YYYY ")}</span>
          <br />
          <span style={{ wordWrap: "break-word" }}>{props.value.split(" ")[1]}</span>
        </span>
      ),
      show: headersPreferences.date,
    },
    {
      accessor: "username",
      header: t("Instant.Username"),
      show: headersPreferences.username,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "accountId",
      header: t("Instant.AccountId"),
      show: headersPreferences.account,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "description",
      header: t("Instant.Description"),
      show: headersPreferences.description,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "potentialWin",
      header: (props) => (
        <span>
          {t("Instant.Original Potential Win")} ({baseCurrency.isoCode})
        </span>
      ),
      render: (props) => <span>{`${baseCurrency.isoCode} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.potentialWin,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "percentage",
      header: t("Instant.Percentage"),
      show: headersPreferences.percentage,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "totalStake",
      header: (props) => (
        <span>
          {t("Instant.Total Stake")} ({baseCurrency.isoCode})
        </span>
      ),
      render: (props) => <span>{`${baseCurrency.isoCode} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.totalStake,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
    {
      accessor: "totalQuote",
      header: (props) => (
        <span>
          {t("Instant.Total Quote")} ({baseCurrency.isoCode})
        </span>
      ),
      render: (props) => <span>{`${baseCurrency.isoCode} ${formatCurrency(props.value)}`}</span>,
      show: headersPreferences.totalQuote,
      style: { whiteSpace: "normal", wordWrap: "break-word" },
    },
  ];

  return columns;
}

export function getFilteredBets(bets, filters, brandFilter) {
  const a = bets.filter((bet) => applyFilters(bet, filters, brandFilter));

  return a;
}

export function getFilteredAccounts(accounts, brandFilter) {
  return accounts.filter((account) => {
    if (brandFilter !== "All") {
      if (account.brand !== brandFilter) return false;
    }

    return true;
  });
}

export function getFilteredPayments(payments, brandFilter) {
  return payments.filter((payment) => {
    if (brandFilter !== "All") {
      if (payment.brand !== brandFilter) return false;
    }

    return true;
  });
}

export function getFilteredFailedBets(failedBets, brandFilter) {
  return failedBets.filter((failedBet) => {
    if (brandFilter !== "All") {
      if (failedBet.brand !== brandFilter) return false;
    }

    return true;
  });
}

export function getFilteredCancelledBets(cancelledBets, brandFilter) {
  return cancelledBets.filter((cancelledBet) => {
    if (brandFilter !== "All") {
      if (cancelledBet.brand !== brandFilter) return false;
    }

    return true;
  });
}

export function getFilteredCashOutBets(cashOutBets, brandFilter) {
  return cashOutBets.filter((cashOutBet) => {
    if (brandFilter !== "All") {
      if (cashOutBet.brand !== brandFilter) return false;
    }

    return true;
  });
}

function applyFilters(bet, filters, brandFilter) {
  if (filters.betType.active && filters.betType.value) {
    if (bet.type !== filters.betType.value) return false;
  }

  if (!filters.markets.active) return false;

  if (filters.markets.active) {
    if (!filters.markets.mainbook && !filters.markets.live) return false;
    if (!filters.markets.live && bet.isLive) return false;
    if (!filters.markets.mainbook && !bet.isLive) return false;
  }

  if (filters.totalStake.active) {
    if (filters.totalStake.type === "atLeast") {
      if (bet.totalStakeP < filters.totalStake.value) return false;
      // if (bet.totalStake < filters.totalStake.value) return false
    }

    if (filters.totalStake.type === "lessThan") {
      if (bet.totalStakeP >= filters.totalStake.value) return false;
      // if (bet.totalStake >= filters.totalStake.value) return false
    }
  }

  if (filters.riskAmount.active) {
    if (filters.riskAmount.type === "atLeast") {
      if (bet.winAmountP < filters.riskAmount.value) return false;
    }

    if (filters.riskAmount.type === "lessThan") {
      if (bet.winAmountP > filters.riskAmount.value) return false;
    }
  }

  if (filters.sports.active && filters.sports.type) {
    if (filters.sports.type === "show" && filters.sports.items[0]) {
      if (!filters.sports.items.some((item) => bet.sport === item)) return false;
    }
    if (filters.sports.type === "show" && filters.sports.items && filters.sports.items.length == 0) {
      return false;
    }

    if (filters.sports.type === "exclude" && filters.sports.items[0]) {
      if (filters.sports.items.some((item) => bet.sport === item)) return false;
    }
  }
  if (!_.includes(["ALL", "All"], brandFilter)) {
    if (bet.brand !== brandFilter) return false;
  }

  return true;
}

export const generateRiskUrl = ({ event, rowInfo }) => {
  let url = `/risk-manager/${rowInfo.sportCodeLower}/e${event.id}`;
  if (event.startTime !== moment().format("MM/DD/YYYY")) {
    const dateRange = `${event.startTime} - ${moment(event.startTime).add(1, "days").format("MM/DD/YYYY")}`;
    url = `${url}?dateFilter=${encodeURI(dateRange)}`;
  }

  return url;
};
