import {
  CLEAR_GROUP_FORM,
  SET_GROUP_FORM,
  SET_NEW_OPERATOR_FORM,
  CLEAR_NEW_OPERATOR_FORM,
  HIDE_DUPLICATE_OPERATOR_MODAL,
  SHOW_DUPLICATE_OPERATOR_MODAL,
  SET_DUPLICATE_OPERATOR_FORM,
} from "./actions";

const initialState = {
  duplicateOperatorDetails: {
    password: "",
    username: "",
  },
  group: {
    brandId: "",
    description: "",
    email: "",
  },

  isShowDuplicateOperatorModal: false,
  newOperator: {
    brandId: "",
    currencyId: "",
    groupId: "",
    password: "",
    statusId: "",
    username: "",
  },
};

const operatorManagerModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP_FORM:
      return {
        ...state,
        group: action.groupDetails,
      };
    case CLEAR_GROUP_FORM:
      return {
        ...state,
        group: {
          brandId: "",
          description: "",
          email: "",
        },
      };
    case SET_NEW_OPERATOR_FORM:
      return {
        ...state,
        newOperator: action.operatorDetails,
      };
    case CLEAR_NEW_OPERATOR_FORM:
      return {
        ...state,
        newOperator: {
          brandId: "",
          currencyId: "",
          groupId: "",
          password: "",
          statusId: "",
          username: "",
        },
      };
    case HIDE_DUPLICATE_OPERATOR_MODAL:
      return {
        ...state,
        duplicateOperatorDetails: {
          password: "",
          username: "",
        },
        isShowDuplicateOperatorModal: false,
      };
    case SHOW_DUPLICATE_OPERATOR_MODAL:
      return {
        ...state,
        isShowDuplicateOperatorModal: true,
      };
    case SET_DUPLICATE_OPERATOR_FORM:
      return {
        ...state,
        duplicateOperatorDetails: {
          password: action.duplicateOperatorDetails.password,
          username: action.duplicateOperatorDetails.username,
        },
      };
    default:
      return state;
  }
};

export default operatorManagerModal;
