import constants from "./constants";

const initialState = {
  sportOptions: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_SPORT_OTHER_OPTIONS_SUCCEEDED:
      return {
        ...state,
        sportOptions: action.response[0],
      };
    default:
      return state;
  }
}
