import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { parseErrorMessageInXhr } from "services//apiUtils";


function* fetchUtilities(action) {
  const { data } = action;
  const { response, xhr } = yield call(API.fetchUtilities, data);
  if (response) {
    yield put({ response, type: constants.FETCH_UTILITIES_SUCCEEDED });
    // toastr.add({ message: `Success.` })
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: constants.FETCH_UTILITIES_FAILED });
    // toastr.add({ message: `Error. ${msg}`, type: 'ERROR' })
  }
}

export default function* utilitiesSaga() {
  yield takeLatest(constants.FETCH_UTILITIES, fetchUtilities);
}
