export default {
  FETCH_BET_RESTRICTIONS_HISTORY: "BR::FETCH_BET_RESTRICTIONS_HISTORY",
  ADD_NEW_BET_RESTRICTION: "BR::ADD_NEW_BET_RESTRICTION",
  FETCH_BET_RESTRICTIONS_HISTORY_FAILED: "BR::FETCH_BET_RESTRICTIONS_HISTORY_FAILED",

  ADD_NEW_BET_RESTRICTION_FAILED: "BR::ADD_NEW_BET_RESTRICTION_FAILED",
  FETCH_BET_RESTRICTIONS_HISTORY_SUCCEEDED: "BR::FETCH_BET_RESTRICTIONS_HISTORY_SUCCEEDED",
  ADD_NEW_BET_RESTRICTION_SUCCEEDED: "BR::ADD_NEW_BET_RESTRICTION_SUCCEEDED",

  FETCH_BET_RESTRICTION_KEYS: "BR::FETCH_BET_RESTRICTION_KEYS",
  CREATE_NEW_BET_RESTRICTIONS_MATRIX: "BR::CREATE_NEW_BET_RESTRICTIONS_MATRIX",
  FETCH_BET_RESTRICTION_KEYS_FAILED: "BR::FETCH_BET_RESTRICTION_KEYS_FAILED",

  CREATE_NEW_BET_RESTRICTIONS_MATRIX_FAILED: "BR::CREATE_NEW_BET_RESTRICTIONS_MATRIX_FAILED",
  FETCH_BET_RESTRICTION_KEYS_SUCCEEDED: "BR::FETCH_BET_RESTRICTION_KEYS_SUCCEEDED",
  CREATE_NEW_BET_RESTRICTIONS_MATRIX_SUCCEEDED: "BR::CREATE_NEW_BET_RESTRICTIONS_MATRIX_SUCCEEDED",

  FETCH_MARKET_TYPE_GROUPS: "BR::FETCH_MARKET_TYPE_GROUPS",
  DELETE_BET_RESTRICTIONS: "BR::DELETE_BET_RESTRICTIONS",
  FETCH_MARKET_TYPE_GROUPS_FAILED: "BR::FETCH_MARKET_TYPE_GROUPS_FAILED",

  DELETE_BET_RESTRICTIONS_FAILED: "BR::DELETE_BET_RESTRICTIONS_FAILED",
  FETCH_MARKET_TYPE_GROUPS_SUCCEEDED: "BR::FETCH_MARKET_TYPE_GROUPS_SUCCEEDED",
  DELETE_BET_RESTRICTIONS_HISTORY: "BR::DELETE_BET_RESTRICTIONS_HISTORY",

  FETCH_MATRIX_DATA: "BR::FETCH_MATRIX_DATA",
  DELETE_BET_RESTRICTIONS_HISTORY_FAILED: "BR::DELETE_BET_RESTRICTIONS_HISTORY_FAILED",
  FETCH_MATRIX_DATA_FAILED: "BR::FETCH_MATRIX_DATA_FAILED",

  DELETE_BET_RESTRICTIONS_HISTORY_SUCCEEDED: "BR::DELETE_BET_RESTRICTIONS_HISTORY_SUCCEEDED",
  FETCH_MATRIX_DATA_SUCCEEDED: "BR::FETCH_MATRIX_DATA_SUCCEEDED",
  DELETE_BET_RESTRICTIONS_SUCCEEDED: "BR::DELETE_BET_RESTRICTIONS_SUCCEEDED",

  FETCH_SPORT_PERIODS: "BR::FETCH_SPORT_PERIODS",
  DELETE_BET_RESTRICTIONS_TEMPORARILY: "BR::DELETE_BET_RESTRICTIONS_TEMPORARILY",
  FETCH_SPORT_PERIODS_FAILED: "BR::FETCH_SPORT_PERIODS_FAILED",

  FETCH_SPORT_PERIODS_SUCCEEDED: "BR::FETCH_SPORT_PERIODS_SUCCEEDED",
  FETCH_UNUSED_BET_RESTRICTION_KEYS: "BR::FETCH_UNUSED_BET_RESTRICTION_KEYS",
  FETCH_UNUSED_BET_RESTRICTION_KEYS_FAILED: "BR::FETCH_UNUSED_BET_RESTRICTION_KEYS_FAILED",

  FETCH_UNUSED_BET_RESTRICTION_KEYS_SUCCEEDED: "BR::FETCH_UNUSED_BET_RESTRICTION_KEYS_SUCCEEDED",
  NEXT_STEP: "BR::NEXT_STEP",
  PREVIOUS_STEP: "BR::PREVIOUS_STEP",

  RESET_CURRENT_DATA: "BR::RESET_CURRENT_DATA",
  RESET_HISTORY_DATA: "BR::RESET_HISTORY_DATA",
  RESET_STEPS: "BR::RESET_STEPS",

  RESTORE_BET_RESTRICTIONS_HISTORY: "BR::RESTORE_BET_RESTRICTIONS_HISTORY",

  RESTORE_BET_RESTRICTIONS_HISTORY_FAILED: "BR::RESTORE_BET_RESTRICTIONS_HISTORY_FAILED",
  RESTORE_BET_RESTRICTIONS_HISTORY_SUCCEEDED: "BR::RESTORE_BET_RESTRICTIONS_HISTORY_SUCCEEDED",
  SET_ACTIVE_BET_TYPE: "BR::SET_ACTIVE_BET_TYPE",

  SET_ACTIVE_CELL: "BR::SET_ACTIVE_CELL",
  UPDATE_BET_RESTRICTIONS: "BR::UPDATE_BET_RESTRICTIONS",
  SET_ACTIVE_HISTORY: "BR::SET_ACTIVE_HISTORY",
  UPDATE_BET_RESTRICTIONS_FAILED: "BR::UPDATE_BET_RESTRICTIONS_FAILED",
  SET_EVALUATION_ORDER_VISIBILITY: "BR::SET_EVALUATION_ORDER_VISIBILITY",
  UPDATE_BET_RESTRICTIONS_HISTORY: "BR::UPDATE_BET_RESTRICTIONS_HISTORY",
  SET_PATH_ID_FOR_EVENT: "BR::SET_PATH_ID_FOR_EVENT",
  UPDATE_BET_RESTRICTIONS_HISTORY_FAILED: "BR::UPDATE_BET_RESTRICTIONS_HISTORY_FAILED",

  UNDO_CHANGES: "BR::UNDO_CHANGES",
  UPDATE_BET_RESTRICTIONS_SUCCEEDED: "BR::UPDATE_BET_RESTRICTIONS_SUCCEEDED",
  UPDATE_BET_RESTRICTIONS_HISTORY_SUCCEEDED: "BR::UPDATE_BET_RESTRICTIONS_HISTORY_SUCCEEDED",
  UPDATE_BET_RESTRICTION_KEYS: "BR::UPDATE_BET_RESTRICTION_KEYS",
  UPDATE_CELL_DATA: "BR::UPDATE_CELL_DATA",
  UPDATE_NEW_MATRIX_DATA: "BR::UPDATE_NEW_MATRIX_DATA",
  UPDATE_STEP: "BR::UPDATE_STEP",
};

export const RESTRICTION_FLAGS = {
  ALLOW: { className: "cell-allow", desc: "Allow", key: "ALLOW" },
  MULTIPLE: { className: "cell-multiple", desc: "Multiple Rules", key: "MULTIPLE" },
  NO_RULE: { className: "cell-no-rule", desc: "No Rule", key: "NO_RULE" },
  REFER: { className: "cell-refer", desc: "Refer", key: "REFER" },
  REJECT: { className: "cell-reject", desc: "Reject", key: "REJECT" },
};

export const COMMON_RULE_PROPS = {
  betRestrictionId: null,
  levelId: 0,
  levelParentId: 0,
  maxSelectionCount: 2,
  minSelectionCount: 2,
  otherFlag: "REJECT",
  sameFlag: "REJECT",
  tagId: 0,
};

export const NEW_BET_RESTRICTION_PROPS = [
  "betRestrictionId",
  "eventTypeId",
  "eventPathId",
  "eventPathDescription",
  "eventPath",
  "tagId",
  "phraseId",
  "marketTypeGroupId",
  "marketTypeGroupDesc",
  "marketTypeId",
  "bookTypeId",
  "periodId",
];
