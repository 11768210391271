const constants = {
  CANCLE_BETS: "EC::CANCLE_BETS",
  CANCLE_BETS_ON_MARKET: "EC::CANCLE_BETS_ON_MARKET",
  CLEAR_SELECTED_EVENT_CREATOR_PATH: "EC::GLOBAL::CLEAR_SELECTED_EVENT_CREATOR_PATH",

  FETCH_SPORT_OTHER_OPTIONS: "EC::FETCH_SPORT_OTHER_OPTIONS",
  FETCH_SPORT_OTHER_OPTIONS_FAILED: "EC::FETCH_SPORT_OTHER_OPTIONS_FAILED",
  FETCH_SPORT_OTHER_OPTIONS_SUCCEEDED: "EC::FETCH_SPORT_OTHER_OPTIONS_SUCCEEDED",

  SELECT_EVENT_CREATOR_PATH: "EC::GLOBAL::SELECT_EVENT_CREATOR_PATH",

  TOGGLE_BULK_UPDATE: "EC::TOGGLE_BULK_UPDATE",
  UPDATE_HEADER_BUTTONS: "EC::GLOBAL::UPDATE_HEADER_BUTTONS",
  UPDATE_SAVE_BUTTON_STATE: "EC::UPDATE_SAVE_BUTTON_STATE",
};

export const paths = {
  COUNTRY: "COUNTRY",
  GAME_EVENT: "GAME_EVENT",
  GAME_RESULT: "GAME_RESULT",
  LEAGUE: "LEAGUE",
  LEAGUE_NEWGAME: "LEAGUE_NEWGAME",
  LEAGUE_NEWRANK: "LEAGUE_NEWRANK",
  MARKET: "MARKET",
  RANK_EVENT: "RANK_EVENT",
  SPORT: "SPORT",
};

export const permissionsCode = {
  ADD_EVENT_PATH: 360,
  ADD_EVENT_PATH_PLAYER: 380,
  ADD_EVENT_PATH_TEAM: 365,
  ADD_GAME_EVENT: 400,
  ADD_RANK_EVENT: 395,
  ALLOW_CLOSED_MARKET_REOPENING: 1227,
  AUTO_SETTLEMENT: 654,
  BULK_TEAM_PLAYER_REMOVAL: 970,
  CANCEL_ALL_BETSLIP_OF_EVENT: 1555,
  CANCEL_ALL_BETSLIP_OF_MARKET: 1556,
  CHANGE_GAME_EVENT_DATE_TIME: 405,
  CHANGE_OPPONENTS_WITH_NO_OPEN_MARKETS: 1461,
  CHANGE_OPPONENTS_WITH_OPEN_MARKETS: 1460,
  DELETE: 355,
  EDIT_EVENT_PATH_AND_OPPONENT_RATINGS: 416,
  EDIT_EVENT_PATH_PLAYER: 385,
  EDIT_EVENT_PATH_TEAM: 370,
  ENABLE_EDIT_MARKETS_DIALOG: 406,
  ENABLE_NEW_MARKETS_DIALOG: 407,
  EVENTS_CREATOR_MARKET_BET_RESTRICTIONS: 840,
  MANAGE_EVENTS: 350,
  MARKET_EDITOR_ENTER_RESULTS: 651,
  MARKET_EDITOR_UPDATE_RESULTS: 652,
  REMOVE_EVENT_PATH_PLAYER: 390,
  REMOVE_EVENT_PATH_TEAM: 375,
  UPDATE_BET_RESTRICTIONS: 653,
  VIEW_AND_EDIT_MARKET_LINE_PROMOTED_FLAG: 410,
  VIEW_AND_EDIT_MARKET_LINE_SHOW_WHEN_UNPRICED_FLAG: 411,
  VIEW_EVENT_PATH_AND_OPPONENT_RATINGS: 415,
};

export const sportsCodesWithRaceCardNumber = ["USHO", "USGR", "GREY", "HORS"];

export default constants;
