import _ from "lodash";

import {
  ADD_OPERATOR,
  ADD_OPERATOR_SUCCEEDED,
  ADD_OPERATOR_FAILED,
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_SUCCEEDED,
  UPDATE_OPERATOR_FAILED,
  DUPLICATE_OPERATOR,
  DUPLICATE_OPERATOR_SUCCEEDED,
  DELETE_OPERATOR,
  ADD_GROUP,
  UPDATE_GROUP,
  EDIT_GROUP,
  ADD_UPDATE_GROUP_SUCCEEDED,
  DELETE_GROUP,
  DELETE_GROUP_SUCCEEDED,
  FETCH_OPERATOR_GROUPS,
  FETCH_OPERATOR_GROUPS_SUCCEEDED,
  FETCH_OPERATOR_GROUPS_FAILED,
  SELECT_OPERATOR,
  SELECT_GROUP,
  FILTER_GROUP,
  SEARCH_OPERATOR,
  TOGGLE_WARNING_MODAL,
  GROUP_ERROR,
} from "./constants";

const initialState = {
  action: "",
  group: {},
  groupFilter: 0,
  groupIndex: [],
  groupStatus: null,
  groupid: null,
  isgroupUpdating: false,

  newOperator: {},
  newOperatorStatus: "",
  operatorid: null,
  search: "",
  selectedOperator: {},
  showWarningModal: false,
  updateOperatorStatus: "",
};

const operatorList = (state = initialState, action) => {
  const {
    group,
    groupDetails,
    groupFilter,
    groupIndex,
    groupid,
    operatorDetails,
    operatorid,
    search,
    selectedOperator,
  } = action;
  switch (action.type) {
    case TOGGLE_WARNING_MODAL:
      return {
        ...state,
        action: action.action,
        showWarningModal: !state.showWarningModal,
      };
    case FETCH_OPERATOR_GROUPS:
      return {
        ...state,
        groupStatus: "LOADING",
      };
    case FETCH_OPERATOR_GROUPS_SUCCEEDED:
      return {
        ...state,
        group,
        groupIndex,
        groupStatus: "LOADED",
      };
    case FETCH_OPERATOR_GROUPS_FAILED:
      return {
        ...state,
        groupStatus: "ERROR",
      };
    case SELECT_OPERATOR:
      let _selectedOperator;
      state.groupIndex.map((grp) => {
        if (grp.id === groupid) {
          if (grp.operators) {
            grp.operators.map((operator) => {
              if (operator.id === operatorid) {
                _selectedOperator = operator;
              }
            });
          }
        }
      });

      return {
        ...state,
        groupid,
        operatorDetails: {
          currencyId: _selectedOperator && _selectedOperator.currencyId,
          email: _selectedOperator && _selectedOperator.email,
          firstName: _selectedOperator && _selectedOperator.firstName,
          groupId: _selectedOperator && (_selectedOperator.operatorGroupId || _selectedOperator.groupId),
          id: _selectedOperator && _selectedOperator.id,
          languageId: _selectedOperator && _selectedOperator.languageId,
          lastName: _selectedOperator && _selectedOperator.lastName,
          mobile: _selectedOperator && _selectedOperator.mobile,
          originId: _selectedOperator && _selectedOperator.originId,
          password: _selectedOperator && _selectedOperator.password,
          priceFormatId: _selectedOperator && _selectedOperator.priceFormat,
          securityLevel: _selectedOperator && _selectedOperator.securityLevel,
          statusId: _selectedOperator && _selectedOperator.statusId,
          username: _selectedOperator && _selectedOperator.userName,
        },
        operatorid,
        selectedOperator: _selectedOperator && _selectedOperator,
      };
    case SELECT_GROUP:
      return {
        ...state,
        groupid,
      };
    case FILTER_GROUP:
      return {
        ...state,
        groupFilter,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        isgroupUpdating: true,
      };
    case ADD_UPDATE_GROUP_SUCCEEDED:
      return {
        ...state,
        groupIndex: state.groupIndex.find((group) => group.id === groupDetails.id)
          ? state.groupIndex.map((item) => {
              if (item.id === groupDetails.id) {
                return {
                  ...item,
                  brandId: groupDetails.brandId,
                  description: groupDetails.description,
                  email: groupDetails.email,
                };
              }

              return item;
            })
          : [
              ...state.groupIndex,
              {
                brandId: groupDetails.brandId,
                description: groupDetails.description,
                email: groupDetails.email,
                id: groupDetails.id,
              },
            ],
        isgroupUpdating: false,
      };
    case DELETE_GROUP_SUCCEEDED:
      return {
        ...state,
        groupIndex: state.groupIndex.filter((group) => group.id !== groupDetails),
        groupid: null,
        isgroupUpdating: false,
      };
    case GROUP_ERROR:
      return {
        ...state,
        isgroupUpdating: false,
      };
    case DELETE_GROUP:
      return {
        ...state,
        groupDetails,
      };

    case ADD_OPERATOR:
      return {
        ...state,
        newOperatorStatus: "LOADING",
        operatorDetails,
      };
    case ADD_OPERATOR_SUCCEEDED:
      return {
        ...state,
        groupIndex: state.groupIndex.map((grp) => {
          if (grp.id === operatorDetails.groupId) {
            if (!grp.operators) {
              grp.operators = [];
            }
            grp.operators = [
              ...grp.operators,
              {
                ...operatorDetails,
                currencyId: operatorDetails.currencyId,
                email: operatorDetails.email,
                firstName: operatorDetails.firstName,
                id: operatorDetails.id,
                languageId: operatorDetails.languageId,
                lastName: operatorDetails.lastName,
                mobile: operatorDetails.mobile,
                operatorGroupId: operatorDetails.groupId,
                originId: operatorDetails.originId,
                password: operatorDetails.password,
                priceFormat: operatorDetails.priceFormat,
                securityLevel: operatorDetails.securityLevel,
                statusId: operatorDetails.statusId,
                userName: operatorDetails.username,
              },
            ];
          }

          return grp;
        }),
        groupid: operatorDetails.groupId,
        newOperatorStatus: "SUCCESS",
        operatorid: operatorDetails.id,
        selectedOperator: {
          ...operatorDetails,
          currencyId: operatorDetails.currencyId,
          email: operatorDetails.email,
          firstName: operatorDetails.firstName,
          groupId: operatorDetails.groupId,
          id: operatorDetails.id,
          languageId: operatorDetails.languageId,
          lastName: operatorDetails.lastName,
          mobile: operatorDetails.mobile,
          originId: operatorDetails.originId,
          password: operatorDetails.password,
          priceFormatId: operatorDetails.priceFormat,
          securityLevel: operatorDetails.securityLevel,
          statusId: operatorDetails.statusId,
          userName: operatorDetails.username,
        },
      };
    case DUPLICATE_OPERATOR:
      return {
        ...state,
        newOperatorStatus: "LOADING",
      };
    case DUPLICATE_OPERATOR_SUCCEEDED:
      return {
        ...state,
        groupIndex: state.groupIndex.map((grp) => {
          if (grp.id === selectedOperator.operatorGroupId) {
            grp.operators = [
              ...grp.operators,
              {
                currencyId: selectedOperator.currencyId,
                email: selectedOperator.email,
                firstName: selectedOperator.firstName,
                id: operatorDetails.id,
                languageId: selectedOperator.languageId,
                lastName: selectedOperator.lastName,
                mobile: selectedOperator.mobile,
                operatorGroupId: selectedOperator.operatorGroupId,
                originId: selectedOperator.originId,
                priceFormat: selectedOperator.priceFormat,
                securityLevel: selectedOperator.securityLevel,
                statusId: selectedOperator.statusId,
                userName: operatorDetails.username,
              },
            ];
          }

          return grp;
        }),
        groupid: selectedOperator.operatorGroupId,
        newOperatorStatus: "SUCCESS",
        operatorid: operatorDetails.id,
        selectedOperator: {
          currencyId: selectedOperator.currencyId,
          email: selectedOperator.email,
          firstName: selectedOperator.firstName,
          id: operatorDetails.id,
          languageId: selectedOperator.languageId,
          lastName: selectedOperator.lastName,
          mobile: selectedOperator.mobile,
          operatorGroupId: selectedOperator.operatorGroupId,
          originId: selectedOperator.originId,
          priceFormatId: selectedOperator.priceFormat,
          securityLevel: selectedOperator.securityLevel,
          statusId: selectedOperator.statusId,
          userName: operatorDetails.username,
        },
      };
    case ADD_OPERATOR_FAILED:
      return {
        ...state,
        newOperatorStatus: "ERROR",
      };

    case DELETE_OPERATOR:
      return {
        ...state,
        group: {
          ...state.group,
          [groupid]: {
            ...state.group[groupid],
            operators: [...state.group[groupid].operators.filter((item) => item.id != operatorid)],
          },
        },
      };
    case UPDATE_OPERATOR:
      return {
        ...state,
        id: state.operatorid,
        operatorDetails: {
          ...state.operatorDetails,
          ...action.formDetails,
        },
        updateOperatorStatus: "LOADING",
      };
    case UPDATE_OPERATOR_SUCCEEDED:
      return {
        ...state,
        groupIndex: state.groupIndex.map((grp) => {
          if (operatorDetails.groupId !== state.selectedOperator.operatorGroupId) {
            if (grp.id === operatorDetails.groupId) {
              // need to remove existing from the array and then add new one to it so that dont't have duplicate
              _.remove(grp.operators, (n) => {
                if (n.id == operatorDetails.id) {
                  return true;
                }

                return false;
              });

              return {
                ...grp,
                operators: grp.operators
                  ? [...grp.operators, { ...operatorDetails, userName: operatorDetails.username }]
                  : [{ ...operatorDetails, userName: operatorDetails.username }],
              };
            }
            if (grp.id === state.groupid) {
              return {
                ...grp,
                operators: grp.operators.filter((operator) => operator.id != operatorDetails.id),
              };
            }
          } else if (grp.id === operatorDetails.groupId) {
            return {
              ...grp,
              operators: grp.operators.map((operator) => {
                if (operator.id === operatorDetails.id) {
                  return {
                    ...operator,
                    ...operatorDetails,
                    userName: operatorDetails.username,
                  };
                }

                return operator;
              }),
            };
          }

          return grp;
        }),
        groupid: operatorDetails.groupId,
        operatorid: operatorDetails.id,
        selectedOperator: {
          ...state.selectedOperator,
          ...operatorDetails,
        },
        updateOperatorStatus: "SUCCESS",
      };
    case UPDATE_OPERATOR_FAILED:
      return {
        ...state,
        updateOperatorStatus: "ERROR",
      };
    case SEARCH_OPERATOR:
      return {
        ...state,
        search: search || "",
      };
    default:
      return state;
  }
};

export default operatorList;
