const constants = {
  FETCH_ALERTS_EVENTSPATHS: "MCM::FETCH_ALERTS_EVENTSPATHS",
  FETCH_ALERTS_EVENTSPATHS_FAILED: "MCM::FETCH_ALERTS_EVENTSPATHS_FAILED",
  FETCH_ALERTS_EVENTSPATHS_SUCCEEDED: "MCM::FETCH_ALERTS_EVENTSPATHS_SUCCEEDED",

  FETCH_MARKET_CONTROL_ALERTS: "MCM::FETCH_MARKET_CONTROL_ALERTS",
  FETCH_MARKET_CONTROL_ALERTS_FAILED: "MCM::FETCH_MARKET_CONTROL_ALERTS_FAILED",
  FETCH_MARKET_CONTROL_ALERTS_SUCCEEDED: "MCM::FETCH_MARKET_CONTROL_ALERTS_SUCCEEDED",

  FETCH_MARKET_CONTROL_HURDLES: "MCM::FETCH_MARKET_CONTROL_HURDLES",
  FETCH_MARKET_CONTROL_HURDLES_FAILED: "MCM::FETCH_MARKET_CONTROL_HURDLES_FAILED",

  FETCH_MARKET_CONTROL_HURDLES_SUCCEEDED: "MCM::FETCH_MARKET_CONTROL_HURDLES_SUCCEEDED",
  SAVE_ALERTS_UPDATES: "MCM::SAVE_ALERTS_UPDATES",

  SAVE_ALERTS_UPDATES_FAILED: "MCM::SAVE_ALERTS_UPDATES_FAILED",
  SAVE_ALERTS_UPDATES_SUCCEEDED: "MCM::SAVE_ALERTS_UPDATES_SUCCEEDED",
  SAVE_HURDLES_UPDATES: "MCM::SAVE_HURDLES_UPDATES",

  SELECT_ROW: "MCM::SELECT_ROW",

  SELECT_TAB_HURDLES_ALERTS: "MCM::SELECT_TAB_HURDLES_ALERTS",

  UNDO_UPDATE_ALERTS_ROW: "MCM::UNDO_UPDATE_ALERTS_ROW",
  UNDO_UPDATE_HURDLES: "MCM::UNDO_UPDATE_HURDLES",
  UNSELECT_ROW: "UTL::UNSELECT_ROW",

  UPDATE_ALERTS_CONFIG: "MCM::UPDATE_ALERTS_CONFIG",
  UPDATE_ALERTS_DESCRIPTION: "MCM::UPDATE_ALERTS_DESCRIPTION",
  UPDATE_ALERTS_ROW: "MCM::UPDATE_ALERTS_ROW",

  UPDATE_HURDLES_ROW: "MCM::UPDATE_HURDLES_ROW",
};

export default constants;
