import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../constants";
import filterTypes from "../constants/filterTypes";
import couponModel from "../models/couponModel";
import * as MarketFeedInfoService from "../services/marketFeedInfo";
import * as RiskDataService from "../services/riskData";
import { objectToArray, formatFilterDates } from "../utils";

function* fetchMarketFeedInfo(action) {
  let marketIds = action.marketIds;
  const markets = [];
  if (typeof marketIds === "undefined") {
    marketIds = [];
    const outcomes = yield select((state) => state.riskDataChanges.unsavedOutcomePriceChanges);
    for (const key in outcomes) {
      if (key.indexOf("spreads") < 0) {
        // don't do anything if prop starts with 'spreads'
        const parentMarket = couponModel.getChunk(key).parentPath;
        const parentMarketId = parentMarket.key.substr(1, parentMarket.key.length);
        if (marketIds.indexOf(parentMarketId) === -1) {
          marketIds.push(parentMarketId);
        }
      }
    }
  }
  for (let i = 0; i < marketIds.length; i++) {
    const market = couponModel.getChunk(`m${marketIds[i]}`);
    markets.push(market);
  }
  yield put({ markets, type: actionTypes.SET_MARKET_FEED_INFO_MARKETS });
  const { response, xhr } = yield call(MarketFeedInfoService.fetchMarketFeedInfo, marketIds);
  if (response) {
    yield put({
      linesConnected: response.linesConnected,
      marketsConnected: response.marketsConnected,
      type: actionTypes.FETCH_MARKET_FEED_INFO_SUCCEEDED,
    });
  } else {
    yield put({ type: actionTypes.FETCH_MARKET_FEED_INFO_FAILED });
  }
}

function* disconnectMarketFromPriceFeed() {
  const { linesToDisconnect, markets, marketsToDisconnect } = yield select((state) => state.marketFeedInfo);
  const marketIds = markets.map((market) => market.key.substr(1, market.key.length));
  const marketIdsToDisconnect = marketsToDisconnect.map((market) => market.substr(1, market.length));
  const { response, xhr } = yield call(
    MarketFeedInfoService.disconnectMarketFromPriceFeed,
    marketIds,
    marketIdsToDisconnect,
    linesToDisconnect,
  );
  if (response) {
    yield put({ type: actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED_SUCCEEDED });
    yield put({ type: actionTypes.RESET_MARKET_FEED_INFO });
  } else {
    yield put({ type: actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED_FAILED });
  }
}

export default function* marketFeedInfoSaga() {
  yield takeLatest(actionTypes.FETCH_MARKET_FEED_INFO, fetchMarketFeedInfo);
  yield takeLatest(actionTypes.DISCONNECT_MARKET_FROM_PRICE_FEED, disconnectMarketFromPriceFeed);
}
