import customerManagerConfig from "../../configs/customerManagerConfig";
import actionTypes from "../../constants";

const initialState = {
  ...customerManagerConfig,
  cancelBet: false,
  errMsg: "",
  isDeletingTransaction: false,
  isDeletingTransactionFailed: false,
  isFetchingData: false,
  isFetchingTransactionDetails: false,
  isRequestFailed: false,
  isSavingTransaction: false,
  isSavingTransactionFailed: false,
  isSettled: false,
  isVoid: false,
  moneyTypes: [],
  transactionDetails: null,
  transactionSubTypes: [],
  transactionType: "2",
  transactionsData: null,
};

const customerManagerTransactions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTION_DETAILS:
      return {
        ...state,
        isFetchingTransactionDetails: true,
        transactionDetails: null,
      };
    case actionTypes.FETCH_TRANSACTIONS:
      // case actionTypes.FETCH_TRANSACTION_TYPE:
      return {
        ...state,
        isFetchingData: true,
      };
    case actionTypes.FETCH_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.id,
      };
    case actionTypes.FETCH_TRANSACTIONS_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        transactionsData: action.transactionsData,
      };
    case actionTypes.FETCH_TRANSACTION_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchingTransactionDetails: false,
        transactionDetails: action.transactionDetails,
      };
    case actionTypes.FETCH_TRANSACTION_TYPE_SUCCEEDED:
      return {
        ...state,
        isFetchingData: false,
        transactionSubTypes: action.transactionTypes,
      };
    case actionTypes.FETCH_TRANSACTIONS_FAILED:
    case actionTypes.FETCH_TRANSACTION_DETAILS_FAILED:
    case actionTypes.FETCH_TRANSACTION_TYPE_FAILED:
      return {
        ...state,
        isFetchingData: false,
        isFetchingTransactionDetails: false,
        isRequestFailed: true,
      };
    case actionTypes.SETTLE_TRANSACTION:
      return {
        ...state,
        isSettled: action.bool,
      };
    case actionTypes.VOID_TRANSACTION:
      return {
        ...state,
        isVoid: action.bool,
      };
    case actionTypes.CANCEL_TRANSACTION:
      return {
        ...state,
        cancelBet: action.bool,
      };
    case actionTypes.REVOKE_TRANSACTION:
      return {
        ...state,
        isSavingTransaction: true,
        isSavingTransactionFailed: false,
      };
    case actionTypes.REVOKE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        isSavingTransaction: false,
        isSavingTransactionFailed: false,
      };
    case actionTypes.REVOKE_TRANSACTION_FAILED:
      return {
        ...state,
        errMsg: action.errorMessage,
        isSavingTransaction: false,
        isSavingTransactionFailed: true,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;

    case actionTypes.DELETE_TRANSACTION:
      // case actionTypes.FETCH_TRANSACTION_TYPE:
      return {
        ...state,
        isDeletingTransaction: true,
      };
    case actionTypes.DELETE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        isDeletingTransaction: false,
        isDeletingTransactionFailed: false,
      };
    case actionTypes.DELETE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        isDeletingTransaction: false,
        isDeletingTransactionFailed: true,
      };
    case actionTypes.CM_GET_MONEYTYPES_SUCCESS:
      return {
        ...state,
        moneyTypes: action.response,
      };
    default:
      return { ...state };
  }
};

export default customerManagerTransactions;
