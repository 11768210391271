import constants from "./constants";

const initialState = {
  allLocales: null,
  debugResult: null,
  editedData: JSON.stringify({ translationResponseList: [] }),
  enable_UndoSaveButton: false,
  newAddedVocah: false,
  perviousData: null,
  rowSelect: false,
  secPerData: JSON.stringify({ translationResponseList: [] }),
  selectedVocabDelete: false,
  sreachResult: null,
  updateBaseVocabSuccesfully: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.RESET_PAGE_STATE:
      return {
        ...state,
        enable_UndoSaveButton: false,
      };
    case constants.FETCH_SEARCH_TEXT_DATA_SUCCEEDED:
      return {
        ...state,
        perviousData: JSON.stringify(action.results),
        secPerData: JSON.stringify(action.results),
        selectedVocabDelete: false,
        sreachResult: action.results,
      };
    case constants.FETCH_SEARCH_TEXT_DATA_FAILED:
      return {
        ...state,
        sreachResult: null,
      };
    case constants.FETCH_ALL_LOCALES_DATA_SUCCEEDED:
      const selectedLanguages = [];
      action.results.map((data, index) => {
        if (data.preferred) {
          selectedLanguages.push({ index, locals: data.locale.localeString });
        }
      });

      return {
        ...state,
        allLocales: action.results,
        selectedLanguages,
      };
    case constants.FETCH_ALL_LOCALES_DATA_FAILED:
      return {
        ...state,
        allLocales: null,
      };
    case constants.EMPTY_ALL_LOCALS_DATA:
      return {
        ...state,
        sreachResult: null,
      };
    case constants.SELECTED_LANGUAGES:
      return {
        ...state,
        selectedLanguages: action.selectedLanguages,
      };
    case constants.UNDO_TEXT_CHANGES:
      return {
        ...state,
        editedData: JSON.stringify({ translationResponseList: [] }),
        enable_UndoSaveButton: false,
        secPerData: JSON.stringify({ translationResponseList: [] }),
        sreachResult: action.baseResult,
      };
    case constants.ON_TABLE_ROW_EDIT:
      const perData = JSON.parse(state.secPerData);
      const editedData = JSON.parse(state.editedData);
      const editedrow = perData.translationResponseList[action.row.id - 1];
      const index = action.row.id - 1;
      var name = action.cellName;
      perData.translationResponseList[index].translationMap[name] = action.cellValue;
      editedrow.translationMap[name] = action.cellValue;
      const indexOfEdit = _.findIndex(editedData.translationResponseList, (o) => o.vocab === editedrow.vocab);
      if (indexOfEdit >= 0) {
        editedData.translationResponseList[indexOfEdit] = editedrow;
      } else {
        editedData.translationResponseList.push(editedrow);
      }

      return {
        ...state,
        editedData: JSON.stringify(editedData),
        enable_UndoSaveButton: true,
        secPerData: JSON.stringify(perData),
        sreachResult: perData,
      };

    case constants.ADD_NEW_BASE_VOCAB_INTO_TABLE:
      const parsedData = JSON.parse(state.secPerData);
      const editData = JSON.parse(state.editedData);
      parsedData.translationResponseList.push(action.details);
      editData.translationResponseList.push(action.details);

      return {
        ...state,
        editedData: JSON.stringify(editData),
        enable_UndoSaveButton: true,
        secPerData: JSON.stringify(parsedData),
        sreachResult: parsedData,
      };
    case constants.ADDING_BASE_VOCAB_WORD_SUCCEEDED:
      return {
        ...state,
        editedData: JSON.stringify({ translationResponseList: [] }),
        newAddedVocah: action.results,
      };
    case constants.ADDING_BASE_VOCAB_WORD_FAILED:
      return {
        ...state,
        newAddedVocah: false,
      };
    case constants.DELETE_BASE_TRANSLATIONS_SUCCEEDED:
      return {
        ...state,
        rowSelect: false,
        selectedVocabDelete: true,
      };
    case constants.ON_ROW_SELECTED:
      return {
        ...state,
        rowSelect: action.val,
      };
    case constants.DELETE_BASE_TRANSLATIONS_FAILED:
      return {
        ...state,
        newAddedVocah: false,
      };
    case constants.UPDATE_BASE_VOCAB_WORD_SUCCEEDED:
      return {
        ...state,
        editedData: JSON.stringify({ translationResponseList: [] }),
        enable_UndoSaveButton: false,
        updateBaseVocabSuccesfully: true,
      };
    case constants.UPDATE_BASE_VOCAB_WORD_FAILED:
      return {
        ...state,
        updateBaseVocabSuccesfully: false,
      };
    case constants.DEBUG_WORD_SEARCH_SUCCEEDED:
      return {
        ...state,
        debugResult: action.results,
      };
    case constants.DEBUG_WORD_SEARCH_FAILED:
      return {
        ...state,
        debugResult: null,
      };
    default:
      return state;
  }
}
