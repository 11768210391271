const constants = {
  FETCH_EVENT_TOTALS: "FETCH_EVENT_TOTALS",
  FETCH_EVENT_TOTALS_FAILED: "FETCH_EVENT_TOTALS_FAILED",
  FETCH_EVENT_TOTALS_SUCCEEDED: "FETCH_EVENT_TOTALS_SUCCEEDED",

  FETCH_GAME_RESULTS_PERIOD_POINTS: "FETCH_GAME_RESULTS_PERIOD_POINTS",
  FETCH_GAME_RESULTS_PERIOD_POINTS_FAILED: "FETCH_GAME_RESULTS_PERIOD_POINTS_FAILED",
  FETCH_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED: "FETCH_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED",

  UPDATE_EVENT_TOTALS: "UPDATE_EVENT_TOTALS",
  UPDATE_EVENT_TOTALS_FAILED: "UPDATE_EVENT_TOTALS_FAILED",
  UPDATE_EVENT_TOTALS_STATE: "UPDATE_EVENT_TOTALS_STATE",

  UPDATE_EVENT_TOTALS_SUCCEEDED: "UPDATE_EVENT_TOTALS_SUCCEEDED",
  UPDATE_GAME_RESULTS_PERIOD_POINTS: "UPDATE_GAME_RESULTS_PERIOD_POINTS",
  UPDATE_GAME_RESULTS_PERIOD_POINTS_FAILED: "UPDATE_GAME_RESULTS_PERIOD_POINTS_FAILED",

  UPDATE_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED: "UPDATE_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED",

  UPDATE_GAME_RESULTS_VOID_PERIOD: "UPDATE_GAME_RESULTS_VOID_PERIOD",
  UPDATE_GAME_RESULTS_VOID_PERIOD_FAILED: "UPDATE_GAME_RESULTS_VOID_PERIOD_FAILED",
  UPDATE_GAME_RESULTS_VOID_PERIOD_SUCCEEDED: "UPDATE_GAME_RESULTS_VOID_PERIOD_SUCCEEDED",
};

export default constants;
