import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const promotionXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchPromotions(id) {
  const url = `/bo/promotions${id ? `/${id}` : ""}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionCampaign(id) {
  const url = `/bo/promotions/${id}/campaigns`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionalGroupsWithBrandId() {
  const url = `/bo/promotionalgroups`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addPromotion(data) {
  const url = `/bo/promotions`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function copyPromotion(data) {
  const url = `/bo/promotions/copy`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deletePromotion(promotionId) {
  const url = `/bo/promotions/${promotionId}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updatePromotion({ data, id }) {
  const url = `/bo/promotions${id ? `/${id}` : ""}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_PUT, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketTypes(code) {
  const url = `/bo/marketTypes?code=${code}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSportEventPaths(id, itemType, excludeEmptyEventPaths = true) {
  const url = `/bo/consolidatedaccumulatorrisk/filter/tree?id=${id}&type=${itemType}&excludeEmptyEventPaths=${excludeEmptyEventPaths}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
