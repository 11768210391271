import actionTypes from "../constants";

const initialState = {
  fetchingOutcomePriceByLineFailed: null,
  isFetchingOutcomePriceByLine: null,
  otherLinesPrices: [],
  outcomeId: null,
  outcomeKeysTogglingVisibility: [],
  outcomePriceByLine: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_OUTCOME_VISIBILITY:
      return {
        ...state,
        outcomeKeysTogglingVisibility: [...state.outcomeKeysTogglingVisibility, action.outcomeData.key],
      };
    case actionTypes.TOGGLE_OUTCOME_VISIBILITY_SUCCEEDED:
    case actionTypes.TOGGLE_OUTCOME_VISIBILITY_FAILED:
      const index = state.outcomeKeysTogglingVisibility.indexOf(action.key);

      return {
        ...state,
        outcomeKeysTogglingVisibility: [
          ...state.outcomeKeysTogglingVisibility.slice(0, index),
          ...state.outcomeKeysTogglingVisibility.slice(index + 1),
        ],
      };

    case actionTypes.FETCH_OUTCOME_PRICE_BY_LINE:
      return {
        ...state,
        fetchingOutcomePriceByLineFailed: false,
        isFetchingOutcomePriceByLine: action.showLoadingIcon,
        outcomeId: action.outcomeId || null,
      };
    case actionTypes.FETCH_OUTCOME_PRICE_BY_LINE_SUCCEEDED:
      const prices = _.cloneDeep(state.otherLinesPrices);
      prices.push({ line: action.lineName, lineId: action.lineId, price: action.price });

      return {
        ...state,
        isFetchingOutcomePriceByLine: false,
        otherLinesPrices: prices,
        outcomeId: null,
        outcomePriceByLine: action.price,
      };
    case actionTypes.FETCH_OUTCOME_PRICE_BY_LINE_FAILED:
      return { ...state, fetchingOutcomePriceByLineFailed: true, isFetchingOutcomePriceByLine: false, outcomeId: null };

    case actionTypes.CANCEL_FETCH_OUTCOME_PRICE_BY_LINE:
      return {
        ...state,
        fetchingOutcomePriceByLineFailed: false,
        isFetchingOutcomePriceByLine: false,
        outcomeId: null,
      };
    case actionTypes.CLEAR_OUTCOME_PRICE_BY_LINE:
      return {
        ...state,
        fetchingOutcomePriceByLineFailed: false,
        isFetchingOutcomePriceByLine: false,
        otherLinesPrices: [],
        outcomeId: null,
        outcomePriceByLine: null,
      };

    default:
      return { ...state };
  }
}
