import { searchByOptions } from "./constants";

import { formatFilterDates } from "utils";


export function buildQueryParams(params) {
  const paramsArray = [];
  Object.keys(params).forEach((key) => {
    paramsArray.push(`${key}=${params[key]}`);
  });

  if (paramsArray.length > 0) {
    return `?${paramsArray.join("&")}`;
  }

  return "";
}

export function cleanStatus(text) {
  return `${text.toLowerCase().replace(/_/g, " ")}`.replace(/\b[a-z]/g, (f) => f.toUpperCase());
}

export function filterAPIPayload(filters) {
  let query = "?";
  if (filters.selectedDate != "") {
    const selectedDate = formatFilterDates(filters.selectedRequestDate);
    query += `${query.substr(query.length - 1, 1) == "?" ? "" : "&"}startDate=${selectedDate.fromDate}&endDate=${
      selectedDate.toDate
    }`;
  }
  if (filters.selectedCurrency != -1) {
    query += `${query.substr(query.length - 1, 1) == "?" ? "" : "&"}currencyId=${filters.selectedCurrency}`;
  }
  if (filters.status.length) {
    let temp = "";
    filters.status.map((o, index) => {
      index + 1 < filters.status.length ? (temp += `${o},`) : (temp += o);
    });
    query += `${query.substr(query.length - 1, 1) == "?" ? "" : "&"}statusIds=${temp}`;
  }
  if (filters.criteria != "") {
    // const searchKey = _.filter(searchByOptions, o => o.id == filters.searchBy)[0]['name'];
    // query += `${query.substr(query.length-1, 1) == '?' ? '' : '&' }${searchKey}=${filters.criteria.split(' ').join('%20')}`;
  }

  return {
    app: filters.tabIndex ? "withdrawals" : "deposits",
    filters_data: filters,
    query,
  };
}
