import _ from "lodash";

import constants from "./constants";
import { combLiabilityData, modifiyData } from "./helper";

import { makeIterable } from "utils";

const initialState = {
  CombLiabilityActions: null,
  CombLiabilityIndicators: null,
  back_Up: null,
  createdBackUpCombLiability: null,
  modifiedCombLiabilityIndicators: null,
  modifiedRow: {},
  originbackUpCombLiability: null,
};

const CombLiability_Limits = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_COMBLIABILITY_LIMITS_SUCCEEDED:
      const response = action.response;
      const data = combLiabilityData(response);

      return {
        ...state,
        CombLiabilityIndicators: JSON.stringify(data),
        back_Up: JSON.stringify(action.response),
        createdBackUpCombLiability: JSON.stringify(data),
        modifiedRow: {},
        originbackUpCombLiability: JSON.stringify(action.response),
      };
    case constants.FETCH_COMBLIABILITY_LIMITS_FAILED:
      return {
        ...state,
        CombLiabilityIndicators: null,
        backUpCombLiability: null,
      };
    case constants.FETCH_COMBLIABILITY_ACTIONS_SUCCEEDED:
      return {
        ...state,
        CombLiabilityActions: action.response,
      };
    case constants.FETCH_COMBLIABILITY_ACTIONS_FAILED:
      return {
        ...state,
        CombLiabilityActions: null,
      };
    case constants.ON_COMB_ROW_SELECTED:
      const { e, isSelected, row } = action;

      return {
        ...state,
        selectedRow: row,
      };
    case constants.ON_COMB_LIABILITY_EXIT:
      const perviousDate = state.createdBackUpCombLiability;

      return {
        ...state,
        CombLiabilityIndicators: perviousDate,
        modifiedRow: {},
        originbackUpCombLiability: state.back_Up,
      };
    case constants.ON_COMB_ROW_UPDATE_SUCCEEDED:
      return {
        ...state,
        modifiedRow: {},
      };
    case constants.ON_COMB_ROW_UPDATE_FAILED:
      return {
        ...state,
        modifiedRow: {},
      };
    case constants.ON_COMB_ROW_EDIT:
      const { cellName, cellValue, rows } = action;
      const selectedRowToEdit = state.selectedRow;
      const prevData = JSON.parse(state.originbackUpCombLiability);
      const isModified = !_.isEqual(selectedRowToEdit[cellName], parseInt(cellValue));
      const modifiedRow = state.modifiedRow;
      const modifiedData = modifiyData(prevData, rows, cellName, cellValue);
      const CombModifiedData = combLiabilityData(modifiedData);
      if (isModified) {
        if (!modifiedRow[rows.combinationLiabilityIndicatorType]) {
          modifiedRow[rows.combinationLiabilityIndicatorType] = [];
          modifiedRow[rows.combinationLiabilityIndicatorType].push(cellName);
        } else if (!_.includes(modifiedRow[rows.combinationLiabilityIndicatorType], cellName)) {
          modifiedRow[rows.combinationLiabilityIndicatorType].push(cellName);
        }
      } else if (modifiedRow[rows.combinationLiabilityIndicatorType]) {
        _.remove(modifiedRow[rows.combinationLiabilityIndicatorType], (f) => f == cellName);
        if (modifiedRow[rows.combinationLiabilityIndicatorType].length == 0) {
          delete modifiedRow[rows.combinationLiabilityIndicatorType];
        }
      }

      return {
        ...state,
        CombLiabilityIndicators: JSON.stringify(CombModifiedData),
        combIsModified: isModified,
        modifiedCombLiabilityIndicators: JSON.stringify(modifiedData),
        modifiedRow,
        originbackUpCombLiability: JSON.stringify(modifiedData),
      };
    default:
      return { ...state };
  }
};

export default CombLiability_Limits;
