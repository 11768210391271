const constants = {
  DELETE_MARKET: "EC::DELETE_MARKET",
  DELETE_MARKETS: "EC::DELETE_MARKETS",
  DELETE_MARKET_FAILED: "EC::DELETE_MARKET_FAILED",

  DELETE_MARKET_SUCCEEDED: "EC::DELETE_MARKET_SUCCEEDED",
  FETCH_MARKET: "EC::FETCH_MARKET",
  FETCH_MARKETS: "EC::FETCH_MARKETS",
  CREATE_NEW_MARKETS: "EC::CREATE_NEW_MARKETS",

  FETCH_MARKETS_FAILED: "EC::FETCH_MARKETS_FAILED",
  CREATE_NEW_MARKETS_FAILED: "EC::CREATE_NEW_MARKETS_FAILED",
  FETCH_MARKETS_SUCCEEDED: "EC::FETCH_MARKETS_SUCCEEDED",

  CLEAR_UPDATED_MARKET_BOOK: "EC::CLEAR_UPDATED_MARKET_BOOK",
  FETCH_MARKET_BOOKS: "EC::FETCH_MARKET_BOOKS",
  CLEAR_MARKET_TYPES: "EC::CLEAR_MARKET_TYPES",

  FETCH_MARKET_BOOKS_FAILED: "EC::FETCH_MARKET_BOOKS_FAILED",
  CREATE_NEW_MARKETS_SUCCEEDED: "EC::CREATE_NEW_MARKETS_SUCCEEDED",
  FETCH_MARKET_BOOKS_SUCCEEDED: "EC::FETCH_MARKET_BOOKS_SUCCEEDED",

  FETCH_MARKET_FAILED: "EC::FETCH_MARKET_FAILED",

  FETCH_MARKET_PERIODS: "EC::FETCH_MARKET_PERIODS",
  FETCH_GAME_RESULT_PERIODS: "EC::FETCH_GAME_RESULT_PERIODS",
  FETCH_MARKET_PERIODS_FAILED: "EC::FETCH_MARKET_PERIODS_FAILED",

  FETCH_GAME_RESULT_PERIODS_FAILED: "EC::FETCH_GAME_RESULT_PERIODS_FAILED",
  FETCH_MARKET_PERIODS_SUCCEEDED: "EC::FETCH_MARKET_PERIODS_SUCCEEDED",
  FETCH_GAME_RESULT_MARKET_TYPES: "EC::FETCH_GAME_RESULT_MARKET_TYPES",

  FETCH_MARKET_PLAYERS: "EC::FETCH_MARKET_PLAYERS",
  FETCH_GAME_RESULT_MARKET_TYPES_FAILED: "EC::FETCH_GAME_RESULT_MARKET_TYPES_FAILED",
  FETCH_MARKET_PLAYERS_FAILED: "EC::FETCH_MARKET_PLAYERS_FAILED",

  FETCH_GAME_RESULT_MARKET_TYPES_SUCCEEDED: "EC::FETCH_GAME_RESULT_MARKET_TYPES_SUCCEEDED",
  FETCH_MARKET_SUCCEEDED: "EC::FETCH_MARKET_SUCCEEDED",
  FETCH_GAME_RESULT_PERIODS_SUCCEEDED: "EC::FETCH_GAME_RESULT_PERIODS_SUCCEEDED",

  REFETCH_MARKET: "EC::REFETCH_MARKET",
  FETCH_MARKET_PLAYERS_SUCCEEDED: "EC::FETCH_MARKET_PLAYERS_SUCCEEDED",
  UPDATE_MARKET_DETAILS: "EC::UPDATE_MARKET_DETAILS",

  FETCH_MARKET_TYPES: "EC::FETCH_MARKET_TYPES",
  UPDATE_MARKET_DETAILS_FAILED: "EC::UPDATE_MARKET_DETAILS_FAILED",
  FETCH_MARKET_TYPES_FAILED: "EC::FETCH_MARKET_TYPES_FAILED",

  UPDATE_MARKET_DETAILS_SUCCEEDED: "EC::UPDATE_MARKET_DETAILS_SUCCEEDED",

  FETCH_MARKET_TYPES_SUCCEEDED: "EC::FETCH_MARKET_TYPES_SUCCEEDED",
  UPDATE_MARKET_OUTCOMES: "EC::UPDATE_MARKET_OUTCOMES",
  RESET_NEW_MARKET_FILTERS: "EC::RESET_NEW_MARKET_FILTERS",
  UPDATE_MARKET_OUTCOMES_FAILED: "EC::UPDATE_MARKET_OUTCOMES_FAILED",

  RESET_NEW_MARKET_PAYLOAD: "EC::RESET_NEW_MARKET_PAYLOAD",
  UPDATE_MARKET_OUTCOMES_SUCCEEDED: "EC::UPDATE_MARKET_OUTCOMES_SUCCEEDED",
  SET_HIDE_OUTCOMES_ON_CREATE_OPTION: "EC::SET_HIDE_OUTCOMES_ON_CREATE_OPTION",
  SET_NEW_MARKET_OUTCOMES: "EC::SET_NEW_MARKET_OUTCOMES",

  UPDATE_MARKET_BOOKS: "EC::UPDATE_MARKET_BOOKS",
  SET_UPDATED_MARKET_BOOK: "EC::SET_UPDATED_MARKET_BOOK",
  UPDATE_MARKET_BOOKS_FAILED: "EC::UPDATE_MARKET_BOOKS_FAILED",

  UPDATE_GAME_RESULT_FILTERS: "EC::UPDATE_GAME_RESULT_FILTERS",
  UPDATE_MARKET_BOOKS_SUCCEEDED: "EC::UPDATE_MARKET_BOOKS_SUCCEEDED",
  UPDATE_NEW_MARKET_FILTERS: "EC::UPDATE_NEW_MARKET_FILTERS",

  UPDATE_NEW_MARKET_PAYLOAD: "EC::UPDATE_NEW_MARKET_PAYLOAD",
};

const validMarketDetailsProps = ["description", "cutoffTime", "autoOpenTime", "status", "autoSettle", "print"];

const marketPropsThatIsArray = [
  "players1",
  "players2",
  "playerHandicapList1",
  "playerHandicapList2",
  "playerValuesList1",
  "playerValuesList2",
];

const validNewMarketProps = [
  ...marketPropsThatIsArray,
  "marketTypeId",
  "periodId",
  "opponentId",
  "instanceNumber",
  "int1",
  "float1",
  "float2",
  "float3",
  "str",
  "multiRangeStr",
  "maxScore1",
  "maxScoreDraw",
  "maxScore2",
  "otherOutcomeType",
];

// Fixed values, there's no api
const outcomeTypes = [
  { key: "NONE", value: "None" },
  { key: "SINGLE_OTHER_OUTCOME", value: "Single Other Outcome" },
  { key: "OUTCOMES_WITH_PLUS", value: "1X2 Other Outcomes" },
];

export default constants;
export { constants, validMarketDetailsProps, outcomeTypes, marketPropsThatIsArray, validNewMarketProps };
