import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* getInitialJackPotBetWinnerData(action) {
  const { response, xhr } = yield call(API.fetchInitialJackPotWinnerData, action);
  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_JACKPOT_WINNER_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_JACKPOT_WINNER_DATA_FAILED });
  }
}

function* payoutJackPotWinner(action) {
  const { response, xhr } = yield call(API.onPayoutJackPotWinner, action);
  if (response) {
    yield put({ results: response, type: constants.PAYOUT_JACKPOT_BET_WINNER_SUCCEEDED });
    toastr.add({ message: i18n.t("Jackpot.Successfully pay out the winner"), type: "SUCCESS" });
  } else {
    yield put({ type: constants.PAYOUT_JACKPOT_BET_WINNER_FAILED });
    const errorMessage = JSON.parse(xhr.responseText).err[0];
    toastr.add({ message: `${i18n.t("Jackpot.Unable to paidout")}. ${errorMessage}`, type: "ERROR" });
  }
}

export default function* winnerjackPotSaga() {
  yield takeLatest(constants.FETCH_INITIAL_JACKPOT_WINNER_DATA, getInitialJackPotBetWinnerData);
  yield takeLatest(constants.PAYOUT_JACKPOT_BET_WINNER, payoutJackPotWinner);
}
