import { put, call, takeLatest, select } from "redux-saga/effects";

import constants from "./constants";
import { getValidMarketData } from "./helpers";
import * as API from "./services";

import { toastr } from "components/toastr";
import i18n from "i18n";

function* fetchAllSportsConfig(action) {
  // sports
  const { response } = yield call(API.fetchAllSport);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ALL_SPORTS_CONFIG_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_SPORTS_CONFIG_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* fetchALLMarketType(action) {
  // fetch market table
  const { response, xhr } = yield call(API.fetchALLMarketTypeConfig);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ALL_MARKET_TYPES_CONFIG_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_MARKET_TYPES_CONFIG_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

function* updateMarketTypesConfig(action) {
  const state = yield select();
  const isValid = getValidMarketData(state, action.payload);
  if (isValid) {
    yield put({ type: constants.UPDATE_MARKET_TYPES_CONFIG_FAILED });
    toastr.add({ message: `duplicate position`, type: "ERROR" });
  } else {
    const { response, xhr } = yield call(API.updateMarketTypesConfig, action.payload);
    if (response) {
      toastr.add({ message: i18n.t("Utilities.Updated Successfully"), type: "SUCCESS" });
      yield put({ type: constants.UPDATE_MARKET_TYPES_CONFIG_SUCCEEDED });
      yield put({ payload: action.payload.sports, type: constants.FETCH_ALL_MARKET_TYPES_CONFIG });
    } else {
      yield put({ type: constants.UPDATE_MARKET_TYPES_CONFIG_FAILED });
      toastr.add({ message: `Error!`, type: "ERROR" });
    }
  }
}

function* fetchAllWagerLimitGroupConfig(action) {
  const { response, xhr } = yield call(API.fetchAllWagerLimitGroupConfig);
  if (response) {
    yield put({ response, type: constants.FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG_FAILED });
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

export default function* utilitiesMarketTypeConfig() {
  yield takeLatest(constants.FETCH_ALL_SPORTS_CONFIG, fetchAllSportsConfig);
  yield takeLatest(constants.FETCH_ALL_MARKET_TYPES_CONFIG, fetchALLMarketType);
  yield takeLatest(constants.UPDATE_MARKET_TYPES_CONFIG, updateMarketTypesConfig);
  yield takeLatest(constants.FETCH_ALL_WAGER_LIMIT_GROUP_CONFIG, fetchAllWagerLimitGroupConfig);
}
