import qs from "query-string";

import { DUMMY_ID } from "./constants";

import { makeIterable, formatFilterDates } from "utils";

export const getParentPaths = (path, pathsMap, parentPaths) => {
  const parentPath = pathsMap[path.id];
  if (parentPath) {
    if (parentPath.level !== 0) {
      parentPaths.push({ data: { isExpanded: true }, id: parentPath.id });
      getParentPaths(pathsMap[parentPath.parentId], pathsMap, parentPaths);
    } else {
      parentPaths.push({ data: {}, id: path.id }); // push the root path for ancestors array
    }
  }

  return parentPaths;
};

const defaultParameters = { includeEvents: true, virtual: 0 };
export const generateParameters = ({
  defaultParams = defaultParameters,
  datesFilter,
  marketStatusFilter,
  datesParamFormat = "FROMDATE",
  sportCode,
}) => {
  let dates = datesFilter ? formatFilterDates(datesFilter) : {};
  if (datesParamFormat === "DATEFROM") {
    dates = {
      dateFrom: dates.fromDate,
      dateTo: dates.toDate,
    };
  }

  return qs.stringify(
    {
      ...(defaultParams && defaultParams),
      ...dates,
      // ...{sportCode},
      ...(marketStatusFilter && { marketStatusIds: marketStatusFilter }),
    },
    { encode: false },
  );
};
export const hasDescMatch = (path, str) => {
  if (path) {
    return path.description.trim().toLowerCase().indexOf(str.toLowerCase()) >= 0;
  }

  return false;
};
export const hasFeedIdMatch = (path, str) => {
  if (path) {
    return path.feedCode && path.feedCode.trim().toLowerCase().indexOf(str.toLowerCase()) >= 0;
  }

  return false;
};
export const hasEventIdMatch = (path, str) => {
  if (path) {
    return path.id == str;
  }

  return false;
};
export const resetMatchSearchProps = (path) => {
  const _path = { ...path };
  if (path.directMatch !== undefined) {
    delete _path.directMatch;
  }
  if (path.hasChildWithMatch !== undefined) {
    delete _path.hasChildWithMatch;
  }
  if (path.hasDirectMatchOnParent !== undefined) {
    delete _path.hasDirectMatchOnParent;
  }
  if (path.level !== 0) {
    _path.isExpanded = false;
  }
  if (_path.type === "path") {
    _path.searchCount = 0; // reset search count
  }

  return _path;
};

export const insertUniqueId = (array, id) => {
  if (array.includes(id)) {
    return array;
  }

  return [...array, id];
};

export const generatePathsMap = ({
  activePathId,
  baseUrl,
  level,
  newPathsMap,
  parentId,
  paths,
  pathsMap,
  sportCode,
  type,
}) => {
  paths.forEach((path) => {
    const oldRef = { ...pathsMap[path.id] };
    // Remove eventCount and marketCount because these are not sent as props when these have no values
    delete oldRef.eventCount;
    delete oldRef.marketCount;
    const ref = { ...oldRef, ...path, childMarkets: [], eventPaths: [], events: [], level, parentId };
    if (type) {
      ref.type = type;
    } else if (path.eventType === undefined) {
      ref.type = "path";
    } else {
      ref.type = "event";
    }
    if (ref.type === "event") {
      ref.childMarkets = oldRef.childMarkets ? [...oldRef.childMarkets] : [];
    }
    ref.isActive = activePathId === path.id;
    if (level === 0) {
      ref.isExpanded = true;
    }
    switch (ref.type) {
      case "event":
        ref.url = `${baseUrl}/${sportCode}/e${path.id}`;
        break;
      default:
        ref.url = `${baseUrl}/${sportCode}/p${path.id}`;
    }
    if (parentId && newPathsMap[parentId]) {
      newPathsMap[parentId].eventPaths = insertUniqueId(newPathsMap[parentId].eventPaths, path.id);
    }
    newPathsMap[path.id] = { ...ref };
    const commonProps = {
      activePathId,
      baseUrl,
      level: level + 1,
      newPathsMap,
      parentId: path.id,
      pathsMap,
      sportCode,
    };
    if (path.events) {
      newPathsMap = generatePathsMap({ paths: path.events, ...commonProps, type: "event" });
    }
    if (path.eventPaths) {
      newPathsMap = generatePathsMap({ paths: path.eventPaths, ...commonProps });
    }
  });

  return newPathsMap;
};

export const generateChildMarkets = ({
  activePathId,
  baseUrl,
  eventId,
  level,
  markets,
  parentId,
  pathsMap,
  sportCode,
}) => {
  markets.forEach((path) => {
    const oldRef = { ...pathsMap[path.id] };
    const ref = { ...oldRef, ...path, childMarkets: [], eventPaths: [], events: [], level, parentId, type: "market" };
    ref.isActive = activePathId === path.id;
    ref.url = `${baseUrl}/${sportCode}/m${path.id}?eventId=${eventId}`;
    if (parentId && pathsMap[parentId]) {
      pathsMap[parentId].childMarkets = insertUniqueId(pathsMap[parentId].childMarkets, path.id);
    }
    pathsMap[path.id] = { ...ref };
    const commonProps = { activePathId, baseUrl, eventId, level: level + 1, parentId: path.id, pathsMap, sportCode };
    if (path.childMarkets) {
      pathsMap = generateChildMarkets({ markets: path.childMarkets, ...commonProps });
    }
  });

  return pathsMap;
};

export const generatePathAncestors = ({ ancestors, path, pathsMap }) => {
  const parentPath = pathsMap[path.parentId];
  ancestors.push(path.id);
  if (parentPath) {
    ancestors = generatePathAncestors({ ancestors, path: parentPath, pathsMap });
  }

  return ancestors;
};

export const markParentPath = (path, newPathsMap) => {
  if (path && !path.hasChildWithMatch) {
    path.hasChildWithMatch = true;
    path.isExpanded = true;
    markParentPath(newPathsMap[path.parentId], newPathsMap);
  }

  return newPathsMap;
};

export const searchPaths = (paths, state, searchOption, str, pathsMap, newPathsMap) => {
  const aaa = false;
  paths.forEach((id) => {
    const path = pathsMap[id];
    const parentPath = newPathsMap[path.parentId];
    const ref = resetMatchSearchProps(path);

    if (searchOption && searchOption != "" && searchOption != "ANY") {
      if (state.searchOption == "FEED_ID") {
        // will match feed id
        if (hasFeedIdMatch(ref, str)) {
          ref.directMatch = true;
          newPathsMap = markParentPath(parentPath, newPathsMap);
        }
      } else {
        // will exact match event id
        if (hasEventIdMatch(ref, str)) {
          ref.directMatch = true;
          newPathsMap = markParentPath(parentPath, newPathsMap);
        }
      }
    } else {
      // will match description
      if (hasDescMatch(ref, str)) {
        ref.directMatch = true;
        newPathsMap = markParentPath(parentPath, newPathsMap);
      }
    }

    if (parentPath && (parentPath.directMatch || parentPath.hasDirectMatchOnParent)) {
      ref.hasDirectMatchOnParent = true;
    }
    newPathsMap[id] = ref;
    if (path.type === "path") {
      path.searchCount = 0; // reset search count
    }
    if (path.events) {
      newPathsMap = searchPaths(path.events, state, searchOption, str, pathsMap, newPathsMap);
    }
    if (path.eventPaths) {
      newPathsMap = searchPaths(path.eventPaths, state, searchOption, str, pathsMap, newPathsMap);
    }
  });

  return newPathsMap;
};

export const modifyParentPathsCountBy = (pathsMap, path, value) => {
  if (path) {
    path.count = path.count ? path.count + value : 0 + value;
    path.eventCount = path.eventCount ? path.eventCount + value : 0 + value;
    if (path.count < 0) {
      path.count = 0;
    }
    if (path.eventCount < 0) {
      path.eventCount = 0;
    }
    if (path && path.parentId) {
      modifyParentPathsCountBy(pathsMap, pathsMap[path.parentId], value);
    }
  }

  return pathsMap;
};

export const sortChildrenByDescription = (array) =>
  [...array].sort((a, b) => {
    const descA = a.description.toUpperCase();
    const descB = b.description.toUpperCase();
    if (descA < descB) {
      return -1;
    }
    if (descA > descB) {
      return 1;
    }

    return 0;
  });

export const getSortedPaths = (array, pathsMap) => {
  const sortedEventPaths = array.map((id) => ({ description: pathsMap[id].description, id }));

  return sortChildrenByDescription(sortedEventPaths).map((e) => e.id);
};

export const search = ({ pathsMap, searchOption, searchStr, state }) => {
  let newPathsMap = {};
  const activeSportPath = pathsMap[state.activeSportId];
  if (!activeSportPath) {
    return pathsMap;
  }
  if (searchStr) {
    newPathsMap = {
      [state.activeSportId]: activeSportPath,
      ...searchPaths(
        [...activeSportPath.eventPaths, ...activeSportPath.events],
        state,
        searchOption,
        searchStr,
        pathsMap,
        {},
      ),
    };
  } else {
    newPathsMap = [...makeIterable(pathsMap)].reduce((accu, val) => {
      const newVal = resetMatchSearchProps(val);
      if (val.level === 0) {
        accu[val.id] = newVal;
      } else {
        accu[val.id] = { ...newVal, isExpanded: false };
      }

      return accu;
    }, {});
  }
  const events = [...makeIterable(newPathsMap)].filter((path) => {
    if (path.type === "event") {
      if (path.directMatch || path.hasDirectMatchOnParent) {
        return true;
      }
    }

    return false;
  });
  if (events.length) {
    events.forEach((event) => {
      addCountToParentPath({ path: newPathsMap[event.parentId], pathsMap: newPathsMap, searchStr });
    });
  } else {
    // if no results found, set active sport's searchCount to 0
    newPathsMap[state.activeSportId].searchCount = 0;
  }

  return newPathsMap;
};

export const getCommonPathProps = ({ activePathId, pathsMap, sportCode }) => ({
  childMarkets: [],
  count: 0,
  eventCount: 0,
  eventPaths: [],
  events: [],
  id: DUMMY_ID,
  isActive: true,
  isExpanded: false,
  level: pathsMap[activePathId].level + 1,
  parentId: activePathId,
  sportCode,
  type: "path",
  url: "",
});

export const deletePath = ({ id, state }) => {
  let pathsMap = { ...state.pathsMap };
  const path = pathsMap[id];
  const parentPath = pathsMap[path.parentId];
  let pathForModification = null;
  const otherProps = {
    childMarkets: [...pathsMap[parentPath.id].childMarkets],
    eventPaths: [...pathsMap[parentPath.id].eventPaths],
    events: [...pathsMap[parentPath.id].events],
  };
  let targetKey;
  if (path.type === "path") {
    targetKey = "eventPaths";
    otherProps.count = parentPath.count ? parentPath.count - 1 : 0;
    otherProps.eventCount = parentPath.eventCount ? parentPath.eventCount - 1 : 0;
  } else if (path.type === "event") {
    targetKey = "events";
    otherProps.count = parentPath.count ? parentPath.count - 1 : 0;
    otherProps.eventCount = parentPath.eventCount ? parentPath.eventCount - 1 : 0;
    pathForModification = { ...parentPath };
  } else if (path.type === "market") {
    targetKey = "childMarkets";
    if (parentPath.type === "event") {
      otherProps.marketCount = parentPath.marketCount ? parentPath.marketCount - 1 : 0;
    }
  }
  otherProps[targetKey] = otherProps[targetKey].filter((id) => id !== path.id);
  pathsMap[parentPath.id] = {
    ...parentPath,
    ...otherProps,
  };
  delete pathsMap[path.id];
  if (pathForModification && pathForModification.parentId) {
    pathsMap = modifyParentPathsCountBy(pathsMap, pathsMap[pathForModification.parentId], -1);
  }

  return { ...state, pathsMap };
};

export const addInitialCountToParentPath = ({ path, pathsMap }) => {
  path.count++;
  if (pathsMap[path.parentId]) {
    addInitialCountToParentPath({ path: pathsMap[path.parentId], pathsMap });
  }

  return pathsMap;
};

export const getParentEvent = ({ event, path, pathsMap }) => {
  if (path.type === "event") {
    event = path;
  } else if (pathsMap[path.id]) {
    event = getParentEvent({ event, path: pathsMap[path.id], pathsMap });
  }

  return event;
};

export const addCountToParentPath = ({ path, pathsMap, searchStr }) => {
  if (path.searchStr !== searchStr) {
    path.searchStr = searchStr;
    path.searchCount = 1;
  } else {
    path.searchCount++;
  }
  if (pathsMap[path.parentId]) {
    addCountToParentPath({ path: pathsMap[path.parentId], pathsMap, searchStr });
  }

  return pathsMap;
};

export const subtractCountFromParentPath = ({ path, pathsMap }) => {
  path.searchCount--;
  if (pathsMap[path.parentId]) {
    subtractCountFromParentPath({ path: pathsMap[path.parentId], pathsMap });
  }

  return pathsMap;
};

export const generateSportsDropdown = () => {
  const list = [
    {
      code: "FOOT",
      defaultEventPathId: 240,
      description: "Football",
      id: 240,
      rawDesc: "Football",
      sportCode: "FOOT",
    },
    {
      code: "HORS",
      defaultEventPathId: 364,
      description: "Horse Racing",
      id: 364,
      rawDesc: "Horse Racing",
      sportCode: "HORS",
    },
    {
      code: "MOSP",
      defaultEventPathId: 22881,
      description: "Motor Sports",
      id: 22881,
      rawDesc: "Motor Sports",
      sportCode: "MOSP",
    },
    {
      code: "TENN",
      defaultEventPathId: 239,
      description: "Tennis",
      id: 239,
      rawDesc: "Tennis",
      sportCode: "TENN",
    },
    {
      code: "AMFB",
      defaultEventPathId: 1,
      description: "American Football",
      id: 1,
      rawDesc: "American Football",
      sportCode: "AMFB",
    },
    {
      code: "SNOO",
      defaultEventPathId: 22884,
      description: "Snooker",
      id: 22884,
      rawDesc: "Snooker",
      sportCode: "SNOO",
    },
    {
      code: "BOXI",
      defaultEventPathId: 238,
      description: "Boxing",
      id: 238,
      rawDesc: "Boxing",
      sportCode: "BOXI",
    },
    {
      code: "BASE",
      defaultEventPathId: 226,
      description: "Baseball",
      id: 226,
      rawDesc: "Baseball",
      sportCode: "BASE",
    },
    {
      code: "GREY",
      defaultEventPathId: 1000,
      description: "Greyhound Racing",
      id: 1000,
      rawDesc: "Greyhound Racing",
      sportCode: "GREY",
    },
    {
      code: "BASK",
      count: 13,
      defaultEventPathId: 227,
      description: "Basketball",
      id: 227,
      rawDesc: "Basketball",
      sportCode: "BASK",
    },
    {
      code: "RUGU",
      defaultEventPathId: 22877,
      description: "Rugby Union",
      id: 22877,
      rawDesc: "Rugby Union",
      sportCode: "RUGU",
    },
    {
      code: "ARCH",
      defaultEventPathId: 6006,
      description: "Archery",
      id: 6006,
      rawDesc: "Archery",
      sportCode: "ARCH",
    },
    {
      code: "COUN",
      defaultEventPathId: 6001,
      description: "Counter-Strike",
      id: 6001,
      rawDesc: "Counter-Strike",
      sportCode: "COUN",
    },
    {
      code: "DOTA",
      defaultEventPathId: 6002,
      description: "Dota",
      id: 6002,
      rawDesc: "Dota",
      sportCode: "DOTA",
    },
    {
      code: "LEAG",
      defaultEventPathId: 6003,
      description: "League of Legends",
      id: 6003,
      rawDesc: "League of Legends",
      sportCode: "LEAG",
    },
    {
      code: "OVER",
      defaultEventPathId: 6005,
      description: "Overwatch",
      id: 6005,
      rawDesc: "Overwatch",
      sportCode: "OVER",
    },
    {
      code: "HAND",
      defaultEventPathId: 1100,
      description: "Handball",
      id: 1100,
      rawDesc: "Handball",
      sportCode: "HAND",
    },
    {
      code: "VOLL",
      defaultEventPathId: 1200,
      description: "Volleyball",
      id: 1200,
      rawDesc: "Volleyball",
      sportCode: "VOLL",
    },
    {
      code: "BAND",
      defaultEventPathId: 1700,
      description: "Bandy",
      id: 1700,
      rawDesc: "Bandy",
      sportCode: "BAND",
    },
    {
      code: "FUTS",
      defaultEventPathId: 1600,
      description: "Futsal",
      id: 1600,
      rawDesc: "Futsal",
      sportCode: "FUTS",
    },
    {
      code: "TABL",
      defaultEventPathId: 1900,
      description: "Table Tennis",
      id: 1900,
      rawDesc: "Table Tennis",
      sportCode: "TABL",
    },
    {
      code: "ICEH",
      defaultEventPathId: 2100,
      description: "Ice Hockey",
      id: 2100,
      rawDesc: "Ice Hockey",
      sportCode: "ICEH",
    },
    {
      code: "BOWL",
      defaultEventPathId: 3400,
      description: "Bowls",
      id: 3400,
      rawDesc: "Bowls",
      sportCode: "BOWL",
    },
    {
      code: "BADM",
      defaultEventPathId: 5000,
      description: "Badminton",
      id: 5000,
      rawDesc: "Badminton",
      sportCode: "BADM",
    },
    {
      code: "CYCL",
      defaultEventPathId: 2700,
      description: "Cycling",
      id: 2700,
      rawDesc: "Cycling",
      sportCode: "CYCL",
    },
    {
      code: "PERL",
      defaultEventPathId: 6015,
      description: "Perfect League",
      id: 6015,
      rawDesc: "Perfect League",
      sportCode: "PERL",
    },
    {
      code: "FLOO",
      defaultEventPathId: 3600,
      description: "Floorball",
      id: 3600,
      rawDesc: "Floorball",
      sportCode: "FLOO",
    },
    {
      code: "MMA",
      defaultEventPathId: 6011,
      description: "UFC/MMA",
      id: 6011,
      rawDesc: "UFC/MMA",
      sportCode: "MMA",
    },
  ];

  return list;
};
