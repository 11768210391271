import constants, { paths } from "./constants";

const buttonConfig = {
  [paths.SPORT]: ["EVENT"],
  [paths.COUNTRY]: ["EVENT", "RANKEVENT", "GAMEEVENT"],
  [paths.LEAGUE]: ["EVENT", "RANKEVENT", "GAMEEVENT"],
  [paths.LEAGUE_NEWGAME]: [],
  [paths.LEAGUE_NEWRANK]: [],
  [paths.GAME_EVENT]: ["MARKET", "EDITMARKET", "CANCLE_PREMATCH_MARKET", "CANCLE_LIVE_MARKET"],
  [paths.RANK_EVENT]: ["MARKET", "EDITMARKET", "CANCLE_LIVE_MARKET", "CANCLE_PREMATCH_MARKET"],
  [paths.MARKET]: ["MARKET", "EDITMARKET", "CANCLE_MARKET"],
  [paths.GAME_RESULT]: [],
};

export function enableHeaderButtons(type) {
  if (
    [
      paths.SPORT,
      paths.COUNTRY,
      paths.LEAGUE,
      paths.GAME_EVENT,
      paths.RANK_EVENT,
      paths.LEAGUE_NEWGAME,
      paths.LEAGUE_NEWRANK,
      paths.MARKET,
      paths.GAME_RESULT,
    ].includes(type)
  ) {
    return {
      activePage: type,
      enableHeaderButtons: buttonConfig[type],
      type: constants.UPDATE_HEADER_BUTTONS,
    };
  }

  return undefined;
}

export function selectPath(path) {
  return {
    path,
    type: constants.SELECT_EVENT_CREATOR_PATH,
  };
}

export function clearSelectedPath() {
  return {
    type: constants.CLEAR_SELECTED_EVENT_CREATOR_PATH,
  };
}

export function fetchSportOtherOptions(code) {
  return {
    code,
    type: constants.FETCH_SPORT_OTHER_OPTIONS,
  };
}

export function updateSaveButtonState(isSaveButtonDisabled) {
  return {
    isSaveButtonDisabled,
    type: constants.UPDATE_SAVE_BUTTON_STATE,
  };
}

export function toggleBulkUpdate(isBulkUpdateActive) {
  return {
    isBulkUpdateActive,
    type: constants.TOGGLE_BULK_UPDATE,
  };
}

export function cancleBets(details) {
  return {
    details,
    type: constants.CANCLE_BETS,
  };
}

export function cancleBetsOnMarket(market) {
  return {
    market,
    type: constants.CANCLE_BETS_ON_MARKET,
  };
}
