const constants = {
  FETCH_INITIAL_ACCOUNTS_DATA: "EC::FETCH_INITIAL_ACCOUNTS_DATA",
  FETCH_INITIAL_ACCOUNTS_DATA_FAILED: "EC::FETCH_INITIAL_ACCOUNTS_DATA_FAILED",
  FETCH_INITIAL_ACCOUNTS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_ACCOUNTS_DATA_SUCCEEDED",

  FETCH_INITIAL_BETS_DATA: "EC::FETCH_INITIAL_BETS_DATA",
  FETCH_INITIAL_BETS_DATA_FAILED: "EC::FETCH_INITIAL_BETS_DATA_FAILED",
  FETCH_INITIAL_BETS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_BETS_DATA_SUCCEEDED",

  FETCH_INITIAL_CANCELLEDBETS_DATA: "EC::FETCH_INITIAL_CANCELLEDBETS_DATA",
  CHANGE_BET_TYPE: "EC::CHANGE_BET_TYPE",
  FETCH_INITIAL_CANCELLEDBETS_DATA_FAILED: "EC::FETCH_INITIAL_CANCELLEDBETS_DATA_FAILED",

  FETCH_INITIAL_CANCELLEDBETS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_CANCELLEDBETS_DATA_SUCCEEDED",
  FETCH_INITIAL_CASHOUTBETS_DATA: "EC::FETCH_INITIAL_CASHOUTBETS_DATA",
  CHANGE_TOTAL_STAKE_TYPE: "EC::CHANGE_TOTAL_STAKE_TYPE",

  FETCH_INITIAL_CASHOUTBETS_DATA_FAILED: "EC::FETCH_INITIAL_CASHOUTBETS_DATA_FAILED",
  CHANGE_RISK_AMOUNT_TYPE: "EC::CHANGE_RISK_AMOUNT_TYPE",
  FETCH_INITIAL_CASHOUTBETS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_CASHOUTBETS_DATA_SUCCEEDED",

  CHANGE_RISK_AMOUNT_VALUE: "EC::CHANGE_RISK_AMOUNT_VALUE",
  FETCH_INITIAL_FAILEDBETS_DATA: "EC::FETCH_INITIAL_FAILEDBETS_DATA",
  ADD_SPORT: "EC::ADD_SPORT",

  FETCH_INITIAL_FAILEDBETS_DATA_FAILED: "EC::FETCH_INITIAL_FAILEDBETS_DATA_FAILED",
  CHANGE_MAX_ROWS: "EC::CHANGE_MAX_ROWS",
  FETCH_INITIAL_FAILEDBETS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_FAILEDBETS_DATA_SUCCEEDED",

  CHANGE_ACTIVE_TAB_INDEX: "EC::CHANGE_ACTIVE_TAB_INDEX",
  FETCH_INITIAL_PAYMENTS_DATA: "EC::FETCH_INITIAL_PAYMENTS_DATA",
  CHANGE_FONT_COLOR: "EC::CHANGE_FONT_COLOR",

  FETCH_INITIAL_PAYMENTS_DATA_FAILED: "EC::FETCH_INITIAL_PAYMENTS_DATA_FAILED",
  ADD_FORMATTER_ITEM: "EC::ADD_FORMATTER_ITEM",
  FETCH_INITIAL_PAYMENTS_DATA_SUCCEEDED: "EC::FETCH_INITIAL_PAYMENTS_DATA_SUCCEEDED",

  CHANGE_BACKGROUND_COLOR: "EC::CHANGE_BACKGROUND_COLOR",
  FETCH_SUBSEQUENT_ACCOUNTS_DATA: "EC::FETCH_SUBSEQUENT_ACCOUNTS_DATA",
  CHANGE_LOWER_LIMIT: "EC::CHANGE_LOWER_LIMIT",

  FETCH_SUBSEQUENT_BETS_DATA: "EC::FETCH_SUBSEQUENT_BETS_DATA",
  CHANGE_SPORTS_TYPE: "EC::CHANGE_SPORTS_TYPE",
  FETCH_SUBSEQUENT_BETS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_BETS_DATA_FAILED",

  CHANGE_TOTAL_STAKE_VALUE: "EC::CHANGE_TOTAL_STAKE_VALUE",
  FETCH_SUBSEQUENT_BETS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_BETS_DATA_SUCCEEDED",
  CHANGE_BRAND_VALUE: "EC::CHANGE_BRAND_VALUE",

  FETCH_SUBSEQUENT_ACCOUNTS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_ACCOUNTS_DATA_FAILED",
  CHANGE_UPPER_LIMIT: "EC::CHANGE_UPPER_LIMIT",
  FETCH_SUBSEQUENT_ACCOUNTS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_ACCOUNTS_DATA_SUCCEEDED",

  FETCH_BRANDS_FILTER: "FETCH_BRANDS_FILTER",

  FETCH_SUBSEQUENT_CANCELLEDBETS_DATA: "EC::FETCH_SUBSEQUENT_CANCELLEDBETS_DATA",
  FETCH_BRANDS_FILTER_FAILED: "FETCH_BRANDS_FILTER_FAILED",

  FETCH_SUBSEQUENT_PAYMENTS_DATA: "EC::FETCH_SUBSEQUENT_PAYMENTS_DATA",
  FETCH_BRANDS_FILTER_SUCCEEDED: "FETCH_BRANDS_FILTER_SUCCEEDED",

  FETCH_SUBSEQUENT_PAYMENTS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_PAYMENTS_DATA_FAILED",

  FETCH_DEFAULT_CURRENCY: "FETCH_DEFAULT_CURRENCY",
  FETCH_SUBSEQUENT_PAYMENTS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_PAYMENTS_DATA_SUCCEEDED",

  FETCH_DEFAULT_CURRENCY_SUCCEEDED: "FETCH_DEFAULT_CURRENCY_SUCCEEDED",

  FETCH_SUBSEQUENT_FAILEDBETS_DATA: "EC::FETCH_SUBSEQUENT_FAILEDBETS_DATA",

  FETCH_OPERATOR_DETAILS: "FETCH_OPERATOR_DETAILS",
  FETCH_SUBSEQUENT_FAILEDBETS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_FAILEDBETS_DATA_FAILED",
  FETCH_OPERATOR_DETAILS_FAILED: "FETCH_OPERATOR_DETAILS_FAILED",
  FETCH_SUBSEQUENT_FAILEDBETS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_FAILEDBETS_DATA_SUCCEEDED",
  FETCH_OPERATOR_DETAILS_SUCCEEDED: "FETCH_OPERATOR_DETAILS_SUCCEEDED",
  FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_FAILED",

  FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_CANCELLEDBETS_DATA_SUCCEEDED",
  FETCH_SUBSEQUENT_CASHOUTBETS_DATA: "EC::FETCH_SUBSEQUENT_CASHOUTBETS_DATA",

  FETCH_SUBSEQUENT_CASHOUTBETS_DATA_FAILED: "EC::FETCH_SUBSEQUENT_CASHOUTBETS_DATA_FAILED",
  FETCH_SUBSEQUENT_CASHOUTBETS_DATA_SUCCEEDED: "EC::FETCH_SUBSEQUENT_CASHOUTBETS_DATA_SUCCEEDED",

  HIDE_PREFERENCES_MODAL: "EC:HIDE_PREFERENCES_MODAL",

  LOCK_TABLE: "EC::LOCK_TABLE",

  REHYDRATE_STATE: "EC::REHYDRATE_STATE",

  REMOVE_FORMATTER_ITEM: "EC::REMOVE_FORMATTER_ITEM",

  REMOVE_SCROLL_TOP: "EC::REMOVE_SCROLL_TOP",

  REMOVE_SPORT: "EC::REMOVE_SPORT",
  TOGGLE_ACTIVE: "EC::TOGGLE_ACTIVE",
  SAVE_CONSTANTS_TO_STORE: "EC::SAVE_CONSTANTS_TO_STORE",

  TOGGLE_EXPAND: "EC::TOGGLE_EXPAND",
  SET_HEADER_PREFERENCE: "EC::SET_HEADER_PREFERENCE",
  TOGGLE_MARKETS_TYPE: "EC::TOGGLE_MARKETS_TYPE",

  SET_SEARCH_FILTER: "EC::SET_SEARCH_FILTER",
  SHOW_PREFERENCES_MODAL: "EC::SHOW_PREFERENCES_MODAL",

  UNLOCK_TABLE: "EC::UNLOCK_TABLE",
};

export default constants;
