import constants from "../constants";
import constant from "../Grid/constants";

const initialState = {
  calendar: {
    enteredTo: null,
    from: null,
    to: null,
  },
  criteria: "",

  isSearching: false,
  searchBy: "1",

  searchCriteria: {
    accountid: "",
    accountname: "",
    accountnumber: "",
    username: "",
  },
  selectedCriteria: "candidates",
  selectedCurrency: -1,
  selectedCustomCriteria: "",
  selectedDateRange: 2,

  selectedRequestDate: "Last Hour",
  status: [],
  statusList: [],
  tabIndex: 0,
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case constants.SELECT_TAB:
      return {
        ...state,
        tabIndex: action.index,
      };
    case constants.RESET_STATUS:
      return {
        ...state,
        status: [],
      };
    case constants.RESET_CALENDAR:
      return {
        ...state,
        calendar: {
          enteredTo: null,
          from: null,
          to: null,
        },
      };
    case constants.SET_CALENDAR:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          ...action.calendar,
        },
      };
    case constants.SELECT_STATUS:
      // return {
      //   ...state,
      //   status: state.status.includes(action.status)
      //     ? state.status.filter(status => status !== action.status)
      //     : [...state.status, action.status],
      // }
      return {
        ...state,
        status: action.status,
      };
    case constants.CHANGE_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.currency,
      };
    case constants.CHANGE_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.dateRange,
      };
    case constants.UPDATE_CUSTOM_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          ...action.criteria,
        },
      };
    case constants.SELECT_CUSTOM_CRITERIA:
      return {
        ...state,
        selectedCustomCriteria: action.criteria,
      };
    case constants.SELECT_CRITERIA:
      return {
        ...state,
        selectedCriteria: action.criteria,
      };

    case constants.FETCH_CUSTOM_SEARCH:
      return {
        ...state,
        isSearching: true,
      };
    case constants.FETCH_CUSTOM_SEARCH_SUCCEEDED:
      return {
        ...state,
        isSearching: false,
      };
    case constants.FETCH_CUSTOM_SEARCH_FAILED:
      return {
        ...state,
        isSearching: false,
      };
    case constants.SET_DFAULT_SELECTED_STATUS:
      let defaultStutus = [];
      _.map(action.response, (status, index) => {
        if (status.approvalCandidate) {
          defaultStutus.push(status.id);
        }
      });
      if (defaultStutus.length == 0) {
        defaultStutus = [12, 0, 16, 10]; // default ilter to be highlighted yellow
      }

      return {
        ...state,
        status: defaultStutus,
      };
    // case constants.SET_BASE_CURRENCY:
    //   return {
    //     ...state,
    //     selectedCurrency: action.response.id
    //   }
    case constants.SET_FILTER_DATE:
      return {
        ...state,
        selectedRequestDate: action.value,
      };
    case constants.SET_SEARCH_BY:
      return {
        ...state,
        searchBy: action.value,
      };
    case constant.FILTER_GRID:
      return {
        ...state,
        criteria: action.criteria,
      };
    case constants.FETCH_STATUS_LIST:
      return {
        ...state,
        statusList: action.response,
      };

    default:
      return state;
  }
};

export default filters;
