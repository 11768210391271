import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

const headers1 = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchEmergencyLockdown() {
  const url = `/bo/productlockdowns`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateEmergencyLockdown(data) {
  const url = `/bo/productlockdowns/bulkupdate`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, data, headers1)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
