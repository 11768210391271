import { makeIterable } from "utils";

export const createReport = ({ type = "group", level = 0, parentKey = "", items = [], key, group, desc }) => {
  const report = {
    assigned: [],
    desc,
    isChecked_assigned: false,
    isChecked_unassigned: false,
    isExpanded_assigned: false,
    isExpanded_unassigned: false,
    key,
    level,
    parentKey,
    type,
    unassigned: [],
  };
  report[group] = items;

  return report;
};
export const generateReportsMap = (reportGroups) => {
  const reportsMap = {};
  [...makeIterable(reportGroups, true)].forEach((reportGroup) => {
    const reports = reportGroup.value;
    const group = reportGroup.key;
    [...makeIterable(reports, true)].map((report) => {
      if (report.key.indexOf("/") >= 0) {
        const [parentKey, subKey] = report.key.split("/");
        if (reportsMap[parentKey]) {
          reportsMap[parentKey][group].push(report.key);
        } else {
          reportsMap[parentKey] = createReport({ desc: parentKey, group, items: [report.key], key: parentKey });
        }
        if (!reportsMap[report.key]) {
          reportsMap[report.key] = createReport({
            desc: subKey,
            group,
            items: [...report.value],
            key: report.key,
            level: 1,
            parentKey,
          });
        } else {
          // if nested group already exists
          reportsMap[report.key][group] = [...report.value]
            .reduce((accu, val) => {
              if (!accu.includes(val)) {
                accu = [...accu, val];
              }

              return accu;
            }, reportsMap[report.key][group])
            .sort();
        }

        report.value.map((key) => {
          reportsMap[key] = createReport({ desc: key, group, key, parentKey: report.key, type: "report" });
        });
      } else {
        if (!reportsMap[report.key]) {
          reportsMap[report.key] = createReport({ desc: report.key, group, items: [...report.value], key: report.key });
        } else {
          reportsMap[report.key][group] = [...reportsMap[report.key][group], ...report.value];
        }
        report.value.map((key) => {
          reportsMap[key] = createReport({ desc: key, group, key, parentKey: report.key, type: "report" });
        });
      }
    });
  });

  return sortReports(reportsMap);
};

export const sortReports = (reportsMap) => {
  // sort assigned/unassigned array by type first, then by key
  for (const report of makeIterable(reportsMap)) {
    if (report.type === "group") {
      report.assigned.sort((a, b) => {
        if (reportsMap[a].type > reportsMap[b].type) return -1;
        if (reportsMap[a].type < reportsMap[b].type) return 1;
        if (reportsMap[a].type === reportsMap[b].type) {
          if (reportsMap[a].key > reportsMap[b].key) return 1;
          if (reportsMap[a].key < reportsMap[b].key) return -1;

          return 0;
        }
      });
      report.unassigned.sort((a, b) => {
        if (reportsMap[a].type > reportsMap[b].type) return -1;
        if (reportsMap[a].type < reportsMap[b].type) return 1;
        if (reportsMap[a].type === reportsMap[b].type) {
          if (reportsMap[a].key > reportsMap[b].key) return 1;
          if (reportsMap[a].key < reportsMap[b].key) return -1;

          return 0;
        }
      });
    }
  }

  return reportsMap;
};

export const generateReportsList = ({ group, reportsMap }) =>
  [...makeIterable(reportsMap)]
    .filter((report) => report.type === "group" && report.level === 0 && !!report[group].length)
    .map((report) => report.key)
    .sort();

export const countAssignedWithCheck = ({ group, reportsMap }) =>
  [...makeIterable(reportsMap)].filter((report) => report[`isChecked_${group}`]).length;

export const getReportsArray = ({ group, isSorted = false, reportsMap }) => {
  const allGroup = [...makeIterable(reportsMap)].reduce((accu, val) => [...accu, ...val[group]], []);
  if (isSorted) {
    return allGroup.filter((key) => reportsMap[key].type === "report").sort();
  }

  return allGroup;
};

export const updateReportMap = ({ report, reportsMap }) => ({
  ...reportsMap,
  [report.key]: {
    ...reportsMap[report.key],
    ...report,
  },
});

export const getReportsToToggle = ({ group, isChecked, report, reports, reportsMap }) => {
  report[group].forEach((key) => {
    reports.push({ key, [`isChecked_${group}`]: isChecked });
    if (reportsMap[key][group].length) {
      reports = getReportsToToggle({ group, isChecked, report: reportsMap[key], reports, reportsMap });
    }
  });

  return reports;
};

export const getParentsToUncheck = ({ group, report, reports, reportsMap }) => {
  reports.push({ key: report.key, [`isChecked_${group}`]: false });
  if (reportsMap[report.parentKey]) {
    reports = getParentsToUncheck({ group, report: reportsMap[report.parentKey], reports, reportsMap });
  }

  return reports;
};
export const getParentsToCheck = ({ group, report, reports, reportsMap }) => {
  // auto-check parent folder once all children are checked
  const parentMap = reportsMap[report.parentKey];
  if (parentMap && parentMap[group].length) {
    let checkParent = true;
    for (const key of parentMap[group]) {
      if (!reportsMap[key][`isChecked_${group}`] && key !== report.key) {
        checkParent = false;
        break;
      }
    }
    if (checkParent) {
      reports.push({ key: parentMap.key, [`isChecked_${group}`]: true });
      reports = getParentsToCheck({ group, report: parentMap, reports, reportsMap });
    }
  }

  return reports;
};
