import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchBetRestrictionKeysXhr = null;
const fetchUnusedBetRestrictionKeysXhr = null;
const fetchMatrixDataXhr = null;
const fetchSportPeriodsXhr = null;
const fetchSportMarketTypeGroupsXhr = null;
const fetchBetRestrictionsHistoryXhr = null;
const updateBetRestrictionsXhr = null;
const updateBetRestrictionsHistoryXhr = null;
const deleteBetRestrictionsHistoryXhr = null;
const restoreBetRestrictionsHistoryXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchBetRestrictionKeys(id) {
  let url = `/bo/betrestrictions/keys`;
  if (id) {
    url = `${url}?eventTypeId=${id}`;
  }

  return performHttpCall(fetchBetRestrictionKeysXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUnusedBetRestrictionKeys(id) {
  const url = `/bo/betrestrictions/unusedkeys`;

  return performHttpCall(fetchUnusedBetRestrictionKeysXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchSportPeriods(code) {
  const url = `/bo/periods?code=${code}`;

  return performHttpCall(fetchSportPeriodsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketTypeGroups(params) {
  const url = `/bo/staticdata/markettypegroups${params}`;

  return performHttpCall(fetchSportMarketTypeGroupsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBetRestrictionsHistory(betType) {
  const { betRestrictionTypeId, betTypeGroupId, transSubTypeId } = betType;
  const url =
    `/bo/betrestrictions/history?betTypeGroupId=${betTypeGroupId}` +
    `&transSubTypeId=${transSubTypeId}` +
    `&betRestrictionTypeId=${betRestrictionTypeId}`;

  return performHttpCall(fetchBetRestrictionsHistoryXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateBetRestrictions(restrictions) {
  const url = `/bo/betrestrictions`;

  return performHttpCall(updateBetRestrictionsXhr, httpMethods.HTTP_POST, url, restrictions)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateBetRestrictionsHistory(data) {
  const url = `/bo/betrestrictions/history`;

  return performHttpCall(updateBetRestrictionsHistoryXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function restoreBetRestrictionsHistory(id) {
  const url = `/bo/betrestrictions/restore?brHistoryId=${id}`;

  return performHttpCall(restoreBetRestrictionsHistoryXhr, httpMethods.HTTP_POST, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function addNewBetRestrictions(betType, restrictions) {
  const { betRestrictionTypeId, betTypeGroupId, eventPathId, transSubTypeId } = betType;
  let url =
    `/bo/betrestrictions/addCriteria?betTypeGroupId=${betTypeGroupId}` +
    `&transSubTypeId=${transSubTypeId}` +
    `&betRestrictionTypeId=${betRestrictionTypeId}` +
    `&persist=false`;
  if (eventPathId) {
    url = `${url}&eventPathId=${eventPathId}`;
  }

  return performHttpCall(updateBetRestrictionsXhr, httpMethods.HTTP_POST, url, restrictions)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteBetRestrictions(betType, restrictions) {
  const { betRestrictionTypeId, betTypeGroupId, transSubTypeId } = betType;
  const url =
    `/bo/betrestrictions/removeCriteria?betTypeGroupId=${betTypeGroupId}` +
    `&transSubTypeId=${transSubTypeId}` +
    `&betRestrictionTypeId=${betRestrictionTypeId}` +
    `&persist=true`;

  return performHttpCall(updateBetRestrictionsXhr, httpMethods.HTTP_POST, url, restrictions)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteBetRestrictionsHistory(id) {
  const url = `/bo/betrestrictions/history?brHistoryId=${id}`;

  return performHttpCall(deleteBetRestrictionsHistoryXhr, httpMethods.HTTP_DELETE, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMatrixData({ betRestrictionTypeId, betTypeGroupId, eventPathId, transSubTypeId }) {
  let url =
    `/bo/betrestrictions?betTypeGroupId=${betTypeGroupId}` +
    `&transSubTypeId=${transSubTypeId}` +
    `&betRestrictionTypeId=${betRestrictionTypeId}`;
  if (eventPathId !== undefined) {
    url = `${url}&eventPathId=${eventPathId}`;
  }

  return performHttpCall(fetchMatrixDataXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
