import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import * as API from "../../services/customerManager";
import { renderGenericErrorToastMessage } from "../constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";
import { isEmpty } from "lodash";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* updateAccount(action) {
  // try {
  const { response, xhr } = yield call(API.accountDetailsUpdate, action.id, action.params);
  if (response) {
    yield put({ result: response, type: actionTypes.UPDATE_ACCOUNT_SUCCEEDED });
    yield call(getAccountDetailsForSettings, { id: action.id });
    toastr.add({ message: i18n.t("Manager.Account Successfully updated") });
  } else {
    const parseResponse = xhr.response.data;
    const msg =
      parseResponse.err ||
      parseResponse.errorMessage ||
      (parseResponse.exception && parseResponse.exception.message) ||
      (!isEmpty(parseResponse.errors) && parseResponse.errors[0] && parseResponse.errors[0].message);

    yield put({ errorMessage: msg, type: actionTypes.UPDATE_ACCOUNT_FAILED });
    toastr.add({ message: `${i18n.t(`Manager.Error saving Account`)}: ${msg}`, type: "ERROR" });
  }
  // } catch (e) {
  //   yield put({ type: actionTypes.UPDATE_ACCOUNT_FAILED, errorMessage: errMsg })
  //   yield call(renderGenericErrorToastMessage)
  //   toastr.add({ message: `Error saving Account. ${msg}`, type: 'ERROR' })
  // }
}

function* getDpAccount(action) {
  try {
    const { response, xhr } = yield call(API.getDpAccount, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_DRAGON_PAY_ACCOUNT_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_DRAGON_PAY_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_DRAGON_PAY_ACCOUNT_FAILED });
  }
}

function* createDpAccount(action) {
  try {
    const { response, xhr } = yield call(API.createDpAccount, action.id, action.params);
    if (response) {
      if (response.errorMessage) {
        yield put({ modalErrorMsg: response.errorMessage, type: actionTypes.ADD_DRAGON_PAY_ACCOUNT_FAILED });
      } else {
        yield put({ result: response, type: actionTypes.ADD_DRAGON_PAY_ACCOUNT_SUCCEEDED });
        yield call(getDpAccount, { id: action.id });
        toastr.add({ message: i18n.t("Manager.Successfully added Dragon Pay Account") });
      }
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ modalErrorMsg: msg, type: actionTypes.ADD_DRAGON_PAY_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ modalErrorMsg: errMsg, type: actionTypes.ADD_DRAGON_PAY_ACCOUNT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* disableDpAccount(action) {
  try {
    const { response, xhr } = yield call(API.disableDpAccount, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.DISABLE_DRAGON_PAY_ACCOUNT_SUCCEEDED });
      yield call(getDpAccount, { id: action.id });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.DISABLE_DRAGON_PAY_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.DISABLE_DRAGON_PAY_ACCOUNT_FAILED });
  }
}

function* preferDpAccount(action) {
  try {
    const { response, xhr } = yield call(API.preferDpAccount, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.PREFER_DRAGON_PAY_ACCOUNT_SUCCEEDED });
      yield call(getDpAccount, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Successfully changed prefered Dragon Pay Account") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.PREFER_DRAGON_PAY_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.PREFER_DRAGON_PAY_ACCOUNT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* paybackDpAccount(action) {
  try {
    const { response, xhr } = yield call(API.paybackDpAccount, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.PAYBACK_DRAGON_PAY_ACCOUNT_SUCCEEDED });
      yield put({ modalErrorMsg: response.message, type: actionTypes.OPEN_MODAL_ERROR });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.err || parseResponse.exception.message;
      yield put({ type: actionTypes.PAYBACK_DRAGON_PAY_ACCOUNT_FAILED });
      yield put({ modalErrorMsg: msg, type: actionTypes.OPEN_MODAL_ERROR });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.PAYBACK_DRAGON_PAY_ACCOUNT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getAccountStatus(action) {
  try {
    const { response, xhr } = yield call(API.getAccountStatus, action.id);
    if (response) {
      yield put({ accountStatus: response, type: actionTypes.FETCH_ACCOUNTSTATUS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNTSTATUS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNTSTATUS_FAILED });
  }
}

function* updateAccountStatus(action) {
  try {
    const { response, xhr } = yield call(API.updateAccountStatus, action.id, action.params);
    if (response) {
      yield put({ accountStatus: response, type: actionTypes.UPDATE_ACCOUNTSTATUS_SUCCEEDED });
      toastr.add({ message: i18n.t("Manager.Successfully updated Account Status") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.err || parseResponse.exception.message;
      yield put({ type: actionTypes.UPDATE_ACCOUNTSTATUS_FAILED });
      yield put({ modalErrorMsg: msg, type: actionTypes.OPEN_MODAL_ERROR });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.UPDATE_ACCOUNTSTATUS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getAccountSecurityDetails(action) {
  try {
    const { response, xhr } = yield call(API.getAccountSecurityDetails, action.accountId);
    if (response) {
      yield put({ accountSecurityDetails: response, type: actionTypes.FETCH_ACCOUNTSECURITYDETAILS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.err || parseResponse.exception.message;
      yield put({ type: actionTypes.FETCH_ACCOUNTSECURITYDETAILS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNTSECURITYDETAILS_FAILED });
  }
}

function* updateAccountSecurityDetails(action) {
  // try {
  // if(action.params.pin || action.params.pin === ""){
  //   toastr.add({ message: "PIN is a required", type: 'ERROR' })
  // }else{
  const { response, xhr } = yield call(API.updateAccountSecurityDetails, action.accountId, action.params);
  if (response) {
    yield put({ msg: response, type: actionTypes.UPDATE_ACCOUNTSECURITYDETAILS_SUCCEEDED });
    toastr.add({ message: i18n.t("Manager.Successfully updated Account Security Details") });
  } else {
    const parseResponse = JSON.parse(xhr.response);
    const msg = parseResponse.err || parseResponse.exception.message || parseResponse.errors[0].message;
    yield put({ type: actionTypes.UPDATE_ACCOUNTSECURITYDETAILS_FAILED });
    toastr.add({ message: msg, type: "ERROR" });
  }
  // }
  // } catch (e) {
  //  yield put({type: actionTypes.UPDATE_ACCOUNTSECURITYDETAILS_FAILED, errorMessage: errMsg});
  //  yield call(renderGenericErrorToastMessage);
  // }
}

function* updateCustomerBankAccount(action) {
  const { response, xhr } = yield call(API.updateCustomerBankAccount, action.accountId, action.params);
  if (response) {
    // yield put({ type: constants.FETCH_CUSTOMER_BANK_ACCOUNT, path:  {accountId:action.accountId} })
    yield put({ type: actionTypes.UPDATE_CUSTOMER_BANK_ACCOUNT_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.UPDATE_CUSTOMER_BANK_ACCOUNT_SUCCEEDED });
    // const parseResponse = JSON.parse(xhr.response)
    // const msg = parseResponse.err || parseResponse.exception.message || parseResponse.errors[0].message
    // yield put({ type: actionTypes.UPDATE_ACCOUNTSECURITYDETAILS_FAILED })
    // toastr.add({ message: msg, type: 'ERROR' })
  }
}

function* updateAccountSecurityPassword(action) {
  try {
    const { response, xhr } = yield call(
      API.updateAccountSecurityPassword,
      action.accountId,
      action.method,
      action.params,
    );
    let message = "";
    if (response) {
      yield put({ msg: response, type: actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD_SUCCEEDED });
      switch (action.method) {
        case "password":
          message = i18n.t("Manager.Password successfully updated");
          break;
        case "passwordemail":
          message = i18n.t("Manager.New password successfully sent to your email");
          break;
        case "passwordsms":
          message = i18n.t("Manager.New password successfully sent to your mobile");
          break;
      }
      toastr.add({ message });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errors[0].message || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getPromoRewards(action) {
  try {
    const { response, xhr } = yield call(API.getPromoRewards, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_PROMO_REWARDS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_PROMO_REWARDS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_PROMO_REWARDS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getAccountMoreDetails(action) {
  try {
    const { response, xhr } = yield call(API.getAccountMoreDetails, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_ACCOUNT_MORE_DETAILS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNT_MORE_DETAILS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNT_MORE_DETAILS_FAILED });
  }
}

function* updateAccountMoreDetails(action) {
  try {
    const { response, xhr } = yield call(API.updateAccountMoreDetails, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.UPDATE_ACCOUNT_MORE_DETAILS_SUCCEEDED });
      yield put({ id: action.id, type: actionTypes.FETCH_COMMENTS });
      toastr.add({ message: i18n.t("Manager.Successfully updated Account Details") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.UPDATE_ACCOUNT_MORE_DETAILS_FAILED });
      toastr.add({ message: `${i18n.t("Manager.Updating Account Details failed")}, ${msg}`, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.UPDATE_ACCOUNT_MORE_DETAILS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* getPaymentProviders(action) {
  try {
    const { response, xhr } = yield call(API.getPaymentProviders, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_PAYMENT_PROVIDERS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_PAYMENT_PROVIDERS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_PAYMENT_PROVIDERS_FAILED });
  }
}

function* getAccountDetailsForSettings(action) {
  try {
    const { response, xhr } = yield call(API.getAccountDetailsForSettings, action.id);
    if (response) {
      yield put({ accountStatus: response, type: actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS_FAILED });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS_FAILED });
  }
}

function* getBanksBYCountry(action) {
  const { response } = yield call(API.getBanksBYCountry, action.countryId);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_BANKS_BY_COUNTRY_SUCCEEDED });
  } else {
    yield put({ type: actionTypes.FETCH_BANKS_BY_COUNTRY_FAILED });
  }
}

function* getCustomerBankAccountDetail(action) {
  try {
    const { response, xhr } = yield call(API.getCustomerBankAccountDetail, action.accountId);
    if (response) {
      yield put({
        bankAccountDetails: response.bankAccountDetails,
        type: actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT_SUCCEEDED,
      });
    } else {
      yield put({ type: actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT_FAILED });
  }
}

export default function* customerManagerAccountSaga() {
  yield takeLatest(actionTypes.UPDATE_ACCOUNT, updateAccount);
  yield takeLatest(actionTypes.FETCH_DRAGON_PAY_ACCOUNT, getDpAccount);
  yield takeLatest(actionTypes.ADD_DRAGON_PAY_ACCOUNT, createDpAccount);
  yield takeLatest(actionTypes.DISABLE_DRAGON_PAY_ACCOUNT, disableDpAccount);
  yield takeLatest(actionTypes.PREFER_DRAGON_PAY_ACCOUNT, preferDpAccount);
  yield takeLatest(actionTypes.PAYBACK_DRAGON_PAY_ACCOUNT, paybackDpAccount);

  yield takeLatest(actionTypes.FETCH_ACCOUNTSTATUS, getAccountStatus);
  yield takeLatest(actionTypes.UPDATE_ACCOUNTSTATUS, updateAccountStatus);
  yield takeLatest(actionTypes.FETCH_PROMO_REWARDS, getPromoRewards);

  yield takeLatest(actionTypes.FETCH_ACCOUNT_MORE_DETAILS, getAccountMoreDetails);
  yield takeLatest(actionTypes.UPDATE_ACCOUNT_MORE_DETAILS, updateAccountMoreDetails);
  yield takeLatest(actionTypes.FETCH_PAYMENT_PROVIDERS, getPaymentProviders);

  yield takeLatest(actionTypes.FETCH_ACCOUNTSECURITYDETAILS, getAccountSecurityDetails);
  yield takeLatest(actionTypes.UPDATE_ACCOUNTSECURITYDETAILS, updateAccountSecurityDetails);
  yield takeLatest(actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD, updateAccountSecurityPassword);

  yield takeLatest(actionTypes.UPDATE_CUSTOMER_BANK_ACCOUNT, updateCustomerBankAccount);

  yield takeLatest(actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS, getAccountDetailsForSettings);
  yield takeLatest(actionTypes.FETCH_BANKS_BY_COUNTRY, getBanksBYCountry);

  yield takeLatest(actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT, getCustomerBankAccountDetail);
}
// todo
