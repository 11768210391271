import originalAxios from "axios";

import api from "./api";

let editUserColumnsXhr = null;
let editUserSportsXhr = null;

let fetchUserRiskColumnsXhr = null;
let fetchUserSportsXhr = null;

export function fetchUserRiskSports(userId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/risksports?accountId=${userId}`;

    if (fetchUserSportsXhr) {
      fetchUserSportsXhr.cancel("cancelled due to concurrent request");
    }

    fetchUserSportsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchUserSportsXhr);
      fetchUserSportsXhr = null;
      resolve(response);
    } catch (err) {
      fetchUserSportsXhr = null;
      reject(err);
    }
    fetchUserSportsXhr = null;
  })
    .then((userSports) => ({ userSports: userSports.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUserRiskColumns(userId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/riskcolumns?accountId=${userId}`;

    if (fetchUserRiskColumnsXhr) {
      fetchUserRiskColumnsXhr.cancel("cancelled due to concurrent request");
    }

    fetchUserRiskColumnsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchUserRiskColumnsXhr);
      fetchUserRiskColumnsXhr = null;
      resolve(response);
    } catch (err) {
      fetchUserRiskColumnsXhr = null;
      reject(err);
    }
    fetchUserRiskColumnsXhr = null;
  })
    .then((userColumns) => ({ userColumns: userColumns.data }))
    .catch((xhr) => ({ xhr }));
}

export function editUserRiskColumnsAndSports(userId, columnIds, sportCodes) {
  const promise1 = new Promise((resolve, reject) => {
    const url = `/bo/risksports?accountId=${userId}`;
    const requestBody = sportCodes;

    if (editUserSportsXhr) {
      editUserSportsXhr.cancel("cancelled due to concurrent request");
    }

    editUserSportsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, editUserSportsXhr);
      editUserSportsXhr = null;
      resolve(response);
    } catch (err) {
      editUserSportsXhr = null;
      reject(err);
    }
    editUserSportsXhr = null;
  });

  const promise2 = new Promise((resolve, reject) => {
    if (editUserColumnsXhr) {
      editUserColumnsXhr.abort();
    }
    const url = `/bo/riskcolumns?accountId=${userId}`;
    const requestBody = columnIds;

    if (editUserColumnsXhr) {
      editUserColumnsXhr.cancel("cancelled due to concurrent request");
    }

    editUserColumnsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, editUserColumnsXhr);
      editUserColumnsXhr = null;
      resolve(response);
    } catch (err) {
      editUserColumnsXhr = null;
      reject(err);
    }
    editUserColumnsXhr = null;
  });

  return Promise.all([promise1, promise2])
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
