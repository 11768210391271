import _ from "lodash";

import constants, { DUMMY_ID, availableDates } from "./constants";

import filterTypes from "constants/filterTypes";
import {
  generateParameters,
  generatePathsMap,
  generateChildMarkets,
  generatePathAncestors,
  search,
  getSortedPaths,
  deletePath,
  modifyParentPathsCountBy,
  addInitialCountToParentPath,
  addCountToParentPath,
  subtractCountFromParentPath,
} from "containersV2/SportsTree/helpers";
import { makeIterable } from "utils";

const datesFilter = availableDates[0];
const defaultParams = { includeEvents: true, virtual: 0 };
const datesParamFormat = "";
const marketStatusFilter = [...makeIterable(filterTypes.STATUS)][0].value;

const initialState = {
  datesFilter,
  defaultParams,
  disabledJackPot: false,
  initialJackPotBetData: [],
  marketListFetching: false,
  marketStatusFilter,
  parameters: generateParameters({ datesFilter, datesParamFormat, defaultParams, marketStatusFilter }),
  selectedBetCurrencyStakeMap: {},
  selectedBetJackPotConsolations: [],
  selectedBetJackPotEvent: null,
  selectedBetJackPotMarket: null,
  selectedBetJackPotMarketDetails: null,
  selectedJackpot: null,
  showNewJackpotBetModal: false,
  showWinnerModal: false,
  tFunc: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_INITIAL_JACKPOTBET_DATA_SUCCEEDED:
      return {
        ...state,
        initialJackPotBetData: action.results,
      };
    case constants.FETCH_INITIAL_JACKPOTBET_DATA_FAILED:
      return {
        ...state,
        initialJackPotBetData: [],
      };
    case constants.ON_DISABLE_JACKPOT_SUCCEEDED:
      return {
        ...state,
        disabledJackPot: action.results.success,
      };
    case constants.ON_DISABLE_JACKPOT_FAILED:
      return {
        ...state,
        disabledJackPot: false,
      };
    case constants.SHOW_NEW_JACKPOT_BET_MODAL:
      return {
        ...state,
        showNewJackpotBetModal: true,
      };
    case constants.HIDE_NEW_JACKPOT_BET_MODAL:
      return {
        ...state,
        showNewJackpotBetModal: false,
      };
    case constants.SHOW_WINNER_MODAL:
      return {
        ...state,
        showWinnerModal: true,
      };
    case constants.HIDE_WINNER_MODAL:
      return {
        ...state,
        showWinnerModal: false,
      };
    case constants.SELECTED_CURRENCY_STACK_MAP:
      return {
        ...state,
        selectedBetCurrencyStakeMap: action.value,
      };
    case constants.SELECTED_JACKPOT_CONSOLATIONS:
      return {
        ...state,
        selectedBetCurrencyStakeMap: action.value,
      };
    case constants.FETCH_JACKPOT_EVENT_SUCCEEDED:
      const marketArray = [];
      state.selectedJackpot.jackpotMarketList.map((value, keys) => {
        const valueMarket = _.find(action.results.responses, { id: value.marketId });
        const valueEvent = _.find(action.results.response, { id: value.eventId });
        value["marketDetails"] = valueMarket;
        value["eventDetails"] = valueEvent;
        marketArray.push(value);
      });

      return {
        ...state,
        marketListFetching: false,
        selectedBetJackPotEvent: action.results,
        selectedBetJackPotMarket: marketArray,
        selectedBetJackPotMarketDetails: null,
      };
    case constants.FETCH_JACKPOT_EVENT_FAILED:
      return {
        ...state,
        selectedBetJackPotEvent: null,
      };
    case constants.FETCH_JACKPOT_MARKET_SUCCEEDED:
      return {
        ...state,
        selectedBetJackPotMarketDetails: action.results,
      };
    case constants.FETCH_JACKPOT_MARKET_FAILED:
      return {
        ...state,
        selectedBetJackPotMarketDetails: null,
      };
    case constants.SELECTED_JACKPOT:
      const selectedJackpot = action.selectedJackpot;
      const currenciesId = Object.keys(selectedJackpot.currencyStakeMap);
      const currenciesTextObject = _.find(action.currencies, { id: parseInt(currenciesId[0]) });
      const currencyStakeMap = selectedJackpot.currencyStakeMap[currenciesId[0]];
      currencyStakeMap["currenciesDetails"] = currenciesTextObject;
      const selectedBetCurrencyStakeMap = currencyStakeMap;
      const selectedBetJackPotConsolations = selectedJackpot.jackpotConsolations || [];

      return {
        ...state,
        marketListFetching: true,
        selectedBetCurrencyStakeMap,
        selectedBetJackPotConsolations,
        selectedJackpot,
      };
    case constants.SET_DATES_FILTER:
      return {
        ...state,
        datesFilter: action.datesFilter,
        parameters: generateParameters({
          datesFilter: action.datesFilter,
          datesParamFormat: state.datesParamFormat,
          defaultParams: state.defaultParams,
          marketStatusFilter: state.marketStatusFilter,
        }),
      };
    case constants.SET_TRANS_FUNC:
      return {
        ...state,
        tFunc: action.trans,
      };
    default:
      return state;
  }
}
