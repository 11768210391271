import actionTypes from "../constants";

export function login(uname, pw) {
  return {
    pw,
    type: actionTypes.LOGIN,
    uname,
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function getUserDetails() {
  return {
    type: actionTypes.SET_USER_DETAILS,
  };
}

export function isLoggedIn(isLoggedIn) {
  return {
    isLoggedIn,
    type: actionTypes.SET_ISLOGGEDIN,
  };
}

export function resetInfo() {
  return {
    type: actionTypes.RESET_INFO,
  };
}

export function changePassword(accountId, formData) {
  return {
    accountId,
    formData,
    type: actionTypes.CHANGE_PASSWORD,
  };
}

export function removeErrorMsg() {
  return {
    type: actionTypes.REMOVE_ERROR,
  };
}

export function selectedLanguage(lang, flag) {
  // localStorage.setItem('lang', lang)
  return {
    flag,
    lang,
    type: actionTypes.SELECTED_LANGUAGE,
  };
}
