import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchItemsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchCampaigns(id) {
  const url = `/bo/campaigns${id ? `/${id}` : ""}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchBrands() {
  const url = `/bo/brands`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createCampaign(campaign) {
  const url = "/bo/campaigns";

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_POST, url, campaign, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateCampaign(id, campaign) {
  const url = `/bo/campaigns/${id}`;
  // delete campaign['campaignMessages']
  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_PUT, url, campaign, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteCampaign(id) {
  const url = `/bo/campaigns/${id}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function copyCampaign(campaign) {
  const url = "/bo/campaigns/copy";

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_POST, url, campaign, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchCampaignPromotionStatistics(id) {
  const url = `/bo/campaigns/campaignpromotion/${id}/statistics`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchCampaignPromotionBudgetLog(id) {
  const url = `/bo/campaigns/campaignpromotion/${id}/budgetlogs`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function searchPromoCode(code) {
  const url = `/bo/promocodes/code/${code}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function generatePromoCode(promoCodeData) {
  const url = `/bo/promocodes/generate?expirydate=${promoCodeData.expirydate}&campaignpromoid=${promoCodeData.campaignpromoid}&count=${promoCodeData.count}&maxuses=${promoCodeData.maxuses}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createPromoCode(promoCodeData) {
  const url = "/bo/promocodes";
  delete promoCodeData["count"];
  delete promoCodeData["expirydate"];

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_POST, url, promoCodeData, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromoCode(promoId) {
  const url = `/bo/promocodes/campaignPromoId/${promoId}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromoCodeUsage(code) {
  const url = `/bo/promocodes/usage/${code}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_GET, url, null, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deletePromoCode(id) {
  const url = `/bo/promocodes/${id}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function batchDeletePromoCode(codes) {
  const url = `/bo/promocodes/bulkdelete`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_PUT, url, codes, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updatePromoCode(id, promoCodeData) {
  const url = `/bo/promocodes/${id}`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_PUT, url, promoCodeData, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function batchUpdatePromoCode(codes) {
  const url = `/bo/promocodes/bulkupdate`;

  return performHttpCall(fetchItemsXhr, httpMethods.HTTP_PUT, url, codes, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
