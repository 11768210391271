const actionTypes = {
  ADD_EVENT_PATH: "ADD_EVENT_PATH",
  ADD_EVENT_PATH_FAILED: "ADD_EVENT_PATH_FAILED",
  ADD_EVENT_PATH_SUCCEEDED: "ADD_EVENT_PATH_SUCCEEDED",
  CLEAR_DELETE_EVENT_PATH_ERRORS: "CLEAR_DELETE_EVENT_PATH_ERRORS",
  CLEAR_EVENT_PATH_DETAILS_ERROR: "CLEAR_EVENT_PATH_DETAILS_ERROR",

  CLEAR_EVENT_PATH_TAGS_ERROR: "CLEAR_EVENT_PATH_TAGS_ERROR",

  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_FAILED: "DELETE_EVENT_FAILED",
  DELETE_EVENT_PATH: "DELETE_EVENT_PATH",
  DELETE_EVENT_PATHS: "DELETE_EVENT_PATHS",

  DELETE_EVENT_PATH_FAILED: "DEL;ETE_EVENT_PATH_FAILED",

  DELETE_EVENT_PATH_SUCCEEDED: "DELETE_EVENT_PATH_SUCCEEDED",

  DELETE_EVENT_SUCCEEDED: "DELETE_EVENT_SUCCEEDED",

  
  EDIT_EVENT_PATH: "EDIT_EVENT_PATH",
  
EDIT_EVENT_PATH_FAILED: "EDIT_EVENT_PATH_FAILED",
  
EDIT_EVENT_PATH_SUCCEEDED: "EDIT_EVENT_PATH_SUCCEEDED",

  
// Event Path Details
FETCH_EVENT_PATH_DETAILS: "FETCH_EVENT_PATH_DETAILS",
  

FETCH_EVENT_PATH_DETAILS_FAILED: "FETCH_EVENT_PATH_DETAILS_FAILED",
  
FETCH_EVENT_PATH_DETAILS_SUCCEEDED: "FETCH_EVENT_PATH_DETAILS_SUCCEEDED",

  // Event Path Tags
FETCH_EVENT_PATH_TAGS: "FETCH_EVENT_PATH_TAGS",
  
FETCH_EVENT_PATH_TAGS_FAILED: "FETCH_EVENT_PATH_TAGS_FAILED",
  
FETCH_EVENT_PATH_TAGS_SUCCEEDED: "FETCH_EVENT_PATH_TAGS_SUCCEEDED",

  
SAVE_REORDER: "SAVE_REORDER",

  
SAVE_REORDER_FAILED: "SAVE_REORDER_FAILED",
  
  SAVE_REORDER_SUCCEEDED: "SAVE_REORDER_SUCCEEDED",
  SELECT_EVENT_CREATOR_PATH: "SELECT_EVENT_CREATOR_PATH",

  SET_EVENT_CREATOR_LOADING_VISIBILITY: "SET_EVENT_CREATOR_LOADING_VISIBILITY",
  SET_EVENT_PATH_ACTIVE_TAB_INDEX: "SET_EVENT_PATH_ACTIVE_TAB_INDEX",
  SET_EVENT_PATH_MODE: "SET_EVENT_PATH_MODE",
};

export const modes = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};
export default actionTypes;
