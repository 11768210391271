import _ from "lodash";
import moment from "moment";

import constants from "./constants";

const initialState = {
  fetchingRiskColumns: false,
  modified: false,
  riskColumns: [],
  riskColumnsBackup: [],
  selectedRow: null,
};

const utilitiesRiskDefaultManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_RISK_COLUMNS:
      return {
        ...state,
        fetchingRiskColumns: true,
        riskColumns: [],
        riskColumnsBackup: [],
      };
    case constants.FETCH_RISK_DEFAULT_COLUMNS_SUCCEEDED:
      return {
        ...state,
        fetchingRiskColumns: false,
        modified: false,
        riskColumns: _.cloneDeep(action.defaultRiskColumn),
        riskColumnsBackup: _.cloneDeep(action.defaultRiskColumn),
        selectedRow: null,
      };
    case constants.FETCH_RISK_DEFAULT_COLUMNS_FAILED:
      return {
        ...state,
        fetchingRiskColumns: false,
        riskColumns: _.cloneDeep([]),
        riskColumnsBackup: _.cloneDeep([]),
      };
    case constants.SELECT_RISK_DATA:
      return {
        ...state,
        selectedRow:
          state.selectedRow == null
            ? action.ordinalPosition
            : state.selectedRow == action.ordinalPosition
            ? null
            : action.ordinalPosition,
      };
    case constants.ON_RISK_MOVE_DOWN:
      let riskColumns = state.riskColumns;
      let riskColumnsBackup = state.riskColumnsBackup;
      const riskToSwap = _.findIndex(riskColumns, (r) => r.ordinalPosition == state.selectedRow);
      const isDown = action.isDown;
      const riskSwapWith = action.isDown ? riskToSwap + 1 : riskToSwap - 1;
      const ordinalPosition_of_swapWith = riskColumns[riskSwapWith]["ordinalPosition"];
      riskColumns[riskToSwap]["ordinalPosition"] = ordinalPosition_of_swapWith;
      riskColumns[riskSwapWith]["ordinalPosition"] = state.selectedRow;
      riskColumns.splice(riskSwapWith, 1, riskColumns.splice(riskToSwap, 1, riskColumns[riskSwapWith])[0]);

      return {
        ...state,
        modified: JSON.stringify(riskColumns) != JSON.stringify(riskColumnsBackup),
        riskColumns: _.cloneDeep(riskColumns),
        selectedRow: ordinalPosition_of_swapWith,
      };
    case constants.UNDO_RISK_DEFAULT_MANAGEMENT:
      return {
        ...state,
        modified: false,
        riskColumns: _.cloneDeep(state.riskColumnsBackup),
        selectedRow: null,
      };
    case constants.UPDATE_COLUMN:
      const { column, row, value } = action;
      riskColumns = state.riskColumns;
      riskColumnsBackup = state.riskColumnsBackup;
      riskColumns[row][column] = value;

      return {
        ...state,
        modified: JSON.stringify(riskColumns) != JSON.stringify(riskColumnsBackup),
        riskColumns: _.cloneDeep(riskColumns),
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesRiskDefaultManagement;
