import actionTypes from "../../constants";
import { accountPermissions } from "../../constants/permissions";

const initialState = {
  accountPermissionList: null,
  errorMessage: null,

  mainOperatorPermissions: null,
  operatorPermissionList: null,

  toastClass: "success",
  toastMessage: null,
};

function createPermissions(permissions) {
  const obj = {};
  const values = Object.values(accountPermissions);
  const keys = Object.keys(accountPermissions);
  permissions.map((val, index) => {
    if (values.indexOf(val.actionId) !== -1) {
      obj[keys[values.indexOf(val.actionId)]] = true;
    }
  });

  return obj;
}

const customerManagerGlobal = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CM_ADD_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case actionTypes.CM_CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
      };
    case actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        accountPermissionList: createPermissions(action.permissions),
        mainOperatorPermissions: action.permissions,
        operatorPermissionList: createPermissions(action.permissions),
      };
    case actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_SUCCEEDED:
      return {
        ...state,
        accountPermissionList: createPermissions(action.permissions),
        mainOperatorPermissions: action.permissions,
        operatorPermissionList: createPermissions(action.permissions),
      };
    case actionTypes.FETCH_ACCOUNT_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        accountPermissionList: createPermissions(action.permissions),
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return {
        ...state,
        accountPermissionList: createPermissions(state.mainOperatorPermissions),
      };
    default:
      return { ...state };
  }
};

export default customerManagerGlobal;
