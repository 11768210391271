import _, { isEmpty } from "lodash";

import mainConst from "../../App/constants";

import constants from "./constants";

const initialState = {
  brandId: "",
  description: "",
  eventPathsFilter: {
    eventPathsFilter: [],
    eventPathsFilterMap: {},
    originalData: {},
    prevSportId: null,
    sportId: null,
  },
  isCreatingPromotion: false,
  isCreatingPromotionFailed: false,
  isDeletingPromotion: false,
  isDeletingPromotionFailed: false,
  isDuplicatingPromotion: false,
  isDuplicatingPromotionFailed: false,
  isFetchingEventhPaths: false,
  isFetchingEventhPathsFailed: false,
  isFetchingMarketTypes: false,
  isFetchingMarketTypesFailed: false,
  isFetchingPromotionCampaign: false,
  isFetchingPromotionCampaignFailed: false,
  isFetchingPromotionData: false,
  isFetchingPromotionDataFailed: false,
  isFetchingPromotions: false,
  isFetchingPromotionsFailed: false,
  isUpdatingPromotion: false,
  isUpdatingPromotionFailed: false,
  marketTypesMap: {},
  modified: false,
  name: "",
  originalPromotionFilterValues: {},
  promoRewardValues: {},
  promotionCampaign: [],
  promotionCampaign: [],
  promotionCriterias: {},
  promotionData: {},
  promotionFilterValues: {},
  promotionGroupValues: {},
  promotionGroupsIds: {},
  promotionalGroups: [],
  promotions: [],
  retired: false,
  selectedPromotionCriteria: {},
  virtualStatic: {
    1: "Virtuals",
    2: "Non Virtuals",
  },
  customBetStatic: {
    1: "Custom Bets",
    2: "Non Custom Bets",
  },
};

const promotions = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROMOTIONS:
      return {
        ...state,
        isFetchingPromotions: true,
        isFetchingPromotionsFailed: false,
      };
    case constants.FETCH_PROMOTIONS_SUCCEEDED:
      return {
        ...state,
        isFetchingPromotions: false,
        isFetchingPromotionsFailed: false,
        promotions: _.sortBy(action.promotions, (promo) => !!promo && promo.name && promo.name.toLowerCase()),
      };
    case constants.FETCH_PROMOTIONS_FAILED:
      return {
        ...state,
        isFetchingPromotions: false,
        isFetchingPromotionsFailed: true,
      };

    case constants.ADD_PROMOTION:
      return {
        ...state,
        isCreatingPromotion: true,
        isCreatingPromotionFailed: false,
      };
    case constants.ADD_PROMOTION_SUCCEEDED:
      if (true) {
        const { description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } = action.response;
        const promoGroups = { ...state.promotionGroupValues };
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = { ...state.promotionFilterValues };
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = { ...state.promoRewardValues };
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });

        state["name"] = name;
        state["description"] = description;
        state["retired"] = retired;

        return {
          ...state,
          isCreatingPromotion: false,
          isCreatingPromotionFailed: false,
          // updatePromotionResponse: action.response,
          modified: false,

          promoRewardValues: promoRewards,

          promotionData: {
            ...action.response,
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },

          promotionFilterValues: promoFilters,

          promotionGroupValues: promoGroups,

          promotionGroupsIds: promoGroups,
        };
      }
    case constants.ADD_PROMOTION_FAILED:
      return {
        ...state,
        isCreatingPromotion: false,
        isCreatingPromotionFailed: true,
      };

    case constants.COPY_PROMOTION:
      return {
        ...state,
        isDuplicatingPromotion: true,
        isDuplicatingPromotionFailed: false,
      };
    case constants.COPY_PROMOTION_SUCCEEDED:
      if (true) {
        const { description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } = action.response;
        const promoGroups = {};
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = {};
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = {};
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });

        return {
          ...state,
          description,
          isDuplicatingPromotion: false,
          isDuplicatingPromotionFailed: false,
          name,
          promoRewardValues: promoRewards,
          promotionCampaign: [],
          promotionData: {
            ...action.response,
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },
          promotionFilterValues: promoFilters,
          promotionGroupValues: promoGroups,
          promotionGroupsIds: promoGroups,
          retired,
          // updatePromotionResponse: action.response,
        };
      }
    case constants.COPY_PROMOTION_FAILED:
      return {
        ...state,
        isDuplicatingPromotion: false,
        isDuplicatingPromotionFailed: true,
      };

    case constants.DELETE_PROMOTION:
      return {
        ...state,
        isDeletingPromotion: true,
        isDeletingPromotionFailed: false,
      };
    case constants.DELETE_PROMOTION_SUCCEEDED:
      if (true) {
        return {
          ...state,
          isDeletingPromotion: false,
          isDeletingPromotionFailed: false,
        };
      }
    case constants.DELETE_PROMOTION_FAILED:
      return {
        ...state,
        isDeletingPromotion: false,
        isDeletingPromotionFailed: true,
      };

    case constants.UPDATE_PROMOTION:
      return {
        ...state,
        isUpdatingPromotion: true,
        isUpdatingPromotionFailed: false,
      };

    case constants.UPDATE_PROMOTION_SUCCEEDED:
      if (true) {
        const { description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } = action.response;
        const promoGroups = {};
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = {};
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = {};
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });

        state["name"] = name;
        state["description"] = description;
        state["retired"] = retired;

        return {
          ...state,
          isUpdatingPromotion: false,
          isUpdatingPromotionFailed: false,
          modified: false,

          promoRewardValues: promoRewards,

          promotionData: {
            ...action.response,
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },

          promotionFilterValues: promoFilters,
          // promotionGroupsIds: promoGroups,
          promotionGroupValues: promoGroups,
          // updatePromotionResponse: action.response,
        };
      }
    case constants.UPDATE_PROMOTION_FAILED:
      return {
        ...state,
        isUpdatingPromotion: false,
        isUpdatingPromotionFailed: true,
      };

    case constants.RESET_PROMOTION:
      if (true) {
        const { description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } =
          state.promotionData;
        const promoGroups = {};
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = {};
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = {};
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });

        state["name"] = name;
        state["description"] = description;
        state["retired"] = retired;

        return {
          ...state,
          modified: false,
          promoRewardValues: promoRewards,
          promotionData: {
            ...state.promotionData,
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },
          promotionFilterValues: promoFilters,
          promotionGroupValues: promoGroups,
          promotionGroupsIds: promoGroups,
        };
      }

    case constants.UPDATE_PROMOTION_FIELD:
      if (true) {
        const { field, value } = action;
        const isModified = !_.isEqual(state.promotionData[field], value);

        return {
          ...state,
          [field]: value,
          modified: isModified,
        };
      }

    case constants.FETCH_PROMOTION_DATA:
      return {
        ...state,
        brandId: "",
        description: "",
        isFetchingPromotionData: true,
        isFetchingPromotionDataFailed: false,
        name: "",
        originalPromotionFilterValues: {},
        promoRewardValues: {},
        promotionData: {},
        promotionFilterValues: {},
        promotionGroupValues: {},
        promotionGroupsIds: {},
      };
    case constants.FETCH_PROMOTION_DATA_SUCCEEDED:
      if (true) {
        const { brandId, description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } =
          action.promotion;
        const promoGroups = {};
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = {};
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = {};
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });
        state["brandId"] = brandId;
        state["name"] = name;
        state["description"] = description;
        state["retired"] = retired;

        return {
          ...state,
          isFetchingPromotionData: false,
          isFetchingPromotionDataFailed: false,
          originalPromotionFilterValues: promoFilters,
          promoRewardValues: promoRewards,
          promotionData: {
            ...action.promotion,
            promoFilterValues: {
              ...promoFilters,
            },
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },
          promotionFilterValues: promoFilters,
          promotionGroupValues: promoGroups,
          promotionGroupsIds: promoGroups,
        };
      }

    case constants.FETCH_PROMOTION_DATA_FAILED:
      return {
        ...state,
        isFetchingPromotionData: false,
        isFetchingPromotionDataFailed: true,
      };

    case constants.FETCH_PROMOTION_CAMPAIGN:
      return {
        ...state,
        isFetchingPromotionCampaign: true,
        isFetchingPromotionCampaignFailed: false,
      };
    case constants.FETCH_PROMOTION_CAMPAIGN_SUCCEEDED:
      if (true) {
        return {
          ...state,
          isFetchingPromotionCampaign: false,
          isFetchingPromotionCampaignFailed: false,
          promotionCampaign: action.response,
        };
      }

    case constants.FETCH_PROMOTION_CAMPAIGN_FAILED:
      return {
        ...state,
        isFetchingPromotionCampaign: false,
        isFetchingPromotionCampaignFailed: true,
      };

    case constants.SET_PROMOTION_DATA:
      if (true) {
        const { description, id, name, promoFilterValues, promoGroupIds, promotionRewards, retired } = action.promotion;

        const promoGroups = { ...state.promotionGroupValues };
        _.each(promoGroupIds, (pgId, pgIndex) => {
          promoGroups[`curr-${pgId}`] = {
            id: pgId,
          };
        });

        const promoFilters = { ...state.promotionFilterValues };
        _.each(promoFilterValues, (pf, pfIndex) => {
          delete pf.promotionId;
          promoFilters[`curr-${pf.filterType}`] = pf;
        });

        const promoRewards = { ...state.promoRewardValues };
        _.each(promotionRewards, (reward) => {
          reward.quotaPerAccountDisabled = reward.quotaPerAccount > 0;
          promoRewards[`curr-${reward.type}-${reward.id}`] = reward;
        });
        state["name"] = name;
        state["description"] = description;
        state["retired"] = retired;

        return {
          ...state,
          promoRewardValues: promoRewards,
          promotionData: {
            ...action.promotion,
            promoFilterValues: {
              ...promoFilters,
            },
            promoFilterValues: {
              ...promoFilters,
            },
            promotionRewards: {
              ...promotionRewards,
            },
          },
          promotionFilterValues: promoFilters,
          promotionGroupValues: promoGroups,
          promotionGroupsIds: promoGroups,
        };
      }

    case constants.CLEAR_PROMOTION_DATA:
      return {
        ...state,
        brandId: "",
        description: "",
        name: "",
        promoRewardValues: {},
        promotionCriterias: {},
        promotionData: {},
        promotionFilterValues: {},
        promotionGroupValues: {},
      };

    case constants.ADD_PROMOTION_GROUP:
      if (true) {
        const promoGroups = { ...state.promotionGroupValues };
        promoGroups[`new-${_.size(promoGroups)}`] = {
          id: null,
        };

        return {
          ...state,
          promotionGroupValues: promoGroups,
        };
      }

    case constants.FETCH_PROMOTIONAL_GROUP_WITH_BRANDID_SUCCEEDED:
      return {
        ...state,
        promotionalGroups: action.response,
      };
    case constants.DELETE_PROMOTION_GROUP:
      if (true) {
        const promoGroupIds = { ...state.promotionData.promoGroupIds };
        const promoGroups = { ...state.promotionGroupValues };

        delete promoGroups[action.index];

        return {
          ...state,
          modified: _.size(promoGroupIds) !== _.size(promoGroups),
          promotionGroupValues: promoGroups,
        };
      }

    case constants.UPDATE_PROMOTION_GROUP:
      if (true) {
        const promoGroupIds = { ...state.promotionData.promoGroupIds };
        const promoGroups = { ...state.promotionGroupValues };
        const { field, index, value } = action;
        const isModified = !_.isEqual(
          _.map(promoGroupIds, (pg) => pg),
          _.map(
            {
              ...promoGroups,
              [index]: {
                ...promoGroups[index],
                [field]: value,
              },
            },
            (pg) => pg.id,
          ),
        );

        return {
          ...state,
          modified: isModified,
          promotionGroupValues: {
            ...state.promotionGroupValues,
            [index]: {
              ...state.promotionGroupValues[index],
              [field]: value,
            },
          },
        };
      }

    case constants.ADD_PROMOTION_CRITERIA:
      if (true) {
        const { promotionData } = state;
        const promoFilters = { ...state.promotionFilterValues };

        const isModified = _.isEmpty(promotionData)
          ? false
          : !_.isEqual(
              _.map(Object.values(promotionData.promoFilterValues), (pf) => ({
                filterType: pf.filterType,
                id: pf.id,
                promoFilterId: pf.promoFilterId,
                value: pf.value,
              })),
              _.map(Object.values(promoFilters), (pf) => ({
                filterType: pf.filterType,
                id: pf.id,
                promoFilterId: pf.promoFilterId,
                value: pf.value,
              })),
            );

        promoFilters[`new-${_.size(promoFilters)}`] = {
          filterType: null,
          id: null,
          promoFilterId: null,
          promotionId: null,
          value: null,
        };

        return {
          ...state,
          modified: isModified,
          promotionFilterValues: promoFilters,
        };
      }

    case constants.DELETE_PROMOTION_CRITERIA:
      if (true) {
        const { promotionData } = state;
        const promoFilters = { ...state.promotionFilterValues };
        delete promoFilters[action.index];

        const isModified = !_.isEqual(
          _.map(promotionData.promoFilterValues, (pf) => ({
            filterType: pf.filterType,
            id: pf.id,
            promoFilterId: pf.promoFilterId,
            value: pf.value,
          })),
          _.map(promoFilters, (pf) => pf),
        );

        return {
          ...state,
          modified: isModified,
          promotionFilterValues: promoFilters,
        };
      }

    case constants.UPDATE_PROMOTION_CRITERIA:
      if (true) {
        const promoFilterValues = { ...state.promotionData.promoFilterValues };
        const promoFilters = { ...state.promotionFilterValues };
        const { field, index, value } = action;
        const isModified = !_.isEqual(
          _.map({ ...promoFilterValues }, (pf) => ({
            filterType: pf.filterType,
            id: pf.id,
            promoFilterId: pf.promoFilterId,
            value: pf.value,
          })),
          _.map(
            {
              ...promoFilters,
              [index]: {
                ...promoFilters[index],
                [field]: value,
              },
            },
            (pf) => pf,
          ),
        );

        return {
          ...state,
          modified: isModified,
          promotionFilterValues: {
            ...state.promotionFilterValues,
            [index]: {
              ...state.promotionFilterValues[index],
              [field]: value,
            },
          },
        };
      }
    case constants.UPDATE_PROMOTION_CRITERIA_FOR_BRAND_CHANGE:
      const index = action.index.length < 1 ? "new-0" : action.index[0];

      return {
        ...state,
        promotionFilterValues: {
          ...state.promotionFilterValues,
          [index]: action.payload,
        },
      };
    case constants.SELECT_PROMOTION_CRITERIA:
      if (true) {
        const promotionFilterValues = { ...state.promotionFilterValues };

        const { filterType, filterTypeId, index } = action;
        const hasFilterType = !!filterType;
        promotionFilterValues[index] = {
          filterType: hasFilterType && filterType,
          id: filterTypeId || null,
          promoFilterId: filterTypeId || null,
          value: null,
        };

        return {
          ...state,
          promotionFilterValues,
        };
      }

    case constants.ADD_PROMOTION_REWARD:
      if (true) {
        const promoRewards = { ...state.promoRewardValues };
        promoRewards[`new-${_.size(promoRewards)}`] = {
          basis: null,
          currencyCode: null,
          eventpath: null,
          expiryOffset: null,
          freeBetSnr: false,
          freeBetWinningsType: "PROMO_MONEY_SNR",
          id: null,
          maxRewardValue: null,
          rewardFilterValues: null,
          promotionId: null,
          quotaPerAccount: null,
          quotaPerAccountDisabled: null,
          rolloverMultiplier: null,
          thirdPartyReward: null,
          type: null,
          value: null,
        };

        return {
          ...state,
          promoRewardValues: promoRewards,
        };
      }

    case constants.DELETE_PROMOTION_REWARD:
      if (true) {
        const promotionRewards = { ...state.promotionData.promotionRewards };
        const promoRewards = { ...state.promoRewardValues };

        delete promoRewards[action.index];

        return {
          ...state,
          modified: _.size(promotionRewards) !== _.size(promoRewards),
          promoRewardValues: promoRewards,
        };
      }

    case constants.UPDATE_PROMOTION_REWARD:
      if (true) {
        const promotionRewards = { ...state.promotionData.promotionRewards };
        const promoRewards = { ...state.promoRewardValues };
        const { field, index, value } = action;
        const isModified = !_.isEqual(
          _.map({ ...promotionRewards }, (pr) => ({
            basis: pr.basis,
            currencyCode: pr.currencyCode,
            eventpath: pr.eventpath,
            expiryOffset: pr.expiryOffset,
            freeBetSnr: pr.freeBetSnr,
            freeBetWinningsType: pr.freeBetWinningsType,
            id: pr.id,
            maxRewardValue: pr.maxRewardValue,
            minOdds: pr.minOdds,
            promotionId: pr.promotionId,
            quotaPerAccount: pr.quotaPerAccount,
            quotaPerAccountDisabled: pr.quotaPerAccountDisabled,
            rewardFilterValues: pr.rewardFilterValues,
            rolloverMultiplier: pr.rolloverMultiplier,
            thirdPartyReward: pr.thirdPartyReward,
            type: pr.type,
            value: pr.value,
          })),
          _.map(
            {
              ...promoRewards,
              [index]: {
                ...promoRewards[index],
                [field]: value,
              },
            },
            (pr) => ({
              basis: pr.basis,
              currencyCode: pr.currencyCode,
              eventpath: pr.eventpath,
              expiryOffset: pr.expiryOffset,
              freeBetSnr: pr.freeBetSnr,
              freeBetWinningsType: pr.freeBetWinningsType,
              id: pr.id,
              maxRewardValue: pr.maxRewardValue,
              minOdds: pr.minOdds,
              promotionId: pr.promotionId,
              quotaPerAccount: pr.quotaPerAccount,
              quotaPerAccountDisabled: pr.quotaPerAccountDisabled,
              rewardFilterValues: pr.rewardFilterValues,
              rolloverMultiplier: pr.rolloverMultiplier,
              thirdPartyReward: pr.thirdPartyReward,
              type: pr.type,
              value: pr.value,
            }),
          ),
        );

        return {
          ...state,
          modified: isModified,
          promoRewardValues: {
            ...state.promoRewardValues,
            [index]: {
              ...state.promoRewardValues[index],
              [field]: value,
              ...(field === "quotaPerAccountDisabled" && value && { quotaPerAccount: "" }),
            },
          },
        };
      }

    case constants.UPDATE_PROMOTION_REWARD_FILTER_VALUE:
      if (true) {
        const promotionRewards = { ...state.promotionData.promotionRewards };
        const promoRewards = { ...state.promoRewardValues };
        const { field, filterIndex, index, value } = action;

        const updatedPromoRewards = { ...promoRewards };
        const updatedPromoReward = { ...updatedPromoRewards[index] };
        const updatedFilters = [...updatedPromoReward.rewardFilterValues];
        const updatedFilter = { ...updatedFilters[filterIndex] };
        updatedFilter[field] = value;
        updatedFilters[filterIndex] = updatedFilter;
        updatedPromoReward.rewardFilterValues = updatedFilters;
        updatedPromoRewards[index] = updatedPromoReward;

        const isModified = !_.isEqual(
          _.map({ ...promotionRewards }, (pr) => ({
            basis: pr.basis,
            currencyCode: pr.currencyCode,
            eventpath: pr.eventpath,
            expiryOffset: pr.expiryOffset,
            id: pr.id,
            maxRewardValue: pr.maxRewardValue,
            minOdds: pr.minOdds,
            promotionId: pr.promotionId,
            quotaPerAccount: pr.quotaPerAccount,
            quotaPerAccountDisabled: pr.quotaPerAccountDisabled,
            rewardFilterValues: pr.rewardFilterValues,
            rolloverMultiplier: pr.rolloverMultiplier,
            thirdPartyReward: pr.thirdPartyReward,
            type: pr.type,
            value: pr.value,
          })),
          _.map(
            {
              ...updatedPromoRewards,
            },
            (pr) => ({
              basis: pr.basis,
              currencyCode: pr.currencyCode,
              eventpath: pr.eventpath,
              expiryOffset: pr.expiryOffset,
              id: pr.id,
              maxRewardValue: pr.maxRewardValue,
              minOdds: pr.minOdds,
              promotionId: pr.promotionId,
              quotaPerAccount: pr.quotaPerAccount,
              quotaPerAccountDisabled: pr.quotaPerAccountDisabled,
              rewardFilterValues: pr.rewardFilterValues,
              rolloverMultiplier: pr.rolloverMultiplier,
              thirdPartyReward: pr.thirdPartyReward,
              type: pr.type,
              value: pr.value,
            }),
          ),
        );

        return {
          ...state,
          modified: isModified,
          promoRewardValues: updatedPromoRewards,
        };
      }

    case constants.FETCH_MARKET_TYPES:
      return {
        ...state,
        isFetchingMarketTypes: true,
        isFetchingMarketTypesFailed: false,
      };
    case constants.FETCH_MARKET_TYPES_SUCCEEDED:
      return {
        ...state,
        isFetchingMarketTypes: false,
        isFetchingMarketTypesFailed: false,
        promotions: _.groupBy(action.response, "sportCode"),
      };
    case constants.FETCH_MARKET_TYPES_FAILED:
      return {
        ...state,
        isFetchingMarketTypes: false,
        isFetchingMarketTypesFailed: true,
      };

    case constants.FETCH_SPORT_EVENT_PATHS:
      if (true) {
        const eventPathsFilter = { ...state.eventPathsFilter };
        if (_.has(eventPathsFilter.originalData[action.sportId], action.itemId)) {
          // if (action.itemId !== action.sportId) {
          eventPathsFilter.originalData[action.sportId][action.itemId].parentId = action.itemId;
          eventPathsFilter.originalData[action.sportId][action.itemId].isLoading = action.isOpen;
          eventPathsFilter.originalData[action.sportId][action.itemId].isOpen = action.isOpen;
          // }
          // else {
          //   eventPathsFilter[action.sportId][action.itemId].isLoading = true
          //   eventPathsFilter[action.sportId][action.itemId].isOpen = true
          // }
        } else {
          eventPathsFilter.originalData[action.sportId] = {
            // isLoading: true,
          };
        }

        return {
          ...state,
          eventPathsFilter: {
            ...eventPathsFilter,
            eventPathsFilterMap: {
              ...state.eventPathsFilter.eventPathsFilterMap,
            },
            prevSportId: state.eventPathsFilter.sportId,
            sportId: action.sportId,
          },
          isFetchingEventhPaths: true,
          isFetchingEventhPathsFailed: false,
        };
      }
    case constants.FETCH_SPORT_EVENT_PATHS_SUCCEEDED:
      const { eventPathsFilter, originalData } = { ...state.eventPathsFilter };
      const { itemId } = action;
      if (_.has(originalData[state.eventPathsFilter.sportId], action.itemId)) {
        originalData[action.sportId][action.itemId].isLoading = false;
        originalData[action.sportId][action.itemId].isOpen = action.isOpen;
        originalData[action.sportId][action.itemId].children = action.response.map((ep) => ep.id);
        _.each(action.response, (ep) => {
          if (_.has(originalData[action.sportId], ep.id)) {
            const data = originalData[action.sportId][ep.id];
            // originalData[action.sportId][ep.id] = {}
            ep.parentId = action.sportId;
            ep.isLoading = data.isLoading;
            ep.isOpen = data.isOpen;
            ep.isChecked = data.isChecked;
            originalData[action.sportId][ep.id] = ep;
          } else {
            _.each(action.response, (ep) => {
              originalData[action.sportId][ep.id] = {};
              ep.parentId = action.sportId;
              ep.isLoading = false;
              ep.isOpen = false;
              ep.isChecked = false;
              originalData[action.sportId][ep.id] = ep;
            });
          }
        });
      } else {
        _.each(action.response, (ep) => {
          originalData[action.sportId][ep.id] = {};
          ep.parentId = action.sportId;
          ep.isLoading = false;
          ep.isOpen = false;
          ep.isChecked = false;
          originalData[action.sportId][ep.id] = ep;
        });
      }

      // if (_.keys(originalData[action.sportId]).length) {
      //   toBePushAsToBeAssigned = _.filter(originalData[action.sportId], { isChecked: true }).map(ep => {
      //     const type = ep.type.toLowerCase()
      //     const id =
      //       type === 'eventpath'
      //         ? `ep${ep.id}`
      //         : type === 'gameevent'
      //           ? `ge${ep.id}`
      //           : type === 'rankevent' ? `re${ep.id}` : type === 'market' ? `m${ep.id}` : `o${ep.id}`
      //     return id
      //   })
      // }

      // return {
      //   ...state,
      //   isFetchingEventPathFilters: false,
      //   isFetchingEventPathFiltersFailed: false,
      //   originalData: originalEventPathsFilter,
      //   toBeSelectedEventPathFilters: toBePushAsToBeAssigned,
      //   eventPathsFilter:
      //     state.prevSportId !== action.sportId
      //       ? action.response.map(ep => {
      //           return ep.id
      //         })
      //       : eventPathsFilter,
      // }
      return {
        ...state,
        // eventPaths: action.response,
        eventPathsFilter: {
          ...state.eventPathsFilter,
          eventPathsFilter:
            state.eventPathsFilter.prevSportId !== action.sportId
              ? action.response.map((ep) => ep.id)
              : eventPathsFilter,
          /* {
            ...state.eventPathsFilter.originalData,
            [state.eventPathsFilter.sportId]: _.keyBy(action.response, 'id'),
          } */
          eventPathsFilterMap: {
            ...state.eventPathsFilter.eventPathsFilterMap,
            [action.sportId]: _.keyBy(action.response, "id"),
          },

          originalData,
        },

        isFetchingEventhPaths: false,

        isFetchingEventhPathsFailed: false,
      };
    case constants.FETCH_SPORT_EVENT_PATHS_FAILED:
      return {
        ...state,
        isFetchingEventhPaths: false,
        isFetchingEventhPathsFailed: true,
      };

    case constants.TOGGLE_EVENT_PATH:
      // let originalEPF = { ...state.originalEventPathsFilter }
      // originalEPF[action.sportId][action.itemId].isOpen = action.isOpen

      return {
        ...state,
        eventPathsFilter: {
          ...state.eventPathsFilter,
          originalData: {
            ...state.eventPathsFilter.originalData,
            [state.eventPathsFilter.sportId]: {
              ...state.eventPathsFilter.originalData[state.eventPathsFilter.sportId],
              [action.itemId]: {
                ...state.eventPathsFilter.originalData[state.eventPathsFilter.sportId][action.itemId],
                isOpen: action.isOpen,
              },
            },
          },
        },
      };
    case mainConst.SET_BM_MODULE_EDIT_STATE: {
      return {
        ...state,
        modified:
          typeof action.state === "object" && !action.state.state && action.state.form == "promotions"
            ? action.state.state
            : typeof action.state !== "object" && !action.state
            ? action.state
            : state.modified,
        name:
          typeof action.state === "object" && action.state.state && action.state.form == "promotions"
            ? "New Promotion"
            : state.name,
      };
    }

    default:
      return { ...state };
  }
};

export default promotions;
