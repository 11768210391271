import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchSportOtherOptionsXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchSportOtherOptions(code) {
  const url = `/bo/sports/${code.toUpperCase()}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function cancleBets(details) {
  const url = `/bo/events/${details.event.id}/bets/cancel?liveEvent=${details.live}`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_PUT, url, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function cancleBetsOnMarket(market) {
  const url = `/bo/markets/${market.id}/bets/cancel`;

  return performHttpCall(fetchSportOtherOptionsXhr, httpMethods.HTTP_PUT, url, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
