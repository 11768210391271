import _ from "lodash";

import constants from "./constants";

const initialState = {
  actual_eventtotals: [],

  eventtotals: [],
  isFetchingGameResultsPeriodPoints: false,

  isFetchingGameResultsPeriodPointsFailed: false,
  isUpdatingGameResultsPeriodPoints: false,

  isUpdatingGameResultsPeriodPointsFailed: false,
  isUpdatingGameResultsVoidPeriod: false,

  isUpdatingGameResultsVoidPeriodFailed: false,
  periodPoints: [],

  toBeUpdateEventTotals: [],
};

const eventCreatorApp = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_GAME_RESULTS_PERIOD_POINTS:
      return {
        ...state,
        isFetchingGameResultsPeriodPoints: true,
      };
    case constants.FETCH_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED:
      return {
        ...state,
        isFetchingGameResultsPeriodPoints: false,
        periodPoints: action.response,
      };
    case constants.FETCH_GAME_RESULTS_PERIOD_POINTS_FAILED:
      return {
        ...state,
        isFetchingGameResultsPeriodPoints: false,
        isFetchingGameResultsPeriodPointsFailed: true,
      };

    case constants.UPDATE_GAME_RESULTS_PERIOD_POINTS:
      return {
        ...state,
        isUpdatingGameResultsPeriodPoints: true,
      };
    case constants.UPDATE_GAME_RESULTS_PERIOD_POINTS_SUCCEEDED:
      return {
        ...state,
        isUpdatingGameResultsPeriodPoints: false,
      };
    case constants.UPDATE_GAME_RESULTS_PERIOD_POINTS_FAILED:
      return {
        ...state,
        isUpdatingGameResultsPeriodPoints: false,
        isUpdatingGameResultsPeriodPointsFailed: true,
      };

    case constants.UPDATE_GAME_RESULTS_VOID_PERIOD:
      return {
        ...state,
        isUpdatingGameResultsVoidPeriod: true,
      };
    case constants.UPDATE_GAME_RESULTS_VOID_PERIOD_SUCCEEDED:
      return {
        ...state,
        isUpdatingGameResultsVoidPeriod: false,
      };
    case constants.UPDATE_GAME_RESULTS_VOID_PERIOD_FAILED:
      return {
        ...state,
        isUpdatingGameResultsVoidPeriod: false,
        isUpdatingGameResultsVoidPeriodFailed: true,
      };
    case constants.FETCH_EVENT_TOTALS_SUCCEEDED:
      return {
        ...state,
        actual_eventtotals: action.response,
        eventtotals: action.response,
        toBeUpdateEventTotals: [],
      };
    case constants.FETCH_EVENT_TOTALS_FAILED:
      return {
        ...state,
        actual_eventtotals: [],
        eventtotals: [],
        toBeUpdateEventTotals: [],
      };
    case constants.UPDATE_EVENT_TOTALS_STATE:
      const actual_eventtotals = state.actual_eventtotals;
      const eventTotalToUpdate = action.eventTotal;
      const newValue = action.newValue;
      const neweventtotals = _.map(_.cloneDeep(state.eventtotals), (et) => {
        if (
          et.id == eventTotalToUpdate.id &&
          et.eventTotalType.description == eventTotalToUpdate.eventTotalType.description &&
          et.opponentId == eventTotalToUpdate.opponentId
        ) {
          et.total = newValue * 1;
        }

        return et;
      });

      const toBeUpdateEventTotals = [];
      if (actual_eventtotals.length > 0 && neweventtotals.length > 0) {
        _.map(actual_eventtotals, (ae, k) => {
          if (JSON.stringify(ae) != JSON.stringify(neweventtotals[k])) {
            const e = {
              eventId: neweventtotals[k].eventId,
              eventTotalTypeId: neweventtotals[k].eventTotalType.id,
              id: neweventtotals[k].id,
              periodId: neweventtotals[k].period.id,
              total: neweventtotals[k].total,
            };
            if (neweventtotals[k].opponentId && neweventtotals[k].opponentId > 0) {
              e.opponentId = neweventtotals[k].opponentId;
            }
            toBeUpdateEventTotals.push(e);
          }
        });
      }

      return {
        ...state,
        eventtotals: neweventtotals,
        toBeUpdateEventTotals,
      };
    case constants.UPDATE_EVENT_TOTALS_SUCCEEDED:
      return {
        ...state,
        toBeUpdateEventTotals: [],
      };
    default:
      return { ...state };
  }
};

export default eventCreatorApp;
