import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../constants";
import * as UserRiskPreferencesService from "../services/userRiskPreferences";
import { objectToArray, formatFilterDates } from "../utils";

import { fetchRiskData } from "actions/riskData";

function* editUserRiskColumnsAndSports(action) {
  const userId = yield select((state) => state.auth.details.id);
  const { response, xhr } = yield call(
    UserRiskPreferencesService.editUserRiskColumnsAndSports,
    userId,
    action.columnIds,
    action.sportCodes,
  );
  if (response) {
    yield put({
      columns: action.columnIds,
      sports: action.sportCodes,
      type: actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS_SUCCEEDED,
    });
    yield put(fetchRiskData());
  } else {
    yield put({ type: actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS_FAILED });
  }
}

export default function* userRiskPreferencesSaga() {
  yield takeLatest(actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS, editUserRiskColumnsAndSports);
}
