import _ from "lodash";
import moment from "moment";

import constants from "./constants";

const initialState = {
  baseCurrency: null,
  currentDate: moment().format("MM/DD/YYYY"),
  exchangeRate: JSON.stringify([]),
  exchangeRateBackUp: JSON.stringify([]),
  fetchingExchangeRate: false,
  modified: [],
};

const utilitiesExchangeRate = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_DEFAULT_CURRENCY_SUCCEEDED:
      return {
        ...state,
        baseCurrency: action.response,
      };
    case constants.FETCH_DEFAULT_CURRENCY_FAILED:
      return {
        ...state,
        baseCurrency: null,
      };
    case constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY:
      return {
        ...state,
        fetchingExchangeRate: true,
      };
    case constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_SUCCEEDED:
      return {
        ...state,
        exchangeRate: JSON.stringify(action.response),
        exchangeRateBackUp: JSON.stringify(action.response),
        fetchingExchangeRate: false,
        modified: [],
      };
    case constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_FAILED:
      return {
        ...state,
        exchangeRate: JSON.stringify([]),
        exchangeRateBackUp: JSON.stringify([]),
        fetchingExchangeRate: false,
        modified: [],
      };
    case constants.UNDO_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: state.exchangeRateBackUp,
        modified: [],
      };
    case constants.SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.date,
      };
    case constants.UPDATE_ROW:
      const row = action.row;
      const value = action.value;
      const previousRate = JSON.parse(state.exchangeRate);
      const previousRateBackUp = JSON.parse(state.exchangeRateBackUp);
      const modified = state.modified;
      previousRate[row.index]["exchangeRate"] = value;
      if (previousRate[row.index]["exchangeRate"] != previousRateBackUp[row.index]["exchangeRate"]) {
        if (!_.includes(modified, previousRate[row.index]["id"])) {
          modified.push(previousRate[row.index]["id"]);
        }
      } else if (_.includes(modified, previousRate[row.index]["id"])) {
        const index = _.findIndex(modified, (o) => o == previousRate[row.index]["id"]);
        modified.splice(index, 1);
      }

      return {
        ...state,
        exchangeRate: JSON.stringify(previousRate),
        modified,
      };
    default:
      return { ...state };
  }

  return null;
};

export default utilitiesExchangeRate;
