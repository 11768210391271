import actionTypes from "../constants";

export function viewMarketStateDetails(id) {
  return {
    id,
    type: actionTypes.VIEW_MARKET_STATE_DETAILS,
  };
}

export function fetchEventDetails() {
  return {
    type: actionTypes.FETCH_EVENT_DETAILS,
  };
}

export function changeMarketStatus(marketIds, status) {
  return {
    marketIds,
    status,
    type: actionTypes.CHANGE_MARKETS_STATUS,
  };
}

export function abandonMarkets(marketIds, reasonId, reasonNotes) {
  return {
    marketIds,
    reasonId,
    reasonNotes,
    type: actionTypes.ABANDON_MARKETS,
  };
}

export function changeMarketCutOffTime(marketIds, dateString) {
  return {
    dateString,
    marketIds,
    type: actionTypes.CHANGE_MARKET_CUTOFF_TIME,
  };
}

export function changeMarketAutoOpenTime(marketIds, dateString) {
  return {
    dateString,
    marketIds,
    type: actionTypes.CHANGE_MARKET_AUTO_OPEN_TIME,
  };
}

export function fetchMarketPeriodDetails(eventIds) {
  return {
    eventIds,
    type: actionTypes.FETCH_MARKET_PERIOD_DETAILS,
  };
}

export function updateMarketCutOffAndAutoOpenDateTime(formData) {
  return {
    formData,
    type: actionTypes.UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME,
  };
}
