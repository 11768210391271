import _ from "lodash";

import actionTypes from "../constants";

const initialState = {
  activeAppId: 0,
  allowedAppIds: [],
  appList: [],
  appRecentList: [],
  callUseAppFailed: null,
  errMsg: "Something went wrong. Please try again",
  fetchAppsFailed: null,
  fetchRecentAppsFailed: null,
  isAppListLoaded: false,
  isCallingUseApp: null,
  isFetchingAppPermissions: false,
  isFetchingAppPermissionsFailed: false,
  isFetchingApps: null,
  isFetchingRecentApps: null,
  isSideBarOpen: true,
  lines: {},
  permissionsMap: {},
  useAppResponse: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_APPS:
      return { ...state, fetchAppsFailed: false, isFetchingApps: true };
    case actionTypes.FETCH_APPS_SUCCEEDED:
      return {
        ...state,
        allowedAppIds: action.allowedAppIds,
        appList: action.appList,
        isAppListLoaded: true,
        isFetchingApps: false,
      };
    case actionTypes.FETCH_APPS_FAILED:
      return { ...state, errMsg: action.errMsg, fetchAppsFailed: true, isFetchingApps: false };
    case actionTypes.FETCH_APP_PERMISSIONS:
      return { ...state, isFetchingAppPermissions: true, isFetchingAppPermissionsFailed: false };
    case actionTypes.FETCH_APP_PERMISSIONS_SUCCEEDED:
      if (true) {
        // create a new block scope
        const permissionsMap = action.response.reduce((accu, val) => {
          accu[val.actionId] = { ...val };

          return accu;
        }, {}); //

        return {
          ...state,
          isFetchingAppPermissions: false,
          permissions: action.response.map((permission) => permission.actionId),
          permissionsMap,
        };
      }

    case actionTypes.FETCH_APP_PERMISSIONS_FAILED:
      return { ...state, isFetchingAppPermissions: false, isFetchingAppPermissions: true };
    case actionTypes.FETCH_RECENT_APPS:
      return { ...state, fetchRecentAppsFailed: false, isFetchingApps: true };
    case actionTypes.FETCH_RECENT_APPS_SUCCEEDED:
      _.remove(action.appList, (n) => n.applicationId == 67);

      return { ...state, appRecentList: action.appList, isFetchingRecentApps: false };
    case actionTypes.FETCH_RECENT_APPS_FAILED:
      return { ...state, errMsg: action.errMsg, fetchRecentAppsFailed: true, isFetchingApps: false };
    case actionTypes.USE_APP:
      return { ...state, activeAppId: action.appId, callUseAppFailed: false, isCallingUseApp: true };
    case actionTypes.USE_APP_SUCCEEDED:
      return { ...state, isCallingUseApp: false, useAppResponse: action.response };
    case actionTypes.USE_APP_FAILED:
      return { ...state, callUseAppFailed: true, errMsg: action.errMsg, isCallingUseApp: false };
    case actionTypes.SIDEBAR_TOGGLE:
      return { ...state, isSideBarOpen: action.bool };
    case actionTypes.LINES_SUCCEEDED:
      return { ...state, lines: action.lines };
    default:
      return { ...state };
  }
}
