import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import removeOriginId from "./helpers";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchLines(action) {
  const { response, xhr } = yield call(API.fetchLines);
  if (response) {
    const lines = removeOriginId(response);
    yield put({ response: lines, type: constants.FETCH_LINES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_LINES_FAILED });
  }
}

function* saveLineManagement(action) {
  const { response, xhr } = yield call(API.saveLineManagement, action.lineData);
  if (response) {
    toastr.add({ message: i18n.t("Utilities.Line Updated Successfully"), type: "SUCCESS" });
    const lines = removeOriginId(response);
    yield put({ response: lines, type: constants.SAVE_LINE_MANAGEMENT_SUCCEEDED });
    yield put({ type: constants.FETCH_LINES });
  } else {
    toastr.add({ message: `Error!`, type: "ERROR" });
  }
}

export default function* utilitiesLineManagement() {
  yield takeLatest(constants.FETCH_LINES, fetchLines);
  yield takeLatest(constants.SAVE_LINE_MANAGEMENT, saveLineManagement);
}
