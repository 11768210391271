import opponentsConst from "../constants/opponentsConst";

const initialState = {
  actionFailed: false,

  delAllOppOfEPErrorMessage: null,

  delAllOppOfEPFailed: false,
  // general opponent action info
  errorMessage: null,

  isAddOpponentPerforming: false,
  isAssignMultipleOpponentsToEventPathPerforming: false,

  eventPathOpponentDetailsList: [],

  isAssignSignleOpponentToEventPathPerforming: false,

  eventPathAncestralOpponentsList: [],

  isAssignSingleOpponentWithGradeToEventPathPerforming: false,

  isChangeOpponentGradePerforming: false,

  isCreateAndAssignOpponentPerforming: false,

  isCreateAndAssignOpponentToEventPathPerforming: false,

  isCreateAndAssignOpponentWithGradePerforming: false,

  isDeleteAllOppOfEventPathAndUnderPerforming: false,

  isDeleteAllOpponentOfEventPathPerforming: false,

  isEditOpponentPerforming: false,

  isEditOpponentWithGradePerforming: false,

  isFetchEventPathAncestralOpponentsErrMsg: null,

  isFetchEventPathAncestralOpponentsFailed: false,

  isFetchEventPathAncestralOpponentsPerforming: false,

  isFetchEventPathOpponentsDetailsPerforming: false,

  isFetchKitPatternsPerforming: false,

  isFetchMultipleOpponentDetailsPerfoming: false,

  isFetchOpponentKitsPerforming: false,
  
  // jem bug fix
isFetchOpponentTypesPerforming: false,
  

isFetchSingleOpponentDetailsPerforming: false,
  
isFetchSingleOpponentWithGradeDetailsPerforming: false,
  isPerformingAction: false,

  isUnAssignOpponentFromEventPathPerforming: false,

  kitPattenList: [],

  lastActionMade: null,

  newOppenentDetails: null,

  opponentDetailsMultiple: null,

  opponentDetailsSingle: null,

  opponentDetailsSingleWithGrade: null,

  opponentIdsRemoved: [],

  opponentKitList: [],
  // action specific results
  opponentTypes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case opponentsConst.FETCH_OPPONENT_TYPES:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchOpponentTypesPerforming: true,
        lastActionMade: action.type,
        opponentIdsRemoved: [],
      };
    case opponentsConst.FETCH_OPPONENT_TYPES_SUCCEEDED:
      return { ...state, isFetchOpponentTypesPerforming: false, opponentTypes: action.opponentTypes };
    case opponentsConst.FETCH_OPPONENT_TYPES_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isFetchOpponentTypesPerforming: false };

    case opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchSingleOpponentDetailsPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS_SUCCEEDED:
      return { ...state, isFetchSingleOpponentDetailsPerforming: false, opponentDetailsSingle: action.opponentDetails };
    case opponentsConst.FETCH_SINGLE_OPPONENT_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchSingleOpponentDetailsPerforming: false,
      };

    case opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchMultipleOpponentDetailsPerfoming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchMultipleOpponentDetailsPerfoming: false,
        opponentDetailsMultiple: action.opponentDetailsMultiple,
      };
    case opponentsConst.FETCH_MULTIPLE_OPPONENTS_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchMultipleOpponentDetailsPerfoming: false,
      };

    case opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchSingleOpponentWithGradeDetailsPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchSingleOpponentWithGradeDetailsPerforming: false,
        opponentDetailsSingleWithGrade: action.opponentDetailsSingleWithGrade,
      };
    case opponentsConst.FETCH_SINGLE_OPPONENT_WITH_GRADE_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchSingleOpponentWithGradeDetailsPerforming: false,
      };

    case opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        eventPathOpponentDetailsList: [],
        isFetchEventPathOpponentsDetailsPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS_SUCCEEDED:
      return {
        ...state,
        eventPathOpponentDetailsList: action.eventPathOpponentDetailsList,
        isFetchEventPathOpponentsDetailsPerforming: false,
      };
    case opponentsConst.FETCH_EVENT_PATH_OPPONENTS_DETAILS_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isFetchEventPathOpponentsDetailsPerforming: false,
      };

    case opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS:
      return {
        ...state,
        isFetchEventPathAncestralOpponentsErrMsg: null,
        isFetchEventPathAncestralOpponentsFailed: false,
        isFetchEventPathAncestralOpponentsPerforming: true,
      };
    case opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_SUCCEEDED:
      return {
        ...state,
        eventPathAncestralOpponentsList: action.eventPathAncestralOpponentsList,
        isFetchEventPathAncestralOpponentsPerforming: false,
      };
    case opponentsConst.FETCH_EVENT_PATH_ANCESTRAL_OPPONENTS_FAILED:
      return {
        ...state,
        isFetchEventPathAncestralOpponentsErrMsg: action.errorMessage,
        isFetchEventPathAncestralOpponentsFailed: true,
        isFetchEventPathAncestralOpponentsPerforming: false,
      };

    case opponentsConst.FETCH_OPPONENT_KITS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchOpponentKitsPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_OPPONENT_KITS_SUCCEEDED:
      return { ...state, isFetchOpponentKitsPerforming: false, opponentKitList: action.opponentKitList };
    case opponentsConst.FETCH_OPPONENT_KITS_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isFetchOpponentKitsPerforming: false };

    case opponentsConst.CLEAR_OPPONENT_KITS:
      return { ...state, opponentKitList: [] };

    case opponentsConst.FETCH_KIT_PATTERNS:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isFetchKitPatternsPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.FETCH_KIT_PATTERNS_SUCCEEDED:
      return { ...state, isFetchKitPatternsPerforming: false, kitPattenList: action.kitPattenList };
    case opponentsConst.FETCH_KIT_PATTERNS_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isFetchKitPatternsPerforming: false };

    case opponentsConst.ADD_OPPONENT:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isAddOpponentPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.ADD_OPPONENT_SUCCEEDED:
      return { ...state, isAddOpponentPerforming: false, newOppenentDetails: action.newOppenentDetails };
    case opponentsConst.ADD_OPPONENT_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isAddOpponentPerforming: false };

    case opponentsConst.EDIT_OPPONENT:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isEditOpponentPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.EDIT_OPPONENT_SUCCEEDED:
      return { ...state, isEditOpponentPerforming: false, newOppenentDetails: action.newOppenentDetails };
    case opponentsConst.EDIT_OPPONENT_FAILED:
      return { ...state, actionFailed: true, errorMessage: action.errorMessage, isEditOpponentPerforming: false };

    case opponentsConst.EDIT_OPPONENT_WITH_GRADE:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isEditOpponentWithGradePerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.EDIT_OPPONENT_WITH_GRADE_SUCCEEDED:
      return { ...state, isEditOpponentWithGradePerforming: false };
    case opponentsConst.EDIT_OPPONENT_WITH_GRADE_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isEditOpponentWithGradePerforming: false,
      };

    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH:
      return {
        ...state,
        delAllOppOfEPErrorMessage: null,
        delAllOppOfEPFailed: false,
        isDeleteAllOpponentOfEventPathPerforming: true,
      };
    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_SUCCEEDED:
      return { ...state, isDeleteAllOpponentOfEventPathPerforming: false };
    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_FAILED:
      return {
        ...state,
        delAllOppOfEPErrorMessage: action.errorMessage,
        delAllOppOfEPFailed: true,
        isDeleteAllOpponentOfEventPathPerforming: false,
      };

    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isDeleteAllOppOfEventPathAndUnderPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_SUCCEEDED:
      return { ...state, isDeleteAllOppOfEventPathAndUnderPerforming: false };
    case opponentsConst.DELETE_ALL_OPPONENT_OF_EVENT_PATH_AND_UNDER_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isDeleteAllOppOfEventPathAndUnderPerforming: false,
      };

    case opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isAssignSignleOpponentToEventPathPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_SUCCEEDED:
      return { ...state, isAssignSignleOpponentToEventPathPerforming: false };
    case opponentsConst.ASSIGN_SINGLE_OPPONENT_TO_EVENT_PATH_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isAssignSignleOpponentToEventPathPerforming: false,
      };

    case opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isAssignSingleOpponentWithGradeToEventPathPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_SUCCEEDED:
      return { ...state, isAssignSingleOpponentWithGradeToEventPathPerforming: false };
    case opponentsConst.ASSIGN_SINGLE_OPPONENT_WITH_GRADE_TO_EVENT_PATH_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isAssignSingleOpponentWithGradeToEventPathPerforming: false,
      };

    case opponentsConst.CHANGE_OPPONENT_GRADE:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isChangeOpponentGradePerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.CHANGE_OPPONENT_GRADE_SUCCEEDED:
      return { ...state, isChangeOpponentGradePerforming: false };
    case opponentsConst.CHANGE_OPPONENT_GRADE_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isChangeOpponentGradePerforming: false,
      };

    case opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isAssignMultipleOpponentsToEventPathPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_SUCCEEDED:
      return { ...state, isAssignMultipleOpponentsToEventPathPerforming: false };
    case opponentsConst.ASSIGN_MULTIPLE_OPPONENTS_TO_EVENT_PATH_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isAssignMultipleOpponentsToEventPathPerforming: false,
      };

    case opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isUnAssignOpponentFromEventPathPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH_SUCCEEDED:
      return {
        ...state,
        isUnAssignOpponentFromEventPathPerforming: false,
        opponentIdsRemoved: [...state.opponentIdsRemoved, action.opponentIdUnassigned],
      };
    case opponentsConst.UNASSIGN_OPPONENT_FROM_EVENT_PATH_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isUnAssignOpponentFromEventPathPerforming: false,
      };

    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isCreateAndAssignOpponentPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_SUCCEEDED:
      return { ...state, isCreateAndAssignOpponentPerforming: false };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isCreateAndAssignOpponentPerforming: false,
      };

    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isCreateAndAssignOpponentWithGradePerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_SUCCEEDED:
      return { ...state, isCreateAndAssignOpponentWithGradePerforming: false };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_WITH_GRADE_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isCreateAndAssignOpponentWithGradePerforming: false,
        isEditOpponentPerforming: false,
        isEditOpponentWithGradePerforming: false,
      };

    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH:
      return {
        ...state,
        actionFailed: false,
        errorMessage: null,
        isCreateAndAssignOpponentToEventPathPerforming: true,
        lastActionMade: action.type,
      };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_SUCCEEDED:
      return { ...state, isCreateAndAssignOpponentToEventPathPerforming: false };
    case opponentsConst.CREATE_AND_ASSIGN_OPPONENT_TO_EVENT_PATH_FAILED:
      return {
        ...state,
        actionFailed: true,
        errorMessage: action.errorMessage,
        isCreateAndAssignOpponentToEventPathPerforming: false,
      };

    default:
      return { ...state };
  }
}
