import constants from "./constants";

export function reset() {
  return { type: constants.RESET };
}

export function fetchEPT(sportId, parameters, options = {}) {
  return {
    options,
    parameters,
    sportId,
    type: constants.FETCH_EPT,
  };
}

export function fetchRestrictionEPT(code) {
  return {
    code,
    type: constants.FETCH_RESTRICTION_EPT,
  };
}

export function fetchEventMarkets(eventId, parameters, options = {}) {
  return {
    eventId,
    options,
    parameters,
    type: constants.FETCH_EVENT_MARKETS,
  };
}

export function updatePath(id, data) {
  return {
    data,
    id,
    type: constants.UPDATE_PATH,
  };
}
export function updatePaths(pathsArray) {
  return {
    pathsArray,
    type: constants.UPDATE_PATHS,
  };
}
export function updateActiveSportCodeAndId(activeSportId, activeSportCode, activeSportDescription, datesFilter) {
  return {
    activeSportCode,
    activeSportDescription,
    activeSportId,
    datesFilter,
    type: constants.UPDATE_ACTIVE_SPORT_CODE_AND_ID,
  };
}
export function getUpdatedSport() {
  return {
    type: constants.GET_UPDATED_VALUES_SPORT,
  };
}

export function updateActivePathId(activePathId) {
  return {
    activePathId,
    type: constants.UPDATE_ACTIVE_PATH_ID,
  };
}
export function updateActivePathAncestors(activePathAncestors) {
  return {
    activePathAncestors,
    type: constants.UPDATE_ACTIVE_PATH_ANCESTORS,
  };
}
export function updateToggleState(toggleState) {
  return {
    toggleState,
    type: constants.UPDATE_TOGGLE_STATE,
  };
}
export function updateFilterState(isFiltered) {
  return {
    isFiltered,
    type: constants.UPDATE_FILTER_STATE,
  };
}
export function updateSortState(isSorted) {
  return {
    isSorted,
    type: constants.UPDATE_SORT_STATE,
  };
}
export function updateVirtual(isVirtual) {
  return {
    isVirtual,
    type: constants.UPDATE_VIRTUAL_SPORT,
  };
}
export function setDatesFilter(datesFilter) {
  return {
    datesFilter,
    type: constants.SET_DATES_FILTER,
  };
}
export function setMarketStatusFilter(marketStatusFilter) {
  return {
    marketStatusFilter,
    type: constants.SET_MARKET_STATUS_FILTER,
  };
}
export function updateSearchStr(searchStr) {
  return {
    searchStr,
    type: constants.UPDATE_SEARCH_STR,
  };
}
export function updateSearchOption(searchOption) {
  return {
    searchOption,
    type: constants.UPDATE_SEARCH_OPTION,
  };
}
export function updatePathBaseUrl(baseUrl) {
  return {
    baseUrl,
    type: constants.UPDATE_PATH_BASE_URL,
  };
}
export function createPath(id, data) {
  // creates a new path placeholder
  return {
    data,
    id,
    type: constants.CREATE_PATH,
  };
}
export function finalizePath(id, data) {
  // finalize path placeholder with correct data
  return {
    data,
    id,
    type: constants.FINALIZE_PATH,
  };
}
export function deletePath(id) {
  return {
    id,
    type: constants.DELETE_PATH,
  };
}
export function deletePaths(ids) {
  return {
    ids,
    type: constants.DELETE_PATHS,
  };
}
export function setNewPathsOrder(pathsOrder) {
  return {
    pathsOrder,
    type: constants.SET_NEW_PATHS_ORDER,
  };
}
export function saveNewPathsOrder(pathsOrder) {
  return {
    pathsOrder,
    type: constants.SAVE_NEW_PATHS_ORDER,
  };
}
export function setAsFirstLoad(isFirstLoad) {
  return {
    isFirstLoad,
    type: constants.SET_AS_FIRST_LOAD,
  };
}
export function addToPathSelections(path) {
  return {
    path,
    type: constants.ADD_PATH_TO_SELECTIONS,
  };
}
export function removeFromPathSelections(path) {
  return {
    path,
    type: constants.REMOVE_PATH_FROM_SELECTIONS,
  };
}
export function removeIdsfromPathSelections(ids) {
  return {
    ids,
    type: constants.REMOVE_IDS_FROM_SELECTIONS,
  };
}

export function updateConfig(config) {
  return {
    config,
    type: constants.UPDATE_CONFIG,
  };
}

export function setParameters(parameters) {
  return {
    parameters,
    type: constants.SET_PARAMETERS,
  };
}

export function setDefaultParams(defaultParams) {
  return {
    defaultParams,
    type: constants.SET_DEFAULT_PARAMETERS,
  };
}

export function setDatesParamFormat(datesParamFormat) {
  return {
    datesParamFormat,
    type: constants.SET_DATES_PARAM_FORMAT,
  };
}

export function setInitialStateProps(props) {
  return {
    props,
    type: constants.SET_INITIAL_STATE_PROPS,
  };
}

export function setJackpotBetTypeFilter(jackpotBetTypeFilter) {
  return {
    jackpotBetTypeFilter,
    type: constants.SET_JACKPOT_BET_TYPE_FILTER,
  };
}

export function setJackpotBetStatusFilter(jackpotBetStatusFilter) {
  return {
    jackpotBetStatusFilter,
    type: constants.SET_JACKPOT_BET_STATUS_FILTER,
  };
}
