import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const promotionalGroupsXhr = null;
const promotionalGroupXhr = null;
const promotionalGroupFiltersXhr = null;
const promotionalGroupMembersXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchPromotionalGroups() {
  const url = `/bo/campaigntree/groups?showRetired=true`;

  return performHttpCall(promotionalGroupsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionalGroupFilters() {
  const url = `/bo/promotionalgroups/promotionalgroupfilters`;

  return performHttpCall(promotionalGroupFiltersXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionalGroupMembers(id, params = {}) {
  const queryParams = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");
  let url = `/bo/promotionalgroups/${id}/members`;
  if (queryParams !== "") {
    url = `${url}?${queryParams}`;
  }

  return performHttpCall(promotionalGroupMembersXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionalGroupMembersCount(id, params = {}) {
  const queryParams = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");
  let url = `/bo/promotionalgroups/${id}/membersCount`;
  if (queryParams !== "") {
    url = `${url}?${queryParams}`;
  }

  return performHttpCall(promotionalGroupMembersXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchPromotionalGroupItem(id) {
  const url = `/bo/promotionalgroups/${id}`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function newPromotionalGroupItem(params) {
  const url = `/bo/promotionalgroups`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_POST, url, params)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function copyPromotionalGroupItem(params) {
  const url = `/bo/promotionalgroups/copy`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_POST, url, params)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function editPromotionalGroupItem(id, params) {
  const url = `/bo/promotionalgroups/${id}`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_PUT, url, params)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deletePromotionalGroup(id) {
  const url = `/bo/promotionalgroups/${id}`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function importMembers(id, data) {
  const url = `/bo/promotionalgroups/${id}/import`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_PUT, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function exportMembers(id, name) {
  const url = `/bo/promotionalgroups/${id}/export?filename=${name}`;

  return performHttpCall(promotionalGroupXhr, httpMethods.HTTP_GET, url, null, {
    "Content-Disposition": `attachment; filename="${name}"`,
    "Content-Type": "application/csv",
    processData: false,
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
