import qs from "query-string";
import { push, replace } from "react-router-redux";
import { put, call, select, takeLatest } from "redux-saga/effects";

import { updatePath, updatePaths, fetchEventMarkets as eptFetchEventMatkets } from "./actions";
import actionTypes from "./constants";
import { getParentPaths } from "./helpers";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";
import { makeIterable } from "utils";

function* fetchEPT(action) {
  const { activeAppId } = yield select((state) => state.apps);
  const { options, parameters } = action;
  let sportId = action.sportId;
  switch (activeAppId) {
    case 19: // set sportId to null if current app is Risk Manager
    case 48: // set sportId to null if current app is Results Viewer
      sportId = null;
      break;
  }
  const { response } = yield call(API.fetchEPT, sportId, parameters, activeAppId);
  const { isFirstLoad } = yield select((state) => state.sportsTree);
  if (response) {
    yield put({
      persistOldPathsMap: !!options.persistOldPathsMap,
      response,
      type: actionTypes.FETCH_EPT_SUCCEEDED,
    });
    const { activePathAncestors, activePathId, isFiltered, parameters, pathsMap } = yield select(
      (state) => state.sportsTree,
    );
    let firstPathIdFound;
    let firstPathFound;
    for (let i = 0, length = activePathAncestors.length; i < length; i++) {
      firstPathIdFound = activePathAncestors[i];
      const path = pathsMap[firstPathIdFound];
      if (path) {
        if (!isFiltered) {
          firstPathFound = path;
          break;
        } else if (activeAppId === 19) {
          // if currentApp is Risk Manager
          if (path.type === "path" || path.type === "event") {
            firstPathFound = path;
            break;
          }
        } else if ((path.type === "path" && path.eventCount) || (path.type === "event" && path.marketCount)) {
          firstPathFound = path;
          break;
        }
      }
    }
    // find all expanded events and collapse them
    const expandedEvents = [...makeIterable(pathsMap)].filter((path) => path.eventType);
    if (expandedEvents.length) {
      yield put(updatePaths(expandedEvents.map((event) => ({ data: { isExpanded: false }, id: event.id }))));
    }
    if (firstPathFound && firstPathFound.id !== activePathId && !isFirstLoad) {
      if (firstPathFound.eventType) {
        // if firstPathFound is an event, fetch its markets and expand it
        if (!firstPathFound.isExpanded) {
          yield put(updatePath(firstPathFound.id, { isExpanded: true }));
        }
        yield put(eptFetchEventMatkets(firstPathFound.id, parameters, { autoOpenParentPaths: true }));
      } else {
        // change route to the first available path's url
        yield put(push(firstPathFound.url));
      }
    }
    if (options.location) {
      const dateFilter = qs.parse(options.location.search).dateFilter;
      if (dateFilter) {
        yield put(replace(options.location.pathname));
      }
    }
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_EPT_FAILED });
  }
}

function* fetchRestrictionEPT(action) {
  const { response } = yield call(API.fetchRestrictionEPT, action.code);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_EPT_SUCCEEDED });
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_EPT_FAILED });
  }
}

const flattenMarkets = (markets, accu) => {
  markets.forEach((market) => {
    accu.push(market);
    if (market.childMarkets && market.childMarkets.length) {
      flattenMarkets(market.childMarkets, accu);
    }
  });

  return accu;
};

function* fetchEventMarkets(action) {
  const { eventId, parameters } = action;
  const { response } = yield call(API.fetchEventMarkets, eventId, parameters);
  if (response) {
    yield put({ eventId, response, type: actionTypes.FETCH_EVENT_MARKETS_SUCCEEDED });
    const { activePathId, pathsMap } = yield select((state) => state.sportsTree);
    const path = pathsMap[activePathId];
    if (path && action.options.autoOpenParentPaths) {
      const parentPath = pathsMap[path.parentId];
      if (parentPath && !parentPath.isExpanded) {
        const parentPaths = getParentPaths(pathsMap[parentPath.id], pathsMap, []);
        if (parentPaths.length) {
          yield put(updatePaths(parentPaths));
        }
      }
    }
    if (action.options.updateEventMarketCount) {
      const flatMarkets = flattenMarkets(response, []);
      const marketCount = flatMarkets.length;
      yield put(updatePath(eventId, { marketCount }));
    }
  } else {
    yield put({ errorMessage: "Error", type: actionTypes.FETCH_EVENT_MARKETS_FAILED });
  }
}

function* saveNewPathsOrder(action) {
  try {
    const { pathsOrder } = action;
    const { response, xhr } = yield call(API.saveNewPathsOrder, pathsOrder);
    if (response) {
      yield put({ response, type: actionTypes.SAVE_NEW_PATHS_ORDER_SUCCEEDED });
      toastr.add({ message: i18n.t(`Common.Paths order successfully saved`) });
    } else {
      const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
      yield put({ errorMessage: "Error", type: actionTypes.SAVE_NEW_PATHS_ORDER_FAILED });
      toastr.add({ message: `${i18n.t(`Common.Unable to delete event`)}. ${msg}`, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: "Error", type: actionTypes.SAVE_NEW_PATHS_ORDER_FAILED });
  }
}

export default function* eventCreatorPathTreeSaga() {
  yield takeLatest(actionTypes.FETCH_EPT, fetchEPT);
  yield takeLatest(actionTypes.FETCH_RESTRICTION_EPT, fetchRestrictionEPT);
  yield takeLatest(actionTypes.FETCH_EVENT_MARKETS, fetchEventMarkets);
  yield takeLatest(actionTypes.SAVE_NEW_PATHS_ORDER, saveNewPathsOrder);
}
