import moment from "moment";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import sortExRate from "./healpers";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchDefaultCurrency(action) {
  const { data } = action;
  const { response, xhr } = yield call(API.fetchDefaultCurrency, data);
  if (response) {
    const defaultDate = moment().format();
    yield put({ currencyId: response.id, date: defaultDate, type: constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY });
    yield put({ response, type: constants.FETCH_DEFAULT_CURRENCY_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_DEFAULT_CURRENCY_FAILED });
  }
}

function* fetchExchangeRate(action) {
  const { response, xhr } = yield call(API.fetchExchangeRate, action.currencyId, action.date);
  if (response) {
    const sortedData = sortExRate(response, action.currencyId);
    yield put({ response: sortedData, type: constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY_FAILED });
  }
}

function* saveExchangeRate(action) {
  const { response, xhr } = yield call(API.saveExchangeRate, action.payload);
  if (response) {
    yield put({
      currencyId: action.payload[0]["toCurrencyId"],
      date: action.payload[0]["date"],
      type: constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY,
    });
    toastr.add({ message: i18n.t("Utilities.Exchange Rate Updated Successfully"), type: "SUCCESS" });
    // yield put({ type: constants.SAVE_EXCHANGE_RATE_SUCCEEDED })
  } else {
    // yield put({ type: constants.SAVE_EXCHANGE_RATE_FAILED })
  }
}

export default function* utilitiesExchangeRate() {
  yield takeLatest(constants.FETCH_DEFAULT_CURRENCY, fetchDefaultCurrency);
  yield takeLatest(constants.FETCH_EXCHANGE_RATE_FOR_BASE_CURRENCY, fetchExchangeRate);
  yield takeLatest(constants.SAVE_EXCHANGE_RATE, saveExchangeRate);
}
