export const getValidMarketData = (state, data) => {
  const original = state.utilitiesApp.utilitiesMarketTypeConfig.marketDataConfig;
  let bool = false;
  data.map((y) => {
    original.map((val) => {
      if (val.sportCode == y.sportCode && val.ordinal == y.ordinal && val.id != y.id) {
        bool = true;
      }
    });
  });

  return bool;
};
