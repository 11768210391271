import constants from "./constants";

export function fetchEvent(eventId, isRankEvent = false) {
  return {
    eventId,
    isRankEvent,
    type: constants.FETCH_EVENT,
  };
}

export function fetchEventDefaultMarket(eventId) {
  return {
    eventId,
    type: constants.FETCH_EVENT_DEFAULT_MARKET,
  };
}

export function fetchEventSelectedOpponents(marketId, eventId) {
  return {
    eventId,
    marketId,
    type: constants.FETCH_EVENT_SELECTED_OPPONENTS,
  };
}

export function deleteEvent(eventId) {
  return {
    eventId,
    type: constants.DELETE_EVENT,
  };
}

export function deleteEvents(eventIds) {
  return {
    eventIds,
    type: constants.DELETE_EVENTS,
  };
}

export function createEvent(eventPathId, data) {
  return {
    data,
    eventPathId,
    type: constants.CREATE_EVENT,
  };
}

export function updateEvent(eventId, data) {
  return {
    data,
    eventId,
    type: constants.UPDATE_EVENT,
  };
}

export function fetchOpponents(eventPathId, options) {
  return {
    eventPathId,
    options,
    type: constants.FETCH_EVENT_OPPONENTS,
  };
}

export function fetchOpponentsByType(eventPathId, mode) {
  return {
    eventPathId,
    mode,
    type: constants.FETCH_EVENT_OPPONENTS_BY_TYPE,
  };
}

export function getDefaultTemplate(eventPathId, eventTypeId, data) {
  return {
    data,
    eventPathId,
    eventTypeId,
    type: constants.FETCH_EVENT_DEFAULT_TEMPLATE,
  };
}

export function getDefaultTemplateSettings(eventTypeId, templateId) {
  return {
    eventTypeId,
    templateId,
    type: constants.FETCH_EVENT_DEFAULT_TEMPLATE_SETTINGS,
  };
}

export function resetDefaultTemplateSettings() {
  return {
    type: constants.RESET_EVENT_DEFAULT_TEMPLATE_SETTINGS,
  };
}

export function setNewSelectedOpponents(opponents) {
  return {
    opponents,
    type: constants.SET_NEW_SELECTED_OPPONENTS,
  };
}

export function clearNewSelectedOpponents() {
  return {
    type: constants.CLEAR_NEW_SELECTED_OPPONENTS,
  };
}

export function fetchPlayersOfOpponentA(opponentId) {
  return {
    opponentId,
    type: constants.FETCH_PLAYERS_OF_OPPONENTA,
  };
}

export function fetchPlayersOfOpponentB(opponentId) {
  return {
    opponentId,
    type: constants.FETCH_PLAYERS_OF_OPPONENTB,
  };
}

export function clearPlayersOfOpponentsAB() {
  return {
    type: constants.CLEAR_PLAYERS_OF_OPPONENTS_A_B,
  };
}

export function changeMode(mode) {
  return {
    mode,
    type: constants.CHANGE_PLAY_MODE,
  };
}
