import _ from "lodash";

import constants from "./constants";

const initialState = {
  initialJackPotWinnerData: null,
  payoutJackPotWinner: null,
  perInitialJackPotData: null,
  selectedCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_INITIAL_JACKPOT_WINNER_DATA_SUCCEEDED:
      let winnerData = action.results;
      if (parseInt(state.selectedCount) !== 0 && winnerData.length > 0) {
        var filterValue = _.filter(winnerData, { selectionCount: parseInt(state.selectedCount) });
        winnerData = filterValue;
      }

      return {
        ...state,
        initialJackPotWinnerData: winnerData,
        perInitialJackPotData: action.results,
      };
    case constants.FETCH_INITIAL_JACKPOT_WINNER_DATA_FAILED:
      return {
        ...state,
        initialJackPotWinnerData: null,
      };
    case constants.PAYOUT_JACKPOT_BET_WINNER_SUCCEEDED:
      return {
        ...state,
        payoutJackPotWinner: true,
      };
    case constants.PAYOUT_JACKPOT_BET_WINNER_FAILED:
      return {
        ...state,
        payoutJackPotWinner: false,
      };
    case constants.SELECTION_COUNT_CHANGE:
      let winner = state.perInitialJackPotData;
      if (parseInt(action.val) !== 0 && winner.length > 0) {
        var filterValue = _.filter(winner, { selectionCount: parseInt(action.val) });
        winner = filterValue;
      }

      return {
        ...state,
        initialJackPotWinnerData: winner,
        selectedCount: parseInt(action.val),
      };
    default:
      return state;
  }
}
