import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTH_SET_TOKEN } from "../actions/auth-actions";
import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";

export const getRetailInitialState = ({
  assignedShopOperators = [],
  createdShopGroupId,
  createdShopId,
  createdTillId,
  error = undefined,
  loading = false,
  loadingComments = false,
  saving = false,
  savingComments = false,
  shopComments = undefined,
  tillComments = undefined,
  shopHierarchy = [],
  errorLoadingTillWagerLimits = undefined,
  loadingTillWagerLimits = false,
  tillWagerLimits = {},
}) => ({
  assignedShopOperators,
  createdShopGroupId,
  createdShopId,
  createdTillId,
  error,
  loading,
  loadingComments,
  saving,
  savingComments,
  shopComments,
  shopHierarchy,
  tillComments,
});

export const loadShopHierarchy = createAsyncThunk("retail/loadhierarchy", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/operators/${accountId}/shophierarchy`);

    return {
      shopHierarchy: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop hierarchy", // serializable (err.response.data)
      name: "Shop Hierarchy Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const createShopGroup = createAsyncThunk("retail/createShopGroup", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { brandId, currencyIsoCode, description } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/shopgroups`, {
      brandId,
      currencyIsoCode,
      description,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      shopGroupId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to create shop group", // serializable (err.response.data)
      name: "Shop Group Creation Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const createShop = createAsyncThunk("retail/createShop", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { brandId, description, shopGroupId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/shops`, {
      brandId,
      description,
      shopGroupId,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      shopId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to create shop", // serializable (err.response.data)
      name: "Shop  Creation Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const createTill = createAsyncThunk("retail/createTill", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { authAddress, brandId, description, shopId, type } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/tills`, {
      authAddress,
      brandId,
      description,
      shopId,
      type,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      tillId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to create till", // serializable (err.response.data)
      name: "Till Creation Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const updateShopGroup = createAsyncThunk("retail/updateShopGroup", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { brandId, description, id } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.put(`/bo/shopgroups/${id}`, {
      brandId,
      description,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      shopGroupId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to update shop group", // serializable (err.response.data)
      name: "Shop Group Update Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const updateShop = createAsyncThunk("retail/updateShop", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { brandId, description, enabled, id } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.put(`/bo/shops/${id}`, {
      brandId,
      description,
      enabled,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      shopId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to update shop", // serializable (err.response.data)
      name: "Shop Update Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const updateTill = createAsyncThunk("retail/updateTill", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { authAddress, brandId, description, enabled, id } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.put(`/bo/tills/${id}`, {
      authAddress,
      brandId,
      description,
      enabled,
    });

    thunkAPI.dispatch(loadShopHierarchy()); // reload the tree cleanly

    return {
      tillId: result.data.id,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to create till", // serializable (err.response.data)
      name: "Till Creation Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadComments = createAsyncThunk("retail/loadComments", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());
    const { shopId, tillId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/${shopId ? "shops" : "tills"}/${shopId || tillId}/comments/all`);

    return {
      shopComments: shopId ? result.data : undefined,
      tillComments: tillId ? result.data : undefined,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop / till comments", // serializable (err.response.data)
      name: "Shop / Till Comment Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const createComment = createAsyncThunk("retail/createComment", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { description, isMain, operatorId, shopId, tillId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/${shopId ? "shops" : "tills"}/${shopId || tillId}/comments`, {
      description,
      main: isMain,
      operatorId,
    });

    thunkAPI.dispatch(loadComments({ shopId, tillId })); // reload the comments cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to insert comment", // serializable (err.response.data)
      name: "Comment Creation Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const updateComment = createAsyncThunk("retail/updateComment", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { commentId, description, isMain, shopId, tillId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.put(
      `/bo/${shopId ? "shops" : "tills"}/${shopId || tillId}/comments${!isMain ? `/${commentId}` : ""}`,
      {
        description,
        main: isMain,
      },
    );

    thunkAPI.dispatch(loadComments({ shopId, tillId })); // reload the comments cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to update comment", // serializable (err.response.data)
      name: "Comment Update Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const deleteComment = createAsyncThunk("retail/deleteComment", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { commentId, isMain, shopId, tillId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.delete(
      `/bo/${shopId ? "shops" : "tills"}/${shopId || tillId}/comments${!isMain && `/${commentId}`}`,
    );

    thunkAPI.dispatch(loadComments({ shopId, tillId })); // reload the comments cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to delete comment", // serializable (err.response.data)
      name: "Comment Deletion Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadAvailableShopOperators = createAsyncThunk(
  "retail/loadAvailableShopOperators",
  async (data, thunkAPI) => {
    try {
      const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());
      const { shopId } = data;

      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      const result = await axios.get(`/bo/shops/${shopId}/operators/available`);

      return {
        unassignedShopOperators: result.data,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to load the assigned shop operators", // serializable (err.response.data)
        name: "Shop Operator List Load Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

export const loadShopOperators = createAsyncThunk("retail/loadShopOperators", async (data, thunkAPI) => {
  try {
    const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());
    const { shopId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/shops/${shopId}/operators`);

    return {
      assignedShopOperators: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the assigned shop operators", // serializable (err.response.data)
      name: "Shop Operator List Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const assignShopOperator = createAsyncThunk("retail/assignShopOperator", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { brandId, operatorId, shopId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/shops/${shopId}/operators`, {
      accountId: operatorId,
      brandId,
      shopId,
    });

    thunkAPI.dispatch(loadShopOperators({ shopId })); // reload the shop operators cleanly
    thunkAPI.dispatch(loadAvailableShopOperators({ shopId })); // reload the shop operators cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to assign shop operator", // serializable (err.response.data)
      name: "Shop Operator Assignment Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const deleteShopOperator = createAsyncThunk("retail/deleteShopOperator", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { operatorId, shopId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.delete(`/bo/shops/${shopId}/operators/${operatorId}`);

    thunkAPI.dispatch(loadShopOperators({ shopId })); // reload the shop operators cleanly
    thunkAPI.dispatch(loadAvailableShopOperators({ shopId })); // reload the shop operators cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to delete shop operator assignment ", // serializable (err.response.data)
      name: "Shop Operator Deletion Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadTillWagerLimits = createAsyncThunk("retail/loadTillWagerLimits", async ({ tillId }, thunkAPI) => {
  try {
    const { accountId, authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/tills/${tillId}/tillwagerlimits`);

    return {
      tillWagerLimits: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the till wager limits", // serializable (err.response.data)
      name: "Till Wager Limit Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const saveTillWagerLimits = createAsyncThunk("retail/saveTillWagerLimits", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { tillId, wagerLimits } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/tills/${tillId}/tillwagerlimits`, wagerLimits);

    thunkAPI.dispatch(loadTillWagerLimits({ tillId })); // reload the shop operators cleanly

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to update till wager limits", // serializable (err.response.data)
      name: "Till Wager Limit Update Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadTillBalance = createAsyncThunk("retail/loadTillBalance", async ({ tillId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/tills/${tillId}/balance`);

    return {
      tillBalance: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the till balance", // serializable (err.response.data)
      name: "Till Balance Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadShopBalance = createAsyncThunk("retail/loadShopBalance", async ({ shopId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/shops/${shopId}/balance`);

    return {
      shopBalance: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop balance", // serializable (err.response.data)
      name: "Shop Balance Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const tillToTillTransfer = createAsyncThunk("retail/tilltransfer", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { amount, fromTillId, shopId, toTillId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/shops/${shopId}/tilltransfer`, {
      amount,
      destinationTillId: toTillId,
      sourceTillId: fromTillId,
    });

    thunkAPI.dispatch(loadTillBalance({ tillId: fromTillId }));

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to transfer funds", // serializable (err.response.data)
      name: "Transfer Fund Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadTillTodayPerformance = createAsyncThunk(
  "retail/loadTillTodayPerformance",
  async ({ tillId }, thunkAPI) => {
    try {
      const { authToken, language } = getRequestParams(thunkAPI.getState());
      // const { password, username } = data;

      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const result = await axios.get(
        `/bo/tills/${tillId}/summary?dateFrom=${new Date(new Date().setHours(0, 0, 0, 0))
          .toISOString()
          .slice(0, -1)}+00:00&dateTo=${tomorrow.toISOString().slice(0, -1)}+00:00`,
      );

      return {
        performanceSummary: result.data,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to load the shop performance", // serializable (err.response.data)
        name: "Shop Performance Load Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

export const loadShopTodayPerformance = createAsyncThunk(
  "retail/loadShopTodayPerformance",
  async ({ shopId }, thunkAPI) => {
    try {
      const { authToken, language } = getRequestParams(thunkAPI.getState());
      // const { password, username } = data;

      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const result = await axios.get(
        `/bo/shops/${shopId}/summary?dateFrom=${new Date(new Date().setHours(0, 0, 0, 0))
          .toISOString()
          .slice(0, -1)}+00:00&dateTo=${tomorrow.toISOString().slice(0, -1)}+00:00`,
      );

      return {
        performanceSummary: result.data,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to load the shop performance", // serializable (err.response.data)
        name: "Shop Performance Load Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

export const loadTillPerformance = createAsyncThunk("retail/loadTillPerformance", async ({ tillId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/tills/${tillId}/performance`);

    return {
      performanceSummary: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop performance", // serializable (err.response.data)
      name: "Shop Performance Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadShopPerformance = createAsyncThunk("retail/loadShopPerformance", async ({ shopId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/bo/shops/${shopId}/performance`);

    return {
      performanceSummary: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop performance", // serializable (err.response.data)
      name: "Shop Performance Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadShopPerformanceSummary = createAsyncThunk(
  "retail/loadShopPerformanceSummary",
  async ({ shopId }, thunkAPI) => {
    try {
      const { authToken, language } = getRequestParams(thunkAPI.getState());
      // const { password, username } = data;

      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      const result = await axios.get(`/bo/shops/${shopId}/performance/summary`);

      return {
        performanceSummary: result.data,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to load the shop performance", // serializable (err.response.data)
        name: "Shop Performance Load Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

export const loadTillPerformanceSummary = createAsyncThunk(
  "retail/loadTillPerformanceSummary",
  async ({ tillId }, thunkAPI) => {
    try {
      const { authToken, language } = getRequestParams(thunkAPI.getState());
      // const { password, username } = data;

      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      const result = await axios.get(`/bo/tills/${tillId}/performance/summary`);

      return {
        performanceSummary: result.data,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to load the till performance", // serializable (err.response.data)
        name: "Till Performance Load Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

function to2Digits(number) {
  return String(number).padStart(2, "0");
}

function getTimezone() {
  const offset = new Date().getTimezoneOffset() * -1;
  const offsetHours = offset / 60;
  const offsetMinutes = Math.abs(offset % 60);
  const timezoneHeader = `${Math.sign(offsetHours) > 0 ? "+" : ""}${to2Digits(offsetHours)}:${to2Digits(
    offsetMinutes,
  )}`;

  return timezoneHeader;
}

export const loadShopStatistics = createAsyncThunk("retail/loadShopStatistics", async ({ shopId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    axios.defaults.headers["x-timezone"] = getTimezone();

    const result = await axios.get(`/bo/shops/${shopId}/statistics`);

    return {
      shopStatistics: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop statistics", // serializable (err.response.data)
      name: "Shop Statistics Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadTillStatistics = createAsyncThunk("retail/loadTillStatistics", async ({ tillId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    axios.defaults.headers["x-timezone"] = getTimezone();

    const result = await axios.get(`/bo/tills/${tillId}/statistics`);

    return {
      tillStatistics: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the till statistics", // serializable (err.response.data)
      name: "Till Statistics Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const adjustTill = createAsyncThunk("retail/adjustTill", async (data, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    const { amount, description, fromTillId, shopId } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.post(`/bo/tills/${fromTillId}/adjustment`, {
      cashIn: amount > 0 ? amount : 0,
      cashOut: amount < 0 ? Math.abs(amount) : 0,
      description,
    });

    thunkAPI.dispatch(loadTillBalance({ tillId: fromTillId }));

    return {
      success: true,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to adjust till balance", // serializable (err.response.data)
      name: "Till Adjustment Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadShopTransactions = createAsyncThunk("retail/loadShopTransactions", async ({ shopId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    axios.defaults.headers["x-timezone"] = getTimezone();

    const result = await axios.get(`/bo/shops/${shopId}/transactions`);

    return {
      transactions: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the shop transactions", // serializable (err.response.data)
      name: "Shop transactions Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

export const loadTillTransactions = createAsyncThunk("retail/loadTillTransactions", async ({ tillId }, thunkAPI) => {
  try {
    const { authToken, language } = getRequestParams(thunkAPI.getState());
    // const { password, username } = data;

    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    axios.defaults.headers["x-timezone"] = getTimezone();

    const result = await axios.get(`/bo/tills/${tillId}/transactions`);

    return {
      transactions: result.data,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load the till transactions", // serializable (err.response.data)
      name: "Till Transactions Load Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const retailSlice = createSlice({
  extraReducers: {
    [loadShopOperators.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [loadShopOperators.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [loadShopOperators.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.assignedShopOperators = action.payload.assignedShopOperators;
    },
    [loadAvailableShopOperators.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [loadAvailableShopOperators.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [loadAvailableShopOperators.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.unassignedShopOperators = action.payload.unassignedShopOperators;
    },

    [assignShopOperator.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [assignShopOperator.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [assignShopOperator.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
    },

    [deleteShopOperator.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteShopOperator.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [deleteShopOperator.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
    },

    [loadShopHierarchy.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [loadShopHierarchy.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [loadShopHierarchy.fulfilled]: (state, action) => {
      state.error = null;
      state.loading = false;
      state.shopHierarchy = action.payload.shopHierarchy;
    },
    [createShopGroup.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
      state.createdShopGroupId = undefined;
    },
    [createShopGroup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.saving = false;
      state.savingEnded = true;
    },
    [createShopGroup.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
      state.createdShopGroupId = action.payload.shopGroupId;
    },

    [createShop.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
      state.createdShopId = undefined;
    },
    [createShop.rejected]: (state, action) => {
      state.error = action.error.message;
      state.savingEnded = true;
      state.saving = false;
    },
    [createShop.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
      state.createdShopId = action.payload.shopId;
    },

    [createTill.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
      state.createdTillId = undefined;
    },
    [createTill.rejected]: (state, action) => {
      state.error = action.error.message;
      state.saving = false;
      state.savingEnded = true;
    },
    [createTill.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
      state.createdTillId = action.payload.tillId;
    },

    [updateShopGroup.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
    },
    [updateShopGroup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.saving = false;
      state.savingEnded = true;
    },
    [updateShopGroup.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
    },

    [updateShopGroup.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
    },
    [updateShopGroup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.savingEnded = true;
      state.saving = false;
    },
    [updateShopGroup.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
    },

    [updateShopGroup.pending]: (state) => {
      state.error = null;
      state.saving = true;
      state.savingEnded = false;
    },
    [updateShopGroup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.saving = false;
      state.savingEnded = true;
    },
    [updateShopGroup.fulfilled]: (state, action) => {
      state.error = null;
      state.saving = false;
      state.savingEnded = true;
    },

    [loadComments.pending]: (state) => {
      state.error = null;
      state.loadingComments = true;
      state.shopComments = undefined;
      state.tillComments = undefined;
    },
    [loadComments.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loadingComments = false;
    },
    [loadComments.fulfilled]: (state, action) => {
      state.error = null;
      state.loadingComments = false;
      state.shopComments = action.payload.shopComments;
      state.tillComments = action.payload.tillComments;
    },
    [createComment.pending]: (state) => {
      state.error = null;
      state.savingComments = true;
    },
    [createComment.rejected]: (state, action) => {
      state.error = action.error.message;
      state.savingComments = false;
    },
    [createComment.fulfilled]: (state, action) => {
      state.error = null;
      state.savingComments = false;
    },

    [updateComment.pending]: (state) => {
      state.error = null;
      state.savingComments = true;
    },
    [updateComment.rejected]: (state, action) => {
      state.error = action.error.message;
      state.savingComments = false;
    },
    [updateComment.fulfilled]: (state, action) => {
      state.error = null;
      state.savingComments = false;
    },

    [deleteComment.pending]: (state) => {
      state.error = null;
      state.savingComments = true;
    },
    [deleteComment.rejected]: (state, action) => {
      state.error = action.error.message;
      state.savingComments = false;
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.error = null;
      state.savingComments = false;
    },

    [loadTillWagerLimits.pending]: (state) => {
      state.errorLoadingTillWagerLimits = null;
      state.loadingTillWagerLimits = true;
      state.tillWagerLimits = undefined;
    },
    [loadTillWagerLimits.rejected]: (state, action) => {
      state.errorLoadingTillWagerLimits = action.error.message;
      state.loadingTillWagerLimits = false;
    },
    [loadTillWagerLimits.fulfilled]: (state, action) => {
      state.errorLoadingTillWagerLimits = null;
      state.loadingTillWagerLimits = false;
      state.tillWagerLimits = action.payload.tillWagerLimits;
    },

    [saveTillWagerLimits.pending]: (state) => {
      state.errorSavingTillWagerLimits = null;
      state.savingTillWagerLimits = true;
    },
    [saveTillWagerLimits.rejected]: (state, action) => {
      state.errorSavingTillWagerLimits = action.error.message;
      state.savingTillWagerLimits = false;
    },
    [saveTillWagerLimits.fulfilled]: (state) => {
      state.errorSavingTillWagerLimits = null;
      state.savingTillWagerLimits = false;
    },
    [tillToTillTransfer.pending]: (state) => {
      state.errorTillToTillTransfer = null;
      state.transferringTillToTill = true;
    },
    [tillToTillTransfer.rejected]: (state, action) => {
      state.errorTillToTillTransfer = action.error.message;
      state.transferringTillToTill = false;
    },
    [tillToTillTransfer.fulfilled]: (state) => {
      state.errorTillToTillTransfer = null;
      state.transferringTillToTill = false;
    },
    [loadTillBalance.pending]: (state, action) => {
      state.tillBalanceError = null;
      state.loadingTillBalance = true;
    },
    [loadTillBalance.rejected]: (state, action) => {
      state.tillBalanceError = action.error.message;
      state.loadingTillBalance = false;
    },
    [loadTillBalance.fulfilled]: (state, action) => {
      state.tillBalanceError = null;
      state.loadingTillBalance = false;
      state.tillBalance = action.payload.tillBalance;
    },
    [loadShopBalance.pending]: (state, action) => {
      state.shopBalanceError = null;
      state.loadingShopBalance = true;
    },
    [loadShopBalance.rejected]: (state, action) => {
      state.tillBalanceError = action.error.message;
      state.loadingShopBalance = false;
    },
    [loadShopBalance.fulfilled]: (state, action) => {
      state.shopBalanceError = null;
      state.loadingShopBalance = false;
      state.shopBalance = action.payload.shopBalance;
    },

    [loadShopTodayPerformance.pending]: (state, action) => {
      state.todayPerformanceError = null;
      state.todayLoadingPerformance = true;
      state.todayPerformance = null;
    },
    [loadShopTodayPerformance.rejected]: (state, action) => {
      state.todayPerformanceError = action.error.message;
      state.todayLoadingPerformance = false;
    },
    [loadShopTodayPerformance.fulfilled]: (state, action) => {
      state.todayPerformanceError = null;
      state.todayLoadingPerformance = false;
      state.todayPerformance = action.payload.performanceSummary;
    },
    [loadTillTodayPerformance.pending]: (state, action) => {
      state.todayPerformanceError = null;
      state.todayLoadingPerformance = true;
      state.todayPerformance = null;
    },
    [loadTillTodayPerformance.rejected]: (state, action) => {
      state.todayPerformanceError = action.error.message;
      state.todayLoadingPerformance = false;
    },
    [loadTillTodayPerformance.fulfilled]: (state, action) => {
      state.todayPerformanceError = null;
      state.loadingPerformance = false;
      state.todayPerformance = action.payload.performanceSummary;
    },

    [loadShopPerformance.pending]: (state, action) => {
      state.performanceError = null;
      state.loadingPerformance = true;
      state.performance = null;
    },
    [loadShopPerformance.rejected]: (state, action) => {
      state.performanceError = action.error.message;
      state.loadingPerformance = false;
    },
    [loadShopPerformance.fulfilled]: (state, action) => {
      state.performanceError = null;
      state.loadingPerformance = false;
      state.performance = action.payload.performance;
    },
    [loadTillPerformance.pending]: (state, action) => {
      state.performanceError = null;
      state.loadingPerformance = true;
      state.performance = null;
    },
    [loadTillPerformance.rejected]: (state, action) => {
      state.performanceError = action.error.message;
      state.loadingPerformance = false;
    },
    [loadTillPerformance.fulfilled]: (state, action) => {
      state.performanceError = null;
      state.loadingPerformance = false;
      state.performance = action.payload.performance;
    },

    [loadShopPerformanceSummary.pending]: (state, action) => {
      state.performanceSummaryError = null;
      state.loadingPerformanceSummary = true;
      state.performanceSummary = null;
    },
    [loadShopPerformanceSummary.rejected]: (state, action) => {
      state.performanceSummaryError = action.error.message;
      state.loadingPerformanceSummary = false;
    },
    [loadShopPerformanceSummary.fulfilled]: (state, action) => {
      state.performanceSummaryError = null;
      state.loadingPerformanceSummary = false;
      state.performanceSummary = action.payload.performanceSummary;
    },
    [loadTillPerformanceSummary.pending]: (state, action) => {
      state.performanceSummaryError = null;
      state.loadingPerformanceSummary = true;
      state.performanceSummary = null;
    },
    [loadTillPerformanceSummary.rejected]: (state, action) => {
      state.performanceSummaryError = action.error.message;
      state.loadingPerformanceSummary = false;
    },
    [loadTillPerformanceSummary.fulfilled]: (state, action) => {
      state.performanceSummaryError = null;
      state.loadingPerformanceSummary = false;
      state.performanceSummary = action.payload.performanceSummary;
    },
    [loadShopStatistics.pending]: (state, action) => {
      state.shopStatisticsError = null;
      state.loadingShopStatistics = true;
      state.shopStatistics = null;
    },
    [loadShopStatistics.rejected]: (state, action) => {
      state.shopStatisticsError = action.error.message;
      state.loadingShopStatistics = false;
    },
    [loadShopStatistics.fulfilled]: (state, action) => {
      state.shopStatisticsError = null;
      state.loadingShopStatistics = false;
      state.shopStatistics = action.payload.shopStatistics;
    },
    [loadTillStatistics.pending]: (state, action) => {
      state.tillStatisticsError = null;
      state.loadingTillStatistics = true;
      state.tillStatistics = null;
    },
    [loadTillStatistics.rejected]: (state, action) => {
      state.tillStatisticsError = action.error.message;
      state.loadingTillStatistics = false;
    },
    [loadTillStatistics.fulfilled]: (state, action) => {
      state.tillStatisticsError = null;
      state.loadingTillStatistics = false;
      state.tillStatistics = action.payload.tillStatistics;
    },
    [loadTillTransactions.pending]: (state, action) => {
      state.tillTransactionsError = null;
      state.loadingTillTransactions = true;
      state.tillTransactions = null;
    },
    [loadTillTransactions.rejected]: (state, action) => {
      state.tillTransactionsError = action.error.message;
      state.loadingTillTransactions = false;
    },
    [loadTillTransactions.fulfilled]: (state, action) => {
      state.tillTransactionsError = null;
      state.loadingTillTransactions = false;
      state.tillTransactions = action.payload.transactions;
    },

    [loadShopTransactions.pending]: (state, action) => {
      state.shopTransactionsError = null;
      state.loadingShopTransactions = true;
      state.shopTransactions = null;
    },
    [loadShopTransactions.rejected]: (state, action) => {
      state.shopTransactionsError = action.error.message;
      state.loadingShopTransactions = false;
    },
    [loadShopTransactions.fulfilled]: (state, action) => {
      state.shopTransactionsError = null;
      state.loadingShopTransactions = false;
      state.shopTransactions = action.payload.transactions;
    },
    [adjustTill.pending]: (state) => {
      state.errorTillAdjustment = null;
      state.adjustingTillBalance = true;
    },
    [adjustTill.rejected]: (state, action) => {
      state.errorTillAdjustment = action.error.message;
      state.adjustingTillBalance = false;
    },
    [adjustTill.fulfilled]: (state) => {
      state.errorTillAdjustment = null;
      state.adjustingTillBalance = false;
    },
  },
  initialState: getRetailInitialState({}),
  name: "retail",
  reducers: {
    // setAuthLanguage: {
    //   prepare(payload) {
    //     return { payload };
    //   },
    //   reducer(state, action) {
    //     state.language = action.payload.language;
    //   },
    // },
  },
});

const { actions, reducer } = retailSlice;
// export const {  setAuthLanguage } = actions;
export default reducer;
