import originalAxios from "axios";

import couponModel from "../models/couponModel";

import api from "./api";

let fetchTransactionDetailsXhr = null;
let manualSettleRiskTransactionXhr = null;

export function fetchRiskTransactionDetails(transactionId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/analysis/transactions/${transactionId}`;

    if (fetchTransactionDetailsXhr) {
      fetchTransactionDetailsXhr.cancel("cancelled due to concurrent request");
    }

    fetchTransactionDetailsXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchTransactionDetailsXhr);
      fetchTransactionDetailsXhr = null;
      resolve(response);
    } catch (err) {
      fetchTransactionDetailsXhr = null;
      reject(err);
    }
    fetchTransactionDetailsXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function manualSettleRiskTransaction(transactionId, isVoid, credit, voidReasonId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/transactions/${transactionId}/manualSettle`;
    const requestBody = {};
    if (isVoid !== null) {
      requestBody.isVoid = isVoid;
    }
    if (credit !== null && typeof credit !== "undefined") {
      requestBody.credit = credit;
    }
    if (voidReasonId >= 0) {
      requestBody.voidReasonId = voidReasonId;
    }

    if (manualSettleRiskTransactionXhr) {
      manualSettleRiskTransactionXhr.cancel("cancelled due to concurrent request");
    }

    manualSettleRiskTransactionXhr = originalAxios.CancelToken.source();

    try {
      const response = api.put(url, requestBody, manualSettleRiskTransactionXhr);
      manualSettleRiskTransactionXhr = null;
      resolve(response);
    } catch (err) {
      manualSettleRiskTransactionXhr = null;
      reject(err);
    }
    manualSettleRiskTransactionXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
