import _ from "lodash";
import { SortDirection, SortIndicator } from "react-virtualized";

import constant from "../constants";

import constants from "./constants";
import { getNewlyAddedData, isKeyExistInTableColumns } from "./healpers";

import { formatNumber, formatISODateString } from "utils";

function filterIt(arr, searchKey) {
  return arr.filter((obj) => Object.keys(obj).some((key) => obj[key].includes(searchKey)));
}
// TODO: remove dummy withdrawal data
const initialState = {
  columnConfigs: [],
  gridData: [],
  isGridLoading: false,
  newlyAdded: [],
  originalGridData: [],
  sortBy: null,
  sortDirection: "DESC",
};
const gridReducer = (state = initialState, action) => {
  let newlyAdded;
  switch (action.type) {
    case constants.SET_GRID_DATA:
      newlyAdded = getNewlyAddedData(state.gridData, action.data);

      return {
        ...state,
        gridData: action.data,
        isGridLoading: false,
        newlyAdded,
        originalGridData: action.data,
      };

    case constants.SET_GRID_LOADING_STATE:
      return {
        ...state,
        isGridLoading: action.isLoading,
      };

    case constants.SET_GRID_COLUMN_CONFIG:
      return {
        ...state,
        columnConfigs: action.config,
      };

    case constants.SET_GRID_SORT_CONFIG:
      return {
        ...state,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };

    case constants.SORT_GRID:
      const { sortBy, sortDirection } = state;
      const sortedList = _.orderBy([...state.originalGridData], [action.sortBy], [action.sortDirection.toLowerCase()]);
      newlyAdded = getNewlyAddedData(state.gridData, action.data);

      return {
        ...state,
        gridData: sortedList,
        newlyAdded,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };
    case constants.FILTER_GRID:
      const originalGridData = [...state.originalGridData];
      let filteredList;
      if (action.criteria && originalGridData.length > 0) {
        if (action.searchBy == "1") {
          filteredList = _.filter(originalGridData, (d) =>
            _.find(d, (val, key) => {
              if (key == "paymentDate" || key == "registrationDate") {
                val = formatISODateString(val, "YYYY-MM-DD HH:mm:ss");
              }

              return (
                isKeyExistInTableColumns(state.columnConfigs, key) &&
                val &&
                val !== null &&
                val.toString().toLowerCase().indexOf(action.criteria.toLowerCase()) !== -1
              );
            }),
          );
        } else if (action.searchBy == "2") {
          filteredList = _.filter(
            originalGridData,
            (d) => d && d !== null && d.accountId && d.accountId.toString().indexOf(action.criteria) !== -1,
          );
        } else if (action.searchBy == "3") {
          filteredList = _.filter(
            originalGridData,
            (d) =>
              d &&
              d !== null &&
              d.accountName &&
              d.accountName.toString().toLowerCase().indexOf(action.criteria.toLowerCase()) !== -1,
          );
        } else if (action.searchBy == "4") {
          filteredList = _.filter(
            originalGridData,
            (d) =>
              d &&
              d !== null &&
              d.accountNumber &&
              d.accountNumber.toString().toLowerCase().indexOf(action.criteria.toLowerCase()) !== -1,
          );
        } else if (action.searchBy == "5") {
          filteredList = _.filter(
            originalGridData,
            (d) =>
              d &&
              d !== null &&
              d.userName &&
              d.userName.toString().toLowerCase().indexOf(action.criteria.toLowerCase()) !== -1,
          );
        }
      } else {
        filteredList = originalGridData;
      }

      newlyAdded = getNewlyAddedData(state.gridData, action.data);

      return {
        ...state,
        gridData: filteredList,
        newlyAdded,
        sortBy: state.sortBy,
        sortDirection: state.sortDirection,
      };

    case constants.CLEAR_STATE:
      return initialState;
    case constant.FETCH_FILTERED_DATA:
      return {
        ...state,
        isGridLoading: true,
      };
    case constant.FETCH_FILTERED_DATA_SUCCESS:
      return {
        ...state,
        gridData: action.response.data,
        isGridLoading: false,
      };
    default:
      return { ...state };
  }
};

export default gridReducer;
