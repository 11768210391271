import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const GameResultsXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchGameResultsPeriodPoints(eventId) {
  const url = `/bo/events/${eventId}/periodscores?`;

  return performHttpCall(GameResultsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateGameResultsPeriodPoints(formData) {
  const eventId = formData[0]["eventId"];
  const url = `/bo/events/${eventId}/periodscores?`;

  return performHttpCall(GameResultsXhr, httpMethods.HTTP_POST, url, formData, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateGameResultsVoidPeriod(formData) {
  const eventId = formData["eventId"];
  const url = `/bo/events/${eventId}/voidperiod?`;

  return performHttpCall(GameResultsXhr, httpMethods.HTTP_POST, url, formData, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchEventTotals(eventId, params) {
  let url = `/bo/events/${eventId}/periodsecondaryscores?`;
  if (params.periodId) {
    url += `&periodId=${params.periodId}`;
  }
  if (params.opponentId) {
    url += `&opponentId=${params.opponentId}`;
  }
  if (params.filterYN) {
    url += `&filterYN=${params.filterYN}`;
  }

  return performHttpCall(GameResultsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateEventTotals(eventTotal) {
  const eventId = eventTotal.eventId;
  const url = `/bo/events/${eventId}/periodsecondaryscores`;

  return performHttpCall(GameResultsXhr, httpMethods.HTTP_POST, url, eventTotal, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
