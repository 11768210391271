import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchDefaultCurrency() {
  const url = `/bo/currencies/default`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchExchangeRate(currencyId, date) {
  const url = `/bo/fxrate/${currencyId}/base?date=${date}`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveExchangeRate(payload) {
  const url = `/bo/fxrate/save`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_POST, url, payload)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
