const constants = {
  APPLICATION_ID: 40,
  SET_BM_MODULE: "BM::SET_BM_MODULE",
  SET_BM_MODULE_EDIT_STATE: "BM::SET_BM_MODULE_EDIT_STATE",
  TOGGLE_RETIRED: "BM::TOGGLE_RETIRED",
};

export const MODULE = {
  CAMPAIGN: "CAMPAIGN",
  MESSAGE_PLANS: "MESSAGE_PLANS",
  PROMOTION: "PROMOTION",
  PROMOTIONAL_GROUP: "PROMOTIONAL_GROUP",
  TERMS_CONDITIONS: "TERMS_CONDITIONS",
};

export const permissionsCode = {
  ALLOW_BETSLIP_TOTAL_PERCENTAGE_REWARD: 1500,
  ALLOW_BET_TOTAL_STAKE_PERCENTAGE_REWARD: 1508,
  ALLOW_BET_UNIT_STAKE_PERCENTAGE_REWARD: 1141,
  ALLOW_DEPOSIT_PERCENTAGE_REWARD: 1144,
  ALLOW_FIXED_AMOUNT_REWARD: 1143,
  ALLOW_ODDS_PERCENTAGE_REWARD: 1327,
  ALLOW_PROMO_MANUAL_MINIMUM_ODDS: 1543,
  ALLOW_PROMO_MONEY_REWARD: 1138,
  ALLOW_PROMO_MONEY_SNR_REWARD: 1553,
  ALLOW_PROMO_ROLL_OVER_MULTIPLIER: 1562,
  ALLOW_REAL_MONEY_REWARD: 1137,
  ALLOW_RETURNS_PERCENTAGE_REWARD: 1320,
  ALLOW_WINNING_PERCENTAGE_REWARD: 1142,
  COPY: 1132,
  CREATE_CAMPAIGN: 1133,
  CREATE_PROMOTION: 1134,
  CREATE_PROMOTIONAL_GROUP: 1135,
  CREATE_TERMS_AND_CONDITIONS: 1136,
  DELETE: 1131,
  EDIT: 1130,
};

export default constants;
