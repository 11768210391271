import { put, call, takeLatest } from "redux-saga/effects";

import actionTypes from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import { parseErrorMessageInXhr } from "services//apiUtils";


function* fetchTree(action) {
  const { collapse, filter, pathname, searchKey, showRetired } = action;
  const { response, xhr } = yield call(API.fetchTree, filter, showRetired);
  if (response) {
    if (searchKey) {
      yield put({ data: response, searchKey, type: actionTypes.SEARCH_TREE_ITEM });
    } else {
      yield put({ response, type: actionTypes.FETCH_TREE_SUCCEEDED });
    }
    if (collapse && pathname) {
      yield put({ key: pathname, type: actionTypes.COLLAPSE_SELECTION });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ type: actionTypes.FETCH_TREE_FAILED });
    yield put({ type: actionTypes.RESTORE_OLD_TREE_ITEM });
    toastr.add({ message: `Error. ${msg}`, type: "ERROR" });
  }
}

export default function* bonusManagerTreeSaga() {
  yield takeLatest(actionTypes.FETCH_TREE, fetchTree);
}
