import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import { fetchAppPermissions, fetchApps } from "../actions/apps";
import riskDataConfig from "../configs/riskDataConfig";
import actionTypes from "../constants";
import apiConstantsKeys from "../constants/apiConstantsKeys";
import appNames from "../constants/appNames";
import constants from "../containersV2/InstantAction/constants";
import { forceLogin } from "../redux/slices/authSlice";
import * as ApiConstantsService from "../services/apiConstants";
import * as UserService from "../services/user";
import {
  fetchUserRiskSports,
  editUserRiskSports,
  fetchUserRiskColumns,
  editUserRiskColumns,
} from "../services/userRiskPreferences";
import * as windowLocationService from "../services/windowLocation";
import { objectToArray, formatFilterDates } from "../utils";

const USER_KEY = "USER_DETAILS";

function* startupApp(action) {
  const app = action.appName;
  const keys = apiConstantsKeys[app];
  const isAppListLoaded = yield select((state) => state.apps.isAppListLoaded);
  if (!isAppListLoaded) {
    const userId = yield select((state) => state.auth.accountId);
    yield put(fetchApps(userId)); // fetch assigned applications first
    yield put(fetchAppPermissions(userId));
  }
  if (keys) {
    const { response } = yield call(ApiConstantsService.fetchConstants, keys);
    if (response) {
      yield put({ type: actionTypes.FETCH_API_CONSTANTS_SUCCEEDED, values: response });
    } else {
      yield put({ type: actionTypes.FETCH_API_CONSTANTS_FAILED });
    }
  }
  const accountId = yield select((state) => state.auth.accountId);
  if (app === appNames.RISK_MANAGER) {
    // TODO: separate function for each app
    const { userSports } = yield call(fetchUserRiskSports, accountId);
    const { userColumns } = yield call(fetchUserRiskColumns, accountId);
    if (userSports) {
      yield put({ sports: userSports, type: actionTypes.FETCH_USER_RISK_SPORTS_SUCCEEDED });
    } else {
      yield put({ type: actionTypes.FETCH_USER_RISK_SPORTS_FAILED });
    }
    if (userColumns) {
      yield put({ columns: userColumns, type: actionTypes.FETCH_USER_RISK_COLUMNS_SUCCEEDED });
    } else {
      yield put({ type: actionTypes.FETCH_USER_RISK_COLUMNS_FAILED });
    }
    if (userSports && userColumns) {
      const allColumns = yield select((state) => state.apiConstants.values.riskColumns);
      riskDataConfig.setColumnsVisibility(allColumns, userColumns);
      yield put({ appName: app, type: actionTypes.STARTUP_APP_SUCCEEDED });
    } else {
      yield put({ appName: app, type: actionTypes.STARTUP_APP_FAILED });
    }
  } else {
    yield put({ appName: app, type: actionTypes.STARTUP_APP_SUCCEEDED });
  }
}

function* startupGlobal() {
  // get accountId and token from url
  // this is used for starting up apps from the p8 toolbar
  // backend redirects to /#/<appName>?acc=<accountId>&token=<userToken>
  const accFromUrl = windowLocationService.getParameterByName("acc");
  const tokenFromUrl = windowLocationService.getParameterByName("token");

  if (accFromUrl && tokenFromUrl) {
    yield put(forceLogin({ accountId: accFromUrl, authToken: tokenFromUrl }));
    const { response, xhr } = yield call(UserService.fetchUserDetails, accFromUrl);
  }

  const isLoggedIn = yield select((state) => state.auth.loggedIn);
  const username = yield select((state) => state.auth.username);
  const id = yield select((state) => state.auth.accountId);

  if (isLoggedIn && username && id) {
    yield put({
      details: {
        id,
        username,
      },
      type: actionTypes.LOGIN_SUCCEEDED,
    });
    yield put({
      id,
      type: actionTypes.FETCH_USER_INFORMATION,
    });

    try {
      yield put({ path: {}, type: actionTypes.FETCH_BRAND_DETAILS });
      yield put({ type: constants.FETCH_DEFAULT_CURRENCY });
      yield put({ type: actionTypes.FETCH_API_CONSTANTS });
      const globalKeys = apiConstantsKeys["Global"];
      const { response } = yield call(ApiConstantsService.fetchConstants, globalKeys);
      if (response) {
        yield put({ type: actionTypes.FETCH_API_CONSTANTS_SUCCEEDED, values: response });
        yield put({ type: actionTypes.STARTUP_SUCCEEDED });
      } else {
        yield put({ type: actionTypes.FETCH_API_CONSTANTS_FAILED });
        yield put({ type: actionTypes.STARTUP_FAILED });
      }
    } catch (error) {
      yield put({ type: actionTypes.STARTUP_FAILED });
    } finally {
    }
  } else {
    // yield put({ type: actionTypes.STARTUP_SUCCEEDED });
    // return
  }
}

function* getBrandDetails(action) {
  const { response } = yield call(ApiConstantsService.getBrandDetails, action);
  if (response) {
    yield put({ response, type: actionTypes.FETCH_BRAND_DETAILS_SUCCEEDED });
  } else {
    // console.log("error");
    // yield put({ type: constants.ON_REMOVE_LIABILITY_INDICATOR_FAILED })
    // toastr.add({ message: `Unable to remove Liability Indicators`, type: 'ERROR' })
  }
}

export default function* startupSaga() {
  yield takeLatest(actionTypes.STARTUP, startupGlobal);
  yield takeLatest(actionTypes.STARTUP_APP, startupApp);
  yield takeLatest(actionTypes.FETCH_BRAND_DETAILS, getBrandDetails);
}
