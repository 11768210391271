import actionTypes from "../constants";

const initialState = {
  fetchingOutcomeWagerLimitsGroupFailed: null,
  isFetchingOutcomeWagerLimitsGroup: null,
  limits: [],
  selectedOutcomeId: null,
  selectedWagerLimitsGroupId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_OUTCOME_WAGER_LIMITS_SELECTED_OUTCOME_ID:
      return { ...state, selectedOutcomeId: action.outcomeId };
    case actionTypes.SET_OUTCOME_WAGER_LIMITS_SELECTED_WAGER_LIMITS_GROUP_ID:
      return { ...state, selectedWagerLimitsGroupId: action.wagerLimitsGroupId };
    case actionTypes.FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP:
      return { ...state, fetchingOutcomeWagerLimitsGroupFailed: false, isFetchingOutcomeWagerLimitsGroup: true };
    case actionTypes.FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_SUCCEEDED:
      return { ...state, isFetchingOutcomeWagerLimitsGroup: false, limits: action.limits };
    case actionTypes.FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_FAILED:
      return { ...state, fetchingOutcomeWagerLimitsGroupFailed: true, isFetchingOutcomeWagerLimitsGroup: false };
    default:
      return { ...state };
  }
}
