import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchInitialJackPotWinnerData(action) {
  const url = `bo/jackpots/winners?endDate=${action.details.endDate}&jackpotBetTypeId=${action.details.jackpotBetTypeId}&startDate=${action.details.startDate}&paidoutyn=${action.details.paidOut}`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function onPayoutJackPotWinner(action) {
  const url = `bo/jackpots/winners/payout`;

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_PUT, url, action.details)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
