import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* createNewJackPotItem(action) {
  const { response, xhr } = yield call(API.onCreateNewJackPotItem, action);
  if (response) {
    toastr.add({ message: i18n.t("Jackpot.Jackpot bet is created successfully"), type: "SUCCESS" });
    yield put({ results: response, type: constants.CREATE_NEW_JACKPOT_ITEMS_SUCCESS });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    toastr.add({ message: `${i18n.t("Jackpot.Error")}. ${msg}`, type: "ERROR" });
    yield put({ type: constants.CREATE_NEW_JACKPOT_ITEMS_FAILED });
  }
}

export default function* newJackPotBetData() {
  yield takeLatest(constants.CREATE_NEW_JACKPOT_ITEMS, createNewJackPotItem);
}
