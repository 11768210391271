import actionTypes from "../../constants";

const initialState = {
  fetchingLookupPublishModesFailed: null,
  isFetchingLookupPublishModes: null,
  lookupPublishModes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LOOKUP_PUBLISH_MODES:
      return { ...state, fetchingLookupPublishModesFailed: false, isFetchingLookupPublishModes: true };
    case actionTypes.FETCH_LOOKUP_PUBLISH_MODES_SUCCESS:
      return {
        ...state,
        isFetchingLookupPublishModes: false,
        lookupPublishModes: action.data,
      };
    case actionTypes.FETCH_LOOKUP_PUBLISH_MODES_FAILED:
      return { ...state, fetchingLookupPublishModesFailed: true, isFetchingLookupPublishModes: false };
    default:
      return { ...state };
  }
}
