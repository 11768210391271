const constants = {
  FETCH_RISK_COLUMNS: "UTL::FETCH_RISK_COLUMNS",
  FETCH_RISK_DEFAULT_COLUMNS: "UTL::FETCH_RISK_DEFAULT_COLUMNS",
  FETCH_RISK_DEFAULT_COLUMNS_FAILED: "UTL::FETCH_RISK_DEFAULT_COLUMNS_FAILED",
  FETCH_RISK_DEFAULT_COLUMNS_SUCCEEDED: "UTL::FETCH_RISK_DEFAULT_COLUMNS_SUCCEEDED",
  ON_RISK_MOVE_DOWN: "UTL:ON_RISK_MOVE_DOWN",
  SAVE_RISK_DEFAULT_MANAGEMENT: "UTL::SAVE_RISK_DEFAULT_MANAGEMENT",
  SELECT_RISK_DATA: "UTL::SELECT_RISK_DATA",
  UNDO_RISK_DEFAULT_MANAGEMENT: "UTL::UNDO_RISK_DEFAULT_MANAGEMENT",
  UPDATE_COLUMN: "UTL::UPDATE_COLUMN",
};

export default constants;
