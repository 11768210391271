import { put, call, takeLatest, takeEvery } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import apiConstantsKeys from "constants/apiConstantsKeys";
import actionTypes from "constants/index";
import * as ApiConstantsService from "services/apiConstants";

function* getInitialJackPotBetData(action) {
  const { response } = yield call(API.fetchInitialJackPotBetData, action);
  if (response) {
    yield put({ results: response, type: constants.FETCH_INITIAL_JACKPOTBET_DATA_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_INITIAL_JACKPOTBET_DATA_FAILED });
  }
}

function* onDisableJackpot(action) {
  const { response, xhr } = yield call(API.disableSelectedJackPot, action);
  if (response) {
    yield put({ results: response, type: constants.ON_DISABLE_JACKPOT_SUCCEEDED });
  } else {
    yield put({ type: constants.ON_DISABLE_JACKPOT_FAILED });
  }
}

function* fetchJackPotMarket(action) {
  if (response) {
    yield put({ results: response, type: constants.FETCH_JACKPOT_MARKET_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_JACKPOT_MARKET_FAILED });
  }
}

function* fetchJackPotEvent(action) {
  const { responses, xhrs } = yield call(API.onfetchJackPotMarket, action.marketId);
  const { response, xhr } = yield call(API.onfetchJackPotEvent, action.eventId);
  if (responses && response) {
    const data = {
      response,
      responses,
    };
    yield put({ results: data, type: constants.FETCH_JACKPOT_EVENT_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_JACKPOT_EVENT_FAILED });
  }
}

function* fetchApiConstant(action) {
  const globalKeys = apiConstantsKeys["Customer Manager"];
  const { response } = yield call(ApiConstantsService.fetchConstants, globalKeys);
  if (response) {
    yield put({ type: actionTypes.FETCH_API_CONSTANTS_SUCCEEDED, values: response });
  } else {
    yield put({ type: actionTypes.FETCH_API_CONSTANTS_FAILED });
  }
}

export default function* jackpotBetsaga() {
  yield takeLatest(constants.FETCH_INITIAL_JACKPOTBET_DATA, getInitialJackPotBetData);
  yield takeLatest(constants.ON_DISABLE_JACKPOT, onDisableJackpot);
  yield takeLatest(constants.FETCH_JACKPOT_MARKET, fetchJackPotMarket);
  yield takeLatest(constants.FETCH_JACKPOT_EVENT, fetchJackPotEvent);
  yield takeLatest(actionTypes.FETCH_API_CONSTANTS, fetchApiConstant);
}
