import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

function* fetchSportOtherOptions(action) {
  const { response } = yield call(API.fetchSportOtherOptions, action.code);
  if (response) {
    yield put({ response, type: constants.FETCH_SPORT_OTHERS_OPTIONS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SPORT_OTHERS_OPTIONS_FAILED });
  }
}

export default function* addJackPotSaga() {
  yield takeLatest(constants.FETCH_SPORT_OTHERS_OPTIONS, fetchSportOtherOptions);
}
