import _ from "lodash";
import moment from "moment";

import constants, { betTypes } from "./constants";
import {
  accumRiskConvertStringToInt,
  formatCombinationTypes,
  stringToNumeral,
  placeLimitChange,
  attachUpdatedLimitIndicators,
} from "./helpers";

const initialState = {
  accumulatorRiskList: [],
  arbetDetails: [],
  combiLiabilityIndicators: [],
  currentBetType: "None",
  currentBlockingTypeMap: {},
  defaultCombiLiabilityIndicators: [],
  // Header
enabledAutoRefresh: true,
  
errorMessage: null,
  
eventPathsFilter: {},
  
filterParams: {
    pageIndex: 1,
    pageSize: 100,
    totalPages: null,
  },
  
filters: {
    combinationSize: 2,
    eventPaths: [],
    channelIds: [],
    gameEvents: [],
    blockAny: true,
    greaterCombinationSize: true,
    blockNone: true,
    marketStartTimeAfter: moment().format(),
    betStatus: ["PENDING", "WON", "LOST", "LAST_LEG", "VOID"],
    marketStartTimeBefore: moment().endOf("day").add(1, "days").format(),
    blockSpecific: true,
    payoutRangeMax: null,
    markets: [],
    stakeRangeMax: null,
    outcomes: [],
    stakeRangeMin: null,
    payoutRangeMin: null,
    rankEvents: [],
    settledStatus: "NOT_SETTLED",
    sportCodes: [],
  },
  
hasChangeUnsaved: false,
  
isFetchingConsolidatedAccumulatorArbetDetails: false,
  
isFetchingConsolidatedAccumulatorArbetDetailsFailed: false,
  
isFetchingConsolidatedAccumulatorRiskDetails: false,
  
  isFetchingConsolidatedAccumulatorRiskDetailsFailed: false,

  isFetchingConsolidatedAccumulatorRiskFilter: false,

  isFetchingConsolidatedAccumulatorRiskFilterFailed: false,

  isFetchingEventPathFilters: false,

  isFetchingEventPathFiltersFailed: false,

  isLimitUpdating: false,

  isLimitUpdatingFailed: false,

  isSettingBlockingRule: false,

  isSettingBlockingRuleFailed: false,
  mainPanelSelectedItemId: null,

  originalEventPathsFilter: {},

  prevSportId: null,

  refreshInterval: 2,

  riskDetails: [],

  selectedEventPathFilters: [],

  selectedEventPathFiltersMap: {},
  
  topPanelDetails: {
    combinationRiskTotals: {
      totalStakes: 0,
      totalPayout: 0,
      totalLiability: 0,
      totalBetCount: 0,
      totalCombinations: 0,
    },
    totalStakes: 0,
    singleStake: 0,
    singleBetCount: 0,
    singleBetCountPercentage: 0,
    singleStakePercentage: 0,
    doubleStake: 0,
    doubleBetCount: 0,
    doubleBetCountPercentage: 0,
    doubleStakePercentage: 0,
    trebleStake: 0,
    trebleBetCount: 0,
    trebleBetCountPercentage: 0,
    trebleStakePercentage: 0,
    fourPlusStake: 0,
    fourPlusBetCount: 0,
    fourPlusBetCountPercentage: 0,
    fourPlusStakePercentage: 0,
  },

  
selectedRiskDetail: {},
  // Modals
showBlockingRuleModal: false,
  showFilterModal: false,
  sportAndEventPathFilterResetData: {
    eventPathsFilter: {},
    isFetchingEventPathFilters: false,

    isFetchingEventPathFiltersFailed: false,
    originalEventPathsFilter: {},
    prevSportId: null,
    selectedEventPathFilters: [],
    selectedEventPathFiltersMap: {},
    sportFilterType: "all",

    sportFilters: {},
    sportId: null,
    toBeSelectedEventPathFilters: [],
    toBeUnassignEventPathFilters: [],
  },
  sportDesc: null,
  sportFilterType: "all",
  sportFilters: {},

  sportId: null,
  toBeSelectedEventPathFilters: [],
  toBeUnassignEventPathFilters: [],
  unsavedLimitChange: [],

  userFlags: [],
};

const combinationRiskManager = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          pageIndex: action.filterParams.pageIndex,
          pageSize: action.filterParams.pageSize,
        },
        isFetchingConsolidatedAccumulatorRiskFilter: true,
        isFetchingConsolidatedAccumulatorRiskFilterFailed: false,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_SUCCEEDED:
      return {
        ...state,
        accumulatorRiskList: [
          ...stringToNumeral(action.accumulatorRiskList, [
            "averagePrice",
            "liability",
            "potentialPayout",
            "totalStakes",
          ]),
        ],
        filterParams: {
          ...state.filterParams,
          totalPages: action.totalPages,
        },
        // accumulatorRiskList: action.accumulatorRiskList,
        isFetchingConsolidatedAccumulatorRiskFilter: false,
        isFetchingConsolidatedAccumulatorRiskFilterFailed: false,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_FAILED:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorRiskFilter: false,
        isFetchingConsolidatedAccumulatorRiskFilterFailed: true,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorRiskDetails: true,
        isFetchingConsolidatedAccumulatorRiskDetailsFailed: false,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_SUCCEEDED:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorRiskDetails: false,
        isFetchingConsolidatedAccumulatorRiskDetailsFailed: false,
        riskDetails: action.riskDetails,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_FAILED:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorRiskDetails: false,
        isFetchingConsolidatedAccumulatorRiskDetailsFailed: true,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorArbetDetails: true,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: false,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED:
      return {
        ...state,
        arbetDetails: action.arbetDetails,
        isFetchingConsolidatedAccumulatorArbetDetails: false,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: false,
      };
    case constants.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorArbetDetails: false,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: true,
      };
    case constants.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorArbetDetails: false,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: false,
      };
    case constants.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED:
      return {
        ...state,
        arbetDetails: action.arbetDetails,
        isFetchingConsolidatedAccumulatorArbetDetails: false,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: false,
      };
    case constants.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED:
      return {
        ...state,
        isFetchingConsolidatedAccumulatorArbetDetails: false,
        isFetchingConsolidatedAccumulatorArbetDetailsFailed: true,
      };
    case constants.UPDATE_COMBINATION_LIABILITY_INDICATOR:
      return {
        ...state,
        combiLiabilityIndicators: [
          ...formatCombinationTypes(action.indicators, {
            liability: state.selectedRiskDetail.liability,
            payout: state.selectedRiskDetail.potentialPayout,
            stake: state.selectedRiskDetail.totalStakes,
          }),
        ],
        defaultCombiLiabilityIndicators: action.indicators,
        isLimitUpdating: false,
        isLimitUpdatingFailed: false,
      };
    case constants.INITIALIZE_DEFAULT:
      return {
        ...state,
        arbetDetails: [],
        combiLiabilityIndicators: [],
        currentBetType: "None",
        defaultCombiLiabilityIndicators: [],
        mainPanelSelectedItemId: null,
        selectedRiskDetail: {},
      };
    case constants.SET_MAIN_PANEL_SELECTED_ITEM:
      return {
        ...state,
        mainPanelSelectedItemId: action.itemId,
        selectedRiskDetail: action.riskDetail,
      };
    case constants.REMOVE_COMBINATION_LIABILITY_INDICATOR:
      return {
        ...state,
        combiLiabilityIndicators: [],
      };
    case constants.SORT_ACCUMULATOR_DATA:
      // TODO: implement custom data sorting
      return { ...state };
    case constants.SET_TOP_PANEL_DETAILS:
      return {
        ...state,
        topPanelDetails: {
          ...state.topPanelDetails,
          ...action.topPanelDetails,
        },
      };
    case constants.DISABLE_AUTOREFRESH:
      return {
        ...state,
        enabledAutoRefresh: false,
        refreshInterval: 0,
      };
    case constants.SET_AUTOREFRESH:
      return {
        ...state,
        enabledAutoRefresh: true,
        refreshInterval: action.value,
      };
    case constants.SET_BET_TYPE:
      return {
        ...state,
        currentBetType: betTypes[action.betType],
      };
    case constants.SET_BLOCKING_TYPE_MAP:
      return {
        ...state,
        currentBlockingTypeMap: action.blockingTypeMap,
      };
    case constants.TOGGLE_BLOCKING_RULE_MODAL:
      return {
        ...state,
        showBlockingRuleModal: action.value,
      };
    case constants.TOGGLE_FILTER_MODAL:
      return {
        ...state,
        showFilterModal: !state.showFilterModal,
      };
    case constants.SET_FLAG_ITEM_MODIFIED:
      return {
        ...state,
        hasChangeUnsaved: true,
      };
    case constants.REMOVE_FLAG_ITEM_MODIFIED:
      return {
        ...state,
        hasChangeUnsaved: false,
      };
    case constants.SET_LIMIT_CHANGE:
      const unsaved = [
        ...placeLimitChange(
          state.defaultCombiLiabilityIndicators,
          state.unsavedLimitChange,
          action.indicatorType,
          action.actionType,
          action.value,
        ),
      ];

      return {
        ...state,
        hasChangeUnsaved: unsaved.length > 0,
        unsavedLimitChange: unsaved,
      };
    case constants.RESET_LIMIT_CHANGE:
      return {
        ...state,
        hasChangeUnsaved: false,
        unsavedLimitChange: [],
      };
    case constants.FETCH_PROFILE_FLAGS_SUCCEEDED:
      return {
        ...state,
        userFlags: action.flags,
      };
    case constants.RESET_PROFILE_FLAGS:
      return {
        ...state,
        userFlags: [],
      };

    case constants.SET_SPORT_FILTER_TYPE:
      let filters = { ...state.filters };
      let sportFilters = {};
      filters.sportCodes = [];

      return {
        ...state,
        filters,
        sportFilterType: action.filterType,
        sportFilters,
      };

    case constants.SET_SPORT_FILTER:
      filters = { ...state.filters };
      sportFilters = { ...state.sportFilters };

      if (filters.sportCodes.indexOf(action.sportId) === -1) {
        filters.sportCodes.push(action.sportId);
      } else {
        filters.sportCodes.splice(filters.sportCodes.indexOf(action.sportId), 1);
      }

      sportFilters[action.sportId] = action.isChecked;

      return {
        ...state,
        filters,
        sportFilters,
      };

    case constants.FETCH_EVENT_PATHS_FILTER:
      const origEPF = { ...state.originalEventPathsFilter };

      if (_.has(origEPF[action.sportId], action.itemId)) {
        // if (action.itemId !== action.sportId) {
        origEPF[action.sportId][action.itemId].isLoading = action.isOpen;
        origEPF[action.sportId][action.itemId].isOpen = action.isOpen;
        // }
        // else {
        //   origEPF[action.sportId][action.itemId].isLoading = true
        //   origEPF[action.sportId][action.itemId].isOpen = true
        // }
      } else {
        origEPF[action.sportId] = {
          isLoading: true,
        };
      }

      return {
        ...state,
        isFetchingEventPathFilters: true,
        isFetchingEventPathFiltersFailed: false,
        originalEventPathsFilter: origEPF,
        prevSportId: state.sportId,
        sportId: action.sportId,
        toBeSelectedEventPathFilters: [],
      };

    case constants.SET_SPORT_DESC:
      return {
        ...state,
        sportDesc: action.sportDesc,
      };

    case constants.RESET_EVENT_PATHS_FILTER:
      return {
        ...state,
        isFetchingEventPathFilters: true,
        isFetchingEventPathFiltersFailed: false,
        originalEventPathsFilter: [],
        sportDesc: "",
        toBeSelectedEventPathFilters: [],
      };

    case constants.FETCH_EVENT_PATHS_FILTER_SUCCEEDED:
      const originalEventPathsFilter = { ...state.originalEventPathsFilter };
      const eventPathsFilter = { ...state.eventPathsFilter };
      let toBePushAsToBeAssigned = [];
      const itemId = action.itemId;
      if (_.has(originalEventPathsFilter[action.sportId], action.itemId)) {
        originalEventPathsFilter[action.sportId][action.itemId].isLoading = false;
        originalEventPathsFilter[action.sportId][action.itemId].isOpen = action.isOpen;
        originalEventPathsFilter[action.sportId][action.itemId].children = action.response.map((ep) => ep.id);
        _.each(action.response, (ep) => {
          if (_.has(originalEventPathsFilter[action.sportId], ep.id)) {
            const data = originalEventPathsFilter[action.sportId][ep.id];
            // originalEventPathsFilter[action.sportId][ep.id] = {}
            ep.parentId = itemId;
            ep.isLoading = data.isLoading;
            ep.isOpen = data.isOpen;
            ep.isChecked = data.isChecked;
            ep.isUnassignChecked = data.isUnassignChecked;
            originalEventPathsFilter[action.sportId][ep.id] = ep;
          } else {
            _.each(action.response, (ep) => {
              originalEventPathsFilter[action.sportId][ep.id] = {};
              ep.parentId = itemId;
              ep.isLoading = false;
              ep.isOpen = false;
              ep.isChecked = false;
              ep.isUnassignChecked = false;
              originalEventPathsFilter[action.sportId][ep.id] = ep;
            });
          }
        });
      } else {
        _.each(action.response, (ep) => {
          originalEventPathsFilter[action.sportId][ep.id] = {};
          ep.parentId = itemId;
          ep.isLoading = false;
          ep.isOpen = false;
          ep.isChecked = false;
          ep.isUnassignChecked = false;
          originalEventPathsFilter[action.sportId][ep.id] = ep;
        });
      }

      if (_.keys(originalEventPathsFilter[action.sportId]).length) {
        toBePushAsToBeAssigned = _.filter(originalEventPathsFilter[action.sportId], { isChecked: true }).map((ep) => {
          const type = ep.type.toLowerCase();
          const id =
            type === "eventpath"
              ? `ep${ep.id}`
              : type === "gameevent"
              ? `ge${ep.id}`
              : type === "rankevent"
              ? `re${ep.id}`
              : type === "market"
              ? `m${ep.id}`
              : `o${ep.id}`;

          return id;
        });
      }

      return {
        ...state,
        eventPathsFilter: state.prevSportId !== action.sportId ? action.response.map((ep) => ep.id) : eventPathsFilter,
        isFetchingEventPathFilters: false,
        isFetchingEventPathFiltersFailed: false,
        originalEventPathsFilter,
        toBeSelectedEventPathFilters: toBePushAsToBeAssigned,
      };
    case constants.FETCH_EVENT_PATHS_FILTER_FAILED:
      return {
        ...state,
        isFetchingEventPathFilters: false,
        isFetchingEventPathFiltersFailed: true,
        sportDesc: "",
      };

    case constants.TOGGLE_ASSIGN_EVENT_PATH_FILTER:
      const iId = action.item.id;
      const type = action.itemType;
      const id =
        type === "eventpath"
          ? `ep${iId}`
          : type === "gameevent"
          ? `ge${iId}`
          : type === "rankevent"
          ? `re${iId}`
          : type === "market"
          ? `m${iId}`
          : `o${iId}`;
      const origEventPathsFilter = { ...state.originalEventPathsFilter };
      const toBeSelectedFilter = [...state.toBeSelectedEventPathFilters];
      const selectedEventPathFiltersMap = { ...state.selectedEventPathFiltersMap };
      selectedEventPathFiltersMap[id] = action.item;
      origEventPathsFilter[action.sportId][iId].isChecked = action.isChecked;

      if (toBeSelectedFilter.indexOf(id) === -1 && action.isChecked) {
        toBeSelectedFilter.push(id);
      } else {
        toBeSelectedFilter.splice(toBeSelectedFilter.indexOf(id), 1);
      }

      return {
        ...state,
        originalEventPathsFilter: origEventPathsFilter,
        selectedEventPathFiltersMap,
        toBeSelectedEventPathFilters: toBeSelectedFilter,
      };

    case constants.SET_ASSIGN_EVENT_PATH_FILTER:
      const toBeSelectedEventPathFilters = [...state.toBeSelectedEventPathFilters];
      const selectedEventPathFilters = [...state.selectedEventPathFilters];
      const selectedFilterMap = { ...state.selectedEventPathFiltersMap };
      const newFilters = { ...state.filters };

      _.each(toBeSelectedEventPathFilters, (id) => {
        const toBeAssign = selectedFilterMap[id];

        if (selectedEventPathFilters.indexOf(id) === -1) {
          selectedEventPathFilters.push(id);
        }

        if (toBeAssign.type === "EVENTPATH" && newFilters.eventPaths.indexOf(toBeAssign.id) === -1) {
          newFilters.eventPaths.push(toBeAssign.id);
        } else if (toBeAssign.type === "RANKEVENT" && newFilters.rankEvents.indexOf(toBeAssign.id) === -1) {
          newFilters.rankEvents.push(toBeAssign.id);
        } else if (toBeAssign.type === "GAMEEVENT" && newFilters.gameEvents.indexOf(toBeAssign.id) === -1) {
          newFilters.gameEvents.push(toBeAssign.id);
        } else if (toBeAssign.type === "MARKET" && newFilters.markets.indexOf(toBeAssign.id) === -1) {
          newFilters.markets.push(toBeAssign.id);
        } else if (toBeAssign.type === "OUTCOME" && newFilters.outcomes.indexOf(toBeAssign.id) === -1) {
          newFilters.outcomes.push(toBeAssign.id);
        }
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          ...newFilters,
        },
        selectedEventPathFilters,
        toBeSelectedEventPathFilters,
      };
    case constants.TOGGLE_UNASSIGN_EVENT_PATH_FILTER:
      const selectedEPFMaps = { ...state.selectedEventPathFiltersMap };
      const toBeUnassignId = action.item.id;
      const toBeUnassignType = action.itemType;
      const formattedUnassignId =
        toBeUnassignType === "eventpath"
          ? `ep${toBeUnassignId}`
          : toBeUnassignType === "gameevent"
          ? `ge${toBeUnassignId}`
          : toBeUnassignType === "rankevent"
          ? `re${toBeUnassignId}`
          : toBeUnassignType === "market"
          ? `m${toBeUnassignId}`
          : `o${toBeUnassignId}`;
      const toBeUnassignFilter = [...state.toBeUnassignEventPathFilters];
      selectedEPFMaps[formattedUnassignId].isUnassignChecked = action.isChecked;
      if (toBeUnassignFilter.indexOf(formattedUnassignId) === -1 && action.isChecked) {
        toBeUnassignFilter.push(formattedUnassignId);
      } else {
        toBeUnassignFilter.splice(toBeUnassignFilter.indexOf(formattedUnassignId), 1);
      }

      return {
        ...state,
        toBeUnassignEventPathFilters: toBeUnassignFilter,
      };

    case constants.SET_UNASSIGN_EVENT_PATH_FILTER:
      const toBeUnassignEventPathFilters = [...state.toBeUnassignEventPathFilters];
      const selectedEPF = [...state.selectedEventPathFilters];
      const assignFilterMap = { ...state.selectedEventPathFiltersMap };
      const unassignFilters = { ...state.filters };
      _.each(toBeUnassignEventPathFilters, (id) => {
        const toBeUnassign = assignFilterMap[id];
        if (selectedEPF.indexOf(id) !== -1) {
          selectedEPF.splice(selectedEPF.indexOf(id), 1);
          assignFilterMap[id].isUnassignChecked = false;
        }

        if (toBeUnassign.type === "EVENTPATH" && unassignFilters.eventPaths.indexOf(toBeUnassign.id) !== -1) {
          unassignFilters.eventPaths.splice(unassignFilters.eventPaths.indexOf(toBeUnassign.id), 1);
        } else if (toBeUnassign.type === "RANKEVENT" && unassignFilters.rankEvents.indexOf(toBeUnassign.id) !== -1) {
          unassignFilters.rankEvents.splice(unassignFilters.rankEvents.indexOf(toBeUnassign.id), 1);
        } else if (toBeUnassign.type === "GAMEEVENT" && unassignFilters.gameEvents.indexOf(toBeUnassign.id) !== -1) {
          unassignFilters.gameEvents.splice(unassignFilters.gameEvents.indexOf(toBeUnassign.id), 1);
        } else if (toBeUnassign.type === "MARKET" && unassignFilters.markets.indexOf(toBeUnassign.id) !== -1) {
          unassignFilters.markets.splice(unassignFilters.markets.indexOf(toBeUnassign.id), 1);
        } else if (toBeUnassign.type === "OUTCOME" && unassignFilters.outcomes.indexOf(toBeUnassign.id) !== -1) {
          unassignFilters.outcomes.splice(unassignFilters.outcomes.indexOf(toBeUnassign.id), 1);
        }
      });

      _.each(toBeUnassignEventPathFilters, (id) => {
        toBeUnassignEventPathFilters.splice(id, 1);
      });

      return {
        ...state,
        filters: unassignFilters,
        selectedEventPathFilters: selectedEPF,
        selectedEventPathFiltersMap: assignFilterMap,
        toBeUnassignEventPathFilters,
      };

    case constants.TOGGLE_EVENT_PATHS_FILTER:
      const originalEPF = { ...state.originalEventPathsFilter };
      originalEPF[action.sportId][action.itemId].isOpen = action.isOpen;

      return {
        ...state,
        originalEventPathsFilter: originalEPF,
      };

    case constants.RESET_SPORT_AND_EVENTPATH_FILTER:
      return {
        ...state,
        ...state.sportAndEventPathFilterResetData,
      };

    case constants.PUT_COMBINATION_LIABILITY_INDICATOR:
      return {
        ...state,
        isLimitUpdating: true,
        isLimitUpdatingFailed: false,
      };
    case constants.PUT_COMBINATION_LIABILITY_INDICATOR_SUCCEEDED:
      return {
        ...state,
        accumulatorRiskList: [
          ...attachUpdatedLimitIndicators(state.accumulatorRiskList, state.mainPanelSelectedItemId, action.indicators),
        ],
      };
    case constants.PUT_COMBINATION_LIABILITY_INDICATOR_FAILED:
      return {
        ...state,
        isLimitUpdating: false,
        isLimitUpdatingFailed: true,
      };
    case constants.SET_BLOCKING_RULE:
      return {
        ...state,
        isSettingBlockingRule: true,
        isSettingBlockingRuleFailed: false,
      };
    case constants.SET_BLOCKING_RULE_SUCCEEDED:
      return {
        ...state,
        isSettingBlockingRule: false,
        isSettingBlockingRuleFailed: false,
        showBlockingRuleModal: false,
      };
    case constants.SET_BLOCKING_RULE_FAILED:
      return {
        ...state,
        isSettingBlockingRule: false,
        isSettingBlockingRuleFailed: true,
      };
    case constants.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...action.filters,
        },
        sportAndEventPathFilterResetData: {
          eventPathsFilter: state.eventPathsFilter,
          isFetchingEventPathFilters: state.isFetchingEventPathFilters,
          isFetchingEventPathFiltersFailed: state.isFetchingEventPathFiltersFailed,
          originalEventPathsFilter: state.originalEventPathsFilter,
          prevSportId: state.prevSportId,
          selectedEventPathFilters: state.selectedEventPathFilters,
          selectedEventPathFiltersMap: state.selectedEventPathFiltersMap,
          sportFilterType: state.sportFilterType,
          sportFilters: state.sportFilters,
          sportId: state.sportId,
          toBeSelectedEventPathFilters: state.toBeSelectedEventPathFilters,
          toBeUnassignEventPathFilters: state.toBeUnassignEventPathFilters,
        },
      };
    case constants.SHOULD_ITEM_RESET:
      const { mainPanelSelectedItemId: selectedId } = state;
      const currentSelectedItem = action.accumulatorRiskList.find((item) => item.accumulatorRiskId === selectedId);
      if (!currentSelectedItem) {
        return {
          ...state,
          arbetDetails: [],
          combiLiabilityIndicators: [],
          currentBetType: "None",
          currentBlockingTypeMap: {},
          defaultCombiLiabilityIndicators: [],
          mainPanelSelectedItemId: null,
          riskDetails: [],
          selectedRiskDetail: {},
        };
      }

      return {
        ...state,
      };

    case constants.SET_DEFAULT_CHANNELS:
      return {
        ...state,
        filters: {
          ...state.filters,
          channelIds: action.channels,
        },
      };

    case constants.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filterParams,
        },
      };

    default:
      return { ...state };
  }
};

export default combinationRiskManager;
