import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchUtilitiesXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

export function fetchMessageTriggers() {
  const url = "/bo/staticdata/messageTriggers";

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchUtilityChannels() {
  const url = "/bo/channels";

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchChannelPriorityTrigger() {
  const url = "/bo/channelPriorityTrigger";

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function saveChannelPriority(priority) {
  const url = `/bo/channelPriorityTrigger/save`;

  return performHttpCall(fetchUtilitiesXhr, httpMethods.HTTP_PUT, url, priority)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
