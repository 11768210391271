const eventCreatorPages = {
  BET_RESTRICTIONS: "BET_RESTRICTIONS",
  EVENT_PATH: "EVENT_PATH",
  GET_EVENT_CREATOR_PAGE: "GET_EVENT_CREATOR_PAGE",

  OPPONENTS: "OPPONENTS",
  SET_EVENT_CREATOR_PAGE: "SET_EVENT_CREATOR_PAGE",
};

export default eventCreatorPages;
