const constants = {
  APPLICATION_ID: 7,

  DELETE_RESTRICTION_FOR_GROUP: "DELETE_RESTRICTION_FOR_GROUP",
  DELETE_RESTRICTION_FOR_GROUP_FAILED: "DELETE_RESTRICTION_FOR_GROUP_FAILED",

  FETCH_OPERATOR_GROUPS: "OM::FETCH_OPERATOR_GROUPS",
  DELETE_RESTRICTION_FOR_GROUP_SUCCEEDED: "DELETE_RESTRICTION_FOR_GROUP_SUCCEEDED",
  FETCH_OPERATOR_GROUPS_FAILED: "OM::FETCH_OPERATOR_GROUPS_FAILED",

  FETCH_OPERATOR_GROUPS_SUCCEEDED: "OM::FETCH_OPERATOR_GROUPS_SUCCEEDED",

  FORM_CHANGED: "FORM_CHANGED",

  FORM_ERROR: "FORM_ERROR",

  FORM_LOADED: "FORM_LOADED",

  FORM_LOADING: "FORM_LOADING",

  FORM_MODE_EDIT: "EDIT",

  FORM_MODE_NEW: "NEW",
  
  
  
  
  
  
  
  
  
  FORM_PERMISSION_SAVE_ERROR: "FORM_PERMISSION_SAVE_ERROR",
  








FORM_PERMISSION_SAVE_SUCCESS: "FORM_PERMISSION_SAVE_SUCCESS",
  








FORM_READY: "FORM_READY",
  








FORM_SAVE_ERROR: "FORM_ERROR",
  








FORM_SAVE_SUCCESS: "FORM_SUCCESS",
  








FORM_SAVING: "FORM_SAVING",
  








HIDE_OPERATOR_GROUP_MODAL: "HIDE_OPERATOR_GROUP_MODAL",
  








HIDE_OPERATOR_MODAL: "HIDE_OPERATOR_MODAL",

  








OPERATOR: "OPERATOR",
  








OPERATORGROUP: "OPERATORGROUP",

  








SHOW_OPERATOR_GROUP_MODAL: "SHOW_OPERATOR_GROUP_MODAL",
  








SHOW_OPERATOR_MODAL: "SHOW_OPERATOR_MODAL",
  








STATUS_IDS: {
    CLOSED: { desc: "Closed", value: "1" },
    OPEN: { desc: "Open", value: "2" },
    OPEN_NOT_POOL: { desc: "Open Not Pool", value: "5" },
    SUSPENDED: { desc: "Suspended", value: "3" },
    SUSPENDED_EXCEPT_LOGIN: { desc: "Suspended Except Login", value: "6" },
    SUSPENDED_PASSWORD_FAILURE: {
      desc: "Suspended Password Failure",
      value: "4",
    },
  },
  








STATUS_ID_LIST: {
    0: "All",
    1: "Closed",
    2: "Open",
    3: "Suspended",
    4: "Suspended Password Failure",
    5: "Open Not Pool",
    6: "Suspended Except Login",
  },
  // FETCH_APPLICATIONS : 'OM::FETCH_APPLICATIONS',
// FETCH_APPLICATIONS_SUCCEEDED : 'OM::FETCH_APPLICATIONS_SUCCEEDED',
// FETCH_APPLICATIONS_FAILED : 'OM::FETCH_APPLICATIONS_FAILED',
// FETCH_ACTIONS : 'OM::FETCH_ACTIONS',
// FETCH_ACTIONS_SUCCEEDED : 'OM::FETCH_ACTIONS_SUCCEEDED',
// FETCH_ACTIONS_FAILED : 'OM::FETCH_ACTIONS_FAILED',
// FETCH_REPORTS : 'OM::FETCH_REPORTS',
// FETCH_REPORTS_SUCCEEDED : 'OM::FETCH_REPORTS_SUCCEEDED',
// FETCH_REPORTS_FAILED : 'OM::FETCH_REPORTS_FAILED',
SUBMIT_OPERATOR_DATA: "OM::SUBMIT_OPERATOR_DATA",
  SUBMIT_OPERATOR_DATA_FAILED: "OM::SUBMIT_OPERATOR_DATA_FAILED",
  SUBMIT_OPERATOR_DATA_SUCCEEDED: "OM::SUBMIT_OPERATOR_DATA_SUCCEEDED",

  permissionsCode: {
    CHANGE_NON_ADMIN_USER_PASSWORD: 818,
    CHANGE_USER_PASSWORD: 1505,
    CHANGE_USER_STATUS: 1506,
    CREATE_GROUP: 817,
    CREATE_USER: 816,
    DELETE: 823,
    INSERT: 822,
    MOVE_USER_GROUP: 819,
    UPDATE: 821,
  },
};

export default constants;
