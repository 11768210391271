import customerManagerConfig from "../../configs/customerManagerConfig";
import actionTypes from "../../constants";

const initialState = {
  ...customerManagerConfig,
  accountAdjust: false,
  accountBalance: {
    accountBalance: 0,
  },
  accountBetStatistics: null,
  accountDetails: null,
  accountId: null,
  accountMoreDetails: null,
  accountPermissions: null,
  accountStatistics: null,

  accountStatus: null,
  accountStatusLoading: false,

  accountTransferDetails: null,
  callStatus: null,
  doPromoRewardRefreshAfterExpire: false,
  dragonPayData: null,
  errorMessage: null,
  fxRate: null,
  initializingCall: false,
  isAdvanceSearch: false,
  isCreateAccountView: false,
  isFetchingAccountDetails: false,
  isFetchingData: false,
  isFieldsLocked: true,
  isRefreshingBalance: false,

  isRequestFailed: false,
  isUpdatingAccount: false,
  modalShowAdjustments: false,
  operatorError: null,
  searchAccountTransferResult: [],

  paymentProviders: null,
  searchLoading: false,

  pinStatus: null,
  searchResult: [],

  promoRewards: [],

  promoRewardsLoading: false,
  recentAccounts: [],
  status: null,
};

const customerManagerAccount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ACCOUNT:
      return {
        ...state,
        searchLoading: true,
        searchResult: [],
        status: null,
      };
    case actionTypes.SEARCH_ACCOUNT_SUCCEEDED:
    case actionTypes.ADVANCE_SEARCH_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        searchLoading: false,
        searchResult: action.searchResult,
        status: action.status,
      };
    case actionTypes.FETCH_ACCOUNT_DETAILS:
      return {
        ...state,
        isFetchingAccountDetails: true,
        searchLoading: true,
      };
    case actionTypes.ADVANCE_SEARCH_ACCOUNT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isAdvanceSearch: true,
        isFetchingAccountDetails: false,
        searchLoading: false,
        searchResult: [],
        status: null,
      };
    case actionTypes.SEARCH_ACCOUNT_FAILED:
    case actionTypes.FETCH_ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isAdvanceSearch: false,
        isFetchingAccountDetails: false,
        searchLoading: false,
        searchResult: [],
        status: null,
      };
    case actionTypes.END_CALL_SUCCEEDED:
    case actionTypes.SEARCH_ACCOUNT_CLEAR:
      return {
        ...state,
        accountDetails: null,
        accountId: null,
        errorMessage: null,
        isFieldsLocked: true,
        pinStatus: null,
        searchResult: [],
        status: null,
      };
    case actionTypes.FETCH_ACCOUNT_DETAILS_SUCCEEDED:
      return {
        ...state,
        accountDetails: action.result,
        accountId: action.result.id,
        isFetchingAccountDetails: false,
        searchLoading: false,
        status: action.status,
      };
    case actionTypes.FETCH_MAIN_OPERATOR_PERMISSIONS_SUCCEEDED: // TODO: remove. temp fix for permissions
    case actionTypes.FETCH_ACCOUNT_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        accountPermissions: action.permissions,
      };

    case actionTypes.OPEN_MODAL_CREATE_ACCOUNT:
    case actionTypes.CLOSE_MODAL_CREATE_ACCOUNT:
      return {
        ...state,
        isCreateAccountView: action.show,
      };

    case actionTypes.OPEN_ADVANCE_ACCOUNT_SEARCH:
    case actionTypes.CLOSE_ADVANCE_ACCOUNT_SEARCH:
      return {
        ...state,
        isAdvanceSearch: action.show,
      };
    case actionTypes.VALIDATE_PIN:
      return {
        ...state,
        pinStatus: "LOADING",
      };
    case actionTypes.VALIDATE_PIN_SUCCEEDED:
    case actionTypes.CLEAR_PIN:
      return {
        ...state,
        pinStatus: action.pinStatus,
      };
    case actionTypes.INITIALIZE_CALL:
      return {
        ...state,
        initializingCall: true,
      };
    case actionTypes.INITIALIZE_CALL_SUCCEEDED:
      return {
        ...state,
        callStatus: action.callStatus,
        initializingCall: false,
        status: action.status,
      };
    case actionTypes.INITIALIZE_CALL_FAILED:
      return {
        ...state,
        initializingCall: false,
      };
    case actionTypes.FETCH_RECENT_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        recentAccounts: action.recentAccounts,
      };
    case actionTypes.FETCH_ACCOUNT_BALANCE:
      return {
        ...state,
        isRefreshingBalance: true,
      };
    case actionTypes.FETCH_ACCOUNT_BALANCE_SUCCEEDED:
      return {
        ...state,
        accountBalance: action.result,
        isRefreshingBalance: false,
      };
    case actionTypes.FETCH_ACCOUNT_BALANCE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isRefreshingBalance: false,
      };

    case actionTypes.ADJUST_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        accountAdjust: action.accountAdjust,
        modalShowAdjustments: false,
      };
    case actionTypes.OPEN_MODAL_ACCOUNT_ADJUSTMENT:
    case actionTypes.CLOSE_MODAL_ACCOUNT_ADJUSTMENT:
      return {
        ...state,
        accountAdjust: false,
        modalShowAdjustments: action.show,
      };
    case actionTypes.ADJUST_ACCOUNT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case actionTypes.ADVANCE_SEARCH_ACCOUNT_TRANSFER_SUCCEEDED:
      return {
        ...state,
        searchAccountTransferResult: action.searchResult,
      };

    case actionTypes.SELECT_ACCOUNT_TO_TRANSFER:
    case actionTypes.CLEAR_ACCOUNT_TO_TRANSFER:
      return {
        ...state,
        accountTransferDetails: action.data,
      };
    case actionTypes.ACCOUNT_LOCK:
      return {
        ...state,
        isFieldsLocked: true,
      };
    case actionTypes.ACCOUNT_UNLOCK:
      return {
        ...state,
        isFieldsLocked: false,
      };
    case actionTypes.FETCH_BETSTATISTICS_SUCCEEDED:
      return {
        ...state,
        accountBetStatistics: action.accountBetStatistics,
      };
    case actionTypes.FETCH_ACCOUNTSTATS_SUCCEEDED:
      return {
        ...state,
        accountStatistics: action.accountStatistics,
      };
    case actionTypes.FETCH_ACCOUNTSTATUS:
      return {
        ...state,
        accountStatus: null,
        accountStatusLoading: true,
      };
    case actionTypes.FETCH_ACCOUNTSTATUS_SUCCEEDED:
    case actionTypes.UPDATE_ACCOUNTSTATUS_SUCCEEDED:
      return {
        ...state,
        accountStatus: action.accountStatus,
        accountStatusLoading: false,
      };
    case actionTypes.FETCH_ACCOUNTSTATUS_FAILED:
      return {
        ...state,
        accountStatusLoading: false,
      };
    case actionTypes.UPDATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        accountDetails: action.result,
        isUpdatingAccount: false,
      };

    case actionTypes.FETCH_DRAGON_PAY_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        dragonPayData: action.result,
      };
    case actionTypes.CREATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        accountDetails: action.result,
        accountId: action.result.id,
      };
    case actionTypes.FETCH_FX_RATE_SUCCEEDED:
      return {
        ...state,
        fxRate: action.fxRate,
      };
    case actionTypes.FETCH_PROMO_REWARDS:
      return {
        ...state,
        doPromoRewardRefreshAfterExpire: false,
        promoRewards: [],
        promoRewardsLoading: true,
      };
    case actionTypes.FETCH_PROMO_REWARDS_FAILED:
      return {
        ...state,
        promoRewards: [],
        promoRewardsLoading: false,
      };
    case actionTypes.FETCH_PROMO_REWARDS_SUCCEEDED:
      return {
        ...state,
        promoRewards: action.result,
        promoRewardsLoading: false,
      };
    case actionTypes.FETCH_ACCOUNT_MORE_DETAILS_SUCCEEDED:
    case actionTypes.UPDATE_ACCOUNT_MORE_DETAILS_SUCCEEDED:
      return {
        ...state,
        accountMoreDetails: action.result,
      };
    case actionTypes.FETCH_PAYMENT_PROVIDERS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: action.result,
      };
    case actionTypes.CM_OPERATOR_LOGIN_SUCCEEDED:
      return {
        ...state,
        operatorError: null,
      };
    case actionTypes.CM_OPERATOR_LOGIN_FAILED:
      return {
        ...state,
        operatorError: action.errorMessage,
      };
    case actionTypes.CM_OPERATOR_CLEAR_ERROR:
      return {
        ...state,
        operatorError: null,
      };
    case actionTypes.UPDATE_ACCOUNT:
      return {
        ...state,
        isUpdatingAccount: true,
      };
    case actionTypes.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        isUpdatingAccount: false,
      };
    case actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS:
      return {
        ...state,
        isFetchingAccountDetails: true,
      };
    case actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS_SUCCEEDED:
    case actionTypes.FETCH_ACCOUNTDETAILS_SETTINGS_FAILED:
      return {
        ...state,
        isFetchingAccountDetails: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    case actionTypes.CM_DO_EXPIRE_PROMOTION_REWARD_SUCCESS:
      return {
        ...state,
        doPromoRewardRefreshAfterExpire: true,
      };
    default:
      return { ...state };
  }
};

export default customerManagerAccount;
