import constants from "../constants";

const initialState = {
  noteDetails: {},
  noteid: null,
  notes: [],
  showNotes: false,
};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case constants.RECEIVE_NOTE:
      return {
        ...state,
        notes: [action.note, ...state.notes],
      };
    case constants.SHOW_NOTES:
      return {
        ...state,
        details: action.details,
        noteid: action.id,
        showNotes: true,
      };
    case constants.RESET_NOTES:
      return {
        ...initialState,
      };
    case constants.RECEIVE_NOTES:
      return {
        ...state,
        notes: [...action.notes],
      };
    case constants.TOGGLE_NOTES:
      return {
        ...state,
        showNotes: !state.showNotes,
      };
    default:
      return state;
  }
};

export default notes;
