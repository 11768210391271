export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_OPERATOR_SUCCEEDED = "OF:UPDATE_OPERATOR_SUCCEEDED";
export const UPDATE_OPERATOR_FAILED = "UPDATE_GROUP_FAILED";
export const SET_OPERATOR_FORM = "SET_OPERATOR_FORM";
export const SET_ORIGINAL_FORM = "SET_ORIGINAL_FORM";
export const SET_RENDERED_VALUES = "SET_RENDERED_VALUES";
export const CLEAR_OPERATOR_FORM = "CLEAR_OPERATOR_FORM";
export const RESET_OPERATOR_FORM_MODIFIED = "RESET_OPERATOR_FORM_MODIFIED";
export const RESET_MODIFIED = "RESET_MODIFIED";
export const UPDATE_PASSWORD = "OM::CHANGE_PASSWORD";
export const UPDATE_PASSWORD_SUCCEEDED = "OM::UPDATE_PASSWORD_SUCCEEDED";
export const UPDATE_PASSWORD_FAILED = "OM::UPDATE_PASSWORD_FAILED";
export const RESET_NEW_OPERATOR_PASSWORD = "OM:RESET_NEW_OPERATOR_PASSWORD";
export const RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED = "OM:RESET_NEW_OPERATOR_PASSWORD_SUCCEEDED";
export const RESET_NEW_OPERATOR_PASSWORD_FAILED = "OM:RESET_NEW_OPERATOR_PASSWORD_FAILED";
export const RESET_NEW_PASSWORD_VARIABLE = "OM:RESET_NEW_PASSWORD_VARIABLE";

export function setOperator(formDetails) {
  return {
    formDetails,
    type: SET_OPERATOR_FORM,
  };
}

export function setOriginalOperator(operatorDetails) {
  return {
    operatorDetails,
    type: SET_ORIGINAL_FORM,
  };
}

export function setRenderedValues(operatorDetails) {
  return {
    operatorDetails,
    type: SET_RENDERED_VALUES,
  };
}

export function updateOperator(id, formDetails) {
  return {
    formDetails,
    id,
    type: UPDATE_OPERATOR,
  };
}

export function clearOperatorForm() {
  return {
    type: CLEAR_OPERATOR_FORM,
  };
}

export function resetOperatorForm() {
  return {
    type: RESET_OPERATOR_FORM_MODIFIED,
  };
}

export function updateOperatorPassword(operatorid, formData) {
  return {
    formData,
    type: UPDATE_PASSWORD,
  };
}

export function resetOperatorFormModified() {
  return {
    type: RESET_MODIFIED,
  };
}

export function resetNewOperatorPassword(operatorid, formData) {
  return {
    formData,
    operatorid,
    type: RESET_NEW_OPERATOR_PASSWORD,
  };
}

export function resetNewPasswordVariable() {
  return {
    type: RESET_NEW_PASSWORD_VARIABLE,
  };
}
