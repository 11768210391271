import actionTypes from "../constants";
import filterTypes from "../constants/filterTypes";
import { loadFromStorage, saveInStorage } from "../utils";

const initialState = {
  betStatus: filterTypes.BET_STATUS.ALL_BET_STATUS.value,
  bookType: filterTypes.BOOK_TYPE.PREMATCH.value,
  closeBtn: false,
  code: null,
  customDates: [],
  date: filterTypes.DATES.TODAY,
  desc: null,
  distributedWidth: 0,
  eventSearchString: "",
  liabilityType: filterTypes.LIABILITY_TYPE.LIABILITY.value,
  line: 2,
  market: filterTypes.MARKETS.ALL_MARKETS,
  marketStatusIds: filterTypes.STATUS.OPEN_AND_SUSPENDED.value,
  openBtn: true,
  period: filterTypes.PERIODS.ALL_PERIODS,
  priceType: filterTypes.PRICE_TYPE.PRICED_AND_UNPRICED.value,
  resultedBtn: false,
  rowHeight: loadFromStorage("GRID_ROW_HEIGHT") || "Default",
  settledBtn: false,
  suspendedBtn: true,
  winPlace: filterTypes.WIN_PLACE.WIN.value,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RISK_FILTER_CODE:
      return { ...state, code: action.code };
    case actionTypes.SET_RISK_FILTER_DESC:
      return { ...state, desc: action.desc };
    case actionTypes.SET_RISK_FILTER_DATE:
      return { ...state, date: action.date };
    case actionTypes.ADD_CUSTOM_DATE_FILTER:
      return { ...state, customDates: [...state.customDates, action.customDate] };
    case actionTypes.SET_RISK_FILTER_MARKET_STATUS_IDS:
      let marketStatus = state.marketStatusIds.split(",");
      let newMarketStatusId;
      if (marketStatus.indexOf(action.marketStatusIds) < 0) {
        marketStatus.splice(1, 0, action.marketStatusIds);
        marketStatus = marketStatus.filter((item) => item !== "");
        newMarketStatusId = marketStatus.toString();
      } else {
        const index = marketStatus.indexOf(action.marketStatusIds);
        marketStatus.splice(index, 1);
        marketStatus = marketStatus.filter((item) => item !== "");
        newMarketStatusId = marketStatus.toString();
      }

      return { ...state, marketStatusIds: newMarketStatusId, [action.btnName]: !state[action.btnName] };
    case actionTypes.SET_RISK_FILTER_LINE:
      return { ...state, line: action.line };
    case actionTypes.SET_RISK_FILTER_MARKET:
      return { ...state, market: action.market };
    case actionTypes.SET_RISK_FILTER_PERIOD:
      return { ...state, period: action.period };
    case actionTypes.SET_RISK_FILTER_BOOKTYPE:
      return { ...state, bookType: action.bookType };
    case actionTypes.SET_RISK_FILTER_LIABILITY_TYPE:
      return { ...state, liabilityType: action.liabilityType };
    case actionTypes.SET_RISK_FILTER_PRICE_TYPE:
      return { ...state, priceType: action.priceType };
    case actionTypes.SET_RISK_FILTER_WIN_PLACE:
      return { ...state, winPlace: action.winPlace };
    case actionTypes.SET_RISK_FILTER_BET_STATUS:
      return { ...state, betStatus: action.betStatus };
    case actionTypes.SET_EVENT_SEARCH_VALUE:
      return { ...state, eventSearchString: action.eventSearchString };
    case actionTypes.SET_DISTRIBUTED_WIDTH:
      return { ...state, distributedWidth: action.distributedWidth };
    case actionTypes.RESET_RISK_FILTERS:
      return { ...initialState, code: state.code, date: state.date, eventSearchString: "", rowHeight: state.rowHeight };
    case actionTypes.SET_RISK_ROW_HEIGHT:
      saveInStorage("GRID_ROW_HEIGHT", action.rowHeight);

      return { ...state, rowHeight: action.rowHeight };
    default:
      return { ...state };
  }
}
