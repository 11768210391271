const playersConst = {
  // https://lotuslabs.atlassian.net/browse/PHX-384
  // (PUT)/bo/opponents/<teamId>/<playerId>
  ADD_PLAYER_TO_TEAM: "ADD_PLAYER_TO_TEAM",

  ADD_PLAYER_TO_TEAM_FAILED: "ADD_PLAYER_TO_TEAM_FAILED",

  ADD_PLAYER_TO_TEAM_SUCCEEDED: "ADD_PLAYER_TO_TEAM_SUCCEEDED",

  
  
  CLEAR_PLAYERS_OF_TEAM_DETAILS: "CLEAR_PLAYERS_OF_TEAM_DETAILS",

  



// https://lotuslabs.atlassian.net/browse/PHX-385
// (DELETE)/bo/eventpaths/<eventPathId>/opponents/players
DELETE_ALL_PLAYER_OF_EVENTPATH: "DELETE_ALL_PLAYER_OF_EVENTPATH",

  







DELETE_ALL_PLAYER_OF_EVENTPATH_FAILED: "DELETE_ALL_PLAYER_OF_EVENTPATH_FAILED",

  
  
  





DELETE_ALL_PLAYER_OF_EVENTPATH_SUCCEEDED: "DELETE_ALL_PLAYER_OF_EVENTPATH_SUCCEEDED",

  
  
  



// https://lotuslabs.atlassian.net/browse/PHX-384
// (DELETE)/bo/opponents/<teamId>/players
DELETE_ALL_PLAYER_OF_TEAM: "DELETE_ALL_PLAYER_OF_TEAM",

  





DELETE_ALL_PLAYER_OF_TEAM_FAILED: "DELETE_ALL_PLAYER_OF_TEAM_FAILED",

  





DELETE_ALL_PLAYER_OF_TEAM_SUCCEEDED: "DELETE_ALL_PLAYER_OF_TEAM_SUCCEEDED",

  
  
  



// https://lotuslabs.atlassian.net/browse/PHX-384
// (DELETE)/bo/opponents/<teamId>/<playerId>
DELETE_PLAYER_OF_TEAM: "DELETE_PLAYER_OF_TEAM",

  





DELETE_PLAYER_OF_TEAM_FAILED: "DELETE_PLAYER_OF_TEAM_FAILED",

  





DELETE_PLAYER_OF_TEAM_SUCCEEDED: "DELETE_PLAYER_OF_TEAM_SUCCEEDED",

  



// https://lotuslabs.atlassian.net/browse/PHX-384
// (GET)/bo/opponents/<teamId>/players
FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS: "FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS",

  



FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_FAILED: "FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_FAILED",

  



FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_SUCCEEDED: "FETCH_MULTIPLE_PLAYERS_OF_TEAM_DETAILS_SUCCEEDED",

  
  
  // https://lotuslabs.atlassian.net/browse/PHX-376
// (GET) /bo/opponents/<opponentId>
FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS: "FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS",
  FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_FAILED: "FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_FAILED",
  FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_SUCCEEDED: "FETCH_SINGLE_PLAYER_OF_TEAM_DETAILS_SUCCEEDED",
};

export default playersConst;
