import _ from "lodash";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

function* fetchStakeType() {
  const { response } = yield call(API.fetchStakeType);
  if (response) {
    yield put({ stakeTypes: response, type: constants.FETCH_STAKE_TYPE_SUCCEEDED });
  } else {
    // yield put({ type: constants.FETCH_EVENT_PATH_FAILED })
  }
}

function* fetchIndicatorActions() {
  const { response } = yield call(API.fetchIndicatorActions);
  if (response) {
    yield put({ indicatorActions: response, type: constants.FETCH_INDICATOR_ACTIONS_SUCCEEDED });
  } else {
    // yield put({ type: constants.FETCH_EVENT_PATH_FAILED })
  }
}

function* fetchStakeDistributationIndicator(action) {
  const { response } = yield call(API.fetchStakeDistributationIndicator, action.path);
  if (response) {
    yield put({ response, type: constants.FETCH_STAKE_DISTRIBUTATION_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_STAKE_DISTRIBUTATION_FAILED });
  }
}

function* saveStakeDistribution(action) {
  const { response } = yield call(API.saveStakeDistribution, action);
  if (response) {
    yield put({ response, type: constants.SAVE_STAKE_DISTRIBUTATION_SUCCEEDED });
    toastr.add({ message: i18n.t(`Wager.Stake Distribution Indicator updated successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.SAVE_STAKE_DISTRIBUTATION_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to update Stake Distributation`), type: "ERROR" });
  }
}

function* removeStakeLiabilityIndicators(action) {
  const { response } = yield call(API.removeStakeLiabilityIndicators, action);
  if (response) {
    yield put({ type: constants.ON_REMOVE_STAKE_LIABILITY_INDICATOR_SUCCEEDED });
    toastr.add({ message: i18n.t(`Wager.Stake Distribution Indicators deleted successfully`), type: "SUCCESS" });
  } else {
    yield put({ type: constants.ON_REMOVE_STAKE_LIABILITY_INDICATOR_FAILED });
    toastr.add({ message: i18n.t(`Wager.Unable to remove Stake Distribution Indicators`), type: "ERROR" });
  }
}

export default function* WagerLimitsStakeDistributation() {
  yield takeLatest(constants.FETCH_STAKE_TYPE, fetchStakeType);
  yield takeLatest(constants.FETCH_INDICATOR_ACTIONS, fetchIndicatorActions);
  yield takeLatest(constants.FETCH_STAKE_DISTRIBUTATION, fetchStakeDistributationIndicator);
  yield takeLatest(constants.SAVE_STAKE_DISTRIBUTATION, saveStakeDistribution);
  yield takeLatest(constants.REMOVE_STAKE_LIABILITY_INDICATOR, removeStakeLiabilityIndicators);
}
