import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const promotionXhr = null;
const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

export function fetchTerms(id) {
  const url = `/bo/termsandconditions${id ? `/${id}` : ""}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateTerm(id, term) {
  const url = `/bo/termsandconditions/${id}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_PUT, url, { id, ...term }, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createTerm(term) {
  const url = `/bo/termsandconditions`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_POST, url, term, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function copyTerm(term) {
  const url = `/bo/termsandconditions/copy`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_POST, url, term, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteTerm(promotionId) {
  const url = `/bo/termsandconditions/${promotionId}`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_DELETE, url)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchTermCampaigns(id) {
  const url = `/bo/termsandconditions/${id}/campaigns`;

  return performHttpCall(promotionXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
