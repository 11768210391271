import _ from "lodash";

import { RESTRICTION_FLAGS } from "./constants";

import { makeIterable } from "utils";


export const getMatrixSize = (arrayLength, size) => {
  const newArrayLength = arrayLength - size;
  if (newArrayLength > 0) {
    size = getMatrixSize(newArrayLength, size + 1);
  }

  return size;
};

export const getNextAvailableCol = (matrixArray, row, col) => {
  if (matrixArray[row][col]) {
    col = getNextAvailableCol(matrixArray, row, col + 1);
  }

  return col;
};

export const processMatrix = ({ state, response, options = {} }) => {
  const matrixData = response;
  const activeBetType = state.activeBetType;
  const activeBetTypeKey = state.activeBetTypeKey;
  const periodsMap = state.sportPeriodsMap;
  const cache = state.matrixDataCache[activeBetTypeKey];
  // let isAddingNewBetRestriction = action.isNew;
  const dataLength = matrixData.length;
  const matrixSize = dataLength ? getMatrixSize(dataLength, 1) : 0;
  const matrixArray = [];
  const matrixMap = {};
  const multipleRulesMap = {};
  const matrixHeaders = [];
  const matrixHeaderKeys = [];
  let isSelectOutcome = activeBetType.betRestrictionTypeId === 1;
  if (options.isFromNewMatrix) {
    isSelectOutcome = state.newBetRestrictionData.newMatrixBetType.betRestrictionTypeId === 1;
  }
  if (!isSelectOutcome) {
    // if not Select Outcome type, matrix is multi-dimension
    let row = 0;
    let col = 0;
    for (let i = matrixSize - 1; i >= 0; i--) {
      matrixArray[i] = [];
      for (let j = matrixSize - 1; j >= 0; j--) {
        matrixArray[i][j] = null;
      }
    }
    for (let cell of matrixData) {
      if (col >= matrixSize) {
        col = 0;
        row++;
      }
      if (row < matrixSize) {
        const cr0 = cell.criterias[0];
        const cr1 = cell.criterias[1];
        const pathDesc = cr0.eventPathDescription;
        const marketGroupDesc = cr0.marketTypeGroupDesc;
        const key1 = generateCriteriaKey(cr0);
        const key2 = generateCriteriaKey(cr1);
        const key = `${key1}-${key2}`;
        let flag = getMultipleFlag(cell.rules);
        const periodDesc = cr0.periodId ? periodsMap[cr0.periodId].fullDescription : "";
        const headerName = getHeaderName(
          pathDesc,
          marketGroupDesc,
          cr0.marketTypeDescription,
          cr0.eventTypeId,
          periodDesc,
        );
        if (!matrixHeaderKeys.includes(key1)) {
          matrixHeaderKeys.push(key1);
          matrixHeaders.push({
            betType: {
              betRestrictionType: cell.betRestrictionType,
              betTypeGroup: cell.betTypeGroup,
              transactionSubType: cell.transactionSubType,
            },
            criteria: { ...cr0 },
            desc: headerName,
            descExpanded: getExpandedHeaderName(
              cr0.eventPath,
              pathDesc,
              marketGroupDesc,
              cr0.marketTypeDescription,
              cr0.eventTypeId,
              periodDesc,
            ),
            key: key1,
          });
        }
        if (!matrixMap[key]) {
          matrixMap[key] = {
            cell,
            className: flag.className,
            desc: flag.desc,
            descExpanded: `${flag.desc} ${cell.rules.length ? `(${cell.level})` : ""}`,
            key,
          };
          if (cache && cache.matrixMap && cache.matrixMap[key]) {
            matrixMap[key] = { ...cache.matrixMap[key] }; // get from cache if available
            cell = matrixMap[key].cell;
            flag = getMultipleFlag(cell.rules);
          }
          if (flag.key === "MULTIPLE") {
            multipleRulesMap[key] = [...cell.rules];
          }
          // if (isAddingNewBetRestriction) {
          //     addedMatrixMap[key] = {...matrixMap[key]}
          // }
        }

        if (matrixArray[row][col]) {
          // find the next available col in the row
          col = getNextAvailableCol(matrixArray, row, col);
        }
        matrixArray[row][col] = key;
        matrixArray[col][row] = key;
      }
      col++;
    }
  } else {
    // if single dimension
    for (const cell of matrixData) {
      const cr = cell.criterias[0];
      const pathDesc = cr.eventPathDescription;
      const marketGroupDesc = cr.marketTypeGroupDesc;
      const key = generateCriteriaKey(cr);
      const flag = getSingleFlag(cell.rules);
      const periodDesc = cr.periodId ? periodsMap[cr.periodId].fullDescription : "";
      const headerName = getHeaderName(pathDesc, marketGroupDesc, cr.marketTypeDescription, cr.eventTypeId, periodDesc);
      if (!matrixHeaderKeys.includes(key)) {
        matrixHeaderKeys.push(key);
        matrixHeaders.push({
          betType: {
            betRestrictionType: cell.betRestrictionType,
            betTypeGroup: cell.betTypeGroup,
            transactionSubType: cell.transactionSubType,
          },
          criteria: { ...cr },
          desc: headerName,
          descExpanded: getExpandedHeaderName(
            cr.eventPath,
            pathDesc,
            marketGroupDesc,
            cr.marketTypeDescription,
            cr.eventTypeId,
            periodDesc,
          ),
          key,
        });
      }
      if (!matrixMap[key]) {
        matrixMap[key] = {
          cell,
          className: flag.className,
          desc: flag.desc,
          descExpanded: `${flag.desc} ${cell.rules.length ? `(${cell.level})` : ""}`,
          key,
        };
        if (cache && cache.matrixMap && cache.matrixMap[key]) {
          matrixMap[key] = { ...cache.matrixMap[key] }; // get from cache if available
        }
        // if (isAddingNewBetRestriction) {
        //     addedMatrixMap[key] = {...matrixMap[key]}
        // }
      }

      matrixArray.push([key]);
    }
  }

  return {
    matrixArray,
    matrixData,
    matrixHeaders,
    matrixMap,
    multipleRulesMap,
  };
};

export const getHeaderName = (pathDesc, marketGroupDesc, marketTypeDesc, eventTypeId, periodDesc) => {
  let headerName = "All";
  const descsArray = [];
  let eventTypeName = "";
  if (eventTypeId === 1) {
    eventTypeName = "Game";
  } else if (eventTypeId === 2) {
    eventTypeName = "Rank";
  }
  if (pathDesc) {
    descsArray.push(pathDesc);
  }
  if (eventTypeName) {
    descsArray.push(eventTypeName);
  }
  if (!marketTypeDesc && marketGroupDesc) {
    descsArray.push(marketGroupDesc);
  }
  if (marketTypeDesc) {
    descsArray.push(marketTypeDesc);
  }
  if (periodDesc) {
    descsArray.push(periodDesc);
  }
  if (descsArray.length) {
    headerName = descsArray.join(" - ");
  }

  return headerName;
};

export const getExpandedHeaderName = (
  eventPath,
  pathDesc,
  marketGroupDesc,
  marketTypeDesc,
  eventTypeId,
  periodDesc,
) => {
  let headerName = "All";
  const descsArray = [];
  let eventTypeName = "";
  if (eventTypeId === 1) {
    eventTypeName = "Game";
  } else if (eventTypeId === 2) {
    eventTypeName = "Rank";
  }
  let pathPrefix = "S: ";
  if (eventPath && eventPath.split("/").filter((e) => !!e).length > 1) {
    // if the result length is more than 1, then the selected path must NOT be sport
    pathPrefix = "EP: ";
  }
  const eventTypePrefix = "ET: ";
  const marketGroupPrefix = "MTG: ";
  const marketTypePrefix = "MT: ";
  const periodPrefix = "P: ";
  if (pathDesc) {
    descsArray.push(pathPrefix + pathDesc);
  }
  if (eventTypeName) {
    descsArray.push(eventTypePrefix + eventTypeName);
  }
  if (marketGroupDesc) {
    descsArray.push(marketGroupPrefix + marketGroupDesc);
  }
  if (marketTypeDesc) {
    descsArray.push(marketTypePrefix + marketTypeDesc);
  }
  if (periodDesc) {
    descsArray.push(periodPrefix + periodDesc);
  }
  if (descsArray.length) {
    headerName = descsArray.join(" - ");
  }

  return headerName;
};

export const getMultipleFlag = (rules) => {
  const flagKey = rules.length > 1 ? "MULTIPLE" : rules[0] ? rules[0].sameFlag : "NO_RULE";

  return RESTRICTION_FLAGS[flagKey];
};
export const getSingleFlag = (rules) => {
  const flagKey = rules[0] ? rules[0].sameFlag : "NO_RULE";

  return RESTRICTION_FLAGS[flagKey];
};
export const generateNewMinMaxOnRules = ({ cellData, isMultipleRulesChecked, key, value }) => {
  if (isMultipleRulesChecked) {
    return cellData.rules.map((rule) => ({ ...rule, [key]: value }));
  }

  return [{ ...cellData.rules[0], [key]: value }];
};
export const generateNewFlagsOnRules = ({ index, props, rules, value }) => {
  const otherProps = props.reduce((accu, prop) => {
    accu[prop] = value;

    return accu;
  }, {});

  return [...rules.slice(0, index), { ...rules[index], ...otherProps }, ...rules.slice(index + 1)];
};
export const generateCriteriaKey = (criteria) => {
  const typeDesc = criteria.marketTypeDescription ? criteria.marketTypeDescription : criteria.marketTypeGroupDesc;

  return `
    ${criteria.eventPathDescription}-
    ${typeDesc}-
    ${criteria.eventPathId}-
    ${criteria.eventTypeId}-
    ${criteria.marketTypeGroupId}-
    ${criteria.marketTypeId}-
    ${criteria.periodId}
  `;
};
export const getBetTypeKey = (betType) =>
  `${betType.betRestrictionTypeId}-${betType.betTypeGroupId}-${betType.transSubTypeId}`;
export const betRestrictionHasChanges = (matrixDataCache) => {
  let betRestrictionHasChanges = false;
  const betRestrictionsCacheArray = [...makeIterable(matrixDataCache)];
  for (let i = 0, l = _.size(betRestrictionsCacheArray); i < l; i++) {
    const cache = betRestrictionsCacheArray[i] || {};
    if (_.size(cache.deletedRestrictionsMap) || _.size(cache.updatedCellsArray)) {
      betRestrictionHasChanges = true;
      break;
    }
  }

  return betRestrictionHasChanges;
};

export const getUpdatedCellsArrayAndMatrixMap = (activeBetTypeKey, matrixDataCache) => {
  if (matrixDataCache[activeBetTypeKey]) {
    const cache = matrixDataCache[activeBetTypeKey];

    return {
      deletedRestrictions: [...makeIterable(cache.deletedRestrictionsMap)],
      matrixMap: cache.matrixMap,
      updatedCellsArray: cache.updatedCellsArray,
    };
  }

  return { deletedRestrictions: [], matrixMap: {}, updatedCellsArray: [] };
};
