import _ from "lodash";
import { SortDirection, SortIndicator } from "react-virtualized";

import constants from "../constants";

import { formatNumber, formatISODateString } from "utils";
// TODO: remove dummy withdrawal data
const initialState = {
  approvalCandidates: [],
  columnConfigs: [
    {
      key: "paymentDate",
      label: "Request Date",
      show: true,
      width: 135,
    },
    {
      key: "userName",
      label: "Username",
      show: true,
      width: 150,
    },
    {
      key: "accountName",
      label: "Account Name",
      show: true,
      width: 150,
    },
    {
      class: "tcenter",
      key: "amount",
      label: "Amount",
      show: true,
      width: 150,
    },
    {
      key: "currencyIso",
      label: "Currency",
      show: true,
      width: 80,
    },
    {
      key: "reference",
      label: "Reference",
      show: true,
      width: 150,
    },
    {
      key: "bankInfo",
      label: "Bank Details",
      show: false,
      width: 150,
    },
    {
      key: "approvalStatusId",
      label: "Approval Status",
      show: true,
      width: 120,
      // cellRenderer:(cellData) => {
      //   return <select defaultValue={cellData}>
      //     <option value="Pending">Pending</option>
      //   </select>;
      // }
    },
    {
      class: "tcenter",
      key: "registrationDate",
      label: "Registration Date",
      show: true,
      width: 135,
    },
    {
      key: "accountNumber",
      label: "Account Number",
      show: true,
      width: 150,
    },
    {
      key: "bearerName",
      label: "Bearer Name",
      show: true,
      width: 150,
    },
    {
      class: "tcenter",
      key: "value",
      label: "Value",
      show: true,
      width: 150,
    },
    {
      class: "notes-cell tleft",
      key: "notes",
      label: "Notes",
      show: true,
      width: 150,
      // cellRenderer: (recid,details,click) => {
      //   return <i onClick={()=>click(recid,details)} className="phxico phx-note-outline icon-small" />
      // }
    },
    {
      class: "tcenter",
      key: "process",
      label: "Process",
      show: true,
      width: 70,
    },
    {
      class: "tcenter",
      key: "rejectionReasonId",
      label: "Rejection Reason",
      show: true,
      width: 70,
    },
    {
      key: "displayName",
      label: "Transaction Sub Type",
      show: true,
      width: 150,
    },
    {
      class: "tcenter",
      key: "balance",
      label: "Balance",
      show: false,
      width: 150,
    },
    {
      key: "identityCardNumber",
      label: "Identity Card #",
      show: false,
      width: 150,
    },
    {
      key: "bankAccountNumber",
      label: "Bank Account #",
      show: false,
      width: 150,
    },
    {
      key: "approvedDate",
      label: "Updated Date",
      show: false,
      width: 150,
    },
    {
      class: "tcenter",
      key: "retryCounter",
      label: "# Tries",
      show: false,
      width: 50,
    },
    {
      key: "updatedBy",
      label: "Updated By",
      show: false,
      width: 150,
    },
    {
      key: "senderName",
      label: "Sender's Name",
      show: true,
      width: 150,
    },
    {
      key: "accountHolder",
      label: "Account Holder",
      show: true,
      width: 150,
    },
    {
      key: "approvedBy",
      label: "Approved By",
      show: true,
      width: 150,
    },
  ],
  depositUpdateData: [],
  deposits: [],
  isFetchingApprovalCandidates: false,

  isFetchingApprovalCandidatesFailed: false,

  // Deposit
  isFetchingDeposits: false,

  isFetchingDepositsFailed: false,

  isFetchingPayments: false,
  isFetchingPaymentsFailed: false,
  isFetchingWithdrawals: false,
  isFetchingWithdrawalsFailed: false,
  isUpdatingDeposits: false,

  isUpdatingDepositsFailed: false,

  isUpdatingWithdrawals: false,

  isUpdatingWithdrawalsFailed: false,

  paymentStatus: [],

  paymentStatusForFilter: [],

  recentlyUpdated: null,

  showApproveConfirmModal: false,

  unsavedModal: {
    criteria: "",
    index: "",
    show: false,
  },

  // Withdrawal
withdrawalUpdateData: [],
  
  withdrawals: [],
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case constants.CLEAR_DEPOSIT_WITHDRAWAL:
      return {
        ...state,
        deposits: [],
        withdrawals: [],
      };
    case constants.TOGGLE_UNSAVED_MODAL:
      return {
        ...state,
        unsavedModal: {
          criteria: action.criteria ? action.criteria : "",
          index: action.index ? action.index : "",
          show: !state.unsavedModal.show,
        },
      };
    case constants.UPDATE_PAYMENT_NOTES:
      return {
        ...state,
        ...(action.paymentType === 0
          ? {
              deposits: state.deposits.map((deposit) => {
                if (deposit.payRecId === action.note.payRecId) {
                  return {
                    ...deposit,
                    notes: action.note.note,
                  };
                }

                return deposit;
              }),
            }
          : {
              withdrawals: state.withdrawals.map((withdrawals) => {
                if (withdrawals.payRecId === action.note.payRecId) {
                  return {
                    ...withdrawals,
                    notes: action.note.note,
                  };
                }

                return withdrawals;
              }),
            }),
      };
    case constants.RECEIVE_CUSTOM_SEARCH_DEPOSIT:
      return {
        ...state,
        deposits: [...action.deposits],
      };
    case constants.RECEIVE_CUSTOM_SEARCH_WITHDRAWALS:
      return {
        ...state,
        withdrawals: [...action.withdrawals].map(
          (withdrawal) =>
            // withdrawal.amount = parseInt(withdrawal.amount)
            withdrawal,
        ),
      };
    case constants.FETCH_PAYMENTS:
      return {
        ...state,
        isFetchingPayments: true,
        isFetchingPaymentsFailed: false,
      };
    case constants.FETCH_PAYMENTS_SUCCEEDED:
      return {
        ...state,
        isFetchingPayments: false,
        isFetchingPaymentsFailed: false,
      };
    case constants.FETCH_PAYMENTS_FAILED:
      return {
        ...state,
        isFetchingPayments: false,
        isFetchingPaymentsFailed: true,
      };
    case constants.FETCH_APPROVAL_CANDIDATES:
      return {
        ...state,
        isFetchingWithdrawals: true,
        isFetchingWithdrawalsFailed: false,
      };
    case constants.FETCH_APPROVAL_CANDIDATES_SUCCEEDED:
      return {
        ...state,
        isFetchingWithdrawals: false,
        isFetchingWithdrawalsFailed: false,
        withdrawals: [...action.approvalCandidates].map(
          (withdrawal) =>
            // withdrawal.amount = parseInt(withdrawal.amount)
            withdrawal,
        ),
      };
    case constants.FETCH_APPROVAL_CANDIDATES_FAILED:
      return {
        ...state,
        isFetchingWithdrawals: false,
        isFetchingWithdrawalsFailed: true,
      };

    case constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES:
      return {
        ...state,
        isFetchingDeposits: true,
        isFetchingDepositsFailed: false,
      };
    case constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES_SUCCEEDED:
      if (action.app == "withdrawals") {
        return {
          ...state,
          isFetchingWithdrawals: false,
          isUpdatingWithdrawalsFailed: false,
          recentlyUpdated: null,
          withdrawals: action.deposits,
        };
      }

      return {
        ...state,
        deposits: [...action.deposits].map(
          (deposit) =>
            // deposit.amount = parseInt(deposit.amount)
            deposit,
        ),
        isFetchingDeposits: false,
        isFetchingDepositsFailed: false,
        recentlyUpdated: null,
      };

    case constants.FETCH_DEPOSIT_APPROVAL_CANDIDATES_FAILED:
      return {
        ...state,
        isFetchingDeposits: false,
        isFetchingDepositsFailed: true,
      };

    case constants.SET_DEPOSIT_UPDATE_DATA:
      const currDepositUpdateData = [...state.depositUpdateData];
      const depositDataIndex = _.findIndex(currDepositUpdateData, { payRecId: action.formData.payRecId });
      if (depositDataIndex !== -1) currDepositUpdateData[depositDataIndex] = action.formData;
      else currDepositUpdateData.push(action.formData);

      return {
        ...state,
        depositUpdateData: currDepositUpdateData,
      };

    case constants.RESET_DEPOSIT_UPDATE_DATA:
      return {
        ...state,
        depositUpdateData: [],
      };

    case constants.DELETE_DEPOSIT_UPDATE_DATA:
      const depositUpdateData = [...state.depositUpdateData];

      const depositIndex = _.findIndex(depositUpdateData, {
        payRecId: action.payRecId,
      });

      depositUpdateData.splice(depositIndex, 1);

      return {
        ...state,
        depositUpdateData,
      };

    case constants.UPDATE_DEPOSITS:
      return {
        ...state,
        recentlyUpdated: null,
        // isUpdatingDeposits: true,
        // isUpdatingDepositsFailed: false,
      };
    case constants.UPDATE_DEPOSITS_SUCCEEDED:
      return {
        ...state,
        isUpdatingDeposits: false,
        isUpdatingDepositsFailed: false,
        recentlyUpdated: action.data[0],
      };
    case constants.UPDATE_DEPOSITS_FAILED:
      return {
        ...state,
        isUpdatingDeposits: false,
        isUpdatingDepositsFailed: true,
      };

    case constants.SET_WITHDRAWAL_UPDATE_DATA:
      const currWithdrawalUpdateData = [...state.withdrawalUpdateData];

      const withdrawalDataIndex = _.findIndex(currWithdrawalUpdateData, {
        payRecId: action.formData.payRecId,
      });

      if (withdrawalDataIndex !== -1) currWithdrawalUpdateData[withdrawalDataIndex] = action.formData;
      else currWithdrawalUpdateData.push(action.formData);

      return {
        ...state,
        withdrawalUpdateData: currWithdrawalUpdateData,
      };

    case constants.RESET_WITHDRAWAL_UPDATE_DATA:
      return {
        ...state,
        withdrawalUpdateData: [],
      };

    case constants.DELETE_WITHDRAWAL_UPDATE_DATA:
      const withdrawalUpdateData = [...state.withdrawalUpdateData];

      const withdrawalIndex = _.findIndex(withdrawalUpdateData, {
        payRecId: action.payRecId,
      });

      withdrawalUpdateData.splice(withdrawalIndex, 1);

      return {
        ...state,
        withdrawalUpdateData,
      };

    case constants.UPDATE_WITHDRAWALS:
      return {
        ...state,
        isUpdatingWithdrawals: true,
        isUpdatingWithdrawalsFailed: false,
      };
    case constants.UPDATE_WITHDRAWALS_SUCCEEDED:
      return {
        ...state,
        isUpdatingWithdrawals: false,
        isUpdatingWithdrawalsFailed: false,
        recentlyUpdated: action.data[0],
      };
    case constants.UPDATE_WITHDRAWALS_FAILED:
      return {
        ...state,
        isUpdatingWithdrawals: false,
        isUpdatingWithdrawalsFailed: true,
      };

    case constants.TOGGLE_APPROVE_ALL_CONFIRM_MODAL:
      return {
        ...state,
        showApproveConfirmModal: action.show,
      };
    case constants.FETCH_PAYMENT_STATUS_FOR_FILTER_SUCCEEDED:
      return {
        ...state,
        paymentStatusForFilter: action.response,
      };
    case constants.FETCH_FILTERED_DATA: {
      const loadingTabtrue = action.app == "deposits" ? "isFetchingDeposits" : "isFetchingWithdrawals";

      return {
        ...state,
        [loadingTabtrue]: true,
      };
    }
    case constants.FETCH_FILTERED_DATA_SUCCESS: {
      const loadingTabfalse = action.app == "deposits" ? "isFetchingDeposits" : "isFetchingWithdrawals";

      return {
        ...state,
        [action.response.tab]: action.response.data,
        [loadingTabfalse]: false,
      };
    }
    default:
      return { ...state };
  }
};

export default data;
