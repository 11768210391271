import actionTypes from "../constants";

const initialState = {
  columns: [],
  editUserColumnsAndSportsFailed: null,
  fetchingUserColumnsFailed: null,
  fetchingUserSportsFailed: null,

  isEditingUserColumnsAndSports: null,
  isFetchingUserColumns: null,

  isFetchingUserSports: null,
  sports: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_RISK_COLUMNS:
      return { ...state, fetchingUserColumnsFailed: false, isFetchingUserColumns: true };
    case actionTypes.FETCH_USER_RISK_COLUMNS_SUCCEEDED:
      return { ...state, columns: action.columns, isFetchingUserColumns: false };
    case actionTypes.FETCH_USER_RISK_COLUMNS_FAILED:
      return { ...state, fetchingUserColumnsFailed: true, isFetchingUserColumns: false };

    case actionTypes.FETCH_USER_RISK_SPORTS:
      return { ...state, fetchingUserSportsFailed: false, isFetchingUserSports: true };
    case actionTypes.FETCH_USER_RISK_SPORTS_SUCCEEDED:
      return { ...state, isFetchingUserSports: false, sports: action.sports };
    case actionTypes.FETCH_USER_RISK_SPORTS_FAILED:
      return { ...state, fetchingUserSportsFailed: true, isFetchingUserSports: false };

    case actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS:
      return { ...state, editUserColumnsAndSportsFailed: false, isEditingUserColumnsAndSports: true };
    case actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS_SUCCEEDED:
      return { ...state, columns: action.columns, isEditingUserColumnsAndSports: false, sports: action.sports };
    case actionTypes.EDIT_USER_RISK_COLUMNS_AND_SPORTS_FAILED:
      return { ...state, editUserSportsFailed: true, isEditingUserColumnsAndSports: false };
    default:
      return { ...state };
  }
}
