import { combineReducers } from "redux";

import grid from "../Grid/reducers";

import data from "./data";
import filters from "./filters";
import notes from "./notes";
import preferences from "./preferences";

export default combineReducers({
  data,
  filters,
  grid,
  notes,
  preferences,
});
