import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store.js";

import WebSocketProvider from "componentsV2/BetAttendant/utils/websockets/WebSocket";

import "./stylesheets/main.scss";
import "react-table/react-table.css";
import "react-day-picker/lib/style.css";

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
    ,
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
