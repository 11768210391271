import { takeLatest, put, call } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchSportOtherOptions(action) {
  const { response } = yield call(API.fetchSportOtherOptions, action.code);
  if (response) {
    yield put({ response, type: constants.FETCH_SPORT_OTHER_OPTIONS_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SPORT_OTHER_OPTIONS_FAILED });
  }
}

function* cancleBets(action) {
  const { response, xhr } = yield call(API.cancleBets, action.details);
  if (response) {
    if (response.success) {
      toastr.add({ message: i18n.t("Event.Bets had been cancelled"), type: "SUCCESS" });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* cancleBetsOnMarket(action) {
  const { response, xhr } = yield call(API.cancleBetsOnMarket, action.market);
  if (response) {
    if (response.success) {
      toastr.add({ message: i18n.t("Event.Bets had been cancelled"), type: "SUCCESS" });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

export default function* eventSaga() {
  yield takeLatest(constants.FETCH_SPORT_OTHER_OPTIONS, fetchSportOtherOptions);
  yield takeLatest(constants.CANCLE_BETS, cancleBets);
  yield takeLatest(constants.CANCLE_BETS_ON_MARKET, cancleBetsOnMarket);
}
