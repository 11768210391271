const constants = {
  ADD_ITEM_TO_TREE: "BM::ADD_ITEM_TO_TREE",
  ADD_ITEM_TO_TREE_ROOT: "BM::ADD_ITEM_TO_TREE_ROOT",
  CHANGE_CAMPAIGN_FILTER: "BM::CHANGE_CAMPAIGN_FILTER",

  COLLAPSE_SELECTION: "BM::COLLAPSE_SELECTION",
  CONFIRM_UNSAVED_MODAL: "BM::CONFIRM_UNSAVED_MODAL",
  EXPAND_SELECTION: "BM::EXPAND_SELECTION",
  FETCH_TREE: "BM::FETCH_TREE",
  FETCH_TREE_FAILED: "BM::FETCH_TREE_FAILED",
  FETCH_TREE_SUCCEEDED: "BM::FETCH_TREE_SUCCEEDED",
  INITIALIZE_ITEM_EXPANDED: "BM::INITIALIZE_ITEM_EXPANDED",
  REMOVE_ITEM_FROM_TREE: "BM::REMOVE_ITEM_FROM_TREE",

  REMOVE_ITEM_FROM_TREE_ROOT: "BM::REMOVE_ITEM_FROM_TREE_ROOT",
  RESET_TREE_ITEM: "BM::RESET_TREE_ITEM",

  RESTORE_OLD_TREE_ITEM: "BM::RESTORE_OLD_TREE_ITEM",
  SEARCH_TREE_ITEM: "BM::SEARCH_TREE_ITEM",

  SET_TREE_ACTIVE_ITEM: "BM::SET_TREE_ACTIVE_ITEM",
};

export const BASE_URL = "/bonus-manager";

export const CATEGORY_MAP = {
  campaigns: { name: "Campaigns", url: `${BASE_URL}/campaigns` },
  messagePlans: { name: "Message Plans", url: `${BASE_URL}/message-plans` },
  promotionalGroups: { name: "Promotional Groups", url: `${BASE_URL}/promotional-groups` },
  promotions: { name: "Promotions", url: `${BASE_URL}/promotions` },
  termsAndConditions: { name: "Terms and Conditions", url: `${BASE_URL}/terms-and-conditions` },
};

export const LOCATION_MAP = {
  campaigns: { type: "CAMPAIGN" },
  "message-plans": { type: "CAMPAIGN_MESSAGE_PLAN" },
  "promotional-groups": { type: "PROMO_GROUP" },
  promotions: { type: "PROMOTION" },
  "terms-and-conditions": { type: "TERMS_AND_CONDITONS" },
};

export default constants;
