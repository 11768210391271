import { transactionPermissions } from "./transactionConstants";

const accountPermissions = {
  TRANSACTIONS_ACCOUNT_TRANSFER: 441,

  TRANSACTIONS_ADJUSTMENT: 225,

  TRANSACTIONS_EXPORT: 215,
  // Transactions
  TRANSACTIONS_VIEW: 210,
  ...transactionPermissions,

  CONTACT_CHANGE_CONTACT_DETAILS: 275,

  CONTACT_CHANGE_CONTACT_PREFERENCES: 280,

  // Contact Details
  CONTACT_EDIT: 110,

  CREATE_ACCOUNT_CHANGE_PACKAGE: 952,

  // Create Account
  CREATE_ACCOUNT_NEW: 100,

  CREATE_BULK_CREDITING: 1563,

  DASHBOARD_CHANGE_ACCOUNT_STATUS: 160,

  DASHBOARD_CHANGE_ACCOUNT_STORE: 175,

  DASHBOARD_DELETE_NOTE: 150,

  ACCOUNT_TRANSFER: 1226,

  DASHBOARD_EDIT_CREDITLIMIT: 165,

  DASHBOARD_EDIT_NOTE: 145,

  DASHBOARD_EDIT_TEMPORARY_CREDIT: 170,

  // CUSTOMER MANAGER
  CUSTOMER_MANAGER_EXPIRE_PROMOTION_REWARDS: 1554,

  DASHBOARD_UPDATE_PAYMENT_METHOD: 1213,

  DASHBOARD_VIEW_BETTING_AREA: 115,

  // Dashboard
  DASHBOARD_VIEW_NOTES: 125,

  DASHBOARD_YTD_AND_LT: 120,

  // Misc
  IGNORE_PIN_CHECK: 825,

  // Manual Transaction
  MANUAL_TRANSACTION_ADJUSTMENT: 961,

  MANUAL_TRANSACTION_BONUS: 962,

  MANUAL_TRANSACTION_COLOR_OF_MONEY_PROMO_SNR: 1560,

  SECURITY_ALLOW_ONLINEBETS: 270,

  MANUAL_TRANSACTION_COMMISSION: 963,

  SECURITY_CHANGE_DOB: 260,

  MANUAL_TRANSACTION_EXTERNAL_TRANSFER: 964,

  SECURITY_CHANGE_IDCARDNUMBER: 287,

  MANUAL_TRANSACTION_MINIMUM_ODDS: 1544,

  SECURITY_CHANGE_PASSWORD: 250,

  MANUAL_TRANSACTION_PAYMENT_RECEIPT: 960,

  // Security
  SECURITY_VIEW: 245,

  MANUAL_TRANSACTION_PROMO_REWARD_ROLLOVER_MULTIPLIER: 1561,

  SECURITY_CHANGE_SECURITYQUESTION: 255,

  MARKET_TYPE_CONFIGURATION: 855,

  SECURITY_CHANGE_SECURITYLEVEL: 640,

  PERFORMANCE_PRINT: 185,

  // Settings
  SETTINGS_VIEW: 62,

  PERFORMANCE_SAVE: 180,

  SETTINGS_VIEW_WAGERLIMIT: 1210,

  TOGGLE_TEST_ACCOUNT_STATUS: 1720,

  // Performance
  PERFORMANCE_VIEW: 190,

  // Affiliate Manager
  SET_DEFAULT_COMMISSION_PLANS: 1546,

  UTILITY_ADD_AFFILIATE: 851,

  UTILITY_AFFILIATES_UTILITY: 850,

  // RISK MANAGER
  RISK_MANAGER_CHANGE_LOOKUP_PUBLISH_MODE: 998,

  // UTILITY
  UTILITY_BET_RESTRICTION_HISTORY_MANAGEMENT: 826,

  UTILITY_BET_RESTRICTION_MANAGEMENT: 827,

  UTILITY_CALENDAR_ACTIONS: 1202,

  UTILITY_CHANNEL_PRIORITY_UTILITY: 1558,

  UTILITY_EDIT_AFFILIATE: 852,

  UTILITY_EMERGENCY_LOCK_DOWN: 1203,

  UTILITY_EXCHANGE_RATE_MANAGEMENT: 834,

  UTILITY_HURDLE_MANAGEMENT: 828,

  UTILITY_MANAGE_LINES: 838,

  // UTILITY_MARKET_TYPE_ENABLEMENT: 855,
  UTILITY_MARKET_CONTROL_MANAGEMENT: 971,

  UTILITY_MARKET_TYPE_WAGER_LIMIT_GROUP_MANAGEMENT: 972,

  UTILITY_NOTIFICATIONS_UTILITY: 1201,

  UTILITY_ORIGIN_VALIDATION_MAPPING_MANAGEMENT: 1314,

  UTILITY_PARLAY_MANAGEMENT: 829,

  UTILITY_PA_FEED_MANAGEMENT: 833,

  UTILITY_POINTS_MANAGEMENT: 830,

  UTILITY_PROVIDER_MANAGEMENT: 836,

  UTILITY_REMOVE_AFFILIATE: 853,

  UTILITY_RETAIL_CHANNEL_MANAGEMENT: 1243,

  UTILITY_RISK_DEFAULT_MANAGEMENT: 854,

  UTILITY_SYSTEM_MANAGER: 837,

  UTILITY_TEASER_MANAGEMENT: 831,

  UTILITY_VIGORISH_MANAGEMENT: 832,

  VIEW_ALL_CUSTOMER_BRANDS: 1502,

  UTILITY_VOID_REASON_MANAGEMENT: 1244,

  UTILITY_WINNINGS_TAX_MANAGER: 1312,
};

function allow(permission, id) {
  return permission.find((val) => val.actionId === accountPermissions[id]);
}

export { accountPermissions, allow };
