const constans = {
  ACCEPT_SESSION: "EC::ACCEPT_SESSION",
  ACCEPT_SESSION_FAILED: "EC::ACCEPT_SESSION_FAILED",
  ACCEPT_SESSION_SUCCEEDED: "EC::ACCEPT_SESSION_SUCCEEDED",

  CHANGE_SEND_MESSAGE_STATUS: "EC::CHANGE_SEND_MESSAGE_STATUS",
  CHANGE_TEXT_VALUE: "EC::CHANGE_TEXT_VALUE",
  CLOSE_ASSIGNED_SESSION: "EC::CLOSE_ASSIGNED_SESSION",

  CLOSE_UNASSIGNED_SESSION: "EC::CLOSE_UNASSIGNED_SESSION",
  END_SESSION: "EC::END_SESSION",
  END_SESSION_FAILED: "EC::END_SESSION_FAILED",

  END_SESSION_SUCCEEDED: "EC::END_SESSION_SUCCEEDED",
  FETCH_ASSIGNED_SESSIONS: "EC::FETCH_ASSIGNED_SESSIONS",
  FETCH_ASSIGNED_SESSIONS_FAILED: "EC::FETCH_ASSIGNED_SESSIONS_FAILED",

  FETCH_ASSIGNED_SESSIONS_SUCCEEDED: "EC::FETCH_ASSIGNED_SESSIONS_SUCCEEDED",
  FETCH_ASSIGNED_SESSION_DETAILS: "EC::FETCH_ASSIGNED_SESSION_DETAILS",
  FETCH_ASSIGNED_SESSION_DETAILS_FAILED: "EC::FETCH_ASSIGNED_SESSION_DETAILS_FAILED",

  FETCH_ASSIGNED_SESSION_DETAILS_SUCCEEDED: "EC::FETCH_ASSIGNED_SESSION_DETAILS_SUCCEEDED",
  FETCH_MESSAGES: "EC::FETCH_MESSAGES",
  FETCH_MESSAGES_FAILED: "EC::FETCH_MESSAGES_FAILED",

  FETCH_MESSAGES_SUCCEEDED: "EC::FETCH_MESSAGES_SUCCEEDED",
  FETCH_OPERATOR_DETAILS: "EC::FETCH_OPERATOR_DETAILS",
  FETCH_OPERATOR_DETAILS_FAILED: "EC::FETCH_OPERATOR_DETAILS_FAILED",

  FETCH_OPERATOR_DETAILS_SUCCEEDED: "EC::FETCH_OPERATOR_DETAILS_SUCCEEDED",

  FETCH_UNASSIGNED_SESSIONS: "EC::FETCH_UNASSIGNED_SESSIONS",
  FETCH_UNASSIGNED_SESSIONS_FAILED: "EC::FETCH_UNASSIGNED_SESSIONS_FAILED",

  FETCH_UNASSIGNED_SESSIONS_SUCCEEDED: "EC::FETCH_UNASSIGNED_SESSIONS_SUCCEEDED",

  FETCH_UNASSIGNED_SESSION_DETAILS: "EC::FETCH_UNASSIGNED_SESSION_DETAILS",

  FETCH_UNASSIGNED_SESSION_DETAILS_FAILED: "EC::FETCH_UNASSIGNED_SESSION_DETAILS_FAILED",
  FETCH_UNASSIGNED_SESSION_DETAILS_SUCCEEDED: "EC::FETCH_UNASSIGNED_SESSION_DETAILS_SUCCEEDED",
  SELECT_SESSION: "EC::SELECT_SESSION",

  SEND_MESSAGE: "EC::SEND_MESSAGE",
  SEND_MESSAGE_FAILED: "EC::SEND_MESSAGE_FAILED",
  SEND_MESSAGE_SUCCEEDED: "EC::SEND_MESSAGE_SUCCEEDED",

  TOGGLE_ASSIGNED_SESSION_WARNING: "EC::TOGGLE_ASSIGNED_SESSION_WARNING",
  TOGGLE_UNASSIGNED_SESSION_WARNING: "EC::TOGGLE_UNASSIGNED_SESSION_WARNING",
};

export default constans;
