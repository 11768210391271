import createAxiosInstance from "../redux/async/axios";
import { store } from "../store";
//
// const getAuthLanguage = () => {
//   try {
//     return JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).language;
//   } catch (err) {
//     // do nothing
//   }
//
//   return undefined;
// };
//
// const getAuthToken = () => {
//   try {
//     return JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).authToken;
//   } catch (err) {
//     // do nothing
//   }
//
//   return undefined;
// };
//
// const getAuthAccountId = () => {
//   try {
//     return JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).accountId;
//   } catch (err) {
//     // do nothing
//   }
//
//   return undefined;
// };

const axiosInstance = () => {
  const dispatch = store.dispatch;

  const language = store.getState().auth.language;
  const authToken = store.getState().auth.authToken;

  const axios = createAxiosInstance(dispatch, {
    authToken,
    language,
  });

  return axios;
};

// hack wrapper to bridge the expectations from legacy api endpoints and the new axios setup
const api = {
  delete: async (url, requestBody, cancelToken = null) => {
    const axios = axiosInstance();

    const result = await axios.delete(url, requestBody, { cancelToken: cancelToken?.token });

    return result;
  },
  get: async (url, cancelToken = null) => {
    const axios = axiosInstance();

    const result = axios.get(url, { cancelToken: cancelToken?.token });

    return result;
  },
  post: async (url, requestBody, cancelToken = null) => {
    const axios = axiosInstance();

    const result = axios.post(url, requestBody, { cancelToken: cancelToken?.token });

    return result;
  },
  put: async (url, requestBody, cancelToken = null) => {
    const axios = axiosInstance();

    const result = axios.put(url, requestBody, { cancelToken: cancelToken?.token });

    return result;
  },
};

export default api;
