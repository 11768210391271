import originalAxios from "axios";

import httpMethods from "constants/httpMethods";
import api from "services//api";
import { performHttpCall } from "services//apiUtils";

const fetchMarketsXhr = null;
const fetchMarketXhr = null;
const fetchMarketBooksXhr = null;
const fetchMarketTypesXhr = null;
const fetchMarketPeriodsXhr = null;
const updateMarketDetailsXhr = null;
const updateMarketOutcomesXhr = null;
const updateMarketBooksXhr = null;
const createNewMarketsXhr = null;
const deleteMarketXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function fetchMarkets(eventId) {
  const url = `/bo/marketTypes/instances?eventId=${eventId}`;

  return performHttpCall(fetchMarketsXhr, httpMethods.HTTP_GET, url, null)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarket(marketId) {
  const url = `/bo/markets/${marketId}?lineId=2&includePrices=true&includeMarketTypes=true`;

  return performHttpCall(fetchMarketXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketBooks(marketId) {
  const url = `/bo/books?marketId=${marketId}&lineId=2`;

  return performHttpCall(fetchMarketBooksXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketTypes(eventId) {
  const url = `/bo/marketTypes/instances?eventId=${eventId}&lineId=2`;

  return performHttpCall(fetchMarketTypesXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function fetchMarketPeriods(eventId) {
  const url = `/bo/periods?eventId=${eventId}&lineId=2`;

  return performHttpCall(fetchMarketPeriodsXhr, httpMethods.HTTP_GET, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketDetails(marketId, data) {
  const url = `/bo/markets/${marketId}?lineId=2`;

  return performHttpCall(updateMarketDetailsXhr, httpMethods.HTTP_PUT, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketOutcomes(marketId, data) {
  // const url = `/bo/markets/${marketId}/outcomes?lineId=2`
  // return performHttpCall(updateMarketOutcomesXhr, httpMethods.HTTP_PUT, url, data)
  //   .then(response => ({ response }))
  //   .catch(xhr => ({ xhr }))

  // PX-965 : New api and payload to update outcomes
  const url = `/bo/markets/${marketId}?lineId=2`;
  const payloadForUpdate = {
    outcomes: data,
  };

  return performHttpCall(updateMarketOutcomesXhr, httpMethods.HTTP_PUT, url, payloadForUpdate)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function updateMarketBooks(marketId, data) {
  const url = `/bo/managebooks/${marketId}?lineId=2`;

  return performHttpCall(updateMarketBooksXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function createNewMarkets(eventId, data, hideOutcomesOnCreate) {
  const url = `/bo/events/${eventId}/markets/generate?lineId=2${hideOutcomesOnCreate ? "&hideOutcomes=true" : ""}`;

  return performHttpCall(createNewMarketsXhr, httpMethods.HTTP_POST, url, data)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteMarket(marketId) {
  const url = `/bo/markets/${marketId}?lineId=2`;

  return performHttpCall(deleteMarketXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function deleteMarkets(marketIds) {
  const url = `/bo/markets/${marketIds.join(",")}?lineId=2`;

  return performHttpCall(deleteMarketXhr, httpMethods.HTTP_DELETE, url, null, headers)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

const requests = {};
export function fetchMarketPlayers(players, eventId) {
  const promises = [];
  for (let i = 0; i < players.length; i++) {
    const id = players[i];
    if (id < 0) {
      continue;
    }
    const url = `/bo/opponents/${id}/players?lineId=2`;
    const promise = new Promise((resolve, reject) => {
      if (requests[id]) {
        requests[id].cancel("cancelled due to concurrent request");
      }

      requests[id] = originalAxios.CancelToken.source();

      try {
        const response = api.get(url, requests[id]);
        delete requests[id];
        resolve(response);
      } catch (err) {
        delete requests[id];
        requests[id] = null;
        reject(err);
      }
      requests[id] = null;
    });
    promises.push(promise);
  }

  return Promise.all(promises)
    .then((responses) => {
      const response = {};
      for (let i = 0; i < players.length; i++) {
        const id = players[i];
        if (id < 0) {
          continue;
        }
        response[id] = responses[i].data;
      }

      return { eventId, response };
    })
    .catch((xhr) => ({ xhr }));
}
