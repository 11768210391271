import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchOriginValidation(action) {
  const { response, xhr } = yield call(API.fetchOriginValidation);
  if (response) {
    yield put({ payload: response, type: constants.FETCH_ORIGIN_VALIDATION_MAPPING_SUCCEEDED });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_GET, xhr);
    yield put({ msg, type: constants.FETCH_ORIGIN_VALIDATION_MAPPING_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* addNewOriginValidation(action) {
  const { response, xhr } = yield call(API.addNewOriginValidation, action.payload);
  if (response) {
    yield put({ payload: response, type: constants.ADD_NEW_ORIGIN_VALIDATION_SUCCEEDED });
    yield put({ type: constants.FETCH_ORIGIN_VALIDATION_MAPPING });
    toastr.add({ message: i18n.t("Utilities.Successfully saved Origin Validation Mapping"), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ msg, type: constants.ADD_NEW_ORIGIN_VALIDATION_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* deleteOriginValidation(action) {
  const { response, xhr } = yield call(API.deleteOriginValidation, action.payload);
  if (response) {
    yield put({ payload: response, type: constants.DELETE_ORIGIN_VALIDATION_MAPPINGS_SUCCEEDED });
    yield put({ type: constants.FETCH_ORIGIN_VALIDATION_MAPPING });
    toastr.add({ message: i18n.t("Utilities.Successfully Deleted"), type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETED, xhr);
    yield put({ msg, type: constants.DELETE_ORIGIN_VALIDATION_MAPPINGS_FAILED });
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

export default function* originValidationMappingSaga() {
  yield takeLatest(constants.FETCH_ORIGIN_VALIDATION_MAPPING, fetchOriginValidation),
    yield takeLatest(constants.ADD_NEW_ORIGIN_VALIDATION, addNewOriginValidation),
    yield takeLatest(constants.DELETE_ORIGIN_VALIDATION_MAPPINGS, deleteOriginValidation);
}
