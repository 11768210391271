const constants = {
  // Application Actions Permision
  FETCH_ACTIONS: "OM::FETCH_ACTIONS",

  FETCH_ACTIONS_FAILED: "OM::FETCH_ACTIONS_FAILED",

  FETCH_ACTIONS_SUCCEEDED: "OM::FETCH_ACTIONS_SUCCEEDED",

  // Application permission
  FETCH_APPLICATIONS: "OM::FETCH_APPLICATIONS",

  FETCH_APPLICATIONS_FAILED: "OM::FETCH_APPLICATIONS_FAILED",
  FETCH_APPLICATIONS_SUCCEEDED: "OM::FETCH_APPLICATIONS_SUCCEEDED",
  FETCH_REPORTS: "OM::FETCH_REPORTS",

  FETCH_REPORTS_FAILED: "OM::FETCH_REPORTS_FAILED",

  FETCH_REPORTS_SUCCEEDED: "OM::FETCH_REPORTS_SUCCEEDED",

  MOVE_REPORTS: "OM::MOVE_REPORTS",

  RESET_MODIFIED_PERMISSIONS: "OM::RESET_MODIFIED_PERMISSIONS",

  RESET_MODIFIED_PERMISSIONS_SUCCEEDED: "OM::RESET_MODIFIED_PERMISSIONS_SUCCEEDED",

  SET_ACTION_PERMISSION_LIST_BOX_SELECTED: "OM::SET_ACTION_PERMISSION_LIST_BOX_SELECTED",

  SET_ACTION_PERMISSION_LIST_BOX_SELECTED_FAILED: "OM::SET_ACTION_PERMISSION_LIST_BOX_SELECTED_FAILED",

  SET_ACTION_PERMISSION_LIST_BOX_SELECTED_SUCEEDED: "OM::SET_ACTION_PERMISSION_LIST_BOX_SELECTED_SUCEEDED",
  
  SET_ACTION_PERMISSION_SELECTED: "OM::SET_ACTION_PERMISSION_SELECTED",
  
SET_APPLICATION_PERMISSION_SELECTED: "OM::SET_APPLICATION_PERMISSION_SELECTED",

  
SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED: "OM::SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED",
  
SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_FAILED:
    "OM::SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_FAILED",
  
SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_SUCEEDED:
    "OM::SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED_SUCEEDED",

  
SET_REPORT_PERMISSIONS: "OM::SET_REPORT_PERMISSIONS",
  
SET_REPORT_PERMISSIONS_FAILED: "OM::SET_REPORT_PERMISSIONS_FAILED",
  
SET_REPORT_PERMISSIONS_SUCCEEDED: "OM::SET_REPORT_PERMISSIONS_SUCCEEDED",

  // Application Permissions
UPDATE_ACTION_PERMISSION_LIST_BOX: "OM::UPDATE_ACTION_APPLICATION_PERMISSION_LIST_BOX",
  UPDATE_ACTION_PERMISSION_LIST_BOX_FAILED: "OM::UPDATE_ACTION_APPLICATION_PERMISSION_LIST_BOX_FAILED",
  UPDATE_ACTION_PERMISSION_LIST_BOX_SUCEEDED: "OM::UPDATE_ACTION_APPLICATION_PERMISSION_LIST_BOX_SUCEEDED",

  UPDATE_ALL_PERMISSIONS: "OM::UPDATE_ALL_PERMISSIONS",
  UPDATE_DEFAULT_SELECTED_ACTION_PERMISSION: "OM::UPDATE_DEFAULT_SELECTED_ACTION_PERMISSION",
  UPDATE_DEFAULT_SELECTED_APPLICATION_PERMISSION: "OM::UPDATE_DEFAULT_SELECTED_APPLICATION_PERMISSION",

  UPDATE_DEFAULT_SELECTED_PERMISSIONS: "OM::UPDATE_DEFAULT_SELECTED_PERMISSIONS",

  UPDATE_DEFAULT_SELECTED_REPORT_PERMISSION: "OM::UPDATE_DEFAULT_SELECTED_REPORT_PERMISSION",
  UPDATE_REPORT: "OM::UPDATE_REPORT",
  UPDATE_REPORTS: "OM::UPDATE_REPORTS",

  UPDATE_RESET_MODIFIED_PERMISSIONS: "OM::UPDATE_RESET_MODIFIED_PERMISSIONS",
};

export default constants;
