import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* renderGenericErrorToastMessage() {
  toastr.add({ message: errMsg, type: "ERROR" });
}

export default function* constantsSaga() {
  // yield takeLatest(actionTypes.FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP, fetchOutcomeWagerLimitsByGroup);
}
export { renderGenericErrorToastMessage };
