import actionTypes from "../../constants";

const initialState = {
  accountSecurityDetails: null,
  bankAccountDetails: null,
  banksOfCountry: [],
  changePasswordError: null,
  changePasswordSucceeded: false,
  hasErrorChangePassword: false,
  isChangingPassword: false,

  isFetchingData: false,
  isRequestFailed: false,
  isUpdatingDetails: false,
  updateCustomerBankDetailSuceeded: false,

  updateDetailsSuceeded: false,
};

const customerManagerSecurity = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNTSECURITYDETAILS:
      return {
        ...state,
        accountSecurityDetails: null,
        isFetchingData: true,
        isRequestFailed: false,
        isUpdatingDetails: false,
        updateDetailsSuceeded: false,
      };
    case actionTypes.UPDATE_ACCOUNTSECURITYDETAILS:
      return {
        ...state,
        isUpdatingDetails: true,
      };
    case actionTypes.UPDATE_ACCOUNTSECURITYDETAILS_SUCCEEDED:
      return {
        ...state,
        isUpdatingDetails: false,
        updateDetailsSuceeded: true,
      };
    case actionTypes.FETCH_ACCOUNTSECURITYDETAILS_SUCCEEDED:
      return {
        ...state,
        accountSecurityDetails: action.accountSecurityDetails,
        isFetchingData: false,
        isRequestFailed: false,
      };
    case actionTypes.UPDATE_CUSTOMER_BANK_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        updateCustomerBankDetailSuceeded: true,
        // isFetchingData: true
      };
    case actionTypes.RESET_STATE:
      return {
        ...state,
        isFetchingData: false,
        isUpdatingDetails: false,
        updateCustomerBankDetailSuceeded: false,
      };
    case actionTypes.FETCH_ACCOUNTSECURITYDETAILS_FAILED:
      return {
        ...state,
        accountSecurityDetails: null,
        isFetchingData: false,
        isRequestFailed: true,
      };
    case actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        bankAccountDetails: action.bankAccountDetails,
      };
    case actionTypes.FETCH_CUSTOMER_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        bankAccountDetails: null,
      };
    case actionTypes.CONFIRM_ACCOUNTSECURITYPASSWORD:
      return {
        ...state,
        changePasswordSucceeded: false,
      };
    case actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD:
      return {
        ...state,
        changePasswordError: null,
        changePasswordSucceeded: false,
        hasErrorChangePassword: false,
        isChangingPassword: true,
      };
    case actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD_SUCCEEDED:
      return {
        ...state,
        changePasswordError: null,
        changePasswordSucceeded: true,
        hasErrorChangePassword: false,
        isChangingPassword: false,
      };
    case actionTypes.UPDATE_ACCOUNTSECURITYPASSWORD_FAILED:
      return {
        ...state,
        changePasswordError: action.errorMessage,
        changePasswordSucceeded: false,
        hasErrorChangePassword: true,
        isChangingPassword: false,
        isUpdatingDetails: false,
      };
    case actionTypes.SET_CHANGE_PASSWORD_SUCCEEDED_STATUS:
      return {
        ...state,
        changePasswordSucceeded: false,
      };
    case actionTypes.RESET_PASSWORDERRORMESSAGE:
      return {
        ...state,
        changePasswordError: null,
        changePasswordSucceeded: false,
        hasErrorChangePassword: false,
        isChangingPassword: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    case actionTypes.FETCH_BANKS_BY_COUNTRY_SUCCEEDED:
      return {
        ...state,
        banksOfCountry: action.response,
      };
    case actionTypes.FETCH_BANKS_BY_COUNTRY_FAILED:
      return {
        ...state,
        banksOfCountry: [],
      };
    default:
      return { ...state };
  }
};

export default customerManagerSecurity;
