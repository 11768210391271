import originalAxios from "axios";

import api from "./api";

import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const accountXHR = [];
const fetchItemXHR = null;

const headers = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

const header2 = {
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
  "content-type": "application/json",
};

const header3 = {
  "Cache-Control": "no-cache",
  "X-P8-Cache": "bypass",
  "X-P8-Datasource": "database",
};

function _getURL(url, disableCache, headers, originalURL) {
  const request = originalURL ? url : `/bo${url}`;

  // VT - no caching - this does not help with anything! Trust Varnish and BE settings, do not override
  // if (disableCache) {
  //   let obj = {}
  //   const random = randomStr()
  //   obj[random] = random
  //   request = addParametersToUrl(request, obj)
  // }
  return new Promise((resolve, reject) => {
    if (accountXHR[url]) {
      accountXHR[url].cancel("cancelled due to concurrent request");
    }

    accountXHR[url] = originalAxios.CancelToken.source();

    try {
      const response = api.get(request, accountXHR[url]);
      accountXHR[url] = null;
      resolve(response);
    } catch (err) {
      accountXHR[url] = null;
      reject(err);
    }
    accountXHR[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function _postURL(url, data = {}, headers) {
  // For new Data
  return new Promise((resolve, reject) => {
    if (accountXHR[url]) {
      accountXHR[url].cancel("cancelled due to concurrent request");
    }

    accountXHR[url] = originalAxios.CancelToken.source();

    try {
      const response = api.post(`/bo${url}`, data, accountXHR[url]);
      accountXHR[url] = null;
      resolve(response);
    } catch (err) {
      accountXHR[url] = null;
      reject(err);
    }
    accountXHR[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function _postCSVURL(url, data = {}, headers) {
  // For csv Data
  return new Promise((resolve, reject) => {
    if (accountXHR[url]) {
      accountXHR[url].cancel("cancelled due to concurrent request");
    }

    accountXHR[url] = originalAxios.CancelToken.source();

    try {
      const response = api.post(`/bo${url}`, data, accountXHR[url]);
      accountXHR[url] = null;
      resolve(response);
    } catch (err) {
      accountXHR[url] = null;
      reject(err);
    }
    accountXHR[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function _putURL(url, data = {}) {
  // For Data Update
  return new Promise((resolve, reject) => {
    if (accountXHR[url]) {
      accountXHR[url].cancel("cancelled due to concurrent request");
    }

    accountXHR[url] = originalAxios.CancelToken.source();

    try {
      const response = api.put(`/bo${url}`, data, accountXHR[url]);
      accountXHR[url] = null;
      resolve(response);
    } catch (err) {
      accountXHR[url] = null;
      reject(err);
    }
    accountXHR[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

function _deleteURL(url, data = {}) {
  // For Data deletion
  return new Promise((resolve, reject) => {
    if (accountXHR[url]) {
      accountXHR[url].cancel("cancelled due to concurrent request");
    }

    accountXHR[url] = originalAxios.CancelToken.source();

    try {
      const response = api.delete(`/bo${url}`, data, accountXHR[url]);
      accountXHR[url] = null;
      resolve(response);
    } catch (err) {
      accountXHR[url] = null;
      reject(err);
    }
    accountXHR[url] = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function accountSearch(str) {
  return _getURL(`/accounts/search?contains=${str}`);
}

export function accountAdvanceSearch(params) {
  return _getURL(`/accounts/search/details${params}`);
}

export function accountMoreInfo(str) {
  return _getURL(`/accounts/${str}?ignoreValidation=true`);
}

export function getAccountTypes() {
  return _getURL(`/staticdata/accounttypes`);
}

export function getOrigins() {
  return _getURL(`/origins?onlyDisplayed=true`);
}

export function getLines() {
  return _getURL(`/lines?onlyActiveLines=true&agentId=-4`);
}

export function getPackages() {
  return _getURL(`/staticdata/packages`);
}

export function getPriceFormats() {
  // return _getURL(`/prices/formats?longDescription=true&onlyEnabled=true`);
  return _getURL(`/prices/formats?&onlyEnabled=true`);
}

export function getLanguages() {
  return _getURL(`/languages`);
}

export function getCountries() {
  return _getURL(`/countries`);
}

export function getReferralMethods() {
  return _getURL(`/referralmethods`);
}

export function getCurrencies() {
  return _getURL(`/currencies`);
}

export function getSecurityQuestions() {
  return _getURL(`/securityquestions`);
}

export function validatePin(id, pin) {
  return _postURL(`/accounts/${id}/validatePin`, { pin });
}

export function fetchBrands() {
  return _getURL(`/brands`);
}

export function validateCSV(files) {
  const url = "/bo/adjustments/bulk/confirm";

  return performHttpCall(fetchItemXHR, httpMethods.HTTP_CSVPOST, url, files, header3)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function uploadBulkCredit(bulkCreditDetailsList, brandId) {
  const url = "/bo/adjustments/bulk/upload";

  return performHttpCall(fetchItemXHR, httpMethods.HTTP_POST, url, { brandId, bulkCreditDetailsList }, header2)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function initializeCall(id) {
  return _postURL(`/accounts/${id}/customercall/start`, { accountId: id });
}

export function endCall(id) {
  return _postURL(`/accounts/${id}/customercall/end`, {});
}

export function getTransactions(id, params) {
  return _getURL(`/transactions?accountId=${id}${params}`, false, headers);
}

export function getTransactionDetails(id, params) {
  let url = `/transactions/${id}`;
  if (params) {
    url += `?${params}`;
  }

  return _getURL(url, true);
}

export function getRecentAccounts(id) {
  // return _getURL(`/recentaccounts`);
  return _getURL(`/accounts/${id}/recent`);
}

export function getAccountBalance(id) {
  return _getURL(`/accounts/${id}/balance`);
}

export function getAccountPermissions(id) {
  const operatorID = 83086; // TODO: Change base on operator : 83086 (phxoperator)

  return _getURL(`/accounts/${id}/permissions?extendedResponse=true&applicationId=4`); // Application id 4 = CM
}

export function getTransactionTypes(id) {
  if (id) {
    return _getURL(`/transactions/transactionSubType?transTypeIds=${id}`);
  }

  return _getURL(`/transactions/transactionSubType`);
}

export function accountAdjustment(id, params) {
  return _postURL(`/accounts/${id}/adjustments`, params);
}

export function getFXRate(fromId, toId) {
  return _getURL(`/fxrate?fromCurrencyId=${fromId}&toCurrencyId=${toId}`);
}

export function accountTransfer(id, params) {
  return _postURL(`/accounts/${id}/transfer`, params);
}

export function createAccount(params) {
  return _postURL(`/accounts/create`, params);
}

export function revokeTransaction(transactionId, method, params) {
  return _putURL(`/transactions/${transactionId}/${method}`, params);
}
let deleteTransactionXhr;
export function deleteTransaction(transactionId, params) {
  const url = `/bo/transactions/${transactionId}`;

  return performHttpCall(deleteTransactionXhr, httpMethods.HTTP_DELETE, url, params)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
  // return _deleteURL(`/transactions/${transactionId}`, params);
}

export function getP8Settings(params) {
  return _postURL(`/settings/list`, params);
}

// For Dashboard
export function getBetStatistics(id) {
  return _getURL(`/accounts/${id}/accountbetstats/details`, false, headers);
}

export function getAccountStats(id) {
  return _getURL(`/accounts/${id}/accountstats`, false, headers);
}

export function getAccountStatus(id) {
  return _getURL(`/accounts/${id}/accountStatus`, false, headers);
}

export function updateAccountStatus(id, params) {
  return _putURL(`/accounts/${id}/accountStatus`, params);
}

export function accountDetailsUpdate(id, params) {
  return _putURL(`/accounts/${id}`, params);
}

export function getDpAccount(id) {
  return _getURL(`/${id}/dp/getDpAccount`, false, headers);
}

export function createDpAccount(id, params) {
  return _postURL(`/${id}/dp/register`, params);
}

export function disableDpAccount(id, params) {
  return _postURL(`/${id}/dp/disableDpAccount`, params);
}

export function preferDpAccount(id, params) {
  return _postURL(`/${id}/dp/makePreferredAccount`, params);
}

export function paybackDpAccount(id, params) {
  return _postURL(`/${id}/dp/with`, params);
}

export function getChatHistory(id, params) {
  return _getURL(`/chat/sessions/?customerId=${id}${params}`, false, headers);
}

export function getChatSessions(id) {
  return _getURL(`/chat/sessions/${id}/messages`, false, headers);
}

export function getComments(id) {
  return _getURL(`/accounts/${id}/comments`, false, headers);
}

export function updateCallCenterComment(id, params) {
  return _putURL(`/accounts/${id}/comments/callCentreComment`, params);
}

export function addComment(id, params) {
  return _postURL(`/accounts/${id}/comments`, params);
}

export function updateComment(id, commentId, params) {
  return _putURL(`/accounts/${id}/comments/${commentId}`, params);
}

export function deleteComment(id, commentId) {
  return _deleteURL(`/accounts/${id}/comments/${commentId}`, {});
}

export function getAccountSecurityDetails(id) {
  return _getURL(`/accounts/${id}/security`, false, headers);
}

export function getCustomerBankAccountDetail(id) {
  return _getURL(`/banks/${id}/retrieveBankAccounts`, false, headers);
}

export function updateAccountSecurityDetails(id, params) {
  return _putURL(`/accounts/${id}/security`, params);
}

export function updateCustomerBankAccount(id, params) {
  return _putURL(`/banks/${id}/bankAccountDetails`, params);
}

export function updateAccountSecurityPassword(id, method, params) {
  switch (method) {
    case "password":
      return _putURL(`/accounts/${id}/security/${method}`, params);
      break;
    case "passwordemail":
      // return _postURL(`/accounts/${id}/security/${method}`, params)
      return _postURL(`/accounts/${id}/security/passwordreset?mode=EMAIL`);
      break;
    case "passwordsms":
      return _postURL(`/accounts/${id}/security/passwordreset?mode=SMS`);
      break;
  }
}

export function getPromoRewards(id) {
  return _getURL(`/accounts/${id}/promorewards`, false, headers);
}

export function getPerformanceSummary(id) {
  return _getURL(`/accounts/${id}/performance/summary?bUseCalendar=true`, false, headers);
}

export function getAccountMoreDetails(id) {
  return _getURL(`/accounts/${id}/accountDetails`, false, headers);
}

export function updateAccountMoreDetails(id, params) {
  return _putURL(`/accounts/${id}/accountDetails`, params);
}

export function getPaymentProviders(id) {
  return _getURL(`/accounts/${id}/financials/payments/paymentmethodproviders`, false, headers);
}

export function getPerformance(id, params) {
  return _getURL(`/accounts/${id}/performance/data${params}`, false, headers);
}

// export function getWagerLimitGroupInfo(id) {
//     return _getURL(`/outcomewagerlimits?wagerLimitGroupId=${id}`, false, headers);
// }

export function getWagerLimitGroupInfo(id, eventPathId) {
  return _getURL(
    `/outcomewagerlimits?wagerLimitGroupId=${id}&eventPathId=${eventPathId}&getWagerLimits=true`,
    false,
    headers,
  );
}

export function getEventPathsFromCode(code) {
  return _getURL(`/service/eventpaths/${code}`, false, headers);
}

export function getAccountWagerLimits(id) {
  return _getURL(`/accounts/${id}/accountwagerlimits?includeSportPath=true`, false, headers);
}

export function saveWagerLimit(id, params) {
  return _postURL(`/accounts/${id}/accountwagerlimits`, params);
}

export function getPlayerProfileFlags(id) {
  return _getURL(`/accounts/${id}/profileflags`, false, headers);
}

export function savePlayerProfileFlag(id, params) {
  return _postURL(`/accounts/${id}/profileflags`, params);
}

export function getAccountDetailsForSettings(id) {
  return _getURL(`/accounts/${id}`, false, headers);
}

export function getBanksBYCountry(id) {
  const url = `/bo/countries/${id}/banks`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null, header2)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function getMoneyTypes() {
  const url = `bo/moneytypes`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null, header2)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
export function fetchOptionalFields() {
  const url = `bo/accounts/fields?originId=1`;

  return performHttpCall(null, httpMethods.HTTP_GET, url, null, header2)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}

export function doExpirePromotionReward(promoRewardId) {
  const url = `bo/campaignpromotionrewards/expire/${promoRewardId}`;

  return performHttpCall(null, httpMethods.HTTP_POST, url, null, header2)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
