import actionTypes from "../../constants";

const initialState = {
  history: null,
  historyLoading: false,
  messages: null,
  messagesLoading: false,
};

const customerManagerChat = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CHAT_HISTORY:
      return {
        ...state,
        history: null,
        historyLoading: true,
      };
    case actionTypes.FETCH_CHAT_HISTORY_SUCCEEDED:
      return {
        ...state,
        history: action.result,
        historyLoading: false,
      };
    case actionTypes.FETCH_CHAT_HISTORY_FAILED:
      return {
        ...state,
        historyLoading: false,
      };
    case actionTypes.FETCH_CHAT_SESSION:
      return {
        ...state,
        messages: null,
        messagesLoading: true,
      };
    case actionTypes.FETCH_CHAT_SESSION_SUCCEEDED:
      return {
        ...state,
        messages: action.result,
        messagesLoading: false,
      };
    case actionTypes.FETCH_CHAT_SESSION_FAILED:
      return {
        ...state,
        messages: null,
        messagesLoading: false,
      };
    case actionTypes.END_CALL_SUCCEEDED:
      return initialState;
    default:
      return { ...state };
  }
};

export default customerManagerChat;
