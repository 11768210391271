import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// import reportsList from "reportsManagerReducers/reportsListReducers";
import { REHYDRATE } from "redux-persist";
import { PURGE } from "redux-persist/es/constants";

import actionTypes from "../constants";
import opponentsReducers from "../containers/EventCreator/Pages/Opponents/reducers/opponentsReducers";
import playersReducers from "../containers/EventCreator/Pages/Opponents/reducers/playersReducers";
import eventCreatorModes from "../containers/EventCreator/reducers/eventCreatorModes";
import eventCreatorPages from "../containers/EventCreator/reducers/eventCreatorPages";
import reportItemReducers from "../containersNew/ReportsManager/reducers/reportItemReducers";
import reportsListReducers from "../containersNew/ReportsManager/reducers/reportsListReducers";
import { AUTH_USER_UNAUTHORIZED } from "../redux/actions/auth-actions";
import authSlice, { getAuthInitialState } from "../redux/slices/authSlice";
import brandSlice from "../redux/slices/brandSlice";

import retailSlice, { getRetailInitialState } from "../redux/slices/retailSlice";

import betReferralSlice, {
  betReferralOperatorStatsInitialState,
  getBetReferralInitialState,
} from "../redux/slices/betReferralSlice";

import apiConstants from "./apiConstants";
import apps from "./apps";
import customerManagerAccount from "./customerManager/account";
import customerManagerChat from "./customerManager/chat";
import customerManagerComments from "./customerManager/comments";
import customerManagerConstants from "./customerManager/constants";
import customerManagerCreateAccount from "./customerManager/createAccount";
import customerManagerGlobal from "./customerManager/global";
import customerManagerModal from "./customerManager/modal";
import customerManagerPerformance from "./customerManager/performance";
import customerManagerSecurity from "./customerManager/security";
import customerManagerSettings from "./customerManager/settings";
import customerManagerTransactions from "./customerManager/transactions";
import editMarket from "./editMarket";
import eventPathTree from "./eventPathTree";
import eventPathTreeFilter from "./eventPathTreeFilter";
import market from "./market";
import marketFeedInfo from "./marketFeedInfo";
import marketStateDetails from "./marketStateDetails";
import modals from "./modals";
import notifications from "./notification";
import outcome from "./outcome";
import outcomeWagerLimits from "./outcomeWagerLimits";
import resultsViewer from "./resultsViewer";
import resultsViewerData from "./resultsViewerData";
import resultsViewerParameters from "./resultsViewerParameters";
import riskAnalysis from "./riskAnalysis";
import riskData from "./riskData";
import riskDataChanges from "./riskDataChanges";
import dailyStatistics from "./riskManager/dailyStatistics";
import globalAccumulatorRisks from "./riskManager/globalAccumulatorRisks";
import lookupPublishModes from "./riskManager/lookupPublishModes";
import riskParameters from "./riskParameters";
import riskTransaction from "./riskTransaction";
import startup from "./startup";
import user from "./user";
import userRiskPreferences from "./userRiskPreferences";

import bonusManager from "containersV2/BonusManager/App/reducers";
import bonusManagerTree from "containersV2/BonusManager/Sidebar/reducers";
import combinationRiskManager from "containersV2/CombiRiskManager/reducers";
import customerChat from "containersV2/CustomerChat/reducers";
import eventCreatorApp from "containersV2/EventCreator/App/reducers";
import betRestrictions from "containersV2/EventCreator/BetRestrictions/reducers";
import applicableTemplates from "containersV2/EventCreator/BulkUpdate/reducers";
import feedHistory from "containersV2/EventCreator/Event/FeedHistory/reducers";
import eventCreatorEvent from "containersV2/EventCreator/Event/reducers";
import eventCreatorEventMarkets from "containersV2/EventCreator/EventMarkets/reducers";
import gameResults from "containersV2/EventCreator/GameResults/reducers";
import eventCreatorEventPath from "containersV2/EventCreator/Path/reducers";
import instantAction from "containersV2/InstantAction/reducers";
import newJackBotManager from "containersV2/JackpotBetManager/AddJackpotBet/Components/EventTreeCreator/reducers";
import addJackPotBetSuccess from "containersV2/JackpotBetManager/AddJackpotBet/reducers";
import jackpotBetManagerApp from "containersV2/JackpotBetManager/App/reducers";
import winnerData from "containersV2/JackpotBetManager/WinnerDialogBox/reducers";
import operatorManagerApp from "containersV2/OperatorManager/App/reducers";
import modifiedGroupForm from "containersV2/OperatorManager/GroupForm/reducer";
import operatorManagerModal from "containersV2/OperatorManager/Main/reducer";
import operatorDetailsForm from "containersV2/OperatorManager/OperatorForm/reducer";
import operatorList from "containersV2/OperatorManager/OperatorList/reducer";
import permissionPanel from "containersV2/OperatorManager/PermissionPanel/reducers";
import paymentHandler from "containersV2/PaymentHandler/reducers";
import settingsbrowser from "containersV2/SettingsBrowser/App/reducers";
import sportsTree from "containersV2/SportsTree/reducers";
import translationmanager from "containersV2/TranslationManager/App/reducers";
import utilitiesApp from "containersV2/Utilities/App/reducers";
import CombLiability_Limits from "containersV2/WagerLimits/App/Components/CombLiabilityIndicators/reducers";
import WL_Liability from "containersV2/WagerLimits/App/Components/LiabilityIndicators/reducers";
import WL_PlayerProfileGroup from "containersV2/WagerLimits/App/Components/PlayerProfileGroups/reducers";
import WL_StakeDistributation from "containersV2/WagerLimits/App/Components/StakeDistribution/reducers";
import WagerLimitsApp from "containersV2/WagerLimits/App/reducers";

const genericBaseAnonymousReducerWrapper = (originalReducer) => (state, action) => {
  switch (action.type) {
    // Todo: add 401 action to clean the storage.
    // Todo: dispatch `PURGE` action when error occurs.
    case PURGE:
      return originalReducer(undefined, action);
    default:
      return originalReducer(state, action);
  }
};

const genericBaseLoggedInReducerWrapper = (originalReducer) => (state, action) => {
  switch (action.type) {
    // Todo: add 401 action to clean the storage.
    // Todo: dispatch `PURGE` action when error occurs.
    case actionTypes.LOGOUT:
    case PURGE:
      return originalReducer(undefined, action);
    default:
      return originalReducer(state, action);
  }
};

const authReducerWrapper = (state, action) => {
  switch (action.type) {
    // Todo: add 401 action to clean the storage.
    // Todo: dispatch `PURGE` action when error occurs.
    case REHYDRATE: {
      return authSlice(
        getAuthInitialState({
          accountId: action.payload?.auth?.accountId,
          authToken: action.payload?.auth?.authToken,
          language: action.payload?.auth?.language,
          loggedIn: action.payload?.auth?.loggedIn,
          rememberedUsername: action.payload?.auth?.rememberedUsername,
          username: action.payload?.auth?.username,
        }),
        action,
      );
    }
    case AUTH_USER_UNAUTHORIZED:
      return authSlice(
        getAuthInitialState({
          ...state,
          accountId: undefined,
          authToken: undefined,
          loggedIn: false,
          username: undefined,
        }),
        action,
      );
    case PURGE:
      return authSlice(undefined, action);
    default:
      return authSlice(state, action);
  }
};

const betReferralReducerWrapper = (state, action) => {
  switch (action.type) {
    // Todo: add 401 action to clean the storage.
    // Todo: dispatch `PURGE` action when error occurs.
    case REHYDRATE: {
      return betReferralSlice(
        getBetReferralInitialState({
          operatorStats: action.payload?.betReferral?.operatorStats,
          subscribedFilter: action.payload?.betReferral?.subscribedFilter,
          subscriptionRequested: action.payload?.betReferral?.subscriptionRequested,
        }),
        action,
      );
    }
    case AUTH_USER_UNAUTHORIZED:
      return betReferralSlice(
        getBetReferralInitialState({
          ...state,
          operatorStats: betReferralOperatorStatsInitialState,
          subscribed: false,
          subscriptionRequested: false,
        }),
        action,
      );
    case PURGE:
      return betReferralSlice(undefined, action);
    default:
      return betReferralSlice(state, action);
  }
};

// aggregator
export const reducers = combineReducers({
  CombLiability_Limits: genericBaseLoggedInReducerWrapper(CombLiability_Limits),
  WL_Liability: genericBaseLoggedInReducerWrapper(WL_Liability),
  WL_PlayerProfileGroup: genericBaseLoggedInReducerWrapper(WL_PlayerProfileGroup),
  WL_StakeDistributation: genericBaseLoggedInReducerWrapper(WL_StakeDistributation),
  WagerLimitsApp: genericBaseLoggedInReducerWrapper(WagerLimitsApp),
  addJackPotBetSuccess: genericBaseLoggedInReducerWrapper(addJackPotBetSuccess),
  apiConstants: genericBaseLoggedInReducerWrapper(apiConstants),
  applicableTemplates: genericBaseLoggedInReducerWrapper(applicableTemplates),
  apps: genericBaseLoggedInReducerWrapper(apps),
  auth: authReducerWrapper,
  betReferral: betReferralReducerWrapper,
  betRestrictions: genericBaseLoggedInReducerWrapper(betRestrictions),
  bonusManager: genericBaseLoggedInReducerWrapper(bonusManager),
  bonusManagerTree: genericBaseLoggedInReducerWrapper(bonusManagerTree),
  brand: genericBaseLoggedInReducerWrapper(brandSlice),
  combinationRiskManager: genericBaseLoggedInReducerWrapper(combinationRiskManager),
  customerChat: genericBaseLoggedInReducerWrapper(customerChat),
  customerManagerAccount: genericBaseLoggedInReducerWrapper(customerManagerAccount),
  customerManagerChat: genericBaseLoggedInReducerWrapper(customerManagerChat),
  customerManagerComments: genericBaseLoggedInReducerWrapper(customerManagerComments),
  customerManagerConstants: genericBaseLoggedInReducerWrapper(customerManagerConstants),
  customerManagerCreateAccount: genericBaseLoggedInReducerWrapper(customerManagerCreateAccount),
  customerManagerGlobal: genericBaseLoggedInReducerWrapper(customerManagerGlobal),

  customerManagerModal: genericBaseLoggedInReducerWrapper(customerManagerModal),
  customerManagerPerformance: genericBaseLoggedInReducerWrapper(customerManagerPerformance),
  customerManagerSecurity: genericBaseLoggedInReducerWrapper(customerManagerSecurity),
  customerManagerSettings: genericBaseLoggedInReducerWrapper(customerManagerSettings),
  customerManagerTransactions: genericBaseLoggedInReducerWrapper(customerManagerTransactions),

  dailyStatistics: genericBaseLoggedInReducerWrapper(dailyStatistics),

  editMarket: genericBaseLoggedInReducerWrapper(editMarket),

  eventCreatorApp: genericBaseLoggedInReducerWrapper(eventCreatorApp),

  eventCreatorEvent: genericBaseLoggedInReducerWrapper(eventCreatorEvent),

  eventCreatorEventMarkets: genericBaseLoggedInReducerWrapper(eventCreatorEventMarkets),

  eventCreatorEventPath: genericBaseLoggedInReducerWrapper(eventCreatorEventPath),

  // eventCreatorPathTree: genericBaseLoggedInReducerWrapper(),
  eventCreatorModes: genericBaseLoggedInReducerWrapper(eventCreatorModes),

  eventCreatorPages: genericBaseLoggedInReducerWrapper(eventCreatorPages),

  eventPathTree: genericBaseLoggedInReducerWrapper(eventPathTree),

  eventPathTreeFilter: genericBaseLoggedInReducerWrapper(eventPathTreeFilter),

  feedHistory: genericBaseLoggedInReducerWrapper(feedHistory),

  form: genericBaseLoggedInReducerWrapper(formReducer),

  gameResults: genericBaseLoggedInReducerWrapper(gameResults),

  globalAccumulatorRisks: genericBaseLoggedInReducerWrapper(globalAccumulatorRisks),

  instantAction: genericBaseLoggedInReducerWrapper(instantAction),

  jackpotBetManagerApp: genericBaseLoggedInReducerWrapper(jackpotBetManagerApp),

  lookupPublishModes: genericBaseLoggedInReducerWrapper(lookupPublishModes),

  market: genericBaseLoggedInReducerWrapper(market),

  marketFeedInfo: genericBaseLoggedInReducerWrapper(marketFeedInfo),

  marketStateDetails: genericBaseLoggedInReducerWrapper(marketStateDetails),
  modals: genericBaseLoggedInReducerWrapper(modals),

  modifiedGroupForm: genericBaseLoggedInReducerWrapper(modifiedGroupForm),

  newJackBotManager: genericBaseLoggedInReducerWrapper(newJackBotManager),

  notifications: genericBaseLoggedInReducerWrapper(notifications),

  operatorDetailsForm: genericBaseLoggedInReducerWrapper(operatorDetailsForm),

  operatorList: genericBaseLoggedInReducerWrapper(operatorList),

  // eventPathTags: genericBaseLoggedInReducerWrapper(),
  // eventPathDetails: genericBaseLoggedInReducerWrapper(),
  operatorManagerApp: genericBaseLoggedInReducerWrapper(operatorManagerApp),

  operatorManagerModal: genericBaseLoggedInReducerWrapper(operatorManagerModal),

  opponentsReducers: genericBaseLoggedInReducerWrapper(opponentsReducers),

  outcome: genericBaseLoggedInReducerWrapper(outcome),

  outcomeWagerLimits: genericBaseLoggedInReducerWrapper(outcomeWagerLimits),

  paymentHandler: genericBaseLoggedInReducerWrapper(paymentHandler),

  permissionPanel: genericBaseLoggedInReducerWrapper(permissionPanel),

  playersReducers: genericBaseLoggedInReducerWrapper(playersReducers),

  report: genericBaseLoggedInReducerWrapper(reportItemReducers),

  reportsList: genericBaseLoggedInReducerWrapper(reportsListReducers),

  resultsViewer: genericBaseLoggedInReducerWrapper(resultsViewer),

  resultsViewerData: genericBaseLoggedInReducerWrapper(resultsViewerData),

  resultsViewerParameters: genericBaseLoggedInReducerWrapper(resultsViewerParameters),

  retail: genericBaseLoggedInReducerWrapper(retailSlice),

  riskAnalysis: genericBaseLoggedInReducerWrapper(riskAnalysis),
  riskData: genericBaseLoggedInReducerWrapper(riskData),
  riskDataChanges: genericBaseLoggedInReducerWrapper(riskDataChanges),
  riskParameters: genericBaseLoggedInReducerWrapper(riskParameters),
  riskTransaction: genericBaseLoggedInReducerWrapper(riskTransaction),
  routing: genericBaseLoggedInReducerWrapper(routerReducer),
  settingsbrowser: genericBaseLoggedInReducerWrapper(settingsbrowser),
  sportsTree: genericBaseLoggedInReducerWrapper(sportsTree),
  startup: genericBaseLoggedInReducerWrapper(startup),
  translationmanager: genericBaseLoggedInReducerWrapper(translationmanager),
  user: genericBaseLoggedInReducerWrapper(user),
  userRiskPreferences: genericBaseLoggedInReducerWrapper(userRiskPreferences),
  utilitiesApp: genericBaseLoggedInReducerWrapper(utilitiesApp),
  winnerData: genericBaseLoggedInReducerWrapper(winnerData),
});
