import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import { store } from "./store";
import de_translation from "./translations/de/translation";
import en_translation from "./translations/en/translation";
import es_translation from "./translations/es/translation";
import fr_translation from "./translations/fr/translation";
import in_translation from "./translations/in/translation";
import ja_translation from "./translations/ja/translation";
import ko_translation from "./translations/ko/translation";
import ms_translation from "./translations/ms/translation";
import pt_translation from "./translations/pt/translation";
import ru_translation from "./translations/ru/translation";
import th_translation from "./translations/th/translation";
import vi_translation from "./translations/vi/translation";
import zh_translation from "./translations/zh/translation";

// backdoor hack to avoid refactoring old features
const getAuthLanguage = () => {
  try {
    return store.getState().auth.language;
  } catch (err) {
    // do nothing
  }

  return "en";
};

i18n
  .use(Backend)
  .use(initReactI18next)

  .init({
    debug: true,
    defaultNS: "translation",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },

    lng: getAuthLanguage() || "en",

    ns: ["translation"],
    resources: {
      de: {
        translation: de_translation,
      },
      en: {
        translation: en_translation,
      },
      es: {
        translation: es_translation,
      },
      fr: {
        translation: fr_translation,
      },
      in: {
        translation: in_translation,
      },
      ja: {
        translation: ja_translation,
      },
      ko: {
        translation: ko_translation,
      },
      ms: {
        translation: ms_translation,
      },
      pt: {
        translation: pt_translation,
      },
      ru: {
        translation: ru_translation,
      },
      th: {
        translation: th_translation,
      },
      vi: {
        translation: vi_translation,
      },
      zh: {
        translation: zh_translation,
      },
    },
  });

export default i18n;
