import _ from "lodash";
import { createTransform } from "redux-persist";

function persistFilter(state, paths = []) {
  const subset = {};
  paths.forEach((path) => {
    const value = _.get(state, path);
    if (typeof value !== "undefined") {
      _.set(subset, path, value);
    }
  });

  return subset;
}

function createFilter(reducerName, inboundPaths, outboundPaths) {
  return createTransform(
    (inboundState) => (inboundPaths ? persistFilter(inboundState, inboundPaths) : inboundState),
    (outboundState) => (outboundPaths ? persistFilter(outboundState, outboundPaths) : outboundState),
    {
      whitelist: [reducerName],
    },
  );
}

// Explicitly specified what fields to persist for `auth` reducer.
const authReducersFilter = createFilter("auth", [
  "accountId",
  "authToken",
  "language",
  "loggedIn",
  "username",
  "rememberedUsername",
]);
const betReferralReducersFilter = createFilter("betReferral", [
  "subscriptionRequested",
  "subscribedFilter",
  "operatorStats",
]);

export default ({ storage }) => ({
  key: "root",
  stateReconciler: false,
  storage,
  transforms: [authReducersFilter, betReferralReducersFilter], // Add here
  version: 1,
  whitelist: ["auth", "betReferral"], // Add here
});
