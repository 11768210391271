import constants from "./constants";

const initialState = {
  previousData: null,
  searchResult: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.RESET_PAGE_STATE:
      return {
        ...state,
        enable_UndoSaveButton: false,
      };
    case constants.FETCH_SEARCH_TEXT_DATA_SETTING_SUCCEEDED:
      return {
        ...state,
        previousData: JSON.stringify(action.results),
        searchResult: action.results,
        secPerData: JSON.stringify(action.results),
        selectedVocabDelete: false,
      };
    case constants.FETCH_SEARCH_TEXT_DATA_FAILED:
      return {
        ...state,
        searchResult: null,
      };
    case constants.FETCH_ALL_SETTINGS_DATA_SUCCEEDED:
      // TODO return data for dropdowns here
      // let selectedLanguages = [];
      // action.results.map((data,index)=>{
      //   if(data.preferred){
      //     selectedLanguages.push({index:index,locals:data.locale.localeString})
      //   }
      // })
      return {
        ...state,
        searchResult: action.results,
        // selectedLanguages
      };
    case constants.FETCH_ALL_SETTINGS_DATA_FAILED:
      return {
        ...state,
        searchResult: null,
      };
    case constants.UNDO_TEXT_CHANGES:
      return {
        ...state,
        enable_UndoSaveButton: false,
        searchResult: action.baseResult,
      };

    default:
      return state;
  }
}
