import _ from "lodash";
import moment from "moment";

import constants from "./constants";

const initialState = {
  fetchingLines: false,
  lineBackup: [],
  lines: [],
  modified: false,
  openAddNewPopup: false,
  selectedRow: null,
};

const utilitiesLineManagement = (state = initialState, action) => {
  const lines = state.lines;
  const lineBackup = state.lineBackup;

  switch (action.type) {
    case constants.FETCH_LINES:
      return {
        ...state,
        fetchingLines: true,
      };
    case constants.FETCH_LINES_SUCCEEDED:
      return {
        ...state,
        fetchingLines: false,
        lineBackup: _.cloneDeep(action.response),
        lines: _.cloneDeep(action.response),
        selectedRow: null,
      };
    case constants.FETCH_LINES_FAILED:
      return {
        ...state,
        fetchingLines: false,
        lineBackup: _.cloneDeep([]),
        lines: _.cloneDeep([]),
      };
    case constants.OPEN_ADD_LINE_POPUP:
      return {
        ...state,
        openAddNewPopup: action.value,
      };
    case constants.SELECT_LINE:
      return {
        ...state,
        selectedRow: state.selectedRow == null ? action.id : state.selectedRow == action.id ? null : action.id,
      };
    case constants.ON_ADD_NEW_LINE:
      const newLine = action.newLine;
      const insertPosition = action.selectedRow;
      let modifiedLines = [];
      let position = insertPosition;
      if (state.openAddNewPopup && state.openAddNewPopup.id) {
        const index = _.findIndex(lines, (l) => l.id == state.openAddNewPopup.id);
        lines[index]["description"] = newLine;
        modifiedLines = lines;
        _.map(modifiedLines, (item, index) => {});
      } else {
        _.map(lines, (item, index) => {
          if (position == item.ordinalPosition) {
            if (position == insertPosition) {
              modifiedLines.push({
                active: true,
                agentAccountId: -4,
                description: newLine,
                ordinalPosition: position,
                parentLineId: 0,
              });
              position += 1;
              item["ordinalPosition"] = position;
              modifiedLines.push(item);
            } else {
              position += 1;
              item["ordinalPosition"] = position;
              modifiedLines.push(item);
            }
          } else {
            modifiedLines.push(item);
          }
        });
      }

      return {
        ...state,
        lines: _.cloneDeep(modifiedLines),
        modified: JSON.stringify(modifiedLines) != JSON.stringify(lineBackup),
        openAddNewPopup: false,
        selectedRow: null,
      };
    case constants.UNDO_LINE_MANAGEMENT:
      return {
        ...state,
        lines: _.cloneDeep(state.lineBackup),
        modified: false,
        selectedRow: null,
      };
    case constants.SAVE_LINE_MANAGEMENT_SUCCEEDED:
      return {
        ...state,
        lineBackup: _.cloneDeep(action.response),
        lines: _.cloneDeep(action.response),
        modified: false,
      };
    case constants.ON_LINE_ACTIVE:
      lines[action.index]["active"] = action.value;
      _.map(lines, (item, index) => {});

      return {
        ...state,
        lines: _.cloneDeep(lines),
        modified: JSON.stringify(lines) != JSON.stringify(lineBackup),
      };
    case constants.ON_MOVE_DOWN:
      const lineToSwap = _.findIndex(lines, (l) => l.id == state.selectedRow);
      const select = lines[lineToSwap]["id"];
      // let isDown = action.isDown;
      if (action.isDown) {
        lines[lineToSwap]["ordinalPosition"] = lines[lineToSwap]["ordinalPosition"] + 1;
        lines.splice(lineToSwap + 1, 1, lines.splice(lineToSwap, 1, lines[lineToSwap + 1])[0]);
      } else {
        lines[lineToSwap]["ordinalPosition"] = lines[lineToSwap]["ordinalPosition"] - 1;
        lines.splice(lineToSwap - 1, 1, lines.splice(lineToSwap, 1, lines[lineToSwap - 1])[0]);
      }
      //     isDown = action.isDown,
      //     lineSwapWith = action.isDown ? lineToSwap + 1 : lineToSwap - 1,
      //     ordinalPosition_of_swapWith = lines[lineSwapWith]['ordinalPosition']
      // let select = lines[lineSwapWith]['id']
      //     lines[lineToSwap]['ordinalPosition'] = ordinalPosition_of_swapWith
      //     lines[lineSwapWith]['ordinalPosition'] = lines[lineToSwap]['ordinalPosition']
      //     lines.splice( lineSwapWith, 1, lines.splice(lineToSwap, 1, lines[lineSwapWith])[0])

      return {
        ...state,
        lines: _.cloneDeep(lines),
        modified: JSON.stringify(lines) != JSON.stringify(lineBackup),
        selectedRow: select,
      };
    default:
      return { ...state };
  }
};

export default utilitiesLineManagement;
