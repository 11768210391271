import { put, call, fork, select, takeLatest, delay } from "redux-saga/effects";

import actionTypes from "../../constants";
import { renderGenericErrorToastMessage } from "../constants";

import { toastr } from "components/toastr/index";
import i18n from "i18n";
import * as API from "services/customerManager";

const errMsg = i18n.t("Common.Something went wrong while connecting to server, please try again later");

function* getComments(action) {
  try {
    const { response, xhr } = yield call(API.getComments, action.id);
    if (response) {
      yield put({ result: response, type: actionTypes.FETCH_COMMENTS_SUCCEEDED });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.FETCH_COMMENTS_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.FETCH_COMMENTS_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* addComment(action) {
  try {
    const { response, xhr } = yield call(API.addComment, action.id, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.ADD_COMMENT_SUCCEEDED });
      yield put({ show: false, type: actionTypes.CLOSE_MODAL_COMMENT });
      yield call(getComments, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Comment successfully added") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.ADD_COMMENT_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.ADD_COMMENT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* updateCallCenterComment(action) {
  try {
    const { response, xhr } = yield call(API.updateCallCenterComment, action.id, action.params);
    if (response) {
      yield put({ callCentreComment: action.params.callCentreComment, type: actionTypes.UPDATE_CC_COMMENT_SUCCEEDED });
      yield put({ show: false, type: actionTypes.CLOSE_MODAL_COMMENT });
      yield call(getComments, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Saved Successfully") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.UPDATE_CC_COMMENT_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.UPDATE_CC_COMMENT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* updateComment(action) {
  try {
    const { response, xhr } = yield call(API.updateComment, action.id, action.commentId, action.params);
    if (response) {
      yield put({ result: response, type: actionTypes.UPDATE_COMMENT_SUCCEEDED });
      yield put({ show: false, type: actionTypes.CLOSE_MODAL_COMMENT });
      yield call(getComments, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Comment successfully updated") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.UPDATE_COMMENT_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.UPDATE_COMMENT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

function* deleteComment(action) {
  try {
    const { response, xhr } = yield call(API.deleteComment, action.id, action.commentId);
    if (!xhr) {
      yield put({ type: actionTypes.DELETE_COMMENT_SUCCEEDED });
      yield call(getComments, { id: action.id });
      toastr.add({ message: i18n.t("Manager.Comment successfully deleted") });
    } else {
      const parseResponse = JSON.parse(xhr.response);
      const msg = parseResponse.errorMessage || parseResponse.exception.message;
      yield put({ errorMessage: msg, type: actionTypes.DELETE_COMMENT_FAILED });
      toastr.add({ message: msg, type: "ERROR" });
    }
  } catch (e) {
    yield put({ errorMessage: errMsg, type: actionTypes.DELETE_COMMENT_FAILED });
    yield call(renderGenericErrorToastMessage);
  }
}

export default function* customerManagerCommentsSaga() {
  yield takeLatest(actionTypes.ADD_COMMENT, addComment);
  yield takeLatest(actionTypes.FETCH_COMMENTS, getComments);
  yield takeLatest(actionTypes.DELETE_COMMENT, deleteComment);
  yield takeLatest(actionTypes.UPDATE_COMMENT, updateComment);
  yield takeLatest(actionTypes.UPDATE_CC_COMMENT, updateCallCenterComment);
}
