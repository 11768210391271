import actionTypes from "../constants";
import constants from "../containersV2/InstantAction/constants";

const initialState = {
  details: {
    id: "",
    username: "",
  },
  errMsg: "Something went wrong while logging in. Please try again later.",
  // flag: <i className="gb flag"></i>
  flag: "GB",

  isLoggedIn: false,

  isLoggingIn: null,

  logInFailed: null,

  logUsername: "",

  selectedLanguage: "en",

  userInfo: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN:
      return { ...state, isLoggingIn: true, logInFailed: false };
    case actionTypes.LOGIN_SUCCEEDED:
      return { ...state, details: action.details, isLoggedIn: true, isLoggingIn: false, logUsername: "" };
    case actionTypes.LOGIN_FAILED:
      return { ...state, errMsg: action.errMsg, isLoggedIn: false, isLoggingIn: false, logInFailed: true };
    case actionTypes.SET_ISLOGGEDIN:
      return { ...state, isLoggedIn: action.isLoggedIn };
    case actionTypes.LOGOUT:
      return { ...state, isLoggedIn: false };
    case actionTypes.SET_USER_DETAILS:
      return { ...state, details: action.details };
    case actionTypes.FETCH_USER_INFORMATION_SUCCEEDED:
      return { ...state, userInfo: action.userInfo };
    case constants.FETCH_DEFAULT_CURRENCY_SUCCEEDED:
      return { ...state, defaultCurrency: action.defaultCurrency };
    case constants.REMOVE_ERROR: {
      return { ...state, errMsg: "" };
    }
    case actionTypes.SET_SELECTED_LANGUAGE: {
      return {
        ...state,
        flag: action.flag,
        selectedLanguage: action.lang,
      };
    }
    case actionTypes.RESET_INFO:
      return {
        details: {
          id: "",
          username: "",
        },
        errMsg: "Something went wrong while logging in. Please try again later.",
        isLoggedIn: false,
        isLoggingIn: null,
        logInFailed: null,
      };
    default:
      return { ...state };
  }
}
