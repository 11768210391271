import { push, replace } from "react-router-redux";
import { put, call, select, takeLatest } from "redux-saga/effects";

import actionTypes from "../../constants";

import appActionTypes from "./constants";
import { formatCombinationTypes, mergeUpdateIndicators } from "./helpers";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function _sortByLabel(a, b) {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;

  return 0;
}

function* fetchFilterAccumulatorRiskData(action) {
  const { filterParams, params } = action;
  const { response, xhr } = yield call(API.fetchAccumulatorFilter, params, filterParams);
  if (response) {
    yield put({
      accumulatorRiskList: response.consolidatedAccumulatorRiskResponseList,
      totalPages: response.totalPages,
      type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_SUCCEEDED,
    });
    yield put({
      topPanelDetails: response.combinationRiskTotalsResponse,
      type: appActionTypes.SET_TOP_PANEL_DETAILS,
    });
    yield put({
      accumulatorRiskList: response.consolidatedAccumulatorRiskResponseList,
      type: appActionTypes.SHOULD_ITEM_RESET,
    });
  } else {
    yield put({ errorMessage: "Error", type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_FAILED });
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    toastr.add({ message: msg, type: "ERROR" });
  }
}

function* fetchArbetDetailAccumulatorRiskData(action) {
  const { params } = action;
  const { response, xhr } = yield call(API.fetchAccumulatorArbetDetails, params);
  if (response) {
    // yield put({
    //   type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED,
    //   arbetDetails: response.list,
    // })
    if (response.list.length === 0) {
      yield put({
        arbetDetails: response.list,
        type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED,
      });
    } else {
      yield put({
        arbetDetails: response.list,
        profileId: [...response.list].shift().accountId,
        type: appActionTypes.FETCH_PROFILE_FLAGS,
      });
    }
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED });
  }
}

function* reloadArbetDetailAccumulatorRiskData(action) {
  const { params } = action;
  const { response, xhr } = yield call(API.fetchAccumulatorArbetDetails, params);
  if (response) {
    yield put({
      arbetDetails: response.list,
      type: appActionTypes.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({
      errorMessage: "Error",
      type: appActionTypes.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_FAILED,
    });
  }
}

function* fetchDetailsAccumulatorRiskData(action) {
  const { paramId } = action;
  const { response, xhr } = yield call(API.fetchAccumulatorDetails, paramId);
  if (response) {
    yield put({
      riskDetails: response,
      type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER_FAILED });
  }
}

function* putIndicators(action) {
  const { defaultIndicators, indicators } = action;
  const { response, xhr } = yield call(API.putCombiIndicators, indicators);
  if (response) {
    yield put({ payload: { defaultIndicators, indicators }, type: appActionTypes.GET_UPDATED_LIABILITY_INDICATORS });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.PUT_COMBINATION_LIABILITY_INDICATOR_FAILED });
    toastr.add({ message: i18n.t("Combination.Failed to saved combination limit"), type: "ERROR" });
  }
}

function* fetchProfileFlags(action) {
  const { arbetDetails, profileId } = action;
  const { response, xhr } = yield call(API.fetchProfileFlags, profileId);
  if (response) {
    yield put({ flags: response, type: appActionTypes.FETCH_PROFILE_FLAGS_SUCCEEDED });
    yield put({
      arbetDetails,
      type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED,
    });
  } else {
    yield put({
      arbetDetails,
      type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS_SUCCEEDED,
    });
    yield put({ errorMessage: "Error", type: appActionTypes.FETCH_PROFILE_FLAGS_FAILED });
  }
}

function* setBlockingRule(action) {
  const { filterParams, filters, id, params } = action;
  const { response, xhr } = yield call(API.setBlockingRule, id, params);
  if (response) {
    yield put({ type: appActionTypes.SET_BLOCKING_RULE_SUCCEEDED });
    yield put({
      filterParams,
      params: filters,
      type: appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER,
    });
    yield put({ type: appActionTypes.TOGGLE_BLOCKING_RULE_MODAL, value: false });
    toastr.add({ message: i18n.t("Combination.New blocking rule applied!") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.SET_BLOCKING_RULE_FAILED });
    toastr.add({ message: i18n.t("Combination.Failed to set new blocking rule"), type: "ERROR" });
  }
}

function* fetchEventPathsFilter(action) {
  const { response, xhr } = yield call(API.fetchEventPathsFilter, action.itemId, action.itemType);
  if (response) {
    yield put({
      ...action,
      response,
      type: appActionTypes.FETCH_EVENT_PATHS_FILTER_SUCCEEDED,
    });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.FETCH_EVENT_PATHS_FILTER_FAILED });
  }
}

function* getUpdatedLiabilityIndicators(action) {
  const { defaultIndicators, indicators } = action.payload;

  const { response, xhr } = yield call(API.getUpdatedLiabilityIndicators, action.payload);
  if (response) {
    yield put({
      indicators: mergeUpdateIndicators(defaultIndicators, response),
      type: appActionTypes.PUT_COMBINATION_LIABILITY_INDICATOR_SUCCEEDED,
    });
    yield put({
      indicators: mergeUpdateIndicators(defaultIndicators, response),
      type: appActionTypes.UPDATE_COMBINATION_LIABILITY_INDICATOR,
    });
    yield put({ type: appActionTypes.RESET_LIMIT_CHANGE });
    toastr.add({ message: i18n.t("Combination.Successfully updated limits for combination") });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_PUT, xhr);
    yield put({ errorMessage: "Error", type: appActionTypes.PUT_COMBINATION_LIABILITY_INDICATOR_FAILED });
    toastr.add({ message: i18n.t("Combination.Failed to saved combination limit"), type: "ERROR" });
  }
}

export default function* combinationRiskManagerSaga() {
  yield takeLatest(appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_FILTER, fetchFilterAccumulatorRiskData);
  yield takeLatest(appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_DETAILS, fetchDetailsAccumulatorRiskData);
  yield takeLatest(
    appActionTypes.FETCH_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS,
    fetchArbetDetailAccumulatorRiskData,
  );
  yield takeLatest(
    appActionTypes.RELOAD_CONSOLIDATED_ACCUMULATOR_RISK_ARBET_DETAILS,
    reloadArbetDetailAccumulatorRiskData,
  );
  yield takeLatest(appActionTypes.PUT_COMBINATION_LIABILITY_INDICATOR, putIndicators);
  yield takeLatest(appActionTypes.FETCH_PROFILE_FLAGS, fetchProfileFlags);
  yield takeLatest(appActionTypes.SET_BLOCKING_RULE, setBlockingRule);
  yield takeLatest(appActionTypes.FETCH_EVENT_PATHS_FILTER, fetchEventPathsFilter),
    yield takeLatest(appActionTypes.GET_UPDATED_LIABILITY_INDICATORS, getUpdatedLiabilityIndicators);
}
