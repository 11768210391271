import originalAxios from "axios";

import api from "./api";

let fetchOutcomeWagerLimitsByGroupXhr = null;

export function fetchOutcomeWagerLimitsByGroup(outcomeId, wagerLimitsGroupId) {
  return new Promise((resolve, reject) => {
    const url = `/bo/outcomewagerlimits?outcomeId=${outcomeId}&inRunning=0&wagerLimitGroupId=${wagerLimitsGroupId}&marketTypeWagerLimitGroupId=1`;

    if (fetchOutcomeWagerLimitsByGroupXhr) {
      fetchOutcomeWagerLimitsByGroupXhr.cancel("cancelled due to concurrent request");
    }

    fetchOutcomeWagerLimitsByGroupXhr = originalAxios.CancelToken.source();

    try {
      const response = api.get(url, fetchOutcomeWagerLimitsByGroupXhr);
      fetchOutcomeWagerLimitsByGroupXhr = null;
      resolve(response);
    } catch (err) {
      fetchOutcomeWagerLimitsByGroupXhr = null;
      reject(err);
    }
    fetchOutcomeWagerLimitsByGroupXhr = null;
  })
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
