import actionTypes from "../../constants";

const initialState = {
  fetchingGlobalAccumulatorRisksFailed: null,
  globalAccumulatorRisks: [],
  isFetchingGlobalAccumulatorRisks: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS:
      return { ...state, fetchingGlobalAccumulatorRisksFailed: false, isFetchingGlobalAccumulatorRisks: true };
    case actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS_SUCCEEDED:
      return {
        ...state,
        globalAccumulatorRisks: action.globalAccumulatorRisks,
        isFetchingGlobalAccumulatorRisks: false,
      };
    case actionTypes.FETCH_GLOBAL_ACCUMULATOR_RISKS_FAILED:
      return { ...state, fetchingGlobalAccumulatorRisksFailed: true, isFetchingGlobalAccumulatorRisks: false };
    default:
      return { ...state };
  }
}
