import constants from "./constants";

export function fetchApplications(operatorType, operatorId) {
  return {
    operatorId,
    operatorType,
    type: constants.FETCH_APPLICATIONS,
  };
}

export function fetchActions(operatorType, operatorId) {
  return {
    operatorId,
    operatorType,
    type: constants.FETCH_ACTIONS,
  };
}

export function fetchReports(operatorGroupId, operatorId) {
  return {
    operatorGroupId,
    operatorId,
    type: constants.FETCH_REPORTS,
  };
}

export function setApplicationPermissionSelected(assigned, unassigned) {
  return {
    assigned,
    type: constants.SET_APPLICATION_PERMISSION_SELECTED,
    unassigned,
  };
}

export function setOriginalApplicationPermission(options) {
  return {
    options,
    type: constants.SET_ORIGINAL_ACTION_PERMISSION_LIST_BOX_SELECTED,
  };
}

export function setActionPermissionSelected(assigned, unassigned) {
  return {
    assigned,
    type: constants.SET_ACTION_PERMISSION_SELECTED,
    unassigned,
  };
}

export function updateActionPermissionList(newList) {
  return {
    newList,
    type: constants.UPDATE_ACTION_PERMISSION_LIST_BOX,
  };
}

export function setReportPermissionSelected(selected) {
  return {
    selected,
    type: constants.SET_REPORT_PERMISSIONS,
  };
}

// export function updateDefaultSelectedApplicationPermission(newList) {
//   return {
//     type: constants.UPDATE_DEFAULT_SELECTED_APPLICATION_PERMISSION,
//     newList
//   }
// }

// export function updateDefaultSelectedActionPermission(newList) {
//   return {
//     type: constants.UPDATE_DEFAULT_SELECTED_ACTION_PERMISSION,
//     newList
//   }
// }

// export function updateDefaultSelectedReportPermission(newList) {
//   return {
//     type: constants.UPDATE_DEFAULT_SELECTED_REPORT_PERMISSION,
//     newList
//   }
// }

export function updateDefaultSelectedPermissions() {
  return {
    type: constants.UPDATE_DEFAULT_SELECTED_PERMISSIONS,
  };
}

export function resetModifiedPermission() {
  return {
    type: constants.RESET_MODIFIED_PERMISSIONS,
  };
}

export function updateResetModifiedPermission() {
  return {
    type: constants.UPDATE_RESET_MODIFIED_PERMISSIONS,
  };
}

export function updateReport(report) {
  return {
    report,
    type: constants.UPDATE_REPORT,
  };
}
export function updateReports(reports) {
  return {
    reports,
    type: constants.UPDATE_REPORTS,
  };
}

export function moveReports(reports, direction) {
  return {
    direction,
    reports,
    type: constants.MOVE_REPORTS,
  };
}

export function updateAllPermissions(
  originalAssignedApplicationPermissions,
  originalUnassignedApplicationPermissions,
  originalAssignedActionPermissions,
  originalUnassignedActionPermissions,
) {
  return {
    originalAssignedActionPermissions,
    originalAssignedApplicationPermissions,
    originalUnassignedActionPermissions,
    originalUnassignedApplicationPermissions,
    type: constants.UPDATE_ALL_PERMISSIONS,
  };
}
