import moment from "moment";
import { put, call, takeLatest } from "redux-saga/effects";

import constants from "./constants";
import sortHurdleSequence from "./healpers";
import * as API from "./services";

import { toastr } from "components/toastr/index";
import httpMethods from "constants/httpMethods";
import i18n from "i18n";
import { parseErrorMessageInXhr } from "services//apiUtils";

function* fetchHurdleSequence(action) {
  const { response, xhr } = yield call(API.fetchHurdleSequence);
  if (response) {
    yield put({ response: sortHurdleSequence(response), type: constants.FETCH_HURDLE_SEQUENCE_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_HURDLE_SEQUENCE_FAILED });
  }
}

function* fetchHurdleEntities(action) {
  const { response, xhr } = yield call(API.fetchHurdleEntities);
  if (response) {
    yield put({ response, type: constants.FETCH_HURDLE_ENTITIES_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_HURDLE_ENTITIES_FAILED });
  }
}

function* deleteHurdleSequence(action) {
  const { response, xhr } = yield call(API.deleteHurdleSequence, action.hurdleToDelete);
  if (response) {
    yield put({ type: constants.FETCH_HURDLE_SEQUENCE });
    toastr.add({ message: i18n.t("Utilities.Hurdle Sequence deleted successfully"), type: "SUCCESS" });
  } else {
    let msg = parseErrorMessageInXhr(httpMethods.HTTP_DELETE, xhr);
    if (msg) {
    } else {
      const res = JSON.parse(xhr.response);
      if (res.errors && res.errors.length > 0) {
        // msg = res.errors[0].message
        msg = i18n.t("Utilities.Unable to delete Hurdle sequence is currently in use");
      } else {
        msg = i18n.t("Utilities.Unable to delete Hurdle sequence is currently in use");
      }
    }
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* updateHurdleSequence(action) {
  const { response, xhr } = yield call(API.updateHurdleSequence, action.hurdleSequence);
  if (response) {
    yield put({ type: constants.FETCH_HURDLE_SEQUENCE });
    toastr.add({ message: `Hurdle Sequence saved successfully`, type: "SUCCESS" });
  } else {
    const msg = parseErrorMessageInXhr(httpMethods.HTTP_POST, xhr);
    toastr.add({ message: `Error: ${msg}`, type: "ERROR" });
  }
}

function* fetchHurdleSequenceAssoc() {
  const { response, xhr } = yield call(API.fetchHurdleSequenceAssoc);
  if (response) {
    yield put({ response, type: constants.FETCH_HURDLE_SEQUENCE_ASSOC_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_HURDLE_SEQUENCE_ASSOC_FAILED });
  }
}

function* fetchSequenceToExecute() {
  const { response, xhr } = yield call(API.fetchSequenceToExecute);
  if (response) {
    yield put({ response, type: constants.FETCH_SEQUENCE_TO_EXECUTE_SUCCEEDED });
  } else {
    yield put({ type: constants.FETCH_SEQUENCE_TO_EXECUTE_FAILED });
  }
}

function* upDateHurdleSequenceAssoc(action) {
  const { response, xhr } = yield call(API.upDateHurdleSequenceAssoc, action.hurdleSequenceAssoc);
  if (response) {
    yield put({ type: constants.FETCH_HURDLE_SEQUENCE_ASSOC });
    toastr.add({ message: i18n.t("Utilities.Hurdle Sequence Associations saved successfully"), type: "SUCCESS" });
  } else {
    toastr.add({ message: `Error!`, type: "ERROR" });
    // yield put({ type: constants.FETCH_SEQUENCE_TO_EXECUTE_FAILED })
  }
}

export default function* utilitiesHurdleManagement() {
  yield takeLatest(constants.FETCH_HURDLE_SEQUENCE, fetchHurdleSequence);
  yield takeLatest(constants.FETCH_HURDLE_ENTITIES, fetchHurdleEntities);
  yield takeLatest(constants.DELETE_HURDEL_SEQUENCE_FROM_SERVER, deleteHurdleSequence);
  yield takeLatest(constants.UPDATE_HURDLE_SEQUENCE, updateHurdleSequence);
  yield takeLatest(constants.FETCH_HURDLE_SEQUENCE_ASSOC, fetchHurdleSequenceAssoc);
  yield takeLatest(constants.FETCH_SEQUENCE_TO_EXECUTE, fetchSequenceToExecute);
  yield takeLatest(constants.UPDATE_HURDLE_SEQUENCE_ASSOC, upDateHurdleSequenceAssoc);
}
