const actionTypes = {
  CHANGE_PASSWORD: "CHANGE_PASSWORD",

  // APPS
FETCH_APPS: "FETCH_APPS",

  
FETCH_APPS_FAILED: "FETCH_APPS_FAILED",

  
FETCH_APPS_SUCCEEDED: "FETCH_APPS_SUCCEEDED",

  
  FETCH_BRAND_DETAILS: "FETCH_BRAND_DETAILS",

  FETCH_BRAND_DETAILS_SUCCEEDED: "FETCH_BRAND_DETAILS_SUCCEEDED",

  // DAILY STATISTICS
FETCH_DAILY_STATISTICS: "FETCH_DAILY_STATISTICS",

  
FETCH_DAILY_STATISTICS_FAILED: "FETCH_DAILY_STATISTICS_FAILED",

  
FETCH_DAILY_STATISTICS_SUCCEEDED: "FETCH_DAILY_STATISTICS_SUCCEEDED",

  
FETCH_RECENT_APPS: "FETCH_RECENT_APPS",

  
// ACCUMULATOR RISKS
FETCH_GLOBAL_ACCUMULATOR_RISKS: "FETCH_GLOBAL_ACCUMULATOR_RISKS",

  
  

FETCH_RECENT_APPS_FAILED: "FETCH_RECENT_APPS_FAILED",

  


FETCH_GLOBAL_ACCUMULATOR_RISKS_FAILED: "FETCH_GLOBAL_ACCUMULATOR_RISKS_FAILED",

  
  


FETCH_USER_INFORMATION: "FETCH_USER_INFORMATION",

  
  


FETCH_GLOBAL_ACCUMULATOR_RISKS_SUCCEEDED: "FETCH_GLOBAL_ACCUMULATOR_RISKS_SUCCEEDED",

  
  


LINES: "LINES",

  



// LOOKUP PUBLISH MODES
FETCH_LOOKUP_PUBLISH_MODES: "FETCH_LOOKUP_PUBLISH_MODES",

  




LINES_FAILED: "LINES_FAILED",

  




FETCH_LOOKUP_PUBLISH_MODES_FAILED: "FETCH_LOOKUP_PUBLISH_MODES_FAILED",

  




LINES_SUCCEEDED: "LINES_SUCCEEDED",

  
  



FETCH_LOOKUP_PUBLISH_MODES_SUCCESS: "FETCH_LOOKUP_PUBLISH_MODES_SUCCESS",

  


// USER
LOGIN: "LOGIN",

  


FETCH_RECENT_APPS_SUCCEEDED: "FETCH_RECENT_APPS_SUCCEEDED",

  

// STARTUP
STARTUP: "STARTUP",

  

EDIT_USER_RISK_COLUMNS_AND_SPORTS: "EDIT_USER_RISK_COLUMNS_AND_SPORTS",

  
  // RISK MANAGER APPS
STARTUP_APP: "STARTUP_APP",

  EDIT_USER_RISK_COLUMNS_AND_SPORTS_FAILED: "EDIT_USER_RISK_COLUMNS_AND_SPORTS_FAILED",

  STARTUP_APP_FAILED: "STARTUP_APP_FAILED",

  EDIT_USER_RISK_COLUMNS_AND_SPORTS_SUCCEEDED: "EDIT_USER_RISK_COLUMNS_AND_SPORTS_SUCCEEDED",

  STARTUP_FAILED: "STARTUP_FAILED",

  // API CONSTANTS
FETCH_API_CONSTANTS: "FETCH_API_CONSTANTS",

  
STARTUP_SUCCEEDED: "STARTUP_SUCCEEDED",

  
FETCH_API_CONSTANTS_FAILED: "FETCH_API_CONSTANTS_FAILED",

  
FETCH_API_CONSTANTS_SUCCEEDED: "FETCH_API_CONSTANTS_SUCCEEDED",

  
STARTUP_APP_SUCCEEDED: "STARTUP_APP_SUCCEEDED",

  
FETCH_USER_INFORMATION_SUCCEEDED: "FETCH_USER_INFORMATION_SUCCEEDED",

  
LOGIN_FAILED: "LOGIN_FAILED",

  
// USER RISK PREFERENCES
FETCH_USER_RISK_COLUMNS: "FETCH_USER_RISK_COLUMNS",

  

LOGIN_SUCCEEDED: "LOGIN_SUCCEEDED",

  
  
FETCH_USER_RISK_COLUMNS_FAILED: "FETCH_USER_RISK_COLUMNS_FAILED",

  
LOGOUT: "LOGOUT",

  
FETCH_USER_RISK_COLUMNS_SUCCEEDED: "FETCH_USER_RISK_COLUMNS_SUCCEEDED",

  
RESET_INFO: "RESET_INFO",

  
  FETCH_USER_RISK_SPORTS: "FETCH_USER_RISK_SPORTS",

  SET_ISLOGGEDIN: "SET_ISLOGGEDIN",

  FETCH_USER_RISK_SPORTS_FAILED: "FETCH_USER_RISK_SPORTS_FAILED",

  SET_USER_DETAILS: "SET_USER_DETAILS",

  ADD_CUSTOM_DATE_FILTER: "ADD_CUSTOM_DATE_FILTER",

  FETCH_USER_RISK_SPORTS_SUCCEEDED: "FETCH_USER_RISK_SPORTS_SUCCEEDED",

  // RISK DATA
FETCH_RISK_DATA: "FETCH_RISK_DATA",

  
REMOVE_ERROR: "REMOVE_ERROR",

  
CANCEL_UPDATE_RISK_DATA: "CANCEL_UPDATE_RISK_DATA",

  
FETCH_RISK_DATA_FAILED: "FETCH_RISK_DATA_FAILED",

  
RESET_RISK_FILTERS: "RESET_RISK_FILTERS",

  
CLEAR_RISK_DATA: "CLEAR_RISK_DATA",

  
USE_APP: "USE_APP",

  
COLLAPSE_EVENT: "COLLAPSE_EVENT",

  
USE_APP_FAILED: "USE_APP_FAILED",

  
  EXPAND_ALL_EVENTS: "EXPAND_ALL_EVENTS",

  
  USE_APP_SUCCEEDED: "USE_APP_SUCCEEDED",

  
EXPAND_EVENT: "EXPAND_EVENT",

  
SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",

  
// RISK DATA CHANGES
ADD_UNSAVED_OUTCOME_PRICE_CHANGE: "ADD_UNSAVED_OUTCOME_PRICE_CHANGE",

  

SEARCH_EVENTS: "SEARCH_EVENTS",

  

CLEAR_UNSAVED_OUTCOME_PRICE_CHANGES: "CLEAR_UNSAVED_OUTCOME_PRICE_CHANGES",

  

SET_EVENT_SEARCH_VALUE: "SET_EVENT_SEARCH_VALUE",

  

CLEAR_SPECIFIC_UNSAVED_OUTCOME_PRICE_CHANGES: "CLEAR_SPECIFIC_UNSAVED_OUTCOME_PRICE_CHANGES",

  
  

SET_RISK_FILTER_BET_STATUS: "SET_RISK_FILTER_BET_STATUS",

  


FETCH_CHUNK_RISK_DATA: "FETCH_CHUNK_RISK_DATA",

  

// UPDATE PUBLISH MODE
UPDATE_LOOKUP_PUBLISH_MODES: "UPDATE_LOOKUP_PUBLISH_MODES",

  

ADD_BELOW_MINIMUM_PRICE_MARGIN: "ADD_BELOW_MINIMUM_PRICE_MARGIN",

  

UPDATE_LOOKUP_PUBLISH_MODES_FAILED: "UPDATE_LOOKUP_PUBLISH_MODES_FAILED",

  
  
FETCH_CHUNK_RISK_DATA_FAILED: "FETCH_CHUNK_RISK_DATA_FAILED",

  
UPDATE_LOOKUP_PUBLISH_MODES_SUCCESS: "UPDATE_LOOKUP_PUBLISH_MODES_SUCCESS",

  
ADD_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER: "ADD_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER",

  
SET_RISK_FILTER_BOOKTYPE: "SET_RISK_FILTER_BOOKTYPE",

  
FETCH_CHUNK_RISK_DATA_SUCCEEDED: "FETCH_CHUNK_RISK_DATA_SUCCEEDED",

  // RISK PARAMS
SET_RISK_FILTER_CODE: "SET_RISK_FILTER_CODE",

  ADD_RESULTS_VIEWER_CUSTOM_DATE_FILTER: "ADD_RESULTS_VIEWER_CUSTOM_DATE_FILTER",

  SET_RISK_FILTER_DATE: "SET_RISK_FILTER_DATE",

  FETCH_RISK_DATA_SUCCEEDED: "FETCH_RISK_DATA_SUCCEEDED",

  SET_RISK_FILTER_DESC: "SET_RISK_FILTER_DESC",

  // RESULTS VIEWER DATA
FETCH_RESULTS_VIEWER_DATA: "FETCH_RESULTS_VIEWER_DATA",

  
SET_RISK_FILTER_LIABILITY_TYPE: "SET_RISK_FILTER_LIABILITY_TYPE",

  
FETCH_RESULTS_VIEWER_DATA_FAILED: "FETCH_RESULTS_VIEWER_DATA_FAILED",

  
SET_RISK_FILTER_LINE: "SET_RISK_FILTER_LINE",

  
CANCEL_UPDATE_RESULTS_VIEWER_DATA: "CANCEL_UPDATE_RESULTS_VIEWER_DATA",

  
SET_RISK_FILTER_MARKET: "SET_RISK_FILTER_MARKET",

  
FETCH_RESULTS_VIEWER_DATA_SUCCEEDED: "FETCH_RESULTS_VIEWER_DATA_SUCCEEDED",
  
SET_RISK_FILTER_MARKET_STATUS_IDS: "SET_RISK_FILTER_MARKET_STATUS_IDS",
  
  CLEAR_RESULTS_VIEWER_DATA: "CLEAR_RESULTS_VIEWER_DATA",

  COLLAPSE_RESULTS_VIEWER_EVENT: "COLLAPSE_RESULTS_VIEWER_EVENT",

  SET_RISK_FILTER_PERIOD: "SET_RISK_FILTER_PERIOD",

  EXPAND_ALL_RESULTS_VIEWER_EVENTS: "EXPAND_ALL_RESULTS_VIEWER_EVENTS",

  SET_RISK_FILTER_PRICE_TYPE: "SET_RISK_FILTER_PRICE_TYPE",

  EXPAND_RESULTS_VIEWER_EVENT: "EXPAND_RESULTS_VIEWER_EVENT",

  SET_RISK_FILTER_WIN_PLACE: "SET_RISK_FILTER_WIN_PLACE",

  // EVENT PATH TREE
FETCH_EVENT_PATH_TREE: "FETCH_EVENT_PATH_TREE",

  
FETCH_EVENT_MARKETS: "FETCH_EVENT_MARKETS",

  
SET_RISK_ROW_HEIGHT: "SET_RISK_ROW_HEIGHT",

  
FETCH_EVENT_MARKETS_CANCEL: "FETCH_EVENT_MARKETS_CANCEL",

  
SET_DISTRIBUTED_WIDTH: "SET_DISTRIBUTED_WIDTH",

  
FETCH_EVENT_MARKETS_FAILED: "FETCH_EVENT_MARKETS_FAILED",

  
REMOVE_BELOW_MINIMUM_PRICE_MARGIN: "REMOVE_BELOW_MINIMUM_PRICE_MARGIN",

  
CLEAR_EVENT_PATH_TREE_SEARCH_VALUE: "CLEAR_EVENT_PATH_TREE_SEARCH_VALUE",

  
  UPDATE_RISK_DATA: "UPDATE_RISK_DATA",

  FETCH_EVENT_MARKETS_SUCCEEDED: "FETCH_EVENT_MARKETS_SUCCEEDED",

  UPDATE_RISK_DATA_FAILED: "UPDATE_RISK_DATA_FAILED",

  // MODALS
CLOSE_MODAL: "CLOSE_MODAL",

  
UPDATE_RISK_DATA_SUCCEEDED: "UPDATE_RISK_DATA_SUCCEEDED",

  
CLOSE_ALL_MODALS: "CLOSE_ALL_MODALS",

  
FETCH_EVENT_PATH_TREE_FAILED: "FETCH_EVENT_PATH_TREE_FAILED",

  
REMOVE_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER: "REMOVE_RESULTS_VIEWER_MARKET_STATUS_ID_FILTER",

  
FETCH_EVENT_PATH_TREE_SUCCEEDED: "FETCH_EVENT_PATH_TREE_SUCCEEDED",

  
REMOVE_UNSAVED_OUTCOME_PRICE_CHANGE: "REMOVE_UNSAVED_OUTCOME_PRICE_CHANGE",

  
FETCH_OUTCOME_PRICE_BY_LINE: "FETCH_OUTCOME_PRICE_BY_LINE",

  
RESET_RESULTS_VIEWER_FILTERS: "RESET_RESULTS_VIEWER_FILTERS",

  
  CANCEL_FETCH_OUTCOME_PRICE_BY_LINE: "CANCEL_FETCH_OUTCOME_PRICE_BY_LINE",

  
  SAVE_OUTCOME_PRICE_CHANGES: "SAVE_OUTCOME_PRICE_CHANGES",

  
CLEAR_OUTCOME_PRICE_BY_LINE: "CLEAR_OUTCOME_PRICE_BY_LINE",

  
SAVE_OUTCOME_PRICE_CHANGES_FAILED: "SAVE_OUTCOME_PRICE_CHANGES_FAILED",

  
FETCH_OUTCOME_PRICE_BY_LINE_FAILED: "FETCH_OUTCOME_PRICE_BY_LINE_FAILED",

  
SAVE_OUTCOME_PRICE_CHANGES_SUCCEEDED: "SAVE_OUTCOME_PRICE_CHANGES_SUCCEEDED",

  
// MARKETS
CHANGE_MARKET_STATUS: "CHANGE_MARKET_STATUS",

  

SET_CODE_AFTER_SAVING_CHANGES: "SET_CODE_AFTER_SAVING_CHANGES",

  

CHANGE_MARKET_STATUS_FAILED: "CHANGE_MARKET_STATUS_FAILED",

  

SET_DESC_AFTER_SAVING_CHANGES: "SET_DESC_AFTER_SAVING_CHANGES",

  

CHANGE_MARKET_STATUS_SUCCEEDED: "CHANGE_MARKET_STATUS_SUCCEEDED",

  
// RESULTS VIEWER
SET_RESULTS_VIEWER_FILTER_CODE: "SET_RESULTS_VIEWER_FILTER_CODE",

  
FETCH_MARKET_FEED_INFO: "FETCH_MARKET_FEED_INFO",

  
SET_RESULTS_VIEWER_FILTER_DESC: "SET_RESULTS_VIEWER_FILTER_DESC",

  
DISCONNECT_MARKET_FROM_PRICE_FEED: "DISCONNECT_MARKET_FROM_PRICE_FEED",

  
  SET_RESULTS_VIEWER_COLUMN_SORT: "SET_RESULTS_VIEWER_COLUMN_SORT",

  DISCONNECT_MARKET_FROM_PRICE_FEED_FAILED: "DISCONNECT_MARKET_FROM_PRICE_FEED_FAILED",

  SET_RESULTS_VIEWER_ROW_HEIGHT: "SET_RESULTS_VIEWER_ROW_HEIGHT",

  DISCONNECT_MARKET_FROM_PRICE_FEED_SUCCEEDED: "DISCONNECT_MARKET_FROM_PRICE_FEED_SUCCEEDED",

  SET_RESULTS_VIEWER_START_TIME_SORT: "SET_RESULTS_VIEWER_START_TIME_SORT",

  FETCH_MARKET_FEED_INFO_FAILED: "FETCH_MARKET_FEED_INFO_FAILED",

  FETCH_EVENT_DETAILS: "FETCH_EVENT_DETAILS",

  UPDATE_RESULTS_VIEWER_DATA: "UPDATE_RESULTS_VIEWER_DATA",

  FETCH_EVENT_DETAILS_FAILED: "FETCH_EVENT_DETAILS_FAILED",

  UPDATE_RESULTS_VIEWER_DATA_FAILED: "UPDATE_RESULTS_VIEWER_DATA_FAILED",

  CHANGE_MARKETS_STATUS: "CHANGE_MARKETS_STATUS",

  UPDATE_RESULTS_VIEWER_DATA_SUCCEEDED: "UPDATE_RESULTS_VIEWER_DATA_SUCCEEDED",

  CHANGE_MARKETS_STATUS_FAILED: "CHANGE_MARKETS_STATUS_FAILED",

  FETCH_OUTCOME_PRICE_BY_LINE_SUCCEEDED: "FETCH_OUTCOME_PRICE_BY_LINE_SUCCEEDED",

  CHANGE_MARKETS_STATUS_SUCCEEDED: "CHANGE_MARKETS_STATUS_SUCCEEDED",

  OPEN_MODAL: "OPEN_MODAL",

  CHANGE_MARKET_AUTO_OPEN_TIME: "CHANGE_MARKET_AUTO_OPEN_TIME",

  SELECT_EVENT_PATH_TREE_SPORT: "SELECT_EVENT_PATH_TREE_SPORT",

  
  CHANGE_MARKET_AUTO_OPEN_TIME_FAILED: "CHANGE_MARKET_AUTO_OPEN_TIME_FAILED",

  
SELECT_EVENT_PATH_TREE_SPORT_FAILED: "SELECT_EVENT_PATH_TREE_SPORT_FAILED",

  
ABANDON_MARKETS: "ABANDON_MARKETS",

  
SELECT_EVENT_PATH_TREE_SPORT_SUCCEEDED: "SELECT_EVENT_PATH_TREE_SPORT_SUCCEEDED",

  
  
ABANDON_MARKETS_FAILED: "ABANDON_MARKETS_FAILED",

  

RESET_EVENT_PATH_TREE: "RESET_EVENT_PATH_TREE",

  

ABANDON_MARKETS_SUCCEEDED: "ABANDON_MARKETS_SUCCEEDED",

  

CHANGE_MARKET_AUTO_OPEN_TIME_SUCCEEDED: "CHANGE_MARKET_AUTO_OPEN_TIME_SUCCEEDED",

  

SEARCH_EVENT_PATH_TREE: "SEARCH_EVENT_PATH_TREE",

  

CHANGE_MARKET_CUTOFF_TIME: "CHANGE_MARKET_CUTOFF_TIME",

  
//EVENT PATH TREE FILTERING
SET_EVENT_PATH_TREE_SEARCH_VALUE: "SET_EVENT_PATH_TREE_SEARCH_VALUE",

  
CHANGE_MARKET_CUTOFF_TIME_FAILED: "CHANGE_MARKET_CUTOFF_TIME_FAILED",

  
SEARCH_EVENT_PATH_TREE_SUCCEEDED: "SEARCH_EVENT_PATH_TREE_SUCCEEDED",

  
CHANGE_MARKET_CUTOFF_TIME_SUCCEEDED: "CHANGE_MARKET_CUTOFF_TIME_SUCCEEDED",

  
  // OUTCOMES
TOGGLE_OUTCOME_VISIBILITY: "TOGGLE_OUTCOME_VISIBILITY",

  
FETCH_EDIT_MARKET: "FETCH_EDIT_MARKET",

  
TOGGLE_OUTCOME_VISIBILITY_FAILED: "TOGGLE_OUTCOME_VISIBILITY_FAILED",

  
ADD_EDIT_MARKET_PRICE_MARGIN: "ADD_EDIT_MARKET_PRICE_MARGIN",

  
TOGGLE_OUTCOME_VISIBILITY_SUCCEEDED: "TOGGLE_OUTCOME_VISIBILITY_SUCCEEDED",

  
FETCH_EDIT_MARKET_DETAILS: "FETCH_EDIT_MARKET_DETAILS",

  
FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP: "FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP",

  
FETCH_EDIT_MARKET_DETAILS_FAILED: "FETCH_EVENT_DETAILS_FAILED",

  
FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_FAILED: "FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_FAILED",

  
  
FETCH_EDIT_MARKET_DETAILS_SUCCEEDED: "FETCH_EVENT_DETAILS_SUCCEEDED",

  
// OUTCOME WAGER LIMITS
SET_OUTCOME_WAGER_LIMITS_SELECTED_OUTCOME_ID: "SET_OUTCOME_WAGER_LIMITS_SELECTED_OUTCOME_ID",

  
FETCH_EDIT_MARKET_FAILED: "FETCH_EDIT_MARKET_FAILED",

  
SET_OUTCOME_WAGER_LIMITS_SELECTED_WAGER_LIMITS_GROUP_ID: "SET_OUTCOME_WAGER_LIMITS_SELECTED_WAGER_LIMITS_GROUP_ID",

  
FETCH_BETS_ANALYSIS: "FETCH_BETS_ANALYSIS",

  
FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_SUCCEEDED: "FETCH_OUTCOME_WAGER_LIMITS_BY_GROUP_SUCCEEDED",

  
FETCH_BETS_ANALYSIS_FAILED: "FETCH_BETS_ANALYSIS_FAILED",

  
FETCH_BETS_ANALYSIS_SUCCEEDED: "FETCH_BETS_ANALYSIS_SUCCEEDED",

  
FETCH_MARKET_FEED_INFO_SUCCEEDED: "FETCH_MARKET_FEED_INFO_SUCCEEDED",

  
FETCH_EDIT_MARKET_SUCCEEDED: "FETCH_EDIT_MARKET_SUCCEEDED",

  //MARKET FEED DISCONNECT
SET_MARKET_FEED_INFO_MARKETS: "SET_MARKET_FEED_INFO_MARKETS",

  
  FETCH_BET_DATA: "FETCH_BET_DATA",

  
RESET_FETCH_MARKET_FEED_INFO: "RESET_FETCH_MARKET_FEED_INFO",

  
FETCH_BET_DATA_FAILED: "FETCH_BET_DATA_FAILED",

  
RESET_MARKET_FEED_INFO: "RESET_MARKET_FEED_INFO",

  
CLEAR_BET_DATA: "CLEAR_BET_DATA",

  
TOGGLE_LINE_TO_DISCONNECT: "TOGGLE_LINE_TO_DISCONNECT",

  
FETCH_BET_DATA_SUCCEEDED: "FETCH_BET_DATA_SUCCEEDED",

  
TOGGLE_MARKET_TO_DISCONNECT: "TOGGLE_MARKET_TO_DISCONNECT",

  
FETCH_EVENT_DETAILS_SUCCEEDED: "FETCH_EVENT_DETAILS_SUCCEEDED",

  
// CUSTOMER MANAGER ACCOUNT
FETCH_MAIN_OPERATOR_PERMISSIONS: "FETCH_MAIN_OPERATOR_PERMISSIONS",

  
  
//MARKET STATE DETAILS
VIEW_MARKET_STATE_DETAILS: "VIEW_MARKET_STATE_DETAILS",

  

FETCH_MAIN_OPERATOR_PERMISSIONS_FAILED: "FETCH_MAIN_OPERATOR_PERMISSIONS_FAILED",

  

FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT: "FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT",

  

FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_FAILED: "FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_FAILED",

  

FETCH_MARKET_PERIOD_DETAILS: "FETCH_MARKET_PERIOD_DETAILS",

  

FETCH_BRANDS: "FETCH_BRANDS",

  

FETCH_MARKET_PERIOD_DETAILS_FAILED: "FETCH_MARKET_PERIOD_DETAILS_FAILED",

  
  
FETCH_BRANDS_ERROR: "FETCH_BRANDS_ERROR",

  
  
FETCH_MARKET_PERIOD_DETAILS_SUCCEEDED: "FETCH_MARKET_PERIOD_DETAILS_SUCCEEDED",

  

CHANGE_BRAND: "CHANGE_BRAND",

  
// RISK ANALYSIS
FETCH_RISK_ANALYSIS_SUMMARY: "FETCH_RISK_ANALYSIS_SUMMARY",

  
FETCH_BRANDS_SUCCESS: "FETCH_BRANDS_SUCCESS",

  
FETCH_RISK_ANALYSIS_SUMMARY_FAILED: "FETCH_RISK_ANALYSIS_SUMMARY_FAILED",

  
CLOSE_MODAL_BULKCREDITING: "CLOSE_MODAL_BULKCREDITING",

  
FETCH_RISK_ANALYSIS_SUMMARY_SUCCEEDED: "FETCH_RISK_ANALYSIS_SUMMARY_SUCCEEDED",

  
CLOSE_BULK_ERROR_MODAL: "CLOSE_BULK_ERROR_MODAL",

  
REMOVE_EDIT_MARKET_PRICE_MARGIN: "REMOVE_EDIT_MARKET_PRICE_MARGIN",

  
CLOSE_BULK_SUCCESS_MODAL: "CLOSE_BULK_SUCCESS_MODAL",

  // EDIT MARKET
SET_EDIT_MARKET_MARKETS: "SET_EDIT_MARKET_MARKETS",

  
  FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_SUCCEEDED: "FETCH_MAIN_OPERATOR_PERMISSIONS_FROM_REDIRECT_SUCCEEDED",

  
SET_EDIT_MARKET_SELECTED_MARKET_ID: "SET_EDIT_MARKET_SELECTED_MARKET_ID",
  
FETCH_MAIN_OPERATOR_PERMISSIONS_SUCCEEDED: "FETCH_MAIN_OPERATOR_PERMISSIONS_SUCCEEDED",
  
FETCH_RISK_MULTIPLE_SUMMARY: "FETCH_RISK_MULTIPLE_SUMMARY",

  
SAVE_EDIT_MARKET_CHANGES: "SAVE_EDIT_MARKET_CHANGES",
  
FETCH_RISK_MULTIPLE_SUMMARY_FAILED: "FETCH_RISK_MULTIPLE_SUMMARY_FAILED",
  
SAVE_EDIT_MARKET_CHANGES_FAILED: "SAVE_EDIT_MARKET_CHANGES_FAILED",

  
FETCH_ACCOUNT_DETAILS: "FETCH_ACCOUNT_DETAILS",
  
SAVE_EDIT_MARKET_CHANGES_SUCCEEDED: "SAVE_EDIT_MARKET_CHANGES_SUCCEEDED",

  
FETCH_ACCOUNT_DETAILS_FAILED: "FETCH_ACCOUNT_DETAILS_FAILED",
  // UPDATE MARKET CUTOFF
UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME: "UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME",
  FETCH_ACCOUNT_DETAILS_CLEAR: "FETCH_ACCOUNT_DETAILS_CLEAR",
  
  UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_FAILED: "UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_FAILED",

  
FETCH_ACCOUNT_DETAILS_SUCCEEDED: "FETCH_ACCOUNT_DETAILS_SUCCEEDED",

  
UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_SUCCEEDED: "UPDATE_MARKET_CUTOFF_AND_AUTOOPEN_DATETIME_SUCCEEDED",

  
FETCH_ACCOUNT_PERMISSIONS: "FETCH_ACCOUNT_PERMISSIONS",

  
FETCH_ACCOUNT_PERMISSIONS_CLEAR: "FETCH_ACCOUNT_PERMISSIONS_CLEAR",

  
FETCH_RISK_MULTIPLE_SUMMARY_SUCCEEDED: "FETCH_RISK_MULTIPLE_SUMMARY_SUCCEEDED",

  
CLOSE_MODAL_CREATE_ACCOUNT: "CLOSE_MODAL_CREATE_ACCOUNT",

  // RISK TRANSACTION
FETCH_RISK_TRANSACTION_DETAILS: "FETCH_RISK_TRANSACTION_DETAILS",

  CLOSE_MODAL_DRAGON_PAY: "CLOSE_MODAL_DRAGON_PAY",

  FETCH_RISK_TRANSACTION_DETAILS_FAILED: "FETCH_RISK_TRANSACTION_DETAILS_FAILED",

  CLOSE_MODAL_DRAGON_PAY_PAYBACK: "CLOSE_MODAL_DRAGON_PAY_PAYBACK",

  RESET_RISK_ANALYSIS: "RESET_RISK_ANALYSIS",

  CLOSE_MODAL_ERROR: "CLOSE_MODAL_ERROR",

  SET_RISK_ANALYSIS_ACTIVE_KEY: "SET_RISK_ANALYSIS_ACTIVE_KEY",

  FETCH_ACCOUNT_PERMISSIONS_FAILED: "FETCH_ACCOUNT_PERMISSIONS_FAILED",

  FETCH_ACCOUNT_PERMISSIONS_SUCCEEDED: "FETCH_ACCOUNT_PERMISSIONS_SUCCEEDED",
  FETCH_RISK_TRANSACTION_DETAILS_SUCCEEDED: "FETCH_RISK_TRANSACTION_DETAILS_SUCCEEDED",
  FETCH_COUNTRIES: "FETCH_COUNTRIES",
  LOAD_DETAILS_SILENT: "CM::LOAD_DETAILS_SILENT",
  FETCH_CURRENCIES: "FETCH_CURRENCIES",
  LOGIN_FROM_REDIRECT: "CM::LOGIN_FROM_REDIRECT",

  FETCH_ACCTTYPES: "FETCH_ACCTTYPES",
  OPEN_MODAL_BULKCREDITING: "OPEN_MODAL_BULKCREDITING",
  FETCH_LANGUAGES: "FETCH_LANGUAGES",
  SEARCH_ACCOUNT: "SEARCH_ACCOUNT",

  FETCH_LINES: "FETCH_LINES",
  SEARCH_ACCOUNT_FAILED: "SEARCH_ACCOUNT_FAILED",
  FETCH_COUNTRIES_FAILED: "FETCH_COUNTRIES_FAILED",
  SEARCH_ACCOUNT_SUCCEEDED: "SEARCH_ACCOUNT_SUCCEEDED",

  FETCH_CURRENCIES_FAILED: "FETCH_CURRENCIES_FAILED",
  SEARCH_ACCOUNT_CLEAR: "SEARCH_ACCOUNT_CLEAR",
  FETCH_ACCTTYPES_FAILED: "FETCH_ACCTTYPES_FAILED",

  VALIDATE_BULK_CSV: "VALIDATE_BULK_CSV",
  FETCH_LANGUAGES_FAILED: "FETCH_LANGUAGES_FAILED",

  VALIDATE_BULK_CSV_ERROR: "VALIDATE_BULK_CSV_ERROR",
  FETCH_LINES_FAILED: "FETCH_LINES_FAILED",

  VALIDATE_BULK_CSV_SUCCESS: "VALIDATE_BULK_CSV_SUCCESS",
  FETCH_COUNTRIES_SUCCEEDED: "FETCH_COUNTRIES_SUCCEEDED",

  FETCH_CURRENCIES_SUCCEEDED: "FETCH_CURRENCIES_SUCCEEDED",
  REQUEST_BULK_CREDIT: "REQUEST_BULK_CREDIT",

  FETCH_ACCTTYPES_SUCCEEDED: "FETCH_ACCTTYPES_SUCCEEDED",
  REQUEST_BULK_CREDIT_ERROR: "REQUEST_BULK_CREDIT_ERROR",
  FETCH_LANGUAGES_SUCCEEDED: "FETCH_LANGUAGES_SUCCEEDED",
  REQUEST_BULK_CREDIT_SUCCESS: "REQUEST_BULK_CREDIT_SUCCESS",
  FETCH_LINES_SUCCEEDED: "FETCH_LINES_SUCCEEDED",
  MANUAL_SETTLE_RISK_TRANSACTION: "MANUAL_SETTLE_RISK_TRANSACTION",
  CLEAR_PIN: "CLEAR_PIN",
  MANUAL_SETTLE_RISK_TRANSACTION_FAILED: "MANUAL_SETTLE_RISK_TRANSACTION_FAILED",
  CLOSE_ADVANCE_ACCOUNT_SEARCH: "CLOSE_ADVANCE_ACCOUNT_SEARCH",
  MANUAL_SETTLE_RISK_TRANSACTION_SUCCEEDED: "MANUAL_SETTLE_RISK_TRANSACTION_SUCCEEDED",

  ADVANCE_SEARCH_ACCOUNT: "ADVANCE_SEARCH_ACCOUNT",
  ADVANCE_SEARCH_ACCOUNT_FAILED: "ADVANCE_SEARCH_ACCOUNT_FAILED",
  FETCH_ORIGINS: "FETCH_ORIGINS",
  ADVANCE_SEARCH_ACCOUNT_SUCCEEDED: "ADVANCE_SEARCH_ACCOUNT_SUCCEEDED",
  FETCH_ORIGINS_FAILED: "FETCH_ORIGINS_FAILED",
  ADVANCE_SEARCH_ACCOUNT_TRANSFER: "ADVANCE_SEARCH_ACCOUNT_TRANSFER",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  ADVANCE_SEARCH_ACCOUNT_TRANSFER_FAILED: "ADVANCE_SEARCH_ACCOUNT_TRANSFER_FAILED",
  UPDATE_ACCOUNT_FAILED: "UPDATE_ACCOUNT_FAILED",
  ADVANCE_SEARCH_ACCOUNT_TRANSFER_SUCCEEDED: "ADVANCE_SEARCH_ACCOUNT_TRANSFER_SUCCEEDED",

  UPDATE_ACCOUNT_SUCCEEDED: "UPDATE_ACCOUNT_SUCCEEDED",
  END_CALL: "END_CALL",
  OPEN_MODAL_CREATE_ACCOUNT: "OPEN_MODAL_CREATE_ACCOUNT",
  END_CALL_FAILED: "END_CALL_FAILED",
  OPEN_MODAL_DRAGON_PAY: "OPEN_MODAL_DRAGON_PAY",
  END_CALL_SUCCEEDED: "END_CALL_SUCCEEDED",
  OPEN_MODAL_DRAGON_PAY_PAYBACK: "OPEN_MODAL_DRAGON_PAY_PAYBACK",
  FETCH_ORIGINS_SUCCEEDED: "FETCH_ORIGINS_SUCCEEDED",
  OPEN_MODAL_ERROR: "OPEN_MODAL_ERROR",
  FETCH_PACKAGES: "FETCH_PACKAGES",

  FETCH_PACKAGES_FAILED: "FETCH_PACKAGES_FAILED",
  FETCH_PACKAGES_SUCCEEDED: "FETCH_PACKAGES_SUCCEEDED",
  FETCH_PRICE_FORMATS: "FETCH_PRICE_FORMATS",
  CLOSE_MODAL_END_CALL: "CLOSE_MODAL_END_CALL",

  FETCH_PRICE_FORMATS_FAILED: "FETCH_PRICE_FORMATS_FAILED",
  CLOSE_SELF_EXCLUSION_WARN_MODAL: "CLOSE_SELF_EXCLUSION_WARN_MODAL",

  FETCH_REFERRAL_METHODS: "FETCH_REFERRAL_METHODS",
  CONFIRM_SELF_EXCLUSION_WARN: "CONFIRM_SELF_EXCLUSION_WARN",
  FETCH_SECURITY_QUESTIONS: "FETCH_SECURITY_QUESTIONS",

  CLOSE_TRANSACTION_WARN_MODAL: "CLOSE_TRANSACTION_WARN_MODAL",
  FETCH_PRICE_FORMATS_SUCCEEDED: "FETCH_PRICE_FORMATS_SUCCEEDED",
  CLOSE_MODAL_ACCOUNT_ADJUSTMENT: "CLOSE_MODAL_ACCOUNT_ADJUSTMENT",

  FETCH_RECENT_ACCOUNTS: "FETCH_RECENT_ACCOUNTS",
  CLOSE_MODAL_ACCOUNT_TRANSFER: "CLOSE_MODAL_ACCOUNT_TRANSFER",
  FETCH_RECENT_ACCOUNTS_FAILED: "FETCH_RECENT_ACCOUNTS_FAILED",

  FETCH_ACCOUNT_BALANCE: "FETCH_ACCOUNT_BALANCE",
  FETCH_REFERRAL_METHODS_FAILED: "FETCH_REFERRAL_METHODS_FAILED",
  FETCH_ACCOUNT_BALANCE_FAILED: "FETCH_ACCOUNT_BALANCE_FAILED",

  FETCH_SECURITY_QUESTIONS_FAILED: "FETCH_SECURITY_QUESTIONS_FAILED",
  FETCH_ACCOUNT_BALANCE_SUCCEEDED: "FETCH_ACCOUNT_BALANCE_SUCCEEDED",
  FETCH_FX_RATE: "FETCH_FX_RATE",

  FETCH_REFERRAL_METHODS_SUCCEEDED: "FETCH_REFERRAL_METHODS_SUCCEEDED",
  FETCH_FX_RATE_FAILED: "FETCH_FX_RATE_FAILED",
  FETCH_SECURITY_QUESTIONS_SUCCEEDED: "FETCH_SECURITY_QUESTIONS_SUCCEEDED",

  ADJUST_ACCOUNT: "ADJUST_ACCOUNT",
  FETCH_TRANSACTIONS: "FETCH_TRANSACTIONS",
  ADJUST_ACCOUNT_FAILED: "ADJUST_ACCOUNT_FAILED",

  VALIDATE_PIN: "VALIDATE_PIN",
  ADJUST_ACCOUNT_SUCCEEDED: "ADJUST_ACCOUNT_SUCCEEDED",

  VALIDATE_PIN_FAILED: "VALIDATE_PIN_FAILED",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  VALIDATE_PIN_SUCCEEDED: "VALIDATE_PIN_SUCCEEDED",

  CREATE_ACCOUNT_FAILED: "CREATE_ACCOUNT_FAILED",
  ADD_DRAGON_PAY_ACCOUNT: "ADD_DRAGON_PAY_ACCOUNT",

  OPEN_ADVANCE_ACCOUNT_SEARCH: "OPEN_ADVANCE_ACCOUNT_SEARCH",
  ADD_DRAGON_PAY_ACCOUNT_FAILED: "ADD_DRAGON_PAY_ACCOUNT_FAILED",

  ADD_DRAGON_PAY_ACCOUNT_SUCCEEDED: "ADD_DRAGON_PAY_ACCOUNT_SUCCEEDED",
  FETCH_TRANSACTIONS_FAILED: "FETCH_TRANSACTIONS_FAILED",

  CREATE_ACCOUNT_SUCCEEDED: "CREATE_ACCOUNT_SUCCEEDED",
  INITIALIZE_CALL: "INITIALIZE_CALL",
  DISABLE_DRAGON_PAY_ACCOUNT: "DISABLE_DRAGON_PAY_ACCOUNT",

  INITIALIZE_CALL_FAILED: "INITIALIZE_CALL_FAILED",
  DISABLE_DRAGON_PAY_ACCOUNT_FAILED: "DISABLE_DRAGON_PAY_ACCOUNT_FAILED",
  INITIALIZE_CALL_SUCCEEDED: "INITIALIZE_CALL_SUCCEEDED",

  DISABLE_DRAGON_PAY_ACCOUNT_SUCCEEDED: "DISABLE_DRAGON_PAY_ACCOUNT_SUCCEEDED",
  CM_ADD_ERROR: "CM_ADD_ERROR",
  FETCH_TRANSACTIONS_SUCCEEDED: "FETCH_TRANSACTIONS_SUCCEEDED",

  CM_CLEAR_ERROR: "CM_CLEAR_ERROR",
  FETCH_TRANSACTION_DETAILS: "FETCH_TRANSACTION_DETAILS",
  // CANCEL TRANSACTION
CANCEL_TRANSACTION: "CANCEL_TRANSACTION",

  
FETCH_TRANSACTION_DETAILS_FAILED: "FETCH_TRANSACTION_DETAILS_FAILED",
  
FETCH_DRAGON_PAY_ACCOUNT: "FETCH_DRAGON_PAY_ACCOUNT",
  
FETCH_TRANSACTION_DETAILS_SUCCEEDED: "FETCH_TRANSACTION_DETAILS_SUCCEEDED",
  
// Delete Transactions
DELETE_TRANSACTION: "DELETE_TRANSACTION",

  

DELETE_TRANSACTION_FAILED: "DELETE_TRANSACTION_FAILED",
  

FETCH_RECENT_ACCOUNTS_SUCCEEDED: "FETCH_RECENT_ACCOUNTS_SUCCEEDED",
  

DELETE_TRANSACTION_SUCCEEDED: "DELETE_TRANSACTION_SUCCEEDED",

  
  
OPEN_MODAL_END_CALL: "OPEN_MODAL_END_CALL",
  
// Get Bet Statistics
FETCH_BETSTATISTICS: "FETCH_BETSTATISTICS",

  

OPEN_SELF_EXCLUSION_WARN_MODAL: "OPEN_SELF_EXCLUSION_WARN_MODAL",

  

FETCH_BETSTATISTICS_FAILED: "FETCH_BETSTATISTICS_FAILED",

  
  
// Get Account Statistics
FETCH_ACCOUNTSTATS: "FETCH_ACCOUNTSTATS",

  

OPEN_TRANSACTION_WARN_MODAL: "OPEN_TRANSACTION_WARN_MODAL",

  

FETCH_ACCOUNTSTATS_FAILED: "FETCH_ACCOUNTSTATS_FAILED",

  

OPEN_MODAL_ACCOUNT_ADJUSTMENT: "OPEN_MODAL_ACCOUNT_ADJUSTMENT",

  

FETCH_ACCOUNTSTATS_SUCCEEDED: "FETCH_ACCOUNTSTATS_SUCCEEDED",

  

OPEN_MODAL_ACCOUNT_TRANSFER: "OPEN_MODAL_ACCOUNT_TRANSFER",

  
  
// Get Account Status
FETCH_ACCOUNTSTATUS: "FETCH_ACCOUNTSTATUS",

  

SETTLE_SELECTION: "SETTLE_TRANSACTION",

  

FETCH_ACCOUNTSTATUS_FAILED: "FETCH_ACCOUNTSTATUS_FAILED",

  

FETCH_FX_RATE_SUCCEEDED: "FETCH_FX_RATE_SUCCEEDED",

  
  
ACCOUNT_TRANSFER: "ACCOUNT_TRANSFER",

  
FETCH_TRANSACTION_TYPE: "FETCH_TRANSACTION_TYPE",

  
ACCOUNT_TRANSFER_FAILED: "ACCOUNT_TRANSFER_FAILED",

  
FETCH_TRANSACTION_TYPE_FAILED: "FETCH_TRANSACTION_TYPE_FAILED",

  
ACCOUNT_TRANSFER_SUCCEEDED: "ACCOUNT_TRANSFER_SUCCEEDED",

  
FETCH_TRANSACTION_TYPE_SUCCEEDED: "FETCH_TRANSACTION_TYPE_SUCCEEDED",

  
  CLOSE_MODAL_ACCOUNT_TRANSFER_CONFIRMATION: "CLOSE_MODAL_ACCOUNT_TRANSFER_CONFIRMATION",

  FETCH_DRAGON_PAY_ACCOUNT_FAILED: "FETCH_DRAGON_PAY_ACCOUNT_FAILED",

  CLEAR_ACCOUNT_TO_TRANSFER: "CLEAR_ACCOUNT_TO_TRANSFER",

  FETCH_DRAGON_PAY_ACCOUNT_SUCCEEDED: "FETCH_DRAGON_PAY_ACCOUNT_SUCCEEDED",

  ACCOUNT_LOCK: "ACCOUNT_LOCK",

  PAYBACK_DRAGON_PAY_ACCOUNT: "PAYBACK_DRAGON_PAY_ACCOUNT",

  ACCOUNT_UNLOCK: "ACCOUNT_UNLOCK",

  RESET_ERROR_FIELD: "RESET_ERROR_FIELD",

  PAYBACK_DRAGON_PAY_ACCOUNT_FAILED: "PAYBACK_DRAGON_PAY_ACCOUNT_FAILED",

  PAYBACK_DRAGON_PAY_ACCOUNT_SUCCEEDED: "PAYBACK_DRAGON_PAY_ACCOUNT_SUCCEEDED",

  CLOSE_MODAL_ACCOUNT_TRANSFER_SEARCH: "CLOSE_MODAL_ACCOUNT_TRANSFER_SEARCH",

  PREFER_DRAGON_PAY_ACCOUNT: "PREFER_DRAGON_PAY_ACCOUNT",

  // Get account security details
FETCH_ACCOUNTSECURITYDETAILS: "FETCH_ACCOUNTSECURITYDETAILS",

  
  
PREFER_DRAGON_PAY_ACCOUNT_FAILED: "PREFER_DRAGON_PAY_ACCOUNT_FAILED",

  

FETCH_ACCOUNTSECURITYDETAILS_FAILED: "FETCH_ACCOUNTSECURITYDETAILS_FAILED",

  

PREFER_DRAGON_PAY_ACCOUNT_SUCCEEDED: "PREFER_DRAGON_PAY_ACCOUNT_SUCCEEDED",

  

FETCH_ACCOUNTSECURITYDETAILS_SUCCEEDED: "FETCH_ACCOUNTSECURITYDETAILS_SUCCEEDED",

  
  

REQUEST_UFOP: "REQUEST_UFOP",
  


FETCH_ACCOUNTSTATUS_SUCCEEDED: "FETCH_ACCOUNTSTATUS_SUCCEEDED",

  


REQUEST_U_FOS: "REQUEST_U_FOS",

  
  

FETCH_BETSTATISTICS_SUCCEEDED: "FETCH_BETSTATISTICS_SUCCEEDED",

  
// Void or Cancel Transactions
REVOKE_TRANSACTION: "REVOKE_TRANSACTION",

  
// Get customer bank account details
FETCH_CUSTOMER_BANK_ACCOUNT: "FETCH_CUSTOMER_BANK_ACCOUNT",

  
  

REVOKE_TRANSACTION_FAILED: "REVOKE_TRANSACTION_FAILED",

  


CONFIRM_ACCOUNTSECURITYPASSWORD: "CONFIRM_ACCOUNTSECURITYPASSWORD",

  


SETTLE_TRANSACTION: "SETTLE_TRANSACTION",

  


FETCH_BANKS_BY_COUNTRY: "FETCH_BANKS_BY_COUNTRY",

  

//VOID TRANSACTION
VOID_TRANSACTION: "VOID_TRANSACTION",

  

FETCH_BANKS_BY_COUNTRY_FAILED: "FETCH_BANKS_BY_COUNTRY_FAILED",

  
  
FETCH_CUSTOMER_BANK_ACCOUNT_FAILED: "FETCH_CUSTOMER_BANK_ACCOUNT_FAILED",

  
FETCH_BANKS_BY_COUNTRY_SUCCEEDED: "FETCH_BANKS_BY_COUNTRY_SUCCEEDED",

  
REVOKE_TRANSACTION_SUCCEEDED: "REVOKE_TRANSACTION_SUCCEEDED",

  
// Customer Manager Chat
FETCH_CHAT_HISTORY: "FETCH_CHAT_HISTORY",

  
// Get P8 Settings
FETCH_P8SETTINGS: "FETCH_P8SETTINGS",

  
FETCH_CHAT_HISTORY_FAILED: "FETCH_CHAT_HISTORY_FAILED",

  
FETCH_P8SETTINGS_FAILED: "FETCH_P8SETTINGS_FAILED",
  
FETCH_CHAT_HISTORY_SUCCEEDED: "FETCH_CHAT_HISTORY_SUCCEEDED",
  
FETCH_P8SETTINGS_SUCCEEDED: "FETCH_P8SETTINGS_SUCCEEDED",

  
FETCH_CHAT_SESSION: "FETCH_CHAT_SESSION",

  
FETCH_CUSTOMER_BANK_ACCOUNT_SUCCEEDED: "FETCH_CUSTOMER_BANK_ACCOUNT_SUCCEEDED",

  
ADD_COMMENT: "ADD_COMMENT",

  
  GET_OPTIONAL_FIELDS: "GET_OPTIONAL_FIELDS",

  ADD_COMMENT_FAILED: "ADD_COMMENT_FAILED",

  GET_OPTIONAL_FIELDS_SUCCESS: "GET_OPTIONAL_FIELDS_SUCCESS",

  ADD_COMMENT_SUCCEEDED: "ADD_COMMENT_SUCCEEDED",

  UPDATE_ACCOUNTSTATUS: "UPDATE_ACCOUNTSTATUS",

  
  FETCH_CHAT_SESSION_FAILED: "FETCH_CHAT_SESSION_FAILED",

  
UPDATE_ACCOUNTSTATUS_FAILED: "UPDATE_ACCOUNTSTATUS_FAILED",

  
FETCH_CHAT_SESSION_SUCCEEDED: "FETCH_CHAT_SESSION_SUCCEEDED",

  
UPDATE_ACCOUNTSTATUS_SUCCEEDED: "UPDATE_ACCOUNTSTATUS_SUCCEEDED",

  
DELETE_COMMENT: "DELETE_COMMENT",

  
DELETE_COMMENT_FAILED: "DELETE_COMMENT_FAILED",

  
  
OPEN_MODAL_ACCOUNT_TRANSFER_CONFIRMATION: "OPEN_MODAL_ACCOUNT_TRANSFER_CONFIRMATION",

  

CLOSE_MODAL_COMMENT: "CLOSE_MODAL_COMMENT",

  

OPEN_MODAL_ACCOUNT_TRANSFER_SEARCH: "OPEN_MODAL_ACCOUNT_TRANSFER_SEARCH",

  

DELETE_COMMENT_SUCCEEDED: "DELETE_COMMENT_SUCCEEDED",

  
// REset state
RESET_STATE: "RESET_STATE",

  
// Customer Manager Comments
FETCH_COMMENTS: "FETCH_COMMENTS",

  
  

SELECT_ACCOUNT_TO_TRANSFER: "SELECT_ACCOUNT_TO_TRANSFER",

  


FETCH_ACCOUNT_MORE_DETAILS: "FETCH_ACCOUNT_MORE_DETAILS",

  


SET_CHANGE_PASSWORD_SUCCEEDED_STATUS: "SET_CHANGE_PASSWORD_SUCCEEDED_STATUS",

  


FETCH_ACCOUNT_MORE_DETAILS_FAILED: "FETCH_ACCOUNT_MORE_DETAILS_FAILED",

  

// UPDATE account security details
UPDATE_ACCOUNTSECURITYDETAILS: "UPDATE_ACCOUNTSECURITYDETAILS",

  

FETCH_ACCOUNT_MORE_DETAILS_SUCCEEDED: "FETCH_ACCOUNT_MORE_DETAILS_SUCCEEDED",

  

UPDATE_ACCOUNTSECURITYDETAILS_FAILED: "UPDATE_ACCOUNTSECURITYDETAILS_FAILED",

  
  

FETCH_COMMENTS_FAILED: "FETCH_COMMENTS_FAILED",

  
  

UPDATE_ACCOUNTSECURITYDETAILS_SUCCEEDED: "UPDATE_ACCOUNTSECURITYDETAILS_SUCCEEDED",

  

FETCH_COMMENTS_SUCCEEDED: "FETCH_COMMENTS_SUCCEEDED",

  
// UPDATE customer's bank account
UPDATE_CUSTOMER_BANK_ACCOUNT: "UPDATE_CUSTOMER_BANK_ACCOUNT",

  
// Customer Manager Promo
FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO: "FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO",
  

UPDATE_CUSTOMER_BANK_ACCOUNT_FAILED: "UPDATE_CUSTOMER_BANK_ACCOUNT_FAILED",

  

FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_FAILED: "FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_FAILED",

  

UPDATE_CUSTOMER_BANK_ACCOUNT_SUCCEEDED: "UPDATE_CUSTOMER_BANK_ACCOUNT_SUCCEEDED",

  

FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_SUCCEEDED: "FETCH_OUTCOME_WAGERLIMIT_GROUP_INFO_SUCCEEDED",

  

FETCH_PAYMENT_PROVIDERS: "FETCH_PAYMENT_PROVIDERS",

  
  // Update account security details password
UPDATE_ACCOUNTSECURITYPASSWORD: "UPDATE_ACCOUNTSECURITYPASSWORD",

  FETCH_PAYMENT_PROVIDERS_FAILED: "FETCH_PAYMENT_PROVIDERS_FAILED",

  UPDATE_ACCOUNTSECURITYPASSWORD_FAILED: "UPDATE_ACCOUNTSECURITYDETAILS_FAILED",

  CM_OPERATOR_LOGIN: "CM_OPERATOR_LOGIN",

  
  UPDATE_ACCOUNTSECURITYPASSWORD_SUCCEEDED: "UPDATE_ACCOUNTSECURITYPASSWORD_SUCCEEDED",

  
CM_OPERATOR_LOGIN_FAILED: "CM_OPERATOR_LOGIN_FAILED",

  
CM_OPERATOR_CLEAR_ERROR: "CM_OPERATOR_CLEAR_ERROR",

  
RESET_PASSWORDERRORMESSAGE: "RESET_PASSWORDERRORMESSAGE",

  
CLOSE_MODAL_CM_OPERATOR_LOGIN: "CLOSE_MODAL_CM_OPERATOR_LOGIN",

  
CM_OPERATOR_LOGIN_SUCCEEDED: "CM_OPERATOR_LOGIN_SUCCEEDED",

  
FETCH_PAYMENT_PROVIDERS_SUCCEEDED: "FETCH_PAYMENT_PROVIDERS_SUCCEEDED",

  
// Get Account Wager Limit
FETCH_ACCOUNT_WAGER_LIMITS: "FETCH_ACCOUNT_WAGER_LIMITS",
  

FETCH_PERFORMANCE: "FETCH_PERFORMANCE",
  

FETCH_ACCOUNT_WAGER_LIMITS_FAILED: "FETCH_ACCOUNT_WAGER_LIMITS_FAILED",

  
// Customer Manager Promo
FETCH_PROMO_REWARDS: "FETCH_PROMO_REWARDS",
  
FETCH_ACCOUNT_WAGER_LIMITS_SUCCEEDED: "FETCH_ACCOUNT_WAGER_LIMITS_SUCCEEDED",
  
UPDATE_CC_COMMENT: "UPDATE_CC_COMMENT",

  
// Customer Manager Settings
FETCH_EVENTPATHS_FROM_CODE: "FETCH_EVENTPATHS_FROM_CODE",
  

UPDATE_CC_COMMENT_FAILED: "UPDATE_CC_COMMENT_FAILED",
  
  
FETCH_EVENTPATHS_FROM_CODE_FAILED: "FETCH_EVENTPATHS_FROM_CODE_FAILED",

  
UPDATE_COMMENT: "UPDATE_COMMENT",

  
FETCH_EVENTPATHS_FROM_CODE_SUCCEEDED: "FETCH_EVENTPATHS_FROM_CODE_SUCCEEDED",

  
UPDATE_COMMENT_FAILED: "UPDATE_COMMENT_FAILED",

  
FETCH_PERFORMANCE_FAILED: "FETCH_PERFORMANCE_FAILED",

  
UPDATE_COMMENT_SUCCEEDED: "UPDATE_COMMENT_SUCCEEDED",

  
  // Get Account details for Settings Tab
FETCH_ACCOUNTDETAILS_SETTINGS: "FETCH_ACCOUNTDETAILS_SETTINGS",

  
FETCH_ACCOUNTDETAILS_SETTINGS_FAILED: "FETCH_ACCOUNTDETAILS_SETTINGS_FAILED",

  
UPDATE_CC_COMMENT_SUCCEEDED: "UPDATE_CC_COMMENT_SUCCEEDED",

  
FETCH_ACCOUNTDETAILS_SETTINGS_SUCCEEDED: "FETCH_ACCOUNTDETAILS_SETTINGS_SUCCEEDED",

  
FETCH_APP_PERMISSIONS: "FETCH_APP_PERMISSIONS",

  
FETCH_PROMO_REWARDS_FAILED: "FETCH_PROMO_REWARDS_FAILED",

  
DELETE_EVENT_PATH: "DELETE_EVENT_PATH",

  
  OPEN_MODAL_COMMENT: "OPEN_MODAL_COMMENT",

  DELETE_EVENT_PATH_FAILED: "DEL;ETE_EVENT_PATH_FAILED",

  SELECT_COMMENT_ID: "SELECT_COMMENT_ID",

  CLEAR_DELETE_EVENT_PATH_ERRORS: "CLEAR_DELETE_EVENT_PATH_ERRORS",

  FETCH_PROMO_REWARDS_SUCCEEDED: "FETCH_PROMO_REWARDS_SUCCEEDED",

  
  DELETE_EVENT: "DELETE_EVENT",

  
DELETE_EVENT_FAILED: "DELETE_EVENT_FAILED",

  
FETCH_PERFORMANCE_SUCCEEDED: "FETCH_PERFORMANCE_SUCCEEDED",

  
DELETE_EVENT_PATH_SUCCEEDED: "DELETE_EVENT_PATH_SUCCEEDED",

  
UPDATE_ACCOUNT_MORE_DETAILS: "UPDATE_ACCOUNT_MORE_DETAILS",

  
// CUSTOMER MANAGER MANUAL TRANSACTION
CM_GET_MONEYTYPES: "CM:GET_MONEYTYPES",

  

UPDATE_ACCOUNT_MORE_DETAILS_FAILED: "UPDATE_ACCOUNT_MORE_DETAILS_FAILED",

  
  

CM_GET_MONEYTYPES_FAILED: "CM:CM_GET_MONEYTYPES_FAILED",

  


UPDATE_ACCOUNT_MORE_DETAILS_SUCCEEDED: "UPDATE_ACCOUNT_MORE_DETAILS_SUCCEEDED",

  


CM_GET_MONEYTYPES_SUCCESS: "CM:CM_GET_MONEYTYPES_SUCCESS",

  

// Customer Manager Performance
FETCH_PERFORMANCE_SUMMARY: "FETCH_PERFORMANCE_SUMMARY",

  

DELETE_EVENT_SUCCEEDED: "DELETE_EVENT_SUCCEEDED",

  

FETCH_PERFORMANCE_SUMMARY_FAILED: "FETCH_PERFORMANCE_SUMMARY_FAILED",

  

FETCH_APP_PERMISSIONS_FAILED: "FETCH_APP_PERMISSIONS_FAILED",

  
  
FETCH_PERFORMANCE_SUMMARY_SUCCEEDED: "FETCH_PERFORMANCE_SUMMARY_SUCCEEDED",

  
  
FETCH_APP_PERMISSIONS_SUCCEEDED: "FETCH_APP_PERMISSIONS_SUCCEEDED",

  

AFFILIATE_VIEWER_LOGIN_FAILED: "AFFILIATE_VIEWER_LOGIN_FAILED",

  
  
// Get Profile Flags
FETCH_PLAYER_PROFILE_FLAGS: "FETCH_PLAYER_PROFILE_FLAGS",

  

AFFILIATE_VIEWER_LOGIN_SUCCEEDED: "AFFILIATE_VIEWER_LOGIN_SUCCEEDED",

  

FETCH_PLAYER_PROFILE_FLAGS_FAILED: "FETCH_PLAYER_PROFILE_FLAGS_FAILED",

  

FETCH_AV_COUNTRIES: "FETCH_AV_COUNTRIES",

  

FETCH_PLAYER_PROFILE_FLAGS_SUCCEEDED: "FETCH_PLAYER_PROFILE_FLAGS_SUCCEEDED",

  

FETCH_AV_COUNTRIES_FAILED: "FETCH_AV_COUNTRIES_FAILED",

  
  

OPEN_MODAL_CM_OPERATOR_LOGIN: "OPEN_MODAL_CM_OPERATOR_LOGIN",

  


FETCH_AV_COUNTRIES_SUCCEEDED: "FETCH_AV_COUNTRIES_SUCCEEDED",

  

// Save Profile Flags
SAVE_PLAYER_PROFILE_FLAG: "SAVE_PLAYER_PROFILE_FLAG",

  

CM_DO_EXPIRE_PROMOTION_REWARD: "CM_DO_EXPIRE_PROMOTION_REWARD",

  
// Save Wager Limit
SAVE_WAGER_LIMIT: "SAVE_WAGER_LIMIT",

  
CM_DO_EXPIRE_PROMOTION_REWARD_FAILED: "CM_DO_EXPIRE_PROMOTION_REWARD_FAILED",

  
SAVE_WAGER_LIMIT_FAILED: "SAVE_WAGER_LIMIT_FAILED",

  
CM_DO_EXPIRE_PROMOTION_REWARD_SUCCESS: "CM_DO_EXPIRE_PROMOTION_REWARD_SUCCESS",

  
SAVE_WAGER_LIMIT_SUCCEEDED: "SAVE_WAGER_LIMIT_SUCCEEDED",

  
CLAIM_ITME: "CLAIM_ITME",

  
  // Set Default wager limit
SET_DEFAULT_WAGER_LIMIT: "SET_DEFAULT_WAGER_LIMIT",

  
CLAIM_ITME_SUCCESS: "CLAIM_ITME_SUCCESS",

  
SET_PATH_SPECIFIC_LIMITS: "SET_PATH_SPECIFIC_LIMITS",

  
ALERT_UPDATE: "ALERT_UPDATE",

  
  
// AFFILIATE VIEWER
FETCH_AV_DEPENDENCIES: "FETCH_AV_DEPENDENCIES",

  


SAVE_PLAYER_PROFILE_FLAG_FAILED: "SAVE_PLAYER_PROFILE_FLAG_FAILED",
  

FETCH_AV_LANGUAGE: "FETCH_AV_LANGUAGE",
  
SAVE_PLAYER_PROFILE_FLAG_SUCCEEDED: "SAVE_PLAYER_PROFILE_FLAG_SUCCEEDED",
  
FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  
FETCH_USER_DETAILS_FAILED: "FETCH_USER_DETAILS_FAILED",
  
FETCH_USER_DETAILS_SUCCEEDED: "FETCH_USER_DETAILS_SUCCEEDED",
  
FETCH__AV_LANGUAGE_FAILED: "FETCH__AV_LANGUAGE_FAILED",
  
FETCH__AV_LANGUAGE_SUCCEEDED: "FETCH__AV_LANGUAGE_SUCCEEDED",
  
// NOTIFICATION
GET_NOTIFY_ALERTS: "GET_NOTIFY_ALERTS",
  
// EVENT CREATOR PATH TREE
SELECT_EVENT_CREATOR_PATH: "SELECT_EVENT_CREATOR_PATH",
  
GET_NOTIFY_ALERTS_SUCCESS: "GET_NOTIFY_ALERTS_SUCCESS",
  
SET_EVENT_CREATOR_LOADING_VISIBILITY: "SET_EVENT_CREATOR_LOADING_VISIBILITY",

  
LOGIN_AFFILIATE_VIEWER: "LOGIN_AFFILIATE_VIEWER",
  
  LOGOUT_AFFILIATE_VIEWER: "LOGOUT_AFFILIATE_VIEWER",

  READ_ALL: "READ_ALL",

  READ_ALL_SUCCESS: "READ_ALL_SUCCESS",

  SAVE_REORDER: "SAVE_REORDER",

  READ_ITEM: "READ_ITEM",

  SAVE_REORDER_FAILED: "SAVE_REORDER_FAILED",
  READ_ITEM_SUCCESS: "READ_ITEM_SUCCESS",
  SAVE_REORDER_SUCCEEDED: "SAVE_REORDER_SUCCEEDED",
  REGISTER_AFFILIATE_VIWER: "REGISTER_AFFILIATE_VIWER",
  SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
  SET_BRAND_ID: "SET_BRAND_ID",
  SET_USER_DATA: "SET_USER_DATA",
};

export default actionTypes;
