const actionTypes = {
  FETCH_APPLICABLE_TEMPLATES: "FETCH_APPLICABLE_TEMPLATES",
  FETCH_APPLICABLE_TEMPLATES_FAILED: "FETCH_APPLICABLE_TEMPLATES_FAILED",
  FETCH_APPLICABLE_TEMPLATES_SUCCEEDED: "FETCH_APPLICABLE_TEMPLATES_SUCCEEDED",

  UPDATE_APPLICABLE_TEMPLATES: "UPDATE_APPLICABLE_TEMPLATES",
  UPDATE_APPLICABLE_TEMPLATES_FAILED: "UPDATE_APPLICABLE_TEMPLATES_FAILED",
  UPDATE_APPLICABLE_TEMPLATES_SUCCEEDED: "UPDATE_APPLICABLE_TEMPLATES_SUCCEEDED",
};

export default actionTypes;
