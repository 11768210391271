import React, { useEffect } from "react";
import { Route, Router } from "react-router";

import asyncComponent from "./Async";
import { history } from "./store.js";
import { useSelector } from "react-redux";
import { getAuthLanguage } from "./redux/reselect/auth-selector";
import i18n from "i18next";
// import Lobby from './containers/Lobby/'
// import Login from './containers/Login'
// import EnsureLoggedInContainer from './containers/EnsureLoggedInContainer'
// import CustomerManager from './containers/CustomerManager/'
// import OperatorManager from './containersV2/OperatorManager/App'
// import CombiRiskManager from './containersV2/CombiRiskManager'
// import RiskManager from './containers/RiskManager/'
// import CustomerChat from './containersV2/CustomerChat/'
// import ResultsViewer from './containers/ResultsViewer/'
// import InstantAction from 'containersV2/InstantAction/'
// import ReportsManager from 'reportsManagerRoot'

// // Event Creator
// import EventCreatorApp from 'containersV2/EventCreator/App'
// import EventPath from 'containersV2/EventCreator/Path'
// import Event from 'containersV2/EventCreator/Event'
// import Market from 'containersV2/EventCreator/EventMarkets'
// import CreateGameEvent from 'containersV2/EventCreator/Event/GameEvent/CreateContainer'
// import CreateRankEvent from 'containersV2/EventCreator/Event/RankEvent/CreateContainer'

// import ToastContainer from './components/toastr/index'
// import Utilities from 'containersV2/Utilities/App/index'
// import UtilitiesMain from 'containersV2/Utilities/Main/index'
// import NotFound from './containers/NotFound'
// import PaymentHandlerApp from './containersV2/PaymentHandler'
// import BonusManager from 'containersV2/BonusManager/App/index'
// import Campaigns from 'containersV2/BonusManager/Main/Campaigns/index'
// import Promotions from 'containersV2/BonusManager/Main/Promotions/index'
// import PromotionalGroups from 'containersV2/BonusManager/Main/PromotionalGroups/index'
// import MessagePlans from 'containersV2/BonusManager/Main/MessagePlans/index'
// import TermsAndConditions from 'containersV2/BonusManager/Main/TermsAndConditions/index'

// import JackpotBetManager from 'containersV2/JackpotBetManager/App'
// import TranslationManager from 'containersV2/TranslationManager/App'
// import WagerLimits from 'containersV2/WagerLimits/App'
// import Dashboard from 'containersV2/AffiliateManager/Main/Dashboard/index'
// import RevenueWithdrawals from 'containersV2/AffiliateManager/Main/RevenueWithdrawals/index'
// import CommissionPlans from 'containersV2/AffiliateManager/Main/CommissionPlans/index'
// import LandingPages from 'containersV2/AffiliateManager/Main/LandingPages/index'
// import Reports from 'containersV2/AffiliateManager/Main/Reports/index'
// import AVRevenueWithdrawals from 'containersV2/AffiliateViewer/Main/RevenueWithdrawals/index'
// import AVMyAccount from 'containersV2/AffiliateViewer/Main/MyAccount/index'
// import AVReports from 'containersV2/AffiliateViewer/Main/AVReports/index'

// const Lobby = lazy(() => import('./containers/Lobby/'));
// const Login = lazy(() => import('./containers/Login'));
// const EnsureLoggedInContainer = lazy(() => import('./containers/EnsureLoggedInContainer'));
// const CustomerManager = lazy(() => import('./containers/CustomerManager/'));
// const OperatorManager = lazy(() => import('./containersV2/OperatorManager/App'));
// const CombiRiskManager = lazy(() => import('./containersV2/CombiRiskManager'));
// const RiskManager = lazy(() => import('./containers/RiskManager/'));
// const CustomerChat = lazy(() => import('./containersV2/CustomerChat/'));
// const ResultsViewer = lazy(() => import('./containers/ResultsViewer/'));
// const InstantAction = lazy(() => import('containersV2/InstantAction/'));
// const ReportsManager = lazy(() => import('reportsManagerRoot'));
// const EventCreatorApp = lazy(() => import('containersV2/EventCreator/App'));
// const EventPath = lazy(() => import('containersV2/EventCreator/Path'));
// const Event = lazy(() => import('containersV2/EventCreator/Event'));
// const Market = lazy(() => import('containersV2/EventCreator/EventMarkets'));
// const CreateGameEvent = lazy(() => import('containersV2/EventCreator/Event/GameEvent/CreateContainer'));
// const CreateRankEvent = lazy(() => import('containersV2/EventCreator/Event/RankEvent/CreateContainer'));
// const ToastContainer = lazy(() => import('./components/toastr/index'));
// const Utilities = lazy(() => import('containersV2/Utilities/App/index'));
// const UtilitiesMain = lazy(() => import('containersV2/Utilities/Main/index'));
// const NotFound = lazy(() => import('./containers/NotFound'));
// const PaymentHandlerApp = lazy(() => import('./containersV2/PaymentHandler'));
// const BonusManager = lazy(() => import('containersV2/BonusManager/App/index'));
// const Campaigns = lazy(() => import('containersV2/BonusManager/Main/Campaigns/index'));
// const Promotions = lazy(() => import('containersV2/BonusManager/Main/Promotions/index'));
// const PromotionalGroups = lazy(() => import('containersV2/BonusManager/Main/PromotionalGroups/index'));
// const MessagePlans = lazy(() => import('containersV2/BonusManager/Main/MessagePlans/index'));
// const TermsAndConditions = lazy(() => import('containersV2/BonusManager/Main/TermsAndConditions/index'));
// const JackpotBetManager = lazy(() => import('containersV2/JackpotBetManager/App'));
// const TranslationManager = lazy(() => import('containersV2/TranslationManager/App'));
// const WagerLimits = lazy(() => import('containersV2/WagerLimits/App'));
// const Dashboard = lazy(() => import('containersV2/AffiliateManager/Main/Dashboard/index'));
// const RevenueWithdrawals = lazy(() => import('containersV2/AffiliateManager/Main/RevenueWithdrawals/index'));
// const CommissionPlans = lazy(() => import('containersV2/AffiliateManager/Main/CommissionPlans/index'));
// const LandingPages = lazy(() => import('containersV2/AffiliateManager/Main/LandingPages/index'));
// const Reports = lazy(() => import('containersV2/AffiliateManager/Main/Reports/index'));
// const AVRevenueWithdrawals = lazy(() => import('containersV2/AffiliateViewer/Main/RevenueWithdrawals/index'));
// const AVMyAccount = lazy(() => import('containersV2/AffiliateViewer/Main/MyAccount/index'));
// const AVReports = lazy(() => import('containersV2/AffiliateViewer/Main/AVReports/index'));

// const AuthorizedContainer = asyncComponent(() =>
//    import('./AuthorizedContainer').then(module => module.default)
// );

const Lobby = asyncComponent(() => import("./containers/Lobby").then((module) => module.default));
const Login = asyncComponent(() => import("./containers/Login").then((module) => module.default));
const EnsureLoggedInContainer = asyncComponent(() =>
  import("./containers/EnsureLoggedInContainer").then((module) => module.default),
);
const CustomerManager = asyncComponent(() => import("./containers/CustomerManager").then((module) => module.default));
const OperatorManager = asyncComponent(() =>
  import("./containersV2/OperatorManager/App").then((module) => module.default),
);
const CombiRiskManager = asyncComponent(() =>
  import("./containersV2/CombiRiskManager").then((module) => module.default),
);
const RiskManager = asyncComponent(() => import("./containers/RiskManager").then((module) => module.default));
const CustomerChat = asyncComponent(() => import("./containersV2/CustomerChat").then((module) => module.default));
const ResultsViewer = asyncComponent(() => import("./containers/ResultsViewer").then((module) => module.default));
const InstantAction = asyncComponent(() => import("containersV2/InstantAction/").then((module) => module.default));
const ReportsManager = asyncComponent(() => import("containersNew/ReportsManager/").then((module) => module.default));
const EventCreatorApp = asyncComponent(() => import("containersV2/EventCreator/App").then((module) => module.default));
const EventPath = asyncComponent(() => import("containersV2/EventCreator/Path").then((module) => module.default));
const Event = asyncComponent(() => import("containersV2/EventCreator/Event").then((module) => module.default));
const Market = asyncComponent(() => import("containersV2/EventCreator/EventMarkets").then((module) => module.default));
const CreateGameEvent = asyncComponent(() =>
  import("containersV2/EventCreator/Event/GameEvent/CreateContainer").then((module) => module.default),
);
const CreateRankEvent = asyncComponent(() =>
  import("containersV2/EventCreator/Event/RankEvent/CreateContainer").then((module) => module.default),
);
const ToastContainer = asyncComponent(() => import("./components/toastr/index").then((module) => module.default));
const Utilities = asyncComponent(() => import("containersV2/Utilities/App/index").then((module) => module.default));
const UtilitiesMain = asyncComponent(() =>
  import("containersV2/Utilities/Main/index").then((module) => module.default),
);
const NotFound = asyncComponent(() => import("./containers/NotFound").then((module) => module.default));
const PaymentHandlerApp = asyncComponent(() =>
  import("./containersV2/PaymentHandler").then((module) => module.default),
);
const BonusManager = asyncComponent(() =>
  import("containersV2/BonusManager/App/index").then((module) => module.default),
);
const Campaigns = asyncComponent(() =>
  import("containersV2/BonusManager/Main/Campaigns/index").then((module) => module.default),
);
const Promotions = asyncComponent(() =>
  import("containersV2/BonusManager/Main/Promotions/index").then((module) => module.default),
);
const PromotionalGroups = asyncComponent(() =>
  import("containersV2/BonusManager/Main/PromotionalGroups/index").then((module) => module.default),
);
const TermsAndConditions = asyncComponent(() =>
  import("containersV2/BonusManager/Main/TermsAndConditions/index").then((module) => module.default),
);
const JackpotBetManager = asyncComponent(() =>
  import("containersV2/JackpotBetManager/App").then((module) => module.default),
);
const TranslationManager = asyncComponent(() =>
  import("containersV2/TranslationManager/App").then((module) => module.default),
);
const WagerLimits = asyncComponent(() => import("containersV2/WagerLimits/App").then((module) => module.default));
const SettingsBrowser = asyncComponent(() =>
  import("containersV2/SettingsBrowser/App").then((module) => module.default),
);
const ShopManager = asyncComponent(() => import("containersV2/ShopManager/App").then((module) => module.default));

function App() {
  const language = useSelector(getAuthLanguage);

  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <div className="app-phoenix">
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        {/* <Suspense fallback={<div>Loading...</div>}> */}

        <Route component={Login} path="/login" />

        <Route component={EnsureLoggedInContainer}>
          <Route component={Lobby} path="/" />
          <Route component={RiskManager} path="/risk-manager(/:sportCode)(/:key)" />
          <Route component={ResultsViewer} path="/results-viewer(/:sportCode)(/:key)" />
          <Route component={CustomerManager} path="/customer-manager(/:tab)(/:customerId)" />

          <Route component={EventCreatorApp} path="/event-creator">
            <Route component={EventPath} path=":sportCode(/p:pathId)" />
            <Route component={CreateGameEvent} path=":sportCode/e:eventId/game" />
            <Route component={CreateRankEvent} path=":sportCode/e:eventId/rank" />
            <Route component={Event} path=":sportCode/e:eventId" />
            <Route component={Market} path=":sportCode/m:marketId" />
          </Route>
          <Route component={Utilities} path="/utilities">
            <Route component={UtilitiesMain} path=":subRoute" />
          </Route>
          <Route component={BonusManager} path="/bonus-manager">
            <Route component={Campaigns} path="campaigns(/:id)" />
            <Route component={Promotions} path="promotions(/:id)" />
            <Route component={PromotionalGroups} path="promotional-groups(/:id)" />
            <Route component={TermsAndConditions} path="terms-and-conditions(/:id)" />
          </Route>
          <Route component={ReportsManager} path="/reports-manager" />
          <Route component={CustomerChat} path="/customer-chat" />
          <Route component={InstantAction} path="/instant-action" />
          <Route component={OperatorManager} path="/operator-manager" />
          <Route component={PaymentHandlerApp} path="/payment-handler" />
          <Route component={CombiRiskManager} path="/combination-risk-manager" />

          <Route component={JackpotBetManager} path="/jackpot-bet-manager" />
          <Route component={TranslationManager} path="/translation-manager" />
          <Route component={SettingsBrowser} path="/settings-browser" />
          <Route component={WagerLimits} path="/wager-limits" />

          <Route component={ShopManager} path="/shop-manager">
            <Route component={ShopManager} path="tab/:tab/shopGroup/:shopGroupId" />
            <Route component={ShopManager} path="tab/:tab/shopGroup/:shopGroupId/shop/:shopId" />
            <Route component={ShopManager} path="tab/:tab/shopGroup/:shopGroupId/shop/:shopId/till/:tillId" />
          </Route>

          <Route component={NotFound} path="*" />
        </Route>
        {/* </Suspense> */}
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
