import { makeIterable } from "utils";

const constants = {
  APPLICATION_ID: 6,
  FETCH_UTILITIES: "UTL::FETCH_UTILITIES",
  FETCH_UTILITIES_FAILED: "UTL::FETCH_UTILITIES_FAILED",
  FETCH_UTILITIES_SUCCEEDED: "UTL::FETCH_UTILITIES_SUCCEEDED",

  UPDATE_ACTIVE_PATH_ID: "UTL::UPDATE_ACTIVE_PATH_ID",
  UPDATE_PATH: "UTL::UPDATE_PATH",
};

export const utilsTreeMap = {
  1: {
    desc: "Tools",
    id: 1,
    isActive: false,
    isDisabled: false,
    isExpanded: true,
    items: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    level: 1,
    route: null,
    type: "group",
  },
  // 5: {
  //   id: 5,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: false,
  //   items: [],
  //   desc: 'Hurdle Management',
  //   url: '/utilities/hurdle-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 6: {
  //   id: 6,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Parlay Management',
  //   url: '/utilities/parlay-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 7: {
  //   id: 7,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Points Management',
  //   url: '/utilities/points-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 8: {
  //   id: 8,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Tease Management',
  //   url: '/utilities/teaser-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 9: {
  //   id: 9,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Vigorish Management',
  //   url: '/utilities/vigorish-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 10: {
  //   id: 10,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'PA Feed Management',
  //   url: '/utilities/pa-feed-management',
  //   parentId: 1,
  //   level: 2,
  // },
  11: {
    desc: "Exchange Rate Management",
    id: 11,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 1,
    type: "app",
    url: "/utilities/exchange-rate-management",
  },

  12: {
    desc: "Shop and Till Management",
    id: 12,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 1,
    type: "app",
    url: "/utilities/shop-and-till-management",
  },

  // 13: {
  //   id: 13,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Provider Management',
  //   url: '/utilities/provider-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 14: {
  //   id: 14,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Affiliate Management',
  //   url: '/utilities/affiliate-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 15: {
  //   id: 15,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Risk Defaults Management',
  //   url: '/utilities/risk-defaults-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 16: {
  //   id: 16,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Notifications Utility',
  //   url: '/utilities/notifications-utility',
  //   parentId: 1,
  //   level: 2,
  // },
  // 17: {
  //   id: 17,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Calendar Actions',
  //   url: '/utilities/calendar-actions',
  //   parentId: 1,
  //   level: 2,
  // },
  18: {
    desc: "Emergency Lock Down",
    id: 18,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 1,
    type: "app",
    url: "/utilities/emergency-lock-down",
  },

  2: {
    desc: "System",
    id: 2,
    isActive: false,
    isDisabled: false,
    isExpanded: true,
    items: [23],
    level: 1,
    route: null,
    type: "group",
  },

  // 19: {
  //   id: 19,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Retail Channel Management',
  //   url: '/utilities/retail-channel-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 20: {
  //   id: 20,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Winnings Tax Manager',
  //   url: '/utilities/winnings-tax-manager',
  //   parentId: 1,
  //   level: 2,
  // },
  // 21: {
  //   id: 21,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Void Reason Management',
  //   url: '/utilities/void-reason-management',
  //   parentId: 1,
  //   level: 2,
  // },
  // 22: {
  //   id: 22,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Channel Priority Utility',
  //   url: '/utilities/channel-priority-utility',
  //   parentId: 1,
  //   level: 2,
  // },
  // 23: {
  //   id: 23,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'System Manager',
  //   url: '/utilities/system-manager',
  //   parentId: 2,
  //   level: 2,
  // },
  24: {
    desc: "Line Management",
    id: 24,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 3,
    type: "app",
    url: "/utilities/line-management",
  },

  // 25: {
  //   id: 25,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Market Type Enablement',
  //   url: '/utilities/market-type-enablement',
  //   parentId: 3,
  //   level: 2,
  // },
  // 26: {
  //   id: 26,
  //   type: 'app',
  //   isExpanded: true,
  //   isActive: false,
  //   isDisabled: true,
  //   items: [],
  //   desc: 'Market Control Management',
  //   url: '/utilities/market-control-management',
  //   parentId: 3,
  //   level: 2,
  // },
  27: {
    desc: "Wager Limit Group Management",
    id: 27,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 3,
    type: "app",
    url: "/utilities/wager-limit-group-management",
  },

  28: {
    desc: "Origin Validation Mapping Utility",
    id: 28,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 3,
    type: "app",
    url: "/utilities/origin-validation-mapping-utility",
  },

  29: {
    desc: "Market Type Configuration",
    id: 29,
    isActive: false,
    isDisabled: true,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 3,
    type: "app",
    url: "/utilities/market-type-configuration",
  },
  3: {
    desc: "Market Control",
    id: 3,
    isActive: false,
    isDisabled: false,
    isExpanded: true,
    items: [24, 25, 26, 27, 28, 29],
    level: 1,
    route: null,
    type: "group",
  },
  4: {
    desc: "Bet Restriction Management",
    id: 4,
    isActive: false,
    isDisabled: false,
    isExpanded: true,
    items: [],
    level: 2,
    parentId: 1,
    type: "app",
    url: "/utilities/bet-restriction-management",
  },
};

export const utilsURLMap = [...makeIterable(utilsTreeMap)]
  .filter((util) => !!util.url)
  .reduce((accu, val) => {
    accu[val.url] = {
      id: val.id,
      parentId: val.parentId,
      // add other needed props here
    };

    return accu;
  }, {});

export default constants;
