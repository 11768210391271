import constants from "./constants";

export function fetchMessagePlans() {
  return {
    type: constants.FETCH_MESSAGE_PLANS,
  };
}

export function fetchMessagePlanDetails(id) {
  return {
    id,
    type: constants.FETCH_MESSAGE_PLAN_DETAILS,
  };
}

export function resetMessagePlans() {
  return {
    type: constants.RESET_MESSAGE_PLANS,
  };
}

export function createMessagePlanItem(params) {
  return {
    params,
    type: constants.CREATE_MESSAGE_PLAN,
    // params:{
    //     title:'New Message Plan',
    //     description: null,
    //     campaignMessageTrigger: null,
    //     campaignMessageTriggerFilter: null,
    //     template: null,
    //     expirationLength: null,
    //     promoGroupIds: [],
    //     channelIds: [],
    //     retired: false
    // }
  };
}

export function copyMessagePlanItem(params) {
  return {
    params,
    type: constants.COPY_MESSAGE_PLAN,
  };
}

export function updateMessagePlanItem(params) {
  return {
    params,
    type: constants.UPDATE_MESSAGE_PLAN,
  };
}

export function deleteMessagePlanItem(id) {
  return {
    id,
    type: constants.DELETE_MESSAGE_PLAN,
  };
}

export function setMessagePlanModified(value) {
  return {
    type: constants.SET_CAMPAIGN_MODIFIED,
    value,
  };
}

export function updateMessageModifiedData(value) {
  return {
    type: constants.UPDATE_EDITED_MESSAGE_IN_REDUX,
    value,
  };
}

export function updateMessagePlanField(field, value) {
  return {
    field,
    type: constants.UPDATE_MESSAGE_PLAN_FIELD,
    value,
  };
}

export function fetchAttachedCampaigns(messagePlan) {
  if (messagePlan) {
    return {
      payload: messagePlan,
      type: constants.FETCH_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN,
    };
  }

  return {
    type: constants.EMPTY_ATTACHED_CAMPAIGN_TO_MESSAGE_PLAN,
  };
}

export function editMessagePlanItem(value, id) {
  return {
    id,
    type: constants.EDIT_MESSAGE_PLAN_ITEM,
    value,
  };
}
export function copyMessagePlan(messagePlan) {
  return {
    messagePlan,
    type: constants.COPY_MESSAGE_PLAN,
  };
}
export function cancelEdit(isCanceling) {
  return {
    isCanceling,
    type: constants.CANCEL_EDIT,
  };
}
export function fetchOpponent(id) {
  return {
    id,
    type: constants.FETCH_OPPONENTS,
  };
}
