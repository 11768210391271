import { formatNumber } from "../utils";

const riskDataConfig = {
  autoRefreshInterval: 3000,
  columns: [
    {
      alwaysVisible: true,
      desc: "Actions",
      resizable: false,
      visible: true,
      width: "50px",
    },
    {
      alwaysVisible: true,
      dataKeys: ["desc"],
      dataSource: "outcome",
      desc: "Opponents",
      visible: true,
      width: "200px",
    },
    {
      dataKeys: ["fpFormattedSpread", "firstPrice"],
      dataSource: "outcome",
      desc: "First Price",
      summaryDataKeys: ["firstPriceMargin"],
      visible: true,
      width: "110px",
    },
    {
      allowEdit: true,
      dataKeys: ["priceSource", "formattedSpread", "price"],
      dataSource: "outcome",
      desc: "Last Price",
      summaryDataKeys: ["currentPriceMargin"],
      visible: true,
      width: "110px",
    },
    {
      dataKeys: ["turnover"],
      desc: "Turnover",
      formatData: (data) => `${formatNumber(data)}`,
      visible: false,
      width: "80px",
    },
    {
      dataKeys: ["turnoverByOutcomePercentage"],
      desc: "Turnover By Outcome %",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "70px",
    },
    {
      dataKeys: ["singlesTurnoverPercentage"],
      desc: "Singles Turnover %",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "70px",
    },
    {
      dataKeys: ["allStake"],
      desc: "Stakes All Bets",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
      width: "80px",
    },
    {
      dataKeys: ["singlesStake"],
      desc: "Stakes Singles",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
      width: "80px",
    },
    {
      dataKeys: ["stakesPercentageAllBets"],
      desc: "Stakes in % All Bets",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "70px",
    },
    {
      dataKeys: ["stakesPercentageSingles"],
      desc: "Stakes in % Singles",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "70px",
    },
    {
      dataKeys: ["numberOfBets"],
      desc: "No Bets",
      formatData: (data) => `${formatNumber(data, true)}`,
      visible: true,
      width: "50px",
    },
    {
      dataKeys: ["liabilityVariable"],
      desc: "Liability (Variable)",
      formatData: (data) => `${formatNumber(data, true)}`,
      visible: true,
    },
    {
      dataKeys: ["liability"],
      desc: "Liability",
      formatData: (data) => `${formatNumber(data)}`,
      resizable: false,
      visible: true,
    },
    {
      dataKeys: ["liabilityRU"],
      desc: "Liability Run Up",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["exposure"],
      desc: "Exposure",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["exposureRU"],
      desc: "Exposure Run Up",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["payout"],
      desc: "Payout",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["liabilityIndicator"],
      desc: "Liability Indicator",
      formatData: (data) => (data ? "!" : ""),
      visible: true,
      width: "22px",
    },
    {
      dataKeys: ["liabilitySingles"],
      desc: "Liability Singles",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["spStakesSingles"],
      desc: "SP Stakes Singlesf",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["spStakesRoLastLeg"],
      desc: "SP Stakes R/O Last Leg",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["fixedStakesSingles"],
      desc: "Fixed Odds Stakes Singles",
      formatData: (data) => `${formatNumber(data, true)}`,
      visible: true,
    },
    {
      dataKeys: ["fixedRoLastLeg"],
      desc: "Fixed Odds Stakes R/O Last Leg",
      formatData: (data) => `${formatNumber(data, true)}`,
      visible: true,
    },
    {
      dataKeys: ["ruStakesSp"],
      desc: "RU Stakes SP",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["ruStakesPrice"],
      desc: "RU Stakes Price",
      formatData: (data) => `${formatNumber(data, true)}`,
      visible: true,
    },
    {
      dataKeys: ["action"],
      desc: "Action",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["decision"],
      desc: "Decision",
      visible: true,
    },
    {
      dataKeys: ["stakeParlays"],
      desc: "Stakes Multiples",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["stakeTeasers"],
      desc: "Stakes Teasers",
      visible: true,
    },
    {
      dataKeys: ["stakeIfbets"],
      desc: "Stakes If Bets",
      visible: true,
    },
    {
      dataKeys: ["expectedStakeDistributionPercentage"],
      desc: "Expected Stake Distribution %",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "100px",
    },
    {
      dataKeys: ["stakeDeviationPercentage"],
      desc: "Stake Deviation %",
      formatData: (data) => `${data}%`,
      visible: true,
      width: "70px",
    },
    {
      dataKeys: ["cashoutStake"],
      desc: "Stake Cash Out",
      displayNullDataAs: "0",
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
    {
      dataKeys: ["cashoutProfit"],
      desc: "Profit Cash Out",
      displayNullDataAs: "0",
      formatData: (data) => (typeof data === "undefined" ? "0" : data),
      formatData: (data) => `${formatNumber(data)}`,
      visible: true,
    },
  ],
  defaultColumnDataSource: "outcomeRisk",
  defaultColumnWidth: "100px",
  defaultResizableSetting: true,
  getTotalWidth() {
    let totalWidth = 0;
    const defaultColumnWidth = this.defaultColumnWidth;
    this.columns.forEach((column) => {
      if (column.visible || column.alwaysVisible) {
        const width = typeof column.width === "undefined" ? defaultColumnWidth : column.width;
        totalWidth += parseInt(width);
      }
    });

    return totalWidth;
  },
  minColumnWidth: "100px",
  minColumnWidth: "320px",
  setColumnWidth(columnDesc, width) {
    const column = this.columns.find((column) => column.desc === columnDesc);
    if (column) {
      column.width = width;
    }
  },
  setColumnsVisibility(allColumns, visibleColumnIds) {
    for (let i = 0; i < this.columns.length; i++) {
      const column = this.columns[i];
      if (column.alwaysVisible) {
        continue;
      }
      const columnMatch = allColumns.find((item) => item.description.toLowerCase() === column.desc.toLowerCase());
      column.visible = visibleColumnIds.indexOf(columnMatch.id) > -1;
    }
  },
  visibleSpreadMarketTypeGroups: ["SPREAD", "THREE_WAYS_SPREAD", "TOTALS", "THREE_WAYS_TOTALS"],
};

export default riskDataConfig;
