import httpMethods from "constants/httpMethods";
import { performHttpCall } from "services//apiUtils";

const fetchEventXhr = null;
const headers = {
  "X-P8-Datasource": "database",
};

export function onCreateNewJackPotItem(action) {
  const url = "bo/jackpots";

  return performHttpCall(fetchEventXhr, httpMethods.HTTP_POST, url, action.details.payload)
    .then((response) => ({ response: response.data }))
    .catch((xhr) => ({ xhr }));
}
