const timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

const channelConfig = {
  defaultEventPath: "p227",
  defaultSportId: 227,
  lineId: 2,
  originId: 11,
  timeZone: "+0800",
  timeZoneOffset,
};

export default channelConfig;
